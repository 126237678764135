import React, { useContext, useState, useRef, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCompareContext } from '../../context/CompareContext';
import { useCollapse } from '../../context/CollapseContext';
import { UserContext } from "../../context/UserContext";
import Tooltip from '../../components/Tooltip';
import BudgetComponent from "../../components/statement/BudgetComponent";
import AttributeManager from "../../components/statement/AttributeManager";
import logoDoneURL from "../../icons/done.png";
import Comparison from "./Comparison";
import Countdown from "../../components/home/Countdown";
import ScoreIndicator from "../../components/home/ScoreIndicator";
import Savings from "../../components/home/Savings";
import Score from "../../components/home/Score";
import Excess from "../../components/home/Excess";
import Limits from "../../components/home/Limits";
import Conditions from "../../components/home/Conditions";
import SpiderChart from "../../components/home/SpiderChart";
import Strengths from '../../components/home/Strengths';
import Weaknesses from '../../components/home/Weaknesses';
import PropertyInformation from '../../components/home/PropertyInformation';
import AddressInformation from '../../components/home/AddressInformation';
import PropertyRisks from '../../components/home/PropertyRisks';
import WaitingDiv from "../../components/WaitingDiv";
import GiveUp from '../../components/home/GiveUp';
import Gain from '../../components/home/Gain';
import { fetchClients } from '../../apis/ClientService';
import RiskAdviceComponent from "../../components/statement/RiskAdviceComponent";
import { CountryContext } from '../../context/CountryContext';

import './StatementOfAdvice.css';

const StatementOfAdvice = () => {
    const { country } = useContext(CountryContext);
    const comparisonRef = useRef(null);
    const [userContext, setUserContext] = useContext(UserContext);
    const { masterPolicies } = useContext(MasterPoliciesContext);
    const { activeDocument } = useGlobalContext();
    const { compareDocument } = useCompareContext();
    const [clientDetails, setClientDetails] = useState({ name: '', dob: '', address: '' });
    const [adviserDetails, setAdviserDetails] = useState({ name: '', license: '', company: '', contact: '' });
    const [needs, setNeeds] = useState('');
    const [recommendations, setRecommendations] = useState([]);
    const [acknowledgement, setAcknowledgement] = useState('');
    const [disclosure, setDisclosure] = useState('');
    const [alternativeProducts, setAlternativeProducts] = useState('');
    const [basisOfAdvice, setBasisOfAdvice] = useState('');
    const [personalFinancial, setPersonalFinancial] = useState('');
    const [risks, setRisks] = useState('');
    const [costs, setCosts] = useState('');
    const [implementationSteps, setImplementationSteps] = useState('');
    const [scopeOfAdvice, setScopeOfAdvice] = useState(['Property Insurance']);
    const [customScope, setCustomScope] = useState('');
    const [clientGoals, setClientGoals] = useState([]);
    const { isCollapsed } = useCollapse();
    const [tab, setTab] = useState('newStatement');
    const [clients, setClients] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [pdfImage, setPdfImage] = useState('');
    const [score, setScore] = useState(0);
    const [result, setResult] = useState([]);
    const [myData, setMyData] = useState([]);
    const [sections, setSections] = useState({
        clientDetails: false,
        scope: false,
        goals: false,
        budget: false,
        risks: false,
        costs: false,
        strengthsweaknesses: false,
        adviserDetails: false,
        needs: false,
        recommendations: false,
        acknowledgement: false,
        conflictDisclosure: false,
        renumerationDisclosure: false,
        alternativeProducts: false,
        basisOfAdvice: false,
        implementation: false,
        personalFinancial: false,
    });
    const [scopeItems, setScopeItems] = useState({
        'Property Insurance': 'Advice could be related to home and contents insurance, as well as specific policies for high-value items or unique home situations (like flood or earthquake insurance).',
        'Contents Insurance': 'Advice on policies covering the contents of your home, protecting against risks such as theft, fire, and water damage.',
        'Vehicle Insurance': 'Covers various aspects of vehicle insurance, including collision, comprehensive, and liability coverage.',
        'Landlord Insurance': 'Provides coverage for property owners renting out properties, including protection against tenant-related risks and property damage.',
        'Other': ''
    });

    const [goalItems, setGoalItems] = useState({
        'Property Insurance': [
            {
                goal: 'Protect against Natural Disasters',
                description: 'Ensure comprehensive coverage for damages from natural events like earthquakes, floods, and storms to protect the financial investment in the home.'
            },
            {
                goal: 'Secure Financial Investment',
                description: 'Protect the financial investment in their home, ensuring they can repair or rebuild without significant financial burden after any incident.'
            },
            {
                goal: 'Liability Coverage',
                description: 'Include liability protection to cover incidents that may occur on their property, protecting against potential lawsuits.'
            },
            {
                goal: 'Affordable Premiums',
                description: 'Find a balance between comprehensive coverage and affordable premiums to keep the insurance cost-effective.'
            },
            {
                goal: 'Flexible Policy Options',
                description: 'Have the flexibility to adjust coverages, such as choosing between replacement cost or actual cash value for home repairs.'
            }
        ],
        'Contents Insurance': [
            {
                goal: 'Comprehensive Coverage',
                description: 'Cover a wide range of personal belongings from theft, damage, and loss.'
            },
            {
                goal: 'Valuables Protection',
                description: 'Ensure that high-value items such as jewelry, art, and electronics are adequately covered.'
            },
            {
                goal: 'Temporary Living Expenses',
                description: 'Include coverage for living expenses if the home is uninhabitable after a covered event.'
            },
            {
                goal: 'Easy Claim Process',
                description: 'Seek policies with straightforward, hassle-free claims processes to reduce stress in the event of a loss.'
            },
            {
                goal: 'Update and Review Coverage',
                description: 'Ability to regularly update and review the policy to reflect new purchases or changes in the value of belongings.'
            }
        ],
        'Landlord Insurance': [
            {
                goal: 'Tenant-related Damages',
                description: 'Protect against damages to the property caused by tenants, which are not usually covered under standard home insurance.'
            },
            {
                goal: 'Loss of Rental Income',
                description: 'Include coverage for loss of rental income due to the property becoming uninhabitable after an insured event.'
            },
            {
                goal: 'Liability Protection',
                description: 'Cover liability issues that could arise from injuries or other incidents on the property.'
            },
            {
                goal: 'Legal Expense Coverage',
                description: 'Provide for legal expenses in case of disputes with tenants or other issues requiring legal intervention.'
            },
            {
                goal: 'Structural and Contents Coverage',
                description: 'Ensure both the building and the contents owned by the landlord, such as appliances and furnishings, are covered.'
            }
        ],
        'Vehicle Insurance': [
            {
                goal: 'Comprehensive Coverage',
                description: 'Ensure protection against all forms of damage including theft, vandalism, and natural disasters.'
            },
            {
                goal: 'Collision Coverage',
                description: 'Offer coverage for expenses related to vehicle repairs or replacement in the case of collisions with other vehicles or objects.'
            },
            {
                goal: 'Liability Protection',
                description: 'Provide liability coverage to protect against financial loss in case the insured is responsible for an accident causing injury or property damage to others.'
            },
            {
                goal: 'Personal Injury Protection',
                description: 'Cover medical expenses and lost wages for the driver and passengers in case of an accident, regardless of who is at fault.'
            },
            {
                goal: 'Uninsured Motorist Protection',
                description: 'Protect against losses caused by accidents with uninsured or underinsured drivers.'
            }
        ]
    });

    const [markdown, setMarkdown] = useState(`
    ## Conflicts of Interest
    A conflict of interest occurs when our personal or financial interests, or those of another group associated with us, could potentially influence the advice we give you. We take our duty to act in your best interests seriously and have the following measures in place to manage conflicts of interest:
    
    - **Disclosure**: We disclose any relationships or associations that might influence the advice provided to you.
    - **Separation of Duties**: We ensure that decision-making and advisory roles are performed independently.
    - **Monitoring and Compliance**: Regular reviews are conducted to detect and manage any potential conflicts of interest.
    - **Employee Training**: All our staff are trained to recognize and manage conflicts of interest effectively.
    `
    );


  const getScores = (provider) => {
    
    return [
      (provider.score ? provider.score : 0),
      (provider.strengths ? Object.keys(provider.strengths).length * 2 : 0),
      (provider.weaknesses ? Object.keys(provider.weaknesses).length * 2 : 0),
      (provider.conditions ? Object.keys(provider.conditions).length * 2 : 0),
      (provider.demoPrice ? provider.demoPrice / 4 : 0),
    ];
  };

  const matchPolicyWithActiveDocument = (provider) => {
    if (activeDocument.policyProvider === provider.provider &&
      activeDocument.policyType === provider.type &&
      activeDocument.policyWording.filename === provider.fileName) {
      //
      return true;
    } else {
      return false;
    }
  }

  const matchPolicyWithCompareDocument = (provider) => {
    if (compareDocument?.policyProvider === provider.provider &&
      compareDocument?.policyType === provider.type &&
      compareDocument?.policyWording.filename === provider.fileName) {
      return true;
    } else {
      return false;
    }
  }

  const getDataPoints = (provider) => {
    
    return {
      label: `${provider.provider} ${provider.name}`,
      code: provider.provider,
      values: getScores(provider),
      visible: matchPolicyWithActiveDocument(provider) ? true : false,
      color: matchPolicyWithActiveDocument(provider) ? 'rgb(79,166,88)' : matchPolicyWithCompareDocument(provider) ? 'rgb(228,156,41)' : 'rgb(226,226,226)',
    };
  };


    const buildDataSet = (providers) => {
        let updatedData = [];
        for (let provider of providers.values()) {
          
          updatedData.push(getDataPoints(provider));
    
          if (activeDocument.policyProvider === provider.provider &&
            activeDocument.policyType === provider.type &&
            activeDocument.policyWording.filename === provider.fileName) {
            
            setScore(provider.score);
          }
    
        };
    
        if (updatedData.length > 0) {
          for (let i = 0; i < Math.min(3, updatedData.length); i++) {
            updatedData[i].visible = true;
          }
        }
    
        setMyData(updatedData);
      };

    useEffect(() => {
        const filteredPolicies = masterPolicies.filter(item =>
          item.type === activeDocument.policyType && item?.country === userContext.details?.country);
    
        if (filteredPolicies && filteredPolicies.length > 0) {
          
          buildDataSet(filteredPolicies);
        }
    
      }, [masterPolicies, activeDocument, compareDocument]);
    
      function comparePolicies(policyA, policyB) {
        const inPolicyBNotA = [];
        const inPolicyANotB = [];
    
        // Convert Mongoose documents to plain objects
        const coveragesA = policyA.coverages.toObject ? policyA.coverages.toObject() : policyA.coverages;
        const coveragesB = policyB.coverages.toObject ? policyB.coverages.toObject() : policyB.coverages;
    
        // Helper function to check if a coverage is included
        const isIncluded = (coverage) => coverage && coverage.included;
    
        // Iterate over the coverages in policy B
        for (const category in coveragesB) {
          if (coveragesB.hasOwnProperty(category)) {
            const categoryMap = coveragesB[category];
            if (typeof categoryMap === 'object' && categoryMap !== null) {
              for (const coverage in categoryMap) {
                if (categoryMap.hasOwnProperty(coverage)) {
                  const details = categoryMap[coverage];
                  if (isIncluded(details) &&
                    !(coveragesA[category] && isIncluded(coveragesA[category][coverage]))) {
                    inPolicyBNotA.push(`${coverage}`);
                  }
                }
              }
            }
          }
        }
    
        // Iterate over the coverages in policy A
        for (const category in coveragesA) {
          if (coveragesA.hasOwnProperty(category)) {
            const categoryMap = coveragesA[category];
            if (typeof categoryMap === 'object' && categoryMap !== null) {
              for (const coverage in categoryMap) {
                if (categoryMap.hasOwnProperty(coverage)) {
                  const details = categoryMap[coverage];
                  if (isIncluded(details) &&
                    !(coveragesB[category] && isIncluded(coveragesB[category][coverage]))) {
                    inPolicyANotB.push(`${coverage}`);
                  }
                }
              }
            }
          }
        }
    
        return { inPolicyBNotA, inPolicyANotB };
      }

    function comparePolicy() {
        if (!activeDocument || !compareDocument) {
          console.error("Active document or compare document is missing.");
          return;
        }
    
        const filteredPolicies = masterPolicies.filter(item =>
          item.type === activeDocument.policyType && item?.country === userContext.details?.country);
    
        const policy1 = filteredPolicies.find(policy => policy.provider === activeDocument.policyProvider);
        const policy2 = filteredPolicies.find(policy => policy.provider === compareDocument.policyProvider);
    
        if (!policy1 || !policy2) {
          console.error("Failed to find matching policies:",
            !policy1 ? "No match for active document provider." : "",
            !policy2 ? "No match for compare document provider." : "");
          return;
        }
    
        
    
        const comparisonResults = comparePolicies(policy1, policy2);
        
    
        setResult(comparisonResults);
      }
    
    
      useEffect(() => {
        comparePolicy();
      }, [activeDocument, compareDocument]);

    const generatePdf = () => {
        
        if (comparisonRef.current) {
            html2canvas(comparisonRef.current).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                setPdfImage(imgData);  // Store image data in state
            });
        }
    };

    const handleMarkChange = (e) => {
        setMarkdown(e.target.value);
    };

    const toggleGoalCheckbox = (item) => {
        setClientGoals(prev =>
            prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
        );
    };

    const toggleAdviceCheckbox = (item) => {
        setScopeOfAdvice(prev =>
            prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
        );
    };

    const handleCustomScopeChange = (event) => {
        setCustomScope(event.target.value);
    };

    const addCustomScope = () => {
        if (customScope && !scopeOfAdvice.includes(customScope)) {
            setScopeItems(prev => ({
                ...prev,
                [customScope]: '' // Assuming no description for custom items, or you can modify to include
            }));
            setScopeOfAdvice(prev => [...prev, customScope]);
            setCustomScope(''); // Reset custom input field
        }
    };

    const toggleCheckbox = (setter, value) => {
        setter(prev => prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]);
    };

    const toggleSection = (section) => {
        setSections(prevSections => ({ ...prevSections, [section]: !prevSections[section] }));
    };

    const handleChange = (e, setter) => {
        const { name, value } = e.target;
        setter(prev => ({ ...prev, [name]: value }));
    };

    const handleTextChange = (e, setter) => {
        setter(e.target.value);
    };

    const handleRecommendationChange = (index, field, value) => {
        const newRecommendations = [...recommendations];
        newRecommendations[index] = { ...newRecommendations[index], [field]: value };
        setRecommendations(newRecommendations);
    };

    // Function to handle changes from BudgetComponent
    const handleBudgetChange = (newState) => {
         // Log to console
        // setBudgetState(newState); // Update state in the parent component
    };
    const handleAttributeChange = (items) => {
        
    };

    const addRecommendation = () => {
        setRecommendations([...recommendations, { summary: '', coverage: '', benefits: '', cost: '', reasons: '' }]);
    };

    const removeRecommendation = (index) => {
        const newRecommendations = recommendations.filter((_, i) => i !== index);
        setRecommendations(newRecommendations);
    };

    const fetchClientData =  async  () => {
        const data = await fetchClients(userContext.details.username, country);
        if (data.length > 0) {
            setClients(data);
        }
    };

    useEffect(() => {
        if (userContext.details) {
            const loadClientData = async () => {
                await fetchClientData();
            };
    
            loadClientData(); // Call the async function inside useEffect
        }
    }, [userContext.details]);

    useEffect(() => {
        if (searchText.length >= 2) {
            const filtered = clients.filter(client =>
                `${client.personalDetails.firstName} ${client.personalDetails.lastName}`
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            );
            setFilteredClients(filtered);
        } else {
            setFilteredClients(clients);
        }
    }, [searchText, clients]);

    const handleClientSelectChange = (selectedClient) => {
        setSearchText(`${selectedClient.personalDetails.firstName} ${selectedClient.personalDetails.lastName}`);
        setShowDropdown(false);
    };

    const MyDocument = ({ image }) => (
        <Document>
            <Page style={styles.body}>
                <Text style={styles.header} fixed>
                    Statement of Advice
                </Text>
                <Image style={styles.image} src={image} />
                {Object.entries(sections).map(([section, isOpen]) => (
                    isOpen && (
                        <React.Fragment key={section}>
                            {section === 'clientDetails' && (
                                <>
                                    <Text style={styles.title}>Client Details</Text>
                                    <Text>Name: {clientDetails.name}</Text>
                                    <Text>Date of Birth: {clientDetails.dob}</Text>
                                    <Text>Address: {clientDetails.address}</Text>
                                </>
                            )}
                            {section === 'adviserDetails' && (
                                <>
                                    <Text style={styles.title}>Adviser Details</Text>
                                    <Text>Name: {adviserDetails.name}</Text>
                                    <Text>License Number: {adviserDetails.license}</Text>
                                    <Text>Company: {adviserDetails.company}</Text>
                                    <Text>Contact Information: {adviserDetails.contact}</Text>
                                </>
                            )}
                            {section === 'scope' && (
                                <>
                                    <Text style={styles.title}>Client Needs and Objectives</Text>
                                    <Text>{needs}</Text>
                                </>
                            )}
                            {section === 'recommendations' && (
                                <>
                                    <Text style={styles.title}>Recommendations</Text>
                                    {recommendations.map((recommendation, index) => (
                                        <View key={index} style={styles.recommendation}>
                                            <Text>Summary: {recommendation.summary}</Text>
                                            <Text>Coverage: {recommendation.coverage}</Text>
                                            <Text>Benefits: {recommendation.benefits}</Text>
                                            <Text>Cost: {recommendation.cost}</Text>
                                            <Text>Reasons: {recommendation.reasons}</Text>
                                        </View>
                                    ))}
                                </>
                            )}
                            {section === 'acknowledgement' && (
                                <>
                                    <Text style={styles.title}>Client Acknowledgement</Text>
                                    <Text>{acknowledgement}</Text>
                                </>
                            )}
                            {section === 'disclosure' && (
                                <>
                                    <Text style={styles.title}>Disclosure of Interests</Text>
                                    <Text>{disclosure}</Text>
                                </>
                            )}
                            {section === 'alternativeProducts' && (
                                <>
                                    <Text style={styles.title}>Alternative Products Considered</Text>
                                    <Text>{alternativeProducts}</Text>
                                </>
                            )}
                            {section === 'basisOfAdvice' && (
                                <>
                                    <Text style={styles.title}>Basis of Advice</Text>
                                    <Text>{basisOfAdvice}</Text>
                                </>
                            )}
                            {section === 'personalFinancial' && (
                                <>
                                    <Text style={styles.title}>Personal and Financial Situation</Text>
                                    <Text>{personalFinancial}</Text>
                                </>
                            )}
                        </React.Fragment>
                    )
                ))}
                <View style={styles.signature}>
                    <Text>_________________________</Text>
                    <Text>Client Signature</Text>
                </View>
            </Page>
        </Document>
    );

    return (
        <div className="statement-of-advice">
            <h2>Prepare Advice</h2>
            <div className={`statement-of-advice-white ${!activeDocument ? 'hide-analysis-dasboard' : ''}`} style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
                {tab === 'newStatement' ? (<>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('clientDetails')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.clientDetails ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Information about the client such as name, date of birth, and contact details.">
                                    <div>Client</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.clientDetails ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.clientDetails && (<>
                            <div style={{ position: 'relative' }}>
                                <input
                                    style={{ minWidth: '200px' }}
                                    type="text"
                                    placeholder="Search for existing client..."
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onFocus={() => {
                                        setSearchText('');
                                        setShowDropdown(true);
                                    }}
                                    onBlur={() => {
                                        setTimeout(() => setShowDropdown(false), 100);
                                    }}
                                    className="policy-wording-select"
                                />
                                {showDropdown && (
                                    <div style={{
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        border: '1px solid #ccc',
                                        position: 'absolute',
                                        top: '50px',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        left: 0,
                                        zIndex: 1000,
                                    }}>
                                        {filteredClients.map(client => (
                                            <div
                                                key={client.id}
                                                onMouseDown={() => handleClientSelectChange(client)}
                                                style={{ padding: '8px', cursor: 'pointer' }}
                                            >
                                                {client.personalDetails.firstName} {client.personalDetails.lastName}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="analysis-container-top">
                                <div className='analysis-container-white-column-20'>
                                    <AddressInformation address={activeDocument.policyAddress} />
                                </div>
                                <div className='analysis-container-white-column-80'>
                                    <PropertyInformation />
                                    <PropertyRisks address={activeDocument.policyAddress} />
                                </div>
                            </div>
                        </>

                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('situation')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.situation ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Information about the client such as name, date of birth, and contact details.">
                                    <div>Current Situation</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.situation ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.situation && (<>
                            <Score />
                            <div className="analysis-container-top">
                                <div className='analysis-container-white-column-20'>
                                    {/* TODO */}
                                    <ScoreIndicator percentage={'80'} />
                                    {activeDocument !== undefined && activeDocument !== null && activeDocument.periodOfInsuranceEnd && (
                                        <Countdown ExpiryDate={activeDocument.periodOfInsuranceEnd} />
                                    )}
                                    <Savings savings="215" />
                                </div>
                            </div>
                            <div className="analysis-container-top">
                                <Strengths />
                                <Weaknesses />
                            </div>
                        </>

                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('budget')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.budget ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Analysis of the client's needs based on their financial situation, goals, and risk profile.">
                                    <div>Budget</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.budget ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.budget && (
                            <BudgetComponent onChange={handleBudgetChange} />
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('scope')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.scope ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="A clear definition of what the advice covers and any limitations. This might include the types of insurance products discussed and any specific requests or concerns addressed by the client.">
                                    <div>Scope of Advice</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.scope ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.scope && (
                            <div className='form-section-options'>
                                {Object.entries(scopeItems).map(([key, description]) => (
                                    <div className='form-section-option' key={key}>
                                        <input type="checkbox" style={{ width: '20px' }}
                                            checked={scopeOfAdvice.includes(key)}
                                            onChange={() => toggleAdviceCheckbox(key)} />
                                        <Tooltip text={description}>
                                            <div>{key}</div>
                                        </Tooltip>
                                    </div>
                                ))}
                                {scopeOfAdvice.includes('Other') && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter custom scope"
                                            value={customScope}
                                            onChange={handleCustomScopeChange}
                                            style={{ width: '100%', marginTop: '10px' }}
                                        />
                                        <button onClick={addCustomScope} style={{ marginTop: '10px' }}>Add Scope</button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('goals')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.goals ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="A summary of the client's current financial situation, goals, and objectives. This helps to demonstrate how the recommendations are tailored to the client's specific circumstances.">
                                    <div>Client's Goals</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.goals ? "statement-inverted" : ""}`}></div>

                        </div>
                        {sections.goals && (
                            <>
                                {Object.entries(goalItems)
                                    .filter(([category, _]) => scopeOfAdvice.includes(category))
                                    .map(([category, goals]) => (
                                        <div key={category}>
                                            <div style={{ padding: '10px 0px' }}>{category}</div>
                                            <div className='form-section-options'>
                                                {goals.map((goal, index) => (
                                                    <div className='form-section-option' key={index}>
                                                        <input type="checkbox" style={{ width: '20px' }}
                                                            checked={clientGoals.includes(goal.goal)}
                                                            onChange={() => toggleGoalCheckbox(goal.goal)} />
                                                        <Tooltip text={goal.description}>
                                                            <div>{goal.goal}</div>
                                                        </Tooltip>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                {scopeOfAdvice.includes('Other') && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter custom scope"
                                            value={customScope}
                                            onChange={handleCustomScopeChange}
                                            style={{ width: '100%', marginTop: '10px' }}
                                        />
                                        <button onClick={addCustomScope} style={{ marginTop: '10px' }}>Add Scope</button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('needs')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.needs ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Analysis of the client's needs based on their financial situation, goals, and risk profile.">
                                    <div>Client Needs</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.needs ? "statement-inverted" : ""}`}></div>

                        </div>
                        {sections.needs && (
                            <AttributeManager onItemsChange={handleAttributeChange} />
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('recommendations')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.recommendations ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Detailed information about the recommended insurance products, including features, benefits, costs, and how they meet the client's needs and objectives.">
                                    <div>Options</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.recommendations ? "statement-inverted" : ""}`}></div>

                        </div>
                        {sections.recommendations && (
                            <>
                                {recommendations.map((recommendation, index) => (
                                    <div key={index} className="recommendation">
                                        <Comparison ref={comparisonRef} />
                                        <button onClick={() => removeRecommendation(index)}>Remove</button>
                                    </div>
                                ))}
                                <button onClick={addRecommendation}>Add Recommendation</button>
                                <button onClick={generatePdf}>Export Comparison to PDF</button>
                            </>
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('recommendations')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.recommendations ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Detailed information about the recommended insurance products, including features, benefits, costs, and how they meet the client's needs and objectives.">
                                    <div>Recommendations</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.recommendations ? "statement-inverted" : ""}`}></div>

                        </div>
                        {sections.recommendations && (
                            <>
                                {recommendations.map((recommendation, index) => (
                                    <div key={index} className="recommendation">
                                        <Comparison ref={comparisonRef} />
                                        <button onClick={() => removeRecommendation(index)}>Remove</button>
                                    </div>
                                ))}
                                <button onClick={addRecommendation}>Add Recommendation</button>
                                <button onClick={generatePdf}>Export Comparison to PDF</button>
                            </>
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('strengthsweaknesses')}>
                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.strengthsweaknesses ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="Description of any potential risks and disadvantages associated with the recommended options.">
                                    <div>Strengths and Weaknesses</div>
                                </Tooltip>
                            </div>

                            <div className={`statement-arrowhead ${sections.strengthsweaknesses ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.strengthsweaknesses && (
                            <>
                                <GiveUp data={result.inPolicyANotB} />
                                <Gain data={result.inPolicyBNotA} />
                            </>
                        )}
                    </div>
                    <div className="form-section">
                        <div className="form-section-header"
                            onClick={() => toggleSection('costs')}>

                            <div className="form-section-header-title">
                                <img src={logoDoneURL} alt=""
                                    style={{ width: '25px', filter: sections.costs ? 'grayscale(100%)' : '' }} />
                                <Tooltip text="An outline of all costs associated with the advice, including fees, commissions, and other charges.">
                                    <div>Costs</div>
                                </Tooltip>
                            </div>
                            <div className={`statement-arrowhead ${sections.costs ? "statement-inverted" : ""}`}></div>
                        </div>
                        {sections.costs && (
                            <input type="text" placeholder="Outline costs" value={costs} onChange={(e) => setCosts(e.target.value)} />
                        )}
                    </div>
                    <div className='form-section-generate'>
                        <PDFDownloadLink document={<MyDocument image={pdfImage} />} fileName="statement_of_advice.pdf"
                            style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold' }}>
                            {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Generate Statement')}
                        </PDFDownloadLink>
                    </div>
                </>
                ) : (
                    <>
                        <div className="form-section">
                            <div className="form-section-header"
                                onClick={() => toggleSection('risks')}>
                                <div className="form-section-header-title">
                                    <img src={logoDoneURL} alt=""
                                        style={{ width: '25px', filter: sections.risks ? 'grayscale(100%)' : '' }} />
                                    <Tooltip text="Description of any potential risks and disadvantages associated with the recommended options.">
                                        <div>Risks</div>
                                    </Tooltip>
                                </div>

                                <div className={`statement-arrowhead ${sections.risks ? "statement-inverted" : ""}`}></div>
                            </div>
                            {sections.risks && (
                                <RiskAdviceComponent />
                            )}
                        </div>
                        <div className="form-section">
                            <div className="form-section-header"
                                onClick={() => toggleSection('implementation')}>
                                <div className="form-section-header-title">
                                    <img src={logoDoneURL} alt=""
                                        style={{ width: '25px', filter: sections.implementation ? 'grayscale(100%)' : '' }} />
                                    <div>Implementation Steps</div>
                                </div>
                                <div className={`statement-arrowhead ${sections.implementation ? "statement-inverted" : ""}`}></div>
                            </div>
                            {sections.implementation && (
                                <textarea placeholder="Describe implementation steps" value={implementationSteps} onChange={(e) => setImplementationSteps(e.target.value)} />
                            )}
                        </div>
                        <div className="form-section">
                            <div className="form-section-header"
                                onClick={() => toggleSection('conflictDisclosure')}>
                                <Tooltip text="Disclosure of any conflicts of interest or remuneration that might affect the advice provided.">
                                    <div>Disclosure of Conflicts</div>
                                </Tooltip>
                                <div className={`statement-arrowhead ${sections.conflictDisclosure ? "statement-inverted" : ""}`}></div>

                            </div>
                            {sections.conflictDisclosure && (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div style={{ backgroundColor: '#f6f8fa', border: '1px solid #ddd', padding: '10px' }}>
                                            <ReactMarkdown>{markdown}</ReactMarkdown>
                                        </div>
                                        <textarea
                                            value={markdown}
                                            onChange={handleMarkChange}
                                            style={{ width: '100%', height: '300px' }}
                                        />
                                    </div>
                                    <div className="form-section-header"
                                        onClick={() => toggleSection('renumerationDisclosure')}>
                                        <Tooltip text="Disclosure of any conflicts of interest or remuneration that might affect the advice provided.">
                                            <div>Disclosure of Renumeration</div>
                                        </Tooltip>
                                        <div className={`statement-arrowhead ${sections.renumerationDisclosure ? "statement-inverted" : ""}`}></div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                        <div style={{ backgroundColor: '#f6f8fa', border: '1px solid #ddd', padding: '10px' }}>
                                            <ReactMarkdown>{markdown}</ReactMarkdown>
                                        </div>
                                        <textarea
                                            value={markdown}
                                            onChange={handleMarkChange}
                                            style={{ width: '100%', height: '300px' }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="form-section">
                            <div className="form-section-header"
                                onClick={() => toggleSection('adviserDetails')}>
                                <Tooltip text="Information about the adviser providing the statement, including their name, qualification details, and any relevant licenses or affiliations.">
                                    <div>Adviser Details</div>
                                </Tooltip>
                                <div className={`statement-arrowhead ${sections.adviserDetails ? "statement-inverted" : ""}`}></div>
                            </div>
                            {sections.adviserDetails && (
                                <>
                                    <input type="text" name="name" placeholder="Name" value={adviserDetails.name} onChange={(e) => handleChange(e, setAdviserDetails)} />
                                    <input type="text" name="license" placeholder="License Number" value={adviserDetails.license} onChange={(e) => handleChange(e, setAdviserDetails)} />
                                    <input type="text" name="company" placeholder="Company" value={adviserDetails.company} onChange={(e) => handleChange(e, setAdviserDetails)} />
                                    <input type="text" name="contact" placeholder="Contact Information" value={adviserDetails.contact} onChange={(e) => handleChange(e, setAdviserDetails)} />
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

            <div className="tab-selector">
                <button className={`tab-button ${tab === 'newStatement' ? 'active' : ''}`} onClick={() => setTab('newStatement')}>Client Details</button>
                <button className={`tab-button ${tab === 'template' ? 'active' : ''}`} onClick={() => setTab('template')}>Advisor Details</button>
            </div>
        </div >
    );
};

const styles = StyleSheet.create({
    body: {
        padding: 20,
        fontFamily: 'Helvetica',
    },
    header: {
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'center',
    },
    title: {
        fontSize: 18,
        marginTop: 20,
        marginBottom: 10,
    },
    signature: {
        marginTop: 50,
        textAlign: 'center',
    },
    image: {
        width: '100%',
        height: 'auto',
        marginVertical: 15,
    }
});

export default StatementOfAdvice;
