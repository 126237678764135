import React, { createContext, useState } from 'react';

export const MasterPoliciesContext = createContext();

export const MasterPoliciesProvider = ({ children }) => {
  const [masterPolicies, setMasterPolicies] = useState([]);

  return (
    <MasterPoliciesContext.Provider value={{ masterPolicies, setMasterPolicies }}>
      {children}
    </MasterPoliciesContext.Provider>
  );
};
