import React, { useState, useEffect, useRef } from 'react';
import SettingsURL from "../../icons/notification_new.png";
import { useGlobalContext } from '../../context/GlobalContext';
import './Notifications.css';

function Notifications() {
    const { activeDocument } = useGlobalContext();
    const [isOpen, setIsOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(3); // Example count
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            message: "Your Home Insurance policy is expiring",
            date: activeDocument?.periodOfInsuranceEnd || "2024-09-01",
        },
        {
            id: 2,
            message: "We've just reviewed the latest Home Insurance policy from Tower",
            date: "2024-09-01",
        },
        {
            id: 3,
            message: "Support coming soon for both Contents & Landlord insurance",
            date: "2024-09-03",
        }
    ]);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function formatDate(dateString) {
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];

        const date = new Date(dateString);

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear().toString().substr(-2);

        let daySuffix;
        switch (day) {
            case 1: case 21: case 31:
                daySuffix = 'st';
                break;
            case 2: case 22:
                daySuffix = 'nd';
                break;
            case 3: case 23:
                daySuffix = 'rd';
                break;
            default:
                daySuffix = 'th';
        }

        return `${day}${daySuffix} ${month} ${year}`;
    }

    function daysAgo(dateString) {
        const today = new Date();
        const date = new Date(dateString);
        const differenceInTime = today - date;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    const handleRemoveNotification = (id) => {
        const updatedNotifications = notifications.filter(notification => notification.id !== id);
        setNotifications(updatedNotifications);
        setNotificationCount(updatedNotifications.length);
    };

    return (
        <div className="notification-container" ref={ref}>
            <div className="notification-icon" onClick={() => setIsOpen(!isOpen)}>
                <img
                    src={SettingsURL}
                    alt="Click me"
                    className="notification-icon"
                />
                {notificationCount > 0 && (
                    <span className="notification-count">{notificationCount}</span>
                )}
            </div>

            {isOpen && notifications.length > 0 && (
                <div className="notification-panel" onMouseLeave={() => setIsOpen(false)}>
                    <ul>
                        {notifications.map(notification => (
                            <li key={notification.id} className="notification-item">
                                <span className="close-btn" onClick={() => handleRemoveNotification(notification.id)}>&times;</span>
                                {notification.message} {formatDate(notification.date)}
                                <div className="date-created-text">Date created: {formatDate(notification.date)} ({daysAgo(notification.date)} days ago)</div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Notifications;
