import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import 'rc-slider/assets/index.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatString } from '../utils/utils';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useGlobalContext } from '../context/GlobalContext';
import { MasterPoliciesContext } from '../context/MasterPoliciesContext';
import SemicircularProgress from './SemicircularProgress';
import './PolicyElementsChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const PolicyElementsChart = ({ valueType }) => {
    const { masterPolicies } = useContext(MasterPoliciesContext);
    const { activeDocument } = useGlobalContext();
    const [policyCoverage, setPolicyCoverage] = useState({});
    const [expandedGrid, setExpandedGrid] = useState(null);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [checkedItems, setCheckedItems] = useState({});
    const expandedRef = useRef(null);
    const filterPopupRef = useRef(null);
    const [isLeftActive, setIsLeftActive] = useState(true);

    useEffect(() => {
        if (activeDocument !== null && activeDocument !== undefined && activeDocument.policyProvider) {
            const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;
            if (masterPolicies && masterPolicies.values) {
                for (let entry of masterPolicies.values()) {
                    if (entry.provider === activeFileName) {

                        const filteredEntries = {};

                        Object.entries(entry.coverages).forEach(([key, value]) => {

                            // Filter the sub-items within the value to only include those with non-empty 'excess' or 'limit'
                            const validSubItems = Object.fromEntries(
                                Object.entries(value).filter(([subKey, subValue]) => {
                                    const isValid = valueType === 'excess' ? subValue.excess !== "" : valueType === 'limit' ? subValue.limit !== "" : false;
                                    return isValid;
                                })
                            );

                            // If there are valid sub-items, include them in the filteredEntries object
                            if (Object.keys(validSubItems).length > 0) {
                                filteredEntries[key] = validSubItems;
                            } 
                        });

                        setPolicyCoverage(filteredEntries);
                        break;
                    }


                }
            }
        }
    }, [activeDocument, masterPolicies]);

    useEffect(() => {
        const initialCheckedItems = {};
        Object.entries(policyCoverage).forEach(([displayName, subItems]) => {
            Object.entries(subItems).forEach(([subDisplayName, subValue]) => {
                let isNotEmpty = false;
                if (valueType === 'limit' && subValue.limit !== "") {
                    isNotEmpty = true;
                }
                else if (valueType === 'excess' && subValue.excess !== "") {
                    isNotEmpty = true;
                }
                if (isNotEmpty) {
                    initialCheckedItems[`${displayName}-${subDisplayName}`] = false;
                }
            });
        });
        setCheckedItems(initialCheckedItems);
    }, [policyCoverage]);

    const toggleGridExpansion = (identifier) => {
        setExpandedGrid(expandedGrid === identifier ? null : identifier);
    };

    const parseCurrency = (value) => {
        const parsedValue = parseFloat(value.replace(/[$,]/g, '')) || 0;
        const formattedValue = parsedValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        return formattedValue;
    };

    const parseCurrencyToNumber = (value) => {
        if (!value) return 0; // Check if value is undefined or null
        return parseFloat(value.replace(/[$,]/g, '')) || 0;
    };

    const precomputedChartData = useMemo(() => {
        const dataMap = new Map();
        Object.entries(policyCoverage).forEach(([displayName, subItems]) => {
            // Filter out subItems with no limit
            const filteredSubItems = Object.entries(subItems).filter(([subDisplayName]) => {
                const hasLimit = parseCurrencyToNumber(policyCoverage?.[displayName]?.[subDisplayName]?.limit);
                if (hasLimit === 0) {

                    return false;
                }
                return true;
            });

            filteredSubItems.forEach(([subDisplayName]) => {
                const data = [];
                const labels = [];
                let total = 0;

                for (let entry of Object.values(masterPolicies)) {
                    const value = valueType === 'limit' ? entry.coverages[displayName]?.[subDisplayName]?.limit : entry.coverages[displayName]?.[subDisplayName]?.excess;
                    if (value !== undefined && value !== null && value !== '' && value !== 0) {
                        const parsedValue = parseCurrencyToNumber(value);

                        if (parsedValue > 0) {
                            data.push(parsedValue);
                            labels.push(entry.provider);
                            total += parsedValue;
                        }
                    }
                }
                const maxValue = Math.max(...data);
                const suggestedMax = maxValue * 1.1;
                const average = data.length > 0 ? total / data.length : 0;
                dataMap.set(`${displayName}-${subDisplayName}`, {
                    labels,
                    datasets: [{
                        label: valueType.charAt(0).toUpperCase() + valueType.slice(1),
                        data,
                        backgroundColor: 'grey',
                    }],
                    suggestedMax,
                    average
                });
            });
        });

        return dataMap;
    }, [policyCoverage, masterPolicies, activeDocument, valueType]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (expandedRef.current && !expandedRef.current.contains(event.target)) {
                setExpandedGrid(null);
            }
            if (filterPopupRef.current && !filterPopupRef.current.contains(event.target)) {
                setFilterPopupOpen(false);
            }
        };
        if (expandedGrid || filterPopupOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [expandedGrid]);

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        setFilterPopupOpen(true)
    };

    const handleInputClick = (e) => {
        if(filterPopupOpen){
            setFilterPopupOpen(false);
        } else {
            handleSearchChange(e);
        }
        const inputElement = document.getElementById(`policy-elements-chart-id-${valueType}`);
        console.log("inputElement",inputElement);
        if (inputElement) {
            const rect = inputElement.getBoundingClientRect();
            console.log("rect",rect);
            const elementPosition = rect.top + window.pageYOffset;
            console.log("elementPosition",elementPosition);
            const offsetPosition = elementPosition - 40; // Subtract 40 pixels to add padding
            console.log("offsetPosition",offsetPosition);
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }

    }; 

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckedItems(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleClearAll = () => {
        const newCheckedItems = {};
        Object.keys(checkedItems).forEach(key => {
            newCheckedItems[key] = false;
        });
        setCheckedItems(newCheckedItems);
    };



    const handleShowAll = () => {
        const newCheckedItems = {};
        Object.keys(checkedItems).forEach(key => {
            newCheckedItems[key] = true;
        });
        setCheckedItems(newCheckedItems);
    };

    const filteredItems = Object.entries(policyCoverage)
        .flatMap(([displayName, subItems]) =>
            Object.entries(subItems).map(([subDisplayName, subValue]) => ({
                displayName,
                subDisplayName,
                subValue
            }))
        )
        .filter(item => formatString(item.displayName).toLowerCase().includes(searchText.toLowerCase()) || formatString(item.subDisplayName).toLowerCase().includes(searchText.toLowerCase()));

    const renderPolicyComparison = (value, average, count, total) => {
        const isExcess = valueType === 'excess';
        const getBoxClassName = (boxType) => {
            let className = `policy-box ${boxType.toLowerCase()}`;
            if (isExcess) {
                if (boxType === 'LOW' && value < average) {
                    className += ' active low-good';
                } else if (boxType === 'AVERAGE' && value === average) {
                    className += ' active average-good';
                } else if (boxType === 'HIGH' && value > average) {
                    className += ' active high-bad';
                }
            } else {
                if (boxType === 'LOW' && value < average) {
                    className += ' active low-bad';
                } else if (boxType === 'AVERAGE' && value === average) {
                    className += ' active average-good';
                } else if (boxType === 'HIGH' && value > average) {
                    className += ' active high-good';
                }
            }
            return className;
        };

        return (
            <div className="policy-elements-chart-comparison">
                <div className={getBoxClassName('LOW')}>LOW</div>
                <div className={getBoxClassName('AVERAGE')}>AVERAGE</div>
                <div className={getBoxClassName('HIGH')}>HIGH</div>
            </div>
        );
    };

    return (
        <>
            {activeDocument && (
                <div className="policy-elements-chart-container">
                    <div className="policy-elements-chart-container-header">
                        <div className="policy-elements-chart-container-header-title">Your {valueType === 'limit' ? 'Limits' : 'Excesses'}</div>
                        <ToggleSwitch isLeftActive={isLeftActive} setIsLeftActive={setIsLeftActive} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        onClick={handleInputClick}
                        id={`policy-elements-chart-id-${valueType}`} // Unique ID for each input

                        className="policy-elements-chart-filter-search"
                        style={{ cursor: filterPopupOpen ? 'pointer' : '' }}
                    />
                    {filterPopupOpen && (
                        <div className="policy-elements-chart-filter-popup" ref={filterPopupRef}>
                            <div className={`policy-elements-chart-checkbox-grid ${isLeftActive ? 'left-active' : 'right-active'}`}>
                                {filteredItems.map(item => (
                                    <label
                                        style={{ display: 'flex', alignItems: "flex-start" }}
                                        key={`${item.displayName}-${item.subDisplayName}`}>
                                        <input
                                            type="checkbox"
                                            style={{ marginTop: "6px" }}
                                            name={`${item.displayName}-${item.subDisplayName}`}
                                            checked={checkedItems[`${item.displayName}-${item.subDisplayName}`] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        {formatString(item.subDisplayName)}
                                    </label>
                                ))}
                            </div>
                            <div className="policy-elements-chart-filter-buttons">
                                <button onClick={handleClearAll} className="policy-elements-chart-clear-button">Clear All</button>
                                <button onClick={handleShowAll} className="policy-elements-chart-show-button">Show All</button>
                            </div>
                        </div>
                    )}

                    <div className="policy-elements-chart-grid"
                        style={{ gridTemplateColumns: `repeat(auto-fill, minmax(${isLeftActive ? '600px' : '450px'}, 1fr))` }}>
                        {Object.entries(policyCoverage).map(([displayName, subItems]) => (
                            Object.entries(subItems).map(([subDisplayName, subValue]) => {
                                const chartData = precomputedChartData.get(`${displayName}-${subDisplayName}`);
                                if (!chartData) return null;
                                const count = chartData.datasets[0]?.data.filter(val => val > 0).length || 0;
                                const text = `${count} / ${masterPolicies.length} or ${masterPolicies.length > 0 ? ((count / masterPolicies.length) * 100).toFixed(2) : '0.00'}% providers specify a ${valueType}.`;

                                return (
                                    subValue?.[valueType] !== undefined && subValue?.[valueType] !== null && subValue?.[valueType] !== '' && (
                                        <div
                                            className="policy-elements-chart-grid-item"
                                            key={`${displayName}-${subDisplayName}`}
                                            ref={expandedGrid === `${displayName}-${subDisplayName}` ? expandedRef : null}
                                            onClick={() => toggleGridExpansion(`${displayName}-${subDisplayName}`)}
                                            style={{ display: checkedItems[`${displayName}-${subDisplayName}`] ? 'block' : 'none' }}
                                        >
                                            <div className="policy-elements-chart-card">
                                                <div className="policy-elements-chart-card-content">
                                                    <div className="policy-elements-chart-card-content-item">
                                                        {isLeftActive && (
                                                            <SemicircularProgress
                                                                count={count}
                                                                total={masterPolicies.length}
                                                                text={text}
                                                            />
                                                        )}
                                                        <div className="policy-elements-chart-info-container">
                                                            <div className="policy-elements-chart-limit">{parseCurrency(subValue[valueType])}</div>
                                                            <div className="policy-elements-chart-sub-display-name">{formatString(subDisplayName)}</div>
                                                        </div>
                                                        <div className="policy-elements-chart-limit-container">
                                                            {renderPolicyComparison(
                                                                parseCurrencyToNumber(subValue[valueType]),
                                                                chartData.average,
                                                                count,
                                                                chartData.datasets[0].data.length
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="policy-elements-chart-card-content-item">
                                                        {expandedGrid === `${displayName}-${subDisplayName}` && (
                                                            <div className="policy-elements-chart-popup">
                                                                <Bar
                                                                    data={chartData}
                                                                    options={{
                                                                        responsive: true,
                                                                        maintainAspectRatio: false,
                                                                        scales: {
                                                                            x: { beginAtZero: true },
                                                                            y: {
                                                                                beginAtZero: true,
                                                                                suggestedMax: chartData.suggestedMax
                                                                            }
                                                                        },
                                                                        plugins: {
                                                                            legend: {
                                                                                display: false
                                                                            },
                                                                            annotation: {
                                                                                annotations: {
                                                                                    line1: {
                                                                                        type: 'line',
                                                                                        scaleID: 'y',
                                                                                        value: chartData.average,
                                                                                        borderColor: 'red',
                                                                                        borderWidth: 2,
                                                                                        borderDash: [10, 5],
                                                                                        label: {
                                                                                            content: 'Average',
                                                                                            enabled: true,
                                                                                            position: 'end',
                                                                                            yAdjust: -5,
                                                                                            font: {
                                                                                                size: 8,
                                                                                                weight: 'normal'
                                                                                            },
                                                                                            color: 'red'
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            })
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

const ToggleSwitch = ({ isLeftActive, setIsLeftActive }) => {
    const handleToggle = () => {
        setIsLeftActive(!isLeftActive);
    };

    return (
        <div className="toggle-switch" onClick={handleToggle}>
            <div className={`left-side ${isLeftActive ? 'active' : 'inactive'}`}>
                <div className="small-box"></div>
            </div>
            <div className={`right-side ${!isLeftActive ? 'active' : 'inactive'}`}>
                <div className="small-box"></div>
                <div className="small-box"></div>
            </div>
        </div>
    );
};

export default PolicyElementsChart;
