import React, { createContext, useEffect, useState } from 'react';

export const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
    const [country] = useState(process.env.REACT_APP_COUNTRY);

    return (
      <CountryContext.Provider value={{ country }}>
        {children}
      </CountryContext.Provider>
    );
  };