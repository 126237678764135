import React from 'react';
import { formatString } from '../../utils/utils';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { CountryContext } from '../../context/CountryContext';
import './TableRow.css';

const TableRow = ({
  row,
  columns,
  isEditMode,
  onRemove,
  onDrop,
  onDragOver,
  onDragStart,
  onDragEnter,
  onDragEnd,
  renderSubCoverage,
  masterPolicies,
  policyType,
  country,
}) => {
  const getCoverage = (policy, rowKey) => {
    if (!policy.coverages) return null;
  
    for (const key in policy.coverages) {
      if (policy.coverages[key] && policy.coverages[key][rowKey]) {
        return policy.coverages[key][rowKey];
      }
    }
    return null;
  };

  return (
    <div
      className={`table-row-container ${row.key ? '' : 'blank-row'}`}
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
    >
      <div className="table-row-cell-left">{formatString(row.key)}</div>
      {columns.map((col, index) => {
        let coverage = null;
        if (index === 0) {
          const policy = masterPolicies.find(
            (p) => p.type === policyType && p.country === country
          );
          if (policy) {
            coverage = getCoverage(policy, row.key);
          }
        } else if (index === 1) {
          const policy = masterPolicies[1];
          if (policy) {
            coverage = getCoverage(policy, row.key);
          }
        }
        const { content, className } = row.key ? renderSubCoverage(coverage) : { content: '', className: '' };
        return (
          <div key={index} className={`table-row-cell ${className}`}>
            {content}
          </div>
        );
      })}
      {isEditMode && (
        <div className="remove-row-button" onClick={onRemove}>
          -
        </div>
      )}
    </div>
  );
};

export default TableRow;
