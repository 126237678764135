import { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import { UserProvider } from "./context/UserContext";
import { BrowserRouter as Router } from "react-router-dom";
import { MasterPoliciesProvider } from "./context/MasterPoliciesContext";
import { CountryProvider } from "./context/CountryContext";
import { ProviderPolicyListProvider } from "./context/ProviderPolicyListContext";
import App from "./App";
import "./index.css";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


const Main = () => {

  return (
    <StrictMode>
      <UserProvider>
        <CountryProvider>
          <MasterPoliciesProvider>
            <ProviderPolicyListProvider>
              <Router scrollToTop={true}>
                <App />
              </Router>
            </ProviderPolicyListProvider>
          </MasterPoliciesProvider>
        </CountryProvider>
      </UserProvider>
    </StrictMode>
  );
};

root.render(<Main />);
