import React from 'react';
import './TextInput.css';

const TextInput = ({ label, name, value, handleChange }) => {
  return (
    <div className="textinput-group">
      <div className='textinput-group-label'>{label}</div>
      <input
        className='textinput-group-item-input'
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;
