import React, { useState, useEffect } from "react";
import './DataExport.css'; // CSS for the DataExport component

const DataExport = () => {
  const [exportHistory, setExportHistory] = useState([
    { date: '2024-08-12', file: 'data_export_20240812.csv', status: 'Completed' },
    { date: '2024-07-10', file: 'data_export_20240710.csv', status: 'Completed' },
  ]);

  useEffect(() => {
    document.title = 'Data Export';
  }, []);
  
  const handleDataExport = () => {
    alert("Your data export request has been submitted."); 
    // You can replace this with actual logic to request data export.
    // Add new export to history after request
    const newExport = { date: new Date().toISOString().slice(0, 10), file: 'data_export_' + new Date().toISOString().slice(0, 10) + '.csv', status: 'In Progress' };
    setExportHistory([newExport, ...exportHistory]);
  };

  return (
    <div className="data-export-container">
      <div className="data-export-header">
        <h2>Data Export</h2>
        <p>Request a download of your data, and view previous exports.</p>
      </div>

      <div className="data-export-section">
        <h3>Request Data Export</h3>
        <div className="data-export-info">
          <button className="data-export-button" onClick={handleDataExport}>
            Request Data Export
          </button>
        </div>
      </div>

      <div className="data-export-section">
        <h3>Export History</h3>
        <table className="data-export-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>File</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {exportHistory.map((record, index) => (
              <tr key={index}>
                <td>{record.date}</td>
                <td>
                  {record.status === 'Completed' ? (
                    <a href={`/${record.file}`} download>
                      {record.file}
                    </a>
                  ) : (
                    record.file
                  )}
                </td>
                <td>{record.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataExport;