import React, { useState, useContext, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import RcSlider from 'rc-slider'; // Importing rc-slider for a better range selection
import 'rc-slider/assets/index.css'; // Import slider styles
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useGlobalContext } from '../context/GlobalContext';
import { useCompareContext } from '../context/CompareContext';
import { MasterPoliciesContext } from '../context/MasterPoliciesContext';

import './PolicyChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PolicyChart = ({ type = 'limit', isVertical = true, maxSlide = 1000, compare = true }) => {
    const [filterRange, setFilterRange] = useState({ min: 0, max: maxSlide });
    const { masterPolicies } = useContext(MasterPoliciesContext);
    const [originalData, setOriginalData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const { activeDocument } = useGlobalContext();
    const { compareDocument } = useCompareContext();
    const [policyCoverage, setPolicyCoverage] = useState({});
    const dataKey = JSON.stringify(visibleData);
    const [showDropdown, setShowDropdown] = useState(false);
    const [visibleCount, setVisibleCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        setVisibleCount(visibleData.filter(item => item.visible).length || 0);
        setTotalCount(visibleData.length || 0);
    }, [visibleData]);

    useEffect(() => {
        setVisibleData(originalData);
    }, [originalData]);

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    // Adjust this function to apply the filter range
    const updateVisibleDataRange = () => {
        //

        if (!originalData || originalData.length < 1) return null;
        setVisibleData(originalData.map(item => {
            // Filter data points based on the range
            const filteredDataPoints = item.dataPoints.filter(value =>
                value > filterRange.max
            );
            // Log the original and filtered data points for inspection
            //
            //

            // Return the new item with filtered data points
            return {
                ...item,
                visible: filteredDataPoints.length > 0 ? false : true
            };
        }));
    };

    useEffect(() => {
        updateVisibleDataRange();
    }, [filterRange, originalData]);

    function findLargestDataPoint(tempArray) {
        let largest = Number.NEGATIVE_INFINITY; // Start with the smallest possible number

        // Loop through each item in the tempArray
        tempArray.forEach(item => {
            if (item.visible) { // Ensure we only consider items that are marked visible
                const maxInArray = Math.max(...item.dataPoints); // Get the max value in the current dataPoints array
                if (maxInArray > largest) { // If this max is larger than our current largest, update it
                    largest = maxInArray;
                }
            }
        });

        return largest; // Return the largest found value
    }

    useEffect(() => {
        if (type === 'limit') {
            setFilterRange({ min: 0, max: 10000 });
        } else {
            const max = findLargestDataPoint(originalData);
            //
            if (typeof max === 'number') {
                setFilterRange({ min: 0, max: max * 1.05 });
            } else {
                console.error("Max not a number");
            }
        }
    }, [originalData]);

    // When the user changes the range slider
    const onSliderChange = (range) => {
        setFilterRange({ min: range[0], max: range[1] });
        updateVisibleDataRange();
    };

    function formatCoverageName(coverageName) {
        // Add a space before any uppercase letters and capitalize the first letter
        const withSpaces = coverageName.replace(/([A-Z])/g, ' $1').replace(/ And /g, " & ");

        const updatedCoverageName = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

        switch (true) {
            case updatedCoverageName == 'Tempoary Accommodation':
                return 'Tempoary Accom.';
        }

        return updatedCoverageName;
    }

    useEffect(() => {
        if (activeDocument !== null && activeDocument !== undefined && activeDocument.policyProvider) {
            const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;

            if (masterPolicies && masterPolicies.values) {
                for (let entry of masterPolicies.values()) {
                    if (entry.provider === activeFileName) {
                        setPolicyCoverage(entry.coverages);
                        break;
                    }
                }
            }
        }
    }, [activeDocument]);

    const handleChange = (event, index) => {
        setVisibleData(currentData =>
            currentData.map((item, idx) => {
                if (idx === index) {
                    return { ...item, visible: event.target.checked };
                }
                return item;
            })
        );
    };

    function parseCurrency(value) {
        return Number(value.replace(/[^0-9.-]+/g, "")) || 0;
    }

    const findLimits = () => {
        setOriginalData([]);
        let tempArray = [];

        Object.entries(policyCoverage).forEach(([displayName, value]) => {
            Object.entries(value).forEach(([subDisplayName, subValue]) => {


                if (subValue?.[type] !== undefined && subValue?.[type] !== null && subValue?.[type] !== '') {

                    let data = [];
                    let labels = [];
                    for (let entry of Object.values(masterPolicies)) {
                        let limit = entry.coverages[displayName]?.[subDisplayName]?.[type];
                        if (limit !== undefined && limit !== null && limit !== '') {
                            let dataPt = parseCurrency(limit);
                            if (dataPt > 0) {
                                data.push(parseCurrency(limit));
                                labels.push(entry.provider);
                            }
                        }
                    };
                    if (data.length > 0) {
                        // Check if subDisplayName includes "liability" or "injury" and update the condition
                        //const condition = !(subDisplayName.toLowerCase().includes("liability") || subDisplayName.toLowerCase().includes("injury") || subDisplayName.toLowerCase().includes("legal") || subDisplayName.toLowerCase().includes("loss"));
                        tempArray.push({ label: formatCoverageName(subDisplayName), dataPoints: data, legendLabels: labels, visible: true });
                    }

                }

            });
        });

        //
        setOriginalData(prevLimits => [
            ...prevLimits,
            ...tempArray
        ]);

    };

    useEffect(() => {
        //
        findLimits();
    }, [policyCoverage]);

    const uniqueLegendLabels = [...new Set(visibleData.flatMap(item => item.legendLabels))];

    // Then create a dataset for each unique legend label
    const datasets = uniqueLegendLabels.map(legendLabel => {
        //
        return {
            label: legendLabel,
            data: visibleData
                .filter(item => item.visible)
                .map(item => {
                    const index = item.legendLabels.indexOf(legendLabel);
                    return index !== -1 ? item.dataPoints[index] : 0;
                }),
            backgroundColor: dynamicColor(legendLabel), // Function to assign colors
            // ... other dataset properties
        };
    });

    // Function to dynamically assign colors (customize this part as needed)
    function dynamicColor(legendLabel) {
        let colorMap = {};
        if (activeDocument && activeDocument.policyProvider !== null && activeDocument.policyProvider !== undefined) {
            if (compare) {
                colorMap = {
                    [activeDocument.policyProvider]: '#4fa658',
                    [compareDocument.policyProvider]: 'rgb(228, 156, 41)',
                };
            } else {
                colorMap = {
                    [activeDocument.policyProvider]: '#4fa658',
                };
            }
        }
        return colorMap[legendLabel] || '#cccccc'; // Default color if label not found
    }

    // Prepare your datasets and options for the chart
    const chartData = {
        labels: visibleData
            .filter(item => item.visible)
            .map(item => item.label),
        datasets: datasets
    };

    const chartOptions = {
        indexAxis: isVertical ? 'x' : 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {  // Adjusted for Chart.js v3. Conditionally change the config based on isVertical
                beginAtZero: false,
                suggestedMin: isVertical ? filterRange.min || 0 : undefined, // Apply the filter range only if vertical
                suggestedMax: isVertical ? filterRange.max || 100000 : undefined, // Apply the filter range only if vertical
                ticks: {
                    font: {
                        size: 16
                    },
                }
            },
            x: {  // Adjusted for Chart.js v3. Conditionally change the config based on isVertical
                grid: {
                    display: true
                },
                ticks: {
                    maxRotation: 90,
                    minRotation: 90,
                    font: {
                        size: 16
                    }
                },
                beginAtZero: !isVertical,
                suggestedMin: !isVertical ? filterRange.min || 0 : undefined, // Apply the filter range only if horizontal
                suggestedMax: !isVertical ? filterRange.max || maxSlide : undefined // Apply the filter range only if horizontal
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            crosshair: {
                lineDash: [5, 5], // Dotted line pattern
                lineWidth: 1,
                lineColor: 'red'
            },
        }
    };

    // Handlers for the input changes
    const handleMinChange = (event) => {
        const newMin = Number(event);
        if (newMin <= filterRange.max) { // Ensuring the new minimum does not exceed the maximum
            setFilterRange(prev => ({ ...prev, min: newMin }));
        }
    };

    const handleMaxChange = (event) => {
        const newMax = Number(event);
        if (newMax >= filterRange.min) { // Ensuring the new maximum is not below the minimum
            setFilterRange(prev => ({ ...prev, max: newMax }));
        }
    };

    return (<>
        {visibleData.length > 0 && (
            <div className="barchart-container" >
                <div className='barchart-container-header'>
                    <div className='barchart-h1'>
                        Your <span style={{ color: '#4fa658', fontWeight: 'bold' }}>{activeDocument !== undefined && activeDocument !== null ? activeDocument.policyProvider : ''}</span> policy <span style={{ color: '#4fa658', fontWeight: 'bold' }}>{type === 'excess' ? type : 'limits'}</span> ($)

                    </div>
                    <div className="barchart-dropdown">
                        <button className="limits-dropdown-button" onClick={toggleDropdown}>
                            Showing {visibleCount} of {totalCount}
                        </button>
                        {showDropdown && (
                            <div className="barchart-dropdown-content">
                                <div className="slider-container">
                                    <RcSlider
                                        range
                                        defaultValue={[filterRange.min, filterRange.max]}
                                        min={0}
                                        max={maxSlide}
                                        onAfterChange={onSliderChange}
                                    />
                                    <div className="range-inputs">
                                        <div className="input-wrapper">
                                            <div className="input-wrapper-subscript">Minimum</div>
                                            <span className="dollar-sign">$</span>
                                            <input
                                                type="text"
                                                value={filterRange.min}
                                                onChange={(e) => handleMinChange(e.target.value)}
                                                className="range-input"
                                                style={{ paddingLeft: '20px' }}
                                            />
                                        </div>
                                        <span style={{ paddingTop: '15px' }}>---</span>
                                        <div className="input-wrapper">
                                            <div className="input-wrapper-subscript">Maximum</div>
                                            <span className="dollar-sign">$</span>
                                            <input
                                                type="text"
                                                value={filterRange.max}
                                                onChange={(e) => handleMaxChange(e.target.value)}
                                                className="range-input"
                                                style={{ paddingLeft: '20px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {visibleData.map((item, index) => (
                                    <div className="checkbox-item" key={index}>
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${index}`}
                                            checked={item.visible}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                        <label htmlFor={`checkbox-${index}`}>{item.label}</label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="chart-container">
                    {visibleData !== null && visibleData !== undefined && visibleData.length > 0 && (

                        <Bar className="chart"
                            key={dataKey}
                            data={chartData}
                            options={chartOptions}
                        />
                    )}
                </div>
            </div>
        )
        }
    </>
    );
};

export default PolicyChart;
