import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProcessFlow from '../../components/workflow/ProcessFlow';
import './Wizard.css';

const Wizard = ({ onClose }) => {
  
  // Initialize the navigation hook
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Wizard';
  }, []);

  // Correct function syntax
  function handleClose() {
    if (onClose) {
      onClose();  // Call the passed onClose prop if provided
    }
    navigate('/home');  // Navigate to '/home'
  }

  return (
    <ProcessFlow
      onClose={handleClose}  // Function to close the ProcessFlow
    />
  );
};

export default Wizard;
