// Add a new function for submitting support feedback
export const submitSupportFeedback = async (email,name, topic, message) => {

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/submit-support-feedback", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email, name: name, topic: topic,message: message }),
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || "Failed to submit support feedback - please try again later.");
        }

        const data = await response.json();
        return data.message || "Support feedback submitted successfully.";
    } catch (error) {
        throw new Error(error.message || "Failed to submit support feedback - please try again later.");
    }
};
