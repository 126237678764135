import React from 'react';
import { Bar } from 'react-chartjs-2';
import './PolicyStatusGraph.css';

const statusColors = {
    'Not Started': '#D3D3D3',
    'Needs Understanding': '#C8E6C9',
    'Research': '#9ed1a0',
    'Communication': '#558657',
    'Closed': '#696969'
  };
 

const PolicyStatusGraph = ({ policies }) => {
    const statusCounts = policies.reduce((acc, policy) => {
        acc[policy.status] = (acc[policy.status] || 0) + 1;
        return acc;
    }, {});

    const orderedStatuses = ['Not Started', 'Needs Understanding', 'Research', 'Communication', 'Closed'];
    const data = {
        labels: orderedStatuses,
        datasets: [
            {
                label: 'Policy Count',
                data: orderedStatuses.map(status => statusCounts[status] || 0),
                backgroundColor: orderedStatuses.map(status => statusColors[status]),
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="bar-graph">
            <div className="policy-status-title">Progress Summary</div>
            <Bar data={data} options={options} />
        </div>
    );
};

export default PolicyStatusGraph;
