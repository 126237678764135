import React, { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import ProfileIcon, { ChangeProfileIcon } from '../../../components/profile/ProfileIcon'; // Import the new ProfileIcon component
import './MyProfile.css';

function MyProfile() {
  useEffect(() => {
      document.title = 'MyProfile';
    }, []);

  const [userContext, setUserContext] = useContext(UserContext);
  const [showName, setShowName] = useState(false);
  const [showBirthday, setShowBirthday] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [insuranceType, setInsuranceType] = useState('');
  const [receiveNewsletter, setReceiveNewsletter] = useState(true);
  const [receiveUpdates, setReceiveUpdates] = useState(true);
  const popupRef = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(1);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    document.title = 'Profile';
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowName(false);
        setShowBirthday(false);
        setShowGender(false);
        setShowEmail(false);
        setShowPhone(false);
        setShowAddress(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    if (userContext.details) {
      setFirstName(userContext.details.firstName);
      setLastName(userContext.details.lastName);
      setEmail(userContext.details.username);

    }
  }, []);

  const setName = (event) => {
    setShowName(!showName);
  };
  const setTheGender = (event) => {
    setShowGender(!showGender);
  };
  const setBirthday = (event) => {
    setShowBirthday(!showBirthday);
  };

  const togglePhone = (event) => {
    setShowPhone(!showPhone);
  };
  const toggleEmail = (event) => {
    setShowEmail(!showEmail);
  };
  const toggleAddress = (event) => {
    setShowAddress(!showAddress);
  };

  const setPicture = () => {
    setShowPopup(true);
  };

  const handleSave = () => {
    setUserContext((prevContext) => ({
      ...prevContext,
      details: {
        ...prevContext.details,
        userIcon: selectedIcon,  // Store the index of the selected icon
      },
    }));
    setShowPopup(false);

  };

  const handleCancel = () => {
    setSelectedIcon(null);
    setShowPopup(false);
  };

  return (
    <div className="profile-container">
      <div className="profile-container-header">
        <h2>Your profile</h2>
        <p>Personal info and options to manage it.</p>

      </div>
      <div className="profile">
        <div className="profile-left">
          <div className="profile-section">
            <div className="profile-header">Basic Info</div>
            <table className="profile-table">
              <tbody>
                <tr onClick={setPicture}>
                  <td style={{ 'fontWeight': '450' }}>Picture</td>
                  <td></td>
                  <td>
                  <ProfileIcon size={40} /> {/* Use the ProfileIcon component here */}
                  </td>
                </tr>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Name</td>
                  <td>{firstName} {lastName}</td>
                  <td className="profile-greater-than" onClick={setName}>&gt;</td>
                </tr>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Birthday</td>
                  <td></td>
                  <td className="profile-greater-than" onClick={setBirthday}>&gt;</td>
                </tr>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Gender</td>
                  <td></td>
                  <td className="profile-greater-than" onClick={setTheGender}>&gt;</td>
                </tr>
              </tbody>
            </table>
            {showName && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={setName}>&times;</span>
                  Update your name?
                  <input
                    className="profile-input"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                  />
                  <input
                    className="profile-input"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Last Name"
                  />
                  <button className="profile-button" onClick={setName}>Save</button>
                </div>
              </div>
            )}
            {showBirthday && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={setBirthday}>&times;</span>
                  Update your birthday?
                  < input
                    className="profile-input"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    placeholder="Date of Birth"
                  />

                  <button className="profile-button" onClick={setBirthday}>Save</button>
                </div>
              </div>
            )}
            {showGender && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={setTheGender}>&times;</span>
                  What is your gender?
                  <select value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <button className="profile-button" onClick={setTheGender}>Save</button>
                </div>
              </div>
            )}
          </div>
          <div className="profile-section">
            <div className="profile-header">Contact Info</div>
            <table className="profile-table">
              <tbody>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Email</td>
                  <td>{email}</td>
                  <td className="profile-greater-than" onClick={toggleEmail}>&gt;</td>
                </tr>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Phone</td>
                  <td>{phone}</td>
                  <td className="profile-greater-than" onClick={togglePhone}>&gt;</td>
                </tr>
                <tr>
                  <td style={{ 'fontWeight': '450' }}>Address</td>
                  <td>{address}</td>
                  <td className="profile-greater-than" onClick={toggleAddress}>&gt;</td>
                </tr>
              </tbody>
            </table>
            {showEmail && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={toggleEmail}>&times;</span>
                  Update your email
                  <input
                    className="profile-input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                  <button className="profile-button" onClick={toggleEmail}>Save</button>
                </div>
              </div>
            )}
            {showPhone && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={togglePhone}>&times;</span>
                  Update your phone number:
                  <input
                    className="profile-input"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone Number"
                  />
                  <button className="profile-button" onClick={togglePhone}>Save</button>
                </div>
              </div>
            )}
            {showAddress && (
              <div className="profile-overlay" >
                <div className="profile-popup" ref={popupRef}>
                  <span class="profile-close-button" onClick={toggleAddress}>&times;</span>

                  Update your address:
                  <textarea
                    className="profile-input"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                  />
                  <button className="profile-button" onClick={toggleAddress}>Save</button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="profile-right">
          {/* Insurance Preferences */}
          <div className="profile-section">
            <div className="profile-header">Interests</div>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Home</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Travel</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Vehicle</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Medical</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Illness</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Life</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Farm</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Professional</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Business</p>
            </label>
            {/* Add more inputs for insurance preferences */}
          </div>
          <div className="profile-section">
            <div className="profile-header">Communication</div>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveNewsletter}
                onChange={(e) => setReceiveNewsletter(e.target.checked)}
              />
              <p>Subscribe to newsletter</p>
            </label>
            <label className="profile-interest">
              <input
                type="checkbox"
                checked={receiveUpdates}
                onChange={(e) => setReceiveUpdates(e.target.checked)}
              />
              <p>Receive updates</p>
            </label>
          </div>
        </div>
      </div>
      {/* Display the popup for icon selection */}
      {
        showPopup && (
          <ChangeProfileIcon onClose={togglePopup} />  // Use ChangeProfileIcon for the popup

        )
      }

    </div >

  );
}

export default MyProfile;
