import React, { createContext, useState, useContext } from 'react';

const CollapseContext = createContext();

export const CollapseProvider = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <CollapseContext.Provider value={{ isCollapsed, setIsCollapsed }}>
            {children}
        </CollapseContext.Provider>
    );
};

export const useCollapse = () => useContext(CollapseContext);
