import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { CountryContext } from '../context/CountryContext';
import { fetchClients } from '../apis/ClientService';
import { useClient } from '../context/ClientContext';
import { useGlobalContext } from '../context/GlobalContext';
import './ActiveClient.css';

function ActiveClient({ width = '190px', menu = false }) {
    const { country } = useContext(CountryContext);
    const { activeClient, setActiveClientById } = useClient();
    const { activeDocument, setActiveDocumentName } = useGlobalContext();
    const [userContext] = useContext(UserContext);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const setChange = (selectedClientId) => {
        const selectedClient = clients.find(client => client._id === selectedClientId);
        if (selectedClient) {
            setSelectedClient(selectedClient);
        }
    };

    const handleSelectChange = (event) => {
        const selectedClientId = event.target.value;
        setChange(selectedClientId);
        setActiveClientById(selectedClientId);
    };

    const fetchClientData =  async  () => {
        const data = await fetchClients(userContext.details.username,country);
        if (data.length > 0) {
            setClients(data);
            setSelectedClient(data[0]); // Sets the first client as the selected client
            setActiveClientById(data[0]._id);
        }
    };

    useEffect(() => {
        if (userContext.details) {
            const loadClientData = async () => {
                await fetchClientData();
            };
    
            loadClientData(); // Call the async function inside useEffect
        }
    }, [userContext.details]);

    useEffect(() => {
        if (activeClient && activeClient._id) {
            setChange(activeClient._id);
        }
    }, [activeClient]);

    return (
        <>
            {(activeDocument !== null && activeDocument !== undefined) && (
                <div className="active-policy-div">
                    <select onChange={handleSelectChange} className={`policy-dropdown ${menu ? 'policy-dropdown-greystyle' : ''}`} value={selectedClient?._id || ''} style={{ width: width }}>
                        {clients && clients.length > 0 && (
                            clients.map(client => (
                                <option key={client._id} value={client._id}>
                                    {client.personalDetails.firstName} {client.personalDetails.lastName}
                                </option>
                            ))
                        )}
                    </select>
                </div>
            )}
        </>
    );
}

export default ActiveClient;
