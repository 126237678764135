import React, { useState } from 'react';
import './Tooltip.css';

const Tooltip = ({ children, text, useDoubleClick, top = '130%', left = '50%', width='150px', arrowPosition = 'none'}) => {
    const [isVisible, setIsVisible] = useState(false);

    const formattedText = text && text.split("\n").map((item, index) => (
        <React.Fragment key={index}>
            {item}
            <br />
        </React.Fragment>
    ));

    const toggleVisibility = () => setIsVisible(!isVisible);

    const eventHandlers = useDoubleClick
        ? { onDoubleClick: toggleVisibility }
        : { onMouseEnter: () => setIsVisible(true), onMouseLeave: () => setIsVisible(false) };

    return (
        <>
            <div className="tooltip-container" {...eventHandlers}>
                {children}
                {(formattedText !== null && formattedText !== undefined && formattedText !== '') && (
                    <>
                        {isVisible && (<div className={`tooltip tooltip-${arrowPosition}`} style={{top:top,left:left,width:width}} >{formattedText}</div>)}
                    </>
                )}
            </div>
        </>
    );
};

export default Tooltip;
