import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getWorkflows, getSteps, createStep, updateStep, deleteStep } from '../../apis/Workflow';
import { saveNeedsData, getNeedsData } from '../../apis/Need';
import { getProposals } from '../../apis/Proposal';
import { getQuotes } from '../../apis/Quote';
import ToggleSwitch from '../ToggleSwitch';
import { useClient } from '../../context/ClientContext';
import CollapsibleMenu from './components/CollapsibleMenu';
import NeedsSummaryTables from './components/NeedsSummaryTables';  // Import the updated component

import './ProcessFlow.css';

const ProcessFlow = ({ workflow, onClose }) => {
  const { activeClient } = useClient();
  const [steps, setSteps] = useState([]);
  const [edges, setEdges] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [totalScreens, setTotalScreens] = useState(0);
  const [existingProposals, setExistingProposals] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState('');
  const [existingQuotes, setExistingQuotes] = useState('');
  const [error, setError] = useState('');
  const [selectedQuote, setSelectedQuote] = useState('');
  const [showNeedsSummary, setShowNeedsSummary] = useState(false); // New state to toggle summary view
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(workflow || '');

  useEffect(() => {
    const fetchWorkflows = async () => {
      
      try {
        const res = await getWorkflows();
        
        setWorkflows(res.data);
        if (!selectedWorkflow && res.data.length > 0) {
          setSelectedWorkflow(res.data[0]._id);  // Set default workflow
        }
      } catch (err) {
        console.error('Error fetching workflows:', err);
      }
    };

    fetchWorkflows();
  }, [selectedWorkflow]);

  useEffect(() => {
    const fetchSteps = async () => {
      
      if (!selectedWorkflow) {
        setSteps([]);
        return;
      }
      try {
        const res = await getSteps(selectedWorkflow);
        
        setSteps(res.data.steps || []);
      } catch (err) {
        console.error('Error fetching steps:', err);
        setSteps([]);
      }
    };

    fetchSteps();
  }, [selectedWorkflow]);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const res = await getProposals(activeClient._id);
        setExistingProposals(res.data);
      } catch (err) {
        console.error('Error fetching proposals:', err);
        setError('Failed to load proposals. Please try again later.');
      }
    };

    if (activeClient && activeClient._id) {
      fetchProposals();
    } else {
      setError('Active client not found.');
    }
  }, [activeClient]);

  useEffect(() => {
    if (selectedProposal) {
      const fetchQuotes = async () => {
        try {
          const res = await getQuotes(activeClient._id, selectedProposal);
          setExistingQuotes(res.data);
        } catch (err) {
          console.error('Error fetching quotes:', err);
          setError('Failed to load quotes. Please try again later.');
        }
      };

      fetchQuotes();
    }
  }, [activeClient, selectedProposal]);

  useEffect(() => {
    const fetchWorkflowAndNeeds = async () => {
      if (!selectedQuote) return;

      try {
        const res = await getNeedsData(activeClient._id, selectedProposal, selectedQuote);
        const needsData = res.data[0];

        if (!needsData || !needsData.workflowId) {
          setError('No associated needs data or workflow found.');
          return;
        }

        // Organize selectedOptions by groupId and questionId for easier access
        const organizedOptions = {};
        for (const groupKey in needsData.data) {
          organizedOptions[groupKey] = {};
          for (const questionKey in needsData.data[groupKey]) {
            organizedOptions[groupKey][questionKey] = needsData.data[groupKey][questionKey];
          }
        }

        setSelectedOptions(organizedOptions);

        // Fetch workflow steps based on the associated workflow in the Needs data
        const workflowSteps = await getSteps(needsData.workflowId._id);

        if (workflowSteps.data && workflowSteps.data.steps) {
          setSteps(workflowSteps.data.steps);
          setEdges(workflowSteps.data.edges);
        } else {
          setSteps([]);
          setEdges([]);
          setError('Workflow steps not found.');
        }
      } catch (err) {
        console.error('Error fetching workflow or needs data:', err);
        setError('Failed to load workflow or needs data. Please try again later.');
      }
    };

    fetchWorkflowAndNeeds();
  }, [activeClient, selectedProposal, selectedQuote, selectedWorkflow]);

  useEffect(() => {
    const calculateTotalScreens = () => {
      if (!Array.isArray(steps)) return;

      const groupSteps = steps.filter((step) => step.type === 'group');
      let screens = 0;
      groupSteps.forEach(group => {
        const subSteps = steps.filter((step) => step.parentId === group.id);
        screens += Math.ceil(subSteps.length / 5);
      });
      setTotalScreens(screens);
    };

    calculateTotalScreens();
  }, [steps]);

  const handleNext = () => {
    const groupSteps = steps.filter((step) => step.type === 'group');
    const currentGroupStep = groupSteps[currentGroupIndex];
    const subSteps = currentGroupStep ? steps.filter((step) => step.parentId === currentGroupStep.id) : [];
    const maxPageIndex = Math.ceil(subSteps.length / 5) - 1;

    if (currentPageIndex < maxPageIndex) {
      setCurrentPageIndex(currentPageIndex + 1);
    } else if (currentGroupIndex < groupSteps.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
      setCurrentPageIndex(0);
    } else {
      setShowNeedsSummary(true); // Show summary instead of closing
    }
  };


  const handleDone = () => {
    onClose();  // Handle the done action, usually closing the modal or screen
  };

  const handleBack = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    } else if (currentGroupIndex > 0) {
      const groupSteps = steps.filter((step) => step.type === 'group');
      const currentGroupStep = groupSteps[currentGroupIndex - 1];
      const subSteps = currentGroupStep ? steps.filter((step) => step.parentId === currentGroupStep.id) : [];
      setCurrentGroupIndex(currentGroupIndex - 1);
      setCurrentPageIndex(Math.ceil(subSteps.length / 5) - 1);
    }
  };

  const handleOptionClick = (groupId, stepId, value) => {
   
    setSelectedOptions(prev => {
        // Clone the previous state to avoid direct mutation
        const newState = { ...prev };
        
        // Find the group with the given groupId
        const groupIndex = Object.keys(newState).findIndex(key => newState[key].id === groupId);
        
        if (groupIndex !== -1) {
            const group = newState[groupIndex];
            
            // Find the specific question within the group's questions array
            const questionIndex = group.questions.findIndex(q => q.id === stepId);
            
            if (questionIndex !== -1) {
                // Update the value of the found question
                newState[groupIndex].questions[questionIndex].value = value;
            } else {
                
            }
        } else {
            
        }

        return newState;
    });
};

  const handleAddressSearch = async (value, country) => {
    const url = country === 'NZ' ? `https://api-nz.addysolutions.com/search?s=${value}` : `https://api-au.addysolutions.com/search?s=${value}`;
    const key = country === 'NZ' ? '32de8f505c014a048bb694fab7543ca8' : 'd130b6e06cd4430a84d50504719c0951';
    try {
      const response = await axios.get(url, {
        headers: {
          'Accept': 'application/json',
          'addy-api-key': key
        }
      });
      if (response.data.addresses && response.data.addresses.length > 0) {
        setAddressSuggestions(response.data.addresses);
      } else {
        setAddressSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching address data:', error);
      setError('Failed to fetch address suggestions.');
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedOptions(prevDetails => ({
      ...prevDetails,
      policyAddress: suggestion
    }));
    setAddressSuggestions([]);
  };

  const toSnakeCase = (str) => {
    return str
      .replace(/\s+/g, '_')
      .toLowerCase();
  };

  const toGroupId = (label) => {
    return label.toLowerCase().replace(/\s+/g, '_') + '_group';
  };

  const getValueById = (questionData, id) => {
    if (!questionData || !questionData.category || !Array.isArray(questionData.category.questions)) {
      console.error('Invalid question data structure.');
      return '';
    }

    const question = questionData.category.questions.find(q => q.id === id);
    return question ? question.value : '';
  };
  const saveNeeds = async () => {
    try {
      await saveNeedsData(activeClient._id, selectedProposal, selectedQuote, selectedWorkflow, selectedOptions);
      alert('Needs saved successfully');
    } catch (err) {
      console.error('Error saving needs:', err);
      setError('Failed to save needs data. Please try again later.');
    }
  };
  const handleGroupNavigation = (groupIndex) => {
    setCurrentGroupIndex(groupIndex);
    setCurrentPageIndex(0);  // Reset to the first page of the selected group
  };

  const findValueInSelectedOptions = (selectedOptions, categoryLabel, questionLabel) => {
    // Convert question label to its corresponding question ID
    const questionId = toSnakeCase(questionLabel);

    // Find the corresponding questionData
    const questionData = Object.values(selectedOptions).find(option =>
        option?.id === toGroupId(categoryLabel)
    );

    // Check if questionData is undefined before proceeding
    if (!questionData) {
        
        return '';
    }

    // Find the specific question within the questions array
    const question = Object.values(questionData.questions).find(question =>
        question?.id === questionId
    );

    // Check if question is undefined before trying to access the value
    if (!question) {
        
        return '';
    }

    // Return the value if it exists, otherwise return an empty string
    const value = question.value || '';
    
    

    return value;
};



  const calculateGroupIndicators = (groupStep) => {
    const subSteps = steps.filter((step) => step.parentId === groupStep.id);
    let requiredFields = 0;
    let filledFields = 0;
    subSteps.forEach(subStep => {
      if (subStep.data.validation?.includes('required')) {
        requiredFields += 1;
        const value = findValueInSelectedOptions(selectedOptions, groupStep.data.label, subStep.data.label);
        if (value) {
          filledFields += 1;
        }
      }
    });
    return { requiredFields, filledFields };
  };

  const groupSteps = steps ? steps.filter((step) => step.type === 'group') : [];
  const currentGroupStep = groupSteps[currentGroupIndex];
  const subSteps = currentGroupStep ? steps.filter((step) => step.parentId === currentGroupStep.id) : [];
  const currentSubSteps = subSteps.slice(currentPageIndex * 5, (currentPageIndex + 1) * 5);
  const groupIndicators = groupSteps.map(groupStep => calculateGroupIndicators(groupStep));





  // Example usage inside renderInput function
  const renderInput = (subStep, groupId) => {
    const groupLabel = groupId.data.label; // Assuming the group label is stored here
    const questionLabel = subStep.data.label; // Assuming the question label is stored here

    const value = findValueInSelectedOptions(selectedOptions, groupLabel, questionLabel);
    

    switch (subStep?.data?.inputType) {
      case 'text':
        return (
          <input
            type="text"
            className='process-flow-input-text'
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}

          />
        );
      case 'number':
        return (
          <input
            type="number"
            className='process-flow-input-number'
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          />
        );
      case 'date':
        return (
          <input
            type={subStep.data.inputType}
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          />
        );
      case 'textarea':
        return (
          <textarea
            className='process-flow-input-text'
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          ></textarea>
        );
      case 'dropdown':
        return (
          <select
            className='process-flow-input-dropdown'
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          >
            {subStep.data.dataSeries?.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        );
      case 'boolean':
        return (
          <ToggleSwitch
            labels={['NO', 'YES']}
            checked={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          />
        );
      case 'checkbox':
        return (
          <div className="process-flow-input-checkbox-component">
            {subStep.data.dataSeries?.map((option, index) => (
              <div key={index} className="process-flow-input-checkbox-dot" onClick={() => 
                handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), option)}>
                <div className="process-flow-input-checkbox-dot-label">{option}</div>
                <div className={`process-flow-input-checkbox-dot-label-large ${value.includes(option) ? 'process-flow-input-checkbox-dot-selected' : ''}`}>
                  <div className={`process-flow-input-checkbox-dot-label-small ${value.includes(option) ? 'process-flow-input-checkbox-dot-small-selected' : ''}`} />
                </div>
              </div>
            ))}
          </div>
        );
      case 'address':
        return (
          <div className="process-flow-address">
            <input
              type="text"
              className='process-flow-input-text'
              required={subStep.data.validation?.includes('required') || false}
              value={value}
              onChange={(e) => handleAddressSearch(e.target.value, 'NZ')}
              onBlur={() => setAddressSuggestions([])}
            />
            {addressSuggestions.length > 0 && (
              <ul className="process-flow-address-suggestions">
                {addressSuggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion.a)}>
                    {suggestion.a}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      default:
        return (
          <input
            type="text"
            className='process-flow-input-text'
            required={subStep.data.validation?.includes('required') || false}
            value={value}
            onChange={(e) => handleOptionClick(toGroupId(groupId.data.label), toSnakeCase(subStep.data.label), e.target.value)}
          />
        );
    }
  };

  const completedScreens = currentGroupIndex * Math.ceil(subSteps.length / 5) + currentPageIndex + 1;
  const progress = (completedScreens / totalScreens) * 100;

  return (
    <div className={`process-flow ${currentGroupStep ? 'process-flow-active' : ''}`}>
      <div className="process-flow-group-step">
        <div className="process-flow-group-step-header">
          <div className="process-flow-company-name">
            <span style={{ color: 'rgb(79,166,88)' }}>POLICY</span>
            <span style={{ color: 'white' }}>CHECK</span>
          </div>
          <div className="process-flow-progress-bar-container">
            <div className="process-flow-progress-bar">
              <div
                className="process-flow-progress"
                style={{ width: `${Math.round((completedScreens / totalScreens) * 100)}%` }}
              >
                <span className="process-flow-progress-percentage">{`${Math.round((completedScreens / totalScreens) * 100)}%`}</span>
              </div>
            </div>
          </div>
          <button className="process-flow-close-button" onClick={onClose}>&times;</button>
        </div>
        {error && <div className="process-flow-error">{error}</div>}
        <div className="process-flow-container">

          <CollapsibleMenu
            existingProposals={existingProposals}
            selectedProposal={selectedProposal}
            onSelectProposal={setSelectedProposal}
            existingQuotes={existingQuotes}
            selectedQuote={selectedQuote}
            onSelectQuote={setSelectedQuote}
            onStartFromScratch={() => setSelectedOptions({})}
            onSaveNeeds={saveNeeds}
            isSaveDisabled={!selectedQuote}
            groupSteps={groupSteps}
            groupIndicators={groupIndicators}
            onGroupSelect={handleGroupNavigation}
          />
          <div className="process-flow-content">
            <div className="process-flow-sub-header">
              <h2>{currentGroupStep?.data?.label} {currentPageIndex > 0 ? "(cont'd)" : ""}</h2>
            </div>
            {showNeedsSummary ? (
              <NeedsSummaryTables selectedOptions={selectedOptions} onDone={handleDone} />
            ) : (
              <>
                <div className="process-flow-sub-steps">
                  {currentSubSteps.map((subStep) => (
                    <div key={subStep.id} className="process-flow-sub-step">
                      <label className='process-flow-input-label'>{subStep.data.label}</label>
                      {renderInput(subStep, currentGroupStep)}
                    </div>
                  ))}
                </div>
                <div className="process-flow-navigation-buttons">
                  {currentGroupIndex > 0 || currentPageIndex > 0 ? (
                    <button onClick={handleBack}>Back</button>
                  ) : null}
                  <button onClick={handleNext}>
                    {currentGroupIndex === groupSteps.length - 1 && currentPageIndex === Math.ceil(subSteps.length / 5) - 1 ? 'Done' : 'Next'}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessFlow;
