import React from 'react';
import { Bar } from 'react-chartjs-2';
import './EngagementMetricsChart.css';

const EngagementMetricsChart = ({ engagementData }) => {
  const data = {
    labels: engagementData.map(data => data.metric),
    datasets: [{
      label: 'Engagement Metrics',
      data: engagementData.map(data => data.value),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="engagement-metrics-chart">
      <Bar data={data} options={options} />
    </div>
  );
};

export default EngagementMetricsChart;
