import React, { useEffect, useContext, useState } from 'react';
import { useCollapse } from '../../context/CollapseContext';
import { ProviderPolicyListContext } from '../../context/ProviderPolicyListContext';
import './ProviderPoliciesTable.css';

function ProviderPoliciesTable() {
    useEffect(() => {
        document.title = 'ProviderPoliciesTable';
      }, []);
    const {providerPolicies} = useContext(ProviderPolicyListContext);
    const [policies, setPolicies] = useState(providerPolicies || []);

    const [policiesCount, setPoliciesCount] = useState(0);    
    const [numberOfProviders, setNumberOfProviders] = useState(0);
    const [tableData, setTableData] = useState({});
    const { isCollapsed, setIsCollapsed } = useCollapse();

    useEffect(() => {
        setPolicies(providerPolicies);
    },[providerPolicies]);

    // Transform data for table
    useEffect(() => {
        const data = {};
        policies.forEach(policy => {
            if (!data[policy.provider_name]) {
                data[policy.provider_name] = {};
            }
            if (['Home', 'Motor', 'Contents', 'Landlord', 'ISR','Travel'].includes(policy.category)) {
                data[policy.provider_name][policy.category] = true;
            }
        });
        setTableData(data);
        setPoliciesCount(policies.length);
        setNumberOfProviders(Object.keys(data).length);

    }, [policies]);

    // Get filtered unique categories for table columns
    const categories = ['Home', 'Motor', 'Contents', 'Landlord', 'ISR','Travel'];

    return (
        <div className="provider-policy-page" style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
            <div className='provider-policy-title'>General Insurance Products</div>
            <div className="provider-policy-page-container" >
                <div className='provider-policy-page-container-header' >                
                   <div className='provider-policy-title-sub'>Coverage includes infromation support for the following products:</div>
                   <div className='provider-policy-title-sub'><span style={{fontWeight:'bold'}}>{numberOfProviders}</span> providers, <span style={{fontWeight:'bold'}}>{policiesCount}</span> policies.</div>
                </div>

                <table className='provider-policy-policy-table'>
                    <thead>
                        <tr>
                            <th>Provider / Product</th>
                            {categories.map(category => <th key={category}>{category}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableData)
                            .sort()  
                            .map(provider => (
                                <tr key={provider}>
                                    <td>{provider}</td>
                                    {categories.map(category => (
                                        <td key={category}>
                                            <div className={tableData[provider][category] ? "provider-policy-included-box" : ""}>
                                                {tableData[provider][category] ? "✓" : ""}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProviderPoliciesTable;
