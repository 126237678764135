import React, { createContext, useState, useContext } from 'react';

const CompareContext = createContext();

export const useCompareContext = () => useContext(CompareContext);

export const CompareProvider = ({ children }) => {
  const [compareDocument, setCompareDocument] = useState('');

  return (
    <CompareContext.Provider value={{ compareDocument, setCompareDocument }}>
      {children}
    </CompareContext.Provider>
  );
};
