import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the datalabels plugin
);

const StrengthsBarChart = ({ coverageKey, masterPolicies }) => {
  const keyParts = coverageKey.split('_');
  const [trueCount, falseCount] = masterPolicies.reduce(
    ([trueAcc, falseAcc], policy) => {
      const included = policy.coverages?.[keyParts[0]]?.[keyParts[1]]?.included;
      if (included) {
        return [trueAcc + 1, falseAcc];
      } else {
        return [trueAcc, falseAcc + 1];
      }
    },
    [0, 0]
  );

  const totalCount = trueCount + falseCount;
  const truePercentage = ((trueCount / totalCount) * 100).toFixed(0);
  const falsePercentage = ((falseCount / totalCount) * 100).toFixed(0);

  const data = {
    labels: ['Policies'],
    datasets: [
      {
        label: 'Included',
        data: [trueCount],
        backgroundColor: 'rgb(79, 166, 88)',
        stack: 'Stack 0',
      },
      {
        label: 'Not Included',
        data: [falseCount],
        backgroundColor: 'lightgrey',
        stack: 'Stack 0',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        color: 'white',
        anchor: 'center',
        align: 'center',
        font: {
          size: 16, // Set the font size
          weight: 'bold', // Set the font weight
        },
        formatter: (value, context) => {
          const datasetIndex = context.datasetIndex;
          return datasetIndex === 0 ? `${truePercentage}%` : `${falsePercentage}%`;
        },
      },
    },
  };

  return (
    <div style={{ height: '50px', width: '100%', position: 'relative' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default StrengthsBarChart;
