import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useGlobalContext } from '../../context/GlobalContext';
import { formatString } from '../../utils/utils';
import './BarChartWithDropdown.css'; // Updated CSS path

const BarChartWithDropdown = ({ data }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const tooltipRef = useRef(null); // Reference for the tooltip
    const { activeDocument } = useGlobalContext();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [showDatasetDropdown, setShowDatasetDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const colors = [
        '#4B8B3B', // Muted dark green
        '#2D6A4F', // Forest green
        '#3A6351', // Olive green
        '#5B9279', // Sage green
        '#406D5C', // Teal green
        '#3C6E71', // Slate green
        '#627C85', // Steel grey-green
        '#4E7373', // Muted turquoise
    ];

    // Function to count `included: true` in nested structures
    const countIncludedTrue = (obj) => {
        if (typeof obj !== 'object' || obj === null) return 0;
        return Object.entries(obj).reduce((count, [key, subItem]) => {
            if (subItem && typeof subItem === 'object' && 'included' in subItem && subItem.included === true) {
                count++;
            }
            return count + countIncludedTrue(subItem);
        }, 0);
    };

    // Set default selection of all categories and first two datasets
    useEffect(() => {
        if (data.length > 0) {
            const categories = Object.keys(data[0].coverages);
            setSelectedCategories(categories); // Select all categories by default

            const initialDatasets = data.slice(0, 2).map((item, index) => ({
                provider: item.provider,
                color: colors[index % colors.length]
            }));
            setSelectedDatasets(initialDatasets); // Select the first two datasets by default
        }
    }, [data]);

    // Initialize the tooltip
    useEffect(() => {
        if (!tooltipRef.current) {
            tooltipRef.current = d3.select('body').append('div')
                .attr('class', 'bar-chart-tooltip')
                .style('opacity', 0)
                .style('position', 'absolute')
                .style('background-color', 'white')
                .style('border', '1px solid #ddd')
                .style('padding', '8px')
                .style('border-radius', '4px')
                .style('pointer-events', 'none'); // Ensures the tooltip doesn't interfere with mouse events
        }
    }, []);

    const drawBarChart = () => {
        const svg = d3.select(chartRef.current);
        svg.selectAll("*").remove(); // Clear any existing content

        const margin = { top: 50, right: 50, bottom: 50, left: 150 }; // Increased margins for padding
        const width = 600 - margin.left - margin.right; // Set width to 600px
        const height = 700 - margin.top - margin.bottom; // Increase height to roughly 700px

        const x = d3.scaleLinear().range([0, width]);
        const y = d3.scaleBand().range([0, height]).padding(0.3); // Increase padding to add more spacing between bars

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`); // Apply margin

        // Prepare the data for the chart
        const chartData = selectedCategories.map(category => {
            const values = selectedDatasets.map(dataset => {
                const coverage = data.find(d => d.provider === dataset.provider)?.coverages[category];
                // Use countIncludedTrue instead of d.included ? 1 : 0
                return coverage ? countIncludedTrue(coverage) : 0;
            });
            return { category, values };
        });

        // Set the domains for x and y axes
        x.domain([0, d3.max(chartData, d => d3.max(d.values))]);
        y.domain(chartData.map(d => formatString(d.category))); // Apply formatString to Y-axis labels

        // Append the bars
        chartData.forEach((d, i) => {
            d.values.forEach((value, j) => {
                // Calculate height, ensuring it is positive and valid
                const calculatedHeight = y.bandwidth() / selectedDatasets.length - 5;
                const rectHeight = calculatedHeight > 0 ? calculatedHeight : 1; // Set a minimum height of 1 to avoid negative or zero values

                chart.append('rect')
                    .attr('x', x(0))
                    .attr('y', y(formatString(d.category)) + j * rectHeight) // Apply formatString to the Y position
                    .attr('width', x(value))
                    .attr('height', rectHeight)
                    .attr('fill', colors[j])
                    .attr('class', 'bar-chart-with-dropdown-rect')
                    .on("mouseover", function (event, d) {
                        const [mouseX, mouseY] = d3.pointer(event);
                        tooltipRef.current.transition().duration(200).style("opacity", 0.9);
                        tooltipRef.current.html(`<strong>Provider:</strong> ${selectedDatasets[j].provider}<br><strong>Value:</strong> ${value}`)
                            .style("left", `${event.pageX + 10}px`)
                            .style("top", `${event.pageY - 30}px`);
                    })
                    .on("mouseout", () => {
                        tooltipRef.current.transition().duration(500).style("opacity", 0);
                    });
            });
        });

        // Add x-axis
        chart.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .attr('class', 'bar-chart-with-dropdown-axis')
            .selectAll("text")
            .style("font-size", "14px"); // Increase font size of X-axis labels


        function wrapText(text) {
            text.each(function () {
                const textElement = d3.select(this);
                const words = textElement.text().split(/\s+/).reverse();  // Split text into words
                let word;
                let line = [];
                let lineNumber = 0;
                const lineHeight = 1.1; // Adjust line height
                const y = textElement.attr("y");
                const dy = parseFloat(textElement.attr("dy")) || 0;
                let tspan = textElement.text(null).append("tspan").attr("x", -15)  // Add padding of 15px to the right
                    .attr("y", y).attr("dy", `${dy}em`);

                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    const tspanNode = tspan.node();
                    if (tspanNode && tspanNode.getComputedTextLength() > margin.left - 10) {  // If the line is too long, move it to the next line
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = textElement.append("tspan").attr("x", -15)  // Ensure the same padding for subsequent lines
                            .attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word);
                    }
                }
            });
        }

        // Add y-axis
        chart.append('g')
            .call(d3.axisLeft(y))
            .attr('class', 'bar-chart-with-dropdown-axis')
            .selectAll("text")
            .style("font-size", "14px")
            .call(wrapText, margin.left);  // Call the wrapText function to handle word wrapping
    };

    // Redraw the chart when the selected datasets or categories change
    useEffect(() => {
        if (selectedCategories.length > 0 && selectedDatasets.length > 0) {
            drawBarChart();
        }
    }, [selectedCategories, selectedDatasets]);

    const handleCheckboxChange = (category) => {
        setSelectedCategories(prev => prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]);
    };

    const handleDatasetChange = (provider) => {
        const dataset = data.find(d => d.provider === provider);
        if (selectedDatasets.some(ds => ds.provider === provider)) {
            setSelectedDatasets(prev => prev.filter(ds => ds.provider !== provider));
        } else {
            setSelectedDatasets([...selectedDatasets, { provider, color: colors[selectedDatasets.length % colors.length] }]);
        }
    };

    const selectAllCategories = () => {
        setSelectedCategories(Object.keys(data[0]?.coverages));

    };

    const clearAllCategories = () => {
        setSelectedCategories([]);
    };

    const toggleCategoryDropdown = () => {
        setShowDatasetDropdown(false);
        setShowCategoryDropdown(!showCategoryDropdown);
    };

    const toggleDatasetDropdown = () => {
        setShowCategoryDropdown(false);
        setShowDatasetDropdown(!showDatasetDropdown);
    };

    const selectAllDatasets = () => {
        const allProviders = data.map(dataset => dataset.provider);
        setSelectedDatasets(allProviders.map((provider, index) => ({ provider, color: colors[index % colors.length] })));
    };

    const clearAllDatasets = () => {
        setSelectedDatasets([]);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside both dropdowns
            if (
                headerRef.current &&
                !headerRef.current.contains(event.target)
            ) {
                setShowDatasetDropdown(false);
                setShowCategoryDropdown(false);
            }
        };

        // Add the event listener when dropdown is open
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatasetDropdown, showCategoryDropdown]); // Re-run if either dropdown state changes

    return (
        <div className="spider-container" ref={containerRef}>
            <div className='spider-container-title' ref={headerRef}>
                <div className="spider-container-header">
                    <span style={{ color: '#4fa658', fontWeight: 'bold' }}>{activeDocument !== undefined && activeDocument !== null ? `${activeDocument.policyProvider}` : ''}</span>&nbsp;coverage vs. other providers
                </div>
                <div className="spider-container-header">
                    <button className="dropdown-toggle-dataset" onClick={toggleCategoryDropdown}>
                        Select Categories
                    </button>
                    <button className="dropdown-toggle-dataset" onClick={toggleDatasetDropdown}>
                        Select Datasets
                    </button>
                </div>
                {showCategoryDropdown && (
                    <div className="spider-dropdown-menu">
                        <div className="spider-dropdown-menu-list">
                            {Object.keys(data[0]?.coverages || {}).map((category, index) => (
                                <div className="spider-dropdown-menu-item" key={index}
                                    onClick={() => handleCheckboxChange(category)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCheckboxChange(category)}
                                    />
                                    <label>{formatString(category)}</label> {/* Apply formatString here */}
                                </div>
                            ))}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                        <button onClick={selectAllCategories}>Select All</button>
                        <button onClick={clearAllCategories}>Clear All</button>

                    </div>
                )}
                {showDatasetDropdown && (
                    <div className="spider-dropdown-menu">
                        <div className="spider-dropdown-menu-list">
                            {data.map((dataset, index) => (
                                <div className="spider-dropdown-menu-item" key={index}
                                    onClick={() => handleDatasetChange(dataset.provider)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedDatasets.some(item => item.provider === dataset.provider)}
                                        onChange={() => handleDatasetChange(dataset.provider)}
                                    />
                                    <label>{dataset.provider}</label>
                                </div>
                            ))}
                        </div>
                        <button onClick={selectAllDatasets}>Select All</button>
                        <button onClick={clearAllDatasets}>Clear All</button>
                    </div>
                )}
            </div>

            <svg ref={chartRef} className="bar-chart-with-dropdown-svg" width={700} height={700}></svg> {/* Adjust SVG width to 700px and height to 700px */}
        </div>
    );
};

export default BarChartWithDropdown;
