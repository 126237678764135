import React from 'react';
import ManualURL from "../../../icons/fileUpload.png";
import UploadURL from "../../../icons/docUpload.png";

const Objectives = ({ onNext }) => {
    return (
        <div className="upload-container-row">
            <div className="upload-container-column">
                <div className="upload-container-option-header">
                    <div className="upload-container-option-header-title-left">Check your <span style={{ color: 'rgb(79,166,88)' }}>insurance</span> policy</div>
                    <div className="upload-container-option-header-content">Get started now to see your options including price and coverage.  </div>
                    <br />
                    <div className="upload-container-option-header-content">It’ll only take 5 minutes. </div>
                </div>
            </div>
            <div className="upload-container-column-options">
                <div className="upload-container-option" onClick={onNext}>
                    <img src={UploadURL} style={{ width: '55px' }} alt="" />
                    <div className="upload-container-option-title">
                        Upload an existing policy
                    </div>
                    <div className="upload-container-option-next">&gt;</div>
                </div>
                <div className="upload-container-option" onClick={onNext}>
                    <img src={ManualURL} style={{ width: '55px' }} alt="" />
                    <div className="upload-container-option-title">
                        I don't have a policy
                    </div>
                    <div className="upload-container-option-next">&gt;</div>
                </div>
            </div>
        </div>
    );
};

export default Objectives;
