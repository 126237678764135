import React, { useEffect, useContext, useState, useRef } from 'react';
import { usePolicyTypes } from '../../context/PolicyTypesContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { CountryContext } from '../../context/CountryContext';
import { FiltersContext } from '../../context/FiltersContext';
import PoliciesTable from '../../components/PoliciesTable';
import PoliciesTableBasic from '../../components/PoliciesTableBasic'
import PolicyTableBasicLegend from '../../components/compare/PolicyTableBasicLegend'
import { useCollapse } from '../../context/CollapseContext';
import Conversation from "../../components/chatbot/Conversation";
import ToggleButtons from "../../components/ToggleButtons";
import SearchInput from "../../components/SearchInput";
import SettingsIconURL from "../../icons/settings_grey.png";
import SearchButtonDropDown from '../../components/SearchButtonDropDown';
import './Research.css';

function Research() {
  useEffect(() => {
    document.title = 'Research';
  }, []);
  const { country } = useContext(CountryContext);
  const { policyType, setPolicyType } = useContext(PolicyTypeContext);
  const { isCollapsed } = useCollapse();
  const options = ['Snapshot', 'Highlights', 'Detailed', 'AI']
  const [activeOption, setActiveOption] = useState('Highlights');
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { globalFilters, setGlobalFilters } = useContext(FiltersContext);

  const popupRef = useRef(null);
  const filterDropdownRef = useRef(null);

  const handlePolicyFilter = () => {
    setShowFilterDropdown(!showFilterDropdown);
    setPopupVisible(false); // Ensure popup is hidden when filter is toggled
  };

  const handlePolicyTypeChange = (type) => {
    setPolicyType(type);
    setPopupVisible(false); // Ensure popup is hidden when a policy type is selected

  };

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
    setShowFilterDropdown(false); // Ensure filter dropdown is hidden when popup is toggled
  };

  const countCheckedItems = (globalFilters) => {
    let countChecked = 0;  // Counter for checked items
    let countUnchecked = 0; // Counter for unchecked items

    globalFilters.forEach(filter => {
      if (filter.checked) {
        countChecked++;
      } else {
        countUnchecked++;
      }
    });

    return { countChecked, countUnchecked };
  };

  const DisplayCheckedInfo = () => {
    const { countChecked, countUnchecked } = countCheckedItems(globalFilters);
    const totalCount = countChecked + countUnchecked;

    return (
      <div>Showing <span><strong>{countChecked}</strong></span> out of <span><strong>{totalCount}</strong></span></div>
    );
  };

  // Close all pop-ups if clicking outside the pop-ups
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the pop-up and the dropdown
      if (
        popupRef.current && !popupRef.current.contains(event.target) &&
        filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)
      ) {
        setPopupVisible(false);
        setShowFilterDropdown(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Sort the policy types alphabetically within each category and by enabled first, then disabled
  const sortedPolicyTypes = [...usePolicyTypes()].sort((a, b) => {
    // First, maintain the order of categories
    const categoryOrder = ['Personal', 'Business', 'Motor', 'Construction', 'Professional', 'Property', 'Specialty'];
    const categoryAIndex = categoryOrder.indexOf(a.category);
    const categoryBIndex = categoryOrder.indexOf(b.category);

    // If the categories are the same, then sort by enabled status and label
    if (categoryAIndex === categoryBIndex) {
      if (a.enabled === b.enabled) {
        return a.label.localeCompare(b.label);
      }
      return b.enabled - a.enabled; // Prioritize enabled ones first
    }

    // Otherwise, sort by category order
    return categoryAIndex - categoryBIndex;
  });

  return (
    <div className="compare-container">
      <h2>Research</h2>
      <div className="research-container">
        <div className="compare-container-header">
          <div className="policy-header">
            {/* <ToggleButtons options={options} defaultActive={activeOption} onActiveChange={handleActiveChange} /> */}
            <PolicyTableBasicLegend
              sortedPolicyTypes={sortedPolicyTypes}
              dynamicPolicyType={policyType}
              handlePolicyTypeChange={handlePolicyTypeChange}
              isPopupVisible={isPopupVisible}
              togglePopup={togglePopup}
              popupRef={popupRef} // Reference to the pop-up container
            />
            <div className="policy-table-basic-legend" ref={filterDropdownRef}>
              <button className="insurance-table-custom-filter" onClick={handlePolicyFilter}>
                <DisplayCheckedInfo />
                <img src={SettingsIconURL} alt='' style={{ width: '20px', paddingLeft: '15px' }} />
              </button>
              {showFilterDropdown && (
                <SearchButtonDropDown onClose={handlePolicyFilter} top={'55px'} left={'0px'} width={'800px'} />
              )}
            </div>
          </div>
        </div>
        <PoliciesTable />
        {/* {activeOption === 'Snapshot' ? (
            <div className='compare-search-input-container'>
              <div className='compare-search-input-80'>
                <SearchInput />
              </div>
            </div>
          ) : activeOption === 'Highlights' ? (
            <>
              <PoliciesTableBasic />
            </>
          ) : activeOption === 'Detailed' ? (
            <>
              <PoliciesTable />
            </>
          ) : (
            <>
              <Conversation />
            </>
          )} */}
      </div>
    </div>
  );
}

export default Research;
