import React, { useState } from 'react';
import './SocialIcons.css';
import twitter_1 from '../../images/social/twitter_1.png';
import twitter_2 from '../../images/social/twitter_2.png';
import facebook_1 from '../../images/social/facebook_1.png';
import facebook_2 from '../../images/social/facebook_2.png';
import linkedin_1 from '../../images/social/linkedin_1.png';
import linkedin_2 from '../../images/social/linkedin_2.png';

const SocialIcons = ({ twitterLink, facebookLink, linkedinLink, disabled = false }) => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleClick = (pageURL) => {
    if (!disabled) {
      window.open(pageURL, "_blank");
    }
  };

  const handleHover = (buttonIndex) => {
    if (!disabled) {
      setHoveredButton(buttonIndex);
    }
  };

  const handleLeave = () => {
    setHoveredButton(null);
  };

  return (
    <div className={`social-icons ${disabled ? 'disabled' : ''}`}>
      {twitterLink && (
        <img
          src={hoveredButton === 1 ? twitter_2 : twitter_1}
          alt="Twitter"
          className={`resizable-image ${disabled ? 'disabled' : ''}`}
          onMouseEnter={() => handleHover(1)}
          onMouseLeave={handleLeave}
          onClick={() => handleClick(twitterLink)}
        />
      )}
      {facebookLink && (
        <img
          src={hoveredButton === 2 ? facebook_2 : facebook_1}
          alt="Facebook"
          className={`resizable-image ${disabled ? 'disabled' : ''}`}
          onMouseEnter={() => handleHover(2)}
          onMouseLeave={handleLeave}
          onClick={() => handleClick(facebookLink)}
        />
      )}
      {linkedinLink && (
        <img
          src={hoveredButton === 3 ? linkedin_2 : linkedin_1}
          alt="LinkedIn"
          className={`resizable-image ${disabled ? 'disabled' : ''}`}
          onMouseEnter={() => handleHover(3)}
          onMouseLeave={handleLeave}
          onClick={() => handleClick(linkedinLink)}
        />
      )}
    </div>
  );
};

export default SocialIcons;
