import PageBanner from './../PageBanner';

const ProductHighlightDatapoints = () => {
    const bannerItems = [
        { largeText: '1,000+', smallText: 'Policies reviewed' },
        { largeText: '156+', smallText: 'Providers reviewed' },
        { largeText: '1k+', smallText: 'Data point comparisons' },
    ];

    return (
        <PageBanner items={bannerItems} />
    );
};
export default ProductHighlightDatapoints;
