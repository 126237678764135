import React, { useRef, useCallback, useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, NavLink, useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ShowTourProvider } from '../context/ShowTourContext';
import { ClientProvider } from "../context/ClientContext";
import { WebViewerProvider } from '../context/WebViewerContext';
import { CountryContext } from '../context/CountryContext';
import { CollapseProvider, useCollapse } from "../context/CollapseContext";
import { useGlobalContext } from '../context/GlobalContext';
import { MasterPoliciesContext } from '../context/MasterPoliciesContext';
import { PolicyTypeContext } from '../context/PolicyTypeContext';
import { FiltersContext } from '../context/FiltersContext';
import ActivePolicy from "../components/ActivePolicy";
import ActiveClient from "../components/ActiveClient";
import Leads from "./Dashboard/Leads";
import Retention from "./Dashboard/Retention";
import Notifications from "../components/header/Notifications";
import Notes from "../components/header/Notes";
import Calendar from "../components/header/Calendar";
import Account from "../components/header/Account";
import FileUpload from '../components/FileUpload';
import Home from './Dashboard/Home';
import Claims from './Dashboard/Claims';
import Payments from './Dashboard/Payments';
import Analysis from "./Dashboard/Analysis";
import StatementOfAdvice from "./Dashboard/StatementOfAdvice";
import ProviderPoliciesTable from "./Dashboard/ProviderPoliciesTable";
import Broker from "../components/home/Broker";
import Comparison from "./Dashboard/Comparison";
import Advice from "./Dashboard/Advice";
import Chat from './Dashboard/Chat';
import Commissions from './Dashboard/Commissions/Commissions';
import Research from './Dashboard/Research';
import Eligibility from './Dashboard/Eligibility';
import Clients from './Dashboard/Clients';
import Score from './Dashboard/Score';
import Workflow from './Dashboard/Workflow';
import Wizard from './Dashboard/Wizard';
import Visualise from './Dashboard/Visualise';
import DocumentManagement from './Dashboard/DocumentManagement';
import Profile from './Dashboard/AccountSettings/MyProfile';
import AccountSettings from './Dashboard/AccountSettings/AccountSettings';
import Feedback from './Dashboard/Feedback';
import signOutIcon from "../images/sign-out_icon.png";
import SettingsURL from "../icons/settings.png";
import ChatURL from "../icons/menu/discover.png";
import VisualiseURL from "../icons/menu/visualise.png";
import AnalysisURL from "../icons/menu/checklist.png";
import InsightsURL from "../icons/menu/insights.png";
import CompareURL from "../icons/menu/compare.png";
import ScoreURL from "../icons/menu/score.png";
import ClientsURL from "../icons/menu/clients.png";
import HomeURL from "../icons/menu/home.png";
import LibraryURL from "../icons/menu/library.png";
import MoneyURL from "../icons/menu/money.png";
import RocketURL from "../icons/menu/rocket.png";
import ClaimsURL from "../icons/menu/claims.png";
import LogoURL from "../logo/logo_green.png";
import QuoteBuilder from "../components/quote_builder/QuoteBuilder";
import Quote from "./Dashboard/Quote";
import PolicyLibrary from "./Dashboard/PolicyLibrary";
import Email from "./Dashboard/Email";
import SystemErrorMessage from '../components/SystemErrorMessage'; // Import the error message component
import ProcessFlow from '../components/workflow/ProcessFlow';
import { logoutHandler } from "../apis/AuthService";
import WelcomePopup from '../components/WelcomePopup';
import { fetchMyPolicies } from "../apis/PolicyService";
import useTourModalOnLoad from "../modals/useTourModalOnLoad";

import './Dashboard.css';
import './../components/DocumentUpload.css';

function MenuItem({ to, id, icon, label, isActive, onClick, isMenuCollapsed }) {
  return (
    <li onClick={onClick} id={id}>
      <NavLink
        className={`menu-item-text ${isActive ? 'active' : ''}`}
        style={{ width: isMenuCollapsed ? '70px' : '250px' }}
        to={to}
        onClick={() => {
          // Track the menu item click
          window.gtag('event', 'menu_item_click', {
            menu_item: label,
            path: to,
          });
        }}
      >
        <img src={icon} className="menu-icon" alt={`${label} Icon`} />
        {!isMenuCollapsed && <p>{label}</p>}
      </NavLink>
    </li>
  );
}

const tabNames = ['Current Policy', 'Policy Search', 'Policy Compare', 'Build Quote'];


function DashboardComponent() {
  // Contexts to manage global states and access various functionalities
  const { masterPolicies, setMasterPolicies } = useContext(MasterPoliciesContext);
  const { country } = useContext(CountryContext);
  const { globalFilters, setGlobalFilters } = useContext(FiltersContext);
  const { policyType, setPolicyType } = useContext(PolicyTypeContext);
  const { isCollapsed, setIsCollapsed } = useCollapse(false);
  const { activeDocument, setActiveDocumentName } = useGlobalContext();
  const [userContext, setUserContext] = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(tabNames[0]);

  // Local states
  const [isFetchingUserDetails, setIsFetchingUserDetails] = useState(false); // Track user details fetching
  const [toggle, setToggle] = useState(false); // Toggle for UI elements
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false); // Menu collapse state
  const [showPopup, setShowPopup] = useState(false); // Show logout popup
  const [showBroker, setShowBroker] = useState(false); // Show broker popup
  const [activeMenu, setActiveMenu] = useState(null); // Track active menu item
  const [hasError, setHasError] = useState(false); // Track if an error has occurred
  const [delayExpired, setDelayExpired] = useState(false); // Track if delay has expired

  // Refs for tracking mouse position and menu element
  const mousePosition = useRef({ x: 0, y: 0 });
  const menuRef = useRef();

  // Track initial page view when the component mounts
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname,
      page_title: 'Dashboard',
      page_location: window.location.href,
    });

  }, []);

  // Fetch policies based on the user's country
  async function fetchPolicies() {

    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/insurance-providers?country=${country}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch policies');
      }
      const data = await response.json();
      setMasterPolicies(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      logoutHandler(); // Log the user out on error
    }
  }

  // Update the policy type based on the active document
  useEffect(() => {
    if (activeDocument && activeDocument.policyType !== policyType) {
      setPolicyType(activeDocument.policyType);
    }
  }, [activeDocument]);

  // Fetch policies when the country changes
  useEffect(() => {
    fetchPolicies();
  }, [country]);

  // Handle menu item click
  const handleMenuChildClick = (event, menuName) => {
    handleChildClick(event);
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  // Initialize the navigation hook
  const navigate = useNavigate();

  // Close the broker popup
  const closeBrokerPopup = () => {
    setShowBroker(false);
  };

  // Fetch user details with a callback to avoid unnecessary re-fetching
  const fetchUserDetails = useCallback(() => {
    if (isFetchingUserDetails) {
      return; // Exit if already fetching
    }

    setIsFetchingUserDetails(true); // Set flag to true when fetch starts
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        setUserContext((oldValues) => ({
          ...oldValues,
          details: data,
        }));
      } else {
        if (response.status === 401) {
          window.location.reload();
        } else {
          setUserContext((oldValues) => ({
            ...oldValues,
            details: null,
          }));
        }
      }
    }).finally(() => {
      setIsFetchingUserDetails(false); // Reset flag when fetch is complete
    });
  }, [setUserContext, userContext.token, isFetchingUserDetails]);

  // Handle policy type change from dropdown
  const handlePolicyTypeChange = (event) => {
    setPolicyType(event.target.value);
  };

  // Fetch user details when the component mounts
  useEffect(() => {
    if (!userContext.details && !isFetchingUserDetails) {
      fetchUserDetails();
    }
  }, [fetchUserDetails]);

  // Navigate based on user type
  useEffect(() => {
    navigate('/quote');
  }, []);

  const expandMenu = useCallback((e) => {
    if (isMenuCollapsed && mousePosition.current.x <= 110) {
      menuRef.current.classList.remove('collapsed');
      setIsMenuCollapsed(false);
      setIsCollapsed(false);
    }
  }, [isMenuCollapsed]);

  // Collapse the menu
  const collapseMenu = () => {
    menuRef.current.classList.add('collapsed');
    setIsMenuCollapsed(true);
    setIsCollapsed(true);
  };

  // Track mouse movement
  const handleMouseMove = (e) => {
    mousePosition.current = { x: e.clientX, y: e.clientY };
  };

  // Handle user logout with error handling
  const handleLogout = async () => {
    if (!userContext.token && !isFetchingUserDetails) {
      fetchUserDetails();
    }
    if (await logoutHandler(userContext, setUserContext)) {
      window.gtag('event', 'user_logout', {
        method: 'manual_logout',
        user_id: userContext.details?.id,
      });
    } else {
      setHasError(true);
    }
  };

  // Stop event propagation for child clicks
  const handleChildClick = (event) => {
    event.stopPropagation();  // This stops the click event from bubbling up to the parent
  };

  // Toggle the sign-out popup
  const toggleSignOutPopup = (event) => {
    event.stopPropagation();  // This stops the click event from bubbling up to the parent
    setShowPopup(!showPopup);
  };

  // Toggle the modal visibility
  const setHasSeenModalToTrue = () => {
    setToggle(!toggle);
  };

  const fetchMyPolicyData = async () => {
    const data = await fetchMyPolicies(userContext.details.username, country);
    if (data.length > 0) {
      setActiveDocumentName(data[0]);
    }
  };

  useEffect(() => {
    if (userContext.details) {
      const loadMyPolicyData = async () => {
        await fetchMyPolicyData();
      };

      loadMyPolicyData(); // Call the async function inside useEffect
    }
  }, [userContext.details]);


  useEffect(() => {
    if (!activeDocument || Object.keys(masterPolicies).length === 0) {
      const timer = setTimeout(() => {
        setDelayExpired(true);
      }, 5000); // 5 seconds delay

      return () => clearTimeout(timer);
    } else {
      setDelayExpired(false);
    }
  }, [activeDocument, masterPolicies]);

  useTourModalOnLoad({ expandMenu, setActiveTab });

  return (
    <div>

      {/* Display a maintenance message if the site is down */}
      <div className="dashboard-experience-layer">
        <div className="dashboard-experience-layer-text" style={{ fontWeight: 'bold' }}>We're sorry</div><br />
        <div className="dashboard-experience-layer-text">For the best experience, use an iPad or desktop.</div>
      </div>
      <FileUpload toggleWelcome={toggle} onToggle={setHasSeenModalToTrue} />
      {Object.keys(masterPolicies).length > 0 && (
        <div className={`dashboard ${isMenuCollapsed ? 'dashboard-collapsed' : 'dashboard-expanded'}`}
          onMouseMove={handleMouseMove}
        >
          {showPopup && (
            <div className="signout-popup-container" onClick={handleChildClick}>
              <div className="signout-popup">
                <p>Are you sure you want to log out?</p>
                <div className="signout-popup-buttons">
                  <button onClick={toggleSignOutPopup}>Cancel</button>
                  <button onClick={handleLogout}>Log Out</button>
                </div>
              </div>
            </div>
          )}
          {showBroker && (
            <div className="dashboard-broker-container-overlay" onClick={handleChildClick}>
              <div className="dashboard-broker-container">
                <div className="dashboard-broker-container-header" onClick={() => setShowBroker(!showBroker)}>
                  &times;
                </div>
                <Broker onClose={closeBrokerPopup} />
              </div>
            </div>
          )}
          <Calendar />
          <Notes />
          <Notifications />
          <Account />
          <div className="menu"
            onMouseEnter={expandMenu}
            onMouseLeave={collapseMenu}
            ref={menuRef}>
            <div className="menu-flex">
              <div className="menu-header">
                <Link className="menu-logo-div" to="/home" onClick={(e) => handleMenuChildClick(e, 'home')} >
                  <img src={LogoURL} className={!isMenuCollapsed ? "menu-logo" : "menu-logo-padded"} alt="Logo" />
                  {!isMenuCollapsed && <div className="menu-logo-text"><span style={{ color: 'rgb(79,166,88)' }}>POLICY</span><span style={{ color: 'white' }}>CHECK</span></div>}
                </Link>
              </div>
              <div className="menu-box">
                <ul className="menu-items">
                  <MenuItem
                    to="/home"
                    icon={HomeURL}
                    label="Dashboard"
                    isActive={activeMenu === 'review'}
                    onClick={(e) => handleMenuChildClick(e, 'review')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/clients"
                    icon={ClientsURL}
                    label="Clients"
                    isActive={activeMenu === 'clients'}
                    onClick={(e) => handleMenuChildClick(e, 'clients')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  {/* <MenuItem
                    to="/wizard"
                    icon={RocketURL}
                    label="Quote Wizard"
                    isActive={activeMenu === 'wizard'}
                    onClick={(e) => handleMenuChildClick(e, 'wizard')}
                    isMenuCollapsed={isMenuCollapsed}
                  /> */}
                  <MenuItem
                    id="quote"
                    to="/quote"
                    icon={CompareURL}
                    label="Quote Builder"
                    isActive={activeMenu === 'quote'}
                    onClick={(e) => handleMenuChildClick(e, 'quote')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    id="research"
                    to="/research"
                    icon={VisualiseURL}
                    label="Research"
                    isActive={activeMenu === 'research'}
                    onClick={(e) => handleMenuChildClick(e, 'research')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/policy-library"
                    icon={LibraryURL}
                    label="Policy Library"
                    isActive={activeMenu === 'policy-library'}
                    onClick={(e) => handleMenuChildClick(e, 'policy-library')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/workflows"
                    icon={AnalysisURL}
                    label="Workflow"
                    isActive={activeMenu === 'admin'}
                    onClick={(e) => handleMenuChildClick(e, 'admin')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/email"
                    icon={ChatURL}
                    label="Communication"
                    isActive={activeMenu === 'email'}
                    onClick={(e) => handleMenuChildClick(e, 'email')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/commissions"
                    icon={MoneyURL}
                    label="Commission"
                    isActive={activeMenu === 'commissions'}
                    onClick={(e) => handleMenuChildClick(e, 'commissions')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                  <MenuItem
                    to="/claims"
                    icon={ClaimsURL}
                    label="Claims"
                    isActive={activeMenu === 'claims'}
                    onClick={(e) => handleMenuChildClick(e, 'claims')}
                    isMenuCollapsed={isMenuCollapsed}
                  />
                </ul>
                <ul className="menu-items-2">
                  <li className={`menu-item-text-column ${isMenuCollapsed ? 'doNotShow' : ''}`} onClick={handleChildClick}>
                    {!isMenuCollapsed && <p>Client</p>}
                    <ActiveClient menu={true} />
                  </li>

                  {!isMenuCollapsed &&
                    <Link to="/clients" style={{ textDecoration: 'none' }} onClick={(e) => handleMenuChildClick(e, 'clients')}>
                      <li className="menu-item-text">
                        <div className="dashboard-upload">
                          <button className="dashboard-upload-from-computer">Add client...</button>
                          <div className="dashboard-upload-plus">
                            <div className="dashboard-upload-plus-icon"></div>
                          </div>
                        </div>
                      </li>
                    </Link>
                  }
                  <li className={`menu-item-text-column ${isMenuCollapsed ? 'doNotShow' : ''}`} onClick={handleChildClick}>
                    {!isMenuCollapsed && <p>Policy</p>}
                    <ActivePolicy menu={true} />
                  </li>
                  {!isMenuCollapsed &&
                    <li className="menu-item-text" onClick={handleChildClick} id='addpolicy'>
                      <div className="dashboard-upload">
                        <button
                          className="dashboard-upload-from-computer"
                          onClick={setHasSeenModalToTrue}>Add policy...
                        </button>
                        <div className="dashboard-upload-plus">
                          <div className="dashboard-upload-plus-icon"></div>
                        </div>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </div>

            <Link className="settings-bar" to="/settings" onClick={(e) => handleMenuChildClick(e, '')}>
              <img
                src={SettingsURL}
                alt="Settings Icon"
                className={`clickable-img ${!isMenuCollapsed ? "menu-settings-icon" : "menu-settings-icon-padded"}`}
              />
              {!isMenuCollapsed && <div style={{ paddingTop: '5px', fontSize: '14pt', paddingLeft: '10px', color: 'white' }}>Settings</div>}
            </Link>
            <div className="sign-out-bar" onClick={toggleSignOutPopup}>
              <img
                src={signOutIcon}
                alt="Logout Icon"
                className={`clickable-img ${!isMenuCollapsed ? "menu-settings-icon" : "menu-settings-icon-padded"}`}
              />
              {!isMenuCollapsed && <div style={{ paddingTop: '5px', fontSize: '14pt', paddingLeft: '10px' }}>Logout</div>}
            </div>
          </div>
          <div className={`content ${isMenuCollapsed ? 'expanded' : 'content-collapsed'}`}>
            <Routes>
              {/* Define routes for different dashboard sections */}
              <Route path="/workflows" element={<Workflow />}></Route>
              <Route path="/email" element={<Email />}></Route>
              <Route path="/matrix" element={<ProviderPoliciesTable />}></Route>
              <Route path="/" element={<Comparison />}></Route>
              <Route path="/policy-library" element={<PolicyLibrary />}></Route>
              <Route path="/payments" element={<Payments />}></Route>
              <Route path="/claims" element={<Claims />}></Route>
              <Route path="/retention" element={<Retention />}></Route>
              <Route path="/advice" element={<Advice />}></Route>
              <Route path="/leads" element={<Leads />}></Route>
              <Route path="/chat" element={<Chat />}></Route>
              <Route path="/commissions" element={<Commissions />}></Route>
              <Route path="/statements" element={<StatementOfAdvice />}></Route>
              <Route path="/broker" element={<Broker />}></Route>
              <Route path="/clients" element={<Clients />}></Route>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/find-policy" element={<Comparison />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/active-policy" element={<ActivePolicy />} />
              <Route path="/visualise" element={<Visualise />}></Route>
              <Route path="/dashboard" element={<Analysis />}></Route>
              <Route path="/eligibility" element={<Eligibility />}></Route>
              <Route path="/feedback" element={<Feedback />}></Route>
              <Route path="/research" element={<Research />}></Route>
              <Route path="/score" element={<Score />}></Route>
              <Route path="/settings" element={<AccountSettings />}></Route>
              <Route path="/documents" element={<DocumentManagement />}></Route>
              <Route path="/quote"
                element={
                  <Quote activeTab={activeTab} setActiveTab={setActiveTab} tabNames={tabNames} />
                }>
              </Route>
              <Route path="/wizard" element={<Wizard onClose={() => setActiveMenu('home')} />}></Route>
              <Route path="/quote-builder" element={<QuoteBuilder toggleWelcome={true} onToggle={() => { }} />}></Route>
            </Routes>
          </div>
        </div>
      )}
      {delayExpired && hasError && (
        <SystemErrorMessage message="System down for maintenance. Please try again later." />
      )}
    </div>
  );
}

function Dashboard() {
  return (
    <div>
    <WebViewerProvider>
      <ShowTourProvider>
        <WelcomePopup />
        <ClientProvider>
          <CollapseProvider>
            <DashboardComponent />
          </CollapseProvider>
        </ClientProvider>
      </ShowTourProvider>
    </WebViewerProvider>
    </div>
  );
}

export default Dashboard;
