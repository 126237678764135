import React from 'react';
import HomeIcon from "../../../icons/home.png";
import ContentsIcon from "../../../icons/contents.png";
import HomeAndContentsIcon from "../../../icons/homeandcontents.png";
import TravelIcon from "../../../icons/travel.png";
import LandLordIcon from "../../../icons/landlord.png";
import MotorIcon from "../../../icons/motor.png";

const Scope = ({ policyDetails, setPolicyDetails, onBack, onNext }) => {
    const handleActiveBoxChange = (index, image) => {
        setPolicyDetails({
            ...policyDetails,
            policyType: image.alt,
        });
        onNext();
    };

    const images = [
        { src: HomeIcon, alt: 'Home' },
        { src: ContentsIcon, alt: 'Contents' },
        { src: HomeAndContentsIcon, alt: 'HomeAndContents' },
        { src: LandLordIcon, alt: 'Landlord' },
        { src: TravelIcon, alt: 'Travel' },
        { src: MotorIcon, alt: 'Motor' },
    ];

    return (
        <>
            <div className="upload-container-row">
                <div className="upload-container-column-no-gap">
                    <div className="upload-container-option-header">
                        <div className="upload-container-option-header-title">Select your policy type</div>
                    </div>
                    <div className="upload-container-row">
                        <div className="upload-container-box-grid">
                            {images.map((image, index) => (
                                <div className="upload-container-box-grid-item" key={index}>
                                    <div
                                        className={`upload-container-box ${policyDetails.policyType === image.alt ? 'upload-container-box-active' : ''}`}
                                        onClick={() => handleActiveBoxChange(index, image)}
                                    >
                                        <img src={image.src} alt={image.alt} style={{ width: '200px', height: 'auto' }} />
                                    </div>
                                    <div className="upload-container-box-grid-item-title">
                                        {image.alt}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="upload-buttons">
                <button className="upload-buttons-back" onClick={onBack}>Back</button>
            </div>
        </>
    );
};

export default Scope;
