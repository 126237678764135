import React, { createContext, useContext, useState, useEffect } from 'react';
import { PolicyTypeContext } from '../PolicyTypeContext';
import { useGlobalContext } from '../GlobalContext';

// Create a custom hook to get the data
export const useGeneralAndExistingCover = () => {
  // Hooks must be called inside a React function component or a custom hook
  const { activeDocument } = useGlobalContext();
  const { policyType } = useContext(PolicyTypeContext);

  const general = {
    maxPremium: 1000,
    insuranceProvider: '',
  };

  const existingCover = {
    insurer: activeDocument?.policyProvider,
    policyNumber: activeDocument?.policyNumber,
    policyType: policyType,
    premium: 0,
    excess: 0,
    file: activeDocument?.policyWording?.filename,
    date: activeDocument?.policyWording?.effective_date,
  };

  // Return the general and existingCover objects
  return { general, existingCover };
};
