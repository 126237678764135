import React, { useContext, useState, useEffect } from "react";
import ReactGA from 'react-ga4'; 
import { Callout } from "@blueprintjs/core";
import { CountryContext } from '../../context/CountryContext';
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from 'react-router-dom';
import "./LogInandRegister.css";

const Login = ({ isOpen, onClose, resetStep, forgotPassword }) => {
  const location = useLocation();
  const url = location.pathname; // Get the current path from useLocation
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contextValue, setUserContext] = useContext(UserContext);
  const [validationMessage, setValidationMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  const [step, setStep] = useState(1);
  const [customOption, setCustomOption] = useState(false);

  const navigate = useNavigate();

  const forgot = () => {
    forgotPassword();
    onClose(); // Close the popup
  }

  useEffect(() => {
    if (resetStep) {
      setStep(1); // Reset the step to 1 when resetStep is true
    }
  }, [resetStep]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    clearTimeout(timer); // Clear any existing timer

    const newTimer = setTimeout(() => {
      setIsPasswordVisible(false);
    }, 30000); // 30 seconds

    setTimer(newTimer);
  };

  useEffect(() => {
    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [timer]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = "Something went wrong :( Please try again later.";

    fetch(process.env.REACT_APP_API_ENDPOINT + "users/login", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: email, password }),
    })
      .then(async (response) => {
        setIsSubmitting(false);

        if (!response.ok) {
          if (response.status === 400) {
            setError("Please fill all the fields correctly!");
          } else if (response.status === 401) {
            setError("Invalid email and password combination.");
          } else {
            setError(genericErrorMessage);
          }
        } else {
          const data = await response.json();

          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token };
          });
          // Track the username in Google Analytics
          ReactGA.set({ userId: email });  // Setting the userId
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: email,
          });

          navigate('/dashboard');
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setError("Failed - ", genericErrorMessage);
      });

  };

  // Function to handle input changes and reset error to false
  const handleInputChange = (e) => {
    // Get the new input value
    const newValue = e.target.value;

    // Determine which input field is being changed and update the corresponding state
    if (e.target.name === "username") {
      if (newValue.includes('@')) {
        setValidationMessage('');
      } else {
        setValidationMessage('Please enter a valid email address.');
      }
      setEmail(newValue);

    } else if (e.target.name === "password") {
      setPassword(newValue);
    }

    // Reset error to false
    setError("");
  };

  return (
    <>
      <div className={`login-and-register-pop-up ${isOpen ? "open" : ""}`}>
        <div className="popup-container">
          <div className="popup-container-close" onClick={onClose}>
            &times;
          </div>
          <div className="pop-up-container-heading">Log into your account</div>
          <div className="existing-user" onClick={forgot}>
            Forgot your password?
          </div>
          <div className="popup-container-inputs">
            <input
              className="text-email-password-input"
              type="email"
              name="username"
              placeholder="Email"
              value={email}
              onChange={handleInputChange}
              autoComplete="username"
            />
            <div className="password-input-container">
              <form>
                <input
                  className="text-email-password-input"
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleInputChange}
                  autoComplete="password"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !error) {
                      e.preventDefault(); // Prevent the default Enter key action
                      formSubmitHandler(e); // Pass the event to formSubmitHandler
                    }
                  }}
                />
              </form>
              <button onClick={togglePasswordVisibility} className={`toggle-password-btn ${isPasswordVisible ? 'dark-grey' : ''}`}>
                {isPasswordVisible ? 'Hide' : 'Show'}
              </button>
            </div>
            {validationMessage && <p className="validation-message">{validationMessage}</p>}
            {error && <p className="validation-message">{error}</p>}
          </div>
          <div className="popup-buttons" style={{paddingBottom:'203px'}}>
            <div className="align-buttons">
              <button
                disabled={error}
                className={`popup-buttons-next ${error ? "isError" : "noError"
                  }`}
                onClick={formSubmitHandler}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !error) {
                    e.preventDefault(); // Prevent the default Enter key action
                    formSubmitHandler(e); // Pass the event to formSubmitHandler
                  }
                }}
              >
                Log in
              </button>
            </div>
          </div>
        </div>
        <div className="popup-overlay" onClick={onClose}>
          <div className="popup-overlay-content"></div>
        </div>
      </div>
    </>
  );
};

export default Login;
