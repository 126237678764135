import React, { createContext, useContext, useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

const WebViewerContext = createContext();

export const useWebViewer = () => useContext(WebViewerContext);

export const WebViewerProvider = ({ children }) => {
  const viewerRef = useRef(null);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    // Initialize WebViewer only if not already initialized
    if (!instance && viewerRef.current) {
      WebViewer(
        {
          path: '/pdfjsexpress', // Path to the library's assets, make sure it's correct
          initialDoc: '/documents/QBE-ISR.pdf',
        },
        viewerRef.current
      ).then((instance) => {
        setInstance(instance); // Set the WebViewer instance in the state

        const { Core } = instance;

        // Make sure that the documentViewer exists before adding event listeners
        if (Core && Core.documentViewer) {
          Core.documentViewer.addEventListener('documentLoaded', () => {
            console.log('Document loaded');
          });

          Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
            console.log(`Page number is: ${pageNumber}`);
          });
        } else {
          console.error("documentViewer not initialized properly");
        }
      }).catch((error) => {
        console.error("Error initializing WebViewer: ", error);
      });
    }

    // Cleanup function when the component unmounts
    return () => {
      if (instance) {
        instance.dispose(); // Properly dispose of the WebViewer instance
      }
    };
  }, [instance]);

  return (
    <WebViewerContext.Provider value={{ viewerRef, instance }}>
      {children}
    </WebViewerContext.Provider>
  );
};
