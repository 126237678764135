import React, { useState } from 'react';
import { formatDate } from '../../../utils/utils';
import './QuotesTab.css'

const QuotesTab = ({ clientData, handleChange, onAddQuote, onEditQuote, onDeleteQuote }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [quoteToDelete, setQuoteToDelete] = useState(null);

  const promptDelete = (quote) => {
    setQuoteToDelete(quote);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = (quote) => {
    onDeleteQuote(quote);
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">
          Quotes
          <button onClick={onAddQuote} className="add-quote-button">Add Quote</button>
        </div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Version</th>
              <th>Date</th>
              <th>Exp. Date</th>
              <th>Price (pa.)</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {clientData?.quotes?.map((quote, index) => (
              <tr key={index}>
              {console.log("quote",quote)}
                <td>{quote?.quoteId}</td>
                <td>{quote?.name}</td>
                <td>{quote?.version}</td>
                <td>{formatDate(quote?.date)}</td>
                <td>{formatDate(quote?.expiryDate)}</td>
                <td>${quote?.price.yearly}</td>
                <td>{quote?.status}</td>
               
                <td>
                  <div className="quote-action-button-container">
                    <button className="quote-action-button">
                      <span className="quote-action-button-dot"></span>
                      <span className="quote-action-button-dot"></span>
                      <span className="quote-action-button-dot"></span>
                    </button>
                    <div className="pop-up-menu">
                      <div className="pop-up-menu-item" onClick={() => onEditQuote(quote)}>Edit</div>
                      <div className="pop-up-menu-item" onClick={() => promptDelete(quote)}>Delete</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteModalOpen && (
        <div className="delete-modal">
          <div className="modal-content">
            <h2>Delete Quote</h2>
            <p>Are you sure you want to delete this quote?</p>
            <button onClick={() => handleDelete(quoteToDelete)}>Yes, Delete</button>
            <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuotesTab;
