import React from 'react';
import { Bar } from 'react-chartjs-2';
import './LeadQualificationBreakdown.css';

const LeadQualificationBreakdown = ({ qualificationData }) => {
  const data = {
    labels: Object.keys(qualificationData),
    datasets: [{
      label: 'Number of Leads',
      data: Object.values(qualificationData),
      backgroundColor: [
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className="lead-qualification-breakdown">
      <Bar data={data} options={options} />
    </div>
  );
};

export default LeadQualificationBreakdown;
