import React, { useState, useEffect, useContext } from "react";
import { ShowTourContext } from '../context/ShowTourContext'; // Import the context
import welcome1Image from './../images/1_providers.png';
import welcome2Image from './../images/2_policies.png';
import welcome3Image from './../images/3_review.png';
import welcome4Image from './../images/4_send.png';
import { UserContext } from "./../context/UserContext";
import "./Welcome.css";

function Welcome({ toggleWelcome, onToggle }) {
  const { setShowTour } = useContext(ShowTourContext);
  const [currentCard, setCurrentCard] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fade, setFade] = useState(false); // State to handle fade effect
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    if (toggleWelcome === true) {

      setShowModal(true);
      setCurrentCard(0);
      onToggle();
    }

  }, [toggleWelcome, onToggle]);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem(`hasSeenModal_${userContext.details.username}`, true);
    setShowTour(true);
  };

  const handlePrevCard = () => {
    setCurrentCard((prevCard) => Math.max(prevCard - 1, 0));
  };

  const handleNextCard = () => {
    setCurrentCard((prevCard) => Math.min(prevCard + 1, cards.length - 1));
  };

  useEffect(() => {
    if (userContext.details?.username !== undefined) {
      const modal = localStorage.getItem(`hasSeenModal_${userContext.details.username}`);
      if (modal === undefined || modal === null || modal === false) {
        setShowModal(true);
      }
    }
  }, [userContext]);

  const cards = [
    {
      title: "Step 1: Review your policy",
      description:
        "See how your policy compares...",
      image: welcome3Image,
      width: '76%',
    },
    {
      title: "Step 2: Find a better policy",
      description:
        "Compare the policies from different providers...",
      image: welcome1Image,
      width: '76%',
    },
    {
      title: "Step 3: Compare several policies",
      description:
        "Filter your options to find the best coverage for price",
      image: welcome2Image,
      width: '74%',
    },
    {
      title: "Step 4: Review your new policy",
      description:
        "Use the dashboard to compare & contrast the policy",
      image: welcome4Image,
      width: '87%',
    },
    {
      title: "Disclaimer",
      description:
        "You acknowledge the information provided on this website is for educational and informational purposes only and should not be construed as financial advice. The content on this website is not intended to be a substitute for professional financial advice, diagnosis, or treatment. Always seek the advice of a qualified professional regarding your financial situation. Any reliance you place on any information from this website is strictly at your own risk.",
      image: '',
      width: '0px',
    },
  ];

  // Apply fade-in effect when currentCard changes
  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
    }, 1000); // Small delay before applying fade-in

    return () => clearTimeout(timer); // Clean up the timeout
  }, [currentCard]);

  const WelcomeCard = React.memo(({ title, description, image, width, fade }) => {
    return (
      <div className={`welcome-carousel-card ${fade ? 'fade-in' : ''}`}>
        <h2 className="welcome-carousel-card-title">{title}</h2>
        <p className={title === 'Disclaimer' ? `welcome-carousel-card-description-disclaimer` : `welcome-carousel-card-description`}>
          {description}
        </p>
        {title !== 'Disclaimer' && (
          <img
            src={image}
            className="welcome-img"
            alt="some value"
            style={{ width: width, height: 'auto', maxHeight:'450px' }}
          />
        )}
      </div>
    );
  });

  return (
    <>
      {showModal && (
        <div className="welcome-carousel-container">
          <div className="welcome-carousel">
            <WelcomeCard
              title={cards[currentCard].title}
              description={cards[currentCard].description}
              image={cards[currentCard].image}
              width={cards[currentCard].width}
              fade={fade}
            />
            <div className="welcome-buttons">
              {currentCard > 0 && (
                <button className="welcome-button welcome-back" onClick={handlePrevCard}>Prev</button>
              )}
              {currentCard < cards.length - 1 ? (
                <button className="welcome-button welcome-next" onClick={handleNextCard}>Next</button>
              ) : (
                <button className="welcome-button welcome-next" onClick={handleClose}>OK</button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Welcome;
