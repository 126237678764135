import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial home insurance state
  const initialHomeInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        newProperty: false,
        betterDeal: false,
        changeInSituation: false,
      },
      goals: {
        buildingCoverage: false,
        liabilityCoverage: false,
      },
    },
    situation: {
      propertyDetails: {
        address: '',
        yearBuilt: '',
        constructionType: '',
      },
      propertyUse: {
        longTermRental: false,
        shortTermRental: false,
      },
      propertyRisks: {
        floodZone: false,
        bushfireZone: false,
        highCrimeArea: false,
      },
    },
    modules: {
      options: {
        excessFreeGlass: false,
        sanitaryFixtures: false,
        heritageHome: false,
        lifestyleBlockFencing: false,
        flood: false,
        accidentalDamage: false,
        motorBurnout: false,
        sumExtra: false,
        extendedCoverForChattels: false,
        matchingFloorCoverings: false,
        lifestyleBlockPumpsAndMotors: false,
        houseUnderConstructionOrAlteration: false,
      },
    },
  };

  // Initialize filters state with the initialHomeInsuranceState
  const [filters, setFilters] = useState(initialHomeInsuranceState);

  return (
    <PropertyContext.Provider value={{ filters, setFilters }}>
      {children}
    </PropertyContext.Provider>
  );
};
