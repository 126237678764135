import React, { useRef, useCallback, useContext, useState, useEffect } from "react";
import { logoutHandler } from "../apis/AuthService";
import { fetchClients } from '../apis/ClientService';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    NavLink,
    useNavigate
} from "react-router-dom";
import { formatString, formatStringToCurrencyString } from '../utils/utils';
import { useClient } from '../context/ClientContext';
import { usePolicyTypes } from '../context/PolicyTypesContext';
import { CountryContext } from '../context/CountryContext';
import { UserContext } from "../context/UserContext";
import { useGlobalContext } from '../context/GlobalContext';
import { useUpload } from '../context/UploadContext';
import WaitingDiv from "./WaitingDiv";
import { ProviderPolicyListContext } from '../context/ProviderPolicyListContext';
import UploadURL from "../icons/docUpload.png";
import HomeIcon from "../icons/home.png";
import ManualURL from "../icons/fileUpload.png";
import NoUploadURL from "../icons/NoUpload.png";
import FileUploadURL from "../icons/file_upload.png";
import logoDoneURL from "../icons/done.png";
import logoFailedURL from "../icons/failed.png";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ProgressBar from "./ProgressBar";
import LogoURL from "./../logo/logo_green.png";
import Tooltip from './Tooltip';
import './FileUpload.css';
import './LandingUpload.css';

function FileUpload({ toggleWelcome = false, onToggle }) {
    useEffect(() => {
        document.title = 'Setup';
    }, []);
    const policyTypes = usePolicyTypes();
    const defaultIcon = HomeIcon;
    const { activeClient } = useClient();
    const { country } = useContext(CountryContext);
    const navigate = useNavigate();
    const dateInputRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [showCallout, setShowCallout] = useState(false);
    const [error, setError] = useState("");
    const [uploadedFile, setUploadedFile] = useState("");
    const [policyFileName, setPolicyFileName] = useState("NO_FILE_MANUAL_UPLOAD");
    const [cardNumber, setCardNumber] = useState(1);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false);
    const [gettingInfo, setGettingInfo] = useState(false);
    const [countdown, setCountdown] = useState(10); // Initial countdown value in seconds
    const [validationMessage, setValidationMessage] = useState('');
    const [file, setFile] = useState(null);
    const [fileVariation, setFileVariation] = useState(null);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState({ personalDetails: { firstName: '', lastName: '' }, contactInformation: { email: '', phone: '' } });
    const [importancePositionA, setImportancePositionA] = useState(50);
    const [importancePositionB, setImportancePositionB] = useState(50);
    const snapImportantPoints = [0, 25, 50, 75, 100];
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [installmentOptions, setInstallmentOptions] = useState({ weekly: true, monthly: false, yearly: false });
    const [homeUsageOptions, setHomeUsageOptions] = useState({ occupied: true, rented: false, vacant: false, holidayHome: false, semiOccupied: false });
    const popupRef = useRef();
    const [userContext, setUserContext] = useContext(UserContext);
    const { handleUploadComplete } = useUpload();
    const { activeDocument, setActiveDocumentName } = useGlobalContext();
    const [timerRef, setTimerRef] = useState(null);
    const card5KeysToInclude = ['policyProvider', 'policyName', 'policyWording', 'policyNumber', 'periodOfInsuranceEnd',];
    const card6KeysToInclude = ['policyAddress', 'homeSumInsured'];
    const disabledProducts = ['travel', 'motor', 'health', 'life', 'income', 'professional'];
    const [searchText, setSearchText] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const mandatory = ['policyNumber', 'policyType', 'policyProvider', 'policyPrice', 'policyAddress', 'homeSumInsured', 'homeUse'];
    const { providerPolicies, setProviderPolicies } = useContext(ProviderPolicyListContext);
    const [policyWordings, setPolicyWordings] = useState([]);
    const [activePolicyType, setActivePolicyType] = useState(null);
    const [uniqueProviders, setUniqueProviders] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const tooltipMappings = {
        "policyNumber": "Don't worry. If you don't know your policy number, use a something  which makes sense to you. e.g. HomePolicy22A",
        "policyProvider": "Can't find your provider? Use .com.au for Australia and .co.nz for New Zealand",
        "policyWording": "Don't know which policy wording, match the effective date.",
    }

    async function fetchProducts() {
        let data = null; // Define data outside try-catch

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/provider_policies`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                data = await response.json();
            } else {
                throw new Error('Failed to fetch');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            if (data) {
                setProviderPolicies(data); // Set state only if data is not null
            }
        }
    }

    useEffect(() => {
        if (country) {
            const filteredProviders = providerPolicies.filter(item => item.country === country);
            const providersNames = filteredProviders.map(item => item.provider_name);
            const uniqueNames = Array.from(new Set(providersNames));
            setUniqueProviders(uniqueNames);
        }

    }, [providerPolicies, country]);



    useEffect(() => {
        if (!providerPolicies || providerPolicies.length < 1) {
            fetchProducts();
        }
    }, []);

    const maxPrice = 3000000; // Maximum value for the slider
    const minPrice = 200000;   // Minimum value for the slider

    const activePolicyTypeImgStyle = {
        width: '200px',
        height: 'auto'
    }

    const handleActiveBoxChange = (index, policyType) => {
        setActivePolicyType(index);
        setPolicyDetails({
            ...policyDetails,
            policyType: policyType.code,
            policyName: policyType.label,
        });


        setTimeout(() => {
            handleNextClick();
        }, 1000); // Change 1000 to however many milliseconds delay you want

    };

    useEffect(() => {
        if (userContext.details?.username !== undefined) {

            const modalKey = `hasSeenFileUploadModal_${userContext.details.username}`;
            const modal = localStorage.getItem(modalKey);

            if (toggleWelcome === true) {
                setShowModal(true);
                onToggle();
            } else {
                if (!hasSeenFileUpload()) {
                    localStorage.setItem(modalKey, 'true'); // Store as string 'true'
                    // TODO - Demo system skips showModal and loads sample data
                    //setShowModal(true);
                }
            }
        }
    }, [userContext.details, toggleWelcome]);

    const [goals, setGoals] = useState(
        [
            { label: 'Find the best coverage', checked: false, type: 'Consumer' },
            { label: 'Compare prices', checked: false, type: 'Consumer' },
            { label: 'Understand my policy', checked: false, type: 'Consumer' },
            { label: 'Evaluate providers', checked: false, type: 'Consumer' },
            { label: 'Identify standard coverage', checked: false, type: 'Consumer' },
            { label: 'Gather information', checked: false, type: 'Consumer' },
            { label: 'Increase lead generation', checked: false, type: 'Broker' },
            { label: 'Provide best interest advice', checked: false, type: 'Broker' },
            { label: 'Streamline client onboarding process', checked: false, type: 'Broker' },
            { label: 'Enhance client retention', checked: false, type: 'Broker' },
            { label: 'Improve time management', checked: false, type: 'Broker' },
            { label: 'Optimize policy renewals', checked: false, type: 'Broker' },
        ]);

    const [brokerGoals, setBrokerGoals] = useState([
    ]);

    function getFirstTrueOption(options) {
        for (const key in options) {
            if (options.hasOwnProperty(key) && options[key]) {
                return key;
            }
        }
        return null; // or a default value if no true option is found
    }

    const [policyDetails, setPolicyDetails] = useState({
        policyWording: { name: '', filename: '', effective_date: '' },
        policyProvider: '',
        policyType: 'home',
        policyName: 'Home',
        policyNumber: '',
        policyPrice: '',
        paymentFrequency: getFirstTrueOption(installmentOptions),
        policyAddress: '',
        periodOfInsuranceStart: new Date(),
        periodOfInsuranceEnd: new Date(),
        homeSumInsured: '1,400,000',
        contentsSumInsured: '0',
        homeUse: getFirstTrueOption(homeUsageOptions),
        optionalBenefits: {
            excessGlass: '',
            excessHearing: '',
            excessEyeWear: '',
        },
        client: {},
        excessDetails: {
            home: '',
            contents: '',
            unoccupied: '',
            naturalDisaster: '',
        },
        premium: {
            home: '',
            contents: '',
            landlord: '',
        },
    });

    useEffect(() => {
        let policyWordingsTemp = [];

        if (gettingInfo === false) {
            if (policyDetails && policyDetails.policyProvider && policyDetails.policyType &&
                providerPolicies && providerPolicies.length > 0) {

                const filteredProviders = providerPolicies.filter(item => {

                    return item.country === country && item.category === policyDetails.policyType && item.provider_name === policyDetails.policyProvider;
                    // return item.category === policyDetails.policyType && item.provider_name === policyDetails.policyProvider;

                });


                filteredProviders.forEach(item => {
                    policyWordingsTemp.push({ name: item.provider_name, filename: item.filename, effective_date: item.effective_date });
                });


                setPolicyWordings(policyWordingsTemp);

                const selectedPolicy = policyWordingsTemp.find(policy => {

                    return policy.name === policyDetails.policyProvider
                });

                if (selectedPolicy) {
                    setPolicyDetails(prevDetails => ({
                        ...prevDetails,
                        policyWording: {
                            name: selectedPolicy.name,
                            filename: selectedPolicy.filename,
                            effective_date: selectedPolicy.effective_date
                        }
                    }));
                } else {
                    setPolicyDetails(prevDetails => ({
                        ...prevDetails,
                        policyWording: {
                            name: policyDetails.policyProvider,
                            filename: 'No supported policy',
                            effective_date: ''
                        }
                    }));

                }
            }
        }
    }, [policyDetails.policyProvider, policyDetails.policyType, gettingInfo]); // Ensure all used variables are in the dependency array


    useEffect(() => {

        if (policyDetails.policyType && policyDetails.policyProvider &&
            policyDetails.policyType !== '' && policyDetails.policyProvider !== '' &&
            policyDetails.policyNumber === "") {

            setPolicyDetails(prevDetails => ({
                ...prevDetails,
                policyNumber: `${prevDetails.policyProvider}-${prevDetails.policyType}`
            }));
        }
    }, [policyDetails.policyType, policyDetails.policyProvider, policyDetails.policyNumber]);

    const labelMappings = {
        'policyName': 'Type',
        'policyNumber': 'Policy Number',
        'policyType': 'Type',
        'policyProvider': 'Provider',
        'policyPrice': 'Cost',
        'paymentFrequency': 'Payments',
        'policyAddress': 'Address',
        'periodOfInsuranceEnd': 'Policy Expires',
        'homeSumInsured': 'Insured Amount',
        'homeUse': 'Usage',
        'excessGlass': 'Glass',
        'excessHearing': 'Hearing Aids',
        'excessEyeWear': 'Eye Wear',
        'home': 'Home Excess',
        'contents': 'Contents Excess',
        'unoccupied': 'Unoccupied Excess',
        'naturalDisaster': 'Natural Disaster Excess'
    };

    const logoImage = {
        width: '27px',
        height: '25px',
    };

    const UploadManualURLStyle = {
        width: '55px',
    };

    const UploadManualURLGreyStyle = {
        width: '55px',
        filter: 'grayscale(100%)',
    };


    useEffect(() => {
        setError('');
        setValidationMessage('');
    }, [policyDetails]);

    const handleOptionClick = (option) => {
        setInstallmentOptions({
            weekly: option === 'weekly',
            monthly: option === 'monthly',
            yearly: option === 'yearly'
        });
        setPolicyDetails({
            ...policyDetails,
            paymentFrequency: option
        });

    };

    useEffect(() => {
        if (policyDetails.policyPrice > 150) {
            handleOptionClick('monthly');
        } else if (policyDetails.policyPrice > 500) {
            handleOptionClick('yearly');
        } else {
            handleOptionClick('weekly');
        }

    }, [policyDetails.policyPrice]);

    const handleError = (message) => {
        setError(message);
        setIsWaiting(false);
    };

    function getLabelName(input) {
        const label = labelMappings[input];

        if (label !== null && label !== undefined) {
            return label
        }

        let formattedString = input.charAt(0).toUpperCase() + input.slice(1);

        formattedString = formattedString.replace(/([A-Z])/g, ' $1').trim();

        return formattedString;
    }

    const validateForm = (fields) => {
        let validation = '';

        for (const key of fields) {
            // Check if the value is empty or null
            if (key === 'policyWording') {
                if (policyDetails.policyWording.filename === 'No supported policy') {
                    validation += `\n${validation === '' ? 'Please set:' : ','} ${getLabelName(key)}`;
                }
            } else if (!policyDetails[key] || policyDetails[key] === '') {
                validation += `\n${validation === '' ? 'Please set:' : ','} ${getLabelName(key)}`;
            }
        }
        if (validation === '') {
            setValidationMessage('');
            return true;
        } else {
            setValidationMessage(validation);
            return false;
        }
    };

    useEffect(() => {
        let timer;
        if (isWaiting && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000); // Update countdown every second (1000ms)
        } else if (isWaiting && countdown === 0) {
            if (gettingInfo) {
                setCountdown(10);
            } else {
                setIsWaiting(false);
            }
        } else {
            clearInterval(timer); // Clear the timer when not waiting or countdown is complete
        }

        // Set a maximum timeout of 10 seconds (10000ms)
        const maxTimeout = setTimeout(() => {
            setIsWaiting(false); // Trigger some action when the timeout is reached
            clearInterval(timer); // Clear the interval timer
        }, 10000); // 10 seconds (10000ms)

        // Cleanup functions
        return () => {
            clearInterval(timer); // Clear the timer on component unmount or state change
            clearTimeout(maxTimeout); // Clear the maximum timeout
        };
    }, [isWaiting, countdown, gettingInfo]);

    const startCountdown = () => {

        setIsWaiting(true);
        setCountdown(30); // Reset the countdown to the initial value
    };

    const setPolicyAsActiveDocument = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}users/api/policy?fileName=${encodeURIComponent(formData.get('filename'))}&policyNumber=${encodeURIComponent(formData.get('policyNumber'))}&policyProvider=${encodeURIComponent(formData.get('policyProvider'))}&policyType=${encodeURIComponent(formData.get('policyType'))}&userName=${encodeURIComponent(userContext.details.username)}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error: ${response.status}`);
            }

            const data = await response.json();
            if (data && data.length > 0) {

                setActiveDocumentName(data[0]);
                return true;
            } else {
                console.error('No data received to set as active document');
                return false;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return false;
        }
    };

    const getFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    function isSimilarProvider(providers, provider) {
        // Check if any provider from the list is a substring of the given provider
        const similar = providers.find(p => provider.includes(p));
        return similar || null;
    }

    const fetchFileInfo = async () => {

        startCountdown();
        // handleNextClick();
        if (!file) {
            console.error('No file selectedH, please upload yor policy schedule to get started.');
            return;
        }
        setGettingInfo(true);
        try {

            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}users/file-info?filename=${encodeURIComponent(policyFileName)}&userName=${encodeURIComponent(userContext.details.username)}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userContext.token}`,
                },
            });
            if (!response.ok) {
                handleError(`HTTP error: ${response.status}`);
                return;
            }
            const data = await response.json();
            if (Object.keys(data).length === 0) {
                handleError('Incomplete data in the response');
                return;
            }
            if (data.policyProvider === null) {
                setValidationMessage("We could not read your policy. Please Skip");
                return;
            }

            const matchedProvider = isSimilarProvider(uniqueProviders, data.policyProvider);



            if (matchedProvider) {
                // Update data.policyProvider to matched provider from uniqueProviders
                data.policyProvider = matchedProvider;

                setPolicyDetails(prevState => ({ ...prevState, ...data }));
                if ('periodOfInsuranceEnd' in policyDetails) {
                    setPolicyDetails(prevDetails => ({
                        ...prevDetails,
                        periodOfInsuranceEnd: new Date(data.periodOfInsuranceEnd)
                    }));
                }
                if ('periodOfInsuranceStart' in policyDetails) {
                    setPolicyDetails(prevDetails => ({
                        ...prevDetails,
                        periodOfInsuranceStart: new Date(data.periodOfInsuranceStart)
                    }));
                }


                setIsWaiting(false);
                setGettingInfo(false);

            } else {
                setValidationMessage("We could not read your policy. We support the following: " + uniqueProviders.join(", "))
            }
        } catch (error) {
            handleError(`Error fetching data from the server: ${error.message}`);
        } finally {
            setIsWaiting(false);
            setGettingInfo(false);
        }
    };

    const prepareFormData = () => {
        const formData = new FormData();
        Object.entries(policyDetails).forEach(([key, value]) => {

            if (typeof value === 'object' && key !== 'periodOfInsuranceStart' && key !== 'periodOfInsuranceEnd' && value !== null) {

                const nestedObject = {};

                // If the value is a nested object, iterate over its properties
                Object.entries(value).forEach(([nestedKey, nestedValue]) => {

                    nestedObject[nestedKey] = nestedValue;

                    // Convert the object to a JSON string and append to formData
                    formData.append(key, JSON.stringify(nestedObject));
                });
                // });
            } else {
                // If the value is not a nested object, append it directly
                formData.append(key, value);
            }
        });
        formData.append('userName', userContext.details.username);
        formData.append('firstName', userContext.details.firstName);
        formData.append('lastName', userContext.details.lastName);
        formData.append('filename', policyFileName);

        return formData;
    };

    const handleFileUpload = async (file) => {

        if (!file) {
            handleError('No file selectedA');
            return;
        }
        if (!userContext) {
            handleError('No user selectedB');
            return;
        }
        try {
            const formData = new FormData();
            formData.append('upload', file);
            formData.append('userName', userContext.details.username);
            const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "users/upload-file", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setPolicyFileName(response.data.filename);
            setIsFileUploadComplete(true);
        } catch (error) {
            handleError(`Error uploading file: ${error.message}`);
            return false;
        }
        handleUploadComplete(true);
        return true;
    };

    const fetchClientData =  async  () => {
        const data = await fetchClients(userContext.details.username,country);
        if (data.length > 0) {
            setClients(data);
        }
    };

    useEffect(() => {
        let isFetching = false;

        const fetchData = async () => {
            if (!isFetching && userContext?.details?.username && clients.length < 1) {

                isFetching = true;
                await fetchClientData();
                isFetching = false;
            }
        };

        fetchData();
    }, []);

    const addNewClient = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/clients?username=${userContext.details.username}&firstname=${client.firstName}&lastname=${client.lastName}&email=${client.email}&phone=${client.phone}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            return response.json();
        } catch (error) {
            console.error('Error adding new client:', error);
        }
    };

    const handlePolicyUpload = async () => {
        if (!userContext) {
            handleError('No user selected');
            return;
        }

        let formData = prepareFormData();
        let filename = policyFileName;


        if (!file) {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_API_ENDPOINT + "users/upload-nofile",
                    formData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userContext.token}`,
                        },
                    }
                );

                // Since you're using axios, the response data is accessed via response.data
                const data = response.data;

                if (!data || !data.filename) {
                    console.error("Incomplete data in the response:", data);
                    handleError("Incomplete data received from server");
                    return; // Stop execution if there's no filename
                }
                if (formData.has('filename')) {
                    formData.delete('filename');
                }

                formData.append('filename', data.filename);
                filename = data.filename;
                setPolicyFileName(filename);

            } catch (error) {
                handleError(`Error fetching data from the server: ${error.message}`);
                return; // Stop execution in case of an error
            }
        }

        try {
            await axios.post(
                process.env.REACT_APP_API_ENDPOINT + "users/upload-policy",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        } catch (error) {
            handleError(`Error uploading policy: ${error.message}`);
            return; // Stop execution in case of an error
        }

        handleUploadComplete(true);

        if (formData.get('filename') === null) {
            formData.append('filename', filename);
        }
        return formData;
    };


    const handleDragOver = (e) => e.preventDefault();

    const handleDrop = (e) => {
        e.preventDefault();
        setFile(e.dataTransfer.files[0]);
    };

    function updatePolicyPrice() {
        setPolicyDetails({
            ...policyDetails,
            policyPrice: formatStringToCurrencyString(policyDetails.policyPrice),
        });
    }

    const handleInputChange = async (e) => {
        setValidationMessage('');
        const { name, value } = e.target;

        // If the field is 'policyAddress', fetch suggestions
        if (name === 'policyAddress') {
            setAddressSuggestions([]);
            const url = country === 'NZ' ? `https://api-nz.addysolutions.com/search?s=${value}` : `https://api-au.addysolutions.com/search?s=${value}`;
            const key = country === 'NZ' ? '32de8f505c014a048bb694fab7543ca8' : `d130b6e06cd4430a84d50504719c0951`;
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        'addy-api-key': key
                    }
                });
                if (response.data.addresses && response.data.addresses.length > 1) {
                    setAddressSuggestions(response.data.addresses);
                }
            } catch (error) {
                console.error('Error fetching address data:', error);
            }
        }

        if (name.includes('.')) {
            const [parentKey, childKey] = name.split('.');
            setPolicyDetails({
                ...policyDetails,
                [parentKey]: {
                    ...policyDetails[parentKey],
                    [childKey]: value
                }
            });
        } else if (name === 'periodOfInsuranceEnd') {
            setPolicyDetails({
                ...policyDetails,
                periodOfInsuranceEnd: value,
            });
        } else if (name === 'periodOfInsuranceStart') {
            setPolicyDetails({
                ...policyDetails,
                periodOfInsuranceStart: value
            });
        } else {
            setPolicyDetails({
                ...policyDetails,
                [name]: value
            });

        }

    };

    const handleClientSelectChange = (selectedClient) => {
        if (selectedClient && selectedClient.personalDetails) {
            setPolicyDetails(prevDetails => ({
                ...prevDetails,
                client: selectedClient
            }));
            setSearchText(`${selectedClient.personalDetails.firstName} ${selectedClient.personalDetails.lastName}`);
        }
        setShowDropdown(false);
    };

    useEffect(() => {
        if (activeClient !== undefined) {
            handleClientSelectChange(activeClient)
        }
    }, [activeClient]);

    useEffect(() => {
        if (searchText.length >= 2) {
            const filtered = clients.filter(client =>
                `${client.personalDetails.firstName} ${client.personalDetails.lastName}`
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            );
            setFilteredClients(filtered);
        } else {
            setFilteredClients(clients);
        }
    }, [searchText, clients]);


    const handlePolicyWordingSelectChange = (event) => {
        // Assume the value from the select is a string identifier that can be used to find the full policy details
        const selectedValue = event.target.value;
        // Find the selected policy details based on some identifier (assuming it's the name for this example)
        const selectedPolicy = policyWordings.find(policy => policy.name === selectedValue);

        if (selectedPolicy) {
            setPolicyDetails(prevDetails => ({
                ...prevDetails,
                policyWording: {
                    name: selectedPolicy.name,
                    filename: selectedPolicy.filename,
                    effective_date: selectedPolicy.effective_date
                }
            }));
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setPolicyDetails(prevDetails => ({
            ...prevDetails,
            policyAddress: suggestion
        }));
        setAddressSuggestions([]);
    };

    const handleClientInformation = (e) => {
        const { name, value } = e.target;
        setSearchText('');
        const keys = name.split('.');

        setPolicyDetails((prevDetails) => {
            // Use a recursive helper function to update the nested property
            const updateNestedValue = (obj, keys, value) => {
                const [firstKey, ...remainingKeys] = keys;

                if (remainingKeys.length === 0) {
                    // Base case: if there are no more keys, set the value
                    return {
                        ...obj,
                        [firstKey]: value,
                    };
                }

                // Recursive case: update the next level of the object
                return {
                    ...obj,
                    [firstKey]: updateNestedValue(obj[firstKey] || {}, remainingKeys, value),
                };
            };

            return updateNestedValue(prevDetails, keys, value);
        });
    };

    useEffect(() => {
        if (file && userContext !== undefined && userContext !== null) {
            if (isFileUploadComplete) {
                setIsFileUploadComplete(false);
                fetchFileInfo();
            }
        }
    }, [isFileUploadComplete]);

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            setValidationMessage("Error: No file selectedF");
            setFile(null);
        } else if (selectedFile.type !== 'application/pdf') {
            setValidationMessage("Error: Only PDF files are supported");
            setFile(null);
        } else {
            setValidationMessage("");
            setFile(selectedFile);
        }
        e.target.value = null; // Clear the file input
    };

    useEffect(() => {
        handleFileUpload(file);
    }, [file]);

    const goToCard = (number) => {
        setCardNumber(number);
    };

    const handleNextClick = () => {
        setCardNumber(cardNumber + 1);
    };

    const handleBackClick = () => {

        if (cardNumber > 1) {
            setCardNumber(cardNumber - 1);
        }
        setValidationMessage('');
    };

    const testPrice = () => {
        if (policyDetails.policyPrice === "$0.00") {
            setValidationMessage("Amount cannot be $0.00");
            return false;
        }
        return true;

    }

    const handleNext = (fields) => {

        if (error !== '' || validationMessage !== '') {
            return;
        } else {
            if (fields === null) {
                handleNextClick();
            } else if (validateForm(fields)) {
                if (cardNumber === 4 && !testPrice()) {
                    return;
                }
                handleNextClick();
            }
        }
    };

    const saveClient = async () => {
        await addNewClient();
        handleNext(null);
    }

    const resetGoals = () => {
        const resetGoals = goals.map(goal => {
            return { ...goal, checked: false };
        });
        setGoals(resetGoals);
    };

    const reInitalise = () => {

        goToCard(1);
        setValidationMessage('');
        setFile(null);
        resetGoals();
        setPolicyDetails({
            policyProvider: '',
            policyType: 'home',
            policyName: 'Home',
            policyNumber: '',
            policyPrice: '',
            paymentFrequency: getFirstTrueOption(installmentOptions),
            policyAddress: '',
            periodOfInsuranceStart: new Date(),
            periodOfInsuranceEnd: new Date(),
            homeSumInsured: '$1,400,000',
            contentsSumInsured: '0',
            homeUse: getFirstTrueOption(homeUsageOptions),
            policyWording: { name: '', filename: '', effective_date: '' },
            optionalBenefits: {
                excessGlass: '',
                excessHearing: '',
                excessEyeWear: '',
            },
            excessDetails: {
                home: '',
                contents: '',
                unoccupied: '',
                naturalDisaster: '',
            },
            premium: {
                home: '',
                contents: '',
                landlord: '',
            },
        });
    }

    const onClose = () => {
        setShowModal(false);
        reInitalise();
    }

    const handleClose = () => {
        if (validateForm(mandatory)) {
            try {
                handlePolicyUpload()
                    .then(data => {
                        setUploadedFile(data);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (error) {
                console.error(error);
            } finally {
                setShowModal(false);
                reInitalise();
                navigate('/quote');
                // TODO Set active document.
            }
        }
    };

    async function trySetPolicyAsActiveDocument(uploadedFile, attempts = 0) {


        let success = await setPolicyAsActiveDocument(uploadedFile);


        if (!success && attempts < 10) { // Assuming there's a limit to how many times you want to retry
            setTimeout(() => {
                trySetPolicyAsActiveDocument(uploadedFile, attempts + 1);
            }, 2000);
        }
    }

    useEffect(() => {

        if (uploadedFile && uploadedFile !== undefined && uploadedFile !== null &&
            userContext.details !== null && userContext.details !== undefined) {
            trySetPolicyAsActiveDocument(uploadedFile);
        }
    }, [uploadedFile]);

    // Updated snippet for the policyName dropdown
    const handlePolicyTypeChange = (event) => {
        const selectedPolicyType = policyTypes.find(policy => policy.code === event.target.value);
        setPolicyDetails({
            ...policyDetails,
            policyType: selectedPolicyType ? selectedPolicyType.code : 'home', // Fallback to home
            policyName: selectedPolicyType ? selectedPolicyType.label : 'Home', // Fallback to Home
        });
    };

    const toggleGoal = (label) => {

        const newGoals = goals.map((goal, i) => {
            if (goal.label === label) {

                return { ...goal, checked: !goal.checked };
            }
            return goal;
        });
        setGoals(newGoals);
    };

    const homeSumInsuredValue = parseFloat(policyDetails.homeSumInsured.replace('$', '').replace(/,/g, '').replace(/\./g, ''));
    const sliderPosition = (homeSumInsuredValue - minPrice) / (maxPrice - minPrice) * 100;


    function formatPriceLabel(value) {
        if (value >= 1e6) { // For values in millions
            return `${(value / 1e6).toFixed(0)}m`;
        } else if (value >= 1e3) { // For values in thousands
            return `${(value / 1e3).toFixed(0)}k`;
        } else {
            return value.toString(); // For values less than 1000
        }
    }

    function hasSeenFileUpload() {
        const modalKey = `hasSeenFileUploadModal_${userContext.details.username}`;
        if (userContext && userContext.details) {
            const modal = localStorage.getItem(modalKey);
            return modal === 'true';
        } else {
            return false;
        }
    }

    function formatToCurrency(amount) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // You can use these options to control the display of the currency
            minimumFractionDigits: 0, // Minimum number of decimal places to show
            maximumFractionDigits: 0  // Maximum number of decimal places to show
        }).format(amount);
    }

    function handleSliderChange(event) {
        // Update the policyDetails state with the new value

        setPolicyDetails({
            ...policyDetails,
            homeSumInsured: formatToCurrency(event.target.value)
        });
    }

    const handleImportanceASliderChange = (e) => {
        const value = parseInt(e.target.value, 10);
        const nearest = snapImportantPoints.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        setImportancePositionA(nearest);
    };

    const handleImportanceBSliderChange = (e) => {
        const value = parseInt(e.target.value, 10);
        const nearest = snapImportantPoints.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        setImportancePositionB(nearest);
    };

    const handleUseageOptionClick = (option) => {
        setPolicyDetails(prevDetails => ({
            ...prevDetails,
            homeUse: option
        }));
        setHomeUsageOptions(prevOptions => {
            // Set all options to false except for the one clicked
            return Object.keys(prevOptions).reduce((options, key) => {
                options[key] = key === option;
                return options;
            }, {});
        });

    };

    function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function renderPolicyTypeInput() {
        return (
            <>
                <div className="upload-container-row">
                    <div className="upload-container-column-no-gap">
                        <div className="upload-container-option-header">
                            <div className="upload-container-option-header-title">Select your policy type</div>
                        </div>
                        <div className="upload-container-row">
                            <div className="upload-container-box-grid">
                                {policyTypes.map((policyType, index) => (
                                    <div className="upload-container-box-grid-item" key={index}>
                                        <div
                                            className={`upload-container-box ${activePolicyType === index ? 'upload-container-box-active' : ''} ${!policyType.enabled ? 'upload-container-box-disabled' : 'upload-container-box-grid-item-hover'}`}
                                            onClick={() => policyType.enabled && handleActiveBoxChange(index, policyType)}
                                        >
                                            <img
                                                src={policyType.icon || defaultIcon}
                                                alt={policyType.label}
                                                style={activePolicyTypeImgStyle}
                                                className={`${!policyType.enabled ? 'upload-container-box-img-disabled' : ''}`}
                                            />
                                        </div>
                                        <div className={`upload-container-box-grid-item-title ${!policyType.enabled ? 'upload-container-box-grid-item-title-disabled' : ''}`}>
                                            {policyType.label}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upload-buttons">
                    <button className="upload-buttons-back" onClick={handleBackClick}>Back</button>
                </div>
            </>
        )
    };


    function updatePolicyDetailsInput(label, e) {
        setPolicyDetails({
            ...policyDetails,
            [label]: e.target.value,
        })
        setValidationMessage('');
    }
    const handleDateInputClick = (event) => {
        // Force the focus back to the input which may open the calendar

        if (dateInputRef.current) {
            dateInputRef.current.focus();  // Explicitly set focus
        }
    };

    return (
        <>
            {showModal && (
                <div className="upload-container">
                    {activeDocument && hasSeenFileUpload() && (
                        <div className="file-upload-container-close" onClick={onClose}>
                            &times;
                        </div>
                    )}
                    <div className="upload-header">
                        <div className="upload-header-link-logo" onClick={hasSeenFileUpload() ? onClose : logoutHandler}>
                            <img src={LogoURL} className="file-upload-company-logo" alt="Logo" />
                            <div className="file-upload-company-name"><span style={{ color: 'rgb(79,166,88)' }}>POLICY</span><span style={{ color: 'white' }}>CHECK</span></div>
                        </div>
                    </div>
                    <div className="upload-body">
                        {cardNumber !== 1 && (
                            <div className="upload-step-number">SETUP &nbsp;
                                <span className="upload-step-number-grey" style={{ width: '200px' }}>
                                    <ProgressBar progress={(cardNumber / (userContext.details.type === 'Consumer' ? 10 : 9)) * 100} />
                                </span>
                            </div>
                        )}
                        {cardNumber === 1 && (
                            <div className="upload-container-row" >
                                <div className="upload-container-column">
                                    <div className="upload-container-option-header">
                                        <div className="upload-container-option-header-title-left">Check <span style={{ color: 'rgb(79,166,88)' }}>insurance</span> policy</div>
                                        <div className="upload-container-option-header-content">Complete the needs assessment to see insurance options & analysis. </div>
                                        <br />
                                        <div className="upload-container-option-header-content">It’ll only take 5 minutes. </div>
                                    </div>
                                </div>
                                <div className="upload-container-column-options">

                                    {/* TODO */}
                                    {/* <div className="upload-container-option" onClick={() => setShowPopup(!showPopup)}> */}
                                        <div className="upload-container-option" onClick={handleNextClick}>
                                        <img src={UploadURL} style={UploadManualURLStyle} alt="" />
                                        <div className="upload-container-option-title">
                                            Upload an existing policy
                                        </div>
                                        <div className="upload-container-option-next" >&gt;
                                        </div>

                                        {showPopup && (
                                            <div className="upload-popup-content" onClick={() => setShowPopup(!showPopup)}>
                                                Feature coming soon.
                                            </div>
                                        )}
                                    </div>
                                    <div className="upload-container-option" onClick={() => goToCard(userContext.details.type === 'Consumer' ? 5 : 4)}>
                                        <img src={ManualURL} style={UploadManualURLStyle} alt="" />
                                        <div className="upload-container-option-title">
                                            I don't have a policy
                                        </div>
                                        <div className="upload-container-option-next" >&gt;
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {cardNumber === 2 && (
                            <>
                                {renderPolicyTypeInput()}
                            </>
                        )}
                        {cardNumber === 3 && (<>
                            <div className="upload-container-option-header">
                                Documents
                            </div>
                            {/* TODO */}
                            <div className="upload-container-row">
                                <div className="upload-container-column-no-gap">
                                    <div className="upload-container-option-header">
                                        <Tooltip width={'300px'} text="Your policy schedule contains important information related to this policy document, including who’s insured, your cover type, your agreed value and excesses.">
                                            <div className="upload-container-option-header-title">{country === 'NZ' ? 'Policy Schedule' : 'Insurance Certificate'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className="upload-container-center">
                                        <input
                                            required
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileSelect(e)}
                                        />
                                        <div className="file-upload-button-area">
                                            {isWaiting ? (
                                                <>
                                                    <div className="upload-waiting-message-detailed">Just a tick while we review your policy...</div>
                                                    <WaitingDiv />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src={FileUploadURL}
                                                        className={`file-upload-image ${file ? '' : 'file-upload-image-grey'}`}
                                                        alt=""
                                                        onClick={() => document.getElementById('fileInput').click()}
                                                    />
                                                    <div className="file-upload-filename">
                                                        {(file && !gettingInfo && validationMessage === '' && error === '') && (
                                                            <img src={logoDoneURL} alt="" style={{ width: '25px', paddingTop: '50px', paddingBottom: '50px' }} />
                                                        )}
                                                        {(!gettingInfo && (validationMessage !== '' || error !== '')) && (
                                                            <img src={logoFailedURL} alt="" style={{ width: '25px', paddingTop: '50px', paddingBottom: '50px' }} />
                                                        )}
                                                        {getFileName(file)}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="upload-container-column-no-gap">
                                    <div className="upload-container-option-header">
                                        <Tooltip width={'300px'} text="This document explains your insurance cover, the benefits you’ll receive, your responsibilities and how to make a claim.">
                                            <div className="upload-container-option-header-title">{country === 'NZ' ? 'Policy Doument(s)' : 'Public Disclosure Statement'}</div>
                                        </Tooltip>
                                    </div>
                                    <div className="upload-container-center">
                                        <input
                                            required
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileSelect(e)}
                                        />
                                        <div className="file-upload-button-area">
                                            <img
                                                src={FileUploadURL}
                                                className={`file-upload-image ${policyWordings.length > 0 ? '' : 'file-upload-image-grey'}`}
                                                alt=""
                                            // onClick={() => document.getElementById('fileInput').click()}
                                            />
                                            <select
                                                onChange={handlePolicyWordingSelectChange}
                                                className="policy-wording-select"
                                                // defaultValue={'Please select a PDS...'}
                                                value={policyDetails.policyWording}>
                                                {policyWordings.map((policy, index) => (
                                                    <option key={index} value={policy.name}>
                                                        {policy.name}
                                                    </option>
                                                ))}
                                                <option>Please add a PDS...</option>
                                            </select>
                                            {policyDetails?.policyWording && (
                                                <div className="file-upload-policywording">
                                                    <>
                                                        {policyDetails.policyWording.filename !== '' && (
                                                            <>
                                                                <span><strong>Policy Name: </strong></span>{policyDetails.policyWording.filename}
                                                            </>
                                                        )}
                                                        {policyDetails.policyWording.effective_date !== '' && (
                                                            <>
                                                                <span><strong>Effective Date: </strong></span> {policyDetails.policyWording.effective_date}
                                                            </>
                                                        )}
                                                    </>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="upload-container-column-no-gap">
                                    <div className="upload-container-option-header">
                                        <Tooltip width={'300px'} text="If you are with a broker, they may have negociated additional terms & conditions for you">
                                            <div style={{ color: '#C7C7C7' }} className="upload-container-option-header-title">Policy Variations</div>
                                        </Tooltip>
                                    </div>
                                    <div className="upload-container-center"  >
                                        <input
                                            required
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileSelect(e)}
                                        />
                                        <div className="file-upload-button-area"
                                            style={{ border: 'dashed 2px #C7C7C7' }}>
                                            <img
                                                src={FileUploadURL}
                                                className={`file-upload-image ${fileVariation ? '' : 'file-upload-image-grey'}`}
                                                alt=""
                                            // onClick={() => document.getElementById('fileInput').click()}
                                            />
                                            <div style={{ color: '#C7C7C7' }} className="file-upload-filename">Support coming soon.</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="upload-buttons">
                                <button className="upload-buttons-back" onClick={handleBackClick}>Back</button>
                                {validationMessage !== '' || error !== '' ? (
                                    <button className="upload-buttons-next" onClick={() => goToCard(userContext.details.type === 'Consumer' ? 5 : 4)}>Skip <span className="upload-buttons-arrow">&rarr;</span></button>
                                ) : (
                                    <button className="upload-buttons-next" onClick={() => handleNextClick()}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                )}
                            </div>
                        </>
                        )}
                        {cardNumber === 4 && (
                            <>
                                <div className="upload-container-row">
                                    <div className="upload-container-column-no-gap">
                                        <div className="upload-container-option-header">
                                            <div className="upload-container-option-header-title">
                                                {!hasSeenFileUpload() ? 'Add your first client' : (
                                                    <>
                                                        Select a Client
                                                        {/* <select
                                                            onChange={handleClientSelectChange}
                                                            className="policy-wording-select"
                                                            defaultValue={'Please select a client...'}
                                                            value={policyDetails?.client?._id || 'Please add a client..'} // Assuming client is an object with an id property
                                                        >
                                                            {clients && clients.length > 0 && (
                                                                clients.map(client => (
                                                                    <option key={client._id} value={client._id}>
                                                                        {client.personalDetails.firstName} {client.personalDetails.lastName}
                                                                    </option>
                                                                ))
                                                            )}
                                                            <option>Please add a client...</option>
                                                        </select> */}

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="upload-inputs">
                                            <div className="upload-inputs-search">
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        style={{ minWidth: '200px' }}
                                                        type="text"
                                                        placeholder="Search for existing client..."
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                        onFocus={() => {
                                                            setSearchText(''); // Clear the text when input is focused
                                                            setShowDropdown(true);
                                                        }}
                                                        onBlur={() => {
                                                            setTimeout(() => setShowDropdown(false), 100);
                                                        }}
                                                        className="policy-wording-select"
                                                    />
                                                    {showDropdown && (
                                                        <div style={{
                                                            maxHeight: '40vh',
                                                            overflowY: 'auto',
                                                            border: '1px solid #ccc',
                                                            position: 'absolute',
                                                            top: '50px',
                                                            backgroundColor: 'white',
                                                            width: '100%',
                                                            left: 0,
                                                            zIndex: 1000,
                                                        }}>
                                                            {filteredClients.map(client => (
                                                                <div
                                                                    key={client.id}
                                                                    onMouseDown={() => handleClientSelectChange(client)}
                                                                    style={{ padding: '8px', cursor: 'pointer' }}
                                                                >
                                                                    {client.personalDetails.firstName} {client.personalDetails.lastName}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                {searchText !== '' && (
                                                    <label className="upload-inputs-search-label" >
                                                        or update below to a new client
                                                    </label>
                                                )}
                                            </div>
                                            <div className="upload-inputs-div" />
                                            <label className="upload-input-label" >
                                                First Name
                                            </label>
                                            <input
                                                className="upload-input-text"
                                                type="text"
                                                name="client.personalDetails.firstName"
                                                placeholder="First Name"
                                                value={policyDetails.client?.personalDetails?.firstName || ''}
                                                onChange={handleClientInformation}
                                                autoComplete="given-name"
                                            />
                                            <label className="upload-input-label" >
                                                Last Name
                                            </label>
                                            <input
                                                className="upload-input-text"
                                                type="text"
                                                name="client.personalDetails.lastName"
                                                placeholder="Last Name"
                                                value={policyDetails.client?.personalDetails?.lastName || ''}
                                                onChange={handleClientInformation}
                                                autoComplete="given-name"
                                            />
                                            <label className="upload-input-label" >
                                                Email
                                            </label>
                                            <input
                                                className="upload-input-text"
                                                type="email"
                                                name="client.contactInformation.email"
                                                placeholder="Email"
                                                value={policyDetails.client?.contactInformation?.email || ''}
                                                onChange={handleClientInformation}
                                                autoComplete="email"
                                            />
                                            <label className="upload-input-label" >
                                                Phone
                                            </label>
                                            <input
                                                className="upload-input-text"
                                                type="phone"
                                                name="client.contactInformation.phone"
                                                placeholder="Phone"
                                                value={policyDetails.client?.contactInformation?.phone || ''}
                                                onChange={handleClientInformation}
                                                autoComplete="phone"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="upload-buttons">
                                    <button className="upload-buttons-back" onClick={() => goToCard(1)}>Back</button>
                                    <button disabled={policyDetails.client?.contactInformation?.email === ''} className={`${policyDetails.client?.contactInformation?.email === '' ? 'upload-buttons-back' : 'upload-buttons-next'}`}
                                        onClick={() => handleNextClick()}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                </div>
                            </>

                        )}
                        {cardNumber === 5 && (
                            <>
                                <div className="upload-container-row">
                                    <div className="upload-container-column-no-gap">
                                        <div className="upload-container-option-header">
                                            <div className="upload-container-option-header-title">{userContext.details.type === 'Broker' ? 'How much is the policy?' : 'How much are you paying?'}</div>
                                        </div>
                                        <div className="upload-inputs">
                                            <div className="upload-input">
                                                <label className="upload-input-label">Amount
                                                    {validationMessage.includes('Amount') && (
                                                        <span className="upload-label-missing">*</span>
                                                    )}
                                                </label>
                                                <input className="upload-input-text" type="text" placeholder="$" value={policyDetails.policyPrice}
                                                    onChange={(e) => updatePolicyDetailsInput('policyPrice', e)}
                                                    onBlur={updatePolicyPrice}
                                                //  onKeyPress={updatePolicyPrice}
                                                />
                                            </div>
                                            <div className="upload-input">
                                                <label className="upload-input-label">Frequency</label>
                                                <div className="upload-dot-box">
                                                    <div className="upload-dot" onClick={() => handleOptionClick('weekly')}>
                                                        <div className="upload-input-label">Weekly</div>
                                                        <div className={`upload-dot-circle-large ${installmentOptions.weekly ? 'option-circle-selected' : ''}`}>
                                                            <div className={`upload-dot-circle-small ${installmentOptions.weekly ? 'option-circle-small-selected' : ''}`} />
                                                        </div>
                                                    </div>
                                                    <div className="upload-dot" onClick={() => handleOptionClick('monthly')}>
                                                        <div className="upload-input-label">Monthly</div>
                                                        <div className={`upload-dot-circle-large ${installmentOptions.monthly ? 'option-circle-selected' : ''}`}>
                                                            <div className={`upload-dot-circle-small ${installmentOptions.monthly ? 'option-circle-small-selected' : ''}`} />
                                                        </div>
                                                    </div>
                                                    <div className="upload-dot" onClick={() => handleOptionClick('yearly')}>
                                                        <div className="upload-input-label">Yearly</div>
                                                        <div className={`upload-dot-circle-large ${installmentOptions.yearly ? 'option-circle-selected' : ''}`}>
                                                            <div className={`upload-dot-circle-small ${installmentOptions.yearly ? 'option-circle-small-selected' : ''}`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="upload-buttons">
                                    <button className="upload-buttons-back" onClick={() => goToCard(1)}>Back</button>
                                    <button className="upload-buttons-next" onClick={() => handleNext(['policyPrice', 'paymentFrequency'])}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                </div>
                            </>

                        )}
                        {cardNumber === 6 && (
                            <>
                                <div className="upload-container-row">
                                    <div className="upload-container-column-no-gap">
                                        <div className="upload-container-option-header">
                                            <div className="upload-container-option-header-title">{file ? 'Review your' : 'Your'} policy details</div>
                                        </div>
                                        <div className="upload-inputs">
                                            {card5KeysToInclude.map(key => {
                                                const value = policyDetails[key]; // Access the value from policyDetails based on the key
                                                return (
                                                    <div key={key} className="upload-input">
                                                        <label className="upload-input-label upload-input-label-large" htmlFor={key}>

                                                            <Tooltip text={tooltipMappings[key] ? tooltipMappings[key] : ''}>
                                                                {getLabelName(key)}
                                                            </Tooltip>
                                                            {key === 'policyName' && (
                                                                <>
                                                                    <select
                                                                        className="upload-input-text-row"
                                                                        value={policyDetails.policyType}
                                                                        onChange={handlePolicyTypeChange}
                                                                    >
                                                                        {policyTypes.map((policyType, index) => (
                                                                            <option
                                                                                key={index}
                                                                                value={policyType.code}
                                                                                disabled={!policyType.enabled}
                                                                            >
                                                                                {policyType.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            )}
                                                            {key === 'policyNumber' && (
                                                                <>
                                                                    {validationMessage.includes('Number') && (
                                                                        <span className="upload-label-missing">*</span>
                                                                    )}
                                                                    <input
                                                                        className="upload-input-text-row"
                                                                        type="text"
                                                                        id={key}
                                                                        name={key}
                                                                        value={value}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </>
                                                            )}
                                                            {key === 'periodOfInsuranceEnd' && (
                                                                <>
                                                                    {validationMessage.includes('Expires') && (
                                                                        <span className="upload-label-missing">*</span>
                                                                    )}
                                                                    <div className="upload-input-text-row-date">
                                                                        <DatePicker
                                                                            id={key}
                                                                            name={key}
                                                                            selected={policyDetails.periodOfInsuranceEnd}
                                                                            onChange={(date) => {


                                                                                setPolicyDetails({ ...policyDetails, periodOfInsuranceEnd: new Date(date) });
                                                                            }}
                                                                            dateFormat="dd/MM/yyyy"  // Setting the date format to Day-Month-Year
                                                                            className="upload-input-text-date"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {key === 'policyProvider' && (
                                                                <>
                                                                    <select className="upload-input-text-row" value={policyDetails.policyProvider} onChange={(e) => setPolicyDetails({ ...policyDetails, policyProvider: e.target.value })}>
                                                                        <option value="default">---</option>
                                                                        {uniqueProviders.sort().map(provider => (
                                                                            <option key={provider} value={provider}>
                                                                                {provider}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            )}
                                                            {key === 'policyWording' && (
                                                                <>
                                                                    <select
                                                                        onChange={handlePolicyWordingSelectChange}
                                                                        className="upload-input-text-row"
                                                                        // defaultValue={'Please select a PDS...'}
                                                                        value={policyDetails.policyWording}
                                                                    >
                                                                        {policyWordings.map((policy, index) => (
                                                                            <option key={index} value={policy.name}>
                                                                                {policy.name} - {policy.filename}{policy.effective_date ? ` - ${policy.effective_date}` : ''}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            )}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="upload-buttons">
                                    <button className="upload-buttons-back" onClick={handleBackClick}><span className="upload-buttons-arrow">&larr;</span></button>
                                    <button className="upload-buttons-next" onClick={() => handleNext(['policyName', 'policyType', 'policyNumber', 'policyProvider', 'policyWording', 'periodOfInsuranceEnd'])}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                </div>
                            </>
                        )}
                        {cardNumber === 7 && (
                            <>
                                <div className="upload-container-row">
                                    <div className="upload-container-column-no-gap">
                                        <div className="upload-container-option-header">
                                            <div className="upload-container-option-header-title">Changes in circumstance</div>
                                        </div>
                                        <div className="upload-inputs">
                                            {Object.entries(policyDetails).filter(([key]) => card6KeysToInclude.includes(key)).map(([key, value]) => (
                                                <div key={key} className="upload-input">
                                                    <label className="upload-input-label" htmlFor={key}>{getLabelName(key)}
                                                        {key === 'policyAddress' && validationMessage.includes('Address') && (
                                                            <span className="upload-label-missing">*</span>
                                                        )}
                                                    </label>
                                                    {key === 'homeSumInsured' && (
                                                        <>
                                                            <div className="slider-container">
                                                                <div className="slider-labels">
                                                                    <span>{formatPriceLabel(minPrice)}</span>
                                                                    <span>{formatPriceLabel(maxPrice)}</span>
                                                                </div>
                                                                <input
                                                                    type="range"
                                                                    min={minPrice}
                                                                    max={maxPrice}
                                                                    value={homeSumInsuredValue || 0}
                                                                    onChange={handleSliderChange}
                                                                    className="hidden-slider" // Hide this slider visually
                                                                />
                                                                <div className="slider-track">
                                                                    <div className="slider-fill" style={{ width: `${sliderPosition}%` }}></div>
                                                                    <div className="slider-handle" style={{ left: `${sliderPosition}%` }}>
                                                                        <div className="slider-value">{`${policyDetails.homeSumInsured}`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {key !== 'homeSumInsured' && (
                                                        <>
                                                            {key !== 'policyAddress' && (
                                                                <input
                                                                    className="upload-input-text"
                                                                    type={`${key !== 'periodOfInsuranceEnd' ? 'text' : 'date'}`}
                                                                    id={key}
                                                                    name={key}
                                                                    value={value}
                                                                    onChange={handleInputChange}
                                                                />
                                                            )}
                                                            {key === 'policyAddress' && (<>
                                                                <input
                                                                    className="upload-input-text"
                                                                    type={`${key !== 'periodOfInsuranceEnd' ? 'text' : 'date'}`}
                                                                    id={key}
                                                                    name={key}
                                                                    value={value}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {addressSuggestions.length > 0 && (
                                                                    <>
                                                                        <ul className="address-suggestions">
                                                                            {addressSuggestions.map((suggestion, index) => (
                                                                                <li key={index} onClick={() => handleSuggestionClick(suggestion.a)}>
                                                                                    {suggestion.a}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </>
                                                                )}
                                                            </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                            <div className="file-upload-input-checkbox-component">
                                                <label className="upload-input-label">Home Usage</label>
                                                <div className="upload-dot-box-grid">
                                                    {Object.entries(homeUsageOptions).map(([option, isSelected]) => (
                                                        <div className="upload-dot" key={option} onClick={() => handleUseageOptionClick(option)}>
                                                            <div className="upload-input-label">{option.charAt(0).toUpperCase() + option.slice(1)}</div>
                                                            <div className={`upload-dot-circle-large ${isSelected ? 'option-circle-selected' : ''}`}>
                                                                <div className={`upload-dot-circle-small ${isSelected ? 'option-circle-small-selected' : ''}`} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="upload-buttons">
                                    <button className="upload-buttons-back" onClick={handleBackClick}><span className="upload-buttons-arrow">&larr;</span></button>
                                    {!hasSeenFileUpload() && (
                                        <button className="upload-buttons-next" onClick={() => handleNext(['policyAddress', 'homeSumInsured', 'homeUse'])}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                    )}
                                    {hasSeenFileUpload() && (
                                        <button className="upload-buttons-next" onClick={handleClose}>Finish</button>
                                    )}
                                </div>
                            </>
                        )}
                        {cardNumber === 8 && !hasSeenFileUpload() && (<>
                            <div className="upload-container-row" style={{ width: '100%' }}>
                                <div className="upload-container-column-no-gap">
                                    <div className="upload-container-option-header">
                                        <div className="upload-container-option-header-title">How can we help?</div>
                                        <div className="upload-input-label">Tell us your goals and we’ll help you nail them.</div>
                                    </div>

                                    <div className="upload-inputs" style={{ 'maxWidth': '1000px' }}>
                                        <div className="upload-input">
                                            <div className="upload-square-box">
                                                {goals
                                                    .filter(goal => goal.type === userContext.details.type)
                                                    .map((goal, index) => (
                                                        <div key={index} className={`upload-box ${goal.checked ? 'option-box-selected-border' : ''}`} onClick={() => toggleGoal(goal.label)}>
                                                            <div className="upload-input-label">{goal.label}</div>
                                                            <div className={`upload-dot-box-large ${goal.checked ? 'option-box-selected' : ''}`}>
                                                                {goal.checked && (<>&#10003;</>)}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {userContext.details.type === 'Consumer' && (
                                <div className="upload-buttons">
                                    <button className="upload-buttons-back" onClick={handleBackClick}><span className="upload-buttons-arrow">&larr;</span></button>
                                    <button className="upload-buttons-next" onClick={() => handleNext(null)}>Next <span className="upload-buttons-arrow">&rarr;</span></button>
                                </div>
                            )}
                        </>
                        )}
                        {cardNumber === 9 && !hasSeenFileUpload() && userContext.details.type === 'Consumer' && (
                            <div className="upload-container-row" style={{ width: '100%' }}>
                                <div className="upload-container-column-no-gap">
                                    <div className="upload-container-option-header">
                                        <div className="upload-container-option-header-title">
                                            What is more important?
                                        </div>
                                    </div>
                                    <div className="upload-inputs" style={{ 'max-width': '1000px' }}>
                                        <div className="upload-input-questions">
                                            <div className="importance-slider-container-question">Price or Coverage?</div>
                                            <div className="importance-slider-container">
                                                <div className="importance-slider-labels">
                                                    <span className="importance-slider-label-left">Lower<br />Price</span>
                                                    <span className="importance-slider-label-right">Best<br />Coverage</span>
                                                </div>
                                                <div className="importance-slider">
                                                    <input
                                                        type="range"
                                                        min={0}
                                                        max={100}
                                                        value={importancePositionA}
                                                        onChange={handleImportanceASliderChange}
                                                        className="importance-hidden-slider"
                                                    />
                                                    <div className="importance-slider-track">
                                                        {snapImportantPoints.map((point, index) => (
                                                            <div
                                                                key={index}
                                                                className={`importance-slider-circle${importancePositionA === point ? ' active' : ''}`}
                                                                style={{ left: `${point}%` }}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="upload-input-questions">
                                            <div className="importance-slider-container-question">Lower Price or Lower Excess?</div>
                                            <div className="importance-slider-container">
                                                <div className="importance-slider-labels">
                                                    <span className="importance-slider-label-left">Lower<br />Excess</span>
                                                    <span className="importance-slider-label-right">Lower<br />Price</span>
                                                </div>
                                                <div className="importance-slider">
                                                    <input
                                                        type="range"
                                                        min={0}
                                                        max={100}
                                                        value={importancePositionB}
                                                        onChange={handleImportanceBSliderChange}
                                                        className="importance-hidden-slider"
                                                    />
                                                    <div className="importance-slider-track">
                                                        {snapImportantPoints.map((point, index) => (
                                                            <div
                                                                key={index}
                                                                className={`importance-slider-circle${importancePositionB === point ? ' active' : ''}`}
                                                                style={{ left: `${point}%` }}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {cardNumber > 1 && (<>
                            {validationMessage && <div className="file-upload-validation-message"><p>{validationMessage}</p></div>}
                        </>
                        )}
                        {((cardNumber === 9 && userContext.details.type === 'Consumer') || (cardNumber === 8 && userContext.details.type === 'Broker')) && (
                            <div className="upload-buttons">
                                <button className="upload-buttons-back" onClick={handleBackClick}><span className="upload-buttons-arrow">&larr;</span></button>
                                <button className="upload-buttons-next" onClick={handleClose}>Finish</button>
                            </div>
                        )}
                    </div>
                </div >
            )
            }
        </>
    );
}

export default FileUpload;
