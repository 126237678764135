import React from 'react';

const employmentStatusOptions = ['Employed', 'Unemployed', 'Self-Employed', 'Retired'];

const SituationalTab = ({ clientData, handleChange }) => {
  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">Financial Information</div>
        <div className="client-details-popup modal-subgrid">
          <div className="client-details-popup flex-row">
            <div className="client-details-popup modal-grid-item-label-sub">Annual Income:</div>
            <input className="client-details-popup modal-grid-item-label-input" type="number" name="financialInformation.annualIncome" value={clientData.financialInformation.annualIncome} onChange={handleChange} />
          </div>
          <div className="client-details-popup flex-row">
            <div className="client-details-popup modal-grid-item-label-sub">Employment Status:</div>
            <select className="client-details-popup modal-grid-item-label-input" name="financialInformation.employmentStatus" value={clientData.financialInformation.employmentStatus} onChange={handleChange}>
              {employmentStatusOptions.map(status => <option key={status} value={status}>{status}</option>)}
            </select>
          </div>
          <div className="client-details-popup flex-row">
            <div className="client-details-popup modal-grid-item-label-sub">Credit Score:</div>
            <input className="client-details-popup modal-grid-item-label-input" type="number" name="financialInformation.creditScore" value={clientData.financialInformation.creditScore} onChange={handleChange} />
          </div>
          <div className="client-details-popup flex-row">
            <div className="client-details-popup modal-grid-item-label-sub">Net Worth:</div>
            <input className="client-details-popup modal-grid-item-label-input" type="number" name="financialInformation.netWorth" value={clientData.financialInformation.netWorth} onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">Existing Products</div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Details</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            {clientData.existingProducts.map((product, index) => (
              <tr key={index}>
                <td><input type="text" value={product.productName} onChange={handleChange} /></td>
                <td><input type="text" value={product.productDetails} onChange={handleChange} /></td>
                <td><input type="date" value={product.startDate} onChange={handleChange} /></td>
                <td><input type="date" value={product.endDate} onChange={handleChange} /></td>
                <td><input type="number" value={product.premium} onChange={handleChange} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SituationalTab;
