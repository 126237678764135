import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const CyberContext = createContext();

export const CyberProvider = ({ children }) => {
  // Use the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial cyber insurance state
  const initialCyberInsuranceState = {
    general,        // Use the general object from the hook
    existingCover,  // Use the existingCover object from the hook
    objectivesAndGoals: {
      objectives: {
        protectData: false,
        preventBreaches: false,
        complyRegulations: false,
      },
      goals: {
        networkSecurity: false,
        privacyLiability: false,
        dataBreachCoverage: false,
      },
    },
    situation: {
      businessDetails: {
        businessName: '',
        industry: '',
        employeeCount: 0,
      },
      cyberRisks: {
        ransomware: false,
        phishingAttacks: false,
        networkOutage: false,
      },
    },
    modules: {
      options: {
        cyberExtortion: false,
        dataRestoration: false,
        forensicInvestigation: false,
        publicRelations: false,
      }
    },
  };

  // Set the filters state with the initialCyberInsuranceState
  const [filters, setFilters] = useState(initialCyberInsuranceState);

  return (
    <CyberContext.Provider value={{ filters, setFilters }}>
      {children}
    </CyberContext.Provider>
  );
};
