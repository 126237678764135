import React, { useEffect, useState, useRef, useContext } from 'react';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { ProviderPolicyListContext } from '../../context/ProviderPolicyListContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCompareContext } from '../../context/CompareContext';
import { UserContext } from "../../context/UserContext";
import { CountryContext } from '../../context/CountryContext';
import Countdown from "../../components/home/Countdown";
import { useCollapse } from '../../context/CollapseContext';
import { formatString } from '../../utils/utils';
import ScoreIndicator from "../../components/home/ScoreIndicator";
import MatrixChart from '../../components/home/MatrixChart';
import Savings from "../../components/home/Savings";
import CurrentSituationSummary from "../../components/home/CurrentSituationSummary";
import ActivePolicy from "../../components/ActivePolicy";
import ComparePolicy from "../../components/ComparePolicy";
import Excess from "../../components/home/Excess";
import Limits from "../../components/home/Limits";
import Score from "../../components/home/Score";
import Conditions from "../../components/home/Conditions";
import SpiderChart from "../../components/home/SpiderChart";
import SpiderChartWithDropdown from "../../components/home/SpiderChartWithDropdown";
import BarChartWithDropdown from "../../components/home/BarChartWithDropdown";
import Strengths from '../../components/home/Strengths';
import Weaknesses from '../../components/home/Weaknesses';
import PropertyInformation from '../../components/home/PropertyInformation';
import AddressInformation from '../../components/home/AddressInformation';
import PropertyRisks from '../../components/home/PropertyRisks';
import WaitingDiv from "../../components/WaitingDiv";
import GiveUp from '../../components/home/GiveUp';
import Gain from '../../components/home/Gain';
import { Link } from 'react-router-dom';
import './CurrentSituation.css';

const CurrentSituation = () => {
  useEffect(() => {
    document.title = 'Current';
  }, []);
  const { country } = useContext(CountryContext);
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { providerPolicies } = useContext(ProviderPolicyListContext || {});
  const [userContext] = useContext(UserContext);
  const { activeDocument } = useGlobalContext();
  const { compareDocument } = useCompareContext();
  const [score, setScore] = useState(0);
  const { isCollapsed } = useCollapse();
  const spiderChartRef = useRef();
  const [myData, setMyData] = useState([]);
  const [result, setResult] = useState([]);
  const [policy, setPolicy] = useState([]);

  useEffect(() => {
    
    if (myData.length > 0) {
      spiderChartRef.current.updateChart();
    }
  }, [myData]);

  const getScores = (provider) => {
    
    return [
      (provider.score ? provider.score : 0),
      (provider.strengths ? Object.keys(provider.strengths).length * 2 : 0),
      (provider.weaknesses ? Object.keys(provider.weaknesses).length * 2 : 0),
      (provider.conditions ? Object.keys(provider.conditions).length * 2 : 0),
      (provider.demoPrice ? provider.demoPrice / 4 : 0),
    ];
  };

  const matchPolicyWithActiveDocument = (provider) => {
    if (activeDocument.policyProvider === provider.provider &&
      activeDocument.policyType === provider.type &&
      activeDocument.policyWording.filename === provider.fileName) {
      // 
      return true;
    } else {
      return false;
    }
  }

  const matchPolicyWithCompareDocument = (provider) => {
    if (compareDocument?.policyProvider === provider.provider &&
      compareDocument?.policyType === provider.type &&
      compareDocument?.policyWording.filename === provider.fileName) {
      return true;
    } else {
      return false;
    }
  }

  const getDataPoints = (provider) => {
    
    return {
      label: `${provider.provider} ${provider.name}`,
      code: provider.provider,
      values: getScores(provider),
      visible: matchPolicyWithActiveDocument(provider) ? true : false,
      color: matchPolicyWithActiveDocument(provider) ? 'rgb(79,166,88)' : 'rgb(226,226,226)',
    };
  };

  const buildDataSet = (providers) => {
    let updatedData = [];
    for (let provider of providers.values()) {
      
      updatedData.push(getDataPoints(provider));

      if (activeDocument.policyProvider === provider.provider &&
        activeDocument.policyType === provider.type &&
        activeDocument.policyWording.filename === provider.fileName) {
        
        setScore(provider.score);
      }

    };

    if (updatedData.length > 0) {
      for (let i = 0; i < Math.min(3, updatedData.length); i++) {
        updatedData[i].visible = true;
      }
    }

    setMyData(updatedData);
  };

  useEffect(() => {
    const filteredPolicies = masterPolicies.filter(item =>
      item.type === activeDocument.policyType && item?.country === country);

    if (filteredPolicies && filteredPolicies.length > 0) {
      
      buildDataSet(filteredPolicies);
      setPolicy(filteredPolicies);
    }

  }, [masterPolicies, activeDocument, compareDocument]);

  function comparePolicies(policyA, policyB) {
    const inPolicyBNotA = [];
    const inPolicyANotB = [];

    // Convert Mongoose documents to plain objects
    const coveragesA = policyA.coverages.toObject ? policyA.coverages.toObject() : policyA.coverages;
    const coveragesB = policyB.coverages.toObject ? policyB.coverages.toObject() : policyB.coverages;

    // Helper function to check if a coverage is included
    const isIncluded = (coverage) => coverage && coverage.included;

    // Iterate over the coverages in policy B
    for (const category in coveragesB) {
      if (coveragesB.hasOwnProperty(category)) {
        const categoryMap = coveragesB[category];
        if (typeof categoryMap === 'object' && categoryMap !== null) {
          for (const coverage in categoryMap) {
            if (categoryMap.hasOwnProperty(coverage)) {
              const details = categoryMap[coverage];
              if (isIncluded(details) &&
                !(coveragesA[category] && isIncluded(coveragesA[category][coverage]))) {
                inPolicyBNotA.push(`${coverage}`);
              }
            }
          }
        }
      }
    }

    // Iterate over the coverages in policy A
    for (const category in coveragesA) {
      if (coveragesA.hasOwnProperty(category)) {
        const categoryMap = coveragesA[category];
        if (typeof categoryMap === 'object' && categoryMap !== null) {
          for (const coverage in categoryMap) {
            if (categoryMap.hasOwnProperty(coverage)) {
              const details = categoryMap[coverage];
              if (isIncluded(details) &&
                !(coveragesB[category] && isIncluded(coveragesB[category][coverage]))) {
                inPolicyANotB.push(`${coverage}`);
              }
            }
          }
        }
      }
    }

    return { inPolicyBNotA, inPolicyANotB };
  }

  function comparePolicy() {
    if (!activeDocument || !compareDocument) {
      console.error("Active document or compare document is missing.");
      return;
    }

    const filteredPolicies = masterPolicies.filter(item =>
      item.type === activeDocument.policyType && item?.country === country);

    const policy1 = filteredPolicies.find(policy => policy.provider === activeDocument.policyProvider);
    const policy2 = filteredPolicies.find(policy => policy.provider === compareDocument.policyProvider);

    if (!policy1 || !policy2) {
      console.error("Failed to find matching policies:",
        !policy1 ? "No match for active document provider." : "",
        !policy2 ? "No match for compare document provider." : "");
      return;
    }   

    const comparisonResults = comparePolicies(policy1, policy2);

    setResult(comparisonResults);
  }


  useEffect(() => {
    comparePolicy();
  }, [activeDocument, compareDocument]);


  return (
    <div className="analysis-container">
      <div className="analysis-container-white">
        <Score displayCompare={false} />
        <div className="analysis-container-top">
          <div className='analysis-container-white-column-20'>
            {activeDocument !== undefined && activeDocument !== null && activeDocument.periodOfInsuranceEnd && (
              <Countdown ExpiryDate={activeDocument.periodOfInsuranceEnd} />
            )}
            <Savings savings="215" />
            <CurrentSituationSummary/>
            <Strengths />
            <Conditions />
            <AddressInformation address={activeDocument.policyAddress} />
            <PropertyRisks address={activeDocument.policyAddress} />
            <PropertyInformation />
          </div>
          <div className='analysis-container-white-column-80'>
            <Weaknesses />
            <SpiderChartWithDropdown  data={policy} />
            <BarChartWithDropdown  data={policy} />
            <SpiderChart ref={spiderChartRef} data={myData} />
          </div>
        </div>
        <Limits compare={false} />
        <Excess compare={false} />
      </div>
    </div>
  );
};

export default CurrentSituation;
