import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserContext } from "../context/UserContext";
import { CountryContext } from '../context/CountryContext';
import { ProviderPolicyListContext } from './ProviderPolicyListContext';
import { fetchPolicies } from "../apis/PolicyService"; 

// Create a context
export const FiltersContext = createContext();

// Create a provider component
export const FiltersProvider = ({ children }) => {
    const { providerPolicies, setProviderPolicies } = useContext(ProviderPolicyListContext);
    const [globalFilters, setGlobalFilters] = useState([]);
    const [userContext] = useContext(UserContext);
    const { country } = useContext(CountryContext);


    function setFiltersGlobally(data) {

        if (data.length > 0) {
            // Initialize an empty array for filters
            const filters = [];

            // Use a for...in loop to iterate over keys in the data object
            for (const provider in data) {
                if (data.hasOwnProperty(provider)) { // Check if the key is a direct property of data
                    // Push each provider into the filters array
                    filters.push({
                        name: data[provider].provider_name, // Assuming each provider is an object with a provider_name
                        label: data[provider].provider_name,
                        country: data[provider].country,
                        checked: true
                    });
                }
            }

            // Reduce duplicates in filters, if necessary
            const uniqueFilters = filters.reduce((acc, current) => {
                const x = acc.find(item => item.name === current.name);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            // Set the global filters state with the processed filters array
            setGlobalFilters(uniqueFilters);
        }
    }

    useEffect(() => {
        if (providerPolicies.length > 0) {            
            setFiltersGlobally(providerPolicies);
        } else{
            fetchPolicies(country);
            setFiltersGlobally(providerPolicies);
        }
    }, [providerPolicies]);

    return (
        <FiltersContext.Provider value={{ globalFilters, setGlobalFilters }}>
            {children}
        </FiltersContext.Provider>
    );
};
