import React from 'react';
import './NeedsSummaryTables.css';

const NeedsSummaryTables = ({ selectedOptions, onDone }) => {
  return (
    <div className="needs-summary-tables">
      {Object.keys(selectedOptions).map(groupIndex => {
        const group = selectedOptions[groupIndex];
        return (
          <div key={group.id} className="needs-summary-group">
            <h3 className="needs-summary-group-title">{group.label}</h3>
            <table className="needs-summary-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {group.questions.map((question, index) => (
                  <tr key={index}>
                    <td>{question.id}</td>
                    <td>{question.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
      <div className="needs-summary-navigation-buttons">
        <button onClick={onDone} className="needs-summary-done-button">Done</button>
      </div>
    </div>
  );
};

export default NeedsSummaryTables;
