import React from 'react';
import './Popup.css';

function Popup({ onClose, children, width, height }) {

    const popUpStyle = {
        width: width,
        height: height,
    };

    return (
        <div className="popup-background" onClick={onClose}>
            <div style={popUpStyle} className="popup-content" onClick={e => e.stopPropagation()}>
                <button className="popup-close-button" onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
}

export default Popup;
