import React from 'react';
import './Savings.css';
import { useNavigate } from "react-router-dom";
import savingsIcon from './../../icons/piggy_bank.png';

const Savings = ({ savings }) => {
  const navigate = useNavigate();

  const iconStyle = {
    width: '120px',

  };

  return (
    <div className="savings-container" >
      <div className='savings-container-div'>
        <div className="savings-h1">
          Save up to
        </div>
        <div className="savings-h2">
          ${savings}
        </div>
        <div className="savings-h3">
          per year
        </div>
      </div>
      <div className='savings-container-div'>
        <div className='savings-container-div-centre'>
          <img style={iconStyle} src={savingsIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Savings;
