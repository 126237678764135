import React, { useState, useContext, useEffect, useRef } from 'react';
import { useUpload } from '../context/UploadContext';
import { UserContext } from "../context/UserContext";
import { useGlobalContext } from '../context/GlobalContext';
import fileIconURL from "../icons/file.png";
import './PolicyDocumentList.css';

function PolicyDocumentList() {
    const tableRef = useRef(null);
    const { activeDocument } = useGlobalContext();
    const { isUploadComplete, handleUploadComplete } = useUpload();
    const [isClicked, setIsClicked] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [sortConfig, setSortConfig] = useState({ field: null, direction: 'ascending' });
    const [userContext, setUserContext] = useContext(UserContext);

    const getPolicies = () => {
        if (userContext !== undefined && userContext !== null && userContext.details.username !=='') {
            
            fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/my-policies?username=${userContext.details.username}`, {
                method: "GET",
                credentials: "include",
                // Pass authentication token as bearer token in header
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(response => response.json())
                .then(data => setPolicies(data))
                .catch(error => console.error('Error fetching data:', error));
        }
    };

    const renderPolicyType = (type) => {
        switch (type) {
            case 'home':
                return <><span className="icon home-icon">🏠</span></>;
            case 'isr':
                return <><span className="icon home-icon">🏭</span></>;
            case 'landlord':
                return <><span className="icon home-icon">🏠</span></>;
            case 'contents':
                return <><span className="icon home-icon">🖼</span></>;
            case 'home-contents':
                return <><span className="icon home-icon">🏠</span></>;
            case 'motor':
                return <><span className="icon life-icon">🚗</span></>;
            case 'health':
                return <><span className="icon life-icon">💊</span></>;
            case 'life':
                return <><span className="icon life-icon">💖</span></>;
            case 'travel':
                return <><span className="icon life-icon">🛫</span></>;
            case 'pet':
                return <><span className="icon life-icon">🐶</span></>;
            case 'business':
                return <><span className="icon life-icon">🏬</span></>;
            case 'professional':
                return <><span className="icon life-icon">👩‍💼</span></>;
            default:
                return type;
        }
    };

    const handleDownload = (filename) => {
        

        const url = `${process.env.REACT_APP_API_ENDPOINT}users/api/download/${encodeURIComponent(filename)}?username=${encodeURIComponent(activeDocument.userName)}`;

        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL);
            })
            .catch(error => {
                console.error('Error during file download:', error);
            });
    };


    function deletePolicy(userName, fileName) {
        

        const url = `${process.env.REACT_APP_API_ENDPOINT}users/api/policies?username=${encodeURIComponent(userName)}&filename=${encodeURIComponent(fileName)}`;

        fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                // Check if the response is OK (status code in the range 200-299)
                if (!response.ok) {
                    // Throw an error with the response status
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                getPolicies();
                return response.json();
            })
            .catch(error => {
                console.error('Error performing the delete operation:', error);
                // Optionally, update the UI to reflect the error
            });
    }

    function formatDate(dateString) {
        const date = new Date(dateString);

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = String(date.getFullYear()).slice(-2);

        return `${hours}:${minutes} ${day}.${month}.${year}`;
    }

    function formatProvider(providerString) {
        if (providerString) {
            const index = providerString.indexOf(' Insurance');
            if (index !== -1) {
                return providerString.slice(0, index);
            }
            return providerString; // Return the input string as is if " Insurance" is not found
        }
    }

    function formatISODate(isoDateString) {

        if (isoDateString === undefined || isoDateString == null || isoDateString === '') {
            return ''
        }

        // Parse the ISO date string
        const date = new Date(isoDateString);

        // Extract and format individual date components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        // Combine components into the desired format
        return `${day}.${month}.${year}`;
    }

    function removeUpToFirstHashAndPDF(inputString) {
        if (inputString) {
            const hashIndex = inputString.indexOf('#');

            // Remove everything up to and including '#'
            let result = hashIndex === -1 ? inputString : inputString.substring(hashIndex + 1);

            // Remove '.pdf' extension
            result = result.replace(/\.(pdf|json)$/i, '');
            if (result === 'NOFILE') {
                result = '';
            }
            return result;
        }
    }

    function isPolicyActive(policy) {
        if (activeDocument.filename === policy.filename) {
            policy.status = true;
            return 'ACTIVE'
        } else {
            policy.status = false;
            return '';
        }
    }

    useEffect(() => {
        
        getPolicies();
    }, []);

    useEffect(() => {
        
        handleUploadComplete(false);
        getPolicies();
    }, [isUploadComplete]);


    function refreshTable() {
        
        // Assuming `policies` is already populated with the fetched data
        const sortedPolicies = policies.sort((a, b) => {
            // First, sort by status (true first)
            if (a.status === true && b.status !== true) return -1;
            if (a.status !== true && b.status === true) return 1;

            // Then, sort by uploadDate in descending order (most recent first)
            const dateA = new Date(a.uploadDate);
            const dateB = new Date(b.uploadDate);
            return dateB - dateA;
        });
        setPolicies(sortedPolicies);
    }

    useEffect(() => {
        refreshTable();
        
    }, [isUploadComplete, activeDocument]);

    function capitalizeFirstLetter(str) {
        if (str.length === 0) {
            return str; // Return an empty string if input is empty
        }

        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <div className="policies-table-container">
                <div className="table-scroll">
                    {/* <table className="policies-table" ref={tableRef}> */}
                    <table className="policies-table">
                        <thead>
                            <tr>
                                <th style={{ width: '15px' }}></th>
                                <th style={{ width: '150px' }} >Name</th>
                                <th style={{ width: '100px' }}>Provider</th>
                                <th style={{ width: '55px' }}>Expiry</th>
                                <th style={{ width: '65px' }}>Status</th>
                                <th  >File Name</th>
                                <th style={{ width: '100px' }}>Uploaded</th>
                                <th style={{ width: '70px' }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policies && policies.length > 0 &&
                                [...policies] // Create a copy to avoid mutating the original array
                                    .sort((a, b) => b.status - a.status) // Sort by status
                                    .map(policy => (
                                        <tr key={policy.id}>


                                            <td>{renderPolicyType(capitalizeFirstLetter(policy.policyType))}</td>
                                            <td>{policy.policyNumber}</td>
                                            <td>{formatProvider(policy.policyProvider)}</td>
                                            <td>{formatISODate(policy.periodOfInsuranceEnd)}</td>
                                            {isPolicyActive(policy) ? (
                                                <td className="policy-active-status">{`${isPolicyActive(policy)}`}</td>
                                            ) : (
                                                <td className="policy-inactive-status">INACTIVE</td>
                                            )}
                                            <td className="table-file">
                                                <div className="table-file-info">
                                                    {removeUpToFirstHashAndPDF(policy.filename)}
                                                </div>
                                                <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                    onClick={() => handleDownload(policy.filename)}>
                                                    {(!policy.filename.includes('NO_FILE')) && (
                                                        <img style={{ width: '20px' }} src={fileIconURL} alt="" />
                                                    )}
                                                </span>
                                            </td>
                                            <td>{formatDate(policy.uploadDate)}</td>
                                            <td className="button-td">
                                                <button className="document-delete" onClick={() => deletePolicy(policy.userName, policy.filename)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='document-management-disclaimer'>* See 'Settings' (bottom left) to delete all your polcies use the setting and set how long they stay stored in the system.</div>
        </>
    );
}

export default PolicyDocumentList;
