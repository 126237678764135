import React, { useState } from 'react';
import './ChevronTabs.css'; // Import the CSS file for styling

const ChevronTabs = ({ tabNames, activeTab, setActiveTab }) => {
  const [tabStates, setTabStates] = useState(
    tabNames.reduce((acc, tab) => {
      acc[tab] = false;
      return acc;
    }, {})
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setTabStates({
      ...tabStates,
      [tab]: true,
    });
  };

  const getNextTab = () => {
    const currentIndex = tabNames.indexOf(activeTab);
    return currentIndex >= 0 && currentIndex < tabNames.length - 1 ? tabNames[currentIndex + 1] : null;
  };

  const nextTab = getNextTab();

  return (
    <div className="chevron-tabs-container" id="chevron">
      {tabNames.map((tab) => (
        <button
          key={tab}
          className={`chevron-tabs-container-tab-button ${activeTab === tab ? 'chevron-active' : ''} 
          ${nextTab === tab ? 'chevron-next-to-active' : ''} 
          ${tabNames.length === (tabNames.indexOf(tab) + 1) ? 'chevron-last-tab' : ''}
          ${0 === (tabNames.indexOf(tab)) ? 'chevron-first-tab' : ''}`}
          onClick={() => handleTabClick(tab)}
        >
          {tabStates[tab] && <span className="chevron-tick-icon">✔&nbsp;&nbsp;</span>}
          <span id={tab.trim().toLowerCase().replace(/\s+/g, '-')}>{tab}</span>
        </button>
      ))}
    </div>
  );
};

export default ChevronTabs;
