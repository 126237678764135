import React, { useState } from 'react';
import './ToolTabletip.css';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML

const ToolTabletip = ({ children, htmlContent, useDoubleClick }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    const eventHandlers = useDoubleClick
        ? { onDoubleClick: toggleVisibility }
        : { onMouseEnter: () => setIsVisible(true), onMouseLeave: () => setIsVisible(false) };

    // Sanitize the HTML content
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);

    return (
        <div className="tooltabletip-container" {...eventHandlers}>
            {children}
            {isVisible && sanitizedHtml && (
                <div
                    className="tooltabletip"
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                />
            )}
        </div>
    );
};

export default ToolTabletip;
