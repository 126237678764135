import React from 'react';

const PastClaimsTab = ({ clientData, handleChange }) => {
  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">Past Claims</div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>Claim Date</th>
              <th>Claim Type</th>
              <th>Claim Amount</th>
              <th>Claim Status</th>
              <th>Incident Details</th>
              <th>Policy Number</th>
              <th>Insurer</th>
            </tr>
          </thead>
          <tbody>
            {clientData?.previousClaims?.map((claim, index) => (
              <tr key={index}>
                <td><input type="date" name={`previousClaims.${index}.claimDate`} value={claim.claimDate} onChange={handleChange} /></td>
                <td><input type="text" name={`previousClaims.${index}.claimType`} value={claim.claimType} onChange={handleChange} /></td>
                <td><input type="number" name={`previousClaims.${index}.claimAmount`} value={claim.claimAmount} onChange={handleChange} /></td>
                <td><input type="text" name={`previousClaims.${index}.claimStatus`} value={claim.claimStatus} onChange={handleChange} /></td>
                <td><input type="text" name={`previousClaims.${index}.incidentDetails`} value={claim.incidentDetails} onChange={handleChange} /></td>
                <td><input type="text" name={`previousClaims.${index}.policyNumber`} value={claim.policyNumber} onChange={handleChange} /></td>
                <td><input type="text" name={`previousClaims.${index}.insurer`} value={claim.insurer} onChange={handleChange} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PastClaimsTab;
