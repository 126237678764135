import React, { useEffect, useState } from "react";
import GetStartedBanner from "../../components/GetStartedBanner";
import PricingComponent from "../../components/PricingComponent";
import "./Pricing.css";

const Pricing = () => {
    const [monthName, setMonthName] = useState('');
    useEffect(() => {
        document.title = 'Pricing';
    }, []);

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
    
        // Add one month to the current date
        const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    
        // Get the full name of the month
        const monthName = nextMonth.toLocaleString('default', { month: 'long' });
    
        // Set the month name in the state
        setMonthName(monthName);
      }, []);

    return (
        <div className="pricing-page">
            <div className="pricing-container">
                <h1>Comb through policies faster. Start free.</h1>
                <h2>Get started with a 21 day trial for free.</h2>
                <h2>Until {monthName}, Get 50% off the first three months!</h2>
                <PricingComponent />
                <h4 className="price-subtext">Although we believe the price is fair, there is always a chance of a better price. Ask for a discount.</h4>
            </div>
            <GetStartedBanner />
        </div>
    );
};

export default Pricing;
