import React, { useContext, useState, useEffect } from "react";
import ToggleSwitch from "../../components/ToggleSwitch";
import { addToWaitlist } from "../../apis/AuthService";

import "./LogInandRegister.css";

/**
 * WaitList Component
 *
 * This component handles the user interaction for joining a waitlist. 
 * It provides an input for the user's email address and submits it to 
 * the backend. It also handles validation and displays appropriate messages 
 * based on the submission status.
 * 
 * Props:
 * - isOpen: Boolean indicating if the waitlist popup is open.
 * - onClose: Function to close the popup.
 * - forgotPassword: Function to handle the 'Forgot Password' action.
 */
const WaitList = ({ isOpen, onClose, forgotPassword }) => {
  // State for handling errors and email input
  const [error, setError] = useState(""); // To store any error messages related to the form
  const [email, setEmail] = useState(""); // To store the user's email input
  const [message, setMessage] = useState(""); // To store success/error messages after submission
  const [isSuccess, setIsSuccess] = useState(false); // To track if the submission was successful
  const [isBroker, setIsBroker] = useState(true);

  // Effect to set the document title when the component mounts
  useEffect(() => {
    document.title = 'PolicyCheck';
  }, []);

  useEffect(() => {
    setEmail("");
    setMessage("");
    setIsSuccess(false);
  }, []);

  /**
   * Function to handle the 'Forgot Password' action.
   * It triggers the forgotPassword function and then closes the popup.
   */
  const forgot = () => {
    forgotPassword();
    onClose(); // Close the popup
  }

  /**
     * Function to handle the email submission to the waitlist.
     * Calls the external API function and processes the response.
     */
  const handleEmailSubmit = async () => {
    if (!email.includes("@")) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const data = await addToWaitlist(email, isBroker); // Call the external API function

      if (data.success) {
        setIsSuccess(true);
        setMessage("Thank you for registering! We appreciate your interest and will be in touch shortly.");
      } else {
        setMessage("Thank you for already registering!");
      }
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setMessage("There was an error. Please try again later.");
      setError("There was an error. Please try again later.");
    }
  };

  /**
   * Function to handle input changes in the email field.
   * It updates the state and performs basic validation.
   * Resets error messages when input changes.
   */
  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (e.target.name === "username") {
      // Perform email validation
      if (newValue.includes('@')) {
        setError(''); // Clear the error if the email is valid
      } else {
        setError('Please enter a valid email address.');
      }
      setEmail(newValue); // Update the email state
    }
  };

  return (
    <>
      <div className={`login-and-register-pop-up ${isOpen ? "open" : ""}`}>
        <div className="popup-container">
          {/* Close button for the popup */}
          <div className="popup-container-close" onClick={onClose}>
            &times;
          </div>
          <div className="pop-up-container-heading">Join the Waiting list</div>
          {/* Option for existing users to handle forgot password */}
          <div className="popup-container-inputs">
            {/* Email input field */}

            {!isSuccess ? (
              <>
                <input
                  className="text-email-password-input"
                  type="email"
                  name="username"
                  placeholder="Email"
                  value={email}
                  onChange={handleInputChange}
                  autoComplete="username"
                />
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems:'flex-start', width: '100%', flexDirection:'column', gap:'10px' , marginTop:'10px'}}>
                  Are you an insurance broker?
                  <ToggleSwitch
                    height={'40px'}
                    width={'200px'}
                    radius={'5px'}
                    labels={['Yes', 'No']}
                    onActiveChange={() => setIsBroker(!isBroker)}
                    className="toggle-switch-container"
                  />
                </div>
                <br />
              </>
            ) : (
              <div className={`message success`}>{message}</div>
            )}
          </div>
          <div className="popup-buttons">
            <div className="align-buttons">
              {/* Submit button, disabled if there's an error */}
              {!isSuccess ? (
                <button
                  disabled={!!error}
                  className={`popup-buttons-next ${error ? "isError" : "noError"}`}
                  onClick={handleEmailSubmit}
                >
                  Submit
                </button>
              ) : (
                <button
                  className={`popup-buttons-next ${error ? "isError" : "noError"}`}
                  onClick={() => onClose()}
                >
                  Done
                </button>

              )}

            </div>
          </div>
          {!isSuccess && (
            <div className="existing-user" onClick={forgot}>
              Already registered?
            </div>
          )}
          {/* Display message after submission (either success or error) */}
          {message && !isSuccess && <div className={`message ${isSuccess ? 'success' : 'error'}`}>{message}</div>}
        </div>
        {/* Overlay to close the popup when clicked outside */}
        <div className="popup-overlay" onClick={onClose}>
          <div className="popup-overlay-content"></div>
        </div>
      </div>
    </>
  );
};

export default WaitList;
