import React, { useState, useEffect, useRef } from 'react';
import WaitingDiv from "./WaitingDiv";
import './EQCData.css';

const EQCData = ({ address }) => {
    const [eqcData, setEqcData] = useState(null); // Initially null
    const [isWaiting, setIsWaiting] = useState(false);
    const [error, setError] = useState(false);

    const timeoutRef = useRef(null);

    // Define fetchEQCData outside of useEffect so it can be reused
    const fetchEQCData = async () => {
        setIsWaiting(true);
        try {
            const url = `${process.env.REACT_APP_API_ENDPOINT}users/eqc-data?address=${encodeURIComponent(address)}`;
            const response = await fetch(url, {
                method: "GET",
                credentials: "include"
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            // Ensure responseData is an array
            if (Array.isArray(responseData)) {
                setEqcData(responseData);
            } else {
                setEqcData([]); // Fallback to an empty array if not an array
            }
            setError(false); // Reset error state on successful fetch
        } catch (error) {
            console.error(`Fetch error: ${error.message}`);
            setError(true);
        } finally {
            setIsWaiting(false); // Always stop waiting after fetching
        }
    };

    useEffect(() => {
        if (isWaiting) {
            // Set the timeout to 90 seconds (90,000 milliseconds)
            timeoutRef.current = setTimeout(() => {
                if (isWaiting) {
                    setIsWaiting(false);
                }
            }, 90000); // 90 seconds

            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }
    }, [isWaiting]);

    useEffect(() => {
        if (address) {
            fetchEQCData();
        }
    }, [address]);

    return (
        <>
            <div className="eqc-header-title">Earthquake Database</div>
            {isWaiting ? (
                <>
                    <div className="eqc-header-waiting">Sourcing information from the EQC database</div>
                    <WaitingDiv />
                </>
            ) : error ? (
                <>
                    <div className="error-message">
                        Unable to load data. Please try again later.
                    </div>
                    <button className="eqc-button" onClick={fetchEQCData}>Refresh</button>
                </>
            ) : eqcData && eqcData.length > 0 ? ( // Check if eqcData has items
                <>
                    <div className="eqc-header-waiting">
                        Found previous claims data; <br /> Please check the{" "}
                        <a href="https://www.naturalhazardsportal.govt.nz">
                            Natural Hazards Portal
                        </a>
                    </div>
                    <table className="eqc-container-table">
                        <thead>
                            <tr>
                                <th>EQCover Event Date</th>
                                <th>EQCover Event Type</th>
                                <th>EQCover Claim Type</th>
                            </tr>
                        </thead>
                        <tbody className="eqc-container-items">
                            {eqcData.map((event, index) => (
                                <tr key={index}>
                                    <td>{event.date}</td>
                                    <td>{event.type}</td>
                                    <td>{event.claim}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div>No data found for the specified address.</div>
            )}
        </>
    );
};

export default EQCData;
