import axios from 'axios';


// Fetch all proposals for a specific client
export const getProposals = (clientID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/proposals`, {
    params: { clientID }
  });

// Fetch a specific proposal by ID
export const getProposalData = (clientID, proposalID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/proposals/${proposalID}`, {
    params: { clientID }
  });

// Quote API

// Fetch all quotes for a specific client and proposal
export const getQuotes = (clientID, proposalID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/quotes`, {
    params: { clientID, proposalID }
  });

// Fetch a specific quote by ID
export const getQuoteData = (clientID, proposalID, quoteID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/quotes/${quoteID}`, {
    params: { clientID, proposalID }
  });
