import React, { useState, useMemo } from "react";
import './RecentLeads.css';

const RecentLeads = ({ leads }) => {
  const initialStatuses = leads.reduce((acc, lead) => ({ ...acc, [lead.contactInfo]: lead.status }), {});
  const [leadStatuses, setLeadStatuses] = useState(initialStatuses);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const combinedLeads = leads.map((lead) => ({ ...lead, dynamicStatus: leadStatuses[lead.contactInfo] }));

  const sortedLeads = useMemo(() => {
    let sortableLeads = [...combinedLeads];
    if (sortConfig !== null) {
      sortableLeads.sort((a, b) => {
        const aValue = (sortConfig.key === 'status') ? a.dynamicStatus : a[sortConfig.key];
        const bValue = (sortConfig.key === 'status') ? b.dynamicStatus : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableLeads;
  }, [combinedLeads, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleStatusChange = (leadId, newStatus) => {
    
    setLeadStatuses(prevStatuses => {
      
      const updatedStatuses = { ...prevStatuses, [leadId]: newStatus };
      
      return updatedStatuses;
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'New':
        return { backgroundColor: 'rgba(54, 162, 235, 0.2)' }; // Light red
      case 'Contacted':
        return { backgroundColor: 'rgba(255, 206, 86, 0.2)' }; // Light orange
      case 'Qualified':
        return { backgroundColor: 'rgba(255, 206, 86, 0.2)' }; // Light blue
      case 'Negotiation':
        return { backgroundColor: 'rgba(75, 192, 192, 0.2)' }; // Light teal
      case 'Converted':
        return { backgroundColor: 'rgba(153, 102, 255, 0.2)' }; // Light purple
      default:
        return {};
    }
  };

  return (
    <div className="recent-leads">
      <table>
        <thead>
          <tr>
            <th onClick={() => requestSort('name')}>Name</th>
            <th onClick={() => requestSort('source')}>Source</th>
            <th onClick={() => requestSort('dateCreated')}>Created</th>
            <th onClick={() => requestSort('contactInfo')}>Contact Info</th>
            <th onClick={() => requestSort('phone')}>Phone</th>
            <th onClick={() => requestSort('status')}>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedLeads.map((lead, index) => (
            <tr key={lead.contactInfo}>
              <td>{lead.name}</td>
              <td>{lead.source}</td>
              <td>{lead.dateCreated.toLocaleDateString()}</td>
              <td>{lead.contactInfo}</td>
              <td>{lead.phone}</td>
              <td className="recent-lead-status">
                <select
                  style={getStatusStyle(lead.dynamicStatus)}
                  value={lead.dynamicStatus}
                  onChange={(e) => handleStatusChange(lead.contactInfo, e.target.value)}
                >
                  <option value="New">New</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Negotiation">Negotiation</option>
                  <option value="Converted">Converted</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecentLeads;
