import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from "./UserContext";
import { CountryContext } from "./CountryContext";
import { fetchClients, updateClient as updateClientApi, deleteClient as deleteClientApi } from '../apis/ClientService';

const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [userContext] = useContext(UserContext);
  const { country } = useContext(CountryContext); // Access the country from CountryContext
  const [clients, setClients] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  // Fetch the top 20 clients
  const fetchTopClients = async (username) => {
    setIsFetching(true);
    const data = await fetchClients(username, country); // Pass the country to the fetchClients function
    if (data.length > 20) {
      setClients(data.slice(0, 20)); // Save only the top 20 clients
    } else {
      setClients(data);
    }
    setIsFetching(false);
  };

  // Fetch all clients without limitation
  const fetchAllClients = async (username) => {
    setIsFetching(true);
    const data = await fetchClients(username, country); // Pass the country to the fetchClients function
    setClients(data);
    setIsFetching(false);
  };

  // Update client in the database
  const updateClient = async (updatedClient) => {
    const updatedData = await updateClientApi(updatedClient); // Call the API to update client
    if (updatedData) {
      setClients(prevClients => 
        prevClients.map(client => client._id === updatedClient._id ? updatedClient : client)
      );
    }
  };

  // Delete a client from the database
  const deleteClient = async (clientId) => {
    const response = await deleteClientApi(clientId);
    if (response) {
      setClients(prevClients => prevClients.filter(client => client._id !== clientId));
    }
  };

  // Fetch clients when the username and country are available
  useEffect(() => {
    if (userContext && userContext?.details && userContext?.details?.username) {
      fetchTopClients(userContext.details.username); // Fetch clients when username is available
    }
  }, [userContext?.details?.username, country]); // Also refetch when the country changes

  return (
    <ClientsContext.Provider
      value={{
        clients,
        isFetching,
        fetchTopClients,
        fetchAllClients,
        updateClient,
        deleteClient
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

// Custom hook to use the ClientsContext
export const useClients = () => {
  return useContext(ClientsContext);
};
