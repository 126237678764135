import React, { useState, useEffect } from "react";
import './Billing.css'; // CSS for the Billing component

const Billing = () => {
  const [paymentMethod, setPaymentMethod] = useState('Visa **** 1234');
  const [subscriptionPlan, setSubscriptionPlan] = useState('Premium Plan');
  const [billingHistory, setBillingHistory] = useState([
    { date: '2024-08-15', amount: '$50.00', status: 'Paid' },
    { date: '2024-07-15', amount: '$50.00', status: 'Paid' },
    { date: '2024-06-15', amount: '$50.00', status: 'Paid' },
  ]);
  useEffect(() => {
      document.title = 'Billing';
    }, []);

  return (
    <div className="billing-container">
      <div className="billing-header">
        <h2>Billing</h2>
        <p>Manage your payment details and subscription information.</p>
      </div>

      <div className="billing-section">
        <h3>Payment Method</h3>
        <div className="billing-info">
          <p>Card: {paymentMethod}</p>
          <button className="billing-button" onClick={() => alert('Update Payment Method')}>
            Update Payment Method
          </button>
        </div>
      </div>

      <div className="billing-section">
        <h3>Subscription Plan</h3>
        <div className="billing-info">
          <p>Current Plan: {subscriptionPlan}</p>
          <button className="billing-button" onClick={() => alert('Change Subscription Plan')}>
            Change Plan
          </button>
        </div>
      </div>

      <div className="billing-section">
        <h3>Billing History</h3>
        <table className="billing-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {billingHistory.map((record, index) => (
              <tr key={index}>
                <td>{record.date}</td>
                <td>{record.amount}</td>
                <td>{record.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Billing;
