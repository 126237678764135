import React, { useContext, useState, useEffect } from "react";
import { Callout } from "@blueprintjs/core";
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/UserContext";
import { forgotPassword } from "../../apis/AuthService"; 

import "./LogInandRegister.css";

const ForgotPassword = ({ isOpen, onClose, resetStep }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [contextValue, setUserContext] = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Forgot Password';
    setEmail("");
    setShowForm(true);
  }, []);

  const formSubmitHandler = async (e) => {          
    setShowForm(false);
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      const message = await forgotPassword(email);
      setIsSubmitting(false);
      setSuccessMessage(message);
    } catch (err) {
      setIsSubmitting(false);
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Reset error when the user starts typing again
  };

  const navigateHome = () => {
    if (location.pathname !== '/') {
        navigate('/');
    }
};
  const handleClose = () => {
    navigateHome();
    setShowForm(true);
    setError(""); // Reset error when the user starts typing again
    setSuccessMessage("");
    onClose();
  };

  return (
    <div className={`login-and-register-pop-up ${isOpen ? "open" : ""}`}>
      <div className="popup-container">
        <div className="popup-container-close" onClick={onClose}>
          &times;
        </div>
        {showForm ? (
          <div className="popup-container-div">
            <div className="pop-up-container-heading">Forgot your password?</div>
            <div className="popup-container-inputs">
              <input
                className="text-email-password-input"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={handleInputChange}
                autoComplete="email"
              />
              <div className="popup-buttons" >
                <div className="align-buttons">
                  <button
                    disabled={isSubmitting}
                    className="popup-buttons-next"
                    onClick={formSubmitHandler}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {error && <p className="validation-message">{error}</p>}
            </div>
          </div>
        ) : (
          <div className="popup-container-div">
          <div className="pop-up-container-heading">Thank you</div>
            <p className="pop-up-container-sub-heading">{successMessage}</p>
            <div className="popup-buttons" >
              <div className="align-buttons">
                <button className="popup-buttons-next" onClick={handleClose}>Done</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="popup-overlay" onClick={onClose}>
        <div className="popup-overlay-content"></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
