import React, { useState, useEffect, useRef,useContext } from 'react';
import { CountryContext } from '../../context/CountryContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import WaitingDiv from '../WaitingDiv';
import Message from './Message';
import './Conversation.css';

const Conversation = ({ text = ''}) => {
  const { activeDocument } = useGlobalContext();
  const [inputText, setInputText] = useState(text);
  const [showError, setShowError] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const {policyType} = useContext(PolicyTypeContext);
  const { country } = useContext(CountryContext);
  const [messages, setMessages] = useState(
    activeDocument !== null && activeDocument !== undefined
      ? JSON.parse(localStorage.getItem("chatHistory." + activeDocument.filename))
      : []
  )
  const conversationRef = useRef(null);

  // Load chat history from local storage when the component mounts
  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      const savedMessages = JSON.parse(localStorage.getItem("chatHistory." + activeDocument.filename)) || [];
      setMessages(savedMessages);
      
      if (savedMessages.length === 0) {
        setShowWelcome(true);
      } else {
        setShowWelcome(false);
      }
      if (text !== undefined && text !== null && text !== '') {
        handleMessage();
      }
    }

  }, [activeDocument]);

  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      localStorage.setItem("chatHistory." + activeDocument.filename, JSON.stringify(messages));
    }
  }, [messages]);

  useEffect(() => {
    // Scroll to the bottom of the conversation when messages change
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  });


  const handleMessage = async () => {
    setIsWaiting(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/chatbot", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          text: inputText, 
          provider: activeDocument.policyProvider,
          country: country,
          type: policyType,  
          filename: `../server/uploads/${activeDocument.userName}/${activeDocument.filename}` }),
        credentials: "include"
      });

      if (!response.ok) {
        // If the response status is not OK, throw an error
        
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputText, isBot: false },
        { text: responseData.text, isBot: true },
      ]);

      setInputText('');
      setIsWaiting(false);

    } catch (error) {
      setIsWaiting(false);
      

      // Handle network errors and other fetch-related errors
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        console.error('Network error: Could not connect to the server.');
        setInputText('Please wait, server temporarily offline :( Please try again shortly.');
      } else if (error.message.includes('ECONNREFUSED')) {
        console.error('Connection refused: The server is not running or is unreachable.');
        setInputText('Server is currently unavailable.');
      } else {
        // Handle other errors (e.g., HTTP errors)
        console.error(`An error occurred: ${error.message}`);
        setInputText('Please wait, server temporarily offline - try again shortly...');
      }
    }
  }

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    handleMessage();
  };

  const handleButtonClick = (e) => {
    
    if (activeDocument.filename === undefined | activeDocument.filename === null) {
      
      e.preventDefault(); // Prevent form submission
      setShowError(true);
      // setTimeout(() => setShowError(false), 3000);
      setIsButtonDisabled(true);
    }

    if (!inputText.trim()) {
      
      e.preventDefault(); // Prevent form submission
      setIsButtonDisabled(true);
    }

  };

  const handleFormChange = (e) => {
    setInputText(e.target.value)
    setIsButtonDisabled(false);
    setShowWelcome(false);
    setShowError(false)
  };

  return (
    <div className="fullpage">
      <div className="search-conversation">
        <div className="conversation" ref={conversationRef}>
          {/* {showWelcome &&
            <div className="convo-welcome-container">
              <div className="convo-welcome-left">
                <div className="convo-welcome-top">
                  <div className='convo-welcome-heading-master'>
                    Check your policy
                  </div>
                  <div className='convo-welcome-heading'>
                    Ask a question:                  </div>
                  <div className='convo-welcome-text'>
                    <p>e.g. Am I covered for.....</p>
                    <p>e.g. What am I covered for?</p>
                    <p>e.g. What am I NOT covered for?</p>
                  </div>
                </div>
              </div>
              <div className="convo-welcome-right">
                <div className="convo-welcome-top" >
                  <div className='convo-welcome-heading-master'>
                    Compare your policy
                  </div>
                  <div className='convo-welcome-heading'>
                    Use the word "<span style={{ 'text-decoration': 'underline' }}>compare</span>" to compare policies.
                  </div>
                  <div className='convo-welcome-text'>
                    <p>e.g. How does my coverage compare?</p>
                    <p>e.g. Compare the market: Which providers cover swimming pools?</p>
                    <p>e.g. Compare: My AA Home insurance with Tower Home Insurance</p>
                  </div>
                </div>
              </div>
            </div>
          } */}
          {messages?.map((message, index) => (
            <Message key={index} text={message.text} isBot={message.isBot} />
          ))}
          {isWaiting && (
            <div className="conversation-waiting-container">
              <WaitingDiv />
            </div>
          )}
        </div>
        <div className="conversation-form-left">
          <form className="conversation-form" onSubmit={handleMessageSubmit}>
            <input
              className="conversation-input"
              type="text"
              value={inputText}
              onChange={handleFormChange}
              placeholder="Ask a question..."
            />
            <button
              className={`conversation-button ${!inputText.trim() ? 'disabled' : ''}`}
              onClick={handleButtonClick}
              disabled={isButtonDisabled}
            >
              &lt;
            </button>
            {showError && (
              <div className="conversation-error-popup" onClick={() => setShowError(false)}>
                Please select a policy from the left menu
              </div>
            )}
          </form>
        </div>
        <div className="fine-print">
          Coverage can make mistakes. Consider checking important information.
        </div>
      </div>
    </div>
  );
};

export default Conversation;