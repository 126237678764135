import React, { useContext, useEffect } from 'react';
import { FilterContext } from '../../../context/FilterContext';
import CheckboxGroup from './components/CheckboxGroup';
import TextInput from './components/TextInput';
import { formatString } from '../../../utils/utils';
import './InsuranceFilters.css';

const InsuranceFilters = ({ filterSections }) => {
  const { filters, setFilters } = useContext(FilterContext);

  if (!filters) {
    return null;
  }

  const handleChange = (section) => (e) => {
    const targetType = e.currentTarget.tagName.toLowerCase();
    
    let value, checked, key;
  
    if (targetType === 'div') {
      key = e.currentTarget.getAttribute('data-key');
      if (!key) return; // Safeguard in case key is missing
    } else {
      // Handle text inputs and actual checkbox inputs
      const { type, value: inputValue, checked: inputChecked, name } = e.target;
      key = name;
      value = type === 'checkbox' ? inputChecked : inputValue;
      checked = inputChecked;
    }
  
    // Split the section string into keys to access the nested structure
    const keys = section.split('.');
  
    // Create a shallow copy of filters
    const updatedFilters = { ...filters };
  
    // Traverse the nested structure to get to the correct level
    let currentLevel = updatedFilters;
  
    for (let i = 0; i < keys.length - 1; i++) {
      const currentKey = keys[i];
  
      // Ensure that the current level exists, if not create it
      if (!currentLevel[currentKey]) {
        currentLevel[currentKey] = {};
      }
  
      currentLevel = currentLevel[currentKey]; // Move to the next level
    }
  
    // Now at the correct level, update the value
    currentLevel[keys[keys.length - 1]] = value !== undefined ? value : !currentLevel[key];
    
    // Update the filters state with the new object
    setFilters(updatedFilters);
  };
  
  


  const renderSection = (section, data) => {
    if (!data || typeof data !== 'object') {
      return null;
    }

    const allBooleans = Object.values(data).every((value) => typeof value === 'object');

    return (
      <div key={section}>
        {!allBooleans && (
          <div className="insurance-filter-heading">{formatString(section)}</div>
        )}
        <div className="insurance-filter-group">
          {Object.entries(data)
            .sort(([key1, value1], [key2, value2]) => {
              // Custom sorting logic
              if (typeof value1 === 'object' && typeof value2 !== 'object') {
                return 1; // Place objects after strings/numbers
              } else if (typeof value1 !== 'object' && typeof value2 === 'object') {
                return -1; // Place strings/numbers before objects
              } else {
                return 0; // Maintain existing order
              }
            }).map(([key, value]) => {
              if (typeof value === 'object' || typeof value === 'boolean') {
                return (
                  <div key={key}>
                    <div className="insurance-filter-heading">{formatString(key)}</div>
                    {Object.entries(value).map(([item, itemValue]) =>
                      typeof itemValue === 'boolean' ? (
                        <>
                          <CheckboxGroup
                            key={item}
                            label={formatString(item)}
                            options={{ [item]: itemValue }}
                            state={{ [item]: itemValue }}
                            handleChange={handleChange(`${section}.${key}.${item}`)}
                            checked={itemValue}  // Add this line to bind the checkbox to the state
                          />

                        </>
                      ) : (
                          <TextInput
                            key={item}
                            label={formatString(item)}
                            name={`${section}.${item}`}
                            value={itemValue}
                            handleChange={handleChange(`${section}.${key}.${item}`)}
                          />
                      )
                    )}

                  </div>
                );
              } else if (typeof value === 'text' || typeof value === 'string' ||  typeof value === 'number') {
                return (
                    <TextInput
                      key={key}
                      label={formatString(key)}
                      name={`${section}.${key}`}
                      value={value}
                      handleChange={handleChange(`${section}.${key}`)}
                    />
                );
              }

              return null;
            })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {filterSections.map((section) => (
        <div key={section}>
          {filters[section] && typeof filters[section] === 'object' ? (
            renderSection(section, filters[section])
          ) : null}
        </div>
      ))}
    </div>
  );
};


export default InsuranceFilters;
