import React, { useState, useRef, useEffect, useContext } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { UserContext } from "../../context/UserContext";
import { formatString } from '../../utils/utils';
import WaitingDiv from "../WaitingDiv";
import './PropertyInformation.css';

const PropertyInformation = ({ activeDocument } ) => {
  const [propertyInformation, setPropertyInformation] = useState(null);
  const [userContext] = useContext(UserContext);
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState(false);
  const timeoutRef = useRef(null);

  const fetchPropertyInfo = async () => {
    setIsWaiting(true);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}users/file-property-info?filename=${encodeURIComponent(activeDocument.filename)}&userName=${encodeURIComponent(userContext.details.username)}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userContext.token}`,
        },
      });
      if (!response.ok) {
        setError(true);
        return;
      }
      const data = await response.json();
      if (Object.keys(data).length === 0) {
        
        setError(true);
        return;
      }

      // Check if more than 50% of values are the same or include 'not provided'
      const values = Object.values(data);
      const countMap = {};
      values.forEach(value => {
        if (value === 'not provided' || value === null || value === undefined) {
          value = 'not provided'; // Normalize undefined or null to 'not provided'
        }
        countMap[value] = (countMap[value] || 0) + 1;
      });

      const majorityThreshold = Math.ceil(values.length / 2);
      for (const key in countMap) {
        if (countMap[key] > majorityThreshold) {
          setError(true);
          return null; // More than 50% of values are the same or 'not provided'
        }
      }

      setPropertyInformation(data);
      setError(false);

    } catch (error) {
      setError(true);
      
    }
  };

  useEffect(() => {
    if (isWaiting) {
      timeoutRef.current = setTimeout(() => {
        if (isWaiting) {
          setIsWaiting(false);
        }
      }, 10000); // 180000 milliseconds = 3 minutes

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [isWaiting]);

  useEffect(() => {
    
    fetchPropertyInfo();
  }, [activeDocument]);

  return (
    <div className="property-container">
      <div className="property-container-header">
        Property Information
      </div>
      {isWaiting ? (
        <>
          <div className="eqc-header-waiting">Sourcing information on your property</div>
          <WaitingDiv />
        </>
      ) : error ? (
        <>
          <div className="error-message">
            Unable to load data. Please try again later.
          </div>
          <button className="eqc-button" onClick={fetchPropertyInfo}>Refresh</button>
        </>
      ) : propertyInformation && (Object.entries(propertyInformation).map(([key, value]) => (
        <table className="property-container-items">
          <tbody>
            <tr key={key}> {/* Adding a key for React elements in lists */}
              <td className="property-container-item-label">{formatString(key)}</td>
              <td className="property-container-item-value">{value}</td>
            </tr>
          </tbody>
        </table>
      ))
      )}
    </div>
  );
};

export default PropertyInformation;
