import React, { useState, useContext, useEffect } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCompareContext } from '../../context/CompareContext';
import { formatString } from '../../utils/utils';
import './StrengthsAndWeaknesses.css';

const GiveUp = ({data}) => {  
  const { activeDocument } = useGlobalContext();  
  const { compareDocument } = useCompareContext();
  const [attributes] = useState(data);
  const [tableKey, setTableKey] = useState(0); // State to track table key
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      setTableKey(prevKey => prevKey + 1); // Update table key
    }
  }, [activeDocument]);

   return (
    <>
      {data && Object.keys(data).length > 0 && (
        <div className="strengths-container" >
          <div className='strengths-container-div'>
            <div className="strengths-container-header">
              <span style={{ color: 'rgb(228, 156, 41)', fontWeight: 'bold' }}>
                {compareDocument ? compareDocument.policyProvider : ''}
              </span> over <span style={{ color: '#4fa658', fontWeight: 'bold' }}>
                {activeDocument ? activeDocument.policyProvider : ''}
              </span> means reducing your cover:
            </div>
            <table className="strengths-container-table" key={tableKey}>
              <tbody key={tableKey}>
                {data && Object.keys(data)
                  .slice(0, showMore ? undefined : 8)
                  .map((key, index) => (
                    <tr key={index}>
                      <td className="strengths-container-table-name">{formatString(data[key])}</td>
                      <td className="strengths-container-table-tick">&#10003;</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {Object.keys(data).length > 8 && (
              <div className="conditions-show-more " onClick={toggleShowMore}>
                {showMore ? 'Show less...' : 'Show more...'}
              </div>
            )}
            <div className="strengths-container-footer">
              * A rough guide, please consult insurance providers.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GiveUp;
