import React, { useState } from 'react';

const DocumentsTab = ({ clientData, handleChange, onAddDocument, onEditDocument, onDeleteDocument }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  const promptDelete = (document) => {
    setDocumentToDelete(document);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = (document) => {
    onDeleteDocument(document);
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">
          Documents
          <button onClick={onAddDocument} className="add-document-button">Add Document</button>
        </div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Type</th>
              <th>Content</th>
              <th>Created</th>
              <th>FileName</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clientData?.documents?.map((document, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{document.name}</td>
                <td>{document.documentType}</td>
                <td>{document.content}</td>
                <td>{document.createdAt}</td>
                <td>{document.fileName}</td>
                <td>
                  <div className="document-action-button-container">
                    <button className="document-action-button">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </button>
                    <div className="pop-up-menu">
                      <div className="pop-up-menu-item" onClick={() => onEditDocument(document)}>Edit</div>
                      <div className="pop-up-menu-item" onClick={() => promptDelete(document)}>Delete</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteModalOpen && (
        <div className="delete-modal">
          <div className="modal-content">
            <h2>Delete Document</h2>
            <p>Are you sure you want to delete this document?</p>
            <button onClick={() => handleDelete(documentToDelete)}>Yes, Delete</button>
            <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsTab;
