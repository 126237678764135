import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import WaitingDiv from '../../WaitingDiv';
import './PDFHighlighterNew.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PDFHighlighterNew = ({ url1, url2, search }) => {
  const [pdfDocs, setPdfDocs] = useState([null, null]); // For two PDFs
  const [isRendering, setIsRendering] = useState([false, false]); // Separate states for each PDF
  const [searchTerms, setSearchTerms] = useState(search && search.length > 0 ? search : []);
  const [matches, setMatches] = useState([[], []]); // Matches for both PDFs
  const [matchedSearchTerm, setMatchedSearchTerm] = useState([]);
  const [pagesRendered, setPagesRendered] = useState([false, false]); // For both PDFs
  const containerRefs = [useRef(null), useRef(null)]; // Refs for both containers
  const [isSearching, setIsSearching] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(true);

  const PAGE_MARGIN = 50;
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => (prevZoom > 0.2 ? prevZoom - 0.1 : prevZoom));
  };

  // Load both PDF documents
  useEffect(() => {
    if (!url1 || !url2) {
      console.error("Both URLs are required to load the PDF documents.");
      return;
    }

    const loadPDF = async (url, index) => {
      try {
        const loadingTask = pdfjsLib.getDocument({ url });
        const pdf = await loadingTask.promise;
        setPdfDocs(prevDocs => {
          const newDocs = [...prevDocs];
          newDocs[index] = pdf;
          return newDocs;
        });
      } catch (err) {
        console.error(`Failed to load PDF document ${index + 1}:`, err);
      }
    };

    loadPDF(url1, 0);
    loadPDF(url2, 1);
  }, [url1, url2]);

  // Render pages for both PDFs
  useEffect(() => {
    const renderPDF = async (pdfDoc, containerRef, index) => {
      if (!pdfDoc || isRendering[index]) return;

      setIsRendering(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      const pagesContainer = containerRef.current;
      pagesContainer.innerHTML = '';

      for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const viewport = page.getViewport({ scale: 1 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const pageWrapper = document.createElement('div');
        pageWrapper.className = 'page-container';
        pageWrapper.style.position = 'relative';
        pageWrapper.style.display = 'flex';
        pageWrapper.style.justifyContent = 'center';
        pageWrapper.style.paddingBottom = `${PAGE_MARGIN}px`;
        pageWrapper.style.paddingTop = `${PAGE_MARGIN}px`;
        pageWrapper.style.backgroundColor = '#f9f9f9';

        const canvasWrapper = document.createElement('div');
        canvasWrapper.className = 'canvas-wrapper';
        canvasWrapper.style.position = 'relative';
        canvasWrapper.style.display = 'inline-block';

        canvasWrapper.appendChild(canvas);
        pageWrapper.appendChild(canvasWrapper);
        pagesContainer.appendChild(pageWrapper);

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
      }

      setPagesRendered(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      setIsRendering(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    };

    if (pdfDocs[0]) renderPDF(pdfDocs[0], containerRefs[0], 0);
    if (pdfDocs[1]) renderPDF(pdfDocs[1], containerRefs[1], 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDocs]);

  // Handle search input
  const handleSearchInput = (e) => {
    setTriggerSearch(false);
    const terms = e.target.value
      .split(',')
      .map(term => term.trim())
      .filter(term => term.length > 0);
    setSearchTerms(terms);
  };

  const handleSearch = () => {
    setTriggerSearch(true);
  };

  // Search both documents
  const searchInDocuments = async () => {
    let results = [[], []];
    let foundTerms = [];

    if (!pdfDocs[0] || !pdfDocs[1]) {
      console.error("Both PDF documents must be loaded.");
      return;
    }

    if (!searchTerms || searchTerms.length === 0) {
      console.error("No search terms found.");
      return;
    }

    if (!pagesRendered[0] || !pagesRendered[1]) {
      console.error("Pages are not fully rendered yet.");
      return;
    }

    setIsSearching(true);

    // Search function for a single PDF
    const searchInDocument = async (pdfDoc, index) => {
      let docResults = [];
      let docFoundTerms = [];

      // Loop through each search term
      for (let i = 0; i < searchTerms.length; i++) {
        const searchTerm = searchTerms[i];
        const normalizedSearchTerm = searchTerm.replace(/[^\w]/g, '').replace(/\s+/g, ' ').toLowerCase();

        // Loop through each page of the document
        for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
          const page = await pdfDoc.getPage(pageNum);
          const textContent = await page.getTextContent();

          let matchBuffer = '';
          let matchedItems = [];
          let isMatching = false;

          for (let idx = 0; idx < textContent.items.length; idx++) {
            const item = textContent.items[idx];
            const itemText = item.str;
            const normalizedItemText = itemText.replace(/[^\w]/g, '').replace(/\s+/g, ' ').toLowerCase();

            if (isMatching) {
              matchBuffer += normalizedItemText;
              matchedItems.push(item);
            }

            if (!isMatching && normalizedItemText &&
              (normalizedItemText.includes(normalizedSearchTerm) || normalizedSearchTerm.includes(normalizedItemText))) {
              isMatching = true;
              matchBuffer += normalizedItemText;
              matchedItems.push(item);
            }

            if (isMatching && matchBuffer.includes(normalizedSearchTerm)) {
              matchedItems.forEach((matchedItem, idx) => {
                if (idx === 0) {
                  docFoundTerms.push({
                    item: matchedItem,
                    transform: matchedItem.transform,
                    width: matchedItem.width,
                    height: matchedItem.height,
                    pageNum,
                    matchedText: matchedItem.str,
                    term: searchTerm
                  });
                }
                docResults.push({
                  item: matchedItem,
                  transform: matchedItem.transform,
                  width: matchedItem.width,
                  height: matchedItem.height,
                  pageNum,
                  matchedText: matchedItem.str,
                  term: searchTerm
                });
              });
              matchBuffer = '';
              matchedItems = [];
              isMatching = false;
            }
          }
        }
      }

      return { docResults, docFoundTerms };
    };

    // Perform search on both documents
    const [doc1Results, doc2Results] = await Promise.all([
      searchInDocument(pdfDocs[0], 0),
      searchInDocument(pdfDocs[1], 1)
    ]);

    results[0] = doc1Results.docResults;
    results[1] = doc2Results.docResults;
    foundTerms = doc1Results.docFoundTerms; // Assuming both docs have similar terms

    setIsSearching(false);
    setMatches(results);
    setMatchedSearchTerm(foundTerms);
  };

  useEffect(() => {
    if (pdfDocs[0] && pdfDocs[1] && pagesRendered[0] && pagesRendered[1] && triggerSearch) {
      searchInDocuments();
      setTriggerSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearch, searchTerms, pdfDocs, pagesRendered]);

  useEffect(() => {
    setSearchTerms(search);
  }, [search]);

  const clearHighlights = (containerRef) => {
    const highlights = containerRef.current.querySelectorAll('.highlight');
    highlights.forEach((highlight) => {
      highlight.remove();
    });
  };

  const highlightMatches = () => {
    matches.forEach((docMatches, docIndex) => {
      const containerRef = containerRefs[docIndex];
      if (!containerRef.current || !pagesRendered[docIndex]) {
        console.error(`Pages for document ${docIndex + 1} are not fully rendered yet.`);
        return;
      }

      clearHighlights(containerRef);

      docMatches.forEach((match) => {
        const pageContainers = containerRef.current.querySelectorAll('.page-container');

        if (!pageContainers || !pageContainers[match.pageNum - 1]) {
          console.error(`Page container for page ${match.pageNum} not found in document ${docIndex + 1}.`);
          return;
        }

        const pageContainer = pageContainers[match.pageNum - 1];
        const canvasWrapper = pageContainer.querySelector('.canvas-wrapper');

        const canvas = canvasWrapper.querySelector('canvas');
        if (!canvas) {
          console.error(`Canvas for page ${match.pageNum} not found in document ${docIndex + 1}`);
          return;
        }

        const context = canvas.getContext('2d');
        const transform = match.item.transform;
        const [scaleX, , , scaleY, x, y] = transform;

        const adjustedX = x;
        const termWidth = match.item.width;
        const termHeight = scaleY;
        const adjustedY = y + (termHeight * 0.8);

        const highlightDiv = document.createElement('div');
        highlightDiv.className = 'highlight';
        highlightDiv.style.position = 'absolute';
        highlightDiv.style.left = `${adjustedX}px`;
        highlightDiv.style.top = `${canvas.height - adjustedY}px`;
        highlightDiv.style.width = `${termWidth}px`;
        highlightDiv.style.height = `${termHeight}px`;
        highlightDiv.style.backgroundColor = 'yellow';
        highlightDiv.style.opacity = '0.5';

        canvasWrapper.appendChild(highlightDiv);
      });
    });
  };

  useEffect(() => {
    if (matches.length > 0 && pagesRendered[0] && pagesRendered[1]) {
      highlightMatches();
    }
  }, [matches, pagesRendered]);

  const handleResultClick = (pageNum) => {
    containerRefs.forEach((containerRef, docIndex) => {
      const pageContainers = containerRef.current.querySelectorAll('.page-container');

      if (pageContainers && pageContainers[pageNum - 1]) {
        const pageContainer = pageContainers[pageNum - 1];

        const firstHighlight = pageContainer.querySelector('.highlight');

        const pdfContainer = containerRef.current.parentNode; // The scrollable container

        if (firstHighlight) {
          const scrollPosition = firstHighlight.offsetTop + pageContainer.offsetTop - pdfContainer.offsetTop;
          pdfContainer.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });
        } else {
          const pageScrollPosition = pageContainer.offsetTop - pdfContainer.offsetTop;
          pdfContainer.scrollTo({
            top: pageScrollPosition,
            behavior: 'smooth',
          });
        }
      }
    });
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div className='search-results-menu'>
        <div>Relevant Terms</div>
        <div style={{ fontSize: '8pt' }}>{matchedSearchTerm.length} occurrences</div>
        {isSearching || isRendering[0] || isRendering[1] ? (
          <div style={{ paddingTop: '30px' }}>
            <WaitingDiv />
          </div>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {matchedSearchTerm.map((match, index) => (
              <li key={index} className="pdf-match-item" onClick={() => handleResultClick(match.pageNum)}>
                <p className='pdf-match'>{match.term}</p>
                <p className='pdf-page-number'>P.{match.pageNum}</p>
              </li>
            ))}
          </ul>
        )}
        <div className='terms-search'>
          <input
            type="text"
            placeholder="Search Terms"
            onChange={handleSearchInput}
          />
          <button onClick={handleSearch} className="terms-search-button">
            Search
          </button>
        </div>
        {/* Zoom controls */}
        <div className="zoom-controls">
          <button className="zoom-button zoom-in" onClick={handleZoomIn}>+</button>
          <button className="zoom-button zoom-out" onClick={handleZoomOut}>-</button>
        </div>
      </div>
      {/* Two PDF containers */}
      <div className='pdf-highlighter-container'>
        {/* Document 1 */}
        <div className="pdf-highlighter-container-pdf"
          style={{
            transform: `scale(${zoomLevel})`,
            transformOrigin: 'center top',
            overflowY: 'auto',
            width: '50%',
          }}>
          <div ref={containerRefs[0]}></div>
        </div>
        {/* Document 2 */}
        <div className="pdf-highlighter-container-pdf"
          style={{
            transform: `scale(${zoomLevel})`,
            transformOrigin: 'center top',
            overflowY: 'auto',
            width: '50%',
          }}>
          <div ref={containerRefs[1]}></div>
        </div>
      </div>
    </div>
  );
};

export default PDFHighlighterNew;
