import React, { useState, useRef } from 'react';
import './Menu.css';
import MenuItemList from './MenuItemList';

const Menu = ({ strengths, weaknesses, onItemDrag, inactiveItems }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [menuWidth, setMenuWidth] = useState(400);
  const [searchTerm, setSearchTerm] = useState('');
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = e.clientX - menuRef.current.getBoundingClientRect().left;
    if (newWidth > 50) {
      setMenuWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      ref={menuRef}
      className={`menu-container ${isCollapsed ? 'collapsed' : ''}`}
      style={{ width: isCollapsed ? '40px' : `${menuWidth}px` }}
    >
      <div className="menu-toggle-button" onClick={toggleMenu}>
        <div className="recommendation-menu-hamburger">
          <div className="recommendation-menu-hamburger-line"></div>
          <div className="recommendation-menu-hamburger-line"></div>
          <div className="recommendation-menu-hamburger-line"></div>
        </div>
      </div>
      <div className="menu-content">
        <input
          type="text"
          className="menu-search-bar"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <MenuItemList
          title={`Strengths (${strengths.length})`}
          items={strengths}
          searchTerm={searchTerm}
          onItemDrag={onItemDrag}
          inactiveItems={inactiveItems}
        />
        <MenuItemList
          title={`Weaknesses (${weaknesses.length})`}
          items={weaknesses}
          searchTerm={searchTerm}
          onItemDrag={onItemDrag}
          inactiveItems={inactiveItems}
        />
      </div>
      <div
        className="menu-resize-handle"
        onMouseDown={handleMouseDown}
      ></div>
    </div>
  );
};

export default Menu;
