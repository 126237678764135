import React, { useState, useContext, useEffect } from 'react';
import * as xlsx from 'xlsx';
import ClientTable from '../../components/clients/ClientTable';
import ClientDetailsPopup from '../../components/clients/ClientDetailsPopup';
import { UserContext } from "../../context/UserContext";
import { useCollapse } from '../../context/CollapseContext';
import WidgetBar from '../../components/clients/WidgetBar';
import ExportModal from '../../components/clients/ExportModal';
import ImportModal from '../../components/clients/ImportModal';
import { fetchClients, updateClient, deleteClient } from '../../apis/ClientService';
import { useClients } from '../../context/ClientsContext';

import './Clients.css';

function Clients() {
  const [showSummary, setShowSummary] = useState(false);
  const [userContext, setUserContext] = useContext(UserContext);
  const { clients, isFetching, fetchTopClients, fetchAllClients, updateClient } = useClients(); // Access context state and methods
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [exportedRecordCount, setExportedRecordCount] = useState(0);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const { isCollapsed } = useCollapse();

  useEffect(() => {
    document.title = 'Clients';
  }, []);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  useEffect(() => {
    const username = userContext?.details?.username;
    if (username) {
      fetchAllClients(username); // Fetch clients when username is available
    }
  }, [userContext?.details?.username]); // Track changes to username

  const handleSearch = (searchText) => {
    const filtered = clients.filter(
      (client) =>
        client.personalDetails.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        client.personalDetails.lastName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const handleClientSelect = (client) => {

    setSelectedClient(client);
    setIsPopupOpen(true);
  };

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setSelectedClient(null);
  };


  const handleSaveClient = async (updatedClient) => {
    await updateClient(updatedClient); // Use context to update client
    setFilteredClients(prevClients =>
      prevClients.map(client => client._id === updatedClient._id ? updatedClient : client)
    );
  };

  const handlePopupSave = (updatedClient) => {
    setIsPopupOpen(false);
    setSelectedClient(null);
    handleSaveClient(updatedClient);

  };

  const calculateWidgetData = () => {
    const totalClients = clients.length;

    // Filter clients where policyDetails is defined and policy is 'Re Policy'
    const rePolicies = clients.filter(client => client.policyDetails && client.policyDetails.policy === 'Re Policy').length;

    // Calculate first-time policies by subtracting rePolicies from totalClients
    const firstTimePolicies = totalClients - rePolicies;

    // Calculate average premium only for clients with defined premium
    const avgPremium = totalClients > 0
      ? (clients.reduce((sum, client) => (client.policyDetails && client.policyDetails.premium ? sum + client.policyDetails.premium : sum), 0) / totalClients).toFixed(2)
      : 0;

    // Filter clients where annuityDetails is defined and calculate expiring policies
    const expiringPolicies = {
      oneWeek: clients.filter(client => client.annuityDetails && client.annuityDetails.daysTillExpiry <= 7).length,
      twoWeeks: clients.filter(client => client.annuityDetails && client.annuityDetails.daysTillExpiry > 7 && client.annuityDetails.daysTillExpiry <= 14).length,
      oneMonth: clients.filter(client => client.annuityDetails && client.annuityDetails.daysTillExpiry > 14 && client.annuityDetails.daysTillExpiry <= 30).length,
      sixMonths: clients.filter(client => client.annuityDetails && client.annuityDetails.daysTillExpiry > 30 && client.annuityDetails.daysTillExpiry <= 180).length,
      oneYear: clients.filter(client => client.annuityDetails && client.annuityDetails.daysTillExpiry > 180 && client.annuityDetails.daysTillExpiry <= 365).length,
    };

    return { totalClients, rePolicies, firstTimePolicies, avgPremium, expiringPolicies };
  };


  const widgetData = calculateWidgetData();

  const exportToExcel = () => {
    const ws = xlsx.utils.json_to_sheet(clients.map(client => ({
      'First Name': client.personalDetails.firstName,
      'Last Name': client.personalDetails.lastName,
    })));

    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Clients');

    xlsx.writeFile(wb, 'clients.xlsx');

    setExportedRecordCount(clients.length);
    setIsExportModalOpen(true);
  };

  const handleDeleteClient = async (clientId) => {
    const response = await deleteClient(clientId);
    if (response) {
      //TODO
    }
  };

  return (
    <div className="client-container">
      <h2>Clients</h2>
      <div className="client-container-white-page-container">
        <div className="client-container-header">
          <div className="client-container-header-buttons">
            <button className="client-container-header-button" onClick={() => setShowSummary(!showSummary)}>Summary</button>
            <button className="client-container-header-button" onClick={() => setIsAddModalOpen(true)}>Add</button>
            <button className="client-container-header-button" onClick={exportToExcel}>Export</button>
            <button className="client-container-header-button" onClick={() => setIsImportModalOpen(true)}>Import</button>
          </div>
          {showSummary && (
            <WidgetBar
              totalClients={widgetData.totalClients}
              rePolicies={widgetData.rePolicies}
              firstTimePolicies={widgetData.firstTimePolicies}
              avgPremium={widgetData.avgPremium}
              expiringPolicies={widgetData.expiringPolicies}
            />
          )}
        </div>
        <ClientTable
          clients={filteredClients}
          onSearch={searchText => setFilteredClients(clients.filter(
            client =>
              client.personalDetails.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
              client.personalDetails.lastName.toLowerCase().includes(searchText.toLowerCase())
          ))}
          onClientSelect={client => {
            setSelectedClient(client);
            setIsPopupOpen(true);
          }}
          onDelete={handleDeleteClient}
          onEdit={client => {
            setSelectedClient(client);
            setIsPopupOpen(true);
          }}
        />
        {isPopupOpen && selectedClient && (
          <ClientDetailsPopup
            client={selectedClient}
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            onSave={handlePopupSave}
          />
        )}
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={() => setIsExportModalOpen(false)}
          title={'Export Completed'}
          message={`Exported ${exportedRecordCount} clients to xlsx. Please check your downloads.`}
        />
        <ImportModal
          isOpen={isImportModalOpen}
          onClose={() => setIsImportModalOpen(false)}
        />
      </div>
    </div>
  );
}

export default Clients;
