import React, { useEffect } from 'react';
import { useCollapse } from '../../context/CollapseContext';
import './Eligibility.css';

const Eligibility = () => {
  useEffect(() => {
    document.title = 'Eligibility';
  }, []);
  const { isCollapsed } = useCollapse();

  return (
    <div className="eligibility-container">
      <h2>Your Eligibility</h2>
      <div className="eligibility-container-white" style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}/>
    </div>
  );
};

export default Eligibility;
