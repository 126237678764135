import React, { useState, useEffect, useRef } from 'react';
import './ToggleSwitch.css'; // Ensure your CSS handles dynamic width

const ToggleSwitch = ({ labels = ['NO', 'YES'], defaultActive = '', onActiveChange, height = '50px', radius = '15px', width = '200px' }) => {
    const [active, setActive] = useState(defaultActive !== '' ? defaultActive : labels[0]);
    const [buttonWidth, setButtonWidth] = useState(0);
    const textWidthRef = useRef(null);

    useEffect(() => {
        if (textWidthRef.current) {
            let maxWidth = 0;
            const spans = textWidthRef.current.querySelectorAll('span');
            spans.forEach(span => {
                maxWidth = Math.max(maxWidth, span.offsetWidth);
            });
            setButtonWidth(maxWidth + 20); // +20 for padding
        }
    }, []);

    useEffect(() => {
        // Calculate button width based on the provided width and the number of labels
        setButtonWidth(parseInt(width) / labels.length);
    }, [width, labels.length]);

    const handleChange = (newActive) => {
        setActive(newActive);
        if (onActiveChange) {
            onActiveChange(newActive === labels[1]);
        }
    };

    return (
        <div
            className="toggle-switch-container"
            style={{ width, borderRadius: radius, height: height }}
        >
            <div
                className="toggle-switch-overlay"
                style={{ width: buttonWidth, borderRadius: radius, left: `${labels.indexOf(active) * buttonWidth}px` }}
            ></div>
            {labels.map(label => (
                <button
                    key={label}
                    className={`toggle-switch-button ${active === label ? 'toggle-switch-button-selected' : ''}`}
                    onClick={() => handleChange(label)}
                    style={{ width: buttonWidth }}
                >
                    {label}
                </button>
            ))}
            {/* Hidden span for measuring text width */}
            <div ref={textWidthRef} style={{ position: 'absolute', visibility: 'hidden', height: 0, overflow: 'hidden' }}>
                {labels.map(label => <span key={label}>{label}</span>)}
            </div>
        </div>
    );
};

export default ToggleSwitch;
