import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ExportModal from '../../components/clients/ExportModal';
import { useClient } from '../../context/ClientContext';
import './ClientTable.css';

const getColorClass = (index) => `color-${index % 5}`;

const ClientTable = ({ clients, onSearch, onClientSelect, onEdit, onDelete }) => {
  const { activeClient, updateClient, setActiveClientById } = useClient();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [clientTypeFilter, setClientTypeFilter] = useState('');
  const [occupationIndustryFilter, setOccupationIndustryFilter] = useState('');
  const [previousClaimsFilter, setPreviousClaimsFilter] = useState('');
  const [quotesFilter, setQuotesFilter] = useState('');
  const [policiesFilter, setPoliciesFilter] = useState('');
  const [proposalsFilter, setProposalsFilter] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleClientTypeFilterChange = (e) => {
    setClientTypeFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleOccupationIndustryFilterChange = (e) => {
    setOccupationIndustryFilter(e.target.value);
    setCurrentPage(1);
  };

  const handlePreviousClaimsFilterChange = (e) => {
    setPreviousClaimsFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleQuotesFilterChange = (e) => {
    setQuotesFilter(e.target.value);
    setCurrentPage(1);
  };

  const handlePoliciesFilterChange = (e) => {
    setPoliciesFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleProposalsFilterChange = (e) => {
    setProposalsFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const promptDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDelete = (client) => {
    setIsDeleteModalOpen(false);
    onDelete(client._id);
  };

  const renderClaimTypes = (claims) => {
    const claimTypes = claims.reduce((acc, claim) => {
      const type = claim.claimType;
      if (acc[type]) {
        acc[type] += 1;
      } else {
        acc[type] = 1;
      }
      return acc;
    }, {});
    return Object.entries(claimTypes).map(([type, count], index) => (
      <div key={index} className="item-type-box">
        {count} - {type}
      </div>
    ));
  };

  const renderPolicyTypes = (policies) => {
    const policyTypes = policies.reduce((acc, policy) => {
      const type = policy.type;
      if (acc[type]) {
        acc[type] += 1;
      } else {
        acc[type] = 1;
      }
      return acc;
    }, {});
    return Object.entries(policyTypes).map(([type, count], index) => (
      <div key={index} className="item-type-box">
        {count} - {type}
      </div>
    ));
  };

  const filteredClients = clients.filter(client => {
    const fullName = `${client.personalDetails.firstName} ${client.personalDetails.lastName}`.toLowerCase();
    const matchesSearchText = fullName.includes(searchText.toLowerCase());
    const matchesClientType = clientTypeFilter ? client.clientType === clientTypeFilter : true;
    const occupationOrIndustry = client.clientType === 'Consumer' ? client.personalDetails.occupation : client.businessDetails.industry;
    const matchesOccupationIndustry = occupationIndustryFilter ? occupationOrIndustry.includes(occupationIndustryFilter) : true;
    const previousClaimsCount = client.previousClaims ? client.previousClaims.length : 0;
    const matchesPreviousClaims = previousClaimsFilter ? previousClaimsCount === Number(previousClaimsFilter) : true;
    const quotesCount = client.quotes ? client.quotes.length : 0;
    const matchesQuotes = quotesFilter ? quotesCount === Number(quotesFilter) : true;
    const policiesCount = client.policyDocuments ? client.policyDocuments.length : 0;
    const matchesPolicies = policiesFilter ? policiesCount === Number(policiesFilter) : true;
    const proposalsCount = client.proposals ? client.proposals.length : 0;
    const matchesProposals = proposalsFilter ? proposalsCount === Number(proposalsFilter) : true;

    return matchesSearchText && matchesClientType && matchesOccupationIndustry && matchesPreviousClaims && matchesQuotes && matchesPolicies && matchesProposals;
  });

  const totalRecords = filteredClients.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const clientsToDisplay = filteredClients.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSetClient = (clientId) => {
    setActiveClientById(clientId);
  };

  return (
    <div className="client-table-container">
      <div className="client-table-filters">
        <div className='client-table-filter-row'>
          <input
            type="text"
            placeholder="Search by name"
            value={searchText}
            onChange={handleSearchChange}
            className="client-table-search"
          />
          <select className="client-table-filter" value={clientTypeFilter} onChange={handleClientTypeFilterChange}>
            <option value="">All Client Types</option>
            <option value="Consumer">Consumer</option>
            <option value="Business">Business</option>
          </select>
          <input
            type="text"
            placeholder="Occupation/Industry"
            value={occupationIndustryFilter}
            onChange={handleOccupationIndustryFilterChange}
            className="client-table-filter"
          />
          <input
            type="number"
            placeholder="Previous Claims"
            value={previousClaimsFilter}
            onChange={handlePreviousClaimsFilterChange}
            className="client-table-filter"
          />
          <input
            type="number"
            placeholder="Number of Quotes"
            value={quotesFilter}
            onChange={handleQuotesFilterChange}
            className="client-table-filter"
          />
          <input
            type="number"
            placeholder="Number of Policies"
            value={policiesFilter}
            onChange={handlePoliciesFilterChange}
            className="client-table-filter"
          />
          <input
            type="number"
            placeholder="Number of Proposals"
            value={proposalsFilter}
            onChange={handleProposalsFilterChange}
            className="client-table-filter"
          />
          <select
            className="client-table-container-input"
            onChange={handleRecordsPerPageChange}
            value={recordsPerPage}
          >
            <option value={10}>Show 10</option>
            <option value={25}>Show 25</option>
            <option value={50}>Show 50</option>
            <option value={100}>Show 100</option>
          </select>
        </div>
      </div>
      <table className="client-table-table">
        <thead>
          <tr>
            <th style={{ minWidth: '180px' }} className="align-left">Full Name</th>
            <th className="align-left">ID</th>
            <th className="align-left">Type</th>
            <th className="align-left" >Policies</th>
            <th className="align-left" >Proposals</th>
            <th className="align-left" style={{ minWidth: '220px' }}>Previous Claims</th>
            <th style={{ width: '35px' }}></th>
          </tr>
        </thead>
        <tbody>
          {clientsToDisplay.map((client, index) => {
            const initials = `${client.personalDetails.firstName.charAt(0)}${client.personalDetails.lastName.charAt(0)}`;
            const colorClass = getColorClass(index);
            const previousClaimsCount = client.previousClaims ? client.previousClaims.length : 0;
            const quotesCount = client.quotes ? client.quotes.length : 0;
            const policiesCount = client.policyDocuments ? client.policyDocuments.length : 0;
            const proposalsCount = client.proposals ? client.proposals.length : 0;
            return (
              <tr key={client._id} onClick={() => handleSetClient(client._id)} >
                <td className="align-left" onClick={() => onClientSelect(client)}>
                  <span className={`colored-square ${colorClass}`}>
                    {initials}
                  </span>
                  {client.personalDetails.firstName} {client.personalDetails.lastName}
                </td>
                <td onClick={() => onEdit(client)} className="align-left">{client?.clientId}</td>
                <td onClick={() => onEdit(client)} className="align-left">{client.clientType}</td>
                <td onClick={() => onEdit(client)}>
                  <div className="policy-types-container">
                    {renderPolicyTypes(client.policyDocuments)}
                  </div>
                </td>
                <td onClick={() => onEdit(client)}>
                  <div className="claim-types-container">
                    <div key={`existing-${proposalsCount}`} className="item-type-box">
                      {proposalsCount} - Existing
                    </div>
                    <div key={`new-${proposalsCount}`} className="item-type-box-button" onClick={() => navigate('/advice')}>
                      New proposal...
                    </div>
                  </div>
                </td>
                <td onClick={() => onEdit(client)}>
                  <div className="claim-types-container">
                    {renderClaimTypes(client.previousClaims)}
                  </div>
                </td>
                <td>
                  <div className="client-table-action-button-container">
                    <button className="client-table-action-button">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </button>
                    <div className="pop-up-menu">
                      <div className="pop-up-menu-item" onClick={() => onEdit(client)}>Edit</div>
                      <div className="pop-up-menu-item" onClick={promptDelete}>Delete</div>
                      <ExportModal
                        showCancelButton={true}
                        isOpen={isDeleteModalOpen}
                        onClose={() => handleDelete(client)}
                        onCancel={() => setIsDeleteModalOpen(false)}
                        title={'Delete Client'}
                        message={`Are you sure you want to delete ${client.personalDetails.firstName} ${client.personalDetails.lastName}`}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="client-table-footer">
        <div>Showing {clientsToDisplay.length} of {totalRecords} records</div>
        <div className="pagination">
          <button disabled={currentPage === 1} onClick={() => handlePageChange(1)}>{'<'}</button>
          {[...Array(totalPages).keys()].map(pageNumber => (
            <button
              key={pageNumber + 1}
              className={currentPage === pageNumber + 1 ? 'active' : ''}
              onClick={() => handlePageChange(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button disabled={currentPage === totalPages} onClick={() => handlePageChange(totalPages)}>{'>'}</button>
        </div>
      </div>
    </div>
  );
};

export default ClientTable;
