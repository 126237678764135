import React from 'react';
import './SmallPopup.css';

const SmallPopup = ({ title, content, onClose, position }) => {
  return (
    <div className="small-popup" onClick={(e) => e.stopPropagation()}
      style={{ top: position.top, left: position.left }}>
      {/* <button className="close-button" onClick={onClose}>&times;</button> */}
      <div className="small-popup-content">
        {content}
      </div>
    </div>
  );
};

export default SmallPopup;
