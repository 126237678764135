import React from 'react';
import Tooltip from './Tooltip';

const SemicircularProgress = ({ count, total, text }) => {
    const percentage = total > 0 ? (count / total) * 100 : 0;
    const r = 50;
    const circ = Math.PI * r; // Circumference of a half-circle
    const strokePct = ((100 - percentage) * circ) / 100; // Stroke offset for the half-circle

    return (
        <Tooltip text={text}>
            <div style={{ width: '150px', height: '75px', position: 'relative', margin: '0 auto' }}>
                <svg width="100%" height="100%" viewBox="0 0 100 50">
                    <path
                        d="M 10,50 A 40,40 0 0,1 90,50"
                        fill="none"
                        stroke="lightgrey"
                        strokeWidth="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M 10,50 A 40,40 0 0,1 90,50"
                        fill="none"
                        stroke="rgb(79, 166, 88)"
                        strokeWidth="10"
                        strokeDasharray={circ}
                        strokeDashoffset={strokePct}
                        strokeLinecap="round"
                    />
                </svg>
                <div style={{
                    position: 'absolute',
                    padding: '0px',
                    top: '25px',
                    left: '60px',
                    textAlign: 'center',
                    fontSize: '18pt',
                    color: 'black'
                }}>
                    {percentage.toFixed(0)}%
                </div>
                <div style={{
                    position: 'absolute',
                    top: '60px',
                    left: '60px',
                    borderBlock: 'box-sizing',
                    textAlign: 'center',
                    fontSize: '8pt',
                    color: 'black'
                }}>
                    <span>{count}</span> / <span>{total}</span>
                </div>
            </div>
        </Tooltip>
    );
};

export default SemicircularProgress;
