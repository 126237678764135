import React, { useState, useRef, useEffect } from 'react';
import icon1 from '../../icons/products/Home.png';
import icon2 from '../../icons/products/Auto.png';
import icon3 from '../../icons/products/Life.png';
import icon4 from '../../icons/products/Health.png';
import icon5 from '../../icons/products/Specialty.png';
import icon6 from '../../icons/products/Travel.png';
import icon7 from '../../icons/products/Pet.png';
import icon8 from '../../icons/products/Umbrella.png';
import icon9 from '../../icons/products/Business.png';
import icon10 from '../../icons/products/Workers.png';
import icon11 from '../../icons/products/Liability.png';
import icon12 from '../../icons/products/Professional.png';
import icon13 from '../../icons/products/Cyber.png';
import icon14 from '../../icons/products/Agriculture.png';
import icon15 from '../../icons/products/Umbrella.png';
import icon16 from '../../icons/products/Aviation.png';
import icon17 from '../../icons/products/Credit.png';
import icon18 from '../../icons/products/Flood.png';
import icon19 from '../../icons/products/Reinsurance.png';
import icon20 from '../../icons/products/Specialty.png';
import './ProductCarousel.css';

const items = [
    {
        icon: icon1,
        label: "Home & Property ",
        bulletPoints: [
            "Homeowners Insurance",
            "Renters Insurance",
            "Landlord Insurance",
            "Flood Insurance"
        ]
    },
    {
        icon: icon2,
        label: "Auto & Vehicle",
        bulletPoints: [
            "Auto Insurance",
            "Motorcycle Insurance",
            "Boat Insurance",
            "RV Insurance"
        ]
    },
    {
        icon: icon3,
        label: "Life ",
        bulletPoints: [
            "Term Life Insurance",
            "Whole Life Insurance",
            "Universal Life Insurance",
            "Accidental Death Insurance"
        ]
    },
    {
        icon: icon4,
        label: "Health",
        bulletPoints: [
            "Individual Health Insurance",
            "Family Health Insurance",
            "Dental Insurance",
            "Vision Insurance"
        ]
    },
    {
        icon: icon5,
        label: "Disability",
        bulletPoints: [
            "Short-term Disability",
            "Long-term Disability",
            "Critical Illness Insurance",
            "Income Protection"
        ]
    },
    {
        icon: icon6,
        label: "Travel",
        bulletPoints: [
            "Trip Cancellation",
            "Medical Evacuation",
            "Baggage Loss",
            "Travel Delay"
        ]
    },
    {
        icon: icon7,
        label: "Pet",
        bulletPoints: [
            "Accident and Illness Coverage",
            "Routine Care Coverage",
            "Hereditary Condition Coverage",
            "Emergency Care"
        ]
    },
    {
        icon: icon8,
        label: "Umbrella",
        bulletPoints: [
            "Personal Liability Coverage",
            "Property Damage Liability",
            "Legal Defense Costs",
            "Worldwide Coverage"
        ]
    },
    {
        icon: icon9,
        label: "Business",
        bulletPoints: [
            "General Liability Insurance",
            "Commercial Property Insurance",
            "Business Interruption Insurance",
            "Product Liability Insurance"
        ]
    },
    {
        icon: icon10,
        label: "Workers' Comp",
        bulletPoints: [
            "Medical Expenses Coverage",
            "Lost Wages Coverage",
            "Disability Benefits",
            "Employer Liability"
        ]
    },
    {
        icon: icon11,
        label: "Liability",
        bulletPoints: [
            "General Liability",
            "Product Liability",
            "Public Liability",
            "Employers Liability"
        ]
    },
    {
        icon: icon12,
        label: "Professional",
        bulletPoints: [
            "Errors and Omissions",
            "Malpractice Insurance",
            "Directors and Officers",
            "Fiduciary Liability"
        ]
    },
    {
        icon: icon13,
        label: "Cyber",
        bulletPoints: [
            "Data Breach Coverage",
            "Network Security Liability",
            "Cyber Extortion",
            "Business Interruption"
        ]
    },
    {
        icon: icon14,
        label: "Agriculture",
        bulletPoints: [
            "Crop Insurance",
            "Livestock Insurance",
            "Farm Equipment Insurance",
            "Farm Liability Insurance"
        ]
    },
    {
        icon: icon15,
        label: "Marin",
        bulletPoints: [
            "Cargo Insurance",
            "Hull Insurance",
            "Marine Liability Insurance",
            "Freight Insurance"
        ]
    },
    {
        icon: icon16,
        label: "Aviation",
        bulletPoints: [
            "Aircraft Hull Insurance",
            "Passenger Liability",
            "Public Liability",
            "Ground Risk Hull Insurance"
        ]
    },
    {
        icon: icon17,
        label: "Credit & Surety",
        bulletPoints: [
            "Trade Credit Insurance",
            "Surety Bonds",
            "Fidelity Bonds",
            "Performance Bonds"
        ]
    },
    {
        icon: icon18,
        label: "Flood",
        bulletPoints: [
            "Building Property Coverage",
            "Personal Property Coverage",
            "Replacement Cost Coverage",
            "Cleanup and Debris Removal"
        ]
    },
    {
        icon: icon19,
        label: "Reinsurance",
        bulletPoints: [
            "Proportional Reinsurance",
            "Non-proportional Reinsurance",
            "Catastrophe Bonds",
            "Retrocession"
        ]
    },
    {
        icon: icon20,
        label: "Specialty",
        bulletPoints: [
            "Event Insurance",
            "Kidnap and Ransom Insurance",
            "Fine Arts Insurance",
            "Jewelry Insurance"
        ]
    }
];


const Carousel = ({ items }) => {
    const carouselRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const handleMouseLeaveOrUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 0.7; // scroll-fast
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    const scrollLeftOne = () => {
        carouselRef.current.scrollBy({ left: -350, behavior: 'smooth' });
    };

    const scrollRightOne = () => {
        carouselRef.current.scrollBy({ left: 350, behavior: 'smooth' });
    };

    const handleScroll = () => {
        const scrollPosition = carouselRef.current.scrollLeft;
        const maxScroll = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;

        if (scrollPosition < maxScroll / 3) {
            setActiveIndex(0);
        } else if (scrollPosition < (2 * maxScroll) / 3) {
            setActiveIndex(1);
        } else {
            setActiveIndex(2);
        }
    };

    useEffect(() => {
        const carouselElement = carouselRef.current;
        if (carouselElement) {
            carouselElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div className="carousel-container">
            <h2 className="carousel-title">Supported Policies</h2>
            {/* <div className="carousel-description">We support the following policy types</div> */}
            <button className="carousel-button left" onClick={scrollLeftOne}>
                &lt;
            </button>
            <div
                className="carousel"
                ref={carouselRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeaveOrUp}
                onMouseUp={handleMouseLeaveOrUp}
                onMouseMove={handleMouseMove}
            >
                {items.map((item, index) => (
                    <div key={index} className="carousel-item">
                        <div className="icon-container">
                            <img src={item.icon} alt={item.label} className="carousel-icon" />
                        </div>
                        <div className="carousel-label">
                            {item.label}
                        </div>
                        <ul className="carousel-bullets">
                            {item.bulletPoints.map((point, idx) => (
                                <li key={idx} className="carousel-bullet-item">{point}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <button className="carousel-button right" onClick={scrollRightOne}>
                &gt;
            </button>
            <div className="carousel-navigation">
                <div className={`carousel-circle ${activeIndex === 0 ? 'active' : ''}`}></div>
                <div className={`carousel-circle ${activeIndex === 1 ? 'active' : ''}`}></div>
                <div className={`carousel-circle ${activeIndex === 2 ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

const ProductCarousel = () => {
    return (
        <div className='product-carousel'>
            <Carousel items={items} />
        </div>
    );
};

export default ProductCarousel;
