import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import './BlogCarousel.css';

const BlogCarousel = ({ blogs }) => {
  const navigate = useNavigate();
  const [mostRecent, setMostRecent] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState(new Set(blogs.map(blog => blog.topics).flat()));
  const [selectedProducts, setSelectedProducts] = useState(new Set(blogs.map(blog => blog.products).flat()));

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 1,
    draggable: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '10px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
        }
      }
    ]
  };

  // Helper function to get the four most recent blogs
  const getMostRecentBlogs = (blogs) => {
    return blogs
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 4);
  };

  // Filter blogs based on selected topics and products
  const filteredBlogs = blogs.filter(blog => {
    const matchesTopics = [...selectedTopics].some(topic => blog.topics.includes(topic));
    const matchesProducts = [...selectedProducts].some(product => blog.products.includes(product));
    return matchesTopics && matchesProducts;
  });

  // If 'mostRecent' is true, show only the four most recent blogs
  const blogsToDisplay = mostRecent ? getMostRecentBlogs(filteredBlogs) : filteredBlogs;

  // Handle checkbox change for topics
  const handleTopicChange = (topic) => {
    setSelectedTopics(prev => {
      const newSet = new Set(prev);
      if (newSet.has(topic)) {
        newSet.delete(topic);
      } else {
        newSet.add(topic);
      }
      return newSet;
    });
  };

  // Handle checkbox change for products
  const handleProductChange = (product) => {
    setSelectedProducts(prev => {
      const newSet = new Set(prev);
      if (newSet.has(product)) {
        newSet.delete(product);
      } else {
        newSet.add(product);
      }
      return newSet;
    });
  };

  // Select All Topics
  const selectAllTopics = () => {
    setSelectedTopics(new Set(blogs.map(blog => blog.topics).flat()));
  };

  // Clear All Topics
  const clearAllTopics = () => {
    setSelectedTopics(new Set());
  };

  // Select All Products
  const selectAllProducts = () => {
    setSelectedProducts(new Set(blogs.map(blog => blog.products).flat()));
  };

  // Clear All Products
  const clearAllProducts = () => {
    setSelectedProducts(new Set());
  };

  return (
    <div className="blog-carousel-container">
      <div className="blog-carousel-header">
        <div className='blog-carousel-blog-title'>Our Blog</div>
        <div className="blog-carousel-blog-filters">
          <div className="blog-carousel-dropdown">
            <label
              className={`blog-carousel-filter-button ${mostRecent ? 'active' : ''}`}
              onClick={() => setMostRecent(!mostRecent)}
            >
              Recent
            </label>
          </div>
          <div className="blog-carousel-dropdown">
            <label className="blog-carousel-filter-button">Topics</label>
            <div className="blog-carousel-dropdown-content">
              {Array.from(new Set(blogs.map(blog => blog.topics).flat())).map(topic => (
                <label key={topic}>
                  <input
                    type="checkbox"
                    checked={selectedTopics.has(topic)}
                    onChange={() => handleTopicChange(topic)}
                  />
                  {topic}
                </label>
              ))}
              <div className="blog-carousel-dropdown-buttons">
                <button onClick={selectAllTopics}>Select All</button>
                <button onClick={clearAllTopics}>Clear All</button>
              </div>
            </div>
          </div>
          <div className="blog-carousel-dropdown">
            <label className="blog-carousel-filter-button">Products</label>
            <div className="blog-carousel-dropdown-content">
              {Array.from(new Set(blogs.map(blog => blog.products).flat())).map(product => (
                <label key={product}>
                  <input
                    type="checkbox"
                    checked={selectedProducts.has(product)}
                    onChange={() => handleProductChange(product)}
                  />
                  {product}
                </label>
              ))}
              <div className="blog-carousel-dropdown-buttons">
                <button onClick={selectAllProducts}>Select All</button>
                <button onClick={clearAllProducts}>Clear All</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='blog-carousel-title-desc'>
        We publish weekly with the latest insights into the insurance market for brokers.  Get the best market insights here.
      </div>
      <div className="blog-carousel">
        <Slider {...settings}>
          {blogsToDisplay.map((blog) => (
            <div key={blog.id} className="blog-carousel-blog-card" onDoubleClick={() => navigate('/blog')}>
              <div className="blog-carousel-blog-card-img">
                <img src={blog.image} alt={blog.label} />
              </div>
              <div className="blog-carousel-blog-info" onClick={() => navigate('/blog')}>
                <div className="blog-carousel-title-date">
                  {blog.date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </div>
                <div className="blog-carousel-label">{blog.label}</div>
                <div className="blog-carousel-title">{blog.title}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BlogCarousel;
