import React, { useState, useEffect } from 'react';
import AdviceHeader from '../AdviceHeader';

const ProposalScope = ({ isOpen, toggleSection, proposal, updateProposal, pdf }) => {
    const id = 'scope';
    const [completed, setCompleted] = useState(false);

    const [scopeItems, setScopeItems] = useState({
        'Home Insurance': false,
        'Contents Insurance': false,
        'Landlord Insurance': false,
        'ISR Insurance': false,
        'Vehicle Insurance': false,
    });

    const isAtLeastOneChecked = () => {
        return (Object.values(scopeItems).some(isChecked => isChecked));
    };

    useEffect(() => {
        if (!completed && isAtLeastOneChecked()) {
            setCompleted(true);
        }
        if (completed && !isAtLeastOneChecked()) {
            setCompleted(false);
        }
    }, [scopeItems]);

    const toggleAdviceCheckbox = (item) => {
        setScopeItems(prev => ({
            ...prev,
            [item]: !prev[item]
        }));
    };

    useEffect(() => {
        updateProposal(id, scopeItems)
    }, [scopeItems]);

    return (
        <div className="form-section">
            <AdviceHeader
                id={id}
                toggleSection={toggleSection}
                title={'Scope'}
                tooltip={'A clear definition of what the advice covers and any limitations. This might include the types of insurance products discussed and any specific requests or concerns addressed by the client.'}
                completed={completed}
                pdf={pdf}
                isOpen={isOpen}
            />
            {isOpen && (
                <div>
                    <div className='form-section-options'>
                        {Object.entries(scopeItems).map(([key]) => (
                            <div className='form-section-option'
                                key={key}
                                onClick={() => toggleAdviceCheckbox(key)}
                                style={{ cursor: 'pointer' }}>
                                <input
                                    type="checkbox"
                                    style={{ width: '20px' }}
                                    checked={scopeItems[key]}
                                />
                                <div>{key}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProposalScope;
