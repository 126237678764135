import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import './PolicyScore.css';

function PolicyScore() {
    const { activeDocument } = useGlobalContext();
    const [attributes, setAttributes] = useState({});
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [policyCount, setPolicyCount] = useState(0);

    let includedScore = 0;
    let excludedScore = 0;

    const calculateSumOfScores = () => {
        includedScore = included.reduce((accumulator, currentItem) => accumulator + currentItem.score, 0);
        excludedScore = excluded.reduce((accumulator, currentItem) => accumulator + currentItem.score, 0);
    };

    const getIncludedScore = () => {
        includedScore = included.reduce((accumulator, currentItem) => accumulator + currentItem.score, 0);
        return includedScore;
    };
    const getExcludedScore = () => {
        excludedScore = excluded.reduce((accumulator, currentItem) => accumulator + currentItem.score, 0);
        return excludedScore;
    };

    const getTotalScore = () => {
        let totalScore = 0;
        let includedScoreWeighted = getIncludedScore();
        let excludedScoreWeighted = getExcludedScore();
        totalScore = (((policyCount + excludedScoreWeighted) / policyCount) * 100).toFixed(0);

        return isNaN(totalScore) ? 0 : (parseInt(totalScore).toFixed(1));
    };

    function formatCoverageName(coverageName) {
        const withSpaces = coverageName.replace(/([A-Z])/g, ' $1').replace(/ And /g, " & ");
        const updatedCoverageName = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

        switch (true) {
            case updatedCoverageName === 'Temporary Accommodation':
                return 'Temporary Accom.';
            default:
                return updatedCoverageName;
        }
    }

    const clearArray = () => {
        includedScore = 0;
        excludedScore = 0;
        setIncluded([]);
        setExcluded([]);
    };

    const processItem = (subDisplayName, caseValue) => {
        if (caseValue.included === false) {
            setExcluded((prevOptional) => [
                ...prevOptional,
                {
                    score: -0.5,
                    description: formatCoverageName(subDisplayName),
                    reason: caseValue.reason,
                },
            ]);
        } else if (caseValue.included) {
            setIncluded((prevIncluded) => [
                ...prevIncluded,
                {
                    score: 1,
                    description: formatCoverageName(subDisplayName),
                    reason: caseValue.reason,
                },
            ]);
        }
        calculateSumOfScores();
    };

    const fetchPolicies = async () => {
        if (activeDocument !== null && activeDocument !== undefined) {
            try {
                const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;
                const response = await fetch(
                    process.env.REACT_APP_API_ENDPOINT +
                    `users/api/insurance-provider?policyProvider=${activeFileName}`
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data && data.coverages) {
                    setAttributes(data.coverages);
                } else {
                    
                    // Handle the case where data.coverages is null or undefined
                    // For example, you can set it to an empty object or take other appropriate actions.
                    setAttributes({});
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        }
    };




    const processPolicies = async () => {
        clearArray();
        let count = 0;
        if (attributes && Object.keys(attributes).length > 0) {
            Object.entries(attributes).map(([displayName, value]) => {
                if (displayName !== "naturalDisasters") {
                    Object.entries(value).map(([subDisplayName, subValue]) => {
                        count = count + 1;
                        if (
                            subDisplayName !== "_id" &&
                            subDisplayName !== "included" &&
                            subDisplayName !== "excess" &&
                            subDisplayName !== "limit" &&
                            subDisplayName !== "optional"
                        ) {
                            processItem(subDisplayName, subValue);
                        }
                    });
                }
            });
        } else {
            
        }
        setPolicyCount(count);
    };



    const loadScore = async () => {
        clearArray();
        await fetchPolicies(); // Wait for fetchPolicies to finish
    };

    useEffect(() => {
        
        loadScore();
    }, [activeDocument]);

    useEffect(() => {
        
        loadScore();
    }, []);

    useEffect(() => {
        processPolicies(); // Process policies whenever attributes change
    }, [attributes]);

    return (
        <div className="insurance-score">
            <div className="insurance-container">
                <div className="score-circle">
                    <div className="score-number">
                        {getTotalScore()}
                        <span>%</span>
                    </div>
                </div>
                <div className='insurance-score-line-vertical'>
                    <p></p>
                </div>
                <div className='insurance-score-line'>
                    <p></p>
                </div>
                <div className="score-tables">
                    <table className={`score-table-1`}>
                        <thead>
                            <tr>
                                <th colSpan="2">Strengths</th>
                            </tr>
                        </thead>
                        <tbody>
                            {included.map((item, index) => (
                                <tr key={index}>
                                    <td className="strengths">+{item.score}</td>
                                    <td>
                                        <div className="reasoning">
                                            <p style={{ fontWeight: 'bold' }}>{item.description}</p>
                                            <p>{item.reason}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className={`score-table-2`}>
                        <thead>
                            <tr>
                                <th colSpan="2">Weaknesses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {excluded.map((item, index) => (
                                <tr key={index}>
                                    <td className="weaknesses">{item.score}</td>
                                    <td>
                                        <div className="reasoning">
                                            <p style={{ fontWeight: 'bold' }}>{item.description}</p>
                                            <p>{item.reason}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default PolicyScore;