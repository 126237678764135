import React from 'react';
import { Pie } from 'react-chartjs-2';
import './LeadSourceAnalysis.css';

const LeadSourceAnalysis = ({ leadData }) => {
  const data = {
    labels: Object.keys(leadData),
    datasets: [{
      data: Object.values(leadData),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      // borderColor: [
      //   'rgba(255,99,132,1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)'
      // ],   
      hoverBackgroundColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'
      ],     
    }]
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="lead-source-analysis">
      <Pie data={data} options={options} />
    </div>
  );
};

export default LeadSourceAnalysis;
