import React, { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/UserContext";
import {submitSupportFeedback} from '../../apis/EmailService';
import './FeedbackSupport.css';

function FeedbackSupport({ onClose }) {
  const [userContext] = useContext(UserContext);
  const [formData, setFormData] = useState({
    type: '',
    topic: '',
    customTopic: '',
    communicationPreference: '',
    summary: ''
  });
  const [showThankYou, setShowThankYou] = useState(false);
  const [emojiFeedback, setEmojiFeedback] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEmojiClick = (emoji) => {
    setEmojiFeedback(emoji);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowThankYou(true);

    submitSupportFeedback(
      userContext.details.username,       
      userContext.details.firstName,   
      formData.topic === 'Other' ? formData.customTopic : formData.topic,     
      formData.summary)
    .then(message => console.log(message))
    .catch(error => console.error(error));

    setTimeout(() => {
      setShowThankYou(false);
      onClose();
    }, 3000);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('feedback-support-overlay')) {
      onClose();
    }
  };

  return (
    <div className="feedback-support-overlay" onClick={handleOverlayClick}>
      <div className={`feedback-support-popup ${showThankYou ? 'centered' : 'flex-start'}`}>
        {!showThankYou && (
          <>
            <div className="emoji-feedback-section">
              <h2>How are you feeling?</h2>
              <p>Your input is valuable in helping us tailor our service.</p>
              <div className="emoji-feedback-options">
                <span className={emojiFeedback === 'sad' ? 'selected' : ''} onClick={() => handleEmojiClick('sad')}>😢</span>
                <span className={emojiFeedback === 'meh' ? 'selected' : ''} onClick={() => handleEmojiClick('meh')}>😐</span>
                <span className={emojiFeedback === 'medium' ? 'selected' : ''} onClick={() => handleEmojiClick('medium')}>😏</span>
                <span className={emojiFeedback === 'happy' ? 'selected' : ''} onClick={() => handleEmojiClick('happy')}>🙂</span>
                <span className={emojiFeedback === 'very-happy' ? 'selected' : ''} onClick={() => handleEmojiClick('very-happy')}>😁</span>
              </div>
            </div>
            <div className='feedback-support-div' />
            <form className="feedback-support-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <h3>How can we help?</h3>
                <select name="topic" onChange={handleInputChange} required>
                  <option value="">Select a topic</option>
                  <option value="Product Quality">Product Quality</option>
                  <option value="Billing">Billing</option>
                  <option value="Account Issues">Account Issues</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="Suggestions">Suggestions</option>
                  <option value="Other">Other</option>
                </select>

                {formData.topic === 'Other' && (
                  <input
                    type="text"
                    name="customTopic"
                    placeholder="Write your own topic"
                    onChange={handleInputChange}
                    required
                  />
                )}
                <textarea
                  name="summary"
                  rows="6"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="feedback-support-send-button">Submit Now</button>
            </form>
          </>
        )}
        {showThankYou && (
          <div className="thank-you-message">
            <p style={{fontSize:'24pt'}}>Thank you for your feedback! </p>
            <p>We will get back to you soon.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeedbackSupport;
