import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

// Formatter for currency display
const currencyFormatter = (value) => `$${value.toLocaleString()}`;

// Custom Tooltip component
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p>{label}</p>
                <p>{`Premium: ${currencyFormatter(payload[0].value)}`}</p>
            </div>
        );
    }

    return null;
};

const TopClientsBarGraph = ({ title, data }) => {
    // Sort sales by insurancePremium and take the top 10
    const topSales = data
        .sort((a, b) => b.insurancePremium - a.insurancePremium)
        .slice(0, 10);

    return (
        <div className="commissions-metric-card" style={{ maxWidth: '600px' }}>
            <h3 style={{ paddingBottom: "15px" }}>{title}</h3>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    layout="vertical"
                    data={topSales}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="client" type="category" width={150} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="insurancePremium" fill="rgb(79,166,88)" barSize={20}>
                        <LabelList dataKey="insurancePremium" position="insideRight" style={{ fill: 'white' }} formatter={currencyFormatter} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TopClientsBarGraph;
