import axios from 'axios';

// Fetch all quotes for a specific client and proposal
export const getQuotes = (clientID, proposalID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/quotes`, {
    params: { clientID, proposalID }
  });

// Fetch a specific quote by ID
export const getQuoteData = (clientID, proposalID, quoteID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/quotes/${quoteID}`, {
    params: { clientID, proposalID }
  });
