import React from 'react';
import FlowChart from '../../components/workflow/FlowChart';
import SideBar from '../../components/workflow/SideBar';
import { ReactFlowProvider } from 'react-flow-renderer';
import './Workflow.css';

const Workflow = () => {

  return (
    <div className="flow-chart-component">
      <h2>Needs Capture: Build a Workflow</h2>
      <div className={`flow-chart-white`} >
        <div className="flow-chart-box">
          <ReactFlowProvider>
            <div className="dndflow">
              <SideBar />
              <FlowChart />
            </div>
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default Workflow;