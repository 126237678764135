import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // useNavigate instead of useHistory
import MyProfile from './MyProfile';
import MyPreferences from './MyPreferences';
import MyPrivacy from './MyPrivacy';
import MyPlan from './MyPlan';
import MySecurity from './MySecurity'; 
import MyNotifications from './MyNotifications';
import Billing from './Billing';
import DataExport from './DataExport';
import "./AccountSettings.css";

const AccountSettings = () => {
  const location = useLocation(); // Hook to access the current location (URL)
  const navigate = useNavigate(); // useNavigate for programmatic navigation
  const [selectedMenu, setSelectedMenu] = useState("My Profile");
  useEffect(() => {
      document.title = 'Account Settings';
    }, []);

  const menuItems = [
    "My Profile",
    "My Preferences",
    "Security",
    "Notifications",
    "Billing",
    "My Plan",
    "Data Export",
    "Privacy",
  ];

  // Parse the query parameters to check for a menu item
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const menuParam = params.get('menu'); // Get the 'menu' query parameter

    // If the menuParam matches one of the menuItems, set it as the selectedMenu
    if (menuParam && menuItems.includes(menuParam)) {
      setSelectedMenu(menuParam);
    }
  }, [location.search]); // Runs whenever the URL changes

  // Update the URL when the selected menu changes due to a click
  const handleMenuClick = (item) => {
    setSelectedMenu(item);
    // Update the URL to include the new menu selection using navigate
    navigate(`/settings?menu=${encodeURIComponent(item)}`);
  };

  // Function to determine which content to display based on selectedMenu
  const renderContent = () => {
    switch (selectedMenu) {
      case "My Profile":
        return <MyProfile />;
      case "My Preferences":
        return <MyPreferences />;
      case "Security":
        return <MySecurity />;
      case "Notifications":
        return <MyNotifications />;
      case "Billing":
        return <Billing />;
      case "My Plan":
        return <MyPlan />;
      case "Data Export":
        return <DataExport />;
      case "Privacy":
        return <MyPrivacy />;
      default:
        return <MyProfile />;
    }
  };

  return (
    <div className="account-settings-container">
      <div className="account-settings-menu">
        <h2 className="account-settings-title">Account Settings</h2>
        <ul className="account-settings-menu-list">
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={`account-settings-menu-item ${
                selectedMenu === item ? "active" : ""
              }`}
              onClick={() => handleMenuClick(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="account-settings-content">
        {/* <div className="account-settings-section-title">{selectedMenu}</div> */}
        <div className="account-settings-section-content">
          {renderContent()} {/* Dynamically renders the content based on selectedMenu */}
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
