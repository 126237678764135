import React from 'react';
import ProposalClientDetails from './components/ProposalClientDetails';
import ProposalScope from './components/ProposalScope';
import ProposalQuotes from './components/ProposalQuotes';
import ProposalSummary from './components/ProposalSummary';
import ProposalDisclaimers from './components/ProposalDisclaimers';

const Proposal = ({ sections, toggleSection, proposal, setProposal, pdf = false}) => {

    const updateProposal = (name, value) => {
        setProposal(prev => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <ProposalClientDetails
                isOpen={sections.clientDetails}
                toggleSection={() => toggleSection('clientDetails')}
                proposal={proposal}
                updateProposal={updateProposal}
                pdf={pdf}
            />
            <ProposalScope
                isOpen={sections.scope}
                toggleSection={() => toggleSection('scope')}
                proposal={proposal}
                updateProposal={updateProposal}
                pdf={pdf}
            />
            <ProposalQuotes
                isOpen={sections.quotes}
                toggleSection={() => toggleSection('quotes')}
                proposal={proposal}
                updateProposal={updateProposal}
                pdf={pdf}
            />
            <ProposalSummary
                isOpen={sections.summary}
                toggleSection={() => toggleSection('summary')}
                proposal={proposal}
                updateProposal={updateProposal}
                pdf={pdf}
            />
            <ProposalDisclaimers
                isOpen={sections.disclaimers}
                toggleSection={() => toggleSection('disclaimers')}
                proposal={proposal}
                updateProposal={updateProposal}
                pdf={pdf}
            />
        </>
    );
};

export default Proposal;
