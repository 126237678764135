import React from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import './CurrentSituationSummary.css';

const CurrentSituationSummary = () => {
  const { activeDocument } = useGlobalContext();

  return (
    <div className="current-summary-container" >
      <div className='current-summary-container-div'>
        <div className="current-summary-h1">
          <span><strong>Comprehensive {activeDocument?.policyType} insurance with {activeDocument?.policyProvider}</strong></span><br /><br />
        </div>
        <div className="current-summary-h3">
          This policy offers extensive coverage for various property structures,
          including residential buildings, outbuildings, fixed domestic structures, and features like solar power
          systems and fixed swimming pools. It also covers interior features such as carpets, fixed floor coverings,
          and permanent appliances. Additionally, exterior features like fences, decks, and built-in barbeques are
          protected, along with temporary structures and essential items like keys and locks.
        </div>
      </div>
    </div>
  );
};

export default CurrentSituationSummary;
