import React from 'react';

const ChangeInCircumstance = ({ policyDetails, setPolicyDetails, onBack, onNext }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPolicyDetails({
            ...policyDetails,
            [name]: value,
        });
    };

    return (
        <>
            <div className="upload-container-row">
                <div className="upload-container-column-no-gap">
                    <div className="upload-container-option-header">
                        <div className="upload-container-option-header-title">Changes in circumstance</div>
                    </div>
                    <div className="upload-inputs">
                        <div className="upload-input">
                            <label className="upload-input-label">Home Usage</label>
                            <div className="upload-dot-box-grid">
                                {['occupied', 'rented', 'vacant', 'holidayHome', 'semiOccupied'].map(option => (
                                    <div key={option} className="upload-dot" onClick={() => setPolicyDetails({ ...policyDetails, homeUse: option })}>
                                        <div className="upload-input-label">{option.charAt(0).toUpperCase() + option.slice(1)}</div>
                                        <div className={`upload-dot-circle-large ${policyDetails.homeUse === option ? 'option-circle-selected' : ''}`}>
                                            <div className={`upload-dot-circle-small ${policyDetails.homeUse === option ? 'option-circle-small-selected' : ''}`} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="upload-input">
                            <label className="upload-input-label" htmlFor="homeSumInsured">Home Sum Insured</label>
                            <input
                                className="upload-input-text"
                                type="text"
                                id="homeSumInsured"
                                name="homeSumInsured"
                                value={policyDetails.homeSumInsured}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="upload-input">
                            <label className="upload-input-label" htmlFor="policyAddress">Policy Address</label>
                            <input
                                className="upload-input-text"
                                type="text"
                                id="policyAddress"
                                name="policyAddress"
                                value={policyDetails.policyAddress}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="upload-buttons">
                <button className="upload-buttons-back" onClick={onBack}>Back</button>
                <button className="upload-buttons-next" onClick={onNext}>Next</button>
            </div>
        </>
    );
};

export default ChangeInCircumstance;
