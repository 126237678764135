import React, { useEffect, useState, useRef } from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import { useCompareContext } from '../context/CompareContext';
import Tooltip from './Tooltip';
import './DynamicSlider.css';

const DynamicSlider = ({ markers, displayCompare = true }) => {
  const { activeDocument } = useGlobalContext();
  const { compareDocument } = useCompareContext();
  const [range, setRange] = useState([]);
  const containerRef = useRef(null); // Reference to the container

  const convertToNumber = (input) => {
    let number = typeof input === 'string' ? input.replace(/[$,]/g, '') : input;
    return parseFloat(number) || 0; // Handle NaN case
  };

  function getMaxCount(scores) {
    const scoreCounts = {};
    Object.keys(scores).forEach(key => {
      const item = scores[key];
      // Assuming you want to count scores only for visible items
      if (item.visible) {
        const score = item.score;
        if (scoreCounts[score]) {
          scoreCounts[score]++;
        } else {
          scoreCounts[score] = 1;
        }
      }
    });
    // Find the maximum count
    let maxCount = 0;
    Object.values(scoreCounts).forEach(count => {
      if (count > maxCount) {
        maxCount = count;
      }
    });
    return maxCount;
  }

  useEffect(() => {
    const heightWeighting = getMaxCount(markers);
    if (containerRef.current) {
      containerRef.current.style.height = `${110 + (heightWeighting * 45)}px`; // Set the height dynamically
    }
  }, [markers]); 

  function findMinMaxScores(scores) {
    let minScore = Infinity; // Start with the highest possible number
    let maxScore = -Infinity; // Start with the lowest possible number

    // Iterate over the keys in the scores object
    Object.keys(scores).forEach(key => {
      const item = scores[key];
      // Check if the item is visible
      if (item.visible) {
        const score = item.score;
        if (score < minScore) {
          minScore = score; // Update minScore if current score is lower
        }
        if (score > maxScore) {
          maxScore = score; // Update maxScore if current score is higher
        }
      }
    });

    // Handle the case where no visible items were found
    if (minScore === Infinity || maxScore === -Infinity) {
      return { minScore: null, maxScore: null };
    }

    return { minScore, maxScore };
  }

  useEffect(() => {
    let scores = findMinMaxScores(markers);
    setRange([scores.minScore * 0.98, scores.maxScore * 1.02]);
  }, [markers]);  

  const formatNumber = (number) => {
    if (number >= 1000) {
      const dividedNumber = number / 1000;
      return `${dividedNumber.toFixed(0)}k`;
    }
    return `${number}`;
  };

  const calculatePosition = (value, range) => {
    return ((value - range[0]) / (range[1] - range[0])) * 100
  };

  const renderMarkers = () => {
    const markersArray = [];
    let numberOfOccurences = {}; // Track number of times each marker limit is used

    Object.keys(markers).filter(key => markers[key].visible)
    .forEach(markerName => {

      const marker = markers[markerName].score;
      const markerLimit = convertToNumber(marker);

      // Counting occurrences of markerLimit
      if (!numberOfOccurences[markerLimit]) {
        numberOfOccurences[markerLimit] = 1;
      } else {
        numberOfOccurences[markerLimit]++;
      }

      // Calculate offset based on occurrence
      const offsetY = -35 * (numberOfOccurences[markerLimit] - 1);
      const providerName = markers[markerName]?.code || markers[markerName]?.provider; // Get the provider name from markers
      let displayName = providerName

      // if (mapDisplayNames.hasOwnProperty(providerName)) {
      //     displayName = mapDisplayNames[providerName]; // Access the display name if it exists
      // }

      // Render the marker
      const markerElement = (
        <div
          key={`${markers[markerName].provider}-${markerLimit}-${numberOfOccurences[markerLimit]}`} // Unique key for each marker
          className="dynamic-slider-marker-container"
          style={{
            left: `${calculatePosition(markerLimit, range)}%`,
            top: `${offsetY}px`, // Adjust vertical position based on occurrence
          }}
          title={markers[markerName].provider} // Tooltip added here
        >
          <Tooltip text={markers[markerName].provider + ' - ' + markers[markerName].name + '\n' + markers[markerName].filename} top={'-125px'} left={'0px'} width={'200px'}>
            <div className={`${(markers[markerName].provider === activeDocument.policyProvider &&
               activeDocument?.policyWording && markers[markerName].filename === activeDocument?.policyWording?.filename) ?
                'dynamic-slider-marker-key' : 
                displayCompare && compareDocument?.policyWording && markers[markerName].filename === compareDocument?.policyWording?.filename ?
                'dynamic-slider-marker-compare' : 'dynamic-slider-marker'
                }`}>              
              {formatNumber(markerLimit)}
            </div>
          </Tooltip>
          {offsetY === 0 && (
            <div className="dynamic-slider-marker-text-logo">{displayName}</div>
          )}
        </div>
      );
      markersArray.push(markerElement);
    });

    return markersArray;
  };

  return (
    <div className="dynamic-slider-container" ref={containerRef}>
      <div className="dynamic-slider-container-item">
        <div className="dynamic-slider-container-slider">
          <div className="dynamic-slider" />
          <div className="dynamic-slider-markers">
            {renderMarkers()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicSlider;
