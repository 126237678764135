import React, { useState, useEffect } from 'react';
import './BudgetComponent.css'

const BudgetComponent = ({ onChange }) => {
    const [budget, setBudget] = useState({
        amount: 100,
        period: 'weekly',
    });

    // Effect to auto-adjust period based on the amount
    // useEffect(() => {
    //     if (budget.amount > 500) {
    //         setBudget(prev => ({ ...prev, period: 'yearly' }));
    //     } else if (budget.amount > 400) {
    //         setBudget(prev => ({ ...prev, period: 'monthly' }));
    //     } else if (budget.amount > 200) {
    //         setBudget(prev => ({ ...prev, period: 'fortnightly' }));
    //     } else {
    //         setBudget(prev => ({ ...prev, period: 'weekly' }));
    //     }
    // }, [budget.amount]);

    // Handle change in amount
    const handleAmountChange = (e) => {
        const newAmount = parseInt(e.target.value, 10) || 0;
        setBudget(prev => ({ ...prev, amount: newAmount }));
    };

    // Handle slider change
    const handleSliderChange = (e) => {
        const newAmount = parseInt(e.target.value, 10);
        setBudget(prev => ({ ...prev, amount: newAmount }));
    };

    // Handle period toggle
    const handlePeriodChange = (newPeriod) => {
        setBudget(prev => ({ ...prev, period: newPeriod }));
    };

    // Share state with parent component
    useEffect(() => {
        onChange(budget);
    }, [budget, onChange]);

    // Function to format the number with commas
    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div className="budget-section">
            <div className="budget-component">
                <div className="budget-component-top">
                    <div className='budget-component-label' >
                        <span><strong>Total Insurance Budget</strong></span>
                        <input
                            type="number"
                            value={budget.amount}
                            className="budget-component-label-input"
                            onChange={handleAmountChange} />
                        <div className='budget-component-label-dollar'>$</div>
                    </div>
                    <input
                        className='budget-component-range-input'
                        type="range"
                        min="0"
                        max={`${budget.period === 'yearly' ? '10000' : '1000'}`}
                        value={budget.amount}
                        onChange={handleSliderChange}
                        style={{ backgroundSize: `${(budget.amount / (budget.period === 'yearly' ? 10000 : 1000)) * 100}% 100%` }}
                    />
                </div>
                <div className="period-toggle">
                    {['weekly', 'fortnightly', 'monthly', 'yearly'].map(period => (
                        <button
                            key={period}
                            className={`period-toggle-button ${budget.period === period ? 'period-toggle-button-selected' : ''}`}
                            onClick={() => handlePeriodChange(period)}
                        >
                            {period}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BudgetComponent;

