import React, { useContext } from 'react';
import { RefContext } from '../../context/RefContext';
import featureURL1 from "../../icons/feature_1.png";
import featureURL2 from "../../icons/feature_2.png";
import featureURL3 from "../../icons/feature_3.png";
import featureURL4 from "../../icons/feature_4.png";
import featureURL5 from "../../icons/feature_5.png";
import featureURL6 from "../../icons/feature_6.png";
import './ProductHighlightSummary.css';

// Reusable component for each grid item
const HighlightGridItem = ({ icon, label, description }) => {

    return (
        <div className='highlight-grid-item'>
            <img src={icon} alt={label} className='highlight-icon' />
            <div className='highlight-label'>{label}</div>
            <div className='highlight-description'>{description}</div>
        </div>
    );
};

// Component for displaying key features of the product in a dynamic grid layout
const ProductHighlightSummary = () => {
    const myTargetRef = useContext(RefContext);

    return (
        <div className="highlights-container-large" ref={myTargetRef}>
            {/* Left section for title and description */}
            <div className="highlights-container-left">
                <div className='highlight-summary-title'>
                    Use <span style={{ color: '#4fa658', fontWeight: 'bold' }}>AI</span> to get ahead
                </div>
                <div className='highlights-body-text'>
                    Our platform streamlines client understanding, policy comparison, and proposal generation, freeing up time to focus on building relationships and expert advice.
                </div>

                {/* Dynamic grid layout for key features */}
                <div className='highlights-grid'>
                    <HighlightGridItem
                        icon={featureURL1}
                        label="AI Prompts"
                        description="Which clients should I prioritise today and why?"
                    />
                    <HighlightGridItem
                        icon={featureURL2}
                        label="Effortless Comparisons"
                        description="Side-by-side policy comparisons with key differences highlighted."
                    />
                    <HighlightGridItem
                        icon={featureURL3}
                        label="Digital Experience"
                        description="Give your cients a digital experience."
                    />
                    <HighlightGridItem
                        icon={featureURL4}
                        label="Time Efficiency"
                        description="Spend less time on manual tasks & more time with clients."
                    />
                    <HighlightGridItem
                        icon={featureURL5}
                        label="Enhanced Compliance"
                        description="Maintain full regulatory compliance seamlessly throughout the process."
                    />
                    <HighlightGridItem
                        icon={featureURL6}
                        label="Client Satisfaction"
                        description="Deliver better service for higher client satisfaction and retention."
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductHighlightSummary;
