import React, { useState, useEffect } from 'react';
import AdviceHeader from '../AdviceHeader';
import Quotes from './Quotes';

const ProposalQuotes = ({ isOpen, toggleSection, proposal, updateProposal, pdf }) => {
    const id = 'quotes';
    const [completed, setCompleted] = useState(false);

    const initialData = Array(4).fill(null); // Default to one row of 6 items

    const [gridData, setGridData] = useState(initialData);

    const handleUpdate = (updatedData) => {
        setGridData(updatedData);
    };

    useEffect(() => {
        
        const isCompleted = gridData.some(data => data !== null);
        setCompleted(isCompleted);
    }, [gridData]);

    return (
        <div className="form-section no-page-break">
            <AdviceHeader
                id={id}
                toggleSection={toggleSection}
                title={'Quotes'}
                tooltip={'A clear definition of what the advice covers and any limitations. This might include the types of insurance products discussed and any specific requests or concerns addressed by the client.'}
                completed={completed}
                // pdf={pdf}
                isOpen={isOpen}
            />
            {isOpen && (
                <div>
                    <Quotes initialData={gridData} onUpdate={handleUpdate} pdf={pdf}/>
                </div>
            )}
        </div>
    );
};

export default ProposalQuotes;
