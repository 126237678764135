import React, { useEffect, useContext, useState } from 'react';
import { CountryContext } from '../context/CountryContext';
import { ProviderPolicyListContext } from '../context/ProviderPolicyListContext';
import { formatString,formatPolicyType } from '../utils/utils';
import './PolicyLibrary.css';

function PolicyLibrary() {
    const { providerPolicies } = useContext(ProviderPolicyListContext || {});
    const { country } = useContext(CountryContext);
    const [policiesCount, setPoliciesCount] = useState(0);
    const [numberOfProviders, setNumberOfProviders] = useState(0);
    const [tableData, setTableData] = useState({});
    const categories = ['home', 'motor', 'contents', 'landlord', 'travel', 'isr', 'home-contents'];

    useEffect(() => {
        let data = {};

        if (providerPolicies && Array.isArray(providerPolicies)) {
            providerPolicies.forEach(policy => {
                if (!data[policy.provider_name]) {
                    data[policy.provider_name] = {};
                }
                if (policy.country === country) {
                    if (!data[policy.provider_name][policy.category]) {
                        data[policy.provider_name][policy.category] = []; // Initialize as an empty array
                    }
                    if (categories.includes(policy.category)) {
                        data[policy.provider_name][policy.category].push(policy.name); // Add string to the list
                    }
                }
            });

            // Use Object.keys to iterate over data since data is an object
            Object.keys(data).forEach(provider_name => {
                let keepPolicy = Object.values(data[provider_name]).some(list => list.length > 0);
                if (!keepPolicy) {
                    delete data[provider_name];
                }
            });

            setTableData(data);
            setPoliciesCount(providerPolicies.filter(policy => policy.country === country).length);
            setNumberOfProviders(Object.keys(data).length);
        }

    }, [providerPolicies, country]);

    return (
        <div className="policy-library-page" >
            <h1>Policy library</h1>
            <h2>Policies supported across Australia and New Zealand</h2>
            <div className="policy-library-page-container" >
                <div className='policy-library-page-container-header' >
                    <div className='policy-library-title'>We've reviewed: <span style={{ fontWeight: 'bold' }}>{numberOfProviders}</span> providers across {country}, inclusive of <span style={{ fontWeight: 'bold' }}>{policiesCount}</span> policy wordings.</div>
                    {/* TODO */}
                    {/* <IpBasedLocation onCountryChange={handleCountrySelect} /> */}
                </div>

                <table className='policy-library-policy-table'>
                    <thead>
                        <tr>
                            <th>Provider / Product</th>
                            {categories.map(category => <th key={category}>{formatPolicyType(category)}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableData)
                            .sort()
                            .map(provider => (
                                <tr key={provider}>
                                    <td >{provider}</td>
                                    {categories.map(category => (
                                        <td key={category}>
                                            {tableData[provider] && tableData[provider][category] &&
                                                tableData[provider][category].map((item, index) => (
                                                    <div key={index} className={tableData[provider][category] ? "policy-library-included-box" : ""}>
                                                        <div>{formatString(item)}</div>
                                                    </div>
                                                ))
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PolicyLibrary;
