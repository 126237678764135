import React, { useState } from 'react';
import DateInput from '../DateInput';
import './Broker.css';

const Broker = ({ onClose }) => {
  const [contactMethod, setContactMethod] = useState('Email');
  const [bookingDate, setBookingDate] = useState(new Date());
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:30');
  const [location, setLocation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bookingConfirmed, setBookingConfirmed] = useState(false);

  const regionNames = [
    "Northland",
    "Auckland",
    "Waikato",
    "Bay of Plenty",
    "Gisborne",
    "Hawke's Bay",
    "Taranaki",
    "Manawatū-Whanganui",
    "Wellington",
    "Tasman",
    "Nelson",
    "Marlborough",
    "West Coast",
    "Canterbury",
    "Otago",
    "Southland"
  ];

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const convertedHour = hour % 12 || 12;

    return `${convertedHour}:${minutes} ${ampm}`;
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);

  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);

  };

  const handleBookClick = () => {
    setBookingConfirmed(!bookingConfirmed);

  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  return (
    <div className="broker-container">
      {!bookingConfirmed ? (
        <div className="broker-container-50">
          <div className="broker-container-row">
            <div className="broker-h1">
              Book a <span style={{ color: '#4fa658', fontWeight: 'bold' }}>free</span> broker
            </div>
            <div >
              <DateInput className="broker-contact-info-right" selectedDate={bookingDate} onDateChange={setBookingDate} />

            </div>
          </div>
          <div className="broker-container-row">
            <div style={{ display: 'flex' }}>
              <input
                className="broker-contact-date-and-time"
                type="time"
                value={startTime}
                onChange={handleStartTimeChange}
              />
              <div className="broker-h3">
                <div className="broker-h3-line-top" ></div>
                <div className="broker-h3-line-bottom"> </div>
              </div>
              <input
                className="broker-contact-date-and-time"
                type="time"
                value={endTime}
                onChange={handleEndTimeChange}
              />
            </div>
          </div>
          <div className="broker-container-row">
            <select className="broker-contact-info"
              value={contactMethod}
              onChange={(e) => setContactMethod(e.target.value)}>
              <option value="Email">Send me an email</option>
              <option value="Phone">Phone</option>
              <option value="Video">Video chat</option>
              <option value="Location">In person</option>
            </select>
          </div>
          {contactMethod === 'Location' && (
            <div className="broker-container-row">
              <select className="broker-contact-region-dropdown">
                {regionNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {contactMethod === 'Phone' && (
            <div className="broker-container-row">
              <input className="broker-contact-region-dropdown" type="text" placeholder="Your phone number"
              onChange={handlePhoneNumberChange} value={phoneNumber}/>
            </div>
          )}
          <button className="broker-button" onClick={handleBookClick}>Book</button>

        </div>
      ) : (
        <div className="booking-confirmation-message">
          <p>Great, we will send you an email with the details shortly: </p>
          <p>You've chosen <span className='booking-green'>{contactMethod}</span>, on <span className='booking-green'>{bookingDate.toDateString()}</span>, between <span className='booking-green'>{convertTo12HourFormat(startTime)}</span> and <span className='booking-green'>{convertTo12HourFormat(endTime)}</span>.</p>
          <button className="broker-button" onClick={() => onClose()}>Done</button>
        </div>
      )
      }
    </div >
  );
};

export default Broker;

