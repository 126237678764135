import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const DirectorsContext = createContext();

export const DirectorsProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial directors insurance state
  const initialDirectorsInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        protectDirectors: false,
        protectOfficers: false,
        safeguardCompany: false,
      },
      goals: {
        litigationDefense: false,
        indemnification: false,
        regulatoryCompliance: false,
      },
    },
    situation: {
      companyDetails: {
        companyName: '',
        industry: '',
        revenue: 0,
      },
      governanceRisks: {
        conflictsInterest: false,
        wrongfulActs: false,
        regulatoryInvestigations: false,
      },
    },
    modules: {
      options: {
        employmentPractices: false,
        fiduciaryLiability: false,
        entityCoverage: false,
        sideAExcess: false,
      }
    },
  };

  // Initialize the filters state with the directors insurance state
  const [filters, setFilters] = useState(initialDirectorsInsuranceState);

  return (
    <DirectorsContext.Provider value={{ filters, setFilters }}>
      {children}
    </DirectorsContext.Provider>
  );
};
