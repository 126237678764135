import React, { useState, useEffect, useContext } from "react";
import { CountryContext } from '../../../context/CountryContext';
import { fetchClients } from '../../../apis/ClientService';
import { useClient } from '../../../context/ClientContext';

const ClientDetails = ({ userContext }) => {
    const { country } = useContext(CountryContext);
    const [searchText, setSearchText] = useState('');
    const { activeClient, setActiveClientById } = useClient();
    const [filteredClients, setFilteredClients] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [clients, setClients] = useState([]);

    const fetchClientData = async () => {
        const data = await fetchClients(userContext.details.username, country);
        if (data.length > 0) {
            setClients(data);
        }
    };

    useEffect(() => {
        if (userContext.details) {
            const loadClientData = async () => {
                await fetchClientData();
            };

            loadClientData(); // Call the async function inside useEffect
        }
    }, [userContext.details]);

    useEffect(() => {
        if (searchText.length >= 1) {
            const filtered = clients.filter(client =>
                `${client.personalDetails.firstName} ${client.personalDetails.lastName}`
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            );
            setFilteredClients(filtered);
        } else {
            setFilteredClients(clients);
        }
    }, [searchText, clients]);

    useEffect(() => {
        if (activeClient) {
            setSearchText(`${activeClient?.personalDetails?.firstName} ${activeClient?.personalDetails?.lastName}`);
        }
    }, [activeClient]);

    const handleClientSelectChange = (selectedClient) => {
        setSearchText(`${selectedClient.personalDetails.firstName} ${selectedClient.personalDetails.lastName}`);
        setActiveClientById(selectedClient._id); // Set the new active client
        setShowDropdown(false);
    };

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length >= 1) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    return (
        <div style={{ position: 'relative', padding: '20px' }}>
            <input
                style={{ minWidth: '200px' }}
                type="text"
                placeholder="Search for existing client..."
                value={searchText}
                onChange={handleInputChange}
                onFocus={() => setShowDropdown(true)}
                onBlur={() => {
                    setTimeout(() => setShowDropdown(false), 100);
                }}
                className="policy-wording-select"
            />
            {showDropdown && (
                <div style={{
                    maxHeight: '40vh',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    position: 'absolute',
                    top: '50px',
                    backgroundColor: 'white',
                    width: '100%',
                    left: 0,
                    zIndex: 1000,
                }}>
                    {filteredClients.map(client => (
                        <div
                            key={client._id}
                            onMouseDown={() => handleClientSelectChange(client)}
                            style={{ padding: '8px', cursor: 'pointer' }}
                        >
                            {client.personalDetails.firstName} {client.personalDetails.lastName}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ClientDetails;
