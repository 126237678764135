import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import './MyMapComponent.css';

const containerStyle = {
    minWidth: '100%',
    minHeight: '600px',
};

const defaultCenter = { lat: -34.397, lng: 150.644 };

const MyMapComponent = ({ address, postcode }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS
    });
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [markerIcon, setMarkerIcon] = useState(null);

    const mapRef = useRef(null);
    const [zoomUpdated, setZoomUpdated] = useState(false);

    const onLoad = React.useCallback(function callback(map) {
        mapRef.current = map;
    }, []);

    const onIdle = () => {
        if (mapRef.current && !zoomUpdated) {
            mapRef.current.setZoom(mapRef.current.getZoom() + 1);
            setZoomUpdated(true);
        }
    };

    const mapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'none'
    };

    useEffect(() => {
        // Check that the postcode has at least 4 digits before proceeding
        if (isLoaded && (address || (postcode && postcode.length >= 4))) {
            setMarkerIcon({
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: "#53A658",
                fillOpacity: 1.0,
                strokeWeight: 2,
                strokeColor: "white"
            });

            const geocoder = new window.google.maps.Geocoder();
            const location = address || postcode;
            geocoder.geocode({ 'address': location }, (results, status) => {
                if (status === 'OK') {
                    setMapCenter({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.union(results[0].geometry.viewport);
                    mapRef.current.fitBounds(bounds);
                    setZoomUpdated(false);
                } else {
                    console.error('Geocode was not successful for the following reason:', status);
                }
            });
        }
    }, [address, postcode, isLoaded]);

    return isLoaded ? (
        <div className="map-parent">
            <GoogleMap
                mapContainerStyle={containerStyle}
                mapContainerClassName="map-container"
                center={mapCenter}
                zoom={10}
                onLoad={onLoad}
                onIdle={onIdle}
                options={mapOptions}
            >
                {markerIcon && <Marker position={mapCenter} icon={markerIcon} title={address || postcode} />}
            </GoogleMap>
        </div>
    ) : <div>Loading Map...</div>;
};

export default MyMapComponent;
