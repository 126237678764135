import React, { useState, useContext } from 'react';
import { RefContext } from './../context/RefContext';
import Login from "./../components/user_access/Login";
import Register from "./../components/user_access/Register";
import ForgotPassword from "./../components/user_access/ForgotPassword";
import LogoURL from "./../logo/logo_green.png";
import { useNavigate, useLocation } from 'react-router-dom';
import { usePopup } from './../context/PopupContext'; // Adjust the path as necessary

import './Header.css';

const Header = () => {
    const myTargetRef = useContext(RefContext);
    const navigate = useNavigate();
    const location = useLocation();

    const {
        isLogInPopupOpen,
        setIsLogInPopupOpen,
        isRegisterPopupOpen,
        setIsRegisterPopupOpen,
        isForgotPasswordPopupOpen,
        setIsForgotPasswordPopupOpen,
        isWaitListPopupOpen,
        setIsWaitListPopupOpen
    } = usePopup();

    const scrollToTarget = () => {
        if (location.pathname !== '/') {
            navigate('/'); // Navigate to home page if not already there
            setTimeout(() => {
                scrollToRef();
            }, 0); // Adjust the delay if needed
        } else {
            scrollToRef(); // Scroll immediately if already on the home page
        }
    };

    const scrollToRef = () => {
        if (myTargetRef.current) {
            myTargetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    const closeForgotPassword = () => {
        setIsForgotPasswordPopupOpen(false);  // Make sure this is correctly updating the state
    };

    return (
        <div className="header-bar">
            <div className="header-left">
                <div className="header-link-logo" onClick={() => handleNavigation('/')}>
                    <div className="company-name">
                        <img src={LogoURL} className="company-logo" alt="Logo" />
                        <span className="header-logo-text-green">POLICY</span>
                        <span className="header-logo-text-white">CHECK</span>
                    </div>
                </div>
            </div>
            <div className="header-middle">
                <div className="header-links">
                    <ul className="header-links-list">
                        <li>
                            <div className="header-menu-item">
                                <span className="header-menu-item-title">Solutions</span>
                                <ul className="header-dropdown">
                                    <li className="header-dropdown-option" onClick={scrollToTarget}>
                                        <span>Clients</span>
                                    </li>
                                    <li className="header-dropdown-option" onClick={scrollToTarget}>
                                        <span>Brokers</span>
                                    </li>
                                    <li className="header-dropdown-option" onClick={scrollToTarget}>
                                        <span>Insurers</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="header-menu-item">
                                <span className="header-menu-item-title">Resources</span>
                                <ul className="header-dropdown">
                                    {/* TODO */}
                                    {/* <li className="header-dropdown-option" onClick={() => handleNavigation('/policy-search')}> */}
                                    {/* <li className="header-dropdown-option" >
                                        <span>Policy Search</span>
                                    </li> */}
                                    <li className="header-dropdown-option" onClick={() => handleNavigation('/blog')}>
                                        <span>Blog</span>
                                    </li>
                                    <li className="header-dropdown-option" onClick={() => handleNavigation('/library')}>
                                        <span>Policy Library</span>
                                    </li>
                                    <li className="header-dropdown-option" onClick={() => handleNavigation('/faq')}>
                                        <span>FAQ</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <span className="header-menu-item-title" onClick={() => handleNavigation('/directory')}>Find a Broker</span>
                        </li>
                        <li>
                            <span className="header-menu-item-title" onClick={() => handleNavigation('/pricing')}>Pricing</span>
                        </li>
                        <li>
                            <div className="header-menu-item">
                                <span className="header-menu-item-title" onClick={() => handleNavigation('/our-story')}>About</span>
                                <ul className="header-dropdown">
                                    <li className="header-dropdown-option" onClick={() => handleNavigation('/our-story')}>
                                        <span>Our Story</span>
                                    </li>
                                    <li className="header-dropdown-option" onClick={() => handleNavigation('/our-story')}>
                                        <span>Our Team</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="header-right">
                <button className="sign-in-button" onClick={() => setIsLogInPopupOpen(true)} type="button">
                    Log in
                </button>
                <button className="my-account-button" onClick={() => setIsRegisterPopupOpen(true)} type="button">
                    Register
                </button>
                <Login isOpen={isLogInPopupOpen} 
                onClose={() => setIsLogInPopupOpen(false)} 
                resetStep={true} 
                forgotPassword={() => setIsForgotPasswordPopupOpen(true)} 
                />
                <ForgotPassword isOpen={isForgotPasswordPopupOpen} onClose={closeForgotPassword} resetStep={true} />

                <Register isOpen={isRegisterPopupOpen} onClose={() => setIsRegisterPopupOpen(false)} resetStep={true} />
            </div>
        </div>
    );
};

export default Header;
