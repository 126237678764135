import React from 'react';
import './StarRating.css';

const StarRating = ({ rating, onRating }) => {
    return (
        <div className="star-rating">
            {Array.from({ length: 5 }).map((_, index) => (
                <span
                    key={index}
                    className={index < rating ? 'gold-star' : 'grey-star'}
                    onClick={onRating ? () => onRating(index + 1) : undefined}
                >
                    ★
                </span>
            ))}
        </div>
    );
};

export default StarRating;
