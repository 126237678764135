import React from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';

const StackedBarChart = ({ data }) => {
    return (
        <div className="commissions-metric-card" style={{ maxWidth: '600px' }}>
            <h3>Commissions by Month</h3>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={data}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {/* Bar for Upfront with LabelList showing the total on top */}
                    <Bar dataKey="upfront" stackId="a" fill="#666" isAnimationActive={false}>
                        <LabelList dataKey="upfront" position="top" style={{ fill: '#fff', fontWeight: 'bold' }} />
                    </Bar>
                    {/* Bar for Trail */}
                    <Bar dataKey="trail" stackId="a" fill="rgb(79,166,88)" isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StackedBarChart;
