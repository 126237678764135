import React, { useEffect} from "react";
import { useGlobalContext } from '../../context/GlobalContext';
import PolicyScore from '../../components/PolicyScore';
import './Score.css';

function Score() {
  useEffect(() => {
      document.title = 'Score';
    }, []);
  const { activeDocument } = useGlobalContext();

  return (
    <div className="score-small-container">
      <h2>Coverage Score</h2>
      <h3>{activeDocument !== null && activeDocument.policyName !== undefined ? activeDocument.policyName : 'To start, upload a policy on the left'}</h3>

      {activeDocument !== null && activeDocument.policyName !== undefined  ?
        <PolicyScore /> : null
      }
    </div>

  );
}

export default Score;
