import React, { useEffect} from "react";
import './Retention.css';
import RecentLeads from '../../components/leads/RecentLeads';
import ClientSegmentationChart from '../../components/retention/ClientSegmentationChart'
import ClientChurnReasonsChart from '../../components/retention/ClientChurnReasonsChart';
import ClientSatisfactionScoresChart from '../../components/retention/ClientSatisfactionScoresChart';
import UpcomingReviews from '../../components/retention/UpcomingReviews';
import EngagementMetricsChart from '../../components/retention/EngagementMetricsChart';

const Retention = () => {
  useEffect(() => {
      document.title = 'Retention';
    }, []);

  const segmentationData = [
    { label: 'Low Risk', value: 40 },
    { label: 'Medium Risk', value: 30 },
    { label: 'High Risk', value: 20 },
    // ... more segments ...
  ];

  const churnData = [
    { reason: 'Poor Service', value: 35 },
    { reason: 'High Fees', value: 25 },
    { reason: 'Better Options Elsewhere', value: 15 },
    // ... more reasons ...
  ];

  const satisfactionData = [
    { category: 'Service Quality', score: 8.2 },
    { category: 'Response Time', score: 7.5 },
    { category: 'Overall Experience', score: 8.5 },
    // ... more categories ...
  ];

  const reviewDates = [
    { clientName: 'John Doe', date: new Date(2024, 3, 25) },
    { clientName: 'Jane Smith', date: new Date(2024, 4, 3) },
    // ... more review dates ...
  ];

  const engagementData = [
    { metric: 'Calls', value: 120 },
    { metric: 'Emails', value: 95 },
    { metric: 'Meetings', value: 50 },
    // ... more metrics ...
  ];

  return (
    <div className="lead-container">
      <h2>Customer Retentiton</h2>
      <div className="lead-dashboard">
        <div className="grid">
          <div className="grid-item">
            <div className="grid-item-title">Segmentation</div>
            <ClientSegmentationChart segmentationData={segmentationData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Churn</div>
            <ClientChurnReasonsChart churnData={churnData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Satisfaction</div>
            <ClientSatisfactionScoresChart satisfactionData={satisfactionData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Engagement</div>
            <EngagementMetricsChart engagementData={engagementData} />
          </div>
        </div>
        <div className="lead-container-table">
          <div className="grid-item-title">Upcoming policy expirations</div>
          <UpcomingReviews reviewDates={reviewDates} />
        </div>
      </div>
    </div>
  );
};

export default Retention;