import React, { useEffect, useContext, useState, useRef } from 'react';
import { useGlobalContext } from '../context/GlobalContext';
import { PolicyTypeContext } from '../context/PolicyTypeContext';
import ToolTabletip from './ToolTabletip';
import InfoCircle from "./InfoCircle";
import Tooltip from './Tooltip';
import SearchIconURL from "../icons/search-grey.png";
import { MasterPoliciesContext } from '../context/MasterPoliciesContext';
import { FiltersContext } from '../context/FiltersContext';
import DisplayCheckedInfo from './DisplayCheckedInfo';
import ShowPolicy from './compare/ShowPolicy';
import ShowPolicyNew from './compare/ShowPolicyNew';
import ComparePolicies from './compare/ComparePolicies';
import ComparePoliciesNew from './compare/ComparePoliciesNew';
import './PoliciesTable.css';

const PoliciesTable = () => {
  const contentRef = useRef(null);
  const stickyHeaderRef = useRef(null);
  const scrollMirrorRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedPolicies, setSelectedPolicies] = useState({ first: null, second: null });
  const [showCompare, setShowCompare] = useState(false);
  const [showCompareNew, setShowCompareNew] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showPolicyNew, setShowPolicyNew] = useState(false);

  const { policyType } = useContext(PolicyTypeContext);
  const { masterPolicies, setMasterPolicies } = useContext(MasterPoliciesContext);
  const [filteredPolicies, setFilteredPolicies] = useState(masterPolicies || []);
  const [showAdvancedRow, setShowAdvancedRow] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [coverageTypes, setCoverageTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const tableRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const dropdownRef = useRef(null);
  const { activeDocument } = useGlobalContext();
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const [activeConditions, setActiveConditions] = useState(null);
  const [activeCell, setActiveCell] = useState(null);
  const { globalFilters } = useContext(FiltersContext);

  useEffect(() => {
    if (masterPolicies && Array.isArray(masterPolicies)) {
      const filtered = masterPolicies.filter(policy => policy.type === policyType);
      setFilteredPolicies(filtered);
    }
  }, [masterPolicies, policyType]);

  const handleRightClick = (e, policy) => {
    e.preventDefault();
    e.stopPropagation();

    if (policy) {
      const xPos = `${e.pageX}px`;
      const yPos = `${e.pageY}px`;

      setContextMenu({
        policy,
        xPos,
        yPos,
      });

      console.log('Context menu event fired:', { xPos, yPos, policy });
    } else {
      console.warn('No policy found to open context menu');
    }
  };

  // Function to close the context menu
  const closeContextMenu = () => {
    setTimeout(() => setContextMenu(null), 500); // Delay to see if it's closing too fast
  };

  // Add a click event listener to detect clicks outside of the context menu
  useEffect(() => {
    console.log('Context menu event fired:', contextMenu);
    const handleClickOutside = (event) => {
      console.log("Event target:", event.target); // Log the target of every click
      if (contextMenu &&
        !event.target.closest('.policy-table-context-menu') &&
        !event.target.closest('.cell-yes-included-only') &&
        !event.target.closest('.introjs-button')) { // Exclude IntroJs buttons from closing the context menu
        console.log("Closing context menu");
        closeContextMenu();
      }
    };

    // Attach the event listener when the context menu is open
    if (contextMenu) {
      console.log('Attach the event listener when the context menu is open', contextMenu);
      document.addEventListener('click', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or context menu closes
    return () => {
      console.log('Cleanup the event listener when the component unmounts or context menu closes:', contextMenu);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu]);

  const closeOverlay = () => {
    setContextMenu(null);
    setShowCompare(false);
    setShowCompareNew(false);
    setShowPolicy(false);
    setShowPolicyNew(false);
    setSelectedPolicies({ first: null, second: null });
  };

  const handleSelectForCompare = (policy) => {
    if (policy) {
      setSelectedPolicies({ ...selectedPolicies, first: policy });
      console.log("setSelectedPolicies:", selectedPolicies?.first?.name);
      setContextMenu(null);
    } else {
      console.warn('No policy to select for comparison.');
    }
  };

  const handleShowPolicy = (policy) => {
    if (policy) {
      setShowPolicy(true);
    }
  };

  const handleShowPolicyNew = (policy) => {
    if (policy) {
      setShowPolicyNew(true);
    }
  };

  const handleCompareWithSelected = (policy) => {
    if (selectedPolicies.first && policy) {
      console.log("handleCompareWithSelected:", selectedPolicies?.first?.name, " with: ", policy?.name);
      setSelectedPolicies({ ...selectedPolicies, second: policy });
      setShowCompare(true);
      setContextMenu(null);
    } else {
      console.warn('Cannot compare: either first selected policy or second policy is missing.');
    }
  };

  const handleCompareWithNewSelected = (policy) => {
    if (selectedPolicies.first && policy) {
      console.log("handleCompareWithSelected:", selectedPolicies?.first?.name, " with: ", policy?.name);
      setSelectedPolicies({ ...selectedPolicies, second: policy });
      setShowCompareNew(true);
      setContextMenu(null);
    } else {
      console.warn('Cannot compare: either first selected policy or second policy is missing.');
    }
  };

  useEffect(() => {
    setExpandedRows([]);
  }, [policyType]);

  useEffect(() => {
    setCoverageTypes(filteredPolicies && Array.isArray(filteredPolicies)
      ? Array.from(new Set(filteredPolicies.flatMap(policy => Object.keys(policy.coverages || {}))))
      : []);
  }, [filteredPolicies]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const toggleAdvancedRow = () => {
    setShowAdvancedRow(!showAdvancedRow);
  };

  function refreshCoverageTypes() {
    setCoverageTypes(filteredPolicies && Array.isArray(filteredPolicies)
      ? Array.from(new Set(filteredPolicies.flatMap(policy => Object.keys(policy.coverages || {}))))
      : []);
  };

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      const tableWidth = table.offsetWidth;
      const contentWidth = table.scrollWidth;
      setIsOverflowing(contentWidth > tableWidth);
    }
  }, [tableRef, filteredPolicies, expandedRows]);

  function findMatchingKey(policies, searchString) {
    let matchingKeys = [];

    const searchInObject = (obj, topKey) => {
      if (!obj || typeof obj !== 'object') return;
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object') {
          // If the key matches the search string
          if (key.toLowerCase().includes(searchString.toLowerCase())) {
            if (topKey) {
              matchingKeys.push(topKey);
            }
          }
          // If topKey is not set, set it to the current key
          const currentTopKey = topKey || key;
          searchInObject(obj[key], currentTopKey);
        }
      });
    };

    policies.forEach(policy => {
      if (policy.coverages) {
        searchInObject(policy.coverages, null);
      }
    });

    return Array.from(new Set(matchingKeys)); // Remove duplicates if any
  }


  // Function to render coverage value
  const renderCoverage = (coverage, name) => {
    let cellContent = null;
    let cellClass = '';

    const renderRecursiveCoverage = (subCoverage) => {
      let included = false;
      let excluded = false;
      let limit = false;
      let excess = false;
      let optional = false;
      let conditions = false;

      // Recursive traversal of the coverage object
      const traverseCoverage = (coverageObject) => {
        for (const key in coverageObject) {
          const currentItem = coverageObject[key];

          if (typeof currentItem === 'object') {
            // Recursive call to handle nested objects
            traverseCoverage(currentItem);
          } else {
            // Check for different properties
            if (key === 'excess' && currentItem && currentItem !== "0") {
              excess = true;
            }
            if (key === 'limit' && currentItem && currentItem !== "0") {
              limit = true;
            }
            if (key === 'conditions' && currentItem && currentItem.length > 1) {
              conditions = true;
            }
            if (key === 'included' && currentItem === true) {
              included = true;
            }
            if (key === 'included' && currentItem === false) {
              excluded = true;
            }
            if (key === 'optional' && currentItem === true) {
              optional = true;
            }
          }
        }
      };

      // Start traversal
      traverseCoverage(subCoverage);

      // Generate UI content based on coverage
      cellContent = (
        <div className="coverage-indicator">
          <div className="circles">
            {included ? <div className='policy-circle circle-yes'>&#10003;</div> : null}
            {excluded ? <div className='policy-circle circle-no'>X</div> : null}
            {limit ? <div className='policy-circle circle-limit'>L</div> : null}
            {excess ? <div className='policy-circle circle-excess'>E</div> : null}
            {conditions ? <div className='policy-circle circle-conditions'>C</div> : null}
            {optional ? <div className='policy-circle circle-optional'>O</div> : null}
          </div>
        </div>
      );
      cellClass = 'cell-yes';

      return { content: cellContent, className: cellClass };
    };

    return renderRecursiveCoverage(coverage[name]);
  };


  function formatCurrency(s) {
    // Check if 's' includes two '$' characters
    if ((s.match(/\$/g) || []).length === 2) {
      return 'Check policy';
    }

    try {
      // Convert string to float
      const number = parseFloat(s);
      // Check if the number is a valid number
      if (isNaN(number)) {
        // Return original string if not a valid number
        return s;
      }
      // Format as currency
      return `$${number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    } catch (error) {
      // If the input is not a valid number, return an error message
      return s;
    }
  }

  const formatValue = (value) => {
    return value && value.match ? formatCurrency(value) : '';
  };

  // Function to render sub coverage value
  const renderSubCoverage = (coverage, cellIdentifier) => {
    let cellContent = null;
    let cellClass = '';

    const renderNestedSubCoverage = (subCoverage) => {
      if (!subCoverage.included) {
        cellContent = (
          <div className="">
            <div>
            </div>
          </div>
        );
        cellClass = '';
      }
      // if (typeof subCoverage === 'object') {
      //   return Object.keys(subCoverage).map(subKey => renderNestedSubCoverage(subCoverage[subKey]));
      // }
      else if (subCoverage) {
        if (subCoverage.excess && subCoverage.limit && subCoverage.excess !== "" && subCoverage.excess !== "0" && subCoverage.limit !== "" && subCoverage.limit !== "0") {
          cellContent = (
            <div className="cell-yes-included-box">
              {(subCoverage.conditions !== undefined && subCoverage.conditions !== null && subCoverage.conditions !== "") && (
                <div
                  className="policy-conditional-circle"
                  onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}
                >
                  c
                  {activeConditions === cellIdentifier && (
                    <div className="policy-table-conditions"
                      onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}>
                      {subCoverage.conditions}
                    </div>
                  )}
                </div>
              )}
              <div className="sub-coverage-indicator-excess">
                Excess: <span style={{ fontSize: `${formatValue(subCoverage.excess).length > 11 ? '6pt' : 'initial'}` }}>{formatValue(subCoverage.excess)}</span>
              </div>
              <div className="sub-coverage-indicator-limit">
                Limit: <span style={{ fontSize: `${formatValue(subCoverage.limit).length > 11 ? '6pt' : 'initial'}` }}>{formatValue(subCoverage.limit)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (subCoverage.excess && subCoverage.excess !== "" && subCoverage.excess !== "0" && subCoverage.excess !== "$0") {
          cellContent = (
            <div className="cell-yes-included-box">

              {(subCoverage.conditions !== undefined && subCoverage.conditions !== null && subCoverage.conditions !== "") && (
                <div
                  className="policy-conditional-circle"
                  onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}
                >
                  c
                  {activeConditions === cellIdentifier && (
                    <div className="policy-table-conditions"
                      onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}>
                      {subCoverage.conditions}
                    </div>
                  )}
                </div>
              )}
              <div className="sub-coverage-indicator-excess">
                Excess: <span style={{ fontSize: `${formatValue(subCoverage.excess).length > 11 ? '6pt' : 'initial'}` }}>{formatValue(subCoverage.excess)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (subCoverage.limit && subCoverage.limit !== "" && subCoverage.limit !== "0" && subCoverage.limit !== "$0") {
          cellContent = (
            <div className="cell-yes-included-box">
              {(subCoverage.conditions !== undefined && subCoverage.conditions !== null && subCoverage.conditions !== "") && (
                <div
                  className="policy-conditional-circle"
                  onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}
                >
                  c
                  {activeConditions === cellIdentifier && (
                    <div className="policy-table-conditions"
                      onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}>
                      {subCoverage.conditions}
                    </div>
                  )}
                </div>
              )}
              <div className="sub-coverage-indicator-limit">
                Limit: <span style={{ fontSize: `${formatValue(subCoverage.limit).length > 11 ? '6pt' : 'initial'}` }}>{formatValue(subCoverage.limit)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (subCoverage.included === true) {
          cellContent = (
            <div className="cell-yes-included-box">

              {(subCoverage.conditions !== undefined && subCoverage.conditions !== null && subCoverage.conditions !== "") && (
                <div
                  className="policy-conditional-circle"
                  onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}
                >
                  c
                  {activeConditions === cellIdentifier && (
                    <div className="policy-table-conditions"
                      onClick={() => setActiveConditions(activeConditions === cellIdentifier ? null : cellIdentifier)}>
                      {subCoverage.conditions}
                    </div>
                  )}
                </div>
              )}
              <div className="included-box">&#10003;</div>
            </div>
          );
          cellClass = 'cell-yes-included-only';
        } else if (subCoverage.optional === true) {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="sub-coverage-indicator-optional">Optional</div>
            </div>
          );
          cellClass = 'cell-yes-included-only';
        } else {
          cellContent = (
            <div className="cell-yes-included-box">
              <div>&times;
              </div>
            </div>
          );
          cellClass = 'cell-no';
        }
      }


      return { content: cellContent, className: cellClass };
    };
    return renderNestedSubCoverage(coverage);
  };

  function formatCoverageName(coverageName) {
    // Add a space before any uppercase letters that follow a lowercase letter
    let withSpaces = coverageName.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
    withSpaces = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

    // Correct the capitalization for specific words
    return withSpaces.split(' ').map(word => {
      const lowerWord = word.toLowerCase();
      return (lowerWord === 'and' || lowerWord === 'or') ? lowerWord : word;
    }).join(' ');
  }

  function removeInsuranceFromName(policyName) {
    return policyName.replace(/Insurance/gi, '').trim();
  }


  // Handle row clicks to expand or collapse
  const handleRowClick = (coverageType) => {
    setActiveRowIndex(coverageType);
    setClickedRow(coverageType === clickedRow ? null : coverageType);

    // Toggle the row's expanded state
    if (expandedRows.includes(coverageType)) {
      // Collapse the row if it is already expanded
      setExpandedRows(expandedRows.filter(row => row !== coverageType));
    } else {
      // Expand the row by adding it to the expandedRows array
      setExpandedRows([...expandedRows, coverageType]);
    }
  };


  function reorderPoliciesForActiveDocument(policies, activeDocument) {
    if (!activeDocument || !policies || policies.length === 0) {
      return policies; // Return original array if activeDocument is not defined or policies array is empty
    }

    // Find the index of the activeDocument
    const activeIndex = policies.findIndex(policy => policy.name === activeDocument.policyProvider);
    // If activeDocument is not found or already the first item, return the original array
    if (activeIndex === -1 || activeIndex === 0) {
      return policies;
    }

    // Create a new array with the activeDocument as the first item
    const reorderedPolicies = [...policies];
    const [activePolicy] = reorderedPolicies.splice(activeIndex, 1); // Remove the activePolicy
    reorderedPolicies.unshift(activePolicy); // Insert it at the beginning

    //TODO: setPolicies(reorderedPolicies);
  }

  useEffect(() => {
    if (activeDocument) {
      reorderPoliciesForActiveDocument(filteredPolicies, activeDocument);
    }
  }, [filteredPolicies, activeDocument]);

  useEffect(() => {
    const classifyCells = () => {
      const table = tableRef.current;
      if (table) {
        const cells = table.getElementsByTagName('td');
        Array.from(cells).forEach(cell => {
          const text = cell.textContent.trim();
          if (text === 'Yes') {
            cell.classList.add('cell-yes');
          } else if (text === 'No') {
            cell.classList.add('cell-no');
          } else if (text.includes('Yes') || text.includes('No')) {
            cell.classList.add('cell-yes');
          }
        });
      }
    };

    classifyCells();
  }, [filteredPolicies]); // Re-run when policies change


  const isPolicyIncluded = (policyName) => {
    // Find the corresponding filter for the policy
    const filter = globalFilters.find(f => f.label === policyName);
    // Return true if the filter is found and it's checked, otherwise false
    return filter ? filter.checked : false;
  };

  function formatDate(dateStr) {
    const options = { year: '2-digit', month: 'short', day: 'numeric' };
    const date = new Date(dateStr);

    return date.toLocaleDateString('en-EN', options).replace(/(\d+)(st|nd|rd|th)/, '$1');
  }

  const renderedPoliciesHeader = filteredPolicies && Array.isArray(filteredPolicies)
    ? filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => (
      <th
        className="table-rotate insurance-table-th-policy-name" key={`header-${policy.fileName}-${policy.type}-${policy.name}-${policy.provider}`}>
        <ToolTabletip htmlContent={`<span><strong>Policy:</strong>${policy.fileName}</span><br/>
            <span><strong>Effective:</strong> ${formatDate(policy.effectiveDate)}</span><br/>
            <span><strong>Last Reviewed:</strong> ${formatDate(policy.lastReviewedDate)}</span>
          `}>
          <div className="table-rotate-justify">
            {removeInsuranceFromName(policy.provider)}
            <br />
            <span className="insurance-table-th-policy-subname" style={{ fontWeight: 'normal' }}>
              {policy.provider !== policy.name ? removeInsuranceFromName(policy.name) : ''}
            </span>
          </div>
        </ToolTabletip>
      </th>
    ))
    : null;

  const renderedAdvancedHeader = filteredPolicies && Array.isArray(filteredPolicies)
    ? filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => (
      <th className="insurance-table-th-advanced" key={`advanced-${policy.fileName}`}>
        <select className="insurance-table-custom-select" defaultValue="22.04.22 - 22.04.23">
          <option key={1} value="22.04.22 - 22.04.23">22.04.22 - 22.04.23</option>
          <option key={2} value="22.04.22 - 22.04.23">22.04.21 - 22.04.22</option>
          <option key={3} value="22.04.22 - 22.04.23">22.04.20 - 22.04.21</option>
          <option key={4} value="22.04.22 - 22.04.23">22.04.19 - 22.04.20</option>
          {/* {filteredPolicies.map((p, index) => (
            <option key={index} value={p.fileName}>{p.fileName}</option>
          ))} */}
        </select>
      </th>
    ))
    : null;

  const handleKeyDown = (e) => {
    refreshCoverageTypes();
    setSearchQuery(e.target.value); // This schedules the state update

  };

  const filterCoverageTable = (e) => {
    const matchingKeys = findMatchingKey(filteredPolicies, searchQuery);
    if (matchingKeys.length >= 1) {
      setCoverageTypes(matchingKeys)
    }
    setExpandedRows([...expandedRows, matchingKeys[0]]);

  };

  function handleOutsideClick(event) {
    // Check if the click is outside the policy-table-conditions div
    if (activeCell && !event.target.closest('.policy-table-conditions')) {
      setActiveCell(null);
      setActiveConditions(null);
    }
  }

  useEffect(() => {
    if (activeCell) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    // Clean up the event listener when the component is unmounted
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [activeCell]);


  useEffect(() => {
    const handleScroll = () => {
      const content = contentRef.current;
      const scrollMirror = scrollMirrorRef.current;
      if (content && scrollMirror) {
        const ratio = content.scrollWidth / content.clientWidth;
        scrollMirror.scrollLeft = content.scrollLeft / ratio;
      }
    };

    const content = contentRef.current;
    if (content) {
      content.addEventListener('scroll', handleScroll);
      return () => content.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleMirrorScroll = (e) => {
    const content = contentRef.current;
    const scrollMirror = scrollMirrorRef.current;
    if (content && scrollMirror) {
      const ratio = content.scrollWidth / content.clientWidth;
      content.scrollLeft = scrollMirror.scrollLeft * ratio;
    }
  };

  useEffect(() => {
    if (stickyHeaderRef.current) {
      const rect = stickyHeaderRef.current.getBoundingClientRect();
      setScrollWidth(rect.width);

    }
  }, [filteredPolicies, coverageTypes]);

  return (
    <div style={{ width: '100%' }}>
      {contextMenu && contextMenu.policy && (
        <div className="policy-table-context-menu" style={{ top: contextMenu.yPos, left: contextMenu.xPos }}>
          {/* Show Policy button is always enabled */}
          <button
            className="menu-button show-policy-btn"
            onClick={() => handleShowPolicy(contextMenu.policy)} // Pass selected policy
          >
            Show Policy
          </button>
          <button
            className="menu-button show-policy-btn"
            onClick={() => handleShowPolicyNew(contextMenu.policy)} // Pass selected policy
          >
            Show Policy Details
          </button>

          {/* Select for Compare button */}
          <button
            className="menu-button select-compare-btn"
            onClick={() => handleSelectForCompare(contextMenu.policy)}
          >
            Select for Compare
          </button>

          {/* Compare with Selected button */}
          <button
            className="menu-button compare-with-selected-btn"
            onClick={() => handleCompareWithSelected(contextMenu.policy)}
            disabled={!selectedPolicies.first || selectedPolicies.first.fileName === contextMenu.policy.fileName} // Disable if no policy is selected for comparison or if comparing the same policy
          >
            Compare with Selected
          </button>
          <button
            className="menu-button compare-with-selected-btn"
            onClick={() => handleCompareWithNewSelected(contextMenu.policy)}
            disabled={!selectedPolicies.first || selectedPolicies.first.fileName === contextMenu.policy.fileName} // Disable if no policy is selected for comparison or if comparing the same policy
          >
            Compare with Selected New
          </button>
        </div>
      )}

      <div className="policy-header">
        <div className="policy-table-legend">
          <div className="policy-table-header-inputs">
            <img src={SearchIconURL} alt="" style={{ width: '20px', height: '20px' }} />
            <input type="text" className="policy-table-header-search"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleKeyDown} />
            <button className="policy-table-filter-providers" onClick={filterCoverageTable}>
              Filter
            </button>
          </div>
        </div>
        <div className="policy-table-legend policy-table-legend-grow">
          <div className="policy-table-legend-header">
            Legend
          </div>
          <div className="policy-table-legend-body">
            <Tooltip text="Included indicates this item is explicitly included in your policy wording. Conditions, excess and limits may apply.">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-yes'>&#10003;</div>
                <div className="policy-table-legend-body-item-description">Included</div>
              </div>
            </Tooltip>
            <Tooltip text="Excluded indicates this item is either explicitly excluded or not mentioned.">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-no' >X</div>
                <div className="policy-table-legend-body-item-description">Excluded</div>
              </div>
            </Tooltip>
            <Tooltip text="Limit indicates this item has a specific financial limit or maximum claim amount.">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-limit' >L</div>
                <div className="policy-table-legend-body-item-description">Limit</div>
              </div>
            </Tooltip>
            <Tooltip text="Excess indicates this item comes with a financial execss payment.">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-excess' >E</div>
                <div className="policy-table-legend-body-item-description">Excess</div>
              </div>
            </Tooltip>
            <Tooltip text="Conditon indicates this item may be a key condition. Not all conditions are shown.">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-conditions'>C</div>
                <div className="policy-table-legend-body-item-description">Key conditions</div>
              </div>
            </Tooltip>
            <Tooltip text="Optional indicates an item which may or may not be included. Usually an additional fee is required. Usually needs to be specifically listed in the schedule. ">
              <div className="policy-table-legend-body-item">
                <div className='policy-circle circle-optional'>O</div>
                <div className="policy-table-legend-body-item-description">Optional</div>
              </div>
            </Tooltip>
            {/* <InfoCircle width="200px" message="Use 'Settings' to save your filter preferences and upload missing policies" /> */}
          </div>
        </div>
      </div>
      <div>
        <div
          ref={scrollMirrorRef}
          onScroll={handleMirrorScroll}
          className="insurance-table-visible-scroll"
          style={{
            overflowX: 'auto',
            maxWidth: '100%',
          }}
        >
          <div className="insurance-table-visible-scroll"
            style={{ width: scrollWidth }}>
          </div>
        </div>
        <div className="insurance-table-container"
          ref={contentRef}
        >
          <table ref={tableRef} className={`insurance-table ${isOverflowing ? 'overflowing' : 'not-overflowing'}`}>
            <thead className="sticky-header" ref={stickyHeaderRef}>
              {showAdvancedRow && (
                <tr className="insurance-table-th-advanced">
                  <th className="insurance-table-th-advanced"> </th>
                  {renderedAdvancedHeader}
                </tr>
              )}
              <tr className="sticky-header-bottom">
                <th className="insurance-table-th-policy-name"> </th>
                {renderedPoliciesHeader}
              </tr>
            </thead>
            <tbody id="myTableBody" style={{ width: '100%', overflowX: 'scroll' }}>
              {coverageTypes.map((coverageType, index) => (
                <React.Fragment key={`A-coverageType-${index}-${coverageType}`}>
                  <tr
                    onClick={() => handleRowClick(coverageType)}
                    className={`${expandedRows.includes(coverageType) ? 'policy-table-row-active' : 'policy-table-row'}`}
                  >
                    <td>{formatCoverageName(coverageType)}</td>
                    {filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => {
                      const coverage = policy.coverages ? policy.coverages[coverageType] : null;
                      const { content, className } = coverage ? renderCoverage(policy.coverages, coverageType) : { content: '', className: 'cell-no' };
                      return (
                        <td onContextMenu={(e) => handleRightClick(e, policy)}
                          key={`B-${policy.number}-${policy.name}-${policy.fileName}-${coverageType}`} className={className}>
                          {content}
                        </td>
                      );
                    })}
                  </tr>

                  {/* Show nested rows if the row is expanded */}
                  {expandedRows.includes(coverageType) && filteredPolicies
                    .filter(policy => isPolicyIncluded(policy.provider))
                    .some(policy => typeof policy.coverages[coverageType] === 'object') && (
                      Object.entries(
                        filteredPolicies
                          .filter(policy => isPolicyIncluded(policy.provider))
                          .find(policy => typeof policy.coverages[coverageType] === 'object').coverages[coverageType]
                      ).map(([subDisplayName, subValue], subIndex) => {
                        const uniqueKey = `C-sub-${coverageType}-${subDisplayName}-${subIndex}`;
                        return (
                          <React.Fragment key={uniqueKey}>
                            <tr
                              onClick={() => handleRowClick(subDisplayName)}
                              // className={`${expandedRows.includes(subDisplayName) ? 'policy-table-row-active' : 'policy-table-row'}`}
                              className={'policy-table-row'}
                            >
                              <td className={`policy-table-padded policy-table-sub-coverage-left-padding`}>{formatCoverageName(subDisplayName)}
                                {/* <div className="coverage-table-description">
                                  Can include: {subValue.description !== undefined ? subValue.description : ''}
                                </div> */}
                              </td>
                              {filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => {
                                const subCoverage = policy.coverages[coverageType] && policy.coverages[coverageType][subDisplayName];
                                const cellIdentifier = `${policy.fileName}-${subDisplayName}-${subIndex}`;
                                const { content, className } = subCoverage?.included ?
                                  renderSubCoverage(subCoverage, cellIdentifier) : subCoverage ? renderCoverage(policy.coverages[coverageType][subDisplayName], coverageType) : { content: '×', className: 'cell-no' };

                                return (
                                  <td onContextMenu={(e) => handleRightClick(e, policy)}
                                    key={`D-${cellIdentifier}`} className={className} onDoubleClick={() => setActiveCell(activeCell === cellIdentifier ? null : cellIdentifier)}>
                                    <div className="policy-conditional-container">
                                      {content}
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>

                            {/* Recursively render third-level rows */}
                            {expandedRows.includes(subDisplayName) && subValue && !subValue.included && typeof subValue === 'object' && (
                              Object.entries(subValue).map(([nestedDisplayName, nestedValue], nestedIndex) => {
                                const nestedKey = `D-nested-${subDisplayName}-${nestedDisplayName}-${nestedIndex}`;

                                return (
                                  <React.Fragment key={nestedKey}>
                                    <tr
                                      onClick={() => handleRowClick(nestedDisplayName)}
                                      // className={expandedRows.includes(nestedDisplayName) ? 'policy-table-row-active' : 'policy-table-row'}
                                      className={'policy-table-row'}
                                    >
                                      <td className="policy-table-padded-2 policy-table-sub-coverage-left-padding">{formatCoverageName(nestedDisplayName)}
                                        {/* <div className="coverage-table-description">
                                          Can include: {nestedValue.description !== undefined ? nestedValue.description : ''}
                                        </div> */}
                                      </td>
                                      {filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => {
                                        const thirdLevelCoverage = policy.coverages[coverageType] && policy.coverages[coverageType][subDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName];
                                        const thirdCellIdentifier = `${policy.fileName}-${nestedDisplayName}-${nestedIndex}`;
                                        const { content, className } = thirdLevelCoverage ? renderSubCoverage(thirdLevelCoverage, thirdCellIdentifier) : { content: '×', className: 'cell-no' };
                                        return (
                                          <td onContextMenu={(e) => handleRightClick(e, policy)}
                                            key={`E-${thirdCellIdentifier}`} className={className}>
                                            <div className="policy-conditional-container">
                                              {content}
                                            </div>
                                          </td>
                                        );
                                      })}
                                    </tr>

                                    {/* Recursively render fourth-level nested rows */}
                                    {expandedRows.includes(nestedDisplayName) && nestedValue && !nestedValue.included && typeof nestedValue === 'object' && (
                                      Object.entries(nestedValue).map(([deeperDisplayName, deeperValue], deeperIndex) => {
                                        const deeperKey = `E-deep-${deeperDisplayName}-${deeperIndex}`;
                                        return (
                                          <React.Fragment key={deeperKey}>
                                            <tr
                                              onClick={() => handleRowClick(deeperDisplayName)}
                                              // className={expandedRows.includes(deeperDisplayName) ? 'policy-table-row-active' : 'policy-table-row'}
                                              className={'policy-table-row'}
                                            >
                                              <td className="policy-table-padded-3 policy-table-sub-coverage-left-padding">{formatCoverageName(deeperDisplayName)}</td>
                                              {filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => {
                                                const fourthLevelCoverage = policy.coverages[coverageType] && policy.coverages[coverageType][subDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName][deeperDisplayName];
                                                const fourthCellIdentifier = `${policy.fileName}-${deeperDisplayName}-${deeperIndex}`;
                                                const { content, className } = fourthLevelCoverage ? renderSubCoverage(fourthLevelCoverage, fourthCellIdentifier) : { content: '×', className: 'cell-no' };
                                                return (
                                                  <td onContextMenu={(e) => handleRightClick(e, policy)}
                                                    key={`F-${fourthCellIdentifier}`} className={className}>
                                                    <div className="policy-conditional-container">
                                                      {content}
                                                    </div>
                                                  </td>
                                                );
                                              })}
                                            </tr>

                                            {/* Recursively render fifth-level nested rows */}
                                            {expandedRows.includes(deeperDisplayName) && deeperValue && !deeperValue?.included && typeof deeperValue === 'object' && (
                                              Object.entries(deeperValue).map(([fifthDisplayName, fifthValue], fifthIndex) => {
                                                const fifthKey = `F-fifth-${fifthDisplayName}-${fifthIndex}`;
                                                // if (fifthValue.included){ return null}
                                                return (
                                                  <tr key={fifthKey} className="policy-table-row">
                                                    <td className="policy-table-padded-4 policy-table-sub-coverage-left-padding">{formatCoverageName(fifthDisplayName)}</td>
                                                    {filteredPolicies.filter(policy => isPolicyIncluded(policy.provider)).map(policy => {
                                                      const fifthLevelCoverage = policy.coverages[coverageType] && policy.coverages[coverageType][subDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName][deeperDisplayName] && policy.coverages[coverageType][subDisplayName][nestedDisplayName][deeperDisplayName][fifthDisplayName];
                                                      const fifthCellIdentifier = `${policy.fileName}-${fifthDisplayName}-${fifthIndex}`;
                                                      const { content, className } = fifthLevelCoverage ? renderSubCoverage(fifthLevelCoverage, fifthCellIdentifier) : { content: '×', className: 'cell-no' };
                                                      return (
                                                        <td onContextMenu={(e) => handleRightClick(e, policy)}
                                                          key={`G-${fifthCellIdentifier}`} className={className}>
                                                          <div className="policy-conditional-container">
                                                            {content}
                                                          </div>
                                                        </td>
                                                      );
                                                    })}
                                                  </tr>
                                                );
                                              })
                                            )}
                                          </React.Fragment>
                                        );
                                      })
                                    )}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </React.Fragment>
                        );
                      })
                    )}
                </React.Fragment>
              ))}
            </tbody>

          </table>
          <div className="insurance-table-container-header">
            {/* <button
              className={`policy-table-filter-providers policy-table-filter-providers-advanced ${showAdvancedRow ? 'insurance-table-th-advanced-active' : ''}`}
              onClick={toggleAdvancedRow}>
              Versions
            </button> */}
          </div>
        </div>
      </div>
      <div className="policy-footer">
        <div className="policy-table-disclaimer-title">Explainations:</div>
        <div className="policy-table-disclaimer">C - Conditions: lists prerequisites or obligations of the policyholder for the insurance coverage to remain valid.</div>
        <div className="policy-table-disclaimer">X - Explicitly excluded or not mentioned. </div>
        <div className="policy-table-disclaimer">&#10003; - Specifically mentioned as included. Conditions, excess and limits may apply.</div>
        <div className="policy-table-disclaimer">L - Limit: indicates financial caps or conditions under which the coverage operates. </div>
        <div className="policy-table-disclaimer">E - Excess: shows the amount the policyholder has to pay out of pocket when making a claim. </div>
        <div className="policy-table-disclaimer">O - Optional: denotes if the coverage can be chosen optionally. </div>
      </div>
      {showPolicy && contextMenu && contextMenu.policy && (
        <ShowPolicy policy={contextMenu.policy} closeOverlay={closeOverlay} />
      )}

      {showPolicyNew && contextMenu && contextMenu.policy && (
        <ShowPolicyNew policy={contextMenu.policy} closeOverlay={closeOverlay} />
      )}
      {showCompare && selectedPolicies.first && selectedPolicies.second && (
        <ComparePolicies selectedPolicies={selectedPolicies} closeOverlay={closeOverlay} />
      )}
      {showCompareNew && selectedPolicies.first && selectedPolicies.second && (
        <ComparePoliciesNew selectedPolicies={selectedPolicies} closeOverlay={closeOverlay} />
      )}
    </div >
  );

};

export default PoliciesTable;
