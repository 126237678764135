import { createContext, useState } from 'react';

// Create the context
export const ShowTourContext = createContext();

// Create a provider component
export const ShowTourProvider = ({ children }) => {
  // State to manage whether the tour should be shown (default: false)
  const [showTour, setShowTour] = useState(false);

  // Toggle function to switch between true and false
  const toggleShowTour = () => setShowTour(prevState => !prevState);

  return (
    <ShowTourContext.Provider value={{ showTour, setShowTour, toggleShowTour }}>
      {children}
    </ShowTourContext.Provider>
  );
};
