import React, { useEffect} from "react";
import { useGlobalContext } from '../../context/GlobalContext';
import Conversation from '../../components/chatbot/Conversation';
import './Chat.css';

function Chat() {
  useEffect(() => {
      document.title = 'Chat';
    }, []);
  const { activeDocument } = useGlobalContext();

  return (
    <div className="discover-container">
      <h2>Have a question?</h2>
      <h3>{activeDocument !== null && activeDocument.policyName !== undefined ? "Your coverage for: " + activeDocument.policyName : 'To start, upload a policy on the left'}</h3>

        <Conversation />
    </div>

  );
}

export default Chat;
