// Utility file for formatting functions
// src/utils/utils.js

/**
 * Formats a string by replacing 'And' with '&', adding spaces before uppercase letters,
 * and capitalizing the first letter.
 * @param {string} str - The input string to format.
 * @returns {string} - The formatted string.
 */
 export const formatString = (str) => {
  // Return an empty string if the input is null or undefined
  if (!str) return '';

  // Check if the string has three consecutive capital letters
  if (/[A-Z]{3}/.test(str)) {
      return str; // If it does, return the original string without formatting
  }

  // Replace 'And' with '&'
  let formatted = str.replace(/And/g, ' & ');

  // Add space before any uppercase letters, except the first one if it's uppercase
  formatted = formatted.replace(/([A-Z])/g, (match, p1, offset) => {
      // Don't add space if the uppercase letter is at the start of the string
      return offset === 0 ? match : ` ${match}`;
  });

  // Capitalize the first letter of the formatted string
  formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);

  return formatted;
};

export function formatPolicyType(policyType) {
  return policyType
    .split('-') // Split the string by '-'
    .map((word) => {
      if (word.length === 3 && word === word.toLowerCase()) {
        // Capitalize if it's a 3-letter lowercase word (like 'isr')
        return word.toUpperCase();
      } else {
        // Capitalize the first letter and lowercase the rest for regular words
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(' ')
    .replace(' And ', ' & '); // Replace ' And ' with ' & ' for proper formatting
}

  
  /**
   * Formats a string or number into a USD currency string.
   * @param {string|number} input - The input to format as a currency string.
   * @returns {string} - The formatted currency string.
   */
  export const formatStringToCurrencyString = (input) => {
    // Return '$0' if the input is undefined or null
    if (input === undefined || input === null) {
      return '$0';
    }
  
    
  
    // Ensure the input is a number by removing any non-numeric characters except for '.' and '-'
    const cleanedInput = input.replace(/[^0-9.-]+/g, "");
  
    
  
    // Convert the cleaned input to a number if it's not already a number
    const number = typeof input === 'number' ? input : Number(cleanedInput);
  
    // Check if the input is a valid number
    if (isNaN(number)) {
      return 'Invalid number'; // Return error message for invalid number input
    }
    
    // Use Intl.NumberFormat to format the number into a USD currency string
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
  };
  
  export const formatDate = (inputDate) => {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);
    
    // Extract the day, month, and year from the Date object
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = String(date.getUTCFullYear()).slice(-2); // Get the last two digits of the year
    
    // Format the date as dd-mm-yy
    const formattedDate = `${day}-${month}-${year}`;
    
    return formattedDate;
}
