import React, { useEffect} from "react";
import PolicyDocumentList from '../../components/PolicyDocumentList'
import './DocumentManagement.css';

function DocumentManagement() {
  useEffect(() => {
      document.title = 'DocumentManagement';
    }, []);

  return (
    <div className="document-management">
      <div className="header-and-button">
        <h2>Your policy documents</h2>
      </div>
      <PolicyDocumentList/>
    </div>
  );
}

export default DocumentManagement;
