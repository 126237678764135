import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCompareContext } from '../../context/CompareContext';
import './MatrixChart.css';

const Circle = ({ x, y, size, color, tooltipContent, zIndex }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const rgbValues = color.match(/\d+/g); // ['226', '226', '226'] Assuming color prop is an object with r, g, b values
    const transparentColor = `rgba(${rgbValues.join(", ")}, 0.5)`;

    return (
        <div className="matrix-chart-circle"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            style={{
                left: `${x}%`,
                bottom: `${y}%`,
                backgroundColor: transparentColor,
                border: `solid 2px ${color}`,
                width: `${size * 2}px`,
                height: `${size * 2}px`,
                zIndex: showTooltip ? '1000' : '10'
            }}>
            {showTooltip && (
                <div className="matrix-chart-circle-tooltip">
                    {tooltipContent}
                </div>
            )}

        </div>
    );
};

const Quadrant = ({ position }) => (
    <div className={`quadrant ${position}`}></div>
);

const MatrixChart = ({ data, compare = true }) => {
    const { activeDocument } = useGlobalContext();
    const { compareDocument } = useCompareContext();
    const [positions, setPositions] = useState({ start: null, end: null });

    useEffect(() => {
        if (data) {
            
            const firstGreen = data.find(item => item.color === 'rgb(79,166,88)');
            const firstOrange = data.find(item => item.color === 'rgb(228,156,41)');
            if (firstGreen && firstOrange) {
                setPositions({
                    start: {
                        x: adjustPosition(firstGreen.values[0], minX, maxX, adjustedMinX, adjustedMaxX),
                        y: adjustPosition(firstGreen.values[4], minY, maxY, adjustedMinY, adjustedMaxY)
                    },
                    end: {
                        x: adjustPosition(firstOrange.values[0], minX, maxX, adjustedMinX, adjustedMaxX),
                        y: adjustPosition(firstOrange.values[4], minY, maxY, adjustedMinY, adjustedMaxY)
                    }
                });
            }
        }
    }, [data, activeDocument, compareDocument]);

    if (!data) {
        return null;
    }

    // Find min and max values for both x and y axes
    const minX = Math.min(...data.filter(item => item && item.values && item.values[0] !== 0).map(item => item.values[0]));
    const maxX = Math.max(...data.filter(item => item && item.values && item.values[0] !== 0).map(item => item.values[0]));
    const minY = Math.min(...data.filter(item => item && item.values && item.values[4] !== 0).map(item => item.values[4]));
    const maxY = Math.max(...data.filter(item => item && item.values && item.values[4] !== 0).map(item => item.values[4]));

    // Calculate adjusted range for x and y axes
    const adjustedMinX = minX * 0.5;
    const adjustedMaxX = maxX * 0.9;
    const adjustedMinY = minY * 0.8;
    const adjustedMaxY = maxY * 1.2;

    const adjustPosition = (value, min, max, rangeMin, rangeMax) => {
        const percent = (value - min) / (max - min);
        return (percent * (rangeMax - rangeMin)) + rangeMin;
    };

    return (
        <div className="matrix-chart-container" >
            <div className='matrix-chart-container-title'>
                <div className="matrix-chart-container-header">
                    <span style={{ color: '#4fa658', fontWeight: 'bold' }}>{activeDocument !== undefined && activeDocument !== null ? `${activeDocument.policyProvider}` : ''}</span> policy vs. other providers
                </div>
            </div>
            <div className="matrix-chart">

                <Quadrant position="top-left" />
                <Quadrant position="top-right" />
                <Quadrant position="bottom-left" />
                <Quadrant position="bottom-right" />
                <div className={`matrix-chart-axis-labels x-axis`} style={{ width: '100%' }}>
                    <div className={`matrix-chart-axis-label`} style={{ width: '50%' }}>Low Coverage</div>
                    <div className={`matrix-chart-axis-label`} style={{ width: '50%' }}>High Coverage</div>
                </div>
                <div className={`matrix-chart-axis-labels y-axis`} style={{ width: '300px' }}>
                    <div className={`matrix-chart-axis-label`} style={{ width: '50%' }}>Low Price</div>
                    <div className={`matrix-chart-axis-label`} style={{ width: '50%' }}>High Price</div>
                </div>
                {
                    data.filter(item => item && item.values && item.values[0] !== 0 && item.values[4] !== 0)
                        .sort((a, b) => {
                            const colorsToEnd = ['rgb(79,166,88)', 'rgb(228,156,41)'];

                            // Check if either color of 'a' is in colorsToEnd and 'b' is not
                            if (colorsToEnd.includes(a.color) && !colorsToEnd.includes(b.color)) {
                                return 1;  // a comes after b
                            }
                            // Check if either color of 'b' is in colorsToEnd and 'a' is not
                            if (colorsToEnd.includes(b.color) && !colorsToEnd.includes(a.color)) {
                                return -1; // b comes after a
                            }
                            return 0; // no change
                        })
                        .map((item, index) => {
                            if (item && item.values) {
                                const adjustedX = adjustPosition(item.values[0], minX, maxX, adjustedMinX, adjustedMaxX);
                                const adjustedY = adjustPosition(item.values[4], minY, maxY, adjustedMinY, adjustedMaxY);


                                // Check if adjusted coordinates are both zero, then skip rendering this circle
                                if ((adjustedX === 0 && adjustedY === 0) || adjustedX === undefined || isNaN(adjustedX)) {
                                    return null;
                                }

                                return (
                                    <Circle
                                        key={index}
                                        x={adjustedX}
                                        y={adjustedY}
                                        size={15} // or any dynamic size calculation
                                        color={item.color}
                                        tooltipContent={`${item.code}\nCoverage: ${item.values[0]}\nPrice ${item.values[4]}`}
                                    />
                                );
                            }

                            // Return null if item or item.values is undefined
                            return null;
                        })}
                {compare && compareDocument && compareDocument.policyType === activeDocument.policyType && (
                    <svg className="matrix-chart-background" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '99999999' }} viewBox="0 0 100 100" preserveAspectRatio="none">
                        {/* <line x1="0" y1="90" x2="90" y2="0" stroke="black" strokeWidth="0.2" strokeDasharray="3, 2" />
                    <line x1="0" y1="110" x2="110" y2="0" stroke="black" strokeWidth="0.2" strokeDasharray="3, 2" /> */}
                        {positions.start && positions.end && (
                            <line
                                x1={`${positions.start.x}%`}
                                y1={`${100 - positions.start.y}%`}
                                x2={`${positions.end.x}%`}
                                y2={`${100 - positions.end.y}%`}
                                stroke="black"
                                strokeLinecap="round"
                                strokeWidth="1"
                                markerEnd="url(#arrowhead)" />
                        )}
                        <marker id="arrowhead" markerWidth="5" markerHeight="5" refX="4" refY="2.5" orient="auto">
                            <polygon points="0 0, 5 2.5, 0 5" fill="black" />
                        </marker>
                    </svg>
                )}
            </div>
        </div>
    );
};

export default MatrixChart;