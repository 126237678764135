import React, { useState, useContext, useEffect } from 'react';
import { formatString,formatStringToCurrencyString } from '../utils/utils';
import { UserContext } from "../context/UserContext";
import { useGlobalContext } from '../context/GlobalContext';
import { ProviderPolicyListContext } from '../context/ProviderPolicyListContext';
import Tooltip from './Tooltip';
import './InsuranceForm.css';

const InsuranceForm = ({onClose}) => {
    const { activeDocument } = useGlobalContext();
    const [activeTab, setActiveTab] = useState('basicInformation');
    const [userContext] = useContext(UserContext);
    const { providerPolicies } = useContext(ProviderPolicyListContext);

    const PolicyQuote = {
        basicInformation: {
            address: { description: "The physical location of the insured property.", mandatory: true, default: '', value: activeDocument.policyAddress },
            sumInsured: { description: "The maximum amount the insurance company will pay in the event of a claim.", placeholder: 'Maximum amount ($) of cover', mandatory: true, default: '', value: activeDocument.homeSumInsured, isCurrency: true },
            policyStartDate: {
                description: "Date when the insurance policy becomes effective.",
                mandatory: false,
                default: new Date().toISOString().substring(0, 10), // Format date to YYYY-MM-DD
                value: ''
            },
            mostRecentInsurer: {
                description: "Which company last insured the property?",
                mandatory: false,
                default: 'Unknown',
                value: activeDocument.policyProvider,
                options: ['Unknown', activeDocument.policyProvider]
            },
            currentPrice: {
                description: "How much are you currently paying.",
                mandatory: true, default: '',
                value: activeDocument.policyPrice,
                isCurrency: true
            },
            payentFrequency: { description: "How regularly are your payments", mandatory: true, default: 'weekly', value: activeDocument.paymentFrequency, options: ['weekly', 'bi-monthly', 'monthly', 'yearly'] },
            yearsWithHomeInsurance: { description: "Number of years with continuous home insurance.", mandatory: false, default: '1 - 3 yrs', value: '', options: ['I don\'t know', '<1 yr', '1 - 3 yrs', '3 - 5 yrs', '5 - 10 yrs', '10+ yrs'] },
        },
        propertyDetails: {
            typeOfBuilding: { description: "What type of structure is the insured property?", mandatory: true, default: 'Freestanding', value: '', options: ['Freestanding', 'Apartment', 'Multi-unit', 'Farm', 'Townhouse', 'Relocatable tiny home', 'Lifestyle block or hobby home', 'Retirement village unit'] },
            externalWalls: { description: "What is the primary material used for the external walls?", mandatory: false, default: 'Wood', value: '', options: ['Brick', 'Wood', 'Stone', 'Vinyl'] },
            roofingMaterial: { description: "What is the primary material used for the roofing?", mandatory: false, default: 'Metal', value: '', options: ['Tile', 'Shingle', 'Metal', 'Concrete'] },
            yearBuilt: { description: "What year was your house constructed?", mandatory: true, placeholder: 'Enter year (e.g., 1990)', default: '', value: '' },
            yearPurchased: { description: "What year did you purchase your house?", mandatory: false, placeholder: 'Enter year (e.g., 1990)', default: '', value: '' },
            floorAreaHome: { description: "Total floor area of your home, including attached garage (in square feet).", mandatory: true, placeholder: 'm2', default: '', value: '' },
            floorAreaOutbuildings: { description: "Floor area of your detached garage and outbuildings (in square feet).", mandatory: false, placeholder: 'm2', default: '', value: '' },
            numberOfStoreys: { description: "How many storeys does your home have?", mandatory: false, default: '1', value: '' },
            coveredCarSpaces: { description: "Number of car spaces covered by insurance.", mandatory: false, default: '0', value: '' },
            buildingStandards: { description: "To what standard is your house designed and built?", mandatory: true, default: 'Standard', value: '', options: ['Standard', 'High', 'Premium'] }
        },
        ownerInformation: {
            birthDateOfOldestOwner: { description: "The date of birth of the oldest property owner.", mandatory: true, default: '', value: '' },
            genderOfOldestOwner: { description: "What is the gender of the oldest owner?", mandatory: true, default: '', value: 'Male', options: ['Male', 'Female', 'Other'] },
            occupationOfOwner: { description: "What is your occupation?", mandatory: false, default: '', value: '' },
            ownerType: { description: "Is this owner a person or a business?", mandatory: false, default: 'Person', value: '', options: ['Person', 'Business'] },
        },
        riskAssessment: {
            riskOfNaturalHazards: { description: "According to your local council, is your property at risk of flooding, inundation, land instability, etc.?", mandatory: true, default: 'No', value: '', options: ['Yes', 'No'] },
            homeCondition: { description: "Is your home watertight, structurally sound and secure with no unrepaired damage?", mandatory: true, default: 'Yes', value: '', options: ['Yes', 'No'] },
            homeRenovation: { description: "Is your home undergoing renovation or alteration?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            landType: { description: "Is your house built on flat or sloping land?", mandatory: true, default: 'Flat', value: '', options: ['Flat', 'Gentle', 'Moderate', 'Severe'] },
            mortgageStatus: { description: "Is there a mortgage on this home?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            smokeDetector: { description: "Does this home have a professionally monitored heat or smoke detector?", mandatory: true, default: 'No', value: '', options: ['Yes', 'No'] },
            burglarAlarm: { description: "Does the property have a professionally installed burglar alarm?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            architecturalDesign: { description: "Is the house architecturally designed?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            constructionStandard: { description: "How would you describe your home's standard of construction?", mandatory: true, default: 'Standard', value: '', options: ['Standard', 'Quality', 'Prestigious'] },
            roofType: { description: "Is the roof flat or pitched?", mandatory: true, default: 'Pitched', value: '', options: ['Flat', 'Pitched'] }
        },
        occupancyAndUse: {
            whoLivesInHouse: { description: "Who currently resides at the property?", mandatory: true, default: '', value: '', options: ['Tenants', 'Relatives', 'Employees'] },
            businessAtAddress: { description: "Is there a business operated from the insured address?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            livingFromStartDate: { description: "Will you be living in the property from the start date?", mandatory: true, default: 'Yes', value: '', options: ['Yes', 'No'] }
        },
        structuralFeatures: {
            outbuildingsAndFeatures: {
                description: "What additional structures or features does your property have?",
                mandatory: false,
                options: ['Balcony/Deck', 'Veranda/Pergola', 'Sports court', 'Shed (large)', 'Fixed swimming pool', 'Garden shed', 'Water tanks'],
                value: {
                    'Balcony/Deck': false,
                    'Veranda/Pergola': false,
                    'Sports court': false,
                    'Shed (large)': false,
                    'Fixed swimming pool': false,
                    'Garden shed': false,
                    'Water tanks': false
                }
            },
            freestandingGarage: { description: "Does your house have a freestanding garage?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            bodyCorporate: { description: "Is your home part of a body corporate or does it have a strata or unit title?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] }
        },
        claimsAndIncidents: {
            pastIncidentsOrClaims: { description: "Have any insureds had any incidents or claims involving home buildings in the past 5 years?", mandatory: true, default: 'No', value: '', options: ['Yes', 'No'] },
            dateOfLastIncident: { description: "When did the most recent incident or damage occur?", mandatory: false, default: '', value: '' },
            lossOrDamageDescription: { description: "Describe the type of loss or damage previously claimed.", mandatory: false, default: '', value: '' },
        },
        additionalFeatures: {
            sharedWalls: { description: "Does your home (including garaging) share any wall(s) with any neighboring property?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            homeHeating: { description: "Does your home have any form of heating?", mandatory: false, default: 'Yes', value: '', options: ['Yes', 'No'] },
            gardenSheds: { description: "Does your home have any garden sheds?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] },
            decks: { description: "Does your home have any decks?", mandatory: false, default: 'No', value: '', options: ['Yes', 'No'] }
        },
    };

    const [formData, setFormData] = useState(PolicyQuote);

    const addInsurerOptions = (newOptions) => {
        setFormData(prevFormData => {
            const currentOptions = prevFormData.basicInformation.mostRecentInsurer.options;
            const currentOptionsSet = new Set(currentOptions);

            // Combine current options with new, filtered options
            const combinedOptions = [
                ...currentOptions,
                ...newOptions.filter(option => !currentOptionsSet.has(option))
            ];

            // Sort combined options alphabetically
            const sortedOptions = combinedOptions.sort((a, b) => a.localeCompare(b));

            // Return the updated state with sorted options
            return {
                ...prevFormData,
                basicInformation: {
                    ...prevFormData.basicInformation,
                    mostRecentInsurer: {
                        ...prevFormData.basicInformation.mostRecentInsurer,
                        options: sortedOptions
                    }
                }
            };
        });
    };

    useEffect(() => {
        if (userContext && userContext.details && userContext.details.country) {
            const filteredProviders = providerPolicies.filter(item => item.country === userContext.details.country);
            const providersNames = filteredProviders.map(item => item.provider_name);
            const uniqueNames = Array.from(new Set(providersNames));
            
            addInsurerOptions(uniqueNames);
        }

    }, [userContext]);

    const updateCurrencyChange = (category, key, value) => {
        setFormData(prevData => {
            // Determine if we need to format the value as currency
            const shouldFormatAsCurrency = (prevData[category][key].isCurrency === true ? true : false);
            // Format the value if necessary
            const formattedValue = shouldFormatAsCurrency ? formatStringToCurrencyString(value) : value;
            

            return {
                ...prevData,
                [category]: {
                    ...prevData[category],
                    [key]: {
                        ...prevData[category][key],
                        value: formattedValue
                    }
                }
            };
        });
    };

    const handleOnClose = () => {
        onClose();
    }
    
    const handleInputChange = (category, key, value) => {
        setFormData(prevData => {
            return {
                ...prevData,
                [category]: {
                    ...prevData[category],
                    [key]: {
                        ...prevData[category][key],
                        value: value
                    }
                }
            };
        });
    };

    const renderInput = (item, key, category) => {
        let inputElement = null;
        if (item.options && item.options !== []) {
            inputElement = (
                <select
                    className="insurance-form-input"
                    value={item.value !== '' ? item.value : item.default}
                    onChange={(e) => handleInputChange(category, key, e.target.value)}
                >
                    <option value="">{item.default || 'Please select...'}</option>
                    {item.options.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            );
        } else {
            switch (key) {
                case 'policyStartDate':
                case 'birthDateOfOldestOwner':
                    inputElement = (
                        <input
                            className="insurance-form-input"
                            type="date"
                            value={item.value !== '' ? item.value : item.default}
                            onChange={(e) => handleInputChange(category, key, e.target.value)}
                        />
                    );
                    break;
                case 'mortgageStatus':
                case 'smokeDetector':
                case 'burglarAlarm':
                case 'businessAtAddress':
                case 'livingFromStartDate':
                case 'homeRenovation':
                case 'architecturalDesign':
                case 'jointOwnership':
                    inputElement = (
                        <select
                            className="insurance-form-input"
                            value={item.value !== '' ? item.value : item.default}
                            onChange={(e) => handleInputChange(category, key, e.target.value)}
                        >
                            <option value="">Please Select</option>
                            {item.options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    );
                    break;
                case 'yearBuilt':
                case 'yearPurchased':
                    inputElement = (
                        <input
                            className="insurance-form-input"
                            type="number"
                            min="1900"
                            max={new Date().getFullYear()}
                            value={item.value !== '' ? item.value : item.default}
                            placeholder={item.placeholder}
                            onChange={(e) => handleInputChange(category, key, e.target.value)}
                        />
                    );
                    break;
                case 'lossOrDamageDescription':
                    inputElement = (
                        <textarea
                            className="insurance-form-input"
                            value={item.value !== '' ? item.value : item.default}
                            placeholder={item.placeholder}
                            onChange={(e) => handleInputChange(category, key, e.target.value)}
                        />
                    );
                    break;
                default:
                    inputElement = (
                        <input
                            className="insurance-form-input"
                            type="text"
                            value={item.value !== '' ? item.value : item.default}
                            placeholder={item.placeholder}
                            onChange={(e) => handleInputChange(category, key, e.target.value)}
                            onBlur={(e) => updateCurrencyChange(category, key, e.target.value)}
                        />
                    );
                    break;
            }
        }

        return (
            <div className="insurance-form-field">
                <Tooltip text={item.description}>
                    <label className="insurance-form-label">{formatString(key)} {item.mandatory && <span className="insurance-form-mandatory">*</span>}</label>
                </Tooltip>
                {inputElement}
            </div>
        );
    };

    return (<>
        <div className="insurance-form-page">
            <div className="insurance-form-container">
                <ul className="insurance-form-tab-list">
                    {Object.keys(formData).map(category => (
                        <li key={category} className={`insurance-form-tab-list-item ${activeTab === category ? 'insurance-form-active' : ''}`} onClick={() => setActiveTab(category)}>
                            {formatString(category.replace(/([A-Z])/g, ' $1').trim())}
                        </li>
                    ))}
                </ul>
                <div className="insurance-form-tab-content">
                    {Object.entries(formData[activeTab]).map(([key, item]) => (
                        <>
                            {renderInput(item, key, activeTab)}
                        </>
                    ))}
                </div>
            </div>
        </div>
        <div className="insurance-form-page-footer">
            <button className="insurance-form-update-button" onClick={() => handleOnClose()}>
                Update
            </button>
        </div>
    </>
    );
};

export default InsuranceForm;
