import React, { useState, useEffect, useRef } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth, isSameDay } from 'date-fns';
import './DateInput.css'

function DateInput({ onDateChange }) {
    const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const actualCurrentMonth = startOfMonth(new Date());

    const isCurrentMonth = () => {
        return isSameMonth(currentMonth, actualCurrentMonth);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [calendarOpen, setCalendarOpen] = useState(false);
    const dateInputRef = useRef(null); // Ref for the DateInput component

    useEffect(() => {
        function handleClickOutside(event) {
            if (dateInputRef.current && !dateInputRef.current.contains(event.target)) {
                setCalendarOpen(false);
            }
        }

        // Add event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dateInputRef]);

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        if (!isCurrentMonth()) {
            setCurrentMonth(subMonths(currentMonth, 1));
        }
    };

    const generateWeekdayTitles = () => {
        return weekdays.map(day => (
            <div key={day} className="weekday-title">
                {day}
            </div>
        ));
    };

    const generateCalendar = () => {
        const startWeek = startOfWeek(startOfMonth(currentMonth));
        const endWeek = endOfWeek(endOfMonth(currentMonth));
        const days = eachDayOfInterval({ start: startWeek, end: endWeek });

        return days.map(day => {
            const dayInCurrentMonth = isSameMonth(day, currentMonth);
            const selected = isSameDay(day, selectedDate);
            return (
                <button
                    key={day}
                    disabled={!dayInCurrentMonth}
                    className={`calendar-day ${selected ? 'selected' : ''}`}
                    onClick={() => {
                        setSelectedDate(day);
                        setCalendarOpen(false);
                        onDateChange(day); // Call the callback function with the new date
                    }}
                >
                    {format(day, 'd')}
                </button>
            );
        });
    };

    return (
        <div className="date-picker" ref={dateInputRef}>
            <input
                type="text"
                className="calendar-font"
                value={format(selectedDate, 'MM/dd/yyyy')}
                onFocus={() => setCalendarOpen(true)}
                readOnly
            />
            {calendarOpen && (
                <div className="calendar-popup">
                    <button onClick={prevMonth} disabled={isCurrentMonth()}>{'<'}</button>
                    <span >{format(currentMonth, 'MMMM yyyy')}</span>
                    <button onClick={nextMonth}>{'>'}</button>
                    <div className="calendar-grid">
                        {generateWeekdayTitles()}
                        {generateCalendar()}
                    </div>
                </div>
            )}
        </div>
    );
}

export default DateInput;
