import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import AdviceHeader from "../AdviceHeader";
import AdviceTab from "../AdviceTab";
import ClientDetails from "./ClientDetails";
import BudgetComponent from "../../statement/BudgetComponent";
import './ProposalClientDetails.css';

const ProposalClientDetails = ({ isOpen, toggleSection, proposal, updateProposal, pdf}) => {
    const [completed, setCompleted] = useState(true);
    const [userContext, setUserContext] = useContext(UserContext);
    const [budget, setBudget] = useState(100);

    const id = 'clientDetails';

    const tabs = [
        { key: 'summary', label: 'Summary' },
        { key: 'budget', label: 'Budget' },
    ];    

    const handleBudgetChange = (newState) => {
        if(newState.amount > 0 ){
            setBudget(newState);
            setCompleted(true);
        } else if (completed) {
            setCompleted(false);
        }
    };

    useEffect(() => {
        updateProposal(id, budget)
    }, [budget]);

    return (
        <div className="form-section">
            <AdviceHeader
                id={id}
                toggleSection={toggleSection}
                title={'Client Details'}
                tooltip={'Information about the client such as name, date of birth, and contact details.'}
                completed={completed}
                pdf={pdf}
                isOpen={isOpen}
            />
            {isOpen && (
                <AdviceTab tabs={tabs}>
                    <div key="summary">
                        <ClientDetails userContext={userContext} />
                    </div>
                    <div key="budget">
                        <BudgetComponent onChange={handleBudgetChange} />
                    </div>
                </AdviceTab>
            )}
        </div>
    );
};

export default ProposalClientDetails;
