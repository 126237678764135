import axios from 'axios';

export const saveNeedsData = (clientID, proposalID, quoteID, needsId, data) => {
  const payload = {
    clientID,
    proposalID,
    quoteID,
    data,
  };

  if (needsId) {
    return axios.put(`${process.env.REACT_APP_API_ENDPOINT}api/needs/${needsId}`, payload);
  } else {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}api/needs`, payload);
  }
};

// Fetch all needs for a specific client, proposal, and quote
export const getNeedsData = (clientID, proposalID, quoteID) =>
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/needs`, {
    params: { clientID, proposalID, quoteID }
  });