import React, { useState,useContext } from 'react';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import './PolicyTableBasicLegend.css'; 

const PolicyTableBasicLegend = ({ sortedPolicyTypes, dynamicPolicyType, handlePolicyTypeChange,isPopupVisible, togglePopup,popupRef }) => {
  const {policyType} = useContext(PolicyTypeContext);
  const initialCategory = sortedPolicyTypes.find((policy) => policy.code === policyType)?.category || sortedPolicyTypes[0]?.category || 'General';
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handlePolicySelect = (policy) => {
    handlePolicyTypeChange(policy.code);
  };

  const categories = [...new Set(sortedPolicyTypes.map((policy) => policy.category))];
  
  return (
    <div className="policy-table-legend-container" ref={popupRef}>
      <div className="policy-table-basic-legend" onClick={togglePopup}>
        {sortedPolicyTypes.find((policy) => policy.code === dynamicPolicyType)?.label || 'Select Insurance Type'}
        <div className="policy-table-legend-select-arrow"></div>
      </div>

      {isPopupVisible && (
        <div className="policy-table-legend-popup-overlay">
          <div className="policy-table-legend-popup-content">
            <div className="policy-table-legend-category-buttons">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`policy-table-legend-category-button ${category === selectedCategory ? 'active' : ''}`}
                  onClick={() => handleCategorySelect(category)}
                >
                  {category}
                </button>
              ))}
            </div>

            <div className="policy-table-legend-policy-grid">
              {sortedPolicyTypes
                .filter((policy) => policy.category === selectedCategory)
                .map((policy) => (
                  <div
                    key={policy.code}
                    className={`policy-table-legend-policy-grid-item ${dynamicPolicyType === policy.code ? 'selected' : ''} ${policy.enabled ? 'enabled' : 'disabled'}`}
                    onClick={() => handlePolicySelect(policy)}
                  >
                    {policy.label}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PolicyTableBasicLegend;
