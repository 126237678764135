import React, { useEffect, useState, useContext } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCollapse } from '../../context/CollapseContext';
import { useClient } from '../../context/ClientContext';
import WaitingDiv from "../../components/WaitingDiv";
import ChevronTabs from '../../components/ChevronTabs';
import Analysis from './Analysis';
import PoliciesTable from '../../components/PoliciesTable';
import Comparison from './Comparison';
import CurrentSituation from './CurrentSituation';
import NeedsFilter from '../../components/needs/NeedsFilter';
import UploadPolicyURL from "../../images/upload-policy.png";
import './Quote.css';


const Quote = ({ activeTab, setActiveTab,tabNames}) => {
  useEffect(() => {
    document.title = 'Quote';
  }, []);
  const { activeClient } = useClient();
  const { activeDocument } = useGlobalContext();
  const { isCollapsed } = useCollapse();


  return (
    <div className="quote-dashboardcontainer">
      <h2>Build a quote:</h2>
      <h3>{activeClient?.personalDetails?.firstName} {activeClient?.personalDetails?.lastName} | Type: {activeDocument?.policyType} | Current: {activeDocument?.policyProvider}</h3>
      {!activeDocument && (
          <div className="quote-dashboardcontainer-waiting"
            style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
            <div>Please upload a policy on the left to start...</div>
            <img src={UploadPolicyURL} className="quote-img" alt='upload-img' />
            {/* <WaitingDiv /> */}
          </div>
      )}
      {activeDocument && (
        <ChevronTabs tabNames={tabNames} activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      {activeTab !== 'Current Policy' && activeTab !== 'Build Quote' && (
        <NeedsFilter />
      )}
      <div className="quote-tab-content">
        {activeTab === 'Current Policy' && (
          <CurrentSituation />
        )}
        {activeTab === 'Policy Search' && (
          <PoliciesTable />
        )}
        {activeTab === 'Policy Compare' && (
          <Comparison />
        )}
        {activeTab === 'Build Quote' && (
          <Analysis />
        )}
      </div>
    </div>
  );
};

export default Quote;
