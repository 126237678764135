import React, { useState, useEffect } from 'react';
import './MyNotifications.css';

const MyNotifications = () => {
    const [communicationPreferences, setCommunicationPreferences] = useState({
        newsletter: true,
        updates: true,
        productNews: false,
        promotionalOffers: false,
        productAlerts: false,
        generalUpdates: false,
    });

    useEffect(() => {
        document.title = 'My Notifications';
    }, []);

    const handlePreferenceChange = (preference) => {
        setCommunicationPreferences({
            ...communicationPreferences,
            [preference]: !communicationPreferences[preference],
        });
    };

    return (
        <div className="notifications-container">
            <h2>Notifications Settings</h2>
            <p>Manage how you receive notifications and updates from us.</p>

            <div className="notifications-section">
                <h3>Communication Preferences</h3>
                <p>Choose how you'd like to stay informed:</p>

                <div className="notification-options">
                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.newsletter}
                            onChange={() => handlePreferenceChange('newsletter')}
                        />
                        Subscribe to Newsletter
                    </label>

                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.updates}
                            onChange={() => handlePreferenceChange('updates')}
                        />
                        Receive Updates
                    </label>

                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.productNews}
                            onChange={() => handlePreferenceChange('productNews')}
                        />
                        Product News
                    </label>

                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.promotionalOffers}
                            onChange={() => handlePreferenceChange('promotionalOffers')}
                        />
                        Promotional Offers
                    </label>
                </div>
            </div>

            <div className="notifications-section">
                <h3>Notification Settings</h3>
                <p>Control your notification preferences for product alerts and updates:</p>

                <div className="notification-options">
                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.productAlerts}
                            onChange={() => handlePreferenceChange('productAlerts')}
                        />
                        Product Alerts
                    </label>

                    <label className="notification-option">
                        <input
                            type="checkbox"
                            checked={communicationPreferences.generalUpdates}
                            onChange={() => handlePreferenceChange('generalUpdates')}
                        />
                        General Updates
                    </label>
                </div>
            </div>
        </div>
    );
};

export default MyNotifications;
