import React from 'react';
import { Line } from 'react-chartjs-2';
import './RetentionRateChart.css';

const RetentionRateChart = ({ retentionData }) => {
  const data = {
    labels: retentionData.map(data => data.period),
    datasets: [{
      label: 'Retention Rate',
      data: retentionData.map(data => data.rate),
      fill: false,
      borderColor: 'rgb(83,166,88)',
      tension: 0.1
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="retention-rate-chart">
    <div className="retention-rate-chart-title">Client Communications</div>
      <Line data={data} options={options}/>
    </div>
  );
};

export default RetentionRateChart;
