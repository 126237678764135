import React, { useState, useContext, useEffect } from 'react';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { useGlobalContext } from '../../context/GlobalContext';
import StrengthsBarChart from './components/StrengthsBarChart';
import './StrengthsAndWeaknesses.css';

const Strengths = () => {
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { activeDocument } = useGlobalContext();
  const [attributes, setAttributes] = useState({});
  const [policy, setPolicy] = useState(masterPolicies[0]);
  const [tableKey, setTableKey] = useState(0);
  const { policyType } = useContext(PolicyTypeContext);
  const [showMore, setShowMore] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleShowMore = () => setShowMore(!showMore);

  const fetchPolicies = async () => {
    if (activeDocument !== null && activeDocument !== undefined && activeDocument.policyProvider) {
      const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;

      if (masterPolicies && masterPolicies.values) {
        for (let entry of masterPolicies.values()) {
          if (entry.provider === activeFileName && entry.type === policyType) {
            setPolicy(entry);
            setAttributes(entry.strengths);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      fetchPolicies();
      setTableKey(prevKey => prevKey + 1);
    }
  }, [activeDocument]);

  const formatText = (text) => {
    let formattedText = text.split('_')[1];
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
    formattedText = formattedText.replace(/([A-Z])/g, ' $1').trim();
    return formattedText;
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      {Object.keys(attributes).length > 0 && (
        <div className="strengths-container">
          <div className="strengths-container-div">
            <div className="strengths-container-header">
              <span style={{ color: '#4fa658', fontWeight: 'bold' }}>
                Your {Object.keys(attributes).length} Policy Strengths*
              </span>
            </div>
            <table className="strengths-container-table" key={tableKey}>
              <thead>
                <tr>
                  <th></th>
                  <th style={{ textAlign: 'left' }}>Cover</th>
                  <th>Limit</th>
                  <th>Excess</th>
                </tr>
              </thead>
              <tbody key={tableKey}>
                {attributes && Object.keys(attributes)
                  .slice(0, showMore ? undefined : 8)
                  .map((key, index) => (
                    <React.Fragment key={index}>
                      <tr key={index} onClick={() => handleRowClick(index)}>
                        <td className="strengths-container-table-tick">&#10003;</td>
                        <td className="strengths-container-table-name">{formatText(key)}</td>
                        <td style={{ textAlign: 'center' }} className="strengths-container-table-name">
                          {policy.coverages?.[key.split('_')[0]]?.[key.split('_')[1]]?.limit}
                        </td>
                        <td style={{ textAlign: 'center' }} className="strengths-container-table-name">
                          {policy.coverages?.[key.split('_')[0]]?.[key.split('_')[1]]?.excess}
                        </td>
                      </tr>
                      {expandedRow === index && (
                        <tr key={`${index}-details`} onClick={() => handleRowClick(null)}>
                          <td colSpan="4" className="strengths-container-table-percentage">
                            <div>Percentage of providers which include: {formatText(key)}</div>
                            <StrengthsBarChart coverageKey={key} masterPolicies={masterPolicies} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            {Object.keys(attributes).length > 8 && (
              <div className="conditions-show-more" onClick={toggleShowMore}>
                {showMore ? 'Show less...' : 'Show more...'}
              </div>
            )}
            <div className="strengths-container-footer">
              * Covered in your policy, but 50%+ of insurers do not cover these items.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Strengths;
