import React from 'react';

const maritalStatusOptions = ['Single', 'Married', 'Divorced', 'Widowed'];
const genderOptions = ['Male', 'Female', 'Other'];
const countryOptions = ['Australia', 'New Zealand'];
const stateOptions = {
  'AU': ['New South Wales', 'Victoria', 'Queensland', 'South Australia', 'Western Australia', 'Tasmania'],
  'NZ': ['Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'Dunedin'],
};

const GeneralInfoTab = ({ clientData, handleChange, renderFieldError }) => {

  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid">
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">General Information</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Username:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="userName" value={clientData.userName} disabled />
              {renderFieldError('userName')}
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Client Type:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="clientType" value={clientData.clientType} onChange={handleChange}>
                <option value="Consumer">Consumer</option>
                <option value="Business">Business</option>
              </select>
            </div>
          </div>
        </div>
        {clientData.clientType === "Consumer" && (
          <div className="client-details-popup modal-grid-item">
            <div className="client-details-popup modal-grid-item-label">Personal Details</div>
            <div className="client-details-popup modal-subgrid">
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">First Name:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="personalDetails.firstName" value={clientData.personalDetails.firstName} onChange={handleChange} />
                {renderFieldError('personalDetails.firstName')}
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Middle Name:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="personalDetails.middleName" value={clientData.personalDetails.middleName} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Last Name:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="personalDetails.lastName" value={clientData.personalDetails.lastName} onChange={handleChange} />
                {renderFieldError('personalDetails.lastName')}
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Date of Birth:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="date" name="personalDetails.dateOfBirth" value={clientData.personalDetails.dateOfBirth} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Marital Status:</div>
                <select className="client-details-popup modal-grid-item-label-input" name="personalDetails.maritalStatus" value={clientData.personalDetails.maritalStatus} onChange={handleChange}>
                  {maritalStatusOptions.map(status => <option key={status} value={status}>{status}</option>)}
                </select>
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Dependents:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="number" name="personalDetails.dependents" value={clientData.personalDetails.dependents} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Gender:</div>
                <select className="client-details-popup modal-grid-item-label-input" name="personalDetails.gender" value={clientData.personalDetails.gender} onChange={handleChange}>
                  {genderOptions.map(gender => <option key={gender} value={gender}>{gender}</option>)}
                </select>
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Nationality:</div>
                <select className="client-details-popup modal-grid-item-label-input" name="personalDetails.nationality" value={clientData.personalDetails.nationality} onChange={handleChange}>
                  <option value="Australian">Australian</option>
                  <option value="New Zealander">New Zealander</option>
                </select>
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Occupation:</div>
                <select className="client-details-popup modal-grid-item-label-input" name="personalDetails.occupation" value={clientData.personalDetails.occupation} onChange={handleChange}>
                  <option value="Engineer">Engineer</option>
                  <option value="Teacher">Teacher</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Lawyer">Lawyer</option>
                  <option value="Accountant">Accountant</option>
                </select>
              </div>
            </div>
          </div>
        )}
        {clientData.clientType === 'Business' && (
          <div className="client-details-popup modal-grid-item">
            <div className="client-details-popup modal-grid-item-label">Business Details</div>
            <div className="client-details-popup modal-subgrid">
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Business Name:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="businessDetails.businessName" value={clientData.businessDetails.businessName} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Industry:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="businessDetails.industry" value={clientData.businessDetails.industry} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Registration Number:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="businessDetails.registrationNumber" value={clientData.businessDetails.registrationNumber} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Registration Date:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="date" name="businessDetails.registrationDate" value={clientData.businessDetails.registrationDate} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Number of Employees:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="number" name="businessDetails.numberOfEmployees" value={clientData.businessDetails.numberOfEmployees} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Annual Revenue:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="number" name="businessDetails.annualRevenue" value={clientData.businessDetails.annualRevenue} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Operational Hours:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="businessDetails.operationalHours" value={clientData.businessDetails.operationalHours} onChange={handleChange} />
              </div>
              <div className="client-details-popup flex-row">
                <div className="client-details-popup modal-grid-item-label-sub">Business Location Type:</div>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="businessDetails.businessLocationType" value={clientData.businessDetails.businessLocationType} onChange={handleChange} />
              </div>
            </div>
          </div>
        )}
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Contact Information</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Phone:</div>
              <div className="client-details-popup flex-row">
                <select className="client-details-popup modal-grid-item-label-input" name="contactInformation.phoneCountryCode" value={clientData.contactInformation.phoneCountryCode} onChange={handleChange}>
                  <option value="+61">+61 (Australia)</option>
                  <option value="+64">+64 (New Zealand)</option>
                </select>
                <input className="client-details-popup modal-grid-item-label-input" type="text" name="contactInformation.phone" value={clientData.contactInformation.phone} onChange={handleChange} />
                {renderFieldError('contactInformation.phone')}
              </div>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Email:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="email" name="contactInformation.email" value={clientData.contactInformation.email} onChange={handleChange} />
              {renderFieldError('contactInformation.email')}
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Alternate Phone:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="contactInformation.alternatePhone" value={clientData.contactInformation.alternatePhone} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Fax:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="contactInformation.fax" value={clientData.contactInformation.fax} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Website:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="contactInformation.website" value={clientData.contactInformation.website} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Emergency Contact:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="contactInformation.emergencyContact" value={clientData.contactInformation.emergencyContact} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Address Information</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Address:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="addressInformation.address" value={clientData.addressInformation.address} onChange={handleChange} />
              {renderFieldError('addressInformation.address')}

            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Suburb:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="addressInformation.suburb" value={clientData.addressInformation.suburb} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Postcode:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="number" name="addressInformation.postcode" value={clientData.addressInformation.postcode} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">State:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="addressInformation.state" value={clientData.addressInformation.state} onChange={handleChange}>
                {stateOptions[clientData.addressInformation.country].map(state => <option key={state} value={state}>{state}</option>)}
              </select>              
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Country:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="addressInformation.country" value={clientData.addressInformation.country} onChange={handleChange}>
                {countryOptions.map(country => <option key={country} value={country}>{country}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Billing Address:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="addressInformation.billingAddress" value={clientData.addressInformation.billingAddress} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Shipping Address:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="addressInformation.shippingAddress" value={clientData.addressInformation.shippingAddress} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoTab;
