import React from 'react';
import './ExportModal.css';

const ExportModal = ({ isOpen, onClose, onCancel, title, message, showCancelButton }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onCancel}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>{title}</h3>
        <p>{message}</p>
        <div style={{display:'flex', gap:'10px', justifyContent:'center'}}>
          {showCancelButton && (
            <>
              <button onClick={onCancel}>Cancel</button>
            </>
          )}
          <button onClick={onClose}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
