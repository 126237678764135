import React, { useState, useEffect } from 'react';
import './Policies.css';

const Policies = () => {
    useEffect(() => {
        document.title = 'Policies';
      }, []);
      
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "users/api/documents", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => setDocuments(data));
      }, []);

    const handleDocumentClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="policies-page">
            <div className="policies-container">
                <h1>Documents & disclosures</h1>
                <h3>Information for our customers. All key documents, in one place.</h3>
                <div className="documents-list">
                    {documents.map(doc => (
                        <div className="document-item" onClick={() => handleDocumentClick(doc.url)} key={doc.id}>
                            <img src={doc.icon} alt="Logo" className="document-logo" />
                            <span className="document-name">{doc.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Policies;