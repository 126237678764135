import React, { useContext, useState, useEffect } from 'react';
import SearchInput from './SearchInput';
import { PolicyTypeContext } from '../context/PolicyTypeContext';
import SearchButtonDropDown from './SearchButtonDropDown';
import { FiltersContext } from '../context/FiltersContext';
import SettingsIconURL from "../icons/settings_grey.png";
import { CountryContext } from '../context/CountryContext';
import './SearchContainer.css';

function SearchContainer() {
  const { country } = useContext(CountryContext);
  const { globalFilters } = useContext(FiltersContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const { policyType, setPolicyType } = useContext(PolicyTypeContext);
  const policyTypes = ['home', 'landlord', 'isr', 'contents', 'home-contents'];

  const handleButtonSelect = (policy) => {
    setPolicyType(policy);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const countCheckedItems = (globalFilters) => {
    let countChecked = 0;  // Counter for checked items
    let countUnchecked = 0; // Counter for unchecked items

    globalFilters.forEach(filter => {
      if (filter.checked) {
        countChecked++;
      } else {
        countUnchecked++;
      }
    });

    return { countChecked, countUnchecked };
  };

  const DisplayCheckedInfo = () => {
    const { countChecked, countUnchecked } = countCheckedItems(globalFilters.filter(filter => filter.country === country));
    const totalCount = countChecked + countUnchecked;

    return (
      <div><span><strong>Showing {countChecked} out of {totalCount}</strong></span></div>

    );
  };

  const handleDataChange = (hasData) => {
  };

  return (
    <div className='search-input-page' >
      <div className='search-container-container'>
        <div className='search-container'>
          <div className="search-container-popup-grid">
            {policyTypes.map((policy) => (
              <div key={policy} className="search-container-popup-grid-item">  {/* Key moved here */}
                <div
                  onClick={() => handleButtonSelect(policy)}
                  className={`search-container-popup-grid-item-box ${policyType === policy ? 'search-container-popup-grid-item-box-selected' : ''}`}
                >
                  {policy}
                </div>
              </div>
            ))}
            <div className="search-container-popup-grid-item">
              <button className="search-container-button-filter"
                onClick={toggleDropdown}>
                {DisplayCheckedInfo()}
                <img src={SettingsIconURL} alt='' style={{ width: '20px' }} />
              </button>
              {showDropdown && (
                <SearchButtonDropDown onClose={toggleDropdown} showCountry={true} top={'70px'} left={'-680px'} width={'850px'} />
              )}
            </div>
          </div>
        </div>
        <SearchInput onDataChange={handleDataChange} />
      </div>
    </div>
  );
}

export default SearchContainer;
