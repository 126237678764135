import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RefContext } from './../context/RefContext';
import SocialIcons from './social/SocialIcons';
import LogoURL from "./../logo/logo_green.png";
import { Link } from 'react-router-dom';
import './Footer.css';

/**
 * FooterLink Component
 * Reusable component to render a link or manual link in the footer.
 * Handles both navigation links and scrollable sections.
 * 
 * @param {string} to - The URL to navigate to.
 * @param {string} label - The text label for the link.
 * @param {function} onClick - Optional onClick handler for manual links.
 * @param {boolean} isManualLink - Whether the link is a manual scroll link or not.
 */
const FooterLink = ({ to, label, onClick, isManualLink = false }) => {
  return (
    <li>
      {isManualLink ? (
        <div className="footer-manual-link" onClick={onClick}>{label}</div>
      ) : (
        <Link className="footer-tag" to={to}>{label}</Link>
      )}
    </li>
  );
};

const Footer = () => {
  const myTargetRef = useContext(RefContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [hoveredButton, setHoveredButton] = useState(null);

  const scrollToTarget = () => {
    if (location.pathname !== '/') {
      navigate('/'); // Navigate to the root path only if not already there

      // Delay the scroll until navigation is complete
      setTimeout(() => {
        scrollToRef();
      }, 1000); // Adjust the delay if needed
    } else {
      scrollToRef(); // If already on '/', scroll immediately
    }
  };

  const scrollToRef = () => {
    if (myTargetRef.current) {
      myTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="policy-check-company-name" onClick={() => navigate('/')}>
        <img src={LogoURL} className="footer-company-logo" alt="Logo" />
        <span className="footer-policy-check-green">POLICY</span>
        <span className="policy-check-white">CHECK</span>
      </div>

      <div className="footer-sections">
        <div className="footer-section">
          <div className="footer-section-title">Solutions</div>
          <nav>
            <ul>
              <FooterLink label="Clients" onClick={scrollToTarget} isManualLink />
              <FooterLink label="Brokers" isManualLink />
              <FooterLink label="Insurer" onClick={scrollToTarget} isManualLink />
            </ul>
          </nav>
        </div>
        <div className="footer-section footer-hide">
          <div className="footer-section-title">Resources</div>
          <nav>
            <ul>
              <FooterLink label="Find a Broker" to="/directory" />
              <FooterLink label="Blog" to="/blog" />
              {/* TODO */}
              {/* <FooterLink label="Policy Search" to="/policy-search" /> */}
              <FooterLink label="Policy Library" to="/library" />
              <FooterLink label="FAQ" to="/faq" />
            </ul>
          </nav>
        </div>
        <div className="footer-section">
          <div className="footer-section-title">Company</div>
          <nav>
            <ul>
              <FooterLink to="/our-story" label="About Us" />
              <FooterLink to="/contact-us" label="Contact Us" />
              <FooterLink to="/careers" label="Careers" />
              <FooterLink to="/contact-us?investor-relations" label="Investors" />
            </ul>
          </nav>
        </div>
        <div className="footer-section">
          <div className="footer-section-title">Legal</div>
          <nav>
            <ul>
              <FooterLink to="/policies" label="Privacy Policy" />
              <FooterLink to="/policies" label="Terms & Conditions" />
              <FooterLink to="/policies" label="Product Disclosures" />
            </ul>
          </nav>
        </div>
        <div className="footer-section footer-section-social">
          <SocialIcons
            twitterLink="https://twitter.com/policycheck"
            facebookLink="https://facebook.com/policycheck"
            linkedinLink="https://linkedin.com/company/policycheck"
          />
        </div>
      </div>
      <div className="copyright-container">
        <hr className="footer-divider" />
        <div className="copyright">
          <p className="copyright-info">
            <Link to="/policies">Privacy</Link>
          </p>
          <p className="copyright-info">
            <Link to="/policies">Cookies</Link>
          </p>
          <p className="copyright-info">
            <Link to="/policies">Disclaimer</Link>
          </p>
          <p className="copyright-info">2024 PolicyCheck</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
