import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Countdown.css';

const Countdown = ({ ExpiryDate }) => {
  // Create a Date object
  const expiryDate = new Date(ExpiryDate);

  // Extract day, month, and year
  const day = expiryDate.getDate().toString().padStart(2, '0'); // Pad with zero if needed
  const month = (expiryDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = expiryDate.getFullYear().toString().substr(-2); // Get last two digits of the year
  
  const formattedDate = `${day}-${month}-${year}`;

  const [timeLeft, setTimeLeft] = useState({
    unit: 'days',
    value: 0,
    subValue: 0,
    subUnit: 'hours',
    range: 33,
  });

  useEffect(() => {
    const today = moment();
    const expiry = moment(ExpiryDate);

    const diffDays = expiry.diff(today, 'days');
    const diffWeeks = Math.floor(moment.duration(expiry.diff(today)).asWeeks());
    const diffMonths = expiry.diff(today, 'months');

    let unit = 'days';
    let value = diffDays;
    let subValue = expiry.diff(today, 'hours'); // Get the hours for days
    let subUnit = 'hours';
    let range = 33;

    if (diffDays > 30) {
      unit = 'weeks';
      value = diffWeeks;
      subValue = diffDays; // Get the days for weeks
      subUnit = 'days';
      range = 52;
    }
    if (diffWeeks > 6) {
      unit = 'months';
      value = diffMonths;
      subValue = diffWeeks; // Get the weeks for months
      subUnit = 'weeks';
      range = 12;
    }

    setTimeLeft({ unit, value, subValue, subUnit, range });
  }, [ExpiryDate]);

  return (
    <div className="countdown-container">
      <div className="clock-icon" />
      <div className="countdown-title">Expiring on: {formattedDate}</div>
      <div className="countdown-title">Time till renewal</div>
      <div className="time-left">{timeLeft.value} {timeLeft.unit}</div>
      {timeLeft.subUnit == 'hours' && timeLeft.subValue <= 72 && (
        <div className="sub-time-left">{timeLeft.subValue} {timeLeft.subUnit}</div>
      )}
      {timeLeft.subUnit == 'days' && (
        <div className="sub-time-left">{timeLeft.subValue} {timeLeft.subUnit}</div>
      )}
      <div className={`${timeLeft.range === 12 ? 'circles-container-12' : timeLeft.range === 52 ? 'circles-container-52' : 'circles-container-33'}`}>
        {Array.from({ length: timeLeft.range }, (_, index) => (
          <div
            key={index}
            className={`circle-countdown ${index < timeLeft.value ? 'green-countdown' : 'grey-countdown'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Countdown;
