import React, { useState } from 'react';
import './RiskAdviceComponent.css';

const risks = [
  {
    title: "Underinsurance Risk",
    content: "There is a risk of underinsurance if the insurance coverage is not sufficient to cover the total potential losses. Underinsuring can lead to significant financial shortfall in the event of a claim, as the insurance payout may not cover all damages fully."
  },
  {
    title: "Exclusions & Limitations",
    content: "The policy may not cover certain types of risks such as natural disasters, acts of war, or specific damages like wear and tear. It is imperative to review all policy exclusions and limitations to understand the coverages completely."
  },
  {
    title: "Premium Variability",
    content: "Insurance premiums may vary based on policy renewals, changes in risk profile, or insurance provider policies. It's important to review the factors that might influence premium costs annually."
  },
  {
    title: "Claims Rejection",
    content: "Claims may be rejected if they do not meet the policy conditions, or if the claimant fails to provide accurate and complete information during the claims process."
  },
  {
    title: "Policy Lapse",
    content: "A policy may lapse if premiums are not paid on time. A lapsed policy results in loss of coverage, which means no claims can be made for events occurring during the lapse period."
  },
  {
    title: "Legal and Regulatory Compliance",
    content: "Failure to comply with legal and regulatory requirements can lead to penalties, and may also affect the validity of the insurance coverage."
  },
  {
    title: "Contractual Obligations",
    content: "The policyholder must adhere to all contractual obligations as failure to do so may lead to the insurer denying a claim or cancelling the policy."
  },
  {
    title: "Dependence on 3rd Party Information",
    content: "Insurance decisions often depend on information provided by third parties, which may not always be reliable. Inaccuracies can lead to inappropriate coverage."
  },
  {
    title: "Market Conditions",
    content: "Economic and market conditions can affect the terms and availability of insurance. Market volatility may influence the cost and terms of coverage."
  },
  {
    title: "Technological Risks",
    content: "Technological failures, such as data breaches or cyber-attacks, are generally not covered by standard insurance policies and require specific cyber insurance coverage."
  },
  {
    title: "Natural Disasters",
    content: "Natural disasters such as floods, earthquakes, and hurricanes may not be covered under standard policies. Special endorsements or policies may be necessary for such coverage."
  },
  {
    title: "Health Risk Exclusions",
    content: "Health insurance policies may exclude certain pre-existing conditions or specific health risks, limiting the policyholder's ability to claim for these conditions."
  },
  {
    title: "Investment Risks",
    content: "Investment-linked insurance products carry inherent risks associated with the underlying investments. The value of the insurance policy may fluctuate based on the performance of these investments."
  }
];

const RiskList = ({ onAddRisk }) => {
  return (
    <div className="risk-list">
      {risks.map((risk, index) => (
        <div key={index} className="risk-item">
          <div>{risk.title}</div>
          <div>
            <button onClick={() => onAddRisk(risk)}>+</button>
          </div>
        </div>
      ))}
    </div>
  );
};

const TextBox = ({ text, setText }) => {
  return (
    <textarea
      className="text-box"
      value={text}
      onChange={(e) => setText(e.target.value)}
      style={{ height: '100%', overflowY: 'auto' }}
    />
  );
};

const RiskAdviceComponent = () => {
  const [text, setText] = useState('');

  const handleAddRisk = (risk) => {
    const newText = `${text}\n\n${risk.title}\n${risk.content}`;
    setText(newText);
  };

  return (
    <div className="risk-advice-section">
      <div className="risk-advice-component">
        <div className="risk-advice-component-top">
          <div className='risk-advice-component-label' >
            <span><strong>Key Risks</strong></span>
          </div>
        </div>
        <div className="risk-advice-component-row">
          <RiskList onAddRisk={handleAddRisk} />
          <TextBox text={text} setText={setText} />
        </div>
      </div>
    </div>
  );
};

export default RiskAdviceComponent;
