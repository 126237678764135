import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const LifeContext = createContext();

export const LifeProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial life insurance state
  const initialLifeInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        financialProtection: false, // Objective for financial protection
        incomeReplacement: false, // Objective for income replacement
        estatePlanning: false, // Objective for estate planning
      },
      goals: {
        coverageAmount: 0, // Goal for coverage amount in currency
        policyTerm: 0, // Goal for policy term in years
        beneficiaryDesignation: '', // Goal for beneficiary designation
      },
    },
    situation: {
      personalDetails: {
        name: '',
        gender: '',
        dateOfBirth: '',
        occupation: '',
      },
      healthDetails: {
        smoker: false,
        preExistingConditions: '',
        lifestyleChoices: '',
      },
    },
    modules: {
      options: {
        termLifeInsurance: false,
        wholeLifeInsurance: false,
        universalLifeInsurance: false,
        criticalIllnessRider: false,
        accidentalDeathRider: false,
        disabilityIncomeRider: false,
      },
    },
  };

  // Initialize the filters state using the initialLifeInsuranceState
  const [filters, setFilters] = useState(initialLifeInsuranceState);

  return (
    <LifeContext.Provider value={{ filters, setFilters }}>
      {children}
    </LifeContext.Provider>
  );
};
