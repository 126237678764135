import React from 'react';
import ProductHighlightSummary from './ProductHighlightSummary';
import './ProductHighlights.css';

const ProductHighlights = () => {
  return (
    <div className="highlights-page-container">
      <div className="highlights-page-sub-container">
        <ProductHighlightSummary />
      </div>
    </div>
  );
};

export default ProductHighlights;
