export const forgotPassword = async (email) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/forgot-password", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: email }),
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || "Failed to reset password - please try again later.");
        }

        const data = await response.json();
        return data.message || "Reset password. Please check your email.";
    } catch (error) {
        throw new Error(error.message || "Failed to reset password - please try again later.");
    }
};

export const logoutHandler = async (userContext, setUserContext) => {
    
    
    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        }

        setUserContext((oldValues) => ({
            ...oldValues,
            details: undefined,
            token: null,
        }));

        window.localStorage.setItem("logout", Date.now());
        return true;
    } catch (error) {
        console.error('Error during logout:', error);
        return false;
    }
};

/**
 * Function to add an email to the waitlist.
 *
 * @param {string} email - The email to be added to the waitlist.
 * @param {boolean} isBroker - Indicates if the user is an insurance broker.
 * @returns {Promise} - The response from the server as a promise.
 */
 export const addToWaitlist = async (email, isBroker) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}users/api/waitlist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, isBroker }), // Send both email and isBroker as JSON
      });
  
      return response.json(); // Parse and return the JSON response
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      throw error; // Throw the error so it can be handled by the caller
    }
  };
  