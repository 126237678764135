import React, { useState, useContext, useEffect } from 'react';
import './DynamicTable.css';
import TableRow from './TableRow';
import LightningBolt from './components/LightningBolt';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { CountryContext } from '../../context/CountryContext';
import { useGlobalContext } from '../../context/GlobalContext';

const DynamicTable = ({ strengths, weaknesses, onItemDrag, onItemRemove, onClearAllRows, initialRowCount }) => {
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { policyType } = useContext(PolicyTypeContext);
  const { country } = useContext(CountryContext);
  const { activeDocument } = useGlobalContext();

  const [columns, setColumns] = useState([
    'Current Policy',
    'Option 1 - Recommended',
  ]);
  const [rows, setRows] = useState(new Array(initialRowCount).fill({}));
  const [isEditMode, setIsEditMode] = useState(false);
  const [draggedRow, setDraggedRow] = useState(null);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [editingColumnIndex, setEditingColumnIndex] = useState(null);
  const [showClearAllModal, setShowClearAllModal] = useState(false);
  const [toggledHeaders, setToggledHeaders] = useState(false);

  const addColumn = () => {
    setColumns([...columns, `Option ${columns.length - 1}`]);
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const clearAllRows = () => {
    setRows(new Array(initialRowCount).fill({}));
    setShowClearAllModal(false);
    onClearAllRows(); // Reset inactive items in parent component
  };

  const handleSuggestions = () => {
    const updatedRows = [];
    const filteredStrengths = strengths.filter((_, index) => index % 2 === 0);
    const filteredWeaknesses = weaknesses.filter((_, index) => index % 2 !== 0);
    for (let i = 0; i < initialRowCount / 2; i++) {
      if (i < filteredStrengths.length) {
        updatedRows.push(filteredStrengths[i]);
      }
      if (i < filteredWeaknesses.length) {
        updatedRows.push(filteredWeaknesses[i]);
      }
    }
    while (updatedRows.length < initialRowCount) {
      updatedRows.push({});
    }
    setRows(updatedRows);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    let item;
    try {
      item = JSON.parse(e.dataTransfer.getData('application/json'));
    } catch (error) {
      console.error('Error parsing JSON data:', error);
      return;
    }

    const updatedRows = [...rows];
    if (draggedRow !== null) {
      updatedRows.splice(index, 0, draggedRow);
    } else if (Object.keys(updatedRows[index]).length === 0) {
      updatedRows[index] = item;
      onItemDrag(item.key);  // Inform parent about the item drag
    }
    setRows(updatedRows);
    setDraggedRow(null);
    setDraggedRowIndex(null);
    setPlaceholderIndex(null);
    setIsDragging(false);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setPlaceholderIndex(index);
  };

  const handleDragStart = (index) => {
    setDraggedRow(rows[index]);
    setDraggedRowIndex(index);
    setRows(rows.filter((_, i) => i !== index));
    setIsDragging(true);
  };

  const handleDragEnter = (index) => {
    setPlaceholderIndex(index);
  };

  const handleDragEnd = () => {
    const updatedRows = [...rows];
    if (draggedRow !== null) {
      if (placeholderIndex !== null) {
        updatedRows.splice(placeholderIndex, 0, draggedRow);
      } else if (draggedRowIndex !== null) {
        updatedRows.splice(draggedRowIndex, 0, draggedRow);
      } else {
        updatedRows.push(draggedRow);
      }
    }
    setRows(updatedRows);
    setDraggedRow(null);
    setDraggedRowIndex(null);
    setPlaceholderIndex(null);
    setIsDragging(false);
  };

  const handleRemoveRow = (index) => {
    const removedRow = rows[index];
    const updatedRows = rows.filter((_, i) => i !== index);
    updatedRows.push({});
    setRows(updatedRows);
    onItemRemove(removedRow.key);  // Inform parent about the item removal
  };

  const handleDoubleClick = (index) => {
    setEditingColumnIndex(index);
  };

  const handleChange = (e, index) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = e.target.value;
    setColumns(updatedColumns);
  };

  const handleBlur = () => {
    setEditingColumnIndex(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  const removeColumn = (index) => {
    const updatedColumns = columns.filter((_, i) => i !== index);
    const updatedRows = rows.map(row => {
      const updatedRow = { ...row };
      delete updatedRow[columns[index]];
      return updatedRow;
    });
    setColumns(updatedColumns);
    setRows(updatedRows);
  };

  const addMoreRows = () => {
    setRows([...rows, ...new Array(5).fill({})]);
  };

  const removeEmptyRows = () => {
    const updatedRows = rows.filter(row => Object.keys(row).length > 0);
    while (updatedRows.length < initialRowCount) {
      updatedRows.push({});
    }
    setRows(updatedRows);
  };

  const renderSubCoverage = (coverage) => {
    let cellContent = null;
    let cellClass = '';
    if (typeof coverage === 'object') {
      if (coverage) {
        if (coverage.excess !== "" && coverage.excess !== "0" && coverage.limit !== "" && coverage.limit !== "0") {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="sub-coverage-indicator-excess">
                Excess: <span>{formatCurrency(coverage.excess)}</span>
              </div>
              <div className="sub-coverage-indicator-limit">
                Limit: <span>{formatCurrency(coverage.limit)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (coverage.excess !== "" && coverage.excess !== "0") {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="sub-coverage-indicator-excess">
                Excess: <span>{formatCurrency(coverage.excess)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (coverage.limit !== "" && coverage.limit !== "0") {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="sub-coverage-indicator-limit">
                Limit: <span>{formatCurrency(coverage.limit)}</span>
              </div>
            </div>
          );
          cellClass = 'cell-yes-included';
        } else if (coverage.included === true) {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="included-box">&#10003;</div>
            </div>
          );
          cellClass = 'cell-yes-included-only';
        } else if (coverage.optional === true) {
          cellContent = (
            <div className="cell-yes-included-box">
              <div className="sub-coverage-indicator-optional">Optional</div>
            </div>
          );
          cellClass = 'cell-yes-included-only';
        } else {
          cellContent = (
            <div className="cell-yes-included-box">
              <div>&times;</div>
            </div>
          );
          cellClass = 'cell-no';
        }
      } else {
        cellContent = (
          <div className="cell-yes-included-box">
            <div>&times;</div>
          </div>
        );
        cellClass = 'cell-no';
      }
    } else {
      cellContent = (
        <div className="cell-yes-included-box">
          <div>&times;</div>
        </div>
      );
      cellClass = 'cell-no';
    }

    return { content: cellContent, className: cellClass };
  };

  const formatCurrency = (s) => {
    if ((s.match(/\$/g) || []).length === 2) {
      return 'Check policy';
    }

    try {
      const number = parseFloat(s);
      if (isNaN(number)) {
        return s;
      }
      return `$${number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    } catch (error) {
      return s;
    }
  };

  const toggleHeaderDetails = () => {
    setToggledHeaders(!toggledHeaders);
  };

  const getPolicyDetails = (policy) => {
    if (!policy) return null;

    return (
      <div className="policy-details">
        <div>{policy.policyNumber}</div>
        <div>{policy.policyType}</div>
        <div>{policy.policyProvider}</div>
        <div>{new Date(policy.periodOfInsuranceEnd).toLocaleDateString()}</div>
        <div>
          <a href={policy.filename} target="_blank" rel="noopener noreferrer">{policy.filename}</a>
        </div>
      </div>
    );
  };

  const getPolicyHeaders = () => {
    return (
      <div className="policy-details">
        <div><strong>Policy Name:</strong></div>
        <div><strong>Policy Type:</strong></div>
        <div><strong>Policy Provider:</strong></div>
        <div><strong>Expiration Date:</strong></div>
        <div><strong>Link:</strong></div>
      </div>
    );
  };

  useEffect(() => {
    handleSuggestions();
  }, []);

  return (
    <div className="dynamic-table-container">
      <div className="dynamic-table-buttons">
        <button className="dynamic-table-button" onClick={addColumn}>Add Comparison</button>
        <button
          className={`dynamic-table-button ${isEditMode ? 'green' : ''}`}
          onClick={toggleEditMode}
        >
          {isEditMode ? 'Editing' : 'Edit'}
        </button>
        <button className="dynamic-table-button" onClick={() => setShowClearAllModal(true)}>Clear All</button>
        <button className="dynamic-table-button" onClick={handleSuggestions}>Suggestions<LightningBolt/></button>
      </div>
      {showClearAllModal && (
        <div className="clear-all-modal">
          <div className="clear-all-modal-content">
            <p>Are you sure you want to clear all rows?</p>
            <button className="modal-button" onClick={clearAllRows}>OK</button>
            <button className="modal-button" onClick={() => setShowClearAllModal(false)}>Cancel</button>
          </div>
        </div>
      )}
      <div className="dynamic-table-header">
        <div className="dynamic-table-column"></div>
        {columns.map((col, index) => (
          <div
            className="dynamic-table-column"
            key={index}
            onClick={toggleHeaderDetails}
            onDoubleClick={() => handleDoubleClick(index)}
          >
            <div className='dynamic-table-column-header'>
              {editingColumnIndex === index ? (
                <input
                  type="text"
                  value={columns[index]}
                  onChange={(e) => handleChange(e, index)}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  autoFocus
                />
              ) : (
                <>
                  {col}
                  <div className="dynamic-table-header-info" onClick={() => toggleHeaderDetails()}>i</div>
                  {isEditMode && index > 0 && (
                    <button
                      className="remove-column-button"
                      onClick={() => removeColumn(index)}
                    >
                      -
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {toggledHeaders && (
        <div className="dynamic-table-header">
          <div className="dynamic-table-column">{getPolicyHeaders()}</div>
          {columns.map((col, index) => (
            <div className="dynamic-table-column">
              {getPolicyDetails(activeDocument)}
            </div>
          ))}
        </div>
      )}
      {rows.map((row, index) => (
        <React.Fragment key={index}>
          {isDragging && placeholderIndex === index && <div className="table-row-placeholder"></div>}
          <TableRow
            row={row}
            columns={columns}
            isEditMode={isEditMode}
            onRemove={() => handleRemoveRow(index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragStart={() => handleDragStart(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragEnd={handleDragEnd}
            renderSubCoverage={renderSubCoverage}
            masterPolicies={masterPolicies}
            policyType={policyType}
            country={country}
          />
        </React.Fragment>
      ))}
      <div className="dynamic-table-footer">
        <span className="footer-text" onClick={addMoreRows}>Add more rows...</span>
        <span className="footer-text" onClick={removeEmptyRows}>Remove empty rows...</span>
      </div>
    </div>
  );
};

export default DynamicTable;
