import React from 'react';
import { Handle } from 'react-flow-renderer';

const CustomNode = ({ data}) => {
  return (
    <div className="custom-node">
      <div className="custom-node-title">
        <strong>{data?.label}</strong>
      </div>
      <div className="custom-node-content">
        {data?.type === 'checkbox' && data?.options.map((option, index) => (
          <div key={index}>
            <input type="checkbox" id={`checkbox-${index}`} name={data?.name} value={option} />
            <label htmlFor={`checkbox-${index}`}>{option}</label>
          </div>
        ))}
        {data?.type === 'dropdown' && (
          <select name={data?.name}>
            {data?.options.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        )}
        {data?.type === 'date' && <input type="date" name={data?.name} />}
        {data?.type === 'number' && <input type="number" name={data?.name} />}
        {data?.type === 'textarea' && <textarea name={data?.name}></textarea>}
      </div>
      <Handle
        type="target"
        position="left"
        id="left"
        style={{ top: '50%', background: '#555' }}
      />
      <Handle
        type="source"
        position="right"
        id="right"
        style={{ top: '50%', background: '#555' }}
      />
    </div>
  );
};

export default CustomNode;
