import React from 'react';
import './PDFFooter.css';

const PDFFooter = () => {
    return (
        <div className="advice-pdf-footer">
            <div className="advice-pdf-footer-left">
                <div className="advice-pdf-footer-section">
                    <h4>Business Information</h4>
                    <p><strong>Company Name:</strong> XYZ Insurance Ltd</p>
                    <p><strong>Address:</strong> 1234 Main St, Auckland, NZ</p>
                    <p><strong>Phone:</strong> (09) 123-4567</p>
                    <p><strong>Email:</strong> info@xyzinsurance.co.nz</p>
                </div>
            </div>
            <div className="advice-pdf-footer-right">
                <div className="advice-pdf-footer-section">
                    <h4>Terms & Conditions</h4>
                    <p>All insurance policies are subject to the terms and conditions stated in the policy document. Please read the policy document carefully to understand the coverage and exclusions.</p>
                </div>
                <div className="advice-pdf-footer-section">
                    <h4>Disclaimers</h4>
                    <p>The information provided in this advice is based on the details you have provided. We recommend reviewing your policy regularly to ensure it continues to meet your needs.</p>
                </div>
                <div className="advice-pdf-footer-section">
                    <h4>Complaints</h4>
                    <p>If you have any complaints, please contact our customer service team at complaints@xyzinsurance.co.nz. We are committed to resolving any issues promptly and fairly.</p>
                </div>
                <div className="advice-pdf-footer-section">
                    <h4>Compliance</h4>
                    <p>XYZ Insurance Ltd is a registered financial service provider in New Zealand, and we comply with all NZ financial regulations and standards.</p>
                </div>
            </div>
        </div>
    );
};

export default PDFFooter;
