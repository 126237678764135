import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import WaitingDiv from '../../WaitingDiv';
import './PDFHighlighter.css'

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PDFHighlighter = ({ url, search }) => {
  const [pdfDoc, setPdfDoc] = useState(null);
  const [isRendering, setIsRendering] = useState(false); // Track if rendering is in progress
  const [searchTerms, setSearchTerms] = useState(search && search.length > 0 ? search : []); // Initialize with search if not empty
  const [matches, setMatches] = useState([]);
  const [matchedSearchTerm, setMatchedSearchTerm] = useState([]);
  const [pagesRendered, setPagesRendered] = useState(false); // Track if pages are rendered
  const containerRef = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(true); // Flag to control when to call search
  const [comments, setComments] = useState([]); // Store comments in state
  const [showCommentsPanel, setShowCommentsPanel] = useState(false); // Track visibility of comments panel
  const PAGE_MARGIN = 50; // Add a constant for the page margin
  const [zoomLevel, setZoomLevel] = useState(1);
  const [scrollHeight, setScrollHeight] = useState(0); // Track the full scrollable height
  const wrapperRef = useRef(null); // Reference for the wrapper to control scrolling
  const commentsPanelRef = useRef(null); // Reference for the comments panel

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => (prevZoom > 0.2 ? prevZoom - 0.1 : prevZoom)); // Prevent zoom out too much
  };

  const toggleCommentsPanel = () => setShowCommentsPanel(!showCommentsPanel); // Toggle comments panel

  useEffect(() => {
    if (!url) {
      console.error("URL is required to load the PDF document.");
      return;
    }

    const loadingTask = pdfjsLib.getDocument({ url });

    loadingTask.promise
      .then((pdf) => {
        setPdfDoc(pdf); // This will trigger a re-render and call the next useEffect
      })
      .catch((err) => {
        console.error("Failed to load the PDF document:", err); // Log the error if loading fails
      });
  }, [url]);

  useEffect(() => {
    if (!pdfDoc || isRendering) return; // Exit if the PDF document is not yet loaded or if rendering is already in progress

    const renderPagesSequentially = async () => {
      setIsRendering(true); // Lock rendering
      const pagesContainer = containerRef.current;
      pagesContainer.innerHTML = ''; // Clear any previous content

      // Sequentially render each page
      for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
        const page = await pdfDoc.getPage(pageNum);

        // Create a canvas element for each page
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set viewport with no scaling
        const viewport = page.getViewport({ scale: 1 }); // No scaling here
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Create page wrapper and canvas wrapper
        const pageWrapper = document.createElement('div');
        pageWrapper.className = 'page-container';
        pageWrapper.style.position = 'relative';
        pageWrapper.style.display = 'flex';
        pageWrapper.style.justifyContent = 'center';
        pageWrapper.style.paddingBottom = `${PAGE_MARGIN}px`; // Add margin between pages
        pageWrapper.style.paddingTop = `${PAGE_MARGIN}px`;
        pageWrapper.style.backgroundColor = '#f9f9f9';

        const canvasWrapper = document.createElement('div');
        canvasWrapper.className = 'canvas-wrapper';
        canvasWrapper.style.position = 'relative';
        canvasWrapper.style.display = 'inline-block'; // Ensure canvas and highlights are centered

        canvasWrapper.appendChild(canvas);
        pageWrapper.appendChild(canvasWrapper);
        pagesContainer.appendChild(pageWrapper);

        // Render the page and wait for the render to finish
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise; // Wait for the page to render
      }

      // Once all pages are rendered, set the flag to true
      setPagesRendered(true);
      setIsRendering(false); // Unlock rendering

      // Set scroll height dynamically based on the content
      if (containerRef.current) {
        setScrollHeight(containerRef.current.scrollHeight); // Get the scrollable height of the container
      }
    };

    renderPagesSequentially(); // Call the function to render pages sequentially
  }, [pdfDoc]);

  // Add synchronized scrolling
  const handleScroll = () => {
    const wrapper = wrapperRef.current;
    const scrollPercentage = wrapper.scrollTop / (wrapper.scrollHeight - wrapper.clientHeight);

    // Scroll both viewer and comments panel together
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPercentage * (containerRef.current.scrollHeight - containerRef.current.clientHeight);
    }
    if (commentsPanelRef.current) {
      commentsPanelRef.current.scrollTop = scrollPercentage * (commentsPanelRef.current.scrollHeight - commentsPanelRef.current.clientHeight);
    }
  };
  
  useEffect(() => {
    if (containerRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [wrapperRef.current, containerRef.current, commentsPanelRef.current]);

  // Function to parse and store multiple search terms
  const handleSearchInput = (e) => {
    setTriggerSearch(false); // Set trigger to true after input change
    const terms = e.target.value // Keep the original value
      .split(',') // Split by commas, not spaces
      .map(term => term.trim()) // Trim whitespace around each term
      .filter(term => term.length > 0); // Filter out empty terms
    setSearchTerms(terms);
  };

  const handleSearch = () => {
    setTriggerSearch(true); // Set trigger to true after input change
  };

  const searchInDocument = async () => {
    let results = [];
    let foundTerms = [];

    if (!pdfDoc) {
      console.error("No PDF document found.");
      return;
    }

    if (!searchTerms || searchTerms?.length === 0) {
      console.error("No search terms found.");
      return;
    }

    if (!pdfDoc && !pagesRendered) {
      console.error("Pages not loaded");
      return;
    }

    console.log("Starting search in document...");
    console.log(`Number of pages in document: ${pdfDoc.numPages}`);
    console.log("Search terms:", searchTerms);

    setIsSearching(true);

    // Loop through each search term
    for (let i = 0; i < searchTerms.length; i++) {
      const searchTerm = searchTerms[i];

      // Normalize search term (remove special characters, make lowercase)
      const normalizedSearchTerm = searchTerm.replace(/[^\w]/g, '').replace(/\s+/g, ' ').toLowerCase();
      console.log(`Searching for term "${searchTerm}" (normalized as "${normalizedSearchTerm}")`);

      // Loop through each page of the document
      for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
        console.log(`Processing page ${pageNum}...`);
        const page = await pdfDoc.getPage(pageNum);
        const textContent = await page.getTextContent();

        let matchBuffer = '';  // Buffer to hold partial matches across items
        let matchedItems = []; // To hold all items contributing to the match
        let isMatching = false; // Flag to start collecting only after the first match

        // Iterate through text items on the page
        for (let index = 0; index < textContent.items.length; index++) {
          const item = textContent.items[index];
          const itemText = item.str;

          // Normalize the current item text
          const normalizedItemText = itemText.replace(/[^\w]/g, '').replace(/\s+/g, ' ').toLowerCase();
          console.log(`Item ${index}: "${itemText}" (normalized as "${normalizedItemText}")`);
          console.log(`isMatching ${isMatching}`);

          if (isMatching) {
            // Collect items only after a match has started
            matchBuffer += normalizedItemText;
            matchedItems.push(item);
            console.log(`Added item ${index} to matchBuffer. Current matchBuffer: "${matchBuffer}"`);
          }

          console.log(`normalizedItemText ${normalizedItemText} & normalizedSearchTerm ${normalizedSearchTerm}`);
          // Check if the normalized item contains the search term
          if (!isMatching && normalizedItemText &&
            (normalizedItemText.includes(normalizedSearchTerm) || normalizedSearchTerm.includes(normalizedItemText))) {
            console.log(`First partial match for "${searchTerm}" found in item ${index} on page ${pageNum}`);
            isMatching = true; // Start collecting items into buffer from this point
            matchBuffer += normalizedItemText;
            matchedItems.push(item);  // Collect the first matched item
            console.log(`Starting to collect matched items. Current matchBuffer: "${matchBuffer}"`);
          }

          // If we have a full match in the buffer, collect the result
          if (isMatching && matchBuffer.includes(normalizedSearchTerm)) {
            console.log(`Full match for "${searchTerm}" found on page ${pageNum}`);

            // Add matched items to results
            matchedItems.forEach((matchedItem, idx) => {
              console.log(`Matched item ${idx} on page ${pageNum}: "${matchedItem.str}"`);
              if (idx === 0) {
                foundTerms.push({
                  item: matchedItem,
                  transform: matchedItem.transform,
                  width: matchedItem.width,
                  height: matchedItem.height,
                  pageNum,
                  matchedText: matchedItem.str,  // Original text content
                  term: searchTerm
                });
              }
              results.push({
                item: matchedItem,
                transform: matchedItem.transform,
                width: matchedItem.width,
                height: matchedItem.height,
                pageNum,
                matchedText: matchedItem.str,  // Original text content
                term: searchTerm
              });
            });

            // Reset the buffer and matchedItems after processing a match
            console.log(`Resetting matchBuffer and matchedItems after match on page ${pageNum}`);
            matchBuffer = '';
            matchedItems = [];
            isMatching = false;  // Stop collecting after the match is found
          }
        }
        console.log(`Finished processing page ${pageNum}`);
      }
      console.log(`Finished searching for term "${searchTerm}"`);
    }

    setIsSearching(false);
    console.log("Search completed. Results:", results);
    setMatches(results); // Update matches with the results
    setMatchedSearchTerm(foundTerms); // Update matches with the results
  };

  const addComment = (pageNum, matchPath) => {
    const newComment = {
      pageNum,
      matchPath,
      text: '',
      id: Math.random().toString(36).substr(2, 9), // Unique ID for each comment
    };
    setComments([...comments, newComment]);
  };

  const updateCommentText = (id, newText) => {
    setComments(comments.map(comment => comment.id === id ? { ...comment, text: newText } : comment));
  };

  useEffect(() => {
    if (pdfDoc && pagesRendered && triggerSearch) {
      searchInDocument();
      setTriggerSearch(false); // Reset trigger
    }
  }, [triggerSearch, searchTerms, pdfDoc, pagesRendered]); // Runs when 'triggerSearch' or 'searchTerms' changes


  useEffect(() => {
    setSearchTerms(search);
  }, [search]); // Runs again once pages are rendered

  const clearHighlights = () => {
    // Find all elements with the class 'highlight' and remove them
    const highlights = containerRef.current.querySelectorAll('.highlight');
    highlights.forEach((highlight) => {
      highlight.remove();
    });
  };

    // Function to calculate and adjust the height of the comments panel based on zoom level
    const adjustCommentsPanelHeight = () => {
      if (containerRef.current && wrapperRef.current && commentsPanelRef.current) {
        const scaledHeight = containerRef.current.scrollHeight * zoomLevel;
        setScrollHeight(scaledHeight);
        commentsPanelRef.current.style.height = `${scaledHeight}px`; // Set the height of the comments panel
      }
    };
  
    // Effect to adjust the height when zoom level or scroll height changes
    useEffect(() => {
      adjustCommentsPanelHeight(); // Call this function on zoom change or content change
      console.log("AAAA", scrollHeight);
    }, [zoomLevel, scrollHeight]);

  const highlightMatches = () => {
    if (!containerRef.current || !pagesRendered) {
      console.error("Pages are not fully rendered yet.");
      return;
    }

    // Clear existing highlights before adding new ones
    clearHighlights();

    matches.forEach((match) => {
      const pageContainers = containerRef.current.querySelectorAll('.page-container');

      // Ensure that the page container exists
      if (!pageContainers || !pageContainers[match.pageNum - 1]) {
        console.error(`Page container for page ${match.pageNum} not found.`);
        return;
      }

      const pageContainer = pageContainers[match.pageNum - 1];
      const canvasWrapper = pageContainer.querySelector('.canvas-wrapper'); // Select the wrapper

      const canvas = canvasWrapper.querySelector('canvas');
      if (!canvas) {
        console.error(`Canvas for page ${match.pageNum} not found`);
        return;
      }

      const context = canvas.getContext('2d');
      const transform = match.item.transform;
      const [scaleX, , , scaleY, x, y] = transform; // Extract positioning data

      // No scaling applied, directly use the x, y, width, and height values
      const adjustedX = x;
      const termWidth = match.item.width; // Use the width directly from the match item
      const termHeight = scaleY;
      const adjustedY = y + (termHeight * 0.8);;

      // Create a div to simulate the highlight
      const highlightDiv = document.createElement('div');
      highlightDiv.className = 'highlight';
      highlightDiv.style.position = 'absolute';
      highlightDiv.style.left = `${adjustedX}px`;
      highlightDiv.style.top = `${canvas.height - adjustedY}px`; // Adjust for the flipped y-coordinate
      highlightDiv.style.width = `${termWidth}px`;
      highlightDiv.style.height = `${termHeight}px`;
      highlightDiv.style.backgroundColor = 'yellow';
      highlightDiv.style.opacity = '0.5';

      // Append highlight div to the canvas-wrapper
      canvasWrapper.appendChild(highlightDiv);
    });
  };


  // Use effect to highlight matches after search results are updated
  useEffect(() => {
    if (matches.length > 0 && pagesRendered) {
      highlightMatches();
    }
  }, [matches, pagesRendered]);

  // Scroll to the first highlighted div on the page when a search result is clicked
  const handleResultClick = (pageNum) => {
    const pageContainers = containerRef.current.querySelectorAll('.page-container');

    if (pageContainers && pageContainers[pageNum - 1]) {
      const pageContainer = pageContainers[pageNum - 1]; // Get the correct page container

      // Find the first highlight inside the pageContainer (specific page)
      const firstHighlight = pageContainer.querySelector('.highlight');

      const pdfContainer = document.querySelector('.pdf-viewer-wrapper'); // Target the scrollable container

      if (firstHighlight) {
        // Scroll smoothly to the first highlight within the pageContainer inside the pdf-highlighter-container-pdf
        const scrollPosition = firstHighlight.offsetTop + pageContainer.offsetTop - pdfContainer.offsetTop;
        pdfContainer.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      } else {
        // If no highlight is found, scroll to the top of the page as a fallback
        const pageScrollPosition = pageContainer.offsetTop - pdfContainer.offsetTop;
        pdfContainer.scrollTo({
          top: pageScrollPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className='pdf-highlighter-page'>
      <div className='search-results-menu'>
        <div>Relevant Terms</div>
        <div style={{ fontSize: '8pt' }}>{matchedSearchTerm.length} occurrences</div>
        {isSearching || isRendering ? (
          <div style={{ paddingTop: '30px' }}>
            <WaitingDiv />
          </div>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {matchedSearchTerm
              .sort((a, b) => a.pageNum - b.pageNum) // Sort the array by pageNum in ascending order
              .map((match, index) => (
                <li key={index} className="pdf-match-item" onClick={() => handleResultClick(match.pageNum)}>
                  <p className='pdf-match'>{match.term}</p>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <p className='pdf-page-number'>P.{match.pageNum}</p>
                    <button onClick={() => addComment(match.pageNum, match.term)}>Comment</button>
                  </div>
                </li>
              ))}
          </ul>
        )}
        <div className='terms-search'>
          <input
            type="text"
            placeholder="Search Terms"
            onChange={handleSearchInput}
          />
          <button onClick={handleSearch} className="terms-search-button">
            Search
          </button>
        </div>
      </div>
      <div className='pdf-highlighter-container'>
        <div className="pdf-highlighter-container-pdf">
          <div className="pdf-viewer-wrapper" ref={wrapperRef}>
            <div className="pdf-viewer"
              style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'center top' }}
              ref={containerRef}>
              {/* PDF Page Containers will be dynamically appended here */}
            </div>

            {/* Comments Panel */}
            <div className={`pdf-comments-panel ${showCommentsPanel ? 'open' : 'closed'}`}
              style={{ height: scrollHeight === 0 ? '100%' : `${scrollHeight}px` }}
              ref={commentsPanelRef}

            >
              {comments.map((comment) => (
                <div key={comment.id} className="pdf-comment-entry">
                  <p>Page {comment.pageNum} | Terms: {comment.matchPath.slice(0, 15)}</p>
                  <textarea
                    value={comment.text}
                    onChange={(e) => updateCommentText(comment.id, e.target.value)}
                    placeholder="Enter your comment"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pdf-hamburger-menu" onClick={toggleCommentsPanel}>
          &#9776;
        </div>
      </div>
      <div className="zoom-controls">
        <button className="zoom-button zoom-in" onClick={handleZoomIn}>+</button>
        <button className="zoom-button zoom-out" onClick={handleZoomOut}>-</button>
      </div>
    </div>
  );
};

export default PDFHighlighter;
