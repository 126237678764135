import React, { useState } from 'react';
import AdviceHeader from '../AdviceHeader';
import YourPremiumTable from './YourPremiumTable';

const ProposalSummary = ({ isOpen, toggleSection, proposal, updateProposal, pdf }) => {
    const id = 'summary';
    const [completed, setCompleted] = useState(false);

    const costs = {
        'Home Premium': 500,
        'Landlord Premium': 300,
        'Contents': 200,
        'Vehicle': 400,
    };

    const getCostBreakdown = (amount) => ({
        amount,
        weekly: amount / 4,
        fortnightly: amount / 2,
        monthly: (amount * 12) / 52 * 4,
        yearly: amount * 12,
    });

    const calculatedCosts = Object.keys(costs).reduce((acc, key) => {
        acc[key] = getCostBreakdown(costs[key]);
        return acc;
    }, {});

    return (
        <div className="form-section">
            <AdviceHeader
                id={id}
                toggleSection={toggleSection}
                title={'Summary'}
                tooltip={'A clear definition of what the advice covers and any limitations. This might include the types of insurance products discussed and any specific requests or concerns addressed by the client.'}
                completed={completed}
                pdf={pdf}
                isOpen={isOpen}
            />
            {isOpen && (
                <div>
                    <YourPremiumTable setCompleted={setCompleted} initialCosts={calculatedCosts} />
                </div>
            )}
        </div>
    );
};

export default ProposalSummary;
