import React, { useContext, useEffect } from "react";
import LandingContainer from '../components/LandingContainer';
import ProductShowcase from '../components/landing/ProductShowcase';
import ProductHighlights from '../components/product_highlights/ProductHighlights';
import ProductHighlightDatapoints from '../components/product_highlights/ProductHighlightDatapoints';
import GetStartedBanner from '../components/GetStartedBanner';
import { blogs } from '../components/BlogSummary';
import BlogCarousel from '../components/BlogCarousel';
import GeoPopup from '../components/GeoPopup';
import ProductCarousel from "../components/landing/ProductCarousel";

import './LandingPage.css';

function LandingPage() {
  
  // Effect to set the document title when the component mounts
  useEffect(() => {
    document.title = 'PolicyCheck';
  }, []);

  return (
    <div className="landing-page-container">
      <GeoPopup />
      <LandingContainer />
      <ProductHighlights />
      <ProductHighlightDatapoints />
      <ProductShowcase/>
      <ProductCarousel />
      <GetStartedBanner height='450px'/>
      <BlogCarousel blogs={blogs} />
      <GetStartedBanner />
    </div >
  );
}

export default LandingPage;
