import React, { useEffect, useState, useContext, useRef, forwardRef } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { FiltersContext } from '../../context/FiltersContext';
import { useCollapse } from '../../context/CollapseContext';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import Tooltip from '../../components/Tooltip';
import Tiles from '../../components/Tiles';
import PolicyScore from '../../components/PolicyScore';
import Popup from '../../components/Popup';
import InfoButton from '../../components/InfoButton';
import InsuranceForm from '../../components/InsuranceForm';
import ActivePolicy from "../../components/ActivePolicy";
import ComparisonFilter from '../../components/ComparisonFilter';
import './Comparison.css';

const Comparison = forwardRef((props, ref) => {
  useEffect(() => {
    document.title = 'Comparison';
  }, []);
  const { policyType } = useContext(PolicyTypeContext);
  const [isHidden, setIsHidden] = useState(false);
  const { masterPolicies, setMasterPolicies } = useContext(MasterPoliciesContext);
  const [collapsedStates, setCollapsedStates] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [activePolicySelected, setActivePolicySelected] = useState(false);
  const { isCollapsed, setIsCollapsed } = useCollapse();
  const [fadeClass, setFadeClass] = useState('');
  const [policies, setPolicies] = useState([]);
  const [sortedPolicies, setSortedPolicies] = useState([]);
  const [result, setResult] = useState([]);
  const { activeDocument } = useGlobalContext();
  const [activeDocumentTempName, setActiveDocumentTempName] = useState('');
  const [isCoverageMapOpen, setIsCoverageMapOpen] = useState(false);
  const [isCoverageScoreOpen, setIsCoverageScoreOpen] = useState(false);
  const [showQuoteFilter, setShowQuoteFilter] = useState(false);
  const scrollRef = useRef(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0); // Example option count
  const [totalCount, setTotalCount] = useState(0); // Example option count
  const [expansionGiveUpStates, setExpansionGiveUpStates] = useState({});
  const [expansionGainStates, setExpansionGainStates] = useState({});
  const { globalFilters, setGlobalFilters } = useContext(FiltersContext);

  const toggleGiveUpExpansion = (policyName) => {
    setExpansionGiveUpStates(prev => ({
      ...prev,
      [policyName]: !prev[policyName]
    }));
  };

  const toggleGainExpansion = (policyName) => {
    setExpansionGainStates(prev => ({
      ...prev,
      [policyName]: !prev[policyName]
    }));
  };

  const toggleCollapse = (itemIndex) => {
    setCollapsedStates(prev => {
      const updatedStates = { ...prev };

      // Loop through each policy index and update the specified item index
      Object.keys(updatedStates).forEach(policyIndex => {
        updatedStates[policyIndex] = {
          ...updatedStates[policyIndex],
          [itemIndex]: !updatedStates[policyIndex][itemIndex]
        };
      });

      return updatedStates;
    });
  };

  useEffect(() => {
    // Trigger fade out
    setFadeClass('fade-out');

    // Set a timeout to fade in (should match CSS transition duration)
    const timeoutId = setTimeout(() => setFadeClass(''), 150);

    // Clean up timeout
    return () => clearTimeout(timeoutId);
  }, [sortedPolicies]);

  const toggleQuoteFilter = () => {
    setShowQuoteFilter(!showQuoteFilter)
    
  };


  useEffect(() => {
    if (Object.keys(globalFilters).length > 0) {
      setSelectedCount(globalFilters.filter(filter => { return filter.checked }).length);
      setTotalCount(globalFilters.length);
    }
  }, [globalFilters]);

  const toggleCoverageMapPopup = () => {
    setIsCoverageMapOpen(!isCoverageMapOpen);
  };
  const toggleCoverageScorePopup = () => {
    setIsCoverageScoreOpen(!isCoverageScoreOpen);
  };

  function comparePolicies(policyA, policyB) {
    const inPolicyBNotA = [];
    const inPolicyANotB = [];

    // Convert Mongoose documents to plain objects
    const coveragesA = policyA.coverages.toObject ? policyA.coverages.toObject() : policyA.coverages;
    const coveragesB = policyB.coverages.toObject ? policyB.coverages.toObject() : policyB.coverages;

    // Helper function to check if a coverage is included
    const isIncluded = (coverage) => coverage && coverage.included;

    // Iterate over the coverages in policy B
    for (const category in coveragesB) {
      if (coveragesB.hasOwnProperty(category)) {
        const categoryMap = coveragesB[category];
        if (typeof categoryMap === 'object' && categoryMap !== null) {
          for (const coverage in categoryMap) {
            if (categoryMap.hasOwnProperty(coverage)) {
              const details = categoryMap[coverage];
              if (isIncluded(details) &&
                !(coveragesA[category] && isIncluded(coveragesA[category][coverage]))) {
                inPolicyBNotA.push(`${coverage}`);
              }
            }
          }
        }
      }
    }

    // Iterate over the coverages in policy A
    for (const category in coveragesA) {
      if (coveragesA.hasOwnProperty(category)) {
        const categoryMap = coveragesA[category];
        if (typeof categoryMap === 'object' && categoryMap !== null) {
          for (const coverage in categoryMap) {
            if (categoryMap.hasOwnProperty(coverage)) {
              const details = categoryMap[coverage];
              if (isIncluded(details) &&
                !(coveragesB[category] && isIncluded(coveragesB[category][coverage]))) {
                inPolicyANotB.push(`${coverage}`);
              }
            }
          }
        }
      }
    }

    return { inPolicyBNotA, inPolicyANotB };
  }

  function comparePolicy(policy2) {
    if (policies && activeDocument) {
      const policy1 = policies.find(policy => policy.provider === activeDocument.policyProvider);
      if (policy1) {
        const comparisonResults = comparePolicies(policy1, policy2);
        setResult(prevResult => ({
          ...prevResult,
          [policy2.name]: comparisonResults
        }));
      }
    }
  }

  function formatCoverageName(coverageName) {
    // Add space before any uppercase letter that is either starting a new word or part of an acronym followed by a lowercase letter
    let withSpaces = coverageName.replace(/(\p{Ll})(\p{Lu})/gu, '$1 $2')
      .replace(/(\p{Lu})(\p{Lu}\p{Ll})/gu, '$1 $2');

    // Correct the capitalization and format the string
    return withSpaces.split(' ')
      .map(word => capitalizeWord(word))
      .join(' ');
  }

  function capitalizeWord(word) {
    // Check if the word is an acronym: all uppercase and more than one letter can stay as is
    if (word.toUpperCase() === word && word.length > 1) {
      return word;
    }
    // Otherwise, capitalize the first letter and lowercase the rest
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const handleInfoClick = () => {
    toggleCoverageMapPopup();
  };

  function sortPolicies(policies) {
    
    let newSortedPolicies = policies.sort((a, b) => a.score - b.score);
    
    return newSortedPolicies;
  }

  function filterPolicies(policies) {
    

    if (!policies || !globalFilters) {
      
      return;
    }

    let filteredPolicies = policies.filter(policy => {
      // Check if there is any filter where the label matches the policy provider and it's checked
      return globalFilters.some(filter => filter.label === policy.provider &&
        filter.checked &&
        policy.type === policyType
      );
    });

    

    setSortedPolicies(sortPolicies(filteredPolicies));
  }

  function movePolicy(policyName, moveUp) {
    const index = sortedPolicies.findIndex(p => p.provider === policyName);
    

    // Check if policy is found and is not the first item (for moving up) or last item (for moving down)
    if (index > -1 && ((moveUp && index > 0) || (!moveUp && index < sortedPolicies.length - 1))) {
      // Clone the sortedPolicies array to avoid direct state mutation
      const newSortedPolicies = [...sortedPolicies];

      // Determine the index to swap with: previous index if moving up, next index if moving down
      const swapIndex = moveUp ? index - 1 : index + 1;

      // Swap the policy with the determined index
      [newSortedPolicies[index], newSortedPolicies[swapIndex]] =
        [newSortedPolicies[swapIndex], newSortedPolicies[index]];

      // Update the state
      setSortedPolicies(newSortedPolicies);

      // Determine the new scroll position
      const scrollAmount = 311; // Adjust this value as needed
      const newScrollPosition = moveUp
        ? scrollRef.current.scrollLeft - scrollAmount
        : scrollRef.current.scrollLeft + scrollAmount;

      // Smooth scroll to the new position
      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });

    }
  };

  const updateCheckedForLabel = (labelToMatch, newCheckedValue) => {
    if (Object.keys(globalFilters).length > 0) {
      const updatedFilters = globalFilters.map(filter => {
        
        if (filter.label === labelToMatch) {
          return { ...filter, checked: newCheckedValue };
        }
        return filter;
      });
      
      setGlobalFilters(updatedFilters);
    }
  };

  const applyActivePolicySelected = () => {
    
    if (activePolicySelected) {
      const timer = setTimeout(() => {
        setActivePolicySelected(!activePolicySelected)
        
      }, 5000);
    } else {
      setActivePolicySelected(!activePolicySelected)
    }
  };

  useEffect(() => {
    
    if (policies.length > 0) {
      filterPolicies(policies);
    }
  }, [globalFilters]);

  useEffect(() => {
    
    if (activeDocument === undefined || (activeDocument && activeDocumentTempName !== activeDocument.filename)) {
      setPolicies(masterPolicies);
      setTrigger(true); // Set trigger here after successful API response
      setActiveDocumentTempName(activeDocument.filename);
    }
  }, [activeDocument]);

  // On new policy / active document, reload comparison
  useEffect(() => {
    
    if (policies && policies.length) {
      for (const policy of policies) {
        comparePolicy(policy);
      }
    }
    if (activeDocument !== null && activeDocument !== undefined) {
      updateCheckedForLabel(activeDocument.policyProvider, true);
    }
  }, [policies, activeDocument]);

  useEffect(() => {
    if (policies.length > 0) {
      
      setSortedPolicies(sortPolicies(policies));
    }
    setTrigger(false);
  }, [trigger]);

  useEffect(() => {
    if (policies && policies.length > 0) {
      setCollapsedStates(
        policies.reduce((acc, _, index) => {
          acc[index] = { 1: false, 2: false, 3: true, 4: true, 5: true }; // Initialize each policy with collapse state objects
          return acc;
        }, {})
      );
    }
  }, [policies]);

  useEffect(() => {
    
  }, [collapsedStates]);

  function calculatePrice(stingPrice) {
    let price = parseInt(stingPrice, 10);
    if (activeDocument.paymentFrequency === 'Weekly') {
      return price / 4;
    }
    if (activeDocument.paymentFrequency === 'Yearly') {
      return price * 12;
    }
    return price;
  }

  function calculateSavings(policy) {
    if (!activeDocument || !activeDocument.policyPrice) {
      return <span style={{ color: 'red' }}>Policy price is not available</span>;
    }

    let priceA = parseInt(activeDocument.policyPrice.replace(/[$,]/g, ''), 10);
    let priceB = parseInt(policy.demoPrice, 10);

    if (isNaN(priceA) || isNaN(priceB)) {
      return <span style={{ color: 'red' }}>Invalid price format</span>;
    }

    if (activeDocument.paymentFrequency === 'Weekly') {
      priceA = priceA / 4;
      priceB = priceB / 4;
    }
    if (activeDocument.paymentFrequency === 'Yearly') {
      priceA = priceA * 12;
      priceB = priceB * 12;
    }

    let savings = priceA - priceB;
    if (priceA > priceB) {
      return (
        <span>
          <span style={{ fontWeight: 'bold' }}>SAVE</span><br />${savings} per year
        </span>
      );
    } else {
      return (
        <span>
          <span style={{ color: 'darkgreen' }}><span style={{ fontWeight: 'bold' }}>SPEND</span><br />${priceB - priceA} more per year</span>
        </span>
      );
    }
  }


  function hasAtLeastOneNonNullValue(mapOfObjects) {
    let hasNonNull = false;
    Object.keys(mapOfObjects).forEach(key => {
      const value = mapOfObjects[key];
      if (value !== null) {
        hasNonNull = true;
      }
    });
    return hasNonNull;
  }

  useEffect(() => {
    const mirrorScroll = document.querySelector('.mirror-scroll');
    const scrollContainer = document.querySelector('.scroll-container');

    if (!mirrorScroll || !scrollContainer) {
      
      return;
    }

    const syncScroll = () => {
      scrollContainer.scrollLeft = mirrorScroll.scrollLeft;
      
      
    };

    mirrorScroll.addEventListener('scroll', syncScroll);

    return () => {
      mirrorScroll.removeEventListener('scroll', syncScroll);
    };
  }, []);

  function findReasonByMatch(policy, target) {
    function searchCoverages(coverages) {
      for (const key in coverages) {
        if (typeof coverages[key] === 'object' && coverages[key] !== null) {
          if (key === target && coverages[key].reason) {
            return coverages[key].reason;
          } else {
            const result = searchCoverages(coverages[key]);
            if (result) return result;
          }
        }
      }
    }

    return searchCoverages(policy.coverages);
  }

  function renderSortedPolicies(policies) {
    if (activeDocument !== null && activeDocument !== undefined &&
      policies !== null && policies !== undefined &&
      policies.length > 0) {
      return (
        <div className="comparison-container"
          onClick={applyActivePolicySelected}
        >
          {policies.map((policy, index, array) => (
            <div className="comparison-items-box">
              <div className={activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName ? `comparison-items-active` : `comparison-items`}>
                {(activeDocument.policyProvider === policy.provider && activePolicySelected && activeDocument.policyWording.filename === policy.fileName) && (
                  <>
                    {(index !== 0) && (
                      <button className="comparison-items-box-button-left" onClick={() => movePolicy(activeDocument.policyProvider, true)}>&lt;</button>
                    )}
                    {(index !== (array.length - 1)) && (
                      <button className="comparison-items-box-button-right" onClick={() => movePolicy(activeDocument.policyProvider, false)}>&gt;</button>
                    )}
                  </>
                )}
                <div onDoubleClick={activeDocument.policyProvider === policy.provider ? toggleCoverageScorePopup : null} className="comparison-item-score">
                  <div className={(activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName) ?
                    `active-score` : `inactive-score`}>{policy.score * 2}</div>
                </div>
                <div className={(activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName) ? `comparison-item-header-red` : `comparison-item-header`}>
                  <span style={{ fontSize: policy.provider.length > 14 ? '16pt' : '24pt' }}>{policy.provider}</span>
                </div>
                <div className="comparison-item-header-sub">
                  {policy.provider === policy.name ? policy.type : policy.name}
                  {activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName && (
                    <InfoButton onClick={handleInfoClick} />
                  )}
                </div>
                <div className="comparison-item">
                  <div className={activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName ? `comparison-price-green` : `comparison-price`}>
                    <p>{activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName ? activeDocument.policyPrice.startsWith('$') ? activeDocument.policyPrice : `$${activeDocument.policyPrice}` : `$${calculatePrice(policy.demoPrice)}`} {activeDocument.policyProvider === policy.provider ? <br /> : ''} {activeDocument.paymentFrequency}</p>

                    <p style={{ color: '#4fa658' }}>{activeDocument.policyProvider === policy.provider && activeDocument.policyWording.filename === policy.fileName ? '' : calculateSavings(policy)}</p>
                  </div>
                </div>
                {
                  activeDocument.policyWording.filename !== policy.fileName && (
                    <>
                      {result[policy.name] && result[policy.name].inPolicyBNotA && result[policy.name].inPolicyBNotA.length > 0 && (
                        <div
                          className={`comparison-item-gain-loss ${collapsedStates[index][1] ? 'comparison-item-gain-loss-grey' : ''}`}
                          style={{ height: collapsedStates[index] && collapsedStates[index][1] ? '50px' : 'auto' }}
                        >
                          <div className="comparison-item-gain-loss-header" onClick={() => toggleCollapse(1)}>
                            <div >Gain ({result[policy.name].inPolicyBNotA.length}):  </div>
                            <div className="comparison-arrow"
                              className={`${collapsedStates[index][1] ? 'comparison-arrow-black' : 'comparison-arrow-grey'}`}
                              style={{ transform: `rotate(${collapsedStates[index][1] ? 0 : 180}deg)` }}></div>
                          </div>
                          {result[policy.name] && result[policy.name].inPolicyBNotA && result[policy.name].inPolicyBNotA.length > 0 && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {result[policy.name].inPolicyBNotA.slice(0, expansionGainStates[policy.name] ? result[policy.name].inPolicyBNotA.length : 10).map((item, idx) => (
                                <Tooltip text={findReasonByMatch(policy, item)} useDoubleClick={true}>
                                  <div key={idx}><span style={{ color: 'green', fontSize: '12pt', fontWeight: 'bold' }}>+</span>
                                    &nbsp;{formatCoverageName(item)}
                                  </div>
                                </Tooltip>
                              ))}
                              {result[policy.name].inPolicyBNotA.length > 10 && (
                                <div style={{ cursor: 'pointer', color: 'green' }} onClick={() => toggleGainExpansion(policy.name)}>
                                  {expansionGainStates[policy.name] ? 'See less...' : 'See more...'}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {result[policy.name] && result[policy.name].inPolicyANotB && result[policy.name].inPolicyANotB.length > 0 && (
                        <div
                          className={`comparison-item-gain-loss ${collapsedStates[index][2] ? 'comparison-item-gain-loss-grey' : ''}`}
                          style={{ height: collapsedStates[index][2] ? '50px' : 'auto' }}

                        >
                          <div className="comparison-item-gain-loss-header" onClick={() => toggleCollapse(2)}>
                            <div >Give up ({result[policy.name].inPolicyANotB.length}): </div>
                            <div className="comparison-arrow"
                              className={`${collapsedStates[index][2] ? 'comparison-arrow-black' : 'comparison-arrow-grey'}`}
                              style={{ transform: `rotate(${collapsedStates[index][2] ? 0 : 180}deg)` }}></div>
                          </div>
                          {result[policy.name] && result[policy.name].inPolicyANotB && result[policy.name].inPolicyANotB.length > 0 && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {result[policy.name].inPolicyANotB.slice(0, expansionGiveUpStates[policy.name] ? result[policy.name].inPolicyANotB.length : 10).map((item, idx) => (
                                <Tooltip text={findReasonByMatch(policy, item)} useDoubleClick={true}>
                                  <div key={idx}><span style={{ color: 'red', fontSize: '12pt', fontWeight: 'bold' }}>-</span>
                                    &nbsp;{formatCoverageName(item)}
                                  </div>
                                </Tooltip>
                              ))}
                              {result[policy.name].inPolicyANotB.length > 10 && (
                                <div style={{ cursor: 'pointer', color: 'green' }} onClick={() => toggleGiveUpExpansion(policy.name)}>
                                  {expansionGiveUpStates[policy.name] ? 'See less...' : 'See more...'}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )
                }
                {(activeDocument.policyWording.filename !== policy.fileName && policy.conditions && Object.keys(policy.conditions).length > 0) && (
                  <div className={`comparison-item-gain-loss ${collapsedStates[index][3] ? 'comparison-item-gain-loss-grey' : ''}`}
                    style={{ height: collapsedStates[index][3] ? '50px' : 'auto' }}

                  >
                    <div className="comparison-item-gain-loss-header" onClick={() => toggleCollapse(3)}>
                      <div >Key conditions ({Object.keys(policy.conditions).length}): </div>
                      <div className="comparison-arrow"
                        className={`${collapsedStates[index][3] ? 'comparison-arrow-black' : 'comparison-arrow-grey'}`}
                        style={{ transform: `rotate(${collapsedStates[index][3] ? 0 : 180}deg)` }}></div>
                    </div>
                    {result[policy.name] && !collapsedStates[index][3] && (
                      <>
                        {
                          Object.keys(policy.conditions).map((key, index) => (
                            <label key={index} className="comparison-item-gain-loss-conditions">
                              <Tooltip text={findReasonByMatch(policy, key)} useDoubleClick={true}>
                                &#9632; {formatCoverageName(key)}: {policy.conditions[key]}
                              </Tooltip>
                            </label>
                          ))
                        }
                      </>
                    )}
                  </div>
                )}
                {(activeDocument.policyWording.filename !== policy.fileName && activeDocument.excessDetails && hasAtLeastOneNonNullValue(activeDocument.excessDetails)) && (
                  <div className={`comparison-item-gain-loss ${collapsedStates[index][4] ? 'comparison-item-gain-loss-grey' : ''}`}
                    style={{ height: collapsedStates[index][4] ? '50px' : 'auto' }}

                  >
                    <div className="comparison-item-gain-loss-header" onClick={() => toggleCollapse(4)}>
                      <div >Excess ({Object.keys(activeDocument.excessDetails).length}): </div>
                      <div className="comparison-arrow"
                        className={`${collapsedStates[index][4] ? 'comparison-arrow-black' : 'comparison-arrow-grey'}`}
                        style={{ transform: `rotate(${collapsedStates[index][4] ? 0 : 180}deg)` }}></div>
                    </div>
                    {result[policy.name] && !collapsedStates[index][4] && (
                      <>
                        {
                          Object.keys(activeDocument.excessDetails || {}).map((key, index) => (
                            <label key={index} className="comparison-item-gain-loss-checkbox">
                              {(activeDocument.excessDetails[key] !== '' && activeDocument.excessDetails[key] !== null) && (
                                <>
                                  <Tooltip text={findReasonByMatch(policy, key)} useDoubleClick={true}>
                                    &#9632; {formatCoverageName(key)}: {activeDocument.excessDetails[key]}
                                  </Tooltip>
                                </>
                              )}
                            </label>
                          ))
                        }
                      </>
                    )}
                  </div>
                )}
                {(activeDocument.policyProvider !== policy.provider && activeDocument.optionalBenefits && hasAtLeastOneNonNullValue(activeDocument.optionalBenefits)) && (
                  <div className={`comparison-item-gain-loss ${collapsedStates[index][5] ? 'comparison-item-gain-loss-grey' : ''}`}
                    style={{ height: collapsedStates[index][5] ? '50px' : 'auto' }}

                  >
                    <div className="comparison-item-gain-loss-header" onClick={() => toggleCollapse(5)}>
                      <div >Options ({Object.keys(activeDocument.optionalBenefits).length}): </div>
                      <div className="comparison-arrow"
                        className={`${collapsedStates[index][5] ? 'comparison-arrow-black' : 'comparison-arrow-grey'}`}
                        style={{ transform: `rotate(${collapsedStates[index][5] ? 0 : 180}deg)` }}></div>
                    </div>
                    {result[policy.name] && !collapsedStates[index][4] && (
                      <>
                        {
                          <>
                            {Object.keys(activeDocument.optionalBenefits || {}).map((key, index) => (
                              <label key={index} className="comparison-item-gain-loss-checkbox">
                                {(activeDocument.optionalBenefits[key] !== '' && activeDocument.optionalBenefits[key] !== null) && (
                                  <>
                                    <Tooltip text={findReasonByMatch(policy, key)} useDoubleClick={true}>
                                      &#9632; {formatCoverageName(key)}: {activeDocument.optionalBenefits[key]}
                                    </Tooltip>
                                  </>
                                )}
                              </label>
                            ))}
                          </>
                        }
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
          }
        </div >
      )
    }
  }


  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const displayQuoteFilter = () => {
    return (
      <div className="comparison-page-quote-filter-popup" style={{
        width: isCollapsed ? 'calc(100vw - 275px)' : 'calc(100vw - 435x)'
      }} >
        <div className='comparison-page-quote-filter-popup-header' >
          <div className="comparison-page-quote-filter-popup-header-close" onClick={() => toggleQuoteFilter()}>&times;</div>
        </div>
        <div className="comparison-container-header">
          <div className="comparison-container-header-title-description">Fill in the fields below (or procced with our assumptions) and click 'Get Quotes' to get live quotes from providers: </div>
        </div>
        <InsuranceForm onClose={() => toggleQuoteFilter()} />
      </div>
    );
  };

  return (
    <div ref={ref}>
      {showQuoteFilter && displayQuoteFilter()}
      <div className="comparison-page" style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
        {/* <div className={`comparison-bar-arrow ${isHidden ? 'up' : 'down'}`} onClick={toggleHidden}></div> */}
        {/* <div
        className="comparison-page-container"
        style={{
          width: isCollapsed ? 'calc(100vw - 210px)' : 'calc(100vw - 380px)',
          display: isHidden ? 'none' : 'block'
        }}
      >
        <div className="comparison-page-container-header">
          <div className="comparison-page-container-header-title">Find a <span style={{color:'#4FA658'}}><strong>{policyType}</strong></span> policy:</div>
        </div>
        <div className="comparison-page-container-header">
          <div className="comparison-page-container-header-section">
            <div className="comparison-page-container-header-title-sub">Your current policy:</div>
            <ActivePolicy width={'260px'}/>
          </div>
          <div className="comparison-page-container-header-section" style={{ justifyContent: 'flex-end' }}>
            <button className="comparison-page-container-header-button-filter" onClick={() => setFilterOpen(true)}>Showing <span><strong>{selectedCount}</strong></span> of <span><strong>{totalCount}</strong></span></button>
            <button className="comparison-page-container-header-button-filter" onClick={() => toggleQuoteFilter()}>
              Update Quotes
            </button>
          </div>
          <ComparisonFilter
            filters={globalFilters}
            setFilters={setGlobalFilters}
            isOpen={isFilterOpen}
            onClose={() => setFilterOpen(false)}
            selectedCount={selectedCount}
            totalCount={totalCount}
          />
        </div>

      </div> */}
        <div className="comparison-page-container" style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
          {/* <div className='comparison-page-container-header' >
            <button className="comparison-page-container-header-button-filter" onClick={() => toggleQuoteFilter()}>
              Update Quotes
            </button>
          </div> */}
          {(activeDocument !== null && activeDocument !== undefined) && (
            <div className="scroll-wrapper">
              <div className="mirror-scroll" ref={scrollRef}>
                <div className="content-mirror"></div>
              </div>
              <div className={`scroll-container`} >
                {/* fadeClass */}
                {activeDocument && (
                  <div className="comparison-container">
                    {renderSortedPolicies(sortedPolicies)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {
          isCoverageMapOpen && (
            <Popup onClose={toggleCoverageMapPopup} width="80%" height="70%">
              <div className='coverage-map-name'>Your <span className="underlined">Coverage Map</span> for {activeDocument.policyName}</div>
              <Tiles />
            </Popup>
          )
        }
        {
          isCoverageScoreOpen && (
            <Popup onClose={toggleCoverageScorePopup} width="80%" height="70%">
              <div className='coverage-map-name'>Your <span className="underlined">Coverage Score</span> for {activeDocument.policyName}</div>
              <PolicyScore />
            </Popup>
          )
        }
      </div>
    </div>
  );
});

export default Comparison;
