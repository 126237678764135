import React from 'react';
import Proposal from '../../components/advice/Proposal';
import PDFFooter from '../../components/advice/statement/PDFFooter';
import LogoURL from "../../icons/company_logo_green.png";
import './AdvicePDF.css';

const AdvicePDF = ({ sections, proposal, toggleSection, pdf = true}) => {

    const allTrueSections = Object.keys(sections).reduce((acc, section) => {
        acc[section] = true;
        return acc;
    }, {});

    const LogoStyle = {
        width: '35px',
        height: '35px',
        paddingTop: '10px',
        paddingLeft: '5px',
    };

    return (
        <div style={{ backgroundColor: '#3F3B3C', color: 'white', padding: '20px' }}>
            <header style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-start',
                textAlign: 'center',
                padding: '10px'
            }}>
                <img src={LogoURL} style={LogoStyle} alt="" />
                <div className="advice-pdf-logo-div">
                    <span style={{ color: 'rgb(79,166,88)' }}>POLICY</span>
                    <span style={{ color: 'white' }}>CHECK</span></div>
            </header>
            <div style={{ margin: '10px 20px', padding: '10px 20px' }}>
                <div className="advice-pdf">
                    <div className="advice-pdf-title">Statement of Advice</div>
                    <div className="advice-pdf-description">
                        This Statement of Advice (SoA) is a formal document provided by financial advisors
                        to their clients, detailing tailored financial recommendations based on the client's
                        specific circumstances, goals, and needs. It includes sections on client details,
                        scope of advice, goals, budget analysis, recommendations, and associated risks and costs.
                        The SoA ensures that clients receive clear, compliant, and comprehensive advice to make
                        informed financial decisions.
                    </div>
                    <div className="advice-pdf-rows">
                        <Proposal
                            sections={allTrueSections}
                            toggleSection={toggleSection}
                            proposal={proposal}
                            setProposal={() => { }}
                            pdf={pdf}
                        />
                    </div>
                </div>
            </div>
            <PDFFooter />
        </div>
    );
};

export default AdvicePDF;
