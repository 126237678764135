import React from 'react';

const riskAppetiteOptions = ['Low', 'Medium', 'High'];
const coverageLevelOptions = ['Low', 'Medium', 'High'];
const preferredInsurersOptions = ['Insurer A', 'Insurer B', 'Insurer C'];

const PreferencesTab = ({ clientData, handleChange }) => {
  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid">
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Risk Preferences</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preference Type:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.preferenceType" value={clientData.insurancePreferences.preferenceType} onChange={handleChange}>
                {riskAppetiteOptions.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Details:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="insurancePreferences.details" value={clientData.insurancePreferences.details} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Risk Appetite:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.riskAppetite" value={clientData.insurancePreferences.riskAppetite} onChange={handleChange}>
                {riskAppetiteOptions.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">General Risk Tolerance:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.generalRiskTolerance" value={clientData.insurancePreferences.generalRiskTolerance} onChange={handleChange}>
                {riskAppetiteOptions.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Coverage Level:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.coverageLevel" value={clientData.insurancePreferences.coverageLevel} onChange={handleChange}>
                {coverageLevelOptions.map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Insurer Preferences</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preferred Insurers:</div>
              <div className="client-details-popup modal-grid-item-checkbox-group">
                {preferredInsurersOptions.map(insurer => (
                  <label key={insurer}>
                    <input
                      type="checkbox"
                      name="insurancePreferences.preferredInsurers"
                      value={insurer}
                      checked={clientData.insurancePreferences.preferredInsurers.includes(insurer)}
                      onChange={handleChange}
                    />
                    {insurer}
                  </label>
                ))}
              </div>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Brand Importance:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.brandImportance" value={clientData.insurancePreferences.brandImportance} onChange={handleChange}>
                {["Not Important", "Somewhat Important", "Very Important"].map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Service Importance:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.serviceImportance" value={clientData.insurancePreferences.serviceImportance} onChange={handleChange}>
                {["Not Important", "Somewhat Important", "Very Important"].map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Insurance Credit Rating Score:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.insuranceCreditRatingScore" value={clientData.insurancePreferences.insuranceCreditRatingScore} onChange={handleChange}>
                {["A", "AA", "AAA", "B", "BB", "BBB"].map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Other</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Payment Frequency:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="insurancePreferences.paymentFrequency" value={clientData.insurancePreferences.paymentFrequency} onChange={handleChange}>
                {["Monthly", "Quarterly", "Annually"].map(option => <option key={option} value={option}>{option}</option>)}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesTab;
