// UploadContext.js
import React, { createContext, useContext, useState } from 'react';

const UploadContext = createContext();

export const useUpload = () => {
    const context = useContext(UploadContext);
    if (context === undefined) {
        throw new Error('useUpload must be used within a UploadProvider');
    }
    return context;
};

export const UploadProvider = ({ children }) => {
    const [isUploadComplete, setIsUploadComplete] = useState(false);

    const handleUploadComplete = (complete) => {
        setIsUploadComplete(complete);
    };

    return (
        <UploadContext.Provider value={{ isUploadComplete, handleUploadComplete }}>
            {children}
        </UploadContext.Provider>
    );
};
