import React, { createContext, useState } from 'react';

export const ProviderPolicyListContext = createContext();

export const ProviderPolicyListProvider = ({ children }) => {
    const [providerPolicies, setProviderPolicies] = useState([]);
  
    return (
      <ProviderPolicyListContext.Provider value={{ providerPolicies, setProviderPolicies }}>
        {children}
      </ProviderPolicyListContext.Provider>
    );
  };