import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

// ContentsContext creation
export const ContentsContext = createContext();

export const ContentsProvider = ({ children }) => {
  // Call the custom hook inside the ContentsProvider where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Initialize the state for contents insurance
  const initialContentsInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        protectBelongings: false,
        coverValuables: false,
        insureElectronics: false,
      },
      goals: {
        buildingCoverage: false, // Adjusted to reflect focus on contents
        liabilityCoverage: false,
      },
    },
    situation: {
      propertyDetails: {
        address: '',
        yearBuilt: '',
        constructionType: '',
      },
      contentsDetails: {
        totalValue: '',
        highValueItems: '',
      },
      propertyRisks: {
        floodZone: false,
        bushfireZone: false,
        highCrimeArea: false,
      },
    },
    modules: {
      options: {
        excessFreeGlass: false,
        accidentalDamage: false,
        motorBurnout: false,
        sumInsuredExtension: false,
        matchingFloorCoverings: false,
        specifiedItems: false, // Optional cover for specific valuable items
        portableContents: false, // Cover for portable contents outside the home
        homeOfficeEquipment: false, // Cover for equipment used for home office
      },
    },
  };

  // Set the filters state using the initialContentsInsuranceState
  const [filters, setFilters] = useState(initialContentsInsuranceState);

  return (
    <ContentsContext.Provider value={{ filters, setFilters }}>
      {children}
    </ContentsContext.Provider>
  );
};
