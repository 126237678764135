import React, { useContext, useState, useEffect } from 'react';
import './GeoPopup.css'; // Import the CSS file

function GeoPopup() {
    const [showPopup, setShowPopup] = useState(false);
    const [tempCountry, setTempCountry] = useState(null);
    const url = window.location.href;

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`);
                const data = await response.json();
                setTempCountry(data.country);
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };
        fetchCountryData();
    }, []);

    useEffect(() => {
        const hasSeenPopup = localStorage.getItem('hasSeenGeoPopup');

        if (tempCountry !== undefined && tempCountry !== null && !hasSeenPopup) {
            if ((url.includes('au') && tempCountry !== 'AU') || (url.includes('nz') && tempCountry !== 'NZ')) {
                setShowPopup(true);
            }
        }
    }, [tempCountry, url]);

    const handleClose = () => {
        setShowPopup(false);
        localStorage.setItem('hasSeenGeoPopup', 'true'); // Only set when the user clicks 'Cancel'
    };

    const handleSwitch = () => {
        localStorage.setItem('hasSeenGeoPopup', 'true'); // Only set when the user clicks 'Switch'
        if (url.includes('au')) {
            window.location.href = 'https://www.policycheck.co.nz';
        } else {
            window.location.href = 'https://www.policycheck.com.au';
        }
    };

    return (
        <>
            {showPopup && (
                <div className="popup">
                    <button onClick={handleClose} className="popup-close">×</button>
                    <h4 className="popup-title">You are accessing the {url.includes('au') ? 'AU' : 'NZ'} website from {tempCountry === 'AU' ? 'Australia' : 'New Zealand'}</h4>
                    <button onClick={handleSwitch} className="button button-right">Switch to {tempCountry} website</button>
                    <button onClick={handleClose} className="button">Cancel</button>
                </div>
            )}
        </>
    );
}

export default GeoPopup;
