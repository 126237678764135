import React from 'react';
import './Payments.css';

const Payments = () => {

  return (
    <div className="policy-summary-list">
      <div className="policy-summary-list-header">
        <div className="policy-summary-list-header-title">Payments:</div>
      </div>
    </div>
  );
};

export default Payments;
