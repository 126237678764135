import React, { createContext, useState, useContext } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {
  const [activeDocument, setActiveDocument] = useState('');

  const setActiveDocumentName = (name) => {
    
    setActiveDocument(name);
  };

  return (
    <GlobalContext.Provider value={{ activeDocument, setActiveDocumentName }}>
      {children}
    </GlobalContext.Provider>
  );
};
