import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './AttributeManager.css'

// Mock data
const initialAttributes = Array.from({ length: 10 }, (_, k) => ({
    id: `Attribute-${k + 1}`,
    excess: '',
    limit: ''
}));

const AttributeManager = ({ onItemsChange }) => {
    const [attributes, setAttributes] = useState(initialAttributes);
    const [selectedAttributes, setSelectedAttributes] = useState([]);

    const updateItem = (index, field, value) => {
        const updatedItems = [...selectedAttributes];
        updatedItems[index] = { ...updatedItems[index], [field]: value };
        setSelectedAttributes(updatedItems);
        onItemsChange(updatedItems);  // Assuming you want to propagate changes up to the parent
    };

    const filterItems = (event) => {
        const value = event.target.value.toLowerCase();
        setAttributes(
            initialAttributes.filter(item => item.id.toLowerCase().includes(value))
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return; // Dropped outside the list

        const items = Array.from(selectedAttributes);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSelectedAttributes(items);
        onItemsChange(items);
    };

    const addItem = (item) => {
        const newItems = [...selectedAttributes, { ...item, excess: '', limit: '' }];
        setSelectedAttributes(newItems);
        onItemsChange(newItems);
    };

    const removeItem = (index) => {
        const newItems = Array.from(selectedAttributes);
        newItems.splice(index, 1);
        setSelectedAttributes(newItems);
        onItemsChange(newItems);
    };

    return (
        <div className="attribute-section">
            <div className="attribute-component">
                <span><strong>Needs</strong></span>
                <div style={{ display: 'flex', width: '80%' }}>
                    <div style={{ width: '350px', marginRight: '20px' }}>
                        <input type="text"
                            className="attribute-component-search"
                            placeholder="Search attributes..."
                            onChange={filterItems}
                        />
                        <div style={{
                            overflowY: 'auto', height: '400px',
                            border: '1px solid #ccc'
                        }}>
                            {attributes.map((attribute, index) => (
                                <div key={attribute.id}
                                    className="attribute-component-search-item">
                                    {attribute.id}
                                    <button onClick={() => addItem(attribute)}>+</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="attribute-component-dropzone">
                                    {selectedAttributes.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style, 
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom: '5px',
                                                        padding: '10px',
                                                        gap: '20px',
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                    }}>
                                                    <div className="attribute-component-dropzone-item-green">{item.id}</div>
                                                    <input className="attribute-component-dropzone-item"
                                                        type="text" value={item.excess}
                                                        onChange={(e) => updateItem(index, 'excess', e.target.value)}
                                                        placeholder="$ Excess" />
                                                    <input className="attribute-component-dropzone-item"
                                                        type="text" value={item.limit}
                                                        onChange={(e) => updateItem(index, 'limit', e.target.value)}
                                                        placeholder="$ Limit" />
                                                    <button className="attribute-component-dropzone-button"
                                                        onClick={() => removeItem(index)}>-</button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default AttributeManager;
