import React, { useState, useEffect } from 'react';
import FileUpload from '../../FileUpload';
import { useClient } from '../../../context/ClientContext';
import './Quotes.css';

const Quotes = ({ initialData = [], onUpdate, pdf }) => {
  const { activeClient } = useClient();
  const [cells, setCells] = useState(initialData);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);

  const fetchQuotes = async () => {
    
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/quotes?clientID=${activeClient?._id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const formattedData = data.map((quote) => ({
        id: quote._id,
        icon: '📄',
        name: quote?.name || 'Unknown',
        date: quote?.date,
      }));
      setCells(formattedData);
    } catch (err) {
      console.error('Failed to fetch quotes:', err);
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);
  
  useEffect(() => {
    onUpdate(cells);
  }, [cells]);

  const handleAddItem = (index) => {
    const handlerResult = true;
    if (handlerResult) {
      const newCells = [...cells];
      newCells[index] = { id: new Date().getTime(), icon: '🔔', title: 'Notification' };
      newCells.push(null); // Always ensure there's a blank cell at the end
      setCells(newCells);
    }
  };

  const handleRemoveItem = (index) => {
    const newCells = cells.filter((_, i) => i !== index);
    // Ensure there are at least 3 items, adding a blank cell if necessary
    while (newCells.length < 3) {
      newCells.push(null);
    }
    setCells(newCells);
    onUpdate(newCells);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (cells.length < 3 || cells[cells.length - 1] !== null) {
      setCells((prevCells) => {
        const newCells = [...prevCells];
        while (newCells.length < 3) {
          newCells.push(null);
        }
        if (newCells[newCells.length - 1] !== null) {
          newCells.push(null);
        }
        return newCells;
      });
    }
  }, [cells]);

  const setHasSeenModalToTrue = () => {
    setToggle(!toggle);
  };

  function formatDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);
    
    // Extract the day, month, and year from the Date object
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = String(date.getUTCFullYear()).slice(-2); // Get the last two digits of the year
    
    // Format the date as dd-mm-yy
    const formattedDate = `${day}-${month}-${year}`;
    
    return formattedDate;
}

  return (
    <div className="grid-component">
      <button className="edit-button" onClick={handleEditMode}>
        {editMode ? 'Done' : 'Edit'}
      </button>
      <div className="grid-container">
        {cells.map((cell, index) => (
          <div key={index} className={`grid-cell ${editMode && cell ? 'editable' : ''}`}>
            {cell ? (
              <>
                <div className="grid-icon" style={{fontSize:'40pt'}}>{cell.icon}</div>
                <div className="grid-title">{cell.name}</div>
                <div className="grid-date">{formatDate(cell.date)}</div>
                {editMode && (
                  <button className="remove-button" onClick={() => handleRemoveItem(index)}>
                    -
                  </button>
                )}
              </>
            ) : (
              !pdf && (
                <button className="add-button" onClick={() => setToggle(true)}>
                  +
                </button>
              )
            )}
          </div>
        ))}
      </div>
      <FileUpload toggleWelcome={toggle} onToggle={setHasSeenModalToTrue} />
    </div>
  );
};

export default Quotes;
