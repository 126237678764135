import React from 'react';
import './AdvicePopup.css';

const AdvicePopup = ({ isOpen, onClose, onPrint, children }) => {
    if (!isOpen) return null;

    return (
        <div className="advice-popup-overlay" onClick={onClose}>
            <div className="advice-popup-content" onClick={(e) => e.stopPropagation()}>
                <div className="advice-popup-header">
                    <button className="advice-popup-button" onClick={onPrint}>Print</button>
                    <button className="advice-popup-button" onClick={onClose}>Close</button>
                </div>
                <div className="advice-popup-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdvicePopup;
