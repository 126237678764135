import React from 'react';
import './ProfitabilityOverview.css';

const ProfitabilityOverview = ({ leadBatches }) => {
  return (
    <div className="profitability-overview">
      <table>
        <thead>
          <tr>
            <th>Batch</th>
            <th>Cost</th>
            <th>Converted Customers</th>
            <th>Total Profit</th>
            <th>Return</th>
          </tr>
        </thead>
        <tbody>
          {leadBatches.map((batch, index) => (
            <tr key={index}>
              <td>{batch.name}</td>
              <td>${batch.cost}</td>
              <td>{batch.convertedCustomers}</td>
              <td>${batch.profit}</td>
              <td>${batch.profit - batch.cost}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfitabilityOverview;
