import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { type, value } = payload[0].payload; // Accessing the first item's payload
      const formattedValue = `$${value.toLocaleString()}`; // Convert to currency format
  
      return (
        <div className="custom-tooltip">
          <p className="label">{`${type} : ${formattedValue}`}</p>
        </div>
      );
    }
  
    return null;
  };

  
const aggregateCommissionsByType = (sales) => {
    const commissionsByType = {};
  
    if (!Array.isArray(sales)) {
      return [];
    }
  
    sales.forEach(sale => {
      if (commissionsByType[sale.policyType]) {
        commissionsByType[sale.policyType] += sale.upfront;
      } else {
        commissionsByType[sale.policyType] = sale.upfront;
      }
    });
  
    return Object.entries(commissionsByType).map(([type, value]) => ({ type, value }));
  };
   
  const DonutChart = ({ sales }) => {
    const data = aggregateCommissionsByType(sales);  // Transform sales data to chart data
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
    return (
        <div className="commissions-metric-card" style={{maxWidth:'400px'}}>
        <h3>Total Commission by Product</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            nameKey="type"
            isAnimationActive={true}
            animationDuration={800}
            animationEasing="ease-out"
          >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      </div>
    );
  };
  
  export default DonutChart;
  