import React, { useState } from 'react';
import WaitList from "./../components/user_access/WaitList";
import ForgotPassword from "./../components/user_access/ForgotPassword";
import './PricingComponent.css';

function PricingComponent() {
    const [isWaitListPopupOpen, setWaitListPopupOpen] = useState(false);
    const [isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen] = useState(false);
    const [billingCycle, setBillingCycle] = useState('Monthly');
    const basicPrice = 199;
    const premiumPrice = 130;
    const professionalPrice = 156;

    const calculateDiscountText = (monthlyPrice, annualPrice) => {
        if (billingCycle === 'Monthly') {
            const discountPercent = Math.round((1 - (annualPrice / (monthlyPrice * 12))) * 100);
            return `${discountPercent}% discount if paid annually`;
        } else {
            const annualSavings = (monthlyPrice * 12) - annualPrice;
            return `$${annualSavings.toLocaleString()} savings per year`;
        }
    };

    const plans = [
        {
            title: 'Individual',
            colour: '#747474',
            subTitle: '1 Licence',
            price: billingCycle === 'Monthly' ? '$' + basicPrice : '$' + Math.ceil(basicPrice * 12 * 0.9).toLocaleString(),
            discountText: calculateDiscountText(basicPrice, Math.ceil(basicPrice * 12 * 0.9)),
            attributes: [
                "Unlimited policies",
                "Unlimited clients",
                "1 GB of storage",
                "Standard Reporting",
                "5 Days, 9-5 Support",
                "Single user account",
            ],
            trialText: 'Start Free Trial',
            subTextBold: 'No credit card required',
            subText: "No lock in contracts\nFree 21 day trial\nPrices inclusive of GST\nFair Use Policy applies",
        },
        {
            title: 'Team',
            colour: '#747474',
            subTitle: '5 Licences',
            price: billingCycle === 'Monthly' ? '$' + premiumPrice : '$' + Math.ceil(premiumPrice * 12 * 0.85).toLocaleString(),
            discountText: calculateDiscountText(premiumPrice, Math.ceil(premiumPrice * 12 * 0.85)),
            attributes: [
                "Unlimited policies",
                "Unlimited clients",
                "5 GB of storage",
                "Customised Reporting",
                "5 Days, 9-5 Support",
                "Multi User",
            ],
            trialText: 'Start Free Trial',
            subTextBold: 'No credit card required',
            subText: "No lock in contracts\nFree 21 day trial\nPrices inclusive of GST\nFair Use Policy applies",
        },
        {
            title: 'Firm',
            colour: '#747474',
            subTitle: '10 Licences',
            price: billingCycle === 'Monthly' ? '$' + professionalPrice : '$' + Math.ceil(professionalPrice * 12 * 0.8).toLocaleString(),
            discountText: calculateDiscountText(professionalPrice, Math.ceil(professionalPrice * 12 * 0.8)),
            attributes: [
                "Unlimited policies",
                "Unlimited clients",
                "20 GB of storage",
                "Advanced Reporting",
                "7 Days, 8-8 Support",
                "Advanced User Mgmt.",
            ],
            trialText: 'Start Free Trial',
            subTextBold: 'No credit card required',
            subText: "No lock in contracts\nFree 21 day trial\nPrices inclusive of GST\nFair Use Policy applies",
        },
        {
            title: 'Enterprise',
            colour: '#747474',
            subTitle: 'On Request',
            price: 'TBA',
            discountText: billingCycle === 'Monthly' ? '20% discount if paid annually' : 'Custom savings per year',
            attributes: [
                "Unlimited policies",
                "Unlimited clients",
                "Data storage - TBA",
                "Tailored Reporting",
                "24/7 Support",
                "Full User Mgmt.",
            ],
            trialText: 'Start Free Trial',
            subTextBold: 'No credit card required',
            subText: "No lock in contracts\nFree 21 day trial\nPrices inclusive of GST\nFair Use Policy applies",
        }
    ];

    const toggleBillingCycle = () => {
        setBillingCycle(billingCycle === 'Monthly' ? 'Annual' : 'Monthly');
    };

    const toggleWaitListPopup = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setWaitListPopupOpen(!isWaitListPopupOpen);
    };

    return (
        <div className="pricing-component-container">
            <div className="pricing-container-toggle-container">
                <div className={`pricing-container-toggle-slider ${billingCycle === 'Monthly' ? 'pricing-container-toggle-left' : 'pricing-container-toggle-right'}`}></div>
                <div className={`pricing-container-toggle-option ${billingCycle === 'Monthly' ? 'pricing-container-toggle-option-white' : 'pricing-container-toggle-option-black'}`}
                    onClick={toggleBillingCycle}
                >Monthly</div>
                <div className={`pricing-container-toggle-option ${billingCycle === 'Annual' ? 'pricing-container-toggle-option-white' : 'pricing-container-toggle-option-black'}`}
                    onClick={toggleBillingCycle}
                >Annual</div>
            </div>
            <div className="pricing-container-columns">
                {plans.map((plan, index) => (
                    <div key={index} className="pricing-container-column">
                        <div className="pricing-container-header" style={{ backgroundColor: plan.colour }}>
                            <div className="pricing-container-header-title">{plan.title}</div>
                            <div className="pricing-container-header-subtitle" >{plan.subTitle}</div>
                        </div>
                        <div className="pricing-container-pricing">
                            <div>
                                <span>{plan.price}</span><sup style={{ fontSize: '10pt', width: '20px' }}> p/m per broker</sup>
                            </div>
                            <div className="discount">{plan.discountText}</div>
                        </div>
                        <ul>
                            {plan.attributes.map((attribute, idx) => (
                                <li key={idx}><span>✔  </span>{attribute}</li>
                            ))}
                        </ul>
                        <button className="trial-button" onClick={toggleWaitListPopup}>{plan.trialText}</button>
                        <div className="sub-text-bold">{plan.subTextBold}</div>
                        <div className="sub-text">
                            {plan.subText.split('\n').map((line, idx) => (
                                <React.Fragment key={idx}>
                                    {line}<br />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <ForgotPassword isOpen={isForgotPasswordPopupOpen} onClose={() => setIsForgotPasswordPopupOpen(false)} resetStep={true} />
            <WaitList isOpen={isWaitListPopupOpen} onClose={() => setWaitListPopupOpen(false)} forgotPassword={() => setIsForgotPasswordPopupOpen(true)} />
        </div>
    );
}

export default PricingComponent;
