import React, { useContext, useState, useEffect } from "react";
import { Callout } from "@blueprintjs/core";
import { UserContext } from "../../context/UserContext";
import { CountryContext } from '../../context/CountryContext';
import BoxGrid from "../BoxGrid";
import ConsumerIcon from "../../icons/consumer.png";
import BrokerIcon from "../../icons/broker.png";
import { useNavigate, useLocation } from 'react-router-dom';
import "./LogInandRegister.css";

const Register = ({ isOpen, onClose, resetStep, alreadyAUser, code, username }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;
  const [emailAndCodeDisabled,setEmailAndCodeDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [earlyAccess, setEarlyAccess] = useState();
  const [waitingListThankYou, setWaitingListThankYou] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(username || "");
  const [type, setType] = useState("Broker");
  const { country } = useContext(CountryContext);
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState('');
  const [earlyRegistrationCode, setEarlyRegistrationCode] = useState(code || ""); // Ensure earlyRegistrationCode is never null
  const [userContext, setUserContext] = useContext(UserContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  const [step, setStep] = useState(1);
  const [customOption, setCustomOption] = useState(false);

  const images = [
    { src: ConsumerIcon, alt: 'Consumer' },
    { src: BrokerIcon, alt: 'Broker' }
  ];

  const handleAlreadyUser = () => {
    alreadyAUser();
    onClose(); // Close the popup
    setStep(1);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    clearTimeout(timer); // Clear any existing timer

    const newTimer = setTimeout(() => {
      setIsPasswordVisible(false);
    }, 60000); // 30 seconds

    setTimer(newTimer);
  };

  useEffect(() => {
    if (location.pathname === '/register') {
      setEmailAndCodeDisabled(true);
    }
  }, []);


  useEffect(() => {
    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [timer]);

  useEffect(() => {
    if (code) {
      setEarlyRegistrationCode(code);
    }
  }, [code]);

  useEffect(() => {
    if (username) {
      setEmail(username);
    }
  }, [username]);

  const formSubmitHandler = (e) => {
    if (strength !== "strong") {
      setError("Please choose a stronger password");
      return null;
    }
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    const genericErrorMessage = "Something went wrong :( Please try again later.";

    fetch(process.env.REACT_APP_API_ENDPOINT + "users/signup", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firstName, lastName, username: email, password, earlyRegistrationCode, waitingList: earlyAccess, type: type, country: country }),
    })
      .then(async (response) => {
        setIsSubmitting(false);
        const data = await response.json();
        if (!response.ok) {
          if (response.status === 400) {
            setError("Error: ", data.message || genericErrorMessage);
          } else if (response.status === 401) {
            setError("Invalid email and password combination.");
          } else if (response.status === 500) {

            if (data.message) setError(data.message || genericErrorMessage);
          } else {
            setError(genericErrorMessage);
          }
        } else {

          if (data.token === null || data.token === undefined) {

          } else {
            setUserContext((oldValues) => {
              return { ...oldValues, token: data.token };
            })
            navigate('/dashboard');
          }
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setError(genericErrorMessage);
      });
  };

  // Function to handle input changes and reset error to false
  const handleInputChange = (e) => {
    // Get the new input value
    const newValue = e.target.value;

    // Determine which input field is being changed and update the corresponding state
    if (e.target.name === "firstName") {
      setFirstName(newValue);
    } else if (e.target.name === "lastName") {
      setLastName(newValue);
    } else if (e.target.name === "username") {
      setEmail(newValue);
    } else if (e.target.name === "password") {
      setPassword(newValue);
      setStrength(checkPasswordStrength(newValue));
    } else if (e.target.name === "earlyRegistrationCode") {
      setEarlyRegistrationCode(newValue);
    }

    // Reset error to false
    setError("");
  };

  const checkPasswordStrength = (password) => {
    // Define your criteria for password strength here
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

    if (password.length < 8) {
      return 'weak';
    }

    if (hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar) {
      return 'strong';
    }

    if (hasUpperCase && hasLowerCase && hasDigit) {
      return 'moderate';
    }

    return 'weak';
  };

  const handleOptionChange = (e) => {
    setCustomOption(!customOption)
    setError("");
  }

  const handleImageSelect = (user) => {
    setError('');
    setType(user);
  };



  return (
    <>
      <div className={`login-and-register-pop-up ${isOpen ? "open" : ""}`}>
        <div className="popup-container">
          <div className="popup-container-close" onClick={onClose}>
            &times;
          </div>
          <>
            <div className="pop-up-container-heading">Create account</div>
            <div className="existing-user" onClick={handleAlreadyUser}>
              Already a user?
            </div>
            <div className="popup-container-name-inputs">
              <input
                className="text-email-password-input"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={handleInputChange}
                autoComplete="given-name"
              />
              <input
                className="text-email-password-input"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={handleInputChange}
                autoComplete="given-name"
              />
            </div>
            <div className="popup-container-inputs">
              <input
                className="text-email-password-input"
                type="email"
                name="username"
                placeholder="Email"
                value={email}
                onChange={handleInputChange}
                autoComplete="email"
                disabled={emailAndCodeDisabled} // This disables the input if email is null or empty
              />
              <div className="password-input-container">
                <form>
                  <input
                    className="text-email-password-input"
                    type={isPasswordVisible ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                    value={password}
                    autoComplete="new-password"
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !error) {
                        e.preventDefault(); // Prevent the default Enter key action
                        formSubmitHandler(e); // Pass the event to formSubmitHandler
                      }
                    }}
                  />
                </form>
                <input
                  className="text-email-password-input registration-hidden"
                  type="text"
                  name="earlyRegistrationCode"
                  placeholder="Early Access Code"
                  value={earlyRegistrationCode}
                  onChange={handleInputChange}
                  autoComplete="off"
                  disabled={emailAndCodeDisabled} // This disables the input if code is null or empty

                />
                <label className="early-access">
                  <div className="register-disclaimer-text">
                    By submitting this form you agree to our <a href="/documents/terms-and-conditions.pdf" target="_blank">Terms & Conditions</a>, <a href="/documents/privacy-policy.pdf" target="_blank">Privacy Policy</a> and <a href="/documents/cookies.pdf" target="_blank">Cookie Policy</a>
                  </div>
                </label>
                <button onClick={togglePasswordVisibility} className={`toggle-password-btn ${isPasswordVisible ? 'dark-grey' : ''}`}>
                  {isPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
            {(strength === 'weak' || strength === 'moderate') && (
              <>
                <div className="password-strength">{strength !== '' ? `Password strength ` : ''}
                  <span className={`${strength === 'weak' ? 'password-strength-red' : strength === 'moderate' ? 'password-strength-orange' : 'password-strength-green'}`}>{strength}</span>
                </div>
                <div className="password-help">Hint: Password needs to include: both upper and lower case. at least one number and special character. Greater than 8 characters in length</div>
              </>
            )}
            {error && <Callout intent="danger">{error}</Callout>}

            <div className="popup-buttons">
              <div className="align-buttons">
                <button
                  className="popup-buttons-back"
                  onClick={() => setStep(1)}
                >
                  Back
                </button>
                <button
                  disabled={error}
                  className={`popup-buttons-next ${error ? "isError" : "noError"
                    }`}
                  onClick={formSubmitHandler}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        </div>
        <div className="popup-overlay" onClick={onClose}>
          <div className="popup-overlay-content"></div>
        </div>
      </div>
    </>
  );
};

export default Register;
