import React from 'react';
import { marked } from 'marked';

// Helper function to format the date
const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

// Function to render a table with a title
const renderTable = (title, rows) => (
    <div style={{ marginBottom: '20px' }}>
        <h3>{title}</h3>
        <table style={{ width: '100%', margin:'0px', borderCollapse:'collapse'}}>
            <tbody>
                {rows.map(([label, value], index) => (
                    <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{label}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const EmailPreview = ({
    date, email, firmName, brokerName, brokerContact, brokerAddress, brokerLicence,
    introductionNote, thankYouNote, logo, splashBanner, disclaimers
}) => {
    return (
        <div style={{ backgroundColor: '#f4f4f4', padding: '20px', borderRadius: '5px' }}>
            <div style={{ backgroundColor: '#f4f4f4', color: 'darkgrey', padding: '0px', textAlign: 'center', fontSize: '10pt' }}>
                <p>Your insurance quote from {firmName} <br />
                    Prepared by {brokerName} {brokerLicence ? `(${brokerLicence})` : ''} on {formatDate(date)}</p>
                <p>Having trouble viewing this email? <a href="#">View it in your browser</a>.</p>
            </div>
            <div style={{ boxSizing: 'border-box', width: '100%', backgroundColor: 'white', padding: '0px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', marginTop: '0px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', height: '100px', textAlign: 'center', backgroundImage: `url(${splashBanner})`, padding: '0px', borderRadius: '5px 5px 0 0' }}>
                    {logo && <img src={logo} alt="Company Logo" style={{ marginTop: '10px', marginLeft: '10px', width: 'auto', height: '80%' }} />}
                </div>
                <div style={{ boxSizing: 'border-box', width: '100%', backgroundColor: 'white', padding: '40px' }}>
                    <h2>Summary</h2>
                    <div style={{ width: '90%', boxSizing: 'border-box', fontFamily: 'Poppins' }}
                        dangerouslySetInnerHTML={{ __html: marked(introductionNote) }}
                    />
                    <div style={{ boxSizing: 'border-box',display: 'flex', justifyContent: 'space-between', marginTop: '20px' , gap:'20px'}}>
                        <div style={{ flex: '1'}}>
                            {renderTable('Policy Summary', [
                                ['Policyholders', ''],
                                ['Date', ''],
                                ['Start of Insurance', ''],
                                ['Expiry Date', '']
                            ])}
                            {renderTable('Cover Summary', [
                                ['Cover', ''],
                                ['Optional Cover', 'Included/Not Included'],
                                ['Legal Liability', '']
                            ])}
                        </div>
                        <div style={{ flex: '1', marginLeft: '20px' }}>
                            {renderTable('Contact Details', [
                                ['Contact Number(s)', brokerContact],
                                ['Postal Address', brokerAddress],
                                ['Email Address', email]
                            ])}
                            {renderTable('Excess(es) on Claims', [
                                ['Basic Excess', ''],
                                ['Standard Excess', ''],
                                ['Premium Excess', '']
                            ])}
                            {renderTable('Optional Cover', [
                                ['Cover Option 1', 'Included/Not Included'],
                                ['Cover Option 2', 'Included/Not Included']
                            ])}
                        </div>
                    </div>
                    <div>{thankYouNote}</div>
                </div>
            </div>
            <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f4f4f4', textAlign: 'center', fontSize: '12px', color: '#777', borderTop: '1px solid #ddd' }}>
                <p>
                    Your Account   |   Program Terms   |   Privacy Statement   |   Online Help   |   Contacts   |   PolicyCheck<br />
                    <br />
                    This email was originally sent to {email}. You have received this email because you are subscribed to ‘update' emails from your broker. To unsubscribe from 'updates’ emails only, <a href="#">click here</a>.<br />
                    To view and update the full list of email types that you are currently subscribed to, log in to Your Profile at <a href="https://policycheck.com.au">policycheck.co</a>
                    <br /><br />

                    Sent by {brokerName} (Licence Number: {brokerLicence}) (Contact: {brokerContact}). Headquarters: {brokerAddress}<br />
                    <br />
                    <div style={{ fontSize: '8pt', textAlign: 'left' }}>
                        {Object.keys(disclaimers).map((key, index) => (
                            <React.Fragment key={key}>
                                <p>{'*'.repeat(index + 1)} {disclaimers[key]}</p>
                                {index < Object.keys(disclaimers).length - 1}
                            </React.Fragment>
                        ))}
                    </div>
                </p>
            </div>
        </div>
    );
};

export default EmailPreview;
