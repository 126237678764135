import axios from 'axios';

export const getWorkflows = () => axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/workflows`);

export const getSteps = (workflowId) => {
  if (!workflowId) {
    return Promise.resolve({ data: [] });
  }
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/workflows/${workflowId}/steps`);
};

export const createStep = (workflowId, step) => axios.post(`${process.env.REACT_APP_API_ENDPOINT}api/workflows/${workflowId}/steps`, step);

export const updateStep = (workflowId, stepId, step) => axios.put(`${process.env.REACT_APP_API_ENDPOINT}api/workflows/${workflowId}/steps/${stepId}`, step);

export const deleteStep = (workflowId, stepId) => axios.delete(`${process.env.REACT_APP_API_ENDPOINT}api/workflows/${workflowId}/steps/${stepId}`);
// Fetch all needs for a specific client, proposal, and quote
export const getNeeds = (clientID, proposalID, quoteID) => 
  axios.get(`${process.env.REACT_APP_API_ENDPOINT}api/needs`, {
    params: { clientID, proposalID, quoteID }
  });
