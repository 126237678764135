import React, { useState, useEffect, useRef } from 'react';
import SettingsURL from "../../icons/calendar.png";
import './Calendar.css';

function Calendar() {
    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            message: "Quote for John Turner due today",
            dueDate: new Date(),
        },
        {
            id: 2,
            message: "Expiring Policy for Tim Jenkins",
            dueDate: new Date(),
        }
    ]);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const getDueDate = (date) => {
        const today = new Date();
        const dueDate = new Date(date);
        dueDate.setDate(today.getDate() + 2);

        const options = { month: 'short', day: 'numeric' };
        const formattedDueDate = dueDate.toLocaleDateString('en-US', options);

        const daysLeft = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));

        return { formattedDueDate, daysLeft };
    };

    const handleRemoveNotification = (id) => {
        const updatedNotifications = notifications.filter(notification => notification.id !== id);
        setNotifications(updatedNotifications);
    };

    return (
        <div className="calendar-container" ref={ref}>
            <div className="calendar-icon" onClick={() => setIsOpen(!isOpen)}>
                <img
                    src={SettingsURL}
                    alt="Click me"
                    className="calendar-icon"
                />
                {notifications.length > 0 && (
                    <span className="notification-count">{notifications.length}</span>
                )}
            </div>

            {isOpen && (
                <div className="notification-panel" onMouseLeave={() => setIsOpen(false)}>
                    <ul>
                        {notifications.map(notification => {
                            const dueDateInfo = getDueDate(notification.dueDate);
                            return (
                                <li key={notification.id} className="notification-item">
                                    <span className="close-btn" onClick={() => handleRemoveNotification(notification.id)}>&times;</span>
                                    {notification.message}
                                    <div className="due-date-text">Due date: {dueDateInfo.formattedDueDate} ({dueDateInfo.daysLeft} days left)</div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )} 
        </div>
    );
}

export default Calendar;
