import React, { useState, useEffect } from 'react';
import { usePopup } from './../context/PopupContext'; // Adjust the path as necessary
import ImageURL from "./../images/landing_image_2.png";
import Login from "./../components/user_access/Login";
import Register from "./../components/user_access/Register";
import ForgotPassword from "./../components/user_access/ForgotPassword";
import WaitList from "./../components/user_access/WaitList";
import { useLocation } from 'react-router-dom';
import './LandingContainer.css'

const LandingContainer = () => {
    const location = useLocation();
    const [earlyAccess, setEarlyAccess] = useState(null);
    const [email, setEmail] = useState(null);
    const {
        isLogInPopupOpen,
        setIsLogInPopupOpen,
        isRegisterPopupOpen,
        setIsRegisterPopupOpen,
        isForgotPasswordPopupOpen,
        setIsForgotPasswordPopupOpen,
        isWaitListPopupOpen,
        setIsWaitListPopupOpen
    } = usePopup();


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code'); 
        const email = searchParams.get('username');
        const path = location.pathname; // Get the current path from the location object

        setEarlyAccess(code); 
        setEmail(email);
        if (path.includes('/register')) {
            setIsRegisterPopupOpen(true);
        }

    }, [location]);  // Dependency on location ensures this runs when route changes


    useEffect(() => {
        const hasWaitListBeenShown = localStorage.getItem('waitListShown');

        if (!hasWaitListBeenShown) {
            const timer = setTimeout(() => {
                // Trigger the WaitList popup if none of the others have been opened
                if (!isLogInPopupOpen && !isRegisterPopupOpen && !isForgotPasswordPopupOpen && !isWaitListPopupOpen) {
                    setIsWaitListPopupOpen(true);
                    localStorage.setItem('waitListShown', 'true'); // Mark as shown
                }
            }, 120000); // Adjust the delay as needed

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [isLogInPopupOpen, isRegisterPopupOpen, isForgotPasswordPopupOpen, isWaitListPopupOpen, setIsWaitListPopupOpen]);

    const handleWaitListClose = () => {
        setIsWaitListPopupOpen(false);
        localStorage.setItem('waitListShown', 'true'); // Ensure it's marked as shown even if closed manually
    };

    // Function to close the ForgotPassword popup
    const closeForgotPassword = () => {
        setIsForgotPasswordPopupOpen(false);  // Make sure this is correctly updating the state
    };

    return (
        <div className="landing-container" >
            <img src={ImageURL} className="landing-mask" alt="" />
            <div className="landing-div" >
                <div className='landing-partition-1' >
                    <div className="tag-line">Digital Insurance for Brokers</div>
                    <div className="tag-line-sub-text">Quickly understand client needs, effortlessly compare policies, and communicate proposals and advice with speed and compliance.</div>
                    <br/>
                    <div className="tag-line-sub-text">Spend less time on manual tasks and more time where it matters — building relationships and providing expert advice.</div>
                    <div className="register-login">
                        <button className="landing-popup-button" onClick={() => setIsWaitListPopupOpen(true)}>Start Free Trial</button>
                    </div>
                    <Login isOpen={isLogInPopupOpen} onClose={() => setIsLogInPopupOpen(false)} resetStep={true} forgotPassword={() => setIsForgotPasswordPopupOpen(true)} />
                    <Register isOpen={isRegisterPopupOpen} onClose={() => setIsRegisterPopupOpen(false)} resetStep={true} alreadyAUser={() => setIsLogInPopupOpen(true)} code={earlyAccess} username={email}/>
                    <ForgotPassword isOpen={isForgotPasswordPopupOpen} onClose={closeForgotPassword} resetStep={true} />
                    <WaitList isOpen={isWaitListPopupOpen} onClose={handleWaitListClose} />
                </div>
                <div className="landing-partition-small" ></div>
                <div className="landing-partition-2" />
            </div>
        </div>
    );
};

export default LandingContainer;
