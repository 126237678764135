import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PolicySummary = ({ policyDetails, setPolicyDetails, onBack, onNext }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPolicyDetails({
            ...policyDetails,
            [name]: value,
        });
    };

    const handleDateChange = (date, name) => {
        setPolicyDetails({
            ...policyDetails,
            [name]: date,
        });
    };

    const labelMappings = {
        policyName: 'Type',
        policyNumber: 'Policy Number',
        policyType: 'Type',
        policyProvider: 'Provider',
        policyPrice: 'Cost',
        paymentFrequency: 'Payments',
        policyAddress: 'Address',
        periodOfInsuranceEnd: 'Policy Expires',
        homeSumInsured: 'Insured Amount',
        homeUse: 'Usage',
        excessGlass: 'Glass',
        excessHearing: 'Hearing Aids',
        excessEyeWear: 'Eye Wear',
        home: 'Home Excess',
        contents: 'Contents Excess',
        unoccupied: 'Unoccupied Excess',
        naturalDisaster: 'Natural Disaster Excess'
    };

    return (
        <>
            <div className="upload-container-row">
                <div className="upload-container-column-no-gap">
                    <div className="upload-container-option-header">
                        <div className="upload-container-option-header-title">Review your policy details</div>
                    </div>
                    <div className="upload-inputs">
                        {Object.keys(policyDetails).filter(key => key in labelMappings).map(key => (
                            <div key={key} className="upload-input">
                                <label className="upload-input-label" htmlFor={key}>{labelMappings[key]}</label>
                                {key === 'periodOfInsuranceEnd' ? (
                                    <DatePicker
                                        selected={policyDetails[key]}
                                        onChange={(date) => handleDateChange(date, key)}
                                        dateFormat="dd/MM/yyyy"
                                        className="upload-input-text"
                                    />
                                ) : (
                                    <input
                                        className="upload-input-text"
                                        type="text"
                                        id={key}
                                        name={key}
                                        value={policyDetails[key]}
                                        onChange={handleInputChange}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="upload-buttons">
                <button className="upload-buttons-back" onClick={onBack}>Back</button>
                <button className="upload-buttons-next" onClick={onNext}>Next</button>
            </div>
        </>
    );
};

export default PolicySummary;
