import React, { createContext, useContext, useState } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [isLogInPopupOpen, setIsLogInPopupOpen] = useState(false);
    const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);
    const [isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen] = useState(false);
    const [isWaitListPopupOpen, setIsWaitListPopupOpen] = useState(false);

    return (
        <PopupContext.Provider value={{
            isLogInPopupOpen,
            setIsLogInPopupOpen,
            isRegisterPopupOpen,
            setIsRegisterPopupOpen,
            isForgotPasswordPopupOpen,
            setIsForgotPasswordPopupOpen,
            isWaitListPopupOpen,
            setIsWaitListPopupOpen
        }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);
