import React from 'react';
import './ScoreIndicator.css';

const ScoreIndicator = ({ percentage }) => {
    
    // Convert the percentage to the end angle of the green arc in degrees
    const endAngle = Math.min((percentage / 100) * 180, 180);

    // Function to calculate the end point of the arc based on the angle
    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180.0;
        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians)
        };
    };

    // Calculate the end point of the green arc
    const endCoords = polarToCartesian(100, 100, 90, endAngle);
    const greenArcPath = `M 10,100 A 90,90 0 ${endAngle > 180 ? 1 : 0} 1 ${endCoords.x},${endCoords.y}`;

    return (
        <div className="score-indicator-container">
            <div className="curved-line-meter-container">
                <svg width="250" height="150" viewBox="0 0 200 100">
                    <path
                        d="M10,100 A90,90 0 0 1 190,100 L100,100 Z"
                        fill="rgba(55, 55, 55)"
                        stroke="rgba(55, 55, 55)"
                        strokeWidth="14"
                        strokeLinecap="round" />
                    <path d="M10,100 A90,90 0 0 1 190,100"
                        stroke="#ddd"
                        strokeWidth="15"
                        fill="none"
                        strokeLinecap="square" />
                    <path
                        d={greenArcPath}
                        stroke="#4caf50"
                        strokeWidth="15"
                        strokeLinecap="round"
                        fill="none"
                    />
                </svg>
                <div className="meter-label">{percentage}pt</div>
                <div className="meter-label-sub">Coverage Score</div>
            </div>
        </div>
    );
};

export default ScoreIndicator;
