import React, { useState, useEffect, useRef } from 'react';
import tileLookup from './TilesDescription';
import iconURL from "../icons/A1.png";
import { useGlobalContext } from '../context/GlobalContext';
import './Tiles.css';

function Tiles() {

    const targetDivRef = useRef(null); // Ref for the target div
    const myDivRef = useRef(null);

    const [gridWidth, setGridWidth] = useState(0); // State to store the width


    const [rightPosition, setRightPosition] = useState({});
    const [leftPosition, setLeftPosition] = useState({});
    const [attributes, setAttributes] = useState({});
    const [showTextBubble, setShowTextBubble] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [title, setTitle] = useState({});
    const [reason, setReason] = useState({});
    const [textBubblePosition, setTextBubblePosition] = useState({ left: 0, top: 0 });
    const { activeDocument, setActiveDocumentName } = useGlobalContext();
    const filename = activeDocument.filename;
    const [doubleClicked, setDoubleClicked] = useState({});
    const [isClicked, setIsClicked] = useState(false);

    const attributeRefs = useRef({});

    // function updateDimensions() {
    //     const handleResize = () => {
    //         // Loop over all refs
    //         Object.entries(attributeRefs.current).forEach(([key, ref]) => {
    //             if (ref.current) {
    //                 const rect = ref.current.getBoundingClientRect();
    //                 // Perform any action with the dimensions
    //                 // Other logic based on rect...
    //             }
    //         });
    //     };

    //     window.addEventListener('resize', handleResize);
    //     handleResize(); // Initial check
    //     return () => window.removeEventListener('resize', handleResize);
    // }; // Empty array to ensure this effect runs only once

    function getUpdatedWithDimensions() {
        const handleResize = () => {
            if (myDivRef.current) {
                const rect = myDivRef.current.getBoundingClientRect();
                setRightPosition(rect.right);
            }
        };
        window.addEventListener('resize', handleResize);
        // Initial check
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }

    function getUpdatedWithDimensionsTargetDivRef() {
        const handleResize = () => {
            if (targetDivRef.current) {
                const rect = targetDivRef.current.getBoundingClientRect();
                setLeftPosition(rect.left);
            }
        };
        window.addEventListener('resize', handleResize);
        // Initial check
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }

    function setSubCovergeDivWidth() {
        if (targetDivRef.current !== undefined && targetDivRef.current !== null) {
            getUpdatedWithDimensions();
            getUpdatedWithDimensionsTargetDivRef();
            if (leftPosition && rightPosition) {
                // Calculate the difference
                const widthDifference = (rightPosition - leftPosition - 50);
                setGridWidth(widthDifference);
                targetDivRef.current.style.width = `${widthDifference}px`; // Set the new width
            }
        }
    }

    useEffect(() => {
        setSubCovergeDivWidth();
    }, []);

    const iconStyle = {
        width: '65px',
        height: '65px',
        padding: '10px'
    };

    function formatCoverageName(coverageName) {
        // Add a space before any uppercase letters and capitalize the first letter
        const withSpaces = coverageName.replace(/([A-Z])/g, ' $1').replace(/ And /g, " & ");

        const updatedCoverageName = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

        switch (true) {
            case updatedCoverageName == 'Tempoary Accommodation':
                return 'Tempoary Accom.';
        }

        return updatedCoverageName;
    }

    useEffect(() => {
    }, [doubleClicked]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + `users/api/insurance-provider?policyProvider=${activeDocument.policyProvider}`)
            .then((response) => response.json())
            .then((data) => {
                if (data?.coverages !== undefined) {
                    setAttributes(data.coverages);
                }})
            .catch((error) => console.error('Error fetching attributes:', error));
    }, [activeDocument]);

    // JavaScript code to apply animation delays to each tile
    useEffect(() => {
        const tiles = document.querySelectorAll('.attribute-tile');
        tiles.forEach((tile, index) => {
            tile.style.animation = `fadeIn 1s ease-in-out forwards ${index * 0.0}s`;
        });
    }, [attributes]);

    // Function to close the text bubble
    const handleCloseTextBubble = () => {
        setShowTextBubble(false);
    };

    const handleTileDoubleClick = (displayName) => {
        if (doubleClicked[displayName] === undefined) {
            setDoubleClicked({});
        }
        setDoubleClicked(prevState => ({
            ...prevState,
            [displayName]: !prevState[displayName]
        }))
        getUpdatedWithDimensions();
        setLeftPosition(attributeRefs.current[displayName].getBoundingClientRect().left);
    };

    // Function to get the description based on display name
    const getDescription = (displayName) => {
        return tileLookup[displayName] || 'Description not available'; // Default description
    };

    const renderCell = (value, subCoverage) => {
        switch (true) {
            case value.included === undefined:
                return 'green';
            case value.included:
                return (subCoverage ? 'light-green' : 'green');
            case value.excess !== "":
                return (subCoverage ? 'light-yellow' : 'yellow');
            case value.limit !== "":
                return (subCoverage ? 'light-orange' : 'orange');
            case value.optional:
                return (subCoverage ? 'light-yellow' : 'yellow');
            case !value.included:
                return (subCoverage ? 'light-red' : 'red');
        };
    };

    function showReason(event, displayName, coverageReason) {
        setTextBubblePosition({ left: event.clientX, top: event.clientY });
        setShowTextBubble(true);
        setTitle(formatCoverageName(displayName));
        setReason(coverageReason);
    }

    function formatCurrency(value) {
        // Parse the string to an integer
        var number = parseInt(value, 10);

        // Check if it's a valid number
        if (isNaN(number)) {
            return value; // or return a default value or error message
        }

        // Format the number as a currency string
        return '$' + number.toLocaleString('en-US');
    }

    return (
        <div className='tiles-display'>
            <div ref={myDivRef} className="attributes-container">
                {Object.entries(attributes).map(([displayName, value]) => (
                    <div>
                        <div ref={el => attributeRefs.current[displayName] = el} className="attribute-and-sub-attribute" key={displayName}>
                            <div
                                className={`attribute-tile ${renderCell(value)}`}
                                onClick={() => handleTileDoubleClick(displayName)}
                            >
                                <img className="tile-icon" src={iconURL} style={iconStyle} alt="" />
                                <p className="tile-text">{formatCoverageName(displayName)}</p>
                                {value.excess && value.excess !== "" && value.excess !== "0" ? "Excess: " + formatCurrency(value.excess) : ''}
                                {value.limit && value.limit !== "" && value.limit !== "0" ? "Limit: " + formatCurrency(value.limit) : ''}
                            </div>
                            {doubleClicked[displayName] && value.included === undefined && (
                                <div className="sub-coverage-container" style={{ width: `calc(${rightPosition}px - ${attributeRefs.current[displayName].getBoundingClientRect().left}px - 45px)` }}>
                                    {Object.entries(value).map(([subDisplayName, subValue]) => (
                                        (subDisplayName !== "_id" && subDisplayName !== "included" && subDisplayName !== "excess" && subDisplayName !== "limit" && subDisplayName !== "optional") ? (
                                            <div
                                                key={subDisplayName}
                                                className={`attribute-tile-top ${renderCell(subValue, true)}`}
                                                onClick={(e) => showReason(e, subDisplayName, subValue.reason)}
                                            >
                                                <p className="tile-text-sub">{formatCoverageName(subDisplayName)}</p>
                                                {subValue.excess && subValue.excess !== "" && subValue.excess !== "0" ? "Excess: " + formatCurrency(subValue.excess) : ''}
                                                {subValue.limit && subValue.limit !== "" && subValue.limit !== "0" ? "Limit: " + formatCurrency(subValue.limit) : ''}
                                            </div>
                                        ) : null
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                {showTextBubble && (
                    <div
                        className="text-bubble"
                        // style={{ left: textBubblePosition.left, top: textBubblePosition.top }}
                        style={{ right: "-200px", top: "100px" }}

                        onClick={handleCloseTextBubble}
                    >
                        <div className="text-bubble-close" onClick={handleCloseTextBubble}>&times;</div>
                        <div className="text-bubble-h3">{title}</div>
                        <div className="text-bubble-p">{reason}</div>
                    </div>
                )}
            </div>
        </div >
    );
}

export default Tiles;
