import React, { useState } from 'react';
import { formatString } from '../utils/utils';

import './BoxGrid.css';

const BoxGrid = ({ images, onImageSelect }) => {
    const [activeBox, setActiveBox] = useState(0);

    const imgStyle = {
        width: '150px',
        height: 'auto'
    }

    const handleChange = (index, image) => {
        setActiveBox(index);
        onImageSelect(image.alt);
    };

    return (
        <div className="box-grid">
            {images.map((image, index) => (
                <div key={index} className="box-grid-item">  {/* Moved the key here */}
                    <div
                        className={`box ${activeBox === index ? 'box-active' : 'box-inactive'}`}
                        onClick={() => handleChange(index, image)}
                    >
                        <img src={image.src} alt={image.alt} style={imgStyle} />
                        <div className="indicator"></div>
                    </div>
                    <div className={`box-grid-item-title ${activeBox === index ? '' : 'box-grid-item-title-inactive'}`}>
                        {formatString(image.alt === 'Consumer' ? 'Client' : image.alt)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BoxGrid;
