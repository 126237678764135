import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const AutoContext = createContext();

export const AutoProvider = ({ children }) => {
  // Call the custom hook inside the AutoProvider where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial auto insurance state
  const initialAutoInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`  
    objectivesAndGoals: {
      objectives: {
        vehicleProtection: false, // Objective to protect the vehicle
        coverLiability: false, // Objective to cover liability
        insurePassengers: false, // Objective to insure passengers
      },
      goals: {
        comprehensiveCoverage: false, // Goal for comprehensive coverage
        collisionCoverage: false, // Goal for collision coverage
        uninsuredMotorist: false, // Goal for uninsured motorist coverage
      },
    },
    situation: {
      vehicleDetails: {
        make: '',
        model: '',
        year: '',
        vin: '', // Vehicle Identification Number
      },
      driverDetails: {
        name: '',
        licenseNumber: '',
        drivingHistory: '',
      },
    },
    modules: {
      options: {
        comprehensive: false,
        collision: false,
        liability: false,
        medicalPayments: false,
        roadsideAssistance: false,
        rentalReimbursement: false,
      },
    },
  };

  // Initialize filters state with the initial auto insurance state
  const [filters, setFilters] = useState(initialAutoInsuranceState);

  return (
    <AutoContext.Provider value={{ filters, setFilters }}>
      {children}
    </AutoContext.Provider>
  );
};
