import React, { useState, useEffect } from 'react';
import GeneralInfoTab from './components/GeneralInfoTab';
import SituationTab from './components/SituationTab';
import PreferencesTab from './components/PreferencesTab';
import CommunicationTab from './components/CommunicationTab';
import QuotesTab from './components/QuotesTab';
import ProposalsTab from './components/ProposalsTab';
import DocumentsTab from './components/DocumentsTab';
import PastClaimsTab from './components/PastClaimsTab';
import './ClientDetailsPopup.css';

const ClientDetailsPopup = ({ client, isOpen, onClose, onSave }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [clientData, setClientData] = useState(client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [missingFields, setMissingFields] = useState([]);

  const mandatoryFields = [
    'userName',
    'clientType',
    'personalDetails.firstName',
    'personalDetails.lastName',
    'contactInformation.phone',
    'contactInformation.email',
  ];

  useEffect(() => {
    if (activeTab === 'quotes' || activeTab === 'proposals' || activeTab === 'documents') {
      fetchDataForTab(activeTab);
    }
  }, [activeTab]);

  const fetchDataForTab = async (tab) => {
    setLoading(true);
    setError(null);
    let endpoint = '';
    switch (tab) {
      case 'quotes':
        endpoint = `api/quotes?clientID=${client._id}`;
        break;
      case 'proposals':
        endpoint = `users/api/proposals?clientID=${client._id}`;
        break;
      case 'documents':
        endpoint = `users/api/policy_documents?clientID=${client._id}`;
        break;
      default:
        break;
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + endpoint);
      if (!response.ok) {
        throw new Error(`Error fetching ${tab}`);
      }
      const data = await response.json();
      setClientData((prevData) => ({
        ...prevData,
        [tab]: data,
      }));
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (e) => {
    setError(null);
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    const newClientData = { ...clientData };

    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      newClientData[parent][child] = newValue;
    } else {
      newClientData[name] = newValue;
    }

    setClientData(newClientData);
  };

  const handleSave = () => {
    const missing = [];
    for (const field of mandatoryFields) {
      const [parent, child] = field.split('.');
      if (child) {
        if (!clientData[parent] || !clientData[parent][child]) {
          missing.push(field);
        }
      } else {
        if (!clientData[field]) {
          missing.push(field);
        }
      }
    }
    if (missing.length > 0) {
      setMissingFields(missing);
      setError(`Please fill out the following fields: ${missing.join(', ')}`);
      return;
    }
    onSave(clientData);
  };

  const renderFieldError = (field) => {
    if (missingFields.includes(field)) {
      return <span className="error">This field is required</span>;
    }
    return null;
  };

  const handleAddQuote = () => {
    // Logic to add a new quote
  };

  const handleEditQuote = (quote) => {
    // Logic to edit an existing quote
  };

  const handleDeleteQuote = (quote) => {
    setClientData((prevData) => ({
      ...prevData,
      quotes: prevData.quotes.filter((q) => q._id !== quote._id),
    }));
  };

  const handleAddDocument = () => {
    // Logic to add a new document
  };

  const handleEditDocument = (document) => {
    // Logic to edit an existing document
  };

  const handleDeleteDocument = (document) => {
    setClientData((prevData) => ({
      ...prevData,
      documents: prevData.documents.filter((d) => d._id !== document._id),
    }));
  };

  const handleAddProposal = () => {
    // Logic to add a new proposal
  };

  const handleEditProposal = (proposal) => {
    // Logic to edit an existing proposal
  };

  const handleDeleteProposal = (proposal) => {
    setClientData((prevData) => ({
      ...prevData,
      proposals: prevData.proposals.filter((p) => p._id !== proposal._id),
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="client-details-popup modal-overlay" onClick={onClose}>
      <div className="client-details-popup client-details-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="client-details-popup modal-header">
          <h2 className="client-details-popup modal-title">Client Details</h2>
          <button className="client-details-popup close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="client-details-popup modal-tabs">
          <button onClick={() => handleTabClick('general')} className={activeTab === 'general' ? 'active' : ''}>
            General
          </button>
          <button onClick={() => handleTabClick('situation')} className={activeTab === 'situation' ? 'active' : ''}>
            Situation
          </button>
          <button onClick={() => handleTabClick('preferences')} className={activeTab === 'preferences' ? 'active' : ''}>
            Preferences
          </button>
          <button onClick={() => handleTabClick('communication')} className={activeTab === 'communication' ? 'active' : ''}>
            Communication
          </button>
          <button onClick={() => handleTabClick('claims')} className={activeTab === 'claims' ? 'active' : ''}>
            Past Claims
          </button>
          <button onClick={() => handleTabClick('quotes')} className={activeTab === 'quotes' ? 'active' : ''}>
            Quotes
          </button>
          <button onClick={() => handleTabClick('proposals')} className={activeTab === 'proposals' ? 'active' : ''}>
            Proposals
          </button>
          <button onClick={() => handleTabClick('documents')} className={activeTab === 'documents' ? 'active' : ''}>
            Documents
          </button>
        </div>
        <div className="client-details-popup modal-body">
          {loading && <div>Loading...</div>}
          {activeTab === 'general' && <GeneralInfoTab clientData={clientData} handleChange={handleChange} renderFieldError={renderFieldError} />}
          {activeTab === 'situation' && <SituationTab clientData={clientData} handleChange={handleChange} renderFieldError={renderFieldError} />}
          {activeTab === 'preferences' && <PreferencesTab clientData={clientData} handleChange={handleChange} renderFieldError={renderFieldError} />}
          {activeTab === 'communication' && <CommunicationTab clientData={clientData} handleChange={handleChange} renderFieldError={renderFieldError} />}
          {activeTab === 'claims' && <PastClaimsTab clientData={clientData} handleChange={handleChange} renderFieldError={renderFieldError} />}
          {activeTab === 'quotes' && (
            <QuotesTab
              clientData={clientData}
              handleChange={handleChange}
              onAddQuote={handleAddQuote}
              onEditQuote={handleEditQuote}
              onDeleteQuote={handleDeleteQuote}
            />
          )}
          {activeTab === 'proposals' && (
            <ProposalsTab
              clientData={clientData}
              handleChange={handleChange}
              onAddProposal={handleAddProposal}
              onEditProposal={handleEditProposal}
              onDeleteProposal={handleDeleteProposal}
            />
          )}
          {activeTab === 'documents' && (
            <DocumentsTab
              clientData={clientData}
              handleChange={handleChange}
              onAddDocument={handleAddDocument}
              onEditDocument={handleEditDocument}
              onDeleteDocument={handleDeleteDocument}
            />
          )}
        </div>
        <div className="client-details-popup modal-footer">
          <button className="client-details-popup cancel-button" onClick={onClose}>Cancel</button>
          <button className="client-details-popup save-button" onClick={handleSave}>Save</button>
        </div>
        {error && <div className="error" style={{ width: '100%', textAlign: 'center', color: 'black', paddingTop: '10px' }}>{error}</div>}
      </div>
    </div>
  );
};

export default ClientDetailsPopup;
