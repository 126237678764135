import React, { useContext, useState, useEffect } from "react";
import { Callout } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import "./ResetPassword.css";
import "./LogInandRegister.css";

const Reset = ({ isOpen, onClose, resetStep, forgotPassword, code, username }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState(username);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [contextValue, setUserContext] = useContext(UserContext);
  const [validationMessage, setValidationMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    document.title = 'Reset Password';
  }, []);

  useEffect(() => {
    setEmail(username);
  }, [username]);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    clearTimeout(timer); // Clear any existing timer

    const newTimer = setTimeout(() => {
      setIsPasswordVisible(false);
    }, 30000); // 30 seconds

    setTimer(newTimer);
  };

  useEffect(() => {
    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [timer]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (password !== confirmPassword) {
      setIsSubmitting(false);
      setValidationMessage('Passwords do not match.');
      return;
    } else {
      setValidationMessage('');
    }

    const genericErrorMessage = "Something went wrong :( Please try again later.";

    fetch(`${process.env.REACT_APP_API_ENDPOINT}users/resetpassword?token=${code}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: email,
        password: password
      })
    })
      .then(async (response) => {
        setIsSubmitting(false);

        if (!response.ok) {
          if (response.status === 400) {
            setError("Please fill all the fields correctly!");
          } else if (response.status === 401) {
            setError("Invalid email and password combination.");
          } else {
            setError(genericErrorMessage);
          }
        } else {
          const data = await response.json();
          setUserContext((oldValues) => {
            return { ...oldValues, token: data.token };
          });
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setError("Failed - ", genericErrorMessage);
      });

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    } else if (name === "email") {
      setEmail(value);
    }
    // Reset validation messages
    setError("");
    setValidationMessage("");
  };

  return (
    <>
      <div className="pop-up-container-heading">Reset your password</div>
      <div className="popup-container-inputs" style={{width:'40%'}}>
        <input
          className="text-email-password-input"
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleInputChange}
          autoComplete="username"
          disabled
        />
        <div className="password-input-container" >
          <input
            className="text-email-password-input"
            type={isPasswordVisible ? 'text' : 'password'}
            name="password"
            placeholder="New Password"
            value={password}
            onChange={handleInputChange}
            autoComplete="password"
          />
          <button onClick={togglePasswordVisibility} className={`toggle-password-btn ${isPasswordVisible ? 'dark-grey' : ''}`}>
            {isPasswordVisible ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className="password-input-container">
          <input
            className="text-email-password-input"
            type={isPasswordVisible ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Re-Enter Password"
            value={confirmPassword}
            onChange={handleInputChange}
          />
          <button onClick={togglePasswordVisibility} className={`toggle-password-btn ${isPasswordVisible ? 'dark-grey' : ''}`}>
            {isPasswordVisible ? 'Hide' : 'Show'}
          </button>
        </div>
        {validationMessage && <p className="validation-message">{validationMessage}</p>}
        {error && <p className="validation-message">{error}</p>}
        <div className="popup-buttons">
          <div className="align-buttons">
            <button
              disabled={error || validationMessage}
              className={`popup-buttons-next ${error || validationMessage ? "isError" : "noError"}`}
              onClick={formSubmitHandler}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
