import React from 'react';

const preferredContactTimeOptions = ['Morning', 'Afternoon', 'Evening'];
const preferredContactFrequencyOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Annually'];
const preferredCommunicationMethodOptions = ['Email', 'Phone', 'In-Person', 'SMS'];

const CommunicationTab = ({ clientData, handleChange }) => {
  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid">
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Contact</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Last Contact:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="date" name="crmDataPoints.lastContact" value={clientData.crmDataPoints.lastContact} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Last Meeting Date:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="date" name="crmDataPoints.lastMeetingDate" value={clientData.crmDataPoints.lastMeetingDate} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Next Scheduled Contact:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="date" name="crmDataPoints.nextScheduledContact" value={clientData.crmDataPoints.nextScheduledContact} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Preferences</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preferred Contact Time:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="crmDataPoints.preferredContactTime" value={clientData.crmDataPoints.preferredContactTime} onChange={handleChange}>
                {preferredContactTimeOptions.map(time => <option key={time} value={time}>{time}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preferred Contact Frequency:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="crmDataPoints.preferredContactFrequency" value={clientData.crmDataPoints.preferredContactFrequency} onChange={handleChange}>
                {preferredContactFrequencyOptions.map(frequency => <option key={frequency} value={frequency}>{frequency}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preferred Communication Method:</div>
              <select className="client-details-popup modal-grid-item-label-input" name="clientPreferences.preferredCommunicationMethod" value={clientData.clientPreferences.preferredCommunicationMethod} onChange={handleChange}>
                {preferredCommunicationMethodOptions.map(method => <option key={method} value={method}>{method}</option>)}
              </select>
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Preferred Language:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="clientPreferences.preferredLanguage" value={clientData.clientPreferences.preferredLanguage} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="client-details-popup modal-grid-item">
          <div className="client-details-popup modal-grid-item-label">Other</div>
          <div className="client-details-popup modal-subgrid">
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Referral Source:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="text" name="crmDataPoints.referralSource" value={clientData.crmDataPoints.referralSource} onChange={handleChange} />
            </div>
            <div className="client-details-popup flex-row">
              <div className="client-details-popup modal-grid-item-label-sub">Subscribed to Email:</div>
              <input className="client-details-popup modal-grid-item-label-input" type="checkbox" name="crmDataPoints.subscribedToEmail" checked={clientData.crmDataPoints.subscribedToEmail} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">Notes</div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Note</th>
              <th>Author</th>
            </tr>
          </thead>
          <tbody>
            {clientData.crmDataPoints.notes.map((note, index) => (
              <tr key={index}>
                <td><input type="date" value={note.date} onChange={handleChange} /></td>
                <td><input type="text" value={note.note} onChange={handleChange} /></td>
                <td><input type="text" value={note.author} onChange={handleChange} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommunicationTab;
