import React from 'react';
import PolicyChart from '../PolicyChart';
import PolicyElementsChart from '../PolicyElementsChart';

const Limits = ({ compare = true }) => {

    return (
        <>
            <PolicyElementsChart valueType="limit" />
            {/* <PolicyChart type='limit' isVertical={true} maxSlide={5000000} compare={compare} /> */}
        </>
    );
};

export default Limits;
