import React, { useState, useRef, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCollapse } from '../../context/CollapseContext';
import { useClient } from '../../context/ClientContext';
import Proposal from '../../components/advice/Proposal';
import AdvicePDF from './AdvicePDF';
import AdvicePopup from '../../components/advice/AdvicePopup';
import './Advice.css';

const Advice = () => {
    const printRef = useRef(null);
    const { activeDocument } = useGlobalContext();
    const { isCollapsed } = useCollapse();
    const { activeClient } = useClient();
    const [proposal, setProposal] = useState({});
    const [disclaimers] = useState({});
    const [sections, setSections] = useState({
        clientDetails: false,
        scope: false,
        quotes: false,
        summary: false,
        disclaimers: false,
    });
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleReviewStatement = () => {
        setIsPopupOpen(true);
    };

    const toggleSection = (section) => {
        setSections(prevSections => ({ ...prevSections, [section]: !prevSections[section] }));
    };

    const generateIframeContent = () => {
        const content = (
            <AdvicePDF
                sections={sections}
                proposal={proposal}
                disclaimers={disclaimers}
                toggleSection={toggleSection}
            />
        );
        return ReactDOMServer.renderToStaticMarkup(content);
    };

    const printComponent = () => {
        

        const iframe = document.createElement('iframe');
        iframe.style.position = 'fixed';
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.border = 'none';
        

        document.body.appendChild(iframe);
        

        const doc = iframe.contentWindow.document.open();
        const htmlContent = generateIframeContent();
        

        // Copy styles from the main document to the iframe
        const styles = Array.from(document.styleSheets)
            .map(styleSheet => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map(rule => rule.cssText)
                        .join('');
                } catch (e) {
                    // Avoiding cross-origin restrictions
                    return '';
                }
            })
            .join('');

        doc.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>AdvicePDF</title>
                <style>
                    @page { 
                        margin: 20mm 10mm; /* Adjust margins for all pages */
                    }
                    body { 
                        margin: 0; 
                        padding: 20px; 
                        background-color: #fff; 
                    }
                    .no-interaction {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0);
                        z-index: 9999;
                        pointer-events: none;
                    }
                    .no-page-break {
                        page-break-inside: avoid;
                    }
                    ${styles}
                </style>
            </head>
            <body>
                <div style="position: relative;">
                    ${htmlContent}
                    <div class="no-interaction"></div>
                </div>
            </body>
            </html>
        `);
        

        doc.close();
        

        iframe.onload = () => {
            
            iframe.contentWindow.focus();
            

            setTimeout(() => {
                iframe.contentWindow.print();
                

                document.body.removeChild(iframe);
                
            }, 1000); // Adding a delay to ensure content is fully loaded
        };
    };


    return (
        <div>
            <div className="statement-of-advice">
                <h2>Build a Proposal</h2>
                <h3>{activeClient?.personalDetails?.firstName} {activeClient?.personalDetails?.lastName} | Type: {activeDocument?.policyType} | Current: {activeDocument?.policyProvider}</h3>
                <div className={`statement-of-advice-white ${!activeDocument ? 'hide-analysis-dashboard' : ''}`} style={isCollapsed ? { width: 'calc(100vw - 210px)' } : { width: 'calc(100vw - 380px)' }}>
                    <Proposal
                        sections={sections}
                        toggleSection={toggleSection}
                        proposal={proposal}
                        setProposal={setProposal}
                    />
                    <div className='advice-button-wrapper'>
                        <button
                            className="advice-review-button"
                            onClick={() => handleReviewStatement()}>
                            Review Proposal
                        </button>
                        <button
                            className="advice-review-button"
                            onClick={() => handleReviewStatement()}>
                            Send to Client
                        </button>
                    </div>
                    <AdvicePopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} onPrint={printComponent}>
                        <div ref={printRef}>
                            <AdvicePDF
                                sections={sections}
                                proposal={proposal}
                                disclaimers={disclaimers}
                                toggleSection={toggleSection}
                            />
                        </div>
                    </AdvicePopup>
                </div>
            </div>
        </div>
    );
};

export default Advice;
