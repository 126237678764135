import React, { useState, useEffect } from 'react';
import ToggleSwitch from '../ToggleSwitch';
import GetStartedBanner from '../GetStartedBanner';
import StarRating from '../StarRating';
import ProfileIconURL from "../../icons/profile_grey.png";
import MyPostCodeMapComponent from '../MyPostCodeMapComponent';
import './BrokerDirectory.css';

const BrokerDirectory = () => {
    const [activeTab, setActiveTab] = useState('grid'); // 'map' or 'grid'
    const [showMore, setShowMore] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showMoreInsurers, setShowMoreInsurers] = useState(false);
    const [activeFilter, setActiveFilter] = useState(''); // 'business' or 'personal'
    const [postcodeFilter, setPostcodeFilter] = useState('');
    const [selectedInsuranceType, setSelectedInsuranceType] = useState('');
    const [selectedBusinessType, setSelectedBusinessType] = useState('');
    const [brokers, setBrokers] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({
        email: '',
        phone: '',
        comments: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50); // Set 50 by default
    const [sortOrder, setSortOrder] = useState('');
    const [nearestBrokers, setNearestBrokers] = useState([]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [showInfo, setShowInfo] = useState(false); // New state for showing explanations

    const [formData, setFormData] = useState({
        logo: '',
        name: '',
        suburb: '',
        postcode: '',
        email: '',
        phone: '',
        website: '',
        products: [],
        insurers: [],
    });
    const [ratings, setRatings] = useState({
        availability: 0,
        adviceQuality: 0,
        claimsAssistance: 0,
        easeToDealWith: 0,
    });
    const [formVisible, setFormVisible] = useState(false);
    const [feedback, setFeedback] = useState({
        name: '',
        email: '',
        phone: '',
        comments: '',
    });
    const businessTypes = [
        "All",
        "Retail",
        "Construction",
        "Healthcare",
        "Education",
        "Finance",
        "Technology",
        "Hospitality",
        "Manufacturing",
        "Transportation",
        "Real Estate",
        "Insurance",
        "Consulting",
        "Legal",
        "Media",
        "Agriculture",
        "Telecommunications",
        "Energy",
        "Pharmaceutical",
        "Biotechnology",
        "Engineering",
        "Architecture",
        "Entertainment",
        "Food & Beverage",
        "Aerospace",
        "Automotive",
        "Chemical",
        "Consumer Goods",
        "Defense",
        "Electronics",
        "Environmental Services",
        "Mining",
        "Publishing",
        "Sports",
        "Travel & Tourism",
        "Non-Profit",
        "Government",
        "Utilities",
        "Advertising",
        "Human Resources",
        "Software Development",
        "Data Analysis",
        "Event Planning",
        "Fashion",
        "Interior Design",
        "Logistics",
        "Marine",
        "Printing",
        "Security",
        "Textiles",
        "Veterinary"
    ];

    const insuranceTypes = [
        "All",
        "General",
        "home",
        "Auto",
        "Health",
        "life",
        "travel",
        "Pet",
        "Business",
        "Cyber",
        "Disability",
        "Renters",
        "landlord",
        "Flood",
        "Earthquake",
        "Marine",
        "Aviation",
        "Credit",
        "Workers' Compensation",
        "Liability",
        "Professional Liability",
        "Agriculture",
        "Crop",
        "Livestock",
        "Boat",
        "Motorcycle",
        "RV",
        "Umbrella",
        "Personal Property",
        "Jewelry",
        "Fine Arts",
        "Event",
        "Kidnap and Ransom",
        "Directors and Officers",
        "Errors and Omissions",
        "Fiduciary Liability",
        "Environmental",
        "Commercial Auto",
        "Product Liability",
        "Public Liability",
        "Employers Liability",
        "Bond",
        "Reinsurance",
        "Construction",
        "Engineering",
        "Technology",
        "Media",
        "Hospitality",
        "Food & Beverage",
        "Transportation"
    ];

    const nzInsurers = [
        "All",
        "AIA New Zealand",
        "AMP",
        "ANZ",
        "Asteron Life",
        "BNZ Insurance",
        "Cigna",
        "Fidelity Life",
        "FMG Insurance",
        "MAS",
        "NIB New Zealand",
        "Partners Life",
        "Sovereign",
        "Southern Cross",
        "Tower Insurance",
        "Vero",
        "Westpac"
    ];

    const excludeNames = ['Broker', 'Admin', 'Support', 'Paypal', 'Office', 'N/A'];

    useEffect(() => {
        const fetchBrokers = async () => {
            setLoading(true);
            try {
                const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/api/get-brokers');
                if (!response.ok) {
                    throw new Error('Failed to fetch brokers');
                }
                let data = await response.json();
                data = data.filter(broker => {
                    const brokerName = broker.name && broker.name.trim();
                    return brokerName !== '' && !excludeNames.includes(brokerName);
                });
                setBrokers(data);

            } catch (error) {
                console.error('Error fetching brokers:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBrokers();
    }, []);

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, index) => (
            <span key={index} className={index < rating ? 'broker-directory-gold-star' : 'broker-directory-grey-star'}>
                ★
            </span>
        ));
    };

    const handleSearch = () => {
        // Add search/filter logic based on selectedInsuranceType, selectedBusinessType, and suburbFilter
    };

    const calculateDistance = (postcode1, postcode2) => {
        if (!postcode1 || !postcode2) return 0;
        const distance = Math.abs(parseInt(postcode1, 10) - parseInt(postcode2, 10)) * 10;
        return distance;
    };

    const getNearestBrokers = (postcodeFilter, brokers) => {
        if (!postcodeFilter) return [];
        return brokers
            .map(broker => ({
                ...broker,
                distance: calculateDistance(broker.postcode, postcodeFilter)
            }))
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 10); // Get top 10 nearest brokers
    };

    function doesPostcodeMatch(brokerPostcode, filterPostcode) {
        if (!brokerPostcode || !filterPostcode) {
            return false;
        }

        // Normalize to lowercase for case-insensitive comparison (in case of non-numeric postcodes)
        brokerPostcode = brokerPostcode.toLowerCase();
        filterPostcode = filterPostcode.toLowerCase();

        // Log the postcodes for debugging

        // Check if the postcode contains the filter substring
        if (brokerPostcode.includes(filterPostcode)) {
            return true;
        }

        // If the filter is a number, check if the broker postcode starts with the first two digits of the filter
        if (!isNaN(filterPostcode)) {
            const filterPrefix = filterPostcode.slice(0, 2); // Get the first two digits of the filter
            const brokerPrefix = brokerPostcode.slice(0, 2); // Get the first two digits of the broker postcode


            if (brokerPrefix === filterPrefix) {
                return true;
            }
        }

        return false;
    }


    const filteredBrokers = brokers
        .filter(broker => {
            const matchesType = activeFilter ? broker.type === activeFilter : true;
            const matchesPostcode = postcodeFilter ? doesPostcodeMatch(broker.postcode, postcodeFilter) : true;
            const matchesInsuranceType = selectedInsuranceType ? broker.products.includes(selectedInsuranceType) : true;
            const matchesBusinessType = selectedBusinessType ? broker.products.includes(selectedBusinessType) : true;

            // Calculate and assign distance if postcode filter is applied
            if (postcodeFilter && broker.postcode) {
                broker.distance = calculateDistance(broker.postcode, postcodeFilter);
            }

            return matchesType && matchesPostcode && matchesInsuranceType && matchesBusinessType;
        })
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name);
            } else if (sortOrder === 'desc') {
                return b.name.localeCompare(a.name);
            } else {
                return 0;
            }
        });

    const totalRecords = filteredBrokers.length;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const brokersToDisplay = filteredBrokers.slice(startIndex, endIndex);

    useEffect(() => {
        if (postcodeFilter.length > 3) {

            const nearest = getNearestBrokers(postcodeFilter, brokers);
            setNearestBrokers(nearest);

        }
    }, [postcodeFilter, brokers]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleClaimClick = (broker) => {
        setPopupVisible(true);
        if (broker) {
            setPopupData(broker);
            setFormData({
                logo: '',
                name: broker.name || '',
                suburb: broker.suburb || '',
                postcode: broker.postcode || '',
                email: broker.email || '',
                phone: broker.phone || '',
                website: broker.website || '',
                products: broker.products || [],
                insurers: broker.affiliatedInsurers || [],
            });
        } else {
            setPopupData(null);
            setFormData(
                {
                    logo: '',
                    name: '',
                    suburb: '',
                    postcode: '',
                    email: '',
                    phone: '',
                    website: '',
                    products: [],
                    insurers: [],

                });

        }
        document.body.classList.add('no-scroll'); // Prevent background scroll      
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        // Submit feedback and ratings logic here
        console.log('Feedback submitted:', { ratings, feedback });
    };

    const handleClosePopup = () => {
        setFormVisible(false);
        setPopupVisible(false);
        setPopupData(null);
        document.body.classList.remove('no-scroll'); // Re-enable background scroll
        setSubmitted(false);

    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhone = (phone) => {
        const regex = /^\d{8,}$/;
        return regex.test(phone);
    };

    const validateComments = (comments) => {
        const wordCount = comments.trim().match(/\b\w+\b/g)?.length || 0;
        return wordCount >= 10;
    };

    const handleRatingChange = (category, stars) => {
        if (selectedBroker) {
            setSelectedBroker({
                ...selectedBroker,
                detailedRatings: {
                    ...selectedBroker.detailedRatings,
                    [category]: stars,
                },
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFeedback({
            ...feedback,
            [name]: value,
        });

        // Validate fields
        let error = '';
        if (name === 'email' && !validateEmail(value)) {
            error = 'Invalid email address';
        } else if (name === 'phone' && !validatePhone(value)) {
            error = 'Phone number must be at least 8 digits';
        } else if (name === 'comments' && !validateComments(value)) {
            error = 'Comments must be at least 10 words';
        }

        setErrors({
            ...errors,
            [name]: error,
        });
    };

    const handleSubmitFeedback = async (e) => {
        e.preventDefault();

        // API stub: Check if the user has already given feedback
        const userHasGivenFeedback = await checkUserFeedback(selectedBroker.id, feedback.email);
        if (userHasGivenFeedback) {
            setError('You have already given feedback for this broker.');
            return;
        }

        // API stub: Send feedback to the server and store user as a lead
        await sendFeedback(selectedBroker.id, feedback);

        // Update the broker's aggregate rating
        const updatedBrokers = brokers.map((broker) => {
            if (broker.id === selectedBroker.id) {
                const updatedBroker = {
                    ...broker,
                    aggregateRating: calculateAggregateRating(selectedBroker.detailedRatings),
                    userFeedback: [...broker.userFeedback, feedback],
                };
                return updatedBroker;
            }
            return broker;
        });

        setBrokers(updatedBrokers);
        setSubmitted(true);
        setFormVisible(false);
    };

    const calculateAggregateRating = (detailedRatings) => {
        const totalStars = Object.values(detailedRatings).reduce((acc, curr) => acc + curr, 0);
        return (totalStars / Object.keys(detailedRatings).length).toFixed(1);
    };

    const handleAggregateRatingClick = (broker) => {
        setSelectedBroker(broker);
        setFormVisible(true);
        setFeedback({
            name: '',
            email: '',
            phone: '',
            comments: '',
        });
        setSubmitted(false);
    };

    const checkUserFeedback = async (brokerId, email) => {
        // API stub to check if the user has already given feedback
        // Return true if feedback already exists for this email and brokerId
        return false;
    };

    const sendFeedback = async (brokerId, feedback) => {
        // API stub to send feedback and user details to the server
        console.log('Feedback sent to server:', { brokerId, feedback });
    };


    const handleShowInfo = () => {
        setShowInfo(true); // Show the explanations
    };

    const handleBackToForm = () => {
        setShowInfo(false); // Show the feedback form again
    };

    if (error) return <div>Error: {error}</div>;

    return (
        <div className="broker-directory-page">
            <div className="broker-directory-container">
                <div className="broker-directory-search-bar">
                    <h2>Find a Broker</h2>
                    <div className="broker-directory-search-question">
                        <p>What type of Insurance are you looking for?</p>
                        <button
                            className={`broker-directory-filter-button ${activeFilter === 'business' ? 'active' : ''}`}
                            onClick={() => setActiveFilter('business')}
                        >
                            Business
                        </button>
                        <button
                            className={`broker-directory-filter-button ${activeFilter === 'personal' ? 'active' : ''}`}
                            onClick={() => setActiveFilter('personal')}
                        >
                            Personal
                        </button>
                    </div>

                    <div className="broker-directory-search-question">
                        <p>What type of insurance do you need?</p>
                        <select>
                            <option value="">Insurance type</option>
                            {insuranceTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="broker-directory-search-question">
                        <p>Or, what type of business are you looking to insure?</p>
                        <select>
                            {businessTypes.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="broker-directory-search-question">
                        <p>Where are you located?</p>
                        <input
                            type="text"
                            placeholder="Enter your postcode"
                            onChange={(e) => setPostcodeFilter(e.target.value)}
                        />
                    </div>

                    <button className="broker-directory-search-button" onClick={handleSearch}>Search Now</button>
                </div>
                <div className="broker-directory-results-section">
                    <div className="broker-directory-results-section-header">
                        <p>Showing {brokersToDisplay.length} of {totalRecords} brokers</p>
                        <div className="broker-directory-controls">
                            <select
                                className="broker-directory-sort-order"
                                value={sortOrder}
                                onChange={(e) => {
                                    setSortOrder(e.target.value);
                                }}
                            >
                                <option value="asc">Alphabetical Ascending</option>
                                <option value="desc">Alphabetical Descending</option>
                            </select>
                            <select
                                className="broker-directory-items-per-page"
                                value={recordsPerPage}
                                onChange={(e) => {
                                    const value = e.target.value === 'all' ? totalRecords : parseInt(e.target.value, 10);
                                    setRecordsPerPage(value);
                                    setCurrentPage(1);
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value="all">All</option>
                            </select>
                            <ToggleSwitch
                                height={'30px'}
                                radius={'5px'}
                                labels={['GRID', 'MAP']}
                                onActiveChange={() => setActiveTab((activeTab === 'map') ? 'grid' : 'map')}
                                className="toggle-switch-container"
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        activeTab === 'map' ? (
                            <div className="broker-directory-map-tab">
                                <MyPostCodeMapComponent brokers={nearestBrokers} />
                            </div>
                        ) : (
                            <div className="broker-directory-grid-tab">
                                {brokersToDisplay.map((broker, index) => (
                                    <div key={index} className="broker-directory-broker-card">
                                        <img
                                            src={broker.logo}
                                            alt={`${broker.name} logo`}
                                            className="broker-directory-broker-logo"
                                            onError={(e) => e.target.style.display = 'none'}
                                        />
                                        {!broker.logo || !broker.logo.trim() ? (
                                            <div className="broker-directory-logo-placeholder">
                                                <img src={ProfileIconURL} alt={`${broker.name}`} className="broker-directory-logo-placeholder-image" />
                                            </div>
                                        ) : null}
                                        
                                        {postcodeFilter !== '' && (
                                            <div className="broker-directory-broker-distance">{broker.distance} km away</div>
                                        )}
                                        {broker.name && broker.name !== "N/A" && <h3>{broker.name}</h3>}
                                        <div className="aggregate-rating" onClick={() => handleAggregateRatingClick(broker)}>
                                            <p>Aggregate Rating:</p>
                                            <StarRating
                                                rating={(broker.aggregateRating) || 2}
                                            />
                                        </div>
                                        {broker.suburb && broker.suburb !== "N/A" && <p>{broker.suburb}</p>}
                                        {broker.postcode && broker.postcode !== "N/A" && <p>Postcode: {broker.postcode}</p>}
                                        {/* {broker.email && broker.email !== "N/A" && <p>Email: {broker.email}</p>}
                                        {broker.phone && broker.phone !== "N/A" && <p>Phone: {broker.phone}</p>} */}
                                        {broker.website && broker.website !== "N/A" && (
                                            <p>Website: <a href={`${broker.website}`} target="_blank" rel="noopener noreferrer">{broker.website}</a></p>
                                        )}
                                        {broker.memberSince && broker.memberSince !== "N/A" && <p>Member since: {broker.memberSince}</p>}
                                        {broker.products && broker.products.length > 0 && <p>Products: {broker.products.join(', ')}</p>}
                                        {broker.affiliatedInsurers && broker.affiliatedInsurers.length > 0 && <p>Affiliated Insurers: {broker.affiliatedInsurers.join(', ')}</p>}
                                        <div
                                            className={`broker-status ${broker.status === 'active' ? 'active' : 'claim'}`}
                                            onClick={() => handleClaimClick(broker)}
                                        >
                                            {broker.status === 'active' ? 'Active' : 'Claim'}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    )}

                    <div className="broker-directory-footer">
                        <div className="broker-directory-footer-register" onClick={() => handleClaimClick()}>
                            Can't find your details? Register here....
                        </div>
                        <div className="pagination">
                            <button disabled={currentPage === 1} onClick={() => handlePageChange(1)}>{'<'}</button>
                            {[...Array(totalPages).keys()].map(pageNumber => (
                                <button
                                    key={pageNumber + 1}
                                    className={currentPage === pageNumber + 1 ? 'active' : ''}
                                    onClick={() => handlePageChange(pageNumber + 1)}
                                >
                                    {pageNumber + 1}
                                </button>
                            ))}
                            <button disabled={currentPage === totalPages} onClick={() => handlePageChange(totalPages)}>{'>'}</button>
                        </div>
                    </div>
                </div>
            </div>
            <GetStartedBanner banner="Brokers: Register to claim your profile" height='500px' />
            {isPopupVisible && (
                <div className="popup-overlay" onClick={handleClosePopup}>
                    <div className="directory-popup-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-popup" onClick={handleClosePopup}>&times;</span>
                        <h3>Claim or Update</h3>
                        {!submitted ? (
                            <div className='directory-popup-content-scroll'>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="broker-form-group">
                                        <label>Profile</label>
                                        <input type="file" name="logo" onChange={handleFormChange} />
                                    </div>
                                    <div className="broker-form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            disabled={formData.name !== null && formData.name !== undefined && formData.name !== ''}
                                        />                                    </div>
                                    <div className="broker-form-group">
                                        <label>Suburb</label>
                                        <input type="text" name="suburb" value={formData.suburb === "N/A" ? '' : formData.suburb} onChange={handleFormChange} />
                                    </div>
                                    <div className="broker-form-group">
                                        <label>Postcode</label>
                                        <input type="text" name="postcode" value={formData.postcode} onChange={handleFormChange} maxLength="4" pattern="\d{4}" required />
                                    </div>
                                    <div className="broker-form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" value={formData.email} onChange={handleFormChange} required />
                                    </div>
                                    <div className="broker-form-group">
                                        <label>Phone</label>
                                        <input type="tel" name="phone" value={formData.phone} onChange={handleFormChange} />
                                    </div>
                                    <div className="broker-form-group">
                                        <label>Website</label>
                                        <input type="url" name="website" value={formData.website} onChange={handleFormChange} required pattern=".*\..*" />
                                    </div>
                                    <div className="broker-check-form-group">
                                        <div className='broker-check-form-group-label'>Key Products</div>
                                        <div className="checkbox-grid">
                                            {insuranceTypes.slice(0, 4).map((type, index) => (
                                                <label key={index} className="checkbox-label">
                                                    <input type="checkbox" value={type} />
                                                    {type}
                                                </label>
                                            ))}
                                            {showMore && insuranceTypes.slice(4).map((type, index) => (
                                                <label key={index} className="checkbox-label">
                                                    <input type="checkbox" value={type} />
                                                    {type}
                                                </label>
                                            ))}
                                            {insuranceTypes.length > 4 && (
                                                <div className="show-more">
                                                    <div onClick={() => setShowMore(!showMore)}>
                                                        {showMore ? "Show less options" : "Show more options"}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="broker-check-form-group">
                                        <label>Affiliated Insurers</label>
                                        <div className="checkbox-grid">
                                            {nzInsurers.slice(0, 4).map((type, index) => (
                                                <label key={index} className="checkbox-label">
                                                    <input type="checkbox" value={type} />
                                                    {type}
                                                </label>
                                            ))}
                                            {showMoreInsurers && nzInsurers.slice(4).map((type, index) => (
                                                <label key={index} className="checkbox-label">
                                                    <input type="checkbox" value={type} />
                                                    {type}
                                                </label>
                                            ))}
                                            {insuranceTypes.length > 4 && (
                                                <div className="show-more">
                                                    <div onClick={() => setShowMoreInsurers(!showMoreInsurers)}>
                                                        {showMoreInsurers ? "Show less options" : "Show more options"}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className='directory-popup-content-thanks'>
                                <div className='directory-popup-content-thanks-title'>Thank you!</div>
                                <div>We will review your application and get back to you via email in the next 48 hours.</div>
                            </div>
                        )}
                        {!submitted ? (
                            <button className='directory-popup-content-button' onClick={handleFormSubmit}>Submit</button>
                        ) : (
                            <button className='directory-popup-content-button' onClick={handleClosePopup}>Done</button>
                        )}

                    </div>
                </div>
            )}
            {formVisible && selectedBroker && (
                <div className="popup-overlay" onClick={handleClosePopup}>
                    <div className="directory-popup-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-popup" onClick={handleClosePopup}>&times;</span>
                        {!showInfo ? (
                            <>
                                <div className='directory-popup-content-header'><strong>Broker Review: </strong> {selectedBroker.name}</div>
                                <div className='directory-popup-content-body'>
                                    <div className='directory-popup-content-body-segment'>
                                        <div className='directory-popup-content-body-segment-label'>
                                            Your Feedback
                                        </div>
                                        <div className="rating-category">
                                            <p>Availability:</p>
                                            <StarRating
                                                rating={selectedBroker?.detailedRatings?.availability || 2}
                                                onRating={(stars) => handleRatingChange('availability', stars)}
                                            />
                                        </div>
                                        <div className="rating-category">
                                            <p>Advice Quality:</p>
                                            <StarRating
                                                rating={selectedBroker?.detailedRatings?.adviceQuality || 2}
                                                onRating={(stars) => handleRatingChange('adviceQuality', stars)}
                                            />
                                        </div>
                                        <div className="rating-category">
                                            <p>Claims Assistance:</p>
                                            <StarRating
                                                rating={selectedBroker?.detailedRatings?.claimsAssistance || 2}
                                                onRating={(stars) => handleRatingChange('claimsAssistance', stars)}
                                            />
                                        </div>
                                        <div className="rating-category">
                                            <p>Easy to Deal With:</p>
                                            <StarRating
                                                rating={selectedBroker?.detailedRatings?.easeToDealWith || 2}
                                                onRating={(stars) => handleRatingChange('easeToDealWith', stars)}
                                            />
                                        </div>
                                        {/* "i" Button for info */}
                                        <div className="rating-info-button" onClick={handleShowInfo}>
                                            Find out more about the ratings:
                                            <div className="rating-info-circle">i</div>
                                        </div>
                                    </div>
                                    <div className='directory-popup-content-body-segment'>
                                        <div className='directory-popup-content-body-segment-label'>
                                            Your information
                                        </div>
                                        <div className="broker-form-group">
                                            <label>Name:</label>
                                            <input type="text" name="name" value={feedback.name} onChange={handleInputChange} required />
                                        </div>
                                        <div className="broker-form-group">
                                            <label>Email:</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={feedback.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.email && <p className="error">{errors.email}</p>}
                                        </div>
                                        <div className="broker-form-group">
                                            <label>Phone:</label>
                                            <input type="tel" name="phone" value={feedback.phone} onChange={handleInputChange} required />
                                            {errors.phone && <p className="error">{errors.phone}</p>}
                                        </div>
                                        <div className="broker-form-group">
                                            <label>Comments:</label>
                                            <textarea
                                                name="comments"
                                                value={feedback.comments}
                                                onChange={handleInputChange}
                                                required
                                            ></textarea>
                                            {errors.comments && <p className="error">{errors.comments}</p>}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className='feedback-form-submit'
                                    onClick={handleSubmitFeedback}
                                    disabled={
                                        !validateEmail(feedback.email) ||
                                        !validatePhone(feedback.phone) ||
                                        !validateComments(feedback.comments)
                                    }
                                >
                                    Submit
                                </button>
                                <p className="disclaimer">* Please be professional. Your feedback will be reviewed before being published. Please see our Terms & Conditions.</p>

                            </>
                        ) : (
                            <>
                                <div className='directory-popup-content-header'><strong>Review Categories Explained</strong></div>
                                <div className='directory-popup-content-body'>
                                    <div className='directory-popup-content-body-segment'>
                                        <p><strong>Availability:</strong> How easily accessible was the broker when you needed them?</p>
                                        <p><strong>Advice Quality:</strong> How clear, relevant, and helpful was the advice provided?</p>
                                        <p><strong>Claims Assistance:</strong> How effectively did the broker assist you with any claims?</p>
                                        <p><strong>Easy to Deal With:</strong> How straightforward and pleasant was your interaction with the broker?</p>
                                        <p><strong>Rating Guide:</strong></p>
                                        <ul>
                                            <li><strong>1 Star:</strong> Very poor, not satisfied at all.</li>
                                            <li><strong>5 Stars:</strong> Excellent, highly satisfied.</li>
                                        </ul>
                                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                            <button className='directory-popup-content-button' onClick={handleBackToForm}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )
            }

            {submitted && <p>Thank you for your feedback!</p>}
        </div >
    );
};

export default BrokerDirectory;
