import './Icons.css'; // Importing the CSS for styling

const getStatusIcon = (status) => {
    switch (status) {
        case 'Denied':
            return (
            <div className="claims-icon">
                <div className="claims-icon-denied" style={{fontSize:'8pt'}}>!</div>
                <div className="claims-icon-label" style={{color:'#db6457'}}>Denied</div>
            </div>);
        case 'Approved':
            return (
            <div className="claims-icon">
                <div className="claims-icon-approved">✓</div>
                <div className="claims-icon-label" style={{color:'#4FA658'}}>Approved</div>
            </div>);
        case 'Pending':
            return (
            <div className="claims-icon">
                <div className="claims-icon-pending">?</div>
                <div className="claims-icon-label" style={{color:'#e89c31'}}>Pending</div>
            </div>);
        case 'Under Review':
            return (
            <div className="claims-icon">
                <div className="claims-icon-under-review">-</div>
                <div className="claims-icon-label" style={{color:'#4a5ff8'}}>Under Review</div>
            </div>);
        default:
            return null; // Default case
    }
};

export default getStatusIcon;