import React, { useContext, useState, forwardRef, useEffect, useRef } from 'react';
import { FiltersContext } from '../context/FiltersContext';
import { useGlobalContext } from '../context/GlobalContext';
import { useCompareContext } from '../context/CompareContext';
import { CountryContext } from '../context/CountryContext';
import './SearchButtonDropDown.css';

const SearchButtonDropDown = forwardRef(({ onClose, top = '30px', left, right, width = '400px', popup = true }, ref) => {
  const { activeDocument } = useGlobalContext();
  const { compareDocument } = useCompareContext();
  const { country } = useContext(CountryContext);
  const [searchTerm, setSearchTerm] = useState('');
  const { globalFilters, setGlobalFilters } = useContext(FiltersContext);

  const dropdownStyles = {
    top: top,
    width: width,
    ...(left !== undefined ? { left: left } : {}),
    ...(right !== undefined ? { right: right } : {}),
  };

  const toggleProviderVisibility = (provider) => {
    const updatedFilters = globalFilters.map(filter =>
      filter.name === provider.name &&
        filter.name !== compareDocument?.policyProvider &&
        filter.name !== activeDocument?.policyProvider ? { ...filter, checked: !provider.checked } : filter
    );
    setGlobalFilters(updatedFilters);
  };

  const clearAll = () => {
    const updatedFilters = globalFilters.map(filter => ({
      ...filter,
      checked: filter.name !== compareDocument?.policyProvider &&
        filter.name !== activeDocument?.policyProvider ? false : filter.checked
    }));
    setGlobalFilters(updatedFilters);
  };

  const showAll = () => {
    const updatedFilters = globalFilters.map(filter => ({
      ...filter,
      checked: true
    }));
    setGlobalFilters(updatedFilters);
    setSearchTerm('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      console.error('onClose prop is not a function');
    }
  };

  const renderFilterPopup = () => (
    <>
      {popup && (
        <div className="search-button-drop-down-container-popup-header">

          <input
            type="text"
            className="search-button-drop-down-container-popup-search"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="search-button-drop-down-container-popup-header-close" onClick={handleClose}>&times;</div>
        </div>
      )}
      <div className='search-input-popup-scroll'>
        <div className='search-input-popup-grid'>
          {Object.entries(globalFilters)
            .sort((a, b) => a[0].localeCompare(b[0])) // Sort alphabetically by provider name
            .filter(filter => {
              return country === filter[1].country && filter[1].label.toLowerCase().includes(searchTerm.toLowerCase())
            })
            .map(([key, provider], index) => (
              <div className='search-input-popup-item' key={index}
                onClick={() => toggleProviderVisibility(provider)}>
                <input
                  className='search-input-popup-checkbox'
                  type="checkbox"
                  checked={provider.checked}
                  id={`checkbox-${provider.name}`}
                  onClick={() => toggleProviderVisibility(provider)}
                />
                <div className='search-input-popup-item-label' htmlFor={`checkbox-${provider.name}`}>{provider.label}</div>
              </div>
            ))}
        </div>
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <button className="search-button-drop-down-container-popup-search-clear" onClick={clearAll}>Clear all</button>
        <button className="search-button-drop-down-container-popup-search-clear" onClick={showAll}>Show all</button>
      </div>

    </>
  );

  return (<>
    {popup ? (
      <div className="search-button-drop-down-container" ref={ref} style={dropdownStyles}>
        {renderFilterPopup()}
      </div>
    ) : (
      <>
        {renderFilterPopup()}
      </>
    )
    }
  </>
  );
});

export default SearchButtonDropDown;
