import React from 'react';
import { useParams } from 'react-router-dom';
import ProfileIconURL from "../../icons/profile_grey.png";
import { leadershipProfiles } from './profileData'; // Import the profiles data
import SocialIcons from '../social/SocialIcons';

import './ProfilePage.css';
import { ProviderPolicyListContext } from '../../context/ProviderPolicyListContext';

const ProfilePage = () => {
  const { memberId } = useParams(); // Extract the team member ID from the URL

  const profile = leadershipProfiles.find(profile => profile.link === `/team/${memberId}`);

  if (!profile) {
    return <div>Profile not found</div>;
  }

  const paragraphs = profile.description.split('\n').filter(paragraph => paragraph.trim() !== '');


  return (
    <div className="profile-page">
      <div className="profile-page-container">
        <div className="profile-page-header">
          <div className="profile-page-header-name">
            <h2 className="profile-page-name">{profile.name}</h2>
            <p className="profile-page-bio">{profile.bio}</p>
          </div>
          <img src={ProfileIconURL} alt={`${profile.name}'s profile`} className="profile-page-image" />
        </div>
        {/* {paragraphs.map((paragraph, index) => (
          <p key={index} className="profile-page-description">{paragraph}</p>
        ))} */}
        <div className="profile-page-footer">
          You can follow {profile.name} on
          <br />
          <br />
          {/* <SocialIcons disabled={false} */}
          <SocialIcons disabled={true}
            twitterLink={profile.twitterLink}
            facebookLink={profile.facebookLink}
            linkedinLink={profile.linkedinLink}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
