// LightningBot.js
import React from 'react';
import './LightningBolt.css';

const LightningBolt = () => {
  return (
    <div className="lightning-bot-container">
      <svg className="lightning-bot" viewBox="0 0 200 300">
        <path d="M 100,20 L 70,120 L 130,120 L 80,280 L 100,160 L 60,160 Z" />
      </svg>
    </div>
  );
};

export default LightningBolt;
