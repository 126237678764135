import React, { useState, useEffect, useRef } from 'react';
import { useClient } from '../../context/ClientContext';
import SettingsURL from "../../icons/notes.png";
import './Notes.css';

function Notes() {
    const [isOpen, setIsOpen] = useState(false);
    const [editingNoteIndex, setEditingNoteIndex] = useState(null);
    const [editedNote, setEditedNote] = useState("");
    const [editedTitle, setEditedTitle] = useState("");
    const ref = useRef(null);
    const { activeClient, updateClient } = useClient();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
            if (editingNoteIndex !== null) {
                saveNote();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [editingNoteIndex]);

    const startEditingNote = (index, note, title) => {
        setEditingNoteIndex(index);
        setEditedNote(note);
        setEditedTitle(title);
    };

    const saveNote = () => {
        if (editingNoteIndex !== null && activeClient) {
            const updatedClient = { ...activeClient };
            updatedClient.crmDataPoints.notes[editingNoteIndex].note = editedNote;
            updatedClient.crmDataPoints.notes[editingNoteIndex].title = editedTitle;
            updateClient(updatedClient);
            setEditingNoteIndex(null);
            setEditedNote("");
            setEditedTitle("");
        }
    };

    const handleNoteChange = (event) => {
        setEditedNote(event.target.value);
    };

    const handleTitleChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleSaveClick = () => {
        saveNote();
    };

    const handleAddNote = () => {
        if (activeClient) {
            const newNote = {
                date: new Date(),
                title: "Summary...",
                note: "Note...",
                author: "System"
            };
            const updatedClient = { ...activeClient };
            updatedClient.crmDataPoints.notes.push(newNote);
            updateClient(updatedClient);
            setEditingNoteIndex(updatedClient.crmDataPoints.notes.length - 1);
            setEditedNote(newNote.note);
            setEditedTitle(newNote.title);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="notes-container" ref={ref}>
            <div className="notes-icon" onClick={() => setIsOpen(!isOpen)}>
                <img src={SettingsURL} alt="Click me" className="notes-icon" />
            </div>

            {isOpen && (
                <div className="notification-panel" style={{ width: '600px' }}>
                    <div className='notification-panel-header'>
                        <div>
                            <strong>Notes:&nbsp;</strong> {activeClient?.personalDetails?.firstName} {activeClient?.personalDetails?.lastName}
                        </div>
                        <button onClick={handleAddNote}>+</button>
                    </div>
                    <ul>
                        {activeClient?.crmDataPoints?.notes?.map((note, index) => (
                            <li key={index}>
                                {editingNoteIndex === index ? (
                                    <>
                                        <div className="notes-edit-container">
                                            <input
                                                className='notes-title-input'
                                                value={editedTitle}
                                                onChange={handleTitleChange}
                                                autoFocus
                                            />
                                            <textarea
                                                className='notes-textarea'
                                                value={editedNote}
                                                onChange={handleNoteChange}
                                                onBlur={saveNote}
                                            />
                                            <div className="notes-button-wrapper">
                                                <button
                                                    className="notes-save-button"
                                                    onClick={handleSaveClick}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="notes-title" onClick={() => startEditingNote(index, note.note, note.title)}>
                                            {note.title}
                                        </div>
                                        <div onClick={() => startEditingNote(index, note.note, note.title)}>
                                            {note.note}
                                        </div>
                                        <span className="notes-date">Last updated: {formatDate(note.date)}</span>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Notes;
