import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import './ProfileIcon.css';

// Importing icons
import IconURLDemo from '../../icons/profiles/demo.png';
import IconURL1 from '../../icons/profiles/E.png';
import IconURL2 from '../../icons/profiles/B.png';
import IconURL3 from '../../icons/profiles/C.png';
import IconURL4 from '../../icons/profiles/D.png';
import IconURL5 from '../../icons/profiles/A.png';
import IconURL6 from '../../icons/profiles/F.png';
import IconURL7 from '../../icons/profiles/G.png';

// Icon array
export const icons = [
  IconURLDemo, IconURL1, IconURL2, IconURL3, IconURL4, IconURL5,
  IconURL6, IconURL7
];

const ProfileIcon = ({ size = 40 }) => {
  const [userContext] = useContext(UserContext);
  const [iconIndex, setIconIndex] = useState(userContext?.details?.userIcon || null);

  useEffect(() => {
    setIconIndex(userContext?.details?.userIcon);
  }, [userContext]);

  return (
    <div className="profile-icon-container" style={{ width: size, height: size, borderRadius:'50%', overflow: 'hidden' }}>
      <img
        src={icons[iconIndex || 0]} // Use the selected icon
        alt="Profile Icon"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

// ChangeProfileIcon Component with Custom Image Upload
export const ChangeProfileIcon = ({ onClose }) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [selectedIcon, setSelectedIcon] = useState(userContext?.details?.userIcon || 0);
  const [isCustom, setIsCustom] = useState(false);
  const [customImage, setCustomImage] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const popupRef = useRef();

  useEffect(() => {
    setSelectedIcon(userContext?.details?.userIcon);
  }, [userContext]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempImage(reader.result);
        setDragging(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = (e) => {
    setPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleSaveCustom = () => {
    setCustomImage(tempImage);
    setSelectedIcon(null);
    setIsCustom(false);
    setDragging(false);
  };

  const handleSave = () => {
    setUserContext((prevContext) => ({
      ...prevContext,
      details: {
        ...prevContext.details,
        userIcon: customImage || selectedIcon  // Save selected custom image or default icon
      }
    }));
    onClose();  // Close the popup
  };

  const handleCancel = () => {
    onClose();  // Just close the popup without saving
  };

  return (
    <div className="profile-overlay">
      <div className="profile-popup" ref={popupRef}>
        <span className="profile-close-button" onClick={handleCancel}>&times;</span>
        <h3>Select a Profile Icon</h3>

        {!isCustom ? (
          <>
            <div className="profile-display-icon-selection">
              {icons.map((icon, idx) => (
                <div key={idx} className="profile-display-icon-option" onClick={() => setSelectedIcon(idx)}>
                  <img
                    src={icon}
                    alt={`Icon ${idx + 1}`}
                    style={{
                      width: '100%',
                      height: 'auto',
                      border: selectedIcon === idx ? '4px solid black' : 'none', // Highlight selected icon
                      borderRadius: '15px', // Ensure circular shape for all icons
                    }}
                  />
                </div>
              ))}
              <div className="profile-display-icon-option" onClick={() => setIsCustom(true)}>
                <div className="add-icon">+</div>
              </div>
            </div>

            <div className="profile-display-buttons">
              <button className="profile-display-button-cancel" onClick={handleCancel}>Cancel</button>
              <button className="profile-display-button" onClick={handleSave}>Save</button>
            </div>
          </>
        ) : (
          <>
            <div className="custom-image-upload">
              <input type="file" onChange={handleFileChange} />
              {tempImage && (
                <div
                  className="image-mask"
                  draggable={dragging}
                  onDrag={handleDrag}
                  style={{
                    backgroundImage: `url(${tempImage})`,
                    backgroundPosition: `${position.x}px ${position.y}px`,
                  }}
                />
              )}
              <div className="profile-display-popup-buttons">
                <button className="profile-display-button-cancel" onClick={() => setIsCustom(false)}>Cancel</button>
                <button className="profile-display-button" onClick={handleSaveCustom}>Done</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileIcon;
