import React, { useEffect, useRef, useState, useContext } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { useCompareContext } from '../../context/CompareContext';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { FiltersContext } from '../../context/FiltersContext';
import { CountryContext } from '../../context/CountryContext';
import DynamicSlider from '../../components/DynamicSlider';
import Tooltip from '../../components/Tooltip';
import SearchButtonDropDown from '../../components/SearchButtonDropDown'
import './Score.css';

function Score({ displayCompare = true }) {
  const { activeDocument } = useGlobalContext();
  const { compareDocument, setCompareDocument } = useCompareContext();
  const [policies, setPolicies] = useState([]);
  const [scores, setScores] = useState([]);
  const [tableKey, setTableKey] = useState(0);
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { policyType } = useContext(PolicyTypeContext);
  const { country } = useContext(CountryContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const { globalFilters, setGlobalFilters } = useContext(FiltersContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  
  const openIfClosedDropdown = () => {
    if(!showDropdown){
      setShowDropdown(true)
    }
  }

  useEffect(() => {
    if (masterPolicies.length > 0) {
      const filteredPolicies = masterPolicies.filter(item =>
        item.type === policyType && item.country === country
      );
      setPolicies(filteredPolicies);
    }
  }, [masterPolicies, policyType, country]);

  useEffect(() => {
    if (Object.keys(globalFilters).length > 0) {
      const newScores = policies.map(policy => {
        // Find the filter related to the provider
        const filter = globalFilters.find(f => f.label === policy.provider);
        return {
          provider: policy.provider,
          name: policy.name,
          code: policy?.code || policy.provider,
          score: policy.score,
          filename: policy.fileName,
          visible: filter ? filter.checked : false
        };
      });
      setScores(newScores);
      setTableKey(prevKey => prevKey + 1);
    }
  }, [globalFilters, policies]);

  // Initalise with 15 globalFilters 
  const handleUpdateFilters = () => {

    if (Object.keys(globalFilters).length > 15) {
      const updatedFilters = globalFilters
        .filter(filter => {
          return country === filter.country
        }).map((filter, index) => {

          return index < 15 ? { ...filter, checked: true } : {
            ...filter, checked:
              (activeDocument.policyProvider === filter.name || compareDocument.policyProvider === filter.name) ? true : false
          }
        }
        );
      setGlobalFilters(updatedFilters);
    }
  };

  useEffect(() => {
    let checkedCount = 0;


    //If all the items are showing on load, only load  5 globalFilters 
    const updatedFilters = globalFilters
      .filter(filter => {
        return country === filter.country; // Filter by country
      })
      .map((filter) => {

        if (!filter.checked) {
          checkedCount++;
        }
      });

    if (checkedCount === 0) {
      handleUpdateFilters();
    }
  }, [compareDocument, activeDocument]);

  const countCheckedItems = (globalFilters) => {
    let countChecked = 0;  // Counter for checked items
    let countUnchecked = 0; // Counter for unchecked items

    globalFilters.forEach(filter => {
      if (filter.checked) {
        countChecked++;
      } else {
        countUnchecked++;
      }
    });

    return { countChecked, countUnchecked };
  };

  const DisplayCheckedInfo = () => {
    const { countChecked, countUnchecked } = countCheckedItems(globalFilters);
    const totalCount = countChecked + countUnchecked;

    return (
      <div>Showing <span><strong>{countChecked}</strong></span> out of <span><strong>{totalCount}</strong></span></div>
    );
  };

  useEffect(() => {
    setTableKey(prevKey => prevKey + 1);
  }, [activeDocument]);

   // Handle click outside to close dropdown
   useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="scores-container">
      <div className="scores-container-top">
        <div className="scores-container-header">
          {activeDocument !== undefined && activeDocument !== null ? activeDocument.policyProvider : ''}&nbsp;
          {activeDocument !== undefined && activeDocument !== null ? activeDocument.policyType : ''}
          <Tooltip text="Coverage Score is a rough indication of the level of coverage provided by your policy provider.">&nbsp;<span style={{ fontWeight: 'bold' }}>Coverage Score</span>&nbsp;</Tooltip>vs. other providers
        </div>
        <button className="score-dropdown-button" onClick={openIfClosedDropdown}>
          {DisplayCheckedInfo()}
          {showDropdown && (
            <SearchButtonDropDown onClose={toggleDropdown} ref={dropdownRef} top={'35px'} left={'-630px'} width={'800px'} />
          )}
        </button>
      </div>
      <div className="scores-slider-container" key={tableKey}>
        <DynamicSlider markers={scores} displayCompare={displayCompare} />
      </div>
    </div >
  );
}

export default Score;
