import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Reset from './Reset';
import './ResetPassword.css';   

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState(null);
    const [token, setToken] = useState(null);

    const [isResetPasswordPopupOpen, setIsResetPasswordPopupOpen] = useState(true);

    const navigateHome = () => {
        if (location.pathname !== '/') {
            navigate('/');
        }
    };

    const closeResetPasswordPopup = () => {
        setIsResetPasswordPopupOpen(false);
        navigateHome();
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('username');
        const token = searchParams.get('token');

        setEmail(email);
        setToken(token);

    }, [location]);  // Dependency on location ensures this runs when route changes

    return (
        <div className="reset-container" >
            <Reset isOpen={isResetPasswordPopupOpen} onClose={closeResetPasswordPopup} resetStep={true} code={token} username={email} />
        </div>
    );
};

export default ResetPassword;


