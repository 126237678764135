import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Make sure this CSS file is created

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if consent has been given in the past
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        // Set consent in localStorage
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-consent">
                <p className="cookie-message">
                    PolicyCheck uses cookies to ensure that we give you the best experience on our website.
                    If you continue, we assume that you consent to receive all cookies on the PolicyCheck website.
                </p>
                <div className="cookie-actions">
                    <button className="accept-button" onClick={handleAccept}>I Accept</button>
                    <a href="/documents/cookies.pdf" target="_blank" rel="noopener noreferrer" className="learn-more-link">Learn More</a>
                </div>
            </div>
        )
    );
};

export default CookieConsent;
