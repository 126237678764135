import React, { useState, useContext, useEffect } from 'react';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import './StrengthsAndWeaknesses.css';

const Weaknesses = () => {
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { activeDocument } = useGlobalContext();
  const [attributes, setAttributes] = useState({});
  const [tableKey, setTableKey] = useState(0); // State to track table key
  const { policyType } = useContext(PolicyTypeContext);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore(!showMore);

  const fetchPolicies = async () => {
    if (activeDocument !== null && activeDocument !== undefined && activeDocument.policyProvider) {
      const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;

      if (masterPolicies && masterPolicies.values) {
        for (let entry of masterPolicies.values()) {
          if (entry.provider === activeFileName && entry.type === policyType) {
            setAttributes(entry.weaknesses);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      fetchPolicies();
      setTableKey(prevKey => prevKey + 1); // Update table key
    }
  }, [activeDocument]);


  const formatText = (text) => {
    // Step 1: Split the string at the underscore and take the second part
    let formattedText = text.split('_')[1];

    // Step 2: Capitalize the first letter
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

    // Step 3: Add a space before each uppercase letter except the first one
    formattedText = formattedText.replace(/([A-Z])/g, ' $1').trim();

    return formattedText;
  }

  return (
    <>
      {Object.keys(attributes).length > 0 && (
        <div className="strengths-container" >
          <div className='strengths-container-div'>
            <div className="strengths-container-header">
              <span style={{ color: '#c04527', fontWeight: 'bold' }}>
                Your {Object.keys(attributes).length} Policy Weaknesses*
              </span>            
               </div>
            <table className="strengths-container-table" key={tableKey}>
              <tbody key={tableKey}>
                {attributes && Object.keys(attributes)
                  .slice(0, showMore ? undefined : 8)
                  .map((key, index) => (
                    <tr key={index}>
                      <td className="strengths-container-table-name">{formatText(key)}</td>
                      <td className="strengths-container-table-cross">x</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {Object.keys(attributes).length > 8 && (
              <div className="conditions-show-more " onClick={toggleShowMore}>
                {showMore ? 'Show less...' : 'Show more...'}
              </div>
            )}
            <div className="strengths-container-footer">
              * Not covered, however 50% of insurers include coverage for these items.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Weaknesses;
