import React from 'react';
import './PolicyStatus.css';

const statusColors = {
  'Not Started': '#D3D3D3',
  'Needs Understanding': '#C8E6C9',
  'Research': '#9ed1a0',
  'Communication': '#558657',
  'Closed': '#696969'
};

const PolicyStatus = ({ policies }) => {
  return (
    <div className="policy-status">
      <div className="policy-status-title">Client Progress</div>
      <ul className="policy-status-list">
        {policies.map((policy, index) => (
          <li className="policy-status-item" key={index}>
            <div style={{display:'flex', flexDirection:'column'}}>
              <span><strong>{policy.name} </strong></span>
              <span>{policy.provider} | {policy.type}</span>
            </div>
            {/* <span>Added: {new Date(policy.addedDate).toLocaleDateString()}</span> */}
            <select 
              className="status-dropdown" 
              style={{ backgroundColor: statusColors[policy.status] }}
              value={policy.status}
              onChange={(e) => policy.onStatusChange(index, e.target.value)}
            >
              <option value="Not Started">Not Started</option>
              <option value="Needs Understanding">Needs Understanding</option>
              <option value="Research">Research</option>
              <option value="Communication">Communication</option>
              <option value="Closed">Closed</option>
            </select>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PolicyStatus;
