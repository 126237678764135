import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/UserContext";
import Objectives from './components/Objectives';
import Scope from './components/Scope';
import UploadPolicy from './components/UploadPolicy';
import PolicySummary from './components/PolicySummary';
import ChangeInCircumstance from "./components/ChangeInCircumstance";
import './QuoteBuilder.css';

const QuoteBuilder = ({ toggleWelcome = false, onToggle }) => {
    const navigate = useNavigate();
    const [userContext] = useContext(UserContext);
    const [cardNumber, setCardNumber] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [policyDetails, setPolicyDetails] = useState({
        policyWording: { name: '', filename: '', effective_date: '' },
        policyProvider: '',
        policyType: 'home',
        policyName: 'Home',
        policyNumber: '',
        policyPrice: '',
        paymentFrequency: 'weekly',
        policyAddress: '',
        periodOfInsuranceStart: new Date(),
        periodOfInsuranceEnd: new Date(),
        homeSumInsured: '1,400,000',
        contentsSumInsured: '0',
        homeUse: 'occupied',
        optionalBenefits: {
            excessGlass: '',
            excessHearing: '',
            excessEyeWear: '',
        },
        client: {},
        excessDetails: {
            home: '',
            contents: '',
            unoccupied: '',
            naturalDisaster: '',
        },
        premium: {
            home: '',
            contents: '',
            landlord: '',
        },
    });

    const handleNextClick = () => {
        if (cardNumber === 5) {
            createQuote();
            handleClose();
        } else {
            setCardNumber(cardNumber + 1);
        }
    };

    const handleBackClick = () => {
        if (cardNumber > 1) {
            setCardNumber(cardNumber - 1);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        if (cardNumber === 5) {
            navigate('/dashboard');
        } else {
            navigate('/advice');
        }
    };

    const createQuote = async () => {
        const quote = {
            clientID: userContext.details.username,
            currentInsurance: policyDetails,
            newInsurance: {}
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/api/quotes', {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(quote),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            
        } catch (error) {
            console.error('Error creating quote:', error);
        }
    };

    return (
        <>
            {showModal && (
                <div className="upload-container">
                    <div className="file-upload-container-close" onClick={handleClose}>&times;</div>
                    <div className="upload-header">
                        <div className="quote-builder-company-name">
                            <span style={{ color: 'rgb(79,166,88)' }}>POLICY</span>
                            <span style={{ color: 'white' }}>CHECK</span>
                        </div>
                    </div>
                    <div className="upload-body">
                        {cardNumber === 1 &&
                            <Objectives
                                onNext={handleNextClick} />}
                        {cardNumber === 2 &&
                            <Scope
                                policyDetails={policyDetails}
                                setPolicyDetails={setPolicyDetails}
                                onBack={handleBackClick}
                                onNext={handleNextClick} />}
                        {cardNumber === 3 &&
                            <UploadPolicy
                                policyDetails={policyDetails}
                                setPolicyDetails={setPolicyDetails}
                                onBack={handleBackClick}
                                onNext={handleNextClick} />}
                        {cardNumber === 4 &&
                            <PolicySummary
                                policyDetails={policyDetails}
                                setPolicyDetails={setPolicyDetails}
                                onBack={handleBackClick}
                                onNext={handleNextClick} />}
                        {cardNumber === 5 &&
                            <ChangeInCircumstance
                                policyDetails={policyDetails}
                                setPolicyDetails={setPolicyDetails}
                                onBack={handleBackClick}
                                onNext={handleNextClick} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default QuoteBuilder;
