import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './TopActionsToday.css';

const TopActionsToday = () => {
  const [actions, setActions] = useState([
    "Call with James Thomas at 10 AM",
    "Coffee with Dean 11 AM",
    "Review Melissa Janes scenario",
    "Send Statement of Advice to Peter Grant",
    "Review new home PDS from AIG"
  ]);
  const [newAction, setNewAction] = useState("");

  const handleRemoveAction = (indexToRemove) => {
    setActions(actions.filter((_, index) => index !== indexToRemove));
  };

  const handleAddAction = () => {
    setActions([...actions, { text: "New task...", editing: true }]);
  };
  
  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.preventDefault();
      handleSaveAction(index, event.target.value);
    }
  };

  const handleBlur = (index, newText) => {
    handleSaveAction(index, newText);
  };

  const handleEditAction = (index) => {
    const updatedActions = [...actions];
    updatedActions[index] = { text: updatedActions[index], editing: true };
    setActions(updatedActions);
  };

  const handleSaveAction = (index, newText) => {
    const updatedActions = [...actions];
    updatedActions[index] = newText.trim();
    setActions(updatedActions);
  };

  return (
    <div className="top-actions-today">
      <div className="top-actions-today-header">
        <div className="top-actions-today-title">Your Tasks</div>
        <div className="add-action-btn" onClick={handleAddAction}>+</div>
      </div>
      <ul>
        {actions.map((action, index) => (
          <li className="top-actions-today-item" key={index}>
            {typeof action === 'string' ? (
              <div style={{ width: '100%', cursor: 'pointer' }} onDoubleClick={() => handleEditAction(index)}>
                {action}
              </div>
            ) : (
              <input
                type="text"
                defaultValue={action.text}
                autoFocus
                className='top-actions-today-item-input'
                onBlur={(e) => handleBlur(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            )}
            <span className="remove-action" onClick={() => handleRemoveAction(index)}>&times;</span>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopActionsToday;
