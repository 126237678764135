import React, { useState } from 'react';
import './CollapsibleMenu.css';

const CollapsibleMenu = ({
  existingProposals,
  selectedProposal,
  onSelectProposal,
  existingQuotes,
  selectedQuote,
  onSelectQuote,
  onStartFromScratch,
  onSaveNeeds,
  isSaveDisabled,
  groupSteps,
  groupIndicators,
  onGroupSelect // Use the onGroupSelect passed as a prop
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoadQuoteOpen, setIsLoadQuoteOpen] = useState(false);
  const [isExistingPolicyOpen, setIsExistingPolicyOpen] = useState(false);
  const [isDataCaptureOpen, setIsDataCaptureOpen] = useState(false); // New state for Data Capture
  const [activeOption, setActiveOption] = useState('New'); // Default option is 'New'
  const [activeGroupIndex, setActiveGroupIndex] = useState(null);

  const toggleMenu = () => setIsExpanded(!isExpanded);
  const toggleDataCapture = () => setIsDataCaptureOpen(!isDataCaptureOpen); // Toggle Data Capture

  const handleOptionClick = (option) => {
    setActiveOption(option);
    if (option === 'Load') {
      setIsLoadQuoteOpen(true);
      setIsExistingPolicyOpen(false);
    } else if (option === 'Upload') {
      setIsLoadQuoteOpen(false);
      setIsExistingPolicyOpen(true);
    } else if (option === 'New') {
      setIsLoadQuoteOpen(false);
      setIsExistingPolicyOpen(false);
      onStartFromScratch();
    }
  };

  const handleGroupSelect = (index) => {
    setActiveGroupIndex(index);
    onGroupSelect(index); // Call the onGroupSelect prop function
  };

  return (
    <div className={`collapsible-menu ${isExpanded ? 'collapsible-menu-expanded' : 'collapsible-menu-collapsed'}`}>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="hamburger-icon"></div>
      </div>
      {isExpanded && (
        <>
          <div className="menu-options">
            <div
              className={`menu-option ${activeOption === 'Load' ? 'active' : ''}`}
              onClick={() => handleOptionClick('Load')}
            >
              Load
            </div>
            <div
              className={`menu-option ${activeOption === 'New' ? 'active' : ''}`}
              onClick={() => handleOptionClick('New')}
            >
              New
            </div>
            <div
              className={`menu-option ${activeOption === 'Upload' ? 'active' : ''}`}
              onClick={() => handleOptionClick('Upload')}
            >
              Upload
            </div>
          </div>

          {isLoadQuoteOpen && (
            <div className="process-flow-load-options">
              <select onChange={(e) => onSelectProposal(e.target.value)} value={selectedProposal}>
                <option value="">Select Existing Proposal</option>
                {existingProposals.map((proposal) => (
                  <option key={proposal._id} value={proposal._id}>{proposal.proposalId}</option>
                ))}
              </select>

              <select onChange={(e) => onSelectQuote(e.target.value)} value={selectedQuote} disabled={!selectedProposal}>
                <option value="">Select Existing Quote</option>
                {Array.isArray(existingQuotes) && existingQuotes.map((quote) => (
                  <option key={quote._id} value={quote._id}>{quote.quoteId}</option>
                ))}
              </select>
            </div>
          )}

          {isExistingPolicyOpen && (
            <div className="process-flow-load-options">
              <button>Load Certificate</button>
              <button>Load PDS</button>
            </div>
          )}

          <div className="menu-section">
            <div className="menu-header" onClick={toggleDataCapture}>
              <span>Needs Understanding</span>
              <div className={`triangle-icon ${isDataCaptureOpen ? 'open' : ''}`}></div>
            </div>
            {isDataCaptureOpen && (
              <ul className="menu-links">
                {groupSteps.map((group, index) => {
                  const { requiredFields, filledFields, attentionNeeded } = groupIndicators[index];
                  const completionRate = requiredFields ? (filledFields / requiredFields) : 1;
                  const completionPercentage = Math.round(completionRate * 100);

                  const isActive = index === activeGroupIndex; // Check if this group is active

                  return (
                    <li 
                      key={group.id} 
                      onClick={() => handleGroupSelect(index)} // Use handleGroupSelect to update state and call prop
                      className={`menu-link-item ${isActive ? 'active' : ''}`} // Add active class if it's active
                    >
                      <span className="group-label">{group.data.label}</span>

                      <div className="group-status">
                      <span className="completion-percentage">{completionPercentage}%</span>

                        {attentionNeeded ? (
                          <span className="attention-indicator attention-required">!</span>
                        ) : (
                          <span className="attention-indicator">!</span>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="menu-save">
             <button onClick={onSaveNeeds} disabled={isSaveDisabled}>Save</button>
          </div>

        </>
      )}
    </div>
  );
};

export default CollapsibleMenu;
