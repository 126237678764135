import React from 'react';
import './UpcomingReviews.css';

const UpcomingReviews = ({ reviewDates }) => {
  return (
    <div className="upcoming-reviews">
      <h3>Upcoming Review Dates</h3>
      <ul>
        {reviewDates.map((review, index) => (
          <li key={index}>
            <strong>{review.clientName}:</strong> {review.date.toLocaleDateString()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpcomingReviews;
