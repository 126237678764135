import axios from 'axios';

// Get all coverages or filtered coverages by query parameters
export const getCoverages = async (id, description) => {
    try {
        // Build the query parameters if any are provided
        let query = "";
        if (id || description) {
            const queryParams = [];
            if (id) queryParams.push(`id=${encodeURIComponent(id)}`);
            if (description) queryParams.push(`description=${encodeURIComponent(description)}`);
            query = "?" + queryParams.join("&");
        }

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}users/api/coverages${query}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || "Failed to fetch coverages. Please try again later.");
        }

        const data = await response.json();
        return data; // Return the list of coverages
    } catch (error) {
        throw new Error(error.message || "Failed to fetch coverages. Please try again later.");
    }
};

// Get coverage references based on query, category, and provider name
export const getCoverageReference = async (query, category, providerName) => {
    try {
        // Build the query parameters if any are provided
        let queryParams = "";
        if (query || category || providerName) {
            const params = [];
            if (query) params.push(`query=${encodeURIComponent(query)}`);
            if (category) params.push(`category=${encodeURIComponent(category)}`);
            if (providerName) params.push(`providerName=${encodeURIComponent(providerName)}`);
            queryParams = "?" + params.join("&");
        }

        // Make the API request using axios
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/api/get-embedding${queryParams}`, {
            withCredentials: true, // If needed, to include credentials (like cookies)
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data; // Return the data from the response
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch coverage references. Please try again later.");
    }
};

// Get coverage references based on query, category, and provider name
export const getCoverageReferences = async (category, providerName) => {
    try {
        // Build the query parameters if any are provided
        let queryParams = "";
        if (category || providerName) {
            const params = [];
            if (category) params.push(`category=${encodeURIComponent(category)}`);
            if (providerName) params.push(`providerName=${encodeURIComponent(providerName)}`);
            queryParams = "?" + params.join("&");
        }

        // Make the API request using axios
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/api/get-embeddings${queryParams}`, {
            withCredentials: true, // If needed, to include credentials (like cookies)
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data; // Return the data from the response
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to fetch coverage references. Please try again later.");
    }
};