import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink } from "react-router-dom";
import FeedbackSupport from "./FeedbackSupport"
import ProfileIcon from '../../components/profile/ProfileIcon'; // Import the new ProfileIcon component
import './Account.css';
import Welcome from "../Welcome";

function Account() {
    const [isOpen, setIsOpen] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [toggle, setToggle] = useState(false);

    const ref = useRef(null);

    const onFeedbackClose = () => {
        setShowFeedback(false);
    };
    const onFeedbackOpen = () => {
        setIsOpen(!isOpen);
        setShowFeedback(true);
    };

    const setHasSeenModalToTrue = () => {
        setIsOpen(false);
        setToggle(!toggle);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const downloadDocument = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ''); // This attribute triggers download behavior
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after the click
    };

    const handleDocumentClick = (url) => {
        window.open(url, '_blank');
        // downloadDocument(url);
    };

    
    const loadDocuments = () => {
        setIsOpen(!isOpen);
        fetch(process.env.REACT_APP_API_ENDPOINT + "users/api/documents", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log("Found docs:", data);

                data.forEach((doc, index) => {
                    setTimeout(() => {
                        console.log("Opening doc:", doc.url);
                        handleDocumentClick(doc.url);  // Open each document in a new tab
                    }, index * 1000);  // 500ms delay between each document open
                });
            })
            .catch(error => {
                console.error("Error fetching documents:", error);
            });
    };


    return (
        <div className="account-container" ref={ref}>
            <div className="circle" onClick={() => setIsOpen(!isOpen)}>
                <ProfileIcon size={30} />
            </div>
            {isOpen && (
                <div className="account-panel">
                    <ul>
                        <li className="account-panel-item"><NavLink onClick={() => setIsOpen(!isOpen)} className="account-link" to="/settings?menu=My%20Profile" style={{ color: '#272727ff' }}>My Profile</NavLink></li>
                        <li className="account-panel-item"><NavLink onClick={() => setIsOpen(!isOpen)} className="account-link" to="/settings?menu=My%20Plan" style={{ color: '#272727ff' }}>My Plan</NavLink></li>
                        <li className="account-panel-item"><NavLink onClick={setHasSeenModalToTrue} className="account-link" to="/home" style={{ color: '#272727ff' }}>How it works?</NavLink></li>
                        <li className="account-panel-item"><NavLink onClick={() => setIsOpen(!isOpen)} className="account-link" to="/settings" style={{ color: '#272727ff' }}>Settings</NavLink></li>
                        <li className="account-panel-item"><div onClick={loadDocuments} className="account-link" style={{ color: '#272727ff' }}>Terms & Conditions</div></li>
                        <li className="account-panel-item"><div onClick={onFeedbackOpen} className="account-link" style={{ color: '#272727ff' }}>Feedback & Support</div></li>
                    </ul>
                </div>
            )}
            <Welcome toggleWelcome={toggle} onToggle={setHasSeenModalToTrue} />
            {showFeedback && (
                <FeedbackSupport onClose={onFeedbackClose} />
            )}
        </div>
    );
}

export default Account;
