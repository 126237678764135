import React, { createContext, useContext, useState, useEffect } from 'react';
import { UserContext } from "./UserContext";
import axios from 'axios';

const ClientContext = createContext();

export const useClient = () => useContext(ClientContext);

export const ClientProvider = ({ children }) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const [activeClient, setActiveClientState] = useState();

  const fetchClient = () => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/api/clients?username=${userContext?.details?.username}`)
      .then(response => {
        if (response.data.length > 0) {
          setActiveClientState(response.data[0]);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    if (userContext?.details?.username) {
      fetchClient();
    }
  }, []);

  // Update the client data in the state and database
  const updateClient = async (updatedClient) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}users/api/clients/${updatedClient._id}`, updatedClient);
      setActiveClientState(response.data);
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  // Set the active client by clientId
  const setActiveClientById = async (clientId) => {
    if (clientId) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/api/client?clientId=${clientId}`);
        if (response.data.length > 0) {
          setActiveClientState(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching client by ID:', error);
      }
    } else {
      console.error("No clientId:", clientId)
    }
  };

  return (
    <ClientContext.Provider value={{ activeClient, updateClient, setActiveClientById }}>
      {children}
    </ClientContext.Provider>
  );
};
