import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import * as d3 from 'd3';
import { useGlobalContext } from '../../context/GlobalContext';
import './SpiderChart.css';

const SpiderChart = forwardRef(({ data }, ref) => {
  const [chartDimensions, setChartDimensions] = useState({ width: 600, height: 600 });
  const { activeDocument } = useGlobalContext();
  const containerRef = useRef(null); // Ref for the chart container
  const chartRef = useRef(null);
  const dropdownRef = useRef(null);
  const [selectedData, setSelectedData] = useState(data);
  const [showDropdown, setShowDropdown] = useState(false);
  const colors = [
    'rgb(25, 107, 33)', 'rgb(107, 25, 33)', 'rgb(33, 25, 107)',
    'rgb(107, 33, 25)', 'rgb(33, 107, 25)', 'rgb(25, 33, 107)',
    'rgb(25, 107, 107)', 'rgb(107, 107, 25)'
  ];
  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle resize events
  const handleResize = () => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      // Set width and height within the specified range
      const newWidth = Math.max(600, Math.min(800, width));
      setChartDimensions({ width: newWidth, height: newWidth });
    }
  };

  // Setup ResizeObserver to adjust chart size dynamically
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  // Create tooltip once outside 
  const tooltipRef = useRef(null);

  if (!tooltipRef.current) {
    tooltipRef.current = d3.select('body').append('div')
      .attr('class', 'spider-tooltip')
      .style('opacity', 0);
  }

  const updateChart = () => {
    if (!selectedData || selectedData === undefined || selectedData.length < 1 || selectedData[0].values === undefined) return;
    

    const viewBoxPadding = 50;

    const svg = d3.select(chartRef.current);
    svg.selectAll("*").remove(); // Clear SVG content before redraw

    svg.attr('viewBox', `0 0 ${(chartDimensions.width + viewBoxPadding * 2.2)} ${(chartDimensions.height + viewBoxPadding)}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    // Radar chart chartDimensions
    const radius = Math.min(chartDimensions.width, chartDimensions.height) / 2;
    const innerRadius = 0.2 * radius;

    // Create a radial scale
    const rScale = d3.scaleLinear()
      .domain([0, 100]) // Assuming data values range from 0 to 100
      .range([innerRadius, radius]);

    // Create a scale for the angles
    const angleSlice = Math.PI * 2 / selectedData[0].values.length;
    const angleScale = d3.scaleLinear()
      .domain([0, selectedData[0].values.length])
      .range([0, Math.PI * 2]);

    // Draw the radar chart
    const radarLine = d3.lineRadial()
      .radius(d => rScale(d.value))
      .angle((d, i) => angleScale(i));

    // Center the radar chart group, including extra space for labels
    const radarGroup = svg.append("g")
      .attr("transform", `translate(${(chartDimensions.width + viewBoxPadding * 2) / 2}, ${(chartDimensions.height + viewBoxPadding * 2) / 2})`);

    // Draw the radar paths in the radarGroup
    selectedData.filter(d => d.visible).forEach((dataset, datasetIndex) => {
      const pathGroup = radarGroup.append("g").attr("class", "path-group");

      pathGroup.append("path")
        .attr("class", "radar")
        .attr("d", () => {
          const dataPoints = dataset.values.map((value, i) => ({ value, index: i }));
          dataPoints.push(dataPoints[0]); // Append the first point at the end to close the path
          return radarLine(dataPoints);
        })
        .style("stroke", 'white')
        .style("fill", dataset.color)
        .style("fill-opacity", 0.6)
        .style("stroke-width", 1);

      dataset.values.forEach((value, i) => {
        const xPosition = rScale(value) * Math.cos(angleScale(i) - Math.PI / 2);
        const yPosition = rScale(value) * Math.sin(angleScale(i) - Math.PI / 2);

        // Visible circle (smaller)
        radarGroup.append('circle')
          .attr('cx', xPosition)
          .attr('cy', yPosition)
          .attr('r', 10)
          .style('fill', dataset.color);

        // Invisible circle (larger) for easier hover
        radarGroup.append('circle')
          .attr('cx', xPosition)
          .attr('cy', yPosition)
          .attr('r', 30)
          .style('fill', 'transparent')
          .style('stroke', 'transparent')
          .style('pointer-events', 'all') // Ensure mouse events are captured
          .on('mouseover', (event, d) => {
            tooltipRef.current.transition().duration(200).style('opacity', 0.9);
            tooltipRef.current.html(`${value}<br/> <span style="color: ${dataset.color};"><span style="font-weight: normal;">${dataset.code}</span></span>`)
              .style('left', (event.pageX) + 'px')
              .style('top', (event.pageY - 28) + 'px');
          })
          .on('click', () => handleDataItemClick(dataset.code))
          .on('mouseout', () => {
            tooltipRef.current.transition().duration(500).style('opacity', 0);
          });
      });
    });

    // Assuming each dataset has 5 attributes
    const numAxes = 5;
    const axisLabels = ["Score", "Strengths", "Weaknesses", "Conditions", "Price"];
    const axisRadius = rScale(100);

    function wrapText(textElement, width) {
      textElement.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy") || 0),
          tspan = text.text(null).append("tspan").attr("x", text.attr("x")).attr("y", y).attr("dy", dy + "em");

        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan")
              .attr("x", text.attr("x"))
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    // Add axes
    const axes = radarGroup.append("g")
      .attr("class", "axes");

    axes.selectAll(".axis")
      .data(d3.range(numAxes))
      .enter().append("line")
      .attr("class", "axis")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d, i) => axisRadius * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("y2", (d, i) => axisRadius * Math.sin(angleSlice * i - Math.PI / 2))
      .style("stroke", "lightgrey")
      .style("stroke-width", "1px");

    axes.selectAll(".axis-label")
      .data(axisLabels)
      .enter().append("text")
      .attr("class", "axis-label")
      .attr("x", (d, i) => (axisRadius + 10) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("y", (d, i) => (axisRadius + 10) * Math.sin(angleSlice * i - Math.PI / 2))
      .text(d => d)
      .each(function (d) { d3.select(this).text(d); wrapText(d3.select(this), 50); })
      .style("font-size", "16pt")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle");

    // Add axis markers
    const markerValues = [20, 40, 60, 80, 100]; // Replace with actual marker values
    const markers = radarGroup.append("g")
      .attr("class", "markers");

    markers.selectAll(".marker")
      .data(markerValues)
      .enter().append("circle")
      .attr("class", "marker")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", d => rScale(d))
      .style("fill", "none")
      .style("stroke", "lightgrey")
      .style("stroke-dasharray", "2");

  };

  // Expose function to parent component via ref
  useImperativeHandle(ref, () => ({
    updateChart,
  }));

  const handleDataItemClick = (code) => {
    setSelectedData(currentData => {
      const index = currentData.findIndex(item => item.code === code);
      if (index === -1) return currentData;

      const selectedItem = currentData[index];
      selectedItem.color = colors[currentColorIndex];
      setCurrentColorIndex((currentColorIndex + 1) % colors.length);
      let newData = [...currentData];
      newData.splice(index, 1); // Remove the item
      newData.push(selectedItem); // Add it back at the end
      return newData;
    });
    
    updateChart();
  };

  const setOrderedData = (code, currentData) => {
    const index = currentData.findIndex(item => item.code === code);
    if (index === -1) return currentData; // If item not found, return original data

    const item = currentData[index];
    item.color = 'rgb(79,166,88)';

    let newData = [...currentData.slice(0, index), ...currentData.slice(index + 1)];
    newData.push(item);

    return newData;
  };

  // Update selectedData whenever the data prop changes
  useEffect(() => {
    
    if (selectedData.length > 0){
      
      updateChart(); // Redraw chart when data changes
    }
  }, [selectedData]);

  // Update selectedData whenever the data prop changes
  useEffect(() => {
    if (activeDocument !== undefined && activeDocument !== null && data) {
      setSelectedData(data => setOrderedData(activeDocument.policyProvider, data));
    }
  }, [activeDocument]);

  useEffect(() => {
      setSelectedData(data);
      updateChart(); // Ensure the chart updates with the new data
  }, [data]);

  const toggleDataSet = (index) => {
    setSelectedData(selectedData.map((d, i) => (i === index ? { ...d, visible: !d.visible } : d)));
    updateChart();
  };

  return (
    <div className="spider-container" ref={containerRef}>
      <div className='spider-container-title'>
        <div className="spider-container-header">
          Your&nbsp;<span style={{ color: '#4fa658', fontWeight: 'bold' }}>{activeDocument !== undefined && activeDocument !== null ? `${activeDocument.policyProvider}` : ''}</span>&nbsp;policy vs. other providers
        </div>
      </div>

      <div className="spider-container-chart">
        <svg ref={chartRef} width={chartDimensions.width} height={chartDimensions.height} className="spider-chart"></svg>
        <div className="spider-chart-dropdown" ref={dropdownRef}>
          <button className="spider-chart-dropdown-button" onClick={toggleDropdown}>
            Filter
          </button>
          {showDropdown && (
            <div className="spider-chart-dropdown-content">
            <div className="spider-chart-dropdown-content-scroll">
              {selectedData.map((dataset, index) => (
                <div className="spider-chart-dropdown-item" key={index} onClick={() => toggleDataSet(index)}>
                  <input type="checkbox" checked={selectedData[index].visible} />
                  <div className={`${activeDocument !== null && activeDocument !== undefined && dataset.code === activeDocument.policyProvider ? 'green-label' : ''}`}>{dataset.label}</div>
                </div>
              ))}
              </div>
            </div>
          )}
        </div>
        <div className="spider-chart-legend">
          <div className="spider-chart-legend-item">
            <span><strong>Score - </strong></span>a score out of 100 based on the level of coverage a policy has.
            </div>
          <div className="spider-chart-legend-item">
          <span><strong>Strengths - </strong></span>The degree to which items are covered, but 50%+ of insurers do not cover these items.
            </div>
          <div className="spider-chart-legend-item">
          <span><strong>Weaknesses - </strong></span>The degree to which items are not covered, while 50% of insurers provide cover.
            </div>
          <div className="spider-chart-legend-item">
          <span><strong>Conditions - </strong></span>Conditions - An indidiation of the number of conditions that should to be reviewed.
            </div>
          <div className="spider-chart-legend-item">
          <span><strong>Price - </strong></span> An indicative price guide - illuestration purposes only. Please consult the insurer.
            </div>
        </div>
      </div>
    </div>
  );
});

export default SpiderChart;
