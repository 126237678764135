import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './LeadsOverview.css';

const LeadsOverview = ({ totalLeads, newLeads, contactedLeads }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [{
          data: [12, 19, 3, 5, 2, 3],
          fill: false,
          borderColor: 'rgb(83,166,88)',
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });


    return () => {
      lineChart.destroy();
    };
  }, []);

  return (
    <div className="leads-overview">
      <div className="leads-overview-chart-container">
      <canvas ref={chartRef}></canvas>
        </div>
      <div className="stats">
        <div className="stat">
          <div className="stat-title">Total Leads</div>
          <p>{totalLeads}</p>
        </div>
        <div className="stat">
          <div className="stat-title">New Leads</div>
          <p>{newLeads}</p>
        </div>
        <div className="stat">
          <div className="stat-title">Contacted</div>
          <p>{contactedLeads}</p>
        </div>
      </div>
    </div>
  );
};

export default LeadsOverview;
