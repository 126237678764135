import React, { useContext, useEffect } from "react";
import { FiltersContext } from '../../../context/FiltersContext';
import { CountryContext } from '../../../context/CountryContext';
import { usePolicyTypes } from '../../../context/PolicyTypesContext';
import './MyPreferences.css'; // Add custom styling for this component

const MyPreferences = () => {
    const { globalFilters, setGlobalFilters } = useContext(FiltersContext);
    const { country } = useContext(CountryContext);
    const policyTypes = usePolicyTypes();

    useEffect(() => {
        document.title = 'My Preferences';
    }, []);

    const toggleProviderVisibility = (provider) => {
        const updatedFilters = globalFilters.map(filter =>
            filter.name === provider.name ? { ...filter, checked: !provider.checked } : filter
        );
        setGlobalFilters(updatedFilters);
    };

    useEffect(() => {
        document.title = 'My Preferences';
    }, []);

    const clearAll = () => {
        const updatedFilters = globalFilters.map(filter => ({
            ...filter,
            checked: false
        }));
        setGlobalFilters(updatedFilters);
    };

    const showAll = () => {
        const updatedFilters = globalFilters.map(filter => ({
            ...filter,
            checked: true
        }));
        setGlobalFilters(updatedFilters);
    };

    const clearAllPolicyTypes = () => {
    };

    const showAllPolicyTypes = () => {
    };

    return (
        <div className="preferences-container">
            <h2>My Preferences</h2>
            <p>Manage your experience settings below.</p>

            <div className="preferences-section">
                <h3>Default Providers in Filters</h3>
                <p className="preferences-info">
                    By deselecting a provider, they won't be shown by default in the tool unless you update the filter.
                </p>
                <div className='search-input-popup-grid'>
                    {Object.entries(globalFilters)
                        .sort((a, b) => a[0].localeCompare(b[0])) // Sort alphabetically by provider name
                        .filter(filter => {
                            return country === filter[1].country
                        })
                        .map(([key, provider], index) => (
                            <div className='search-input-popup-item' key={index}
                                onClick={() => toggleProviderVisibility(provider)}>
                                <input
                                    className='search-input-popup-checkbox'
                                    type="checkbox"
                                    checked={provider.checked}
                                    id={`checkbox-${provider.name}`}
                                    onChange={() => toggleProviderVisibility(provider)}
                                />
                                <div className='mypreference-label' htmlFor={`checkbox-${provider.name}`}>{provider.label}</div>
                            </div>
                        ))}
                </div>

                <div style={{ display: 'flex', gap: '20px', marginTop:'30px' }}>
                    <button className="preference-button-grey" onClick={clearAll}>Clear all</button>
                    <button className="preference-button" onClick={showAll}>Show all</button>
                </div>
            </div>

            <div className="preferences-section">
                <h3>Default Policy Types Filters</h3>
                <p className="preferences-info">
                    By deselecting a policy type, they won't be shown by default in the tool unless you update the filter.
                </p>
                <div className='search-input-popup-grid'>
                    {Object.entries(policyTypes)
                        .sort((a, b) => a[0].localeCompare(b[0])) // Sort alphabetically by provider name
                        .map(([key, provider], index) => (
                            <div className='search-input-popup-item' key={index}
                                onClick={() => toggleProviderVisibility(provider)}>
                                <input
                                    className='search-input-popup-checkbox'
                                    type="checkbox"
                                    checked={provider.checked}
                                    id={`checkbox-${provider.name}`}
                                    onChange={() => toggleProviderVisibility(provider)}
                                />
                                <div className='mypreference-label' htmlFor={`checkbox-${provider.name}`}>{provider.label}</div>
                            </div>
                        ))}
                </div>

                <div style={{ display: 'flex', gap: '20px', marginTop:'30px' }}>
                    <button className="preference-button-grey" onClick={clearAllPolicyTypes}>Clear all</button>
                    <button className="preference-button" onClick={showAllPolicyTypes}>Show all</button>
                </div>
            </div>
        </div>
    );
};

export default MyPreferences;
