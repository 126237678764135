
export const fetchPolicies = async (country) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 
            `users/api/provider_policies?country=${country}`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || "Failed to find policies.");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(error.message || "Failed to find policies.");
    }
};

export const fetchMyPolicies = async (username, country) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 
            `users/api/my-policies?username=${username}&country=${country}`, {
            method: "GET",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || "Failed to find policies.");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(error.message || "Failed to find policies.");
    }
};