import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CommissionsOverTime = ({ sales }) => {
  // Prepare the data in the format needed for the chart
  const chartData = sales.map(sale => ({
    date: sale.date,
    upfront: sale.upfront,
    trail: sale.trail,
  }));

  return (
    <div className="commissions-graph-white">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="upfront" stroke="#8884d8" name="Upfront Commission" />
          <Line type="monotone" dataKey="trail" stroke="#82ca9d" name="Trail Commission" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CommissionsOverTime;
