import React, { createContext, useContext, useState, useEffect } from 'react';
import { PropertyProvider, PropertyContext } from './filters/PropertyContext';
import { AutoProvider, AutoContext } from './filters/AutoContext';
import { ContentsProvider, ContentsContext } from './filters/ContentsContext';
import { LandlordProvider, LandlordContext } from './filters/LandlordContext';
import { LifeProvider, LifeContext } from './filters/LifeContext';
import { HealthProvider, HealthContext } from './filters/HealthContext';
import { TravelProvider, TravelContext } from './filters/TravelContext';
import { PetProvider, PetContext } from './filters/PetContext';
import { ProfessionalProvider, ProfessionalContext } from './filters/ProfessionalContext';
import { DirectorsProvider, DirectorsContext } from './filters/DirectorsContext';
import { CyberProvider, CyberContext } from './filters/CyberContext';

export const FilterContext = createContext();

const getProviderComponent = (type) => {
  switch (type) {
    case 'Home':
      return { Provider: PropertyProvider, Context: PropertyContext };
    case 'Auto':
      return { Provider: AutoProvider, Context: AutoContext };
    case 'Contents':
      return { Provider: ContentsProvider, Context: ContentsContext };
    case 'Landlord':
      return { Provider: LandlordProvider, Context: LandlordContext };
    case 'Life':
      return { Provider: LifeProvider, Context: LifeContext };
    case 'Health':
      return { Provider: HealthProvider, Context: HealthContext };
    case 'Travel':
      return { Provider: TravelProvider, Context: TravelContext };
    case 'Pet':
      return { Provider: PetProvider, Context: PetContext };
    case 'Professional':
      return { Provider: ProfessionalProvider, Context: ProfessionalContext };
    case 'Directors':
      return { Provider: DirectorsProvider, Context: DirectorsContext };
    case 'Cyber':
      return { Provider: CyberProvider, Context: CyberContext };

    default:
      return { Provider: PropertyProvider, Context: PropertyContext };
  }
};

export const FilterProvider = ({ children, type }) => {
  const { Provider, Context } = getProviderComponent(type);
  const [filters, setFilters] = useState([]);

  if (Context) {
    return (
      <Provider>
        <Context.Consumer>
          {context => {
            const combinedContext = {
              ...context,
              filters: {
                ...context.filters,
              },
            };
            return (
              <FilterContext.Provider value={{ ...combinedContext, setFilters }}>
                {children}
              </FilterContext.Provider>
            );
          }}
        </Context.Consumer>
      </Provider>
    );
  }

  // Fallback in case no context is provided
  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
