import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '../../Tooltip';
import FileUploadURL from "../../../icons/file_upload.png";


const UploadPolicy = ({ policyDetails, setPolicyDetails, onBack, onNext, userContext, country }) => {
    const [file, setFile] = useState(null);
    const [validationMessage, setValidationMessage] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [policyWordings, setPolicyWordings] = useState([]);
    const [gettingInfo, setGettingInfo] = useState(false);

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            setValidationMessage("Error: No file selected");
            setFile(null);
        } else if (selectedFile.type !== 'application/pdf') {
            setValidationMessage("Error: Only PDF files are supported");
            setFile(null);
        } else {
            setValidationMessage("");
            setFile(selectedFile);
        }
        e.target.value = null; // Clear the file input
    };

    const handlePolicyWordingSelectChange = (event) => {
        const selectedValue = event.target.value;
        const selectedPolicy = policyWordings.find(policy => policy.name === selectedValue);

        if (selectedPolicy) {
            setPolicyDetails(prevDetails => ({
                ...prevDetails,
                policyWording: {
                    name: selectedPolicy.name,
                    filename: selectedPolicy.filename,
                    effective_date: selectedPolicy.effective_date
                }
            }));
        }
    };

    useEffect(() => {
        if (file && userContext) {
            // Handle file upload and set policy details here
        }
    }, [file]);

    return (
        <>
            <div className="upload-container-row">
                <div className="upload-container-column-no-gap">
                    <div className="upload-container-option-header">
                        <Tooltip width={'300px'} text="Your policy schedule contains important information related to this policy document, including who’s insured, your cover type, your agreed value and excesses.">
                            <div className="upload-container-option-header-title">{country === 'NZ' ? 'Policy Schedule' : 'Insurance Certificate'}</div>
                        </Tooltip>
                    </div>
                    <div className="upload-container-center">
                        <input
                            required
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileSelect(e)}
                        />
                        <div className="file-upload-button-area">
                            {isWaiting ? (
                                <>
                                    <div className="upload-waiting-message-detailed">Just a tick while we review your policy...</div>
                                    {/* Add WaitingDiv component */}
                                </>
                            ) : (
                                <>
                                    <img
                                        src={FileUploadURL}
                                        className={`file-upload-image ${file ? '' : 'file-upload-image-grey'}`}
                                        alt=""
                                        onClick={() => document.getElementById('fileInput').click()}
                                    />
                                    <div className="file-upload-filename">
                                        {file ? file.name : 'No file selected'}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="upload-container-column-no-gap">
                    <div className="upload-container-option-header">
                        <Tooltip width={'300px'} text="This document explains your insurance cover, the benefits you’ll receive, your responsibilities and how to make a claim.">
                            <div className="upload-container-option-header-title">{country === 'NZ' ? 'Policy Document(s)' : 'Public Disclosure Statement (PDS)'}</div>
                        </Tooltip>
                    </div>
                    <div className="upload-container-center">
                        <input
                            required
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileSelect(e)}
                        />
                        <div className="file-upload-button-area">
                            <img
                                src={FileUploadURL}
                                className={`file-upload-image ${policyWordings.length > 0 ? '' : 'file-upload-image-grey'}`}
                                alt=""
                            />
                            <select
                                onChange={handlePolicyWordingSelectChange}
                                className="policy-wording-select"
                                defaultValue={'Please select a PDS...'}
                                value={policyDetails.policyWording}>
                                {policyWordings.map((policy, index) => (
                                    <option key={index} value={policy.name}>
                                        {policy.name}
                                    </option>
                                ))}
                                <option>Please add a PDS...</option>
                            </select>
                            {policyDetails?.policyWording && (
                                <div className="file-upload-policywording">
                                    {policyDetails.policyWording.filename !== '' && (
                                        <>
                                            <span><strong>Policy Name: </strong></span>
                                            {policyDetails.policyWording.filename}
                                        </>
                                    )}
                                    {policyDetails.policyWording.effective_date !== '' && (
                                        <>
                                            <span><strong>Effective Date: </strong></span>
                                            {policyDetails.policyWording.effective_date}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="upload-buttons">
                <button className="upload-buttons-back" onClick={onBack}>Back</button>
                <button className="upload-buttons-next" onClick={onNext}>Next</button>
            </div>
        </>
    );
};

export default UploadPolicy;
