import React, { useCallback, useEffect, useState, useContext } from "react";
import { useUpload } from '../context/UploadContext';
import { CountryContext } from '../context/CountryContext';
import { UserContext } from "../context/UserContext";
import { useGlobalContext } from '../context/GlobalContext';
import { FiltersContext } from '../context/FiltersContext';
import { fetchMyPolicies } from "../apis/PolicyService"; 
import './ActivePolicy.css';

function ActivePolicy({width = '190px', menu = false}) {   
    const { country } = useContext(CountryContext);
    const { activeDocument, setActiveDocumentName } = useGlobalContext();
    const { isUploadComplete } = useUpload();
    const [userContext] = useContext(UserContext);
    const [policies, setPolicies] = useState([]);
    const [isFetchingPolicies, setIsFetchingPolicies] = useState(false);
    const { globalFilters, setGlobalFilters } = useContext(FiltersContext);


    const handleSelectChange = (event) => {
        if (activeDocument.filename !== event.target.value) {
            setActiveDocumentName(policies.find(policy => policy.filename === event.target.value));
        }
    };

    const fetchMyPolicyData =  async  () => {
        const data = await fetchMyPolicies(userContext.details.username,country);
        if (data.length > 0) {
            setPolicies(data);
        }
    };

    useEffect(() => {
        if (userContext.details) {
            const loadMyPolicyData = async () => {
                await fetchMyPolicyData();
            };
    
            loadMyPolicyData(); // Call the async function inside useEffect
        }
    }, [userContext.details]);


    useEffect(() => {        
        if(activeDocument){
            const updatedFilters = globalFilters.map(filter =>
                filter.name === activeDocument.policyProvider ? { ...filter, checked: true } : filter
            );
            setGlobalFilters(updatedFilters);
            }
    }, [activeDocument]); 

    useEffect(() => {
        if (policies[0] !== undefined && (activeDocument === undefined || activeDocument === "" || activeDocument === null)) {
            console.log("FFFFsetActiveDocumentName",policies[0]);
            setActiveDocumentName(policies[0]);
        }
    }, [policies]);

    useEffect(() => {
        if (isUploadComplete === true) {
            fetchMyPolicyData();
        }
    }, [isUploadComplete]);

    return (
        <>
            {(activeDocument !== null && activeDocument !== undefined) && (
                <div className="active-policy-div">
                    <select onChange={handleSelectChange} className={`policy-dropdown ${menu ? 'policy-dropdown-greystyle' : ''}`} value={activeDocument.filename} style={{width:width}}>
                        {policies.map((policy, index) => (
                            <option key={policy._id || index} value={policy.filename}>
                                {policy.policyProvider}-{policy.policyType}-
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </>
    );

}

export default ActivePolicy;


