import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const LandlordContext = createContext();

export const LandlordProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial landlord insurance state
  const initialLandlordState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        newProperty: false,
        betterDeal: false,
        changeInSituation: false,
        newTenants: false,
      },
      goals: {
        buildingCoverage: false,
        liabilityCoverage: false,
      },
    },
    situation: {
      propertyDetails: {
        address: '',
        yearBuilt: '',
        constructionType: '',
      },
      rentalDetails: {
        rentAmount: '',
        leaseTerm: '',
        vacancyPeriod: '',
      },
      propertyUse: {
        longTermRental: false,
        shortTermRental: false,
      },
      propertyRisks: {
        floodZone: false,
        bushfireZone: false,
        highCrimeArea: false,
      },
    },
    modules: {
      options: {
        excessFreeGlass: false,
        sanitaryFixtures: false,
        landlordContents: false,
        legalExpenses: false,
        rentDefaultAndTheftByTenant: false,
        maliciousDamageByTenant: false,
        lossOfRent: false,
        accidentalDamage: false,
        motorBurnout: false,
        sumInsuredExtension: false,
        matchingFloorCoverings: false,
        landlordFixturesAndFittings: false,
      },
    },
  };

  // Initialize the filters state using the initialLandlordState
  const [filters, setFilters] = useState(initialLandlordState);

  return (
    <LandlordContext.Provider value={{ filters, setFilters }}>
      {children}
    </LandlordContext.Provider>
  );
};
