import React from 'react';
import { Link } from "react-router-dom";
import './ExpiringClients.css';

const ExpiringClients = ({ expiringClients }) => {

    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day

    const renderDayHeaders = () => {
        return dayNames.map(day => (
            <div key={day} className="expiring-calendar-header">
                {day}
            </div>
        ));
    };

    const getClassForDay = (date) => {
        if (date.toDateString() === today.toDateString()) {
            return "expiring-calendar-day today";
        } else if (date < today) {
            return "expiring-calendar-day past";
        }
        return "expiring-calendar-day";
    };

    // Adjust to start the calendar on the Sunday before today
    const generateCalendarDates = () => {
        const dates = [];
        const startDay = new Date(today);
        // Set to the previous Sunday
        startDay.setDate(today.getDate() - today.getDay());

        for (let i = 0; i < 45; i++) {
            const futureDate = new Date(startDay);
            futureDate.setDate(startDay.getDate() + i);
            dates.push(futureDate);
        }
        return dates;
    };

    // Count clients expiring on each date
    const clientsPerDay = generateCalendarDates().map(date => {
        return {
            date,
            count: expiringClients.filter(client =>
                new Date(client.expiryDate).toDateString() === date.toDateString()
            ).length
        };
    });

    return (
        <div className="expiring-clients-calendar">
            <div className="expiring-clients-calendar-title">Policies Expiring</div>
            <div className="expiring-calendar">
                {renderDayHeaders()}
                {clientsPerDay.map((day, index) => (
                    <div key={index} className={getClassForDay(day.date)}>
                        <span className="expiring-day-number">{day.date.getDate()}</span>
                        {day.count > 0 && (
                            <Link to="/clients" className='expiring-expiry-link'>
                                <span className="expiring-expiry-count">{day.count}</span>
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpiringClients;
