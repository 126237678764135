// API.js
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchClients = async (username,country) => {
  
  try {
    const response = await fetch(`${API_ENDPOINT}users/api/clients?username=${username}&country=${country}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (Array.isArray(data)) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching clients:', error);
    return [];
  }
};

export const updateClient = async (updatedClient) => {
  try {
    const response = await fetch(`${API_ENDPOINT}users/api/client/${updatedClient._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedClient),
    });

    if (response.ok) {
      return response.json();
    } else {
      console.error('Failed to save client:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error saving client:', error);
    return null;
  }
};

export const deleteClient = async (clientId) => {
  try {
    const response = await fetch(`${API_ENDPOINT}users/api/client/${clientId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error deleting client:', error);
    return null;
  }
};
