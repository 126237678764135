import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../context/UserContext";
import './WelcomePopup.css';

const disclaimerItems = [
    {
        title: "Test Environment",
        detail: "This website is a prototype and may contain bugs or incomplete features. It is provided on an 'as is' and 'as available' basis without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. You acknowledge that the beta software may not be reliable, may not operate as intended, and may contain errors. Use is at your own risk."
    },
    {
        title: "User Responsibility",
        detail: "You agree to use this website solely for testing and feedback purposes. Do not upload any confidential, sensitive, or personal information into the website. You agree not to use the website for any commercial or production purposes. You must be at least 18 years old to use this website. You agree to comply with all applicable laws and regulations in connection with your use of the website."
    },
    {
        title: "Feedback",
        detail: "Your feedback is valuable and will be used to improve the website. By submitting feedback, you grant us a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, modify, and distribute the feedback for any purpose without any obligation to you."
    },
    {
        title: "Security Concerns",
        detail: "If you encounter any security issues or vulnerabilities while using the website, you agree to report them immediately to our team."
    },
    {
        title: "Confidentiality",
        detail: "You agree to keep all information about this website confidential and not share any details with third parties without our written consent."
    },
    {
        title: "Intellectual Property Rights",
        detail: "All intellectual property rights in the website and its content are owned by us. You are granted a limited, non-exclusive, non-transferable license to use the website solely for testing and feedback purposes."
    },
    {
        title: "Termination Rights",
        detail: "We reserve the right to terminate or suspend your access to the website at any time, without notice or liability, for any reason whatsoever."
    },
    {
        title: "Data Handling and Privacy",
        detail: "We may collect certain data during your use of the website for the purpose of improving our services. All data will be handled in accordance with our Privacy Policy."
    },
    {
        title: "Third-Party Services and Links",
        detail: "Our website may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for their content or practices."
    },
    {
        title: "No Liability",
        detail: "In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the website; any conduct or content of any third party on the website; any content obtained from the website; or unauthorized access, use, or alteration of your transmissions or content."
    },
    {
        title: "Indemnification",
        detail: "You agree to indemnify, defend, and hold harmless Policy Check and its affiliates from any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your access to or use of the website."
    },
    {
        title: "Force Majeure",
        detail: "We shall not be liable for any failure to perform due to causes beyond our reasonable control, such as natural disasters, wars, or acts of government."
    },
    {
        title: "Modification of Terms",
        detail: "We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting to the website."
    },
    {
        title: "Severability",
        detail: "If any provision of these terms is found to be unenforceable or invalid, the remaining provisions shall remain in effect."
    },
    {
        title: "Entire Agreement",
        detail: "These terms constitute the entire agreement between you and us regarding the website and supersede any prior agreements."
    },
    {
        title: "Assignment",
        detail: "You may not assign or transfer your rights or obligations under these terms without our prior written consent."
    },
    {
        title: "Export Controls",
        detail: "You agree to comply with all applicable export and re-export control laws and regulations."
    },
    {
        title: "Governing Law and Dispute Resolution",
        detail: "These terms and your use of the website are governed by the laws of Australia and New Zealand. Any disputes arising out of or relating to these terms shall be resolved through binding arbitration in accordance with the applicable arbitration rules. You agree to submit to the exclusive jurisdiction of the courts in Australia and New Zealand."
    },
    {
        title: "Acceptance of Terms",
        detail: "By accessing or using the website, you agree to be bound by these terms."
    },
    {
        title: "Terms and Conditions",
        detail: "Click here for our standard Terms and Conditions."
    }
];


function WelcomePopup() {
    const [selectedItem, setSelectedItem] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);

    useEffect(() => {
        // Check if the disclaimer has been accepted by the user
        if (userContext.details?.username) {
            const hasSeenDisclaimerModal = localStorage.getItem(`hasSeenDisclaimerModal_${userContext.details.username}`);
            if (!hasSeenDisclaimerModal || hasSeenDisclaimerModal === 'declined') {
                setShowPopup(true);
            }
        }
    }, [userContext]);

    useEffect(() => {
        // Disable all vertical scrolling when the popup is active
        if (showPopup) {
            document.body.classList.add('disable-vertical-scroll');
        } else {
            document.body.classList.remove('disable-vertical-scroll');
        }

        // Cleanup to remove the class when the component unmounts or popup closes
        return () => {
            document.body.classList.remove('disable-vertical-scroll');
        };
    }, [showPopup]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "users/api/documents", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => setDocuments(data));
    }, []);

    const handleItemClick = (index) => {
        setSelectedItem(selectedItem === index ? null : index);
    };

    const logoutHandler = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userContext.token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error: ${response.status}`);
            }

            setUserContext((oldValues) => ({
                ...oldValues,
                details: undefined,
                token: null,
            }));

            window.localStorage.setItem("logout", Date.now());
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleAccept = () => {
        setShowPopup(false);
        localStorage.setItem(`hasSeenDisclaimerModal_${userContext.details.username}`, 'accepted');
    };

    const handleDecline = () => {
        setShowPopup(false);
        localStorage.setItem(`hasSeenDisclaimerModal_${userContext.details.username}`, 'declined');
        logoutHandler();
    };

    const handleDocumentClick = (url) => {
        window.open(url, '_blank');
    };

    const handleNavigate = () => {
        documents.forEach(doc => {
            handleDocumentClick(doc.url);
        });
    };

    return (
        <>
            {showPopup && (
                <>
                    <div className="welcome-popup-overlay"></div>

                    <div className="welcome-popup">
                        <div className="welcome-popup-title">Prototype Disclaimer</div>
                        <div className="welcome-popup-disclaimer">
                            You acknowledge the information provided on this website is for educational and informational purposes only and should not be construed as financial advice. The content on this website is not intended to be a substitute for professional financial advice, diagnosis, or treatment. Always seek the advice of a qualified professional regarding your financial situation. Any reliance you place on any information from this website is strictly at your own risk.
                        </div>

                        <div className="welcome-popup-content">
                            {disclaimerItems.map((item, index) => (
                                <div key={index} className="disclaimer-item">
                                    <div
                                        className="disclaimer-item-title"
                                        onClick={() => handleItemClick(index)}
                                    >
                                        {item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title}
                                    </div>
                                    <div
                                        className={`disclaimer-item-detail ${item.title === 'Terms and Conditions' ? 'disclaimer-item-detail-link' : ''}`}
                                        onClick={item.title === 'Terms and Conditions' ? handleNavigate : undefined}
                                    >
                                        {item.detail}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="welcome-popup-buttons">
                            <button onClick={handleAccept} className="welcome-popup-button accept">Accept</button>
                            <button onClick={handleDecline} className="welcome-popup-button decline">Decline</button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default WelcomePopup;
