// Steps definition
const tourSteps = (expandMenu, setActiveTab) => [
  {
    element: '#quote',
    intro: 'This will get you started',
    position: 'bottom',
    title: 'Quote Builder',
    onbeforechange: function () {
      expandMenu();
    }
  },
  {
    element: '#current-policy',
    intro: 'After loading in a policy, this screen represents the current state of your client',
    position: 'bottom',
    title: 'Current State',
    onbeforechange: function () {
      if (this._direction === 'backward') {
        // Navigate to the previous tab if moving backward
        setActiveTab('Current Policy');
      }
    }
  },
  {
    element: '#policy-search',
    intro: 'Filter for the policies which are best suited',
    position: 'bottom',
    title: 'Search',
    onbeforechange: function () {
      setActiveTab('Policy Search');
    }
  },
  {
    element: '#policy-compare',
    intro: 'Choose 1 or 2 options to recommend to your client',
    position: 'bottom',
    title: 'Select Options',
    onbeforechange: () => {
      setActiveTab('Policy Compare');
    },
  },
  {
    element: '#build-quote',
    intro: 'Build a quote for your client',
    position: 'bottom',
    title: 'Quote Builder',
    onbeforechange: function () {
      if (this._direction === 'backward') {
        const researchElement = document.getElementById('quote');
        if (researchElement) {
          const linkElement = researchElement.querySelector('a'); // Select the first <a> child element
          if (linkElement) {
            linkElement.click(); // Programmatically trigger the click event on the <a> element
          }
        }
      }
      setActiveTab('Build Quote');
    },
  },
  {
    element: '#research',
    intro: 'You can also research policies',
    position: 'bottom',
    title: 'Research',
    onbeforechange: () => {
      const researchElement = document.getElementById('research');
      if (researchElement) {
        const linkElement = researchElement.querySelector('a'); // Select the first <a> child element
        if (linkElement) {
          linkElement.click(); // Programmatically trigger the click event on the <a> element
        }
      }
    },
  },
  {
    element: '#myTableBody',
    intro: 'Explore diferent policies',
    position: 'bottom',
    title: 'Policy Library',
    onbeforechange: () => {
      const tableBody = document.getElementById('myTableBody');
      if (tableBody) {
        const firstRow = tableBody.querySelector('tr'); // Select the first row in the tbody
        if (firstRow) {
          firstRow.click(); // Programmatically trigger the click event on the first row
        }
      }
    },
  },
  {
    element: '#myTableBody',
    intro: 'Right click on a policy to show or compare policies',
    position: 'left',
    title: 'Right Click',
    onbeforechange: () => {
      const tableBody = document.getElementById('myTableBody');

      if (tableBody) {
        const thirdRowSecondCell = tableBody.querySelector('tr:nth-child(3) td:nth-child(6)');
        if (thirdRowSecondCell) {
          const rect = thirdRowSecondCell.getBoundingClientRect();
          // Calculate the center of the cell
          const xPos = rect.left + rect.width / 2;
          const yPos = rect.top + rect.height / 2;

          // Create and dispatch a right-click (contextmenu) event
          const rightClickEvent = new MouseEvent('contextmenu', {
            bubbles: true,
            cancelable: true,
            view: window,
            button: 2, // Button 2 indicates the right mouse button
            clientX: xPos, // Set to the center of the element
            clientY: yPos

          });
          thirdRowSecondCell.dispatchEvent(rightClickEvent); // Dispatch right-click event to the second cell in the third row
        }
      }
    }
  },
  {
    element: '#addpolicy',
    intro: 'Start by adding a policy',
    position: 'right',
    title: 'Add Policy',
    onbeforechange: function () {
      const researchElement = document.getElementById('quote');
      if (researchElement) {
        const linkElement = researchElement.querySelector('a'); // Select the first <a> child element
        if (linkElement) {
          linkElement.click(); // Programmatically trigger the click event on the <a> element
        }
      }
      setActiveTab('Current Policy');
      expandMenu(); // Call expandMenu when this step is triggered
    }
  },
];

export default tourSteps;
