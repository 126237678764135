import React from 'react';
import './SystemErrorMessage.css';

const SystemErrorMessage = ({ message }) => {
  return (
    <div className="error-message-overlay">
      <div className="error-message-container">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default SystemErrorMessage;
