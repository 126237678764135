import React, { useState, useContext, useEffect } from 'react';
import { getCoverages, getCoverageReferences, getCoverageReference } from '../../apis/Coverage'; // Import the getCoverages API function
import { formatString } from '../../utils/utils';
import { CountryContext } from '../../context/CountryContext';
import PDFHighlighter from './components/PDFHighlighter';
import WaitingDiv from '../WaitingDiv';

import './ShowPolicy.css';
import './ShowPolicyNew.css';

const ShowPolicyNew = ({ policy, closeOverlay }) => {
    const { country } = useContext(CountryContext);
    const [coveragesData, setCoveragesData] = useState([]);
    const [maxColumns, setMaxColumns] = useState(0); // Max columns based on max coverageKey depth
    const [expandedColumns, setExpandedColumns] = useState({}); // Track which columns are expanded
    const [comments, setComments] = useState({}); // Store comments for each row
    const [detailsPopup, setDetailsPopup] = useState(null); // Store details for the popup
    const [coverageReferencesData, setCoverageReferencesData] = useState([]); // State to store the coverage references
    const [isExpanded, setIsExpanded] = useState(false); // Collapsed by default
    const [isTableRendered, setIsTableRendered] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedSearchKey, setSelectedSearchKey] = useState('');
    const [expandedReferenceItems, setExpandedReferenceItems] = useState({}); // Store which reference items are expanded


    const toggleReferenceExpand = (index) => {
        setExpandedReferenceItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSearchClick = (e) => {
        setSearchQuery('');
        setSelectedSearchKey('');
    }

    // Clear button handler
    const handleClearClick = () => {
        setSearchQuery(''); // Clear the search query
        setSelectedSearchKey('');
        setSearchResults([]); // Clear the dropdown results
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.length > 0) {
            // Get all keys at any depth
            const allKeys = getAllKeys(policy.coverages);
            const results = allKeys.filter((key) => key.toLowerCase().includes(query.toLowerCase()));
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    // Select a key from the dropdown
    const handleSearchSelect = (key) => {
        setSelectedSearchKey(key);
        setSearchQuery(key);
        setSearchResults([]);
    };
    const getAllKeys = (obj, parentKey = '', keysSet = new Set()) => {
        if (typeof obj === 'object' && obj !== null) {
            Object.keys(obj).forEach((key) => {
                // Add the current key only if it doesn't exist already
                keysSet.add(formatString(key)); // Add just the key (not parentKey.key)

                // Recursively check nested objects for more unique keys
                if (typeof obj[key] === 'object') {
                    getAllKeys(obj[key], key, keysSet); // Pass key as new parentKey
                }
            });
        }
        return Array.from(keysSet); // Convert Set to array
    };


    // Ensure all columns are expanded by default on load
    useEffect(() => {
        if (maxColumns > 0) {
            // Create an object where all columns are set to true
            const initialExpandedColumns = {};
            for (let i = 0; i < maxColumns; i++) {
                initialExpandedColumns[i] = true;
            }
            setExpandedColumns(initialExpandedColumns); // Set the state with expanded columns
        }
    }, [maxColumns]);

    useEffect(() => {
        const expandedKeys = {};
        const expandAll = (coverage, parentKey = '') => {
            if (coverage && typeof coverage === 'object') {
                Object.entries(coverage).forEach(([key, value]) => {
                    const currentKey = `${parentKey}.${key}`;
                    expandedKeys[currentKey] = true;
                    if (value && typeof value === 'object' && !value?.included) {
                        expandAll(value, currentKey);
                    }
                });
            }
        };

        if (policy && policy.coverages) {
            Object.keys(policy.coverages).forEach((coverageKey) => {
                expandAll(policy.coverages[coverageKey], coverageKey);
            });
        }

    }, [policy]);

    useEffect(() => {
        const fetchCoverages = async () => {
            try {
                const data = await getCoverages(); // Call the API to get coverages
                setCoveragesData(data); // Store the fetched coverages in state
            } catch (error) {
                console.error('Error fetching coverages:', error);
            }
        };
        fetchCoverages();
    }, []);

    // This will run when the component mounts
    useEffect(() => {
        const fetchCoverageReferences = async () => {
            try {
                // Call the API to get coverage references with sample query, category, and provider
                const data = await getCoverageReferences(policy?.type, policy?.provider, policy?.fileName);
                setCoverageReferencesData(data); // Store the fetched data in state
            } catch (error) {
                console.error('Error fetching coverages:', error);
            }
        };

        fetchCoverageReferences(); // Fetch the coverage references on component mount
    }, []); // Empty dependency array ensures this runs only once, when the component mounts


    const findCoverageDescription = (coverageId) => {
        const matchingCoverage = coveragesData.find((coverage) => coverage.id.includes(coverageId));
        return matchingCoverage ? matchingCoverage.description : '';
    };

    const findCoverageConsiderations = (coverageId) => {
        const matchingCoverage = coveragesData.find((coverage) => coverage.id.includes(coverageId));
        return matchingCoverage ? matchingCoverage.considerations : '';
    };

    const toggleColumnExpand = (index) => {
        setExpandedColumns((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const getMaxColumnCount = (obj) => {
        let maxDepth = 0;

        const traverse = (currentObj, currentDepth) => {
            if (typeof currentObj === 'object' && currentObj !== null) {
                Object.keys(currentObj).forEach((key) => {
                    if (key !== 'included') {
                        traverse(currentObj[key], currentDepth + 1);
                    }
                });
            } else {
                maxDepth = Math.max(maxDepth, currentDepth);
            }
        };
        traverse(obj, 0);
        return maxDepth - 1;
    };

    useEffect(() => {
        if (policy && policy.coverages) {
            const maxCols = getMaxColumnCount(policy.coverages);
            setMaxColumns(maxCols); // Set the number of columns dynamically
        }
    }, [policy]);

    const handleDetailsClick = (name, coverage, coverageDetails, path) => {
        coverageDetails['description'] = findCoverageDescription(coverage);
        coverageDetails['considerations'] = findCoverageConsiderations(coverage);
        coverageDetails['comments'] = comments[path] || ''; // Add comments to the details popup
        setDetailsPopup({ details: coverageDetails, path, name });
    };

    const closeDetailsPopup = () => {
        setDetailsPopup(null);
    };

    const renderCoverageRows = (coverage, path = [], level = 0, coverageKey) => {
        const rows = [];
        if (typeof coverage === 'object' && coverage !== null) {
            Object.entries(coverage).forEach(([key, value]) => {
                const currentPath = [...path, formatString(key)];

                // Convert currentPath to camelCase and join with '.'
                const camelCasePath = currentPath
                    .map((part, index) => {
                        // Lowercase first letter and remove spaces for camelCase effect
                        const camelCased = part.replace(/\s(.)/g, (match) => match.toUpperCase())
                            .replace(/\s/g, '')  // Remove any spaces in between
                            .replace(/^(.)/, (match) => match.toLowerCase());  // Lowercase first character
                        return camelCased;
                    })
                    .join('.'); // Join with '.' to form the full path


                // Add coverageKey to the start of camelCasePath
                const fullPathWithValueName = `${coverageKey}.${camelCasePath}`;

                if (typeof value === 'object' && value?.included === undefined) {
                    rows.push(...renderCoverageRows(value, currentPath, level + 1, coverageKey));
                } else {
                    const row = Array(maxColumns + 2).fill('');
                    row[0] = formatString(coverageKey);

                    currentPath.forEach((part, idx) => {
                        row[idx + 1] = part;
                    });

                    row[maxColumns] = value?.included ? 'Yes' : '';

                    const coverageDataForDisplay = searchCoverage(fullPathWithValueName)
                    // Add Comments input
                    row[maxColumns + 2] = (
                        <div style={{ position: 'relative' }}>
                            {coverageDataForDisplay ? (
                                <>
                                    <div className='references-container'>
                                        {coverageDataForDisplay.map((reference, index) => (
                                            <div key={index} className='reference-item'>
                                                {expandedReferenceItems[index] ? reference : `${reference.slice(0, 350)}...`}
                                                {reference.length > 150 && (
                                                    <div className="reference-item-toggle" onClick={() => toggleReferenceExpand(index)}>
                                                        {expandedReferenceItems[index] ? 'Show Less...' : 'Show More...'}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <button className="show-policy-comparison-table-button"
                                        onClick={() => handleDetailsClick(fullPathWithValueName, key, value, currentPath.join(' -> '))}>
                                        See Wording
                                    </button>
                                </>
                            ) : (
                                <WaitingDiv />
                            )}
                        </div>
                    );
                    if (!selectedSearchKey || selectedSearchKey === '' || currentPath.join('.').startsWith(selectedSearchKey)) {
                        rows.push(row);
                    }
                }
            });
        }
        return rows;
    };


    const mergeCells = (rows, colIndex) => {
        const mergedRows = [];
        let lastText = '';
        let lastRowIndex = 0;
        let spanCount = 1;

        rows.forEach((row, rowIndex) => {

            // Ensure the row is initialized and has enough columns
            if (!Array.isArray(row)) {
                row = Array(colIndex + 1).fill(''); // Initialize row with empty array if it's not an array
            }

            const cellText = row[colIndex];

            // Avoid merging empty strings
            if (cellText === '') {
                mergedRows.push([...row]);  // Directly add the row without merging
                return;
            }

            if (cellText === lastText) {
                spanCount += 1;
            } else {
                if (spanCount > 1) {

                    // Ensure the merged row is initialized
                    if (!Array.isArray(mergedRows[lastRowIndex])) {
                        mergedRows[lastRowIndex] = [...rows[lastRowIndex]] || Array(colIndex + 1).fill('');
                    }

                    // Apply rowSpan to the previous set of rows
                    mergedRows[lastRowIndex][colIndex] = { text: lastText, rowSpan: spanCount };

                    // Hide the spanned cells in subsequent rows
                    for (let i = lastRowIndex + 1; i < lastRowIndex + spanCount; i++) {
                        if (!Array.isArray(mergedRows[i])) {
                            mergedRows[i] = [...rows[i]] || Array(colIndex + 1).fill('');  // Initialize row
                        }
                        mergedRows[i][colIndex] = { text: '', rowSpan: 0 };  // Hide merged cells
                    }
                }
                lastText = cellText;
                lastRowIndex = rowIndex;
                spanCount = 1;
            }

            // Ensure the current row is initialized in mergedRows
            if (!Array.isArray(mergedRows[rowIndex])) {
                mergedRows[rowIndex] = [...row];
            }
        });

        if (spanCount > 1) {

            // Apply rowSpan to the last set of rows
            if (!Array.isArray(mergedRows[lastRowIndex])) {
                mergedRows[lastRowIndex] = [...rows[lastRowIndex]] || Array(colIndex + 1).fill('');
            }
            mergedRows[lastRowIndex][colIndex] = { text: lastText, rowSpan: spanCount };

            for (let i = lastRowIndex + 1; i < lastRowIndex + spanCount; i++) {
                if (!Array.isArray(mergedRows[i])) {
                    mergedRows[i] = [...rows[i]] || Array(colIndex + 1).fill('');  // Initialize row
                }
                mergedRows[i][colIndex] = { text: '', rowSpan: 0 };  // Hide merged cells
            }
        }

        return mergedRows;
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Function to search coverageReferencesData for a matching coverage
    const searchCoverage = (name) => {
        const foundRecord = coverageReferencesData.find((item) => item.coverage === name);
        console.log("search coverageReferencesData for a matching coverage", name, " found: ", foundRecord, " data:", coverageReferencesData);
        return foundRecord ? foundRecord.references : null;  // Return null if not found
    };

    return (
        <div className="show-policy-overlay" onClick={closeOverlay}>
            <div className="show-policy-compare-popup" onClick={(e) => e.stopPropagation()}>
                <button className="show-policy-close-button" onClick={detailsPopup ? closeDetailsPopup : closeOverlay}>&times;</button>

                {!detailsPopup && (
                    <>
                        <div className='show-policy-new-comparison-table-header'>
                            <div className='show-policy-comparison-table-header-item'>
                                <strong>Provider:</strong> {policy?.provider}
                            </div>
                            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                            <div className='show-policy-comparison-table-header-item'>
                                <strong>Policy:</strong> {policy?.name}
                            </div>
                        </div>



                        {/* Search Input */}
                        <div className='show-policy-search'>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onClick={handleSearchClick}
                                placeholder="Search for coverage..."
                            />
                            <button className="clear-button" onClick={handleClearClick}>Clear</button>
                            {searchResults.length > 0 && (
                                <ul className="search-dropdown">
                                    {searchResults.map((result, index) => (
                                        <li key={index} onClick={() => handleSearchSelect(result)}>
                                            {result}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>


                        <div className='show-policy-comparison-table-scroll'>
                            {coveragesData.length < 1 && coverageReferencesData.length < 1 ? (
                                <>
                                    <WaitingDiv />
                                </>
                            ) : (
                                <table className="show-policy-comparison-table">
                                    <thead>
                                        <tr>
                                            {Array.from({ length: maxColumns }).map((_, index) => (
                                                <th key={index} >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                        {expandedColumns[index] && (
                                                            <p style={{ verticalAlign: 'top', margin: '4px' }}>{expandedColumns[index] && `Level ${index + 1}`}</p>
                                                        )}
                                                        <button
                                                            className="new-show-policy-expand-collapse-button"
                                                            onClick={() => toggleColumnExpand(index)}
                                                        >
                                                            {expandedColumns[index] ? '-' : '+'}
                                                        </button>
                                                    </div>
                                                </th>
                                            ))}
                                            <th>Included</th>
                                            <th>Terms</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {policy && policy.coverages && Object.keys(policy.coverages).map((coverageKey) => {
                                            let coverageRows = renderCoverageRows(policy.coverages[coverageKey], [], 0, coverageKey);

                                            for (let i = 0; i < maxColumns; i++) {
                                                coverageRows = mergeCells(coverageRows, i);
                                            }

                                            return coverageRows.map((row, idx) => (
                                                <tr key={`${coverageKey}-${idx}`}>
                                                    {row.slice(0, maxColumns).map((col, i) => {
                                                        if (!expandedColumns[i] && col) {
                                                            // If the column is collapsed, don't display content
                                                            return <td key={i}></td>;
                                                        }
                                                        if (col?.rowSpan > 0) {
                                                            return (
                                                                <td key={i} rowSpan={col.rowSpan}>
                                                                    {col.text}
                                                                </td>
                                                            );
                                                        } else if (col?.rowSpan === 0) {
                                                            return null; // Skip hidden cells
                                                        } else {
                                                            return <td key={i}>{col}</td>;
                                                        }
                                                    })}
                                                    <td className={row[maxColumns] === 'Yes' ? 'show-policy-comparison-table-included-cell' : ''}>
                                                        {row[maxColumns]}
                                                    </td>
                                                    <td style={{ padding: '5px', minWidth: '400px' }}>{row[maxColumns + 2]}</td>
                                                </tr>
                                            ));
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </>
                )}
                {detailsPopup && (
                    <>
                        <div className="show-policy-comparison-details-header">
                            <div
                                className="new-show-policy-header-expand-collapse-button"
                                onClick={toggleIsExpanded}
                            >i</div>
                            {isExpanded ? (
                                <>
                                    <table className="show-policy-detailed-comparison-table">
                                        <thead>
                                            <tr>
                                                <th className="show-policy-comparison-table-bold">Coverage Item</th>
                                                <th className="show-policy-comparison-table-bold">Provider</th>
                                                <th className="show-policy-comparison-table-bold">Policy Type</th>
                                                <th className="show-policy-comparison-table-bold">Policy Name</th>
                                                <th className="show-policy-comparison-table-bold">Effective Date</th>
                                                <th className="show-policy-comparison-table-bold">File</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{detailsPopup.path}</td>
                                                <td>{policy?.provider}</td>
                                                <td>{policy?.type}</td>
                                                <td>{policy?.name}</td>
                                                <td>{policy?.effectiveDate}</td>
                                                <td>{policy?.fileName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="show-policy-detailed-comparison-table">
                                        <thead>
                                            <tr>
                                                <th className="show-policy-comparison-table-bold">Included</th>
                                                <th className="show-policy-comparison-table-bold">Limit</th>
                                                <th className="show-policy-comparison-table-bold">Conditions</th>
                                                <th className="show-policy-comparison-table-bold">Conditions (#)</th>
                                                <th className="show-policy-comparison-table-bold">Confidence</th>
                                                <th className="show-policy-comparison-table-bold">Complexity</th>
                                                <th className="show-policy-comparison-table-bold">Description</th>
                                                <th className="show-policy-comparison-table-bold">Considerations</th>
                                                <th className="show-policy-comparison-table-bold">Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{detailsPopup.details?.included ? 'Yes' : 'No'}</td>
                                                <td>{detailsPopup.details?.limit}</td>
                                                <td>{detailsPopup.details?.conditions}</td>
                                                <td>{detailsPopup.details?.conditionsNumber}</td>
                                                <td>{detailsPopup.details?.confidence}</td>
                                                <td>{detailsPopup.details?.complexity}</td>
                                                <td>{detailsPopup.details?.description}</td>
                                                <td>{detailsPopup.details?.considerations}</td>
                                                <td>{detailsPopup.details?.comments}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <div style={{ height: '20px', width: '100%' }}></div>
                            )}
                        </div>
                        {coverageReferencesData?.length > 0 ? (
                            <PDFHighlighter
                                url={`${process.env.REACT_APP_API_ENDPOINT}users/pdfs/${country}/${policy?.provider}/${capitalizeFirstLetter(policy?.type)}/${encodeURIComponent(policy?.fileName)}`}
                                search={searchCoverage(detailsPopup.name)} />
                        ) : (
                            <p>No coverage references found.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    )
};

export default ShowPolicyNew;
