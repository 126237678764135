import React, { useContext } from 'react';
import computerImage from "../../images/computer_icon.png";
// context
import { UserContext } from "../../context/UserContext";
// css
import './Message.css';

const Message = ({ text, isBot }) => {
  const [userContext] = useContext(UserContext);

  const errorMessage = text && text.error;

  return (
    <div className={isBot ? 'message bot' : 'message user'}>
      <div className="image-container">
        {isBot ? (
          <img src={computerImage} style={{ opacity: 0 }} alt="Bot" />
        ) : (
          <div className="message-circle">
            {(userContext.details && userContext.details.firstName && userContext.details.lastName) ?
              (userContext.details.firstName.charAt(0) + userContext.details.lastName.charAt(0)) :
              'AB'
            }
          </div>
        )}
      </div>
      <div className={isBot ? 'message-text bot' : 'message-text user'}>
        {errorMessage ? (
          <div>Error: {errorMessage}</div>
        ) : (
          text
        )}
      </div>
    </div>
  );
};

export default Message;
