import React from 'react';
import logoDoneURL from "../../icons/done.png";
import Tooltip from '../../components/Tooltip';

const AdviceHeader = ({ id, toggleSection, title, tooltip, completed, pdf, isOpen }) => {
    return (
        <div className="form-section-header"
            onClick={() => toggleSection(id)}>
            <div className="form-section-header-title">
                <img src={logoDoneURL} alt=""
                    style={{
                        width: '25px',
                        filter: !completed ? 'grayscale(100%)' : ''
                    }} />
                <Tooltip text={tooltip}>
                    <div>{title}</div>
                </Tooltip>
            </div>
            {!pdf &&
                (
                    <div className={`statement-arrowhead ${isOpen ? 'statement-inverted' : ''}`}></div>
                )
            }
        </div >
    )
};

export default AdviceHeader;