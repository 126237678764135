import React, { useState } from 'react';
import { formatString } from '../../utils/utils';
import './MenuItemList.css';

const MenuItemList = ({ title, items, searchTerm, onItemDrag, inactiveItems }) => {
  const [visibleCount, setVisibleCount] = useState(3);

  const filteredItems = items.filter((item) =>
    item.key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleShowMore = () => {
    setVisibleCount(visibleCount + 10);
  };

  const handleShowLess = () => {
    setVisibleCount(3);
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData('application/json', JSON.stringify(item));
    onItemDrag(item.key);
  };

  return (
    <div className="menu-item-list">
      <h3 className="menu-item-list-header">{title}</h3>
      {filteredItems.slice(0, visibleCount).map((item) => (
        <div
          key={item.key}
          className={`menu-item-row ${inactiveItems.includes(item.key) ? 'inactive' : ''}`}
          draggable={!inactiveItems.includes(item.key)}
          onDragStart={(e) => handleDragStart(e, item)}
        >
          <span>{formatString(item.key)}</span>
          <span>{item.limit}/{item.excess}</span>
        </div>
      ))}
      {filteredItems.length > visibleCount && (
        <div className="menu-show-more" onClick={handleShowMore}>
          Show more
        </div>
      )}
      {visibleCount > 3 && (
        <div className="menu-show-less" onClick={handleShowLess}>
          Show less
        </div>
      )}
    </div>
  );
};

export default MenuItemList;
