import React, { useEffect } from "react";
import PricingComponent from "../../../components/PricingComponent";
import './MyPlan.css';

function MyPlan() {
  useEffect(() => {
    document.title = 'MyPlan';
  }, []);

  return (
    <div className="myplan-container">
      <h2>My Plan</h2>
      <p>Manage your payment plan.</p>
      <div className="myplan-container-center">
        <PricingComponent />
      </div>
    </div >

  );
}

export default MyPlan;
