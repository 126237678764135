import React from 'react';
import './PageBanner.css';

const PageBanner = ({ items, height = 'auto'}) => {
    return (
        <div className="page-banner-container" style={{height:height}}>
            {items.map((item, index) => (
                <div key={index} className="page-banner-item">
                    <div className='page-banner-large'>{item.largeText}</div>
                    <div className='page-banner-small'>{item.smallText}</div>
                </div>
            ))}
        </div>
    );
};

export default PageBanner;
