import React, { useState } from 'react';
import './YourPremiumTable.css';

const YourPremiumTable = ({ setCompleted, initialCosts = {} }) => {
  const [timePeriod, setTimePeriod] = useState('fortnightly');
  const [costs, setCosts] = useState(initialCosts);

  const gstRate = 0.10;

  const calculateGST = (amount) => amount * gstRate;
  const calculateTotal = (amount) => amount + calculateGST(amount);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  const getTimePeriod = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const totalPremium = Object.values(costs).reduce((acc, breakdown) => {
    const amount = parseFloat(breakdown[timePeriod]);
    return acc + (amount !== undefined ? calculateTotal(amount) : 0);
  }, 0);

  const handleAmountChange = (item, period, value) => {
    setCompleted(true);
    const updatedCosts = { ...costs };
    updatedCosts[item][period] = value;
    setCosts(updatedCosts);
  };

  const handleBlur = (item, period) => {
    const updatedCosts = { ...costs };
    let value = updatedCosts[item][period];
    if (value && !value.toString().includes('.')) {
      value = parseFloat(value).toFixed(2);
      updatedCosts[item][period] = value;
      setCosts(updatedCosts);
    }
  };

  return (
    <div className="table-container">
      <span><strong>Pricing options</strong></span>
      <table className="premium-table">
        <thead>
          <tr>
            <th>Insurance</th>
            <th>Weekly</th>
            <th>Fortnightly</th>
            <th>Monthly</th>
            <th>Yearly</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(costs).map(([item, breakdown]) => (
            <tr key={item}>
              <td>{item}</td>
              {['weekly', 'fortnightly', 'monthly', 'yearly'].map((period) => (
                <td key={period} >
                  <div className='premium-table-input'>
                    $
                    <input
                      type="text"
                      value={breakdown[period]}
                      onChange={(e) => handleAmountChange(item, period, e.target.value)}
                      onBlur={() => handleBlur(item, period)}
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <span><strong>Your Premium</strong></span>
      <div>
        <select id="timePeriod" value={timePeriod} onChange={handleTimePeriodChange}>
          <option value="weekly">Weekly</option>
          <option value="fortnightly">Fortnightly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <table className="premium-table">
        <thead>
          <tr>
            <th>Insurance</th>
            <th>{getTimePeriod(timePeriod)} Amount</th>
            <th>GST</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(costs).map(([item, breakdown]) => (
            <tr key={item}>
              <td>{item}</td>
              <td>${breakdown[timePeriod] !== undefined ? formatNumber(parseFloat(breakdown[timePeriod])) : 'N/A'}</td>
              <td>${breakdown[timePeriod] !== undefined ? formatNumber(calculateGST(parseFloat(breakdown[timePeriod]))) : 'N/A'}</td>
              <td>${breakdown[timePeriod] !== undefined ? formatNumber(calculateTotal(parseFloat(breakdown[timePeriod]))) : 'N/A'}</td>
            </tr>
          ))}
          <tr className="total-row">
            <td colSpan="3" className="total-label">Total {getTimePeriod(timePeriod)} Premium</td>
            <td>${formatNumber(totalPremium)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default YourPremiumTable;
