import React, { useState, useEffect, useRef } from 'react';
import { Loader } from "@googlemaps/js-api-loader";
import './MyPostCodeMapComponent.css';

const containerStyle = {
    minWidth: '100%',
    minHeight: '600px',
};

const defaultCenter = { lat: -36.8485, lng: 174.7633 }; // Auckland default center
const COUNTRY = 'New Zealand'; // Full country name for better geocoding results

const MyPostCodeMapComponent = ({ brokers = [] }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        if (brokers && brokers.length > 0) {
            
            // rest of the map initialization and marker setup code
        }
    }, [brokers]);
    
    useEffect(() => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS,
            version: "weekly",
            libraries: ["places"]
        });

        loader.load().then(() => {
            const google = window.google;
            const mapInstance = new google.maps.Map(mapRef.current, {
                center: defaultCenter,
                zoom: 10,
                ...containerStyle,
            });
            setMap(mapInstance);
        }).catch(e => {
            console.error('Error loading Google Maps API:', e);
        });
    }, []);

    useEffect(() => {
        if (map && window.google) {
            const google = window.google;
            const service = new google.maps.Geocoder();
            const bounds = new google.maps.LatLngBounds();

            brokers.forEach((broker) => {
                if (broker.postcode && broker.postcode.length >= 4) {
                    service.geocode({ 'address': `${broker.postcode}, postcode, ${COUNTRY}` }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                            const location = results[0].geometry.location;
                            const marker = new google.maps.Marker({
                                map: map,
                                position: location,
                                title: broker.name,
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 10,
                                    fillColor: "orange", 
                                    fillOpacity: 1.0,
                                    strokeWeight: 2,
                                    strokeColor: "white"
                                }
                            });

                            const infoWindow = new google.maps.InfoWindow({
                                content: `
                                    <div class="info-window-content">
                                        <h3 class="info-window-title">${broker.name}</h3>
                                        <p class="info-window-email">Email: ${broker.email}</p>
                                        <p class="info-window-phone">Phone: ${broker.phone}</p>
                                        <p class="info-window-postcode">Postcode: ${broker.postcode}</p>
                                        <p class="info-window-website">
                                            Website: <a href="${broker.website}" target="_blank">${broker.website}</a>
                                        </p>
                                    </div>`
                            });

                            marker.addListener('click', () => {
                                infoWindow.open(map, marker);
                            });

                            marker.addListener('mouseover', () => {
                                infoWindow.open(map, marker);
                            });

                            marker.addListener('mouseout', () => {
                                infoWindow.close();
                            });

                            markers.push(marker);
                            bounds.extend(location);

                            if (markers.length === brokers.length) {
                                map.fitBounds(bounds);
                                map.setZoom(10);
                            }
                        } else {
                            console.error(`Geocode for ${broker.postcode} was not successful:`, status);
                        }
                    });
                }
            });

            setMarkers(markers);
        }
    }, [map, brokers]);

    return (
        <div className="map-parent">
            <div ref={mapRef} style={containerStyle} className="map-container"></div>
        </div>
    );
};

export default MyPostCodeMapComponent;
