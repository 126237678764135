import React from 'react';
import { formatString } from '../../../../utils/utils';
import './CheckboxGroup.css';

const CheckboxGroup = ({ label, options = {}, state = {}, handleChange }) => {
  return (
    <div className="checkbox-group">
      {Object.keys(options).map((key) => (
        <div
          className="checkbox-group-item"
          key={key}
          data-key={key} // Pass the key as data attribute for div
          onClick={handleChange} // Move the onClick to the div
        >
          <input
            className="checkbox-group-item-input"
            type="checkbox"
            name={key}
            checked={state[key] || false} // Ensure the checkbox is controlled
            readOnly // Make the checkbox readOnly since we handle clicks on the div
          />
          <div>{formatString(key)}</div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
