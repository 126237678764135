import React, { useState } from 'react';
import './CompareStarRating.css';

const CompareStarRating = ({ rating, confidence, complexity, conditions }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);
    const handleShowMore = () => setExpanded(!expanded);

    return (
        <div className="star-rating-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="star-rating">
                {Array.from({ length: 5 }).map((_, index) => (
                    <span key={index} className={index < rating ? 'gold-star' : 'grey-star'}>
                        ★
                    </span>
                ))}
            </div>
            {showTooltip && (
                <div className={`tooltip ${expanded ? 'expanded' : ''}`}>
                    <p>Confidence: {confidence}</p>
                    <p>Complexity: {complexity}</p>
                    <p>Conditions: {conditions}</p>
                    {!expanded && <button onClick={handleShowMore}>Show more</button>}
                    {expanded && (
                        <>
                            <hr />
                            <p><strong>Rating Scale:</strong></p>
                            <p>
                                Each star is based on the product of confidence, complexity, and conditions. The higher these values,
                                the more stars are awarded. Confidence reflects the reliability of the data, complexity shows how detailed 
                                the policy is, and conditions indicate the policy's restrictions.
                            </p>
                            <button onClick={handleShowMore}>Show less</button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CompareStarRating;
