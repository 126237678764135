import ProfileURL from '../../icons/blank_profile_2.webp';

export const leadershipProfiles = [{
  name: "Simon",
  title: "CEO & Chief Product Officer",
  bio: "Simon is CEO",
  image: ProfileURL,
  link: "/team/simon",
  description: `Simon is a seasoned leader in the financial services industry, with over 15 years of experience driving growth and innovation in banking, payments, and fintech. His journey began with a solid foundation in consumer banking and payments, where he honed his skills in team management, tech investment, and strategy execution.
  
    Throughout his career, Simon has held pivotal roles, including Country Leader for Temenos in New Zealand and Regional Banking & Payments Leader at NCR Corporation in Australia. His strategic vision and sales acumen have consistently led to successful market expansions and new business wins.
  
    Now as CEO of PolicyCheck, Simon is leveraging his extensive industry knowledge to revolutionize the insurance brokerage landscape. Under his leadership, PolicyCheck is set to transform how brokers manage policies, driving efficiency, compliance, and customer satisfaction across the board.`,
  twitterLink: "https://x.com/simon_p_archer",
  facebookLink: "",
  linkedinLink: "https://linkedin.com/in/simon",
},
{
  name: "Andrew",
  title: "Chief Customer Officer",
  bio: "Andrew is the Chief Customer Officer",
  image: ProfileURL,
  link: "/team/andrew",
  description: `Andrew brings extensive leadership experience in the financial and fintech sectors, making him an invaluable asset to PolicyCheck. With over two decades of expertise, Andrew has held prominent roles in top-tier organizations across Australia and New Zealand. He served as the Chief Customer Officer at Volt Bank, where he played a crucial role in shaping the customer experience and platform strategy for one of Australia's first neobanks.
  
  Prior to Volt Bank, Andrew was the CEO of Mogoplus, where he led the company's efforts in delivering innovative financial data solutions. His leadership was instrumental in driving growth and establishing the company as a key player in the fintech landscape. Andrew's career also includes significant contributions as Executive General Manager of Retail Distribution at Firstfolio, where he managed large-scale operations and fostered strong customer relationships.
  
  Andrew’s recent work with HES FinTech as a Market Advisor/Partner for the ANZ region highlights his ongoing commitment to driving innovation in the financial services industry. His deep understanding of customer needs and fintech solutions positions him perfectly to lead PolicyCheck's customer-focused initiatives, ensuring that brokers and clients receive the highest level of service and satisfaction.`,
  twitterLink: "",
  facebookLink: "",
  linkedinLink: "https://www.linkedin.com/in/andrew-clouston-11a3a36a/",
},
{
  name: "Rod",
  title: "Chief Technology & Product Officer",
  bio: "Rod is the Chief Technology",
  image: ProfileURL,
  link: "/team/rod",
  description: "Rod Dutra is an entrepreneur and driven problem solver with a passion for uncovering deep customer problems and bringing ideas to life through technology. With extensive experience as a Chief Technology and Product Officer, Rod has led cross-functional teams of talented software engineers and designers, navigating projects from ideation to delivery and execution. His career includes co-founding and serving as CTO at LoanDolphin, where he applied his banking experience to solve real-world problems in the financial sector. As the Chief Technology & Product Officer at Joust, Rod continues to shape the company's vision and strategy, driving innovation and creating meaningful value through cutting-edge technology solutions. His leadership philosophy centers on fostering a healthy balance between speed and quality, ensuring that his teams build the right solutions to solve the right problems.",
  twitterLink: "",
  facebookLink: "",
  linkedinLink: "https://www.linkedin.com/in/roddutra/",
},
{
  name: "Graeme",
  title: "Chief Marketing Officer",
  bio: "Graeme is the Chief Marketing Officer",
  image: ProfileURL,
  link: "/team/graeme",
  description: "Graeme is a seasoned marketing leader with over 10 years of experience in driving revenue and client acquisition through innovative marketing strategies. He has a proven track record in managing large-scale marketing campaigns, stakeholder engagement, and digital platform optimization. Throughout his career, Graeme has held pivotal roles including Chief Marketing Officer at Joust, where he led the company’s marketing initiatives in fintech, insurance, and payments sectors. His expertise extends to areas such as lead generation, conversion rate optimization, and affiliate marketing. At PolicyCheck, Graeme leverages his deep industry knowledge to enhance brand awareness and drive growth, making sure that the company's innovative solutions reach the right audience with maximum impact.",
  twitterLink: "",
  facebookLink: "",
  linkedinLink: "https://www.linkedin.com/in/graemejohn/",
}, {
  name: "Matt",
  title: "Chief Risk & Compliance Officer",
  bio: "Matt is the Chief Risk & Compliance Officer",
  image: ProfileURL,
  link: "/team/matt",
  description: `Matt is an experienced leader in the financial services industry, with over 28 years of experience. He has a solid track record of leading and managing high-performing teams in senior and executive roles across both New Zealand and overseas. Matt is recognized for his transformational leadership and is passionate about providing innovative and customer-centric solutions in the home loan market.
  
  Before joining PolicyCheck, Matt was the Chief Operations Officer at Neo Prime Limited, where he led the risk and operations functions, ensuring compliance, efficiency, and quality across the organization. His leadership was instrumental in driving growth, leading change, and managing stakeholder expectations. Matt's expertise spans across enterprise risk management, financial services, and business transformation, making him an asset in navigating the complex landscape of insurance and financial services.
  
  Currently pursuing an MBA degree at the Australian Institute of Business, Matt continues to expand his knowledge and skills in business administration, management, and operations. His strategic vision and commitment to excellence are driving forces behind PolicyCheck's mission to enhance risk management and compliance in the insurance industry.`,
  twitterLink: "",
  facebookLink: "",
  linkedinLink: "https://www.linkedin.com/in/matthew-nauer/",
}
];
