import React from 'react';
import ClaimsList from '../../components/claims/ClaimsList';
import './Claims.css';

// Insurance specific data arrays
const firstNames = [
    "James", "Mary", "John", "Patricia", "Robert", "Jennifer", "Michael", "Linda", "William", "Elizabeth", "David",
    "Barbara", "Richard", "Susan", "Joseph", "Jessica", "Thomas", "Sarah", "Charles", "Karen", "Christopher",
    "Nancy", "Daniel", "Lisa", "Matthew"
  ];
  const lastNames = [
    "Smith", "Johnson", "Williams", "Brown", "Jones", "Miller", "Davis", "Garcia", "Rodriguez", "Wilson", "Martinez",
    "Anderson", "Taylor", "Thomas", "Hernandez", "Moore", "Martin", "Jackson", "Thompson", "White", "Lopez"
  ];
  const statusOptions = ['Pending', 'Approved', 'Denied', 'Under Review'];
  const coverageTypes = ['General Liability', 'Property Damage', 'Worker’s Compensation', 'Health', 'Auto'];
  const incidentTypes = ['Accident', 'Theft', 'Fire', 'Natural Disaster', 'Vandalism'];
  
  // Generate random first and last names
  function generateRandomFirstName(i) {
    return firstNames[i % firstNames.length];
  }
  
  function generateRandomLastName(i) {
    return lastNames[i % lastNames.length];
  }
  
  // Generate a random date within a specific range
  function generateRandomDate() {
    const start = new Date(2022, 0, 1);
    const end = new Date();
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  
  // Format date as MM/DD/YYYY
  function formatDate(date) {
    return date.toLocaleDateString();
  }
  
  // Generate a random string with a given length
  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // Generate a random claim amount
  function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  // Generate a list of claims with more realism and insurance context
  function generateClaims(n) {
    const claims = [];
    
    for (let i = 0; i < n; i++) {
      const firstName = generateRandomFirstName(i);
      const lastName = generateRandomLastName(i);
      const claimDate = formatDate(generateRandomDate());
      const claimAmount = `$${generateRandomNumber(500, 15000)}`;
      const status = statusOptions[Math.floor(Math.random() * statusOptions.length)];
      const policyNumber = `POL${i}${generateRandomString(6)}`;
      const coverageType = coverageTypes[i % coverageTypes.length];
      const incidentType = incidentTypes[i % incidentTypes.length];
      
      claims.push({
        clientName: `${firstName} ${lastName}`,
        address: `Address ${generateRandomString(10)}`,
        premium: `$${generateRandomNumber(100, 1000)}`,
        claimAmount,
        claimDate,
        status,
        policyNumber,
        coverageType,
        incidentType,
        incidentDescription: `Detailed incident description for ${incidentType}.`,
        costEstimates: `$${generateRandomNumber(1000, 10000)}`,
        thirdPartyDetails: Math.random() > 0.5 ? "N/A" : `Company ${generateRandomString(5)}`,
        additionalNotes: `Claim notes and background information for ${firstName} ${lastName}`
      });
    }
  
    return claims;
  }
    
const exampleClaims = generateClaims(15);

const Claims = () => {

    return (
        <div className="claims-page-container">
            <h2>Claims</h2>
            <div className="claims-page-container-white-page-container">
                <ClaimsList claims={exampleClaims} />
            </div>
        </div>
    );
};

export default Claims;
