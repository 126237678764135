import React from 'react';
import './WidgetBar.css';

const WidgetBar = ({ totalClients, rePolicies, firstTimePolicies, avgPremium, expiringPolicies }) => {

    const formatNumber = (num) => (isNaN(num) ? 0 : Math.round(num).toLocaleString());

    return (
        <div className="widget-bar">
            <div className="widget-row">
                <div className="widget">
                    <div className="widget-number" style={{color:'#4FA658'}}>{totalClients}</div>
                    <div className="widget-label">Clients</div>
                </div>
            </div>
            <div className="widget-row">
                <div className="widget">
                    <div className="widget-number" >{totalClients}</div>
                    <div className="widget-label">Policies</div>
                </div>
            </div>
            <div className="widget-row">
                <div className="widget">
                    <div className="widget-number" >{totalClients}</div>
                    <div className="widget-label">Quotes</div>
                </div>
            </div>
            <div className="widget-row" style={{flex:'3', minWidth:'180px'}}>
                <div className="widget">
                    <div className="widget-number" >{totalClients}
                    <span style={{fontWeight:'10pt'}}>/</span>
                    {totalClients}</div>
                    <div className="widget-label">Proposals Sent & Reviewed</div>
                </div>
            </div>
            <div className="widget-row">
                <div className="widget">
                <div className="widget-number" style={{color:'#f79c2c'}}>${formatNumber(avgPremium)}</div>
                    <div className="widget-label">Average Premium</div>
                </div>
            </div>
            <div className="widget-row">
                <div className="widget">
                <div className="widget-number">${formatNumber(avgPremium)}</div>
                    <div className="widget-label">Total Premiums</div>
                </div>
            </div>
            <div className="widget-row" style={{flex:'4', minWidth:'330px'}}>
                <div className="widget-label" >Expiring</div>
                <div className="widget">
                    <div className="widget-number-center" style={{color:'#ea3b2e'}}>{expiringPolicies.oneWeek}</div>
                    <div className="widget-label-sub" >1 Week</div>
                </div>
                <div className="widget-divider"></div>
                <div className="widget">
                    <div className="widget-number-center" style={{color:'#f79c2c'}}>{expiringPolicies.twoWeeks}</div>
                    <div className="widget-label-sub">2 Weeks</div>
                </div>
                <div className="widget-divider"></div>
                <div className="widget">
                    <div className="widget-number-center" >{expiringPolicies.oneMonth}</div>
                    <div className="widget-label-sub">1 Month</div>
                </div>
                <div className="widget-divider"></div>
                <div className="widget">
                    <div className="widget-number-center" >{expiringPolicies.sixMonths}</div>
                    <div className="widget-label-sub">6 Months</div>
                </div>
                <div className="widget-divider"></div>
                <div className="widget">
                    <div className="widget-number-center" >{expiringPolicies.oneYear}</div>
                    <div className="widget-label-sub">1 Year</div>
                </div>
            </div>
        </div>
    );
};

export default WidgetBar;
