import React, { useEffect} from "react";
import { useGlobalContext } from '../../context/GlobalContext';
import Tiles from '../../components/Tiles';
import './Visualise.css';

function Visualise() {
  useEffect(() => {
      document.title = 'Visualise';
    }, []);
  const { activeDocument } = useGlobalContext();

  return (
    <div className="visualiser">
      <h2>Visualise</h2>
      <h3>{activeDocument !== null && activeDocument.policyName !== undefined ? activeDocument.policyName : 'To start, upload a policy on the left'}</h3>

      {activeDocument !== null && activeDocument.policyName !== undefined ?
        <Tiles /> : null
      }
    </div>
  );
}

export default Visualise;
