import React from 'react';
import { Pie } from 'react-chartjs-2';
import './ClientChurnReasonsChart.css';

const ClientChurnReasonsChart = ({ churnData }) => {
  const data = {
    labels: churnData.map(item => item.reason),
    datasets: [{
      label: 'Reasons for Client Churn',
      data: churnData.map(item => item.value),
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        // Add more colors for each reason
      ],
      hoverOffset: 4
    }]
  };
  
  const options = {
    plugins: {
      legend: {
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="client-churn-reasons-chart">
      <Pie data={data} options={options}/>
    </div>
  );
};

export default ClientChurnReasonsChart;
