import { useEffect, useState, useContext } from 'react';
import { ShowTourContext } from './../context/ShowTourContext'; // Import the context
import { UserContext } from "./../context/UserContext";
import introJs from 'intro.js'; // Import Intro.js
import 'intro.js/introjs.css'; // Import Intro.js CSS
import tourSteps from './steps';
import './steps.css';

// Variable to hold the Intro.js instance globally
let intro = null;

function startTourWithDelay({ expandMenu, setActiveTab }) {
  const checkElementAndStartTour = () => {
    const element = document.querySelector('#quote');
    if (element) {
      console.log("Element '#quote' found, starting tour");

      intro = introJs();
      const steps = tourSteps(expandMenu, setActiveTab);


      intro.setOptions({
        steps: steps,  // Ensure setActiveTab is passed correctly
        showProgress: false,
        showBullets: false
      });

      intro.onbeforechange(function (targetElement) {
        const currentStep = this._currentStep;
        const stepData = steps[currentStep];
        if (stepData && stepData.onbeforechange) {
          stepData.onbeforechange.call(this, targetElement);
        }
      });

      intro.onafterchange(function (targetElement) {
        const currentStep = this._currentStep;
        const stepData = steps[currentStep];
        if (stepData && stepData.onafterchange) {
          stepData.onafterchange.call(this, targetElement);
        }
      });

      intro.start(); // Start the tour
    } else {
      console.log("Element '#quote' not found, retrying...");
      setTimeout(checkElementAndStartTour, 500);  // Retry after 500ms if the element is not yet loaded
    }
  };

  checkElementAndStartTour();  // Start the check when the function is called
}



const useModalOnLoad = ({ expandMenu, setActiveTab }) => {
  const [userContext] = useContext(UserContext); 
  const { showTour,toggleShowTour } = useContext(ShowTourContext);

  useEffect(() => {
    if (showTour) {
      // Check the current count of visits from local storage
      const visits = localStorage.getItem('tourVisits') || 0;
      const visitCount = parseInt(visits, 10);

      // Determine if the modal should be shown (on first and second load)
      if (visitCount < 1) {
        console.log("startTourWithDelay");
        startTourWithDelay({ expandMenu, setActiveTab });
      }
      toggleShowTour();
      // Increment the visit count and update local storage
      localStorage.setItem('tourVisits', visitCount + 1);
    }
  }, [expandMenu, setActiveTab, showTour]);

  // Effect to stop the tour if the user logs out
  useEffect(() => {
    const username = userContext?.details?.username;

    // If no username and intro is active, close the tour
    if (!username && intro && intro._currentStep !== undefined) {
      console.log("User logged out, stopping the tour.");
      intro.exit(); // Stop the Intro.js tour
    }
  }, [userContext]);



};

export default useModalOnLoad;
