import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import './RecentlyAcceptedClients.css';

const RecentlyAcceptedClients = ({ clients, trendData }) => {
  const [timeInterval, setTimeInterval] = useState('daily'); // daily, weekly, or monthly

  const handleIntervalChange = (e) => {
    setTimeInterval(e.target.value);
  };

  const chartData = {
    labels: trendData[timeInterval].map(data => data.label),
    datasets: [{
      label: 'Number of Clients',
      data: trendData[timeInterval].map(data => data.value),
      backgroundColor: 'rgba(79,166,88,0.5)',
      borderColor: 'rgba(79,166,88,1)',
      borderWidth: 1
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="recently-accepted-clients">
      <div className='recently-accepted-clients-title'>
        <span>Policies Compared</span>
        <select className='recently-accepted-clients-filter' onChange={handleIntervalChange} value={timeInterval}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <Bar data={chartData} options={options} />
      <ul className="recently-accepted-clients-items">
        {clients.map((client, index) => (
          <li className="recently-accepted-clients-item" key={client.id || index}>
            <Link to="/clients" style={{ textDecoration: 'none' }}>
              <span>{client.name}</span>&nbsp;-&nbsp;
              <span>{client.startDate.toLocaleDateString()}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentlyAcceptedClients;
