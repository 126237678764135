import React, { useState, useEffect, useRef } from 'react';
import SocialIcons from './social/SocialIcons';
import './BlogSummary.css';

import blogPost1 from './blogs/BlogPost1';
import blogPost2 from './blogs/BlogPost2';
import blogPost3 from './blogs/BlogPost3';
import blogPost4 from './blogs/BlogPost4';
import blogPost5 from './blogs/BlogPost5';
import blogPost6 from './blogs/BlogPost6';
import blogPost7 from './blogs/BlogPost7';
import blogPost8 from './blogs/BlogPost8';
import blogPost9 from './blogs/BlogPost9';
import blogPost10 from './blogs/BlogPost10';

import Blog1 from "../images/blog_1.webp";
import Blog2 from "../images/blog_2.webp";
import Blog3 from "../images/blog_3.webp";
import Blog4 from "../images/blog_4.webp";
import Blog5 from "../images/blog_5.webp";
import Blog6 from "../images/blog_6.webp";
import Blog7 from "../images/blog_7.webp";
import Blog8 from "../images/blog_8.webp";
import Blog9 from "../images/blog_9.webp";
import Blog10 from "../images/blog_10.webp";
const blogs = [
    {
        id: 1,
        date: new Date(),
        label: "Understanding the Basics",
        title: "What Does Home Insurance Actually Cover?",
        image: Blog1,
        article: blogPost1,
        description: "Learn about the essential components of home insurance coverage and ensure your property is adequately protected against unforeseen events.",
        topics: ["Home Insurance", "Coverage Options"],
        products: ["Homeowners Insurance", "Renters Insurance"]
    },
    {
        id: 2,
        date: new Date(new Date().setDate(new Date().getDate() - 1)),
        label: "Choosing the Right Policy",
        title: "How to Match Your Home's Value with Insurance Coverage",
        image: Blog2,
        article: blogPost2,
        description: "Discover the importance of aligning your home's value with the appropriate level of insurance coverage to safeguard your investment and assets.",
        topics: ["Home Insurance", "Policy Comparison"],
        products: ["Homeowners Insurance"]
    },
    {
        id: 3,
        date: new Date(new Date().setDate(new Date().getDate() - 3)),
        label: "Don't Get Caught Unprepared",
        title: "The Importance of Additional Coverage Options in Home Insurance",
        image: Blog3,
        article: blogPost3,
        description: "Explore the supplementary coverage options available in home insurance policies to ensure comprehensive protection against unexpected disasters and liabilities.",
        topics: ["Coverage Options", "Risk Assessment"],
        products: ["Homeowners Insurance", "Disaster Insurance"]
    },
    {
        id: 4,
        date: new Date(new Date().setDate(new Date().getDate() - 5)),
        label: "Beyond the Basics",
        title: "Understanding Liability Coverage in Home Insurance",
        image: Blog4,
        article: blogPost4,
        description: "Delve into the intricacies of liability coverage in home insurance and learn how it shields you from financial repercussions in case of accidents on your property.",
        topics: ["Liability", "Home Insurance"],
        products: ["Liability Insurance", "Homeowners Insurance"]
    },
    {
        id: 5,
        date: new Date(new Date().setDate(new Date().getDate() - 10)),
        label: "Assessing Risk",
        title: "How Your Location Impacts Home Insurance Coverage Needs",
        image: Blog5,
        article: blogPost5,
        description: "Uncover the significance of your home's location in determining the appropriate level of insurance coverage and mitigate potential risks effectively.",
        topics: ["Risk Assessment", "Coverage Options"],
        products: ["Homeowners Insurance", "Disaster Insurance"]
    },
    {
        id: 6,
        date: new Date(new Date().setDate(new Date().getDate() - 20)),
        label: "Protecting Your Valuables",
        title: "Why Personal Property Coverage is Essential in Home Insurance",
        image: Blog6,
        article: blogPost6,
        description: "Learn about the importance of personal property coverage in home insurance policies and ensure your belongings are adequately protected against theft, damage, or loss.",
        topics: ["Personal Property", "Coverage Options"],
        products: ["Personal Property", "Homeowners Insurance"]
    },
    {
        id: 7,
        date: new Date(new Date().setDate(new Date().getDate() - 22)),
        label: "Preparing for the Worst",
        title: "Understanding Disaster Coverage Options in Home Insurance",
        image: Blog7,
        article: blogPost7,
        description: "Gain insights into the various disaster coverage options available in home insurance policies and make informed decisions to safeguard your property against natural calamities.",
        topics: ["Disaster Preparedness", "Coverage Options"],
        products: ["Disaster Insurance", "Homeowners Insurance"]
    },
    {
        id: 8,
        date: new Date(new Date().setDate(new Date().getDate() - 23)),
        label: "Home Renovations and Insurance",
        title: "How to Adjust Your Coverage as Your Property Evolves",
        image: Blog8,
        article: blogPost8,
        description: "Explore the implications of home renovations on your insurance coverage and discover how to adjust your policy to accommodate changes in your property's value and structure.",
        topics: ["Renovations", "Coverage Options"],
        products: ["Renovation Coverage", "Homeowners Insurance"]
    },
    {
        id: 9,
        date: new Date(new Date().setDate(new Date().getDate() - 23)),
        label: "Coverage Gaps",
        title: "Common Exclusions in Home Insurance Policies You Should Know About",
        image: Blog9,
        article: blogPost9,
        description: "Identify common exclusions in home insurance policies and take proactive steps to fill potential coverage gaps to ensure comprehensive protection for your home and assets.",
        topics: ["Coverage Gaps", "Home Insurance"],
        products: ["Homeowners Insurance"]
    },
    {
        id: 10,
        date: new Date(new Date().setDate(new Date().getDate() - 25)),
        label: "The True Cost of Underinsuring",
        title: "Why Cutting Corners on Coverage Could Leave You Vulnerable",
        image: Blog10,
        article: blogPost10,
        description: "Examine the risks associated with underinsuring your home and understand the potential financial consequences of inadequate coverage, motivating you to prioritize comprehensive insurance protection.",
        topics: ["Underinsurance", "Risk Assessment"],
        products: ["Homeowners Insurance", "Personal Property Insurance"]
    }
];


const BlogSummary = () => {
    const [sortedBlogs, setSortedBlogs] = useState(blogs); // State for sorted blogs


    // Function to sort by date
    const sortByDate = () => {
        const sorted = [...blogs].sort((a, b) => new Date(b.date) - new Date(a.date));
        setSortedBlogs(sorted);
    };

    // Function to sort by topic (alphabetically)
    const sortByTopic = () => {
        const sorted = [...blogs].sort((a, b) => a.label.localeCompare(b.label));
        setSortedBlogs(sorted);
    };


    return (
        <>
            <div className="blog-page">
                <div className="blog-page-title">New at PolicyCheck Blog</div>
                <div className="blog-page-title-sub">
                    A collection of stories about our people, our capabilities, our research.
                </div>
                <div className="blog-page-header">

                    <SocialIcons
                        twitterLink="https://twitter.com/policycheck"
                        facebookLink="https://facebook.com/policycheck"
                        linkedinLink="https://linkedin.com/company/policycheck"
                    />
                    <div className="blog-page-header-search">
                        <p>BROWSE ALL POSTS</p>
                        <div className="filter-options">
                            <div>Browse by:</div>
                            <span onClick={sortByDate} className="filter-link"> Date</span> |
                            <span onClick={sortByTopic} className="filter-link"> Topic</span>
                        </div>
                    </div>
                </div>
                <div
                    className="blog-posts-container"
                    style={{ height: `calc(${blogs.length / 3} * 1000px)` }} // Adjust height estimate based on the number of items
                >
                    {/* <div className="blog-card subscribe-card" >
                        <div className="blog-sidebar-content">
                            <h2>Never miss a story</h2>
                            <p>Stay updated about our news as it happens</p>
                            <input type="email" placeholder="Email address" />
                            <button>Subscribe</button>
                        </div>
                    </div> */}
                    {sortedBlogs.map((blog, index) => (
                        <div key={blog.id} className="blog-card" >
                            <div className="blog-card-img">
                                <img src={blog.image} alt={blog.title} />
                            </div>
                            <div className="blog-info">
                                <div className="blog-card-title-date">
                                    {blog.date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                </div>
                                <div className="blog-card-title">{blog.title}</div>
                                <div className="blog-card-title-desc">{blog.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export { BlogSummary, blogs };