import React, { Suspense, useCallback, useContext, useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { CountryContext } from './context/CountryContext';
import { ClientsProvider } from './context/ClientsContext';
import { UserContext } from "./context/UserContext";
import { PopupProvider } from "./context/PopupContext";
import { GlobalProvider } from './context/GlobalContext';
import { CompareProvider } from './context/CompareContext';
import { UploadProvider } from "./context/UploadContext";
import { PolicyTypeProvider } from "./context/PolicyTypeContext";
import { PolicyTypesProvider } from "./context/PolicyTypesContext";
import { ProviderPolicyListContext } from './context/ProviderPolicyListContext';
import { FiltersProvider } from "./context/FiltersContext";
import { RefProvider } from "./context/RefContext";
import Dashboard from "./pages/Dashboard";
import Policies from "./pages/Landing/Policies";
import Directory from "./pages/Landing/Directory";
import PolicySearchTool from "./pages/Landing/PolicySearchTool"
import Pricing from "./pages/Landing/Pricing";
import LandingPage from './pages/LandingPage';
import Loader from "./components/Loader";
import ProfilePage from "./components/about/ProfilePage";
import Footer from "./components/Footer";
import CookieConsent from "./components/footer/CookieConsent";
import ResetPassword from './components/user_access/ResetPassword';
import Header from './components/Header';
import ScrollToTop from "./components/ScrollToTop";
import PolicyLibrary from "./components/PolicyLibrary";
import { LoadScript } from '@react-google-maps/api';

import { fetchPolicies } from "./apis/PolicyService"; 


import 'react-tabs/style/react-tabs.css';
import './App.css';

const Blog = React.lazy(() => import("./pages/Landing/Blog"));
const Careers = React.lazy(() => import("./pages/Landing/Careers"));
const OurStory = React.lazy(() => import("./pages/Landing/OurStory"));
const FAQ = React.lazy(() => import("./pages/Landing/FAQ"));
const InvestorRelations = React.lazy(() => import("./pages/Landing/InvestorRelations"));
const ContactForm = React.lazy(() => import("./pages/Landing/ContactForm"));

const url = window.location.href;

ReactGA.initialize(url.includes('au') ? process.env.REACT_APP_TRACKING_ID_AU : process.env.REACT_APP_TRACKING_ID_NZ);

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Extract UTM parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const utmID = searchParams.get('utm_id');
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmTerm = searchParams.get('utm_term');
    const utmContent = searchParams.get('utm_content');

    // Send the pageview event to GA4 with custom dimensions
    ReactGA.send({
      page_location: window.location.href,
      hitType: 'pageview',
      page_path: location.pathname,
      page_title: document.title,
      hostname: window.location.hostname,
      country: location.pathname.includes('au') ? 'AU' : 'NZ',
      customDimensions: {
        utmID,
        utmSource,
        utmMedium,
        utmCampaign,
        utmTerm,
        utmContent,
      },
    });

  }, [location]);
}

function App() {
      const { country } = useContext(CountryContext);

  const [userContext, setUserContext] = useContext(UserContext);
  const { setProviderPolicies } = useContext(ProviderPolicyListContext);
 
  usePageTracking();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const policies = await fetchPolicies(country);
        if (policies) {
          setProviderPolicies(policies);
        }
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    };
  
    fetchData(); // Call the async function
  
  }, [country]); 
  

  const verifyUser = useCallback(async () => {
    if (userContext?.details) {
      try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + "users/refreshToken", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        });

        if (response.ok) {
          const data = await response.json();
          setUserContext((oldValues) => ({
            ...oldValues,
            token: data.token,
          }));
        } else {
          setUserContext((oldValues) => ({
            ...oldValues,
            token: null,
          }));
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  }, [setUserContext, userContext?.details]);

  const resetPage = () => {
    const url = new URL(window.location.href);
    window.location.href = url.origin;
  };

  const syncLogout = useCallback((event) => {
    if (event.key === "logout") {
      // Implement logic if needed
    }
  }, []);

  useEffect(() => {
    if (userContext?.details) {
      
      //verifyUser(); // Initial verification
      const interval = setInterval(verifyUser, 20 * 60 * 1000); // Re-verify every 20 minutes
      return () => clearInterval(interval);
    }
  }, [verifyUser, userContext?.details]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return () => {
      window.removeEventListener("storage", syncLogout);
    };
  }, [syncLogout]);

  return (
    <RefProvider>
      <PopupProvider>
    <ClientsProvider>
          <GlobalProvider>
            <CompareProvider>
              <FiltersProvider>
                <PolicyTypesProvider>
                  <PolicyTypeProvider>
                    {userContext?.token ? (
                      <UploadProvider>
                        <Dashboard />
                      </UploadProvider>
                    ) : (
                      <div className="app-page">
                        <header>
                          <Header />
                        </header>
                        <main>
                          <ScrollToTop />
                          <Suspense fallback={<Loader />}>
                            <Routes>
                              <Route path="/" element={<LandingPage />} />
                              <Route path="/directory" element={<Directory />} />
                              <Route path="/blog" element={<Blog />} />
                              <Route path="/library" element={<PolicyLibrary />} />
                              {/* TODO */}
                              {/* <Route path="/policy-search" element={<PolicySearchTool />} /> */}
                              <Route path="/careers" element={<Careers />} />
                              <Route path="/faq" element={<FAQ />} />
                              <Route path="/pricing" element={<Pricing />} />
                              <Route path="/contact-us" element={<ContactForm />} />
                              <Route path="/reset-password" element={<ResetPassword />} />
                              <Route path="/policies" element={<Policies />} />
                              <Route path="/our-story" element={<OurStory />} />
                              <Route path="/investor-relations" element={<InvestorRelations />} />
                              <Route path="/team/:memberId" element={<ProfilePage />} />
                              <Route path="*" element={<LandingPage />} />
                            </Routes>
                          </Suspense>
                        </main>
                        <footer>
                          <Footer />
                          <CookieConsent/>
                        </footer>
                      </div>
                    )}
                    <LoadScript
                      loadingElement={<div></div>}
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS}
                      language="en"
                      region="EN"
                      loading="async"
                    />
                  </PolicyTypeProvider>
                </PolicyTypesProvider>
              </FiltersProvider>
            </CompareProvider>
          </GlobalProvider>
    </ClientsProvider>
      </PopupProvider>
    </RefProvider>
  );
}

export default App;
