import React from 'react';
import { Bar } from 'react-chartjs-2';
import './ClientSatisfactionScoresChart.css';

const ClientSatisfactionScoresChart = ({ satisfactionData }) => {
  const data = {
    labels: satisfactionData.map(item => item.category),
    datasets: [{
      label: 'Satisfaction Score',
      data: satisfactionData.map(item => item.score),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
    }]
  };
  
  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="client-satisfaction-scores-chart">
      <Bar data={data} options={options}/>
    </div>
  );
};

export default ClientSatisfactionScoresChart;
