import React, { useState } from 'react';
import './AdviceTab.css';

const AdviceTab = ({ tabs, children }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].key);

    return (
        <div className="advice-segment">
            <div className="advice-tab-selector">
                {tabs.map(tab => (
                    <button
                        key={tab.key}
                        className={`advice-tab-button ${activeTab === tab.key ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.key)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div>
                {children.map(child => (
                    <div key={child.key} style={{ display: activeTab === child.key ? 'block' : 'none' }}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdviceTab;
