import React, { useState, useEffect } from 'react';
import './ProposalDisclaimers.css';

const initialDisclaimers = {
    'General Advice': 'This advice is general in nature and has been prepared without considering your individual objectives, financial situation, or needs. Before acting on this advice, you should consider the appropriateness of the advice, having regard to your own circumstances, and seek independent financial advice if necessary. The information provided does not constitute a comprehensive or complete statement of the matters discussed or the law relating thereto. Therefore, it is crucial that you do not solely rely on this advice when making any decisions, as your personal circumstances may differ significantly from the scenarios outlined.',
    'Product Suitability': 'The suitability of this product for you should be assessed with careful consideration of your individual needs, financial situation, and personal objectives. While this product may offer various benefits, it is essential to ensure that these align with your specific requirements and future goals. This assessment should take into account factors such as your income, expenditure, risk tolerance, and long-term financial plans. Seeking advice from a qualified financial advisor can help you determine whether this product is the best option for your circumstances and prevent potential mismatches in your financial planning.',
    'Financial Risks': 'There are inherent financial risks involved with this insurance product that need to be thoroughly understood and considered before making a purchase. These risks may include the possibility of premiums increasing over time, changes in the terms and conditions of the policy, and the potential for claims to be denied based on policy exclusions or non-disclosure of pertinent information. It is important to be aware that insurance policies do not cover every eventuality and that certain events or conditions may be excluded. As such, it is crucial to review the policy documents in detail and understand the specific risks involved to make an informed decision.',
    'Policy Exclusions': 'Certain exclusions and conditions apply to this insurance policy, which may limit or negate the benefits provided under certain circumstances. Exclusions typically include pre-existing conditions, certain high-risk activities, or events that are deemed preventable or non-accidental. It is essential to thoroughly review the policy documentation to understand what is and is not covered. Being aware of these exclusions can help you avoid unexpected surprises when making a claim and ensure that you have adequate coverage for your specific needs. If any exclusions or conditions are unclear, seeking clarification from the insurance provider or a professional advisor is recommended.',
};

const DisclaimerRow = ({ label, defaultText, onTextChange, checked }) => {
    const [text, setText] = useState(defaultText);

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setText(newText);
        onTextChange(label, newText);
    };

    return (
        <div className="disclaimer-row">
            <div className="disclaimer-row-label">
                <input
                    type="text"
                    value={label}
                    readOnly
                    className='disclaimer-row-label-label'
                />
            </div>
            <textarea
                className='proposal-disclaimer-item'
                value={text}
                onChange={handleTextChange}
                disabled={!checked}
                style={{ flex: '1', backgroundColor: checked ? 'white' : 'lightgrey' }}
                placeholder="Please input your disclaimer here."
            />
        </div>
    );
};

const ProposalDisclaimers = ({ setDisclaimerArray }) => {
    const [disclaimers, setDisclaimers] = useState(initialDisclaimers);
    const [checkboxState, setCheckboxState] = useState(
        Object.keys(initialDisclaimers).reduce((acc, label) => ({ ...acc, [label]: true }), {})
    );

    useEffect(() => {
        setDisclaimerArray(disclaimers);
    }, [disclaimers, setDisclaimerArray]);

    const handleTextChange = (label, newText) => {
        setDisclaimers((prevDisclaimers) => ({
            ...prevDisclaimers,
            [label]: newText
        }));
    };

    const addDisclaimer = () => {
        const newLabel = `Additional disclaimer...`;
        setDisclaimers({ ...disclaimers, [newLabel]: '' });
        setCheckboxState({ ...checkboxState, [newLabel]: true });
    };

    return (
        <div>
            <div className='disclaimer-rows'>
                {Object.keys(disclaimers).map((label) => (
                    <DisclaimerRow
                        key={label}
                        label={label}
                        defaultText={disclaimers[label]}
                        onTextChange={handleTextChange}
                        checked={checkboxState[label]}
                    />
                ))}
            </div>
            <div className="add-disclaimer-wrapper">
                <button className="add-disclaimer-button" onClick={addDisclaimer}>Add</button>
            </div>
        </div>
    );
};

export default ProposalDisclaimers;
