import React from 'react';
import EQCData from '../EQCData'
import './PropertyRisks.css';

const PropertyRisks = ({ address }) => {

  return (
    <div className="property-risk-container">
      <EQCData address={address}/>
    </div>
  );
};

export default PropertyRisks;
