import React, { useState, useEffect } from 'react';
import './MySecurity.css';

const MySecurity = () => {
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [mfaMethod, setMfaMethod] = useState('SMS');

    useEffect(() => {
        document.title = 'My Security';
    }, []);

    const toggleMfa = () => {
        setIsMfaEnabled(!isMfaEnabled);
    };

    const handleMfaMethodChange = (event) => {
        setMfaMethod(event.target.value);
    };

    return (
        <div className="security-container">
            <h2>Security Settings</h2>
            <p>Manage your account security and enable Multi-Factor Authentication (MFA).</p>

            <div className="security-section">
                <h3>Password</h3>
                <p>Change your password to keep your account secure.</p>
                <button className="security-button">Change Password</button>
            </div>

            <div className="security-section">
                <h3>Multi-Factor Authentication (MFA)</h3>
                <p>MFA adds an extra layer of security to your account by requiring more than just your password.</p>
                <div className="security-options">
                    <label className="security-option">
                        <input
                            type="checkbox"
                            checked={isMfaEnabled}
                            onChange={toggleMfa}
                        />
                        Enable Multi-Factor Authentication
                    </label>
                </div>

                {isMfaEnabled && (
                    <div className="mfa-method-section">
                        <h4>Select MFA Method:</h4>
                        <div className="mfa-method-options">
                            <label>
                                <input
                                    type="radio"
                                    name="mfaMethod"
                                    value="SMS"
                                    checked={mfaMethod === 'SMS'}
                                    onChange={handleMfaMethodChange}
                                />
                                SMS
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="mfaMethod"
                                    value="Authenticator App"
                                    checked={mfaMethod === 'Authenticator App'}
                                    onChange={handleMfaMethodChange}
                                />
                                Authenticator App
                            </label>
                        </div>
                    </div>
                )}
            </div>

            <div className="security-section">
                <h3>Session Management</h3>
                <p>Review and manage devices that are currently logged into your account.</p>
                <button className="security-button">View Active Sessions</button>
            </div>
        </div>
    );
};

export default MySecurity;
