import React, { useState } from 'react';
import './RecommendationTable.css';
import Menu from './Menu';
import DynamicTable from './DynamicTable';

const strengths = [
  { type: 'home', key: 'generalLegalLiability', limit: 10, excess: 2 },
  { type: 'home', key: 'keysAndLocks', limit: 5, excess: 1 },
  { type: 'home', key: 'retainingWalls', limit: 10, excess: 2 },
  { type: 'home', key: 'outbuildings', limit: 5, excess: 1 },
  { type: 'home', key: 'homeOffice', limit: 10, excess: 2 },
  { type: 'home', key: 'hiddenGradualDamage', limit: 5, excess: 1 },
  { type: 'home', key: 'hiddenWaterDamage', limit: 10, excess: 2 },
  { type: 'home', key: 'carpetsExtendedReplacement', limit: 5, excess: 1 },
  // add more strengths
];

const weaknesses = [
  { type: 'home', key: 'homeOfficeEquipment', limit: 8, excess: 3 },
  { type: 'home', key: 'keysAndLocksLostOrStolen', limit: 4, excess: 0 },
  { type: 'home', key: 'mobilePhoneLimit', limit: 8, excess: 3 },
  { type: 'home', key: 'totalJewelleryLimit', limit: 4, excess: 0 },
  { type: 'home', key: 'carpetsExtendedReplacement', limit: 8, excess: 3 },
  { type: 'home', key: 'looseFloorCoverings', limit: 4, excess: 0 },
  { type: 'home', key: 'prescriptionGlassesContactLensesLimit', limit: 8, excess: 3 },
  { type: 'home', key: 'singleItemLimit', limit: 4, excess: 0 },
  { type: 'home', key: 'glassBreakage', limit: 8, excess: 3 },
  { type: 'home', key: 'sanitaryFixture', limit: 4, excess: 0 },
  // add more weaknesses
];

const RecommendationTable = () => {
  const [inactiveItems, setInactiveItems] = useState([]);

  const handleItemDrag = (itemName) => {
    setInactiveItems([...inactiveItems, itemName]);
  };
  
  const handleItemRemove = (itemName) => {
    setInactiveItems(inactiveItems.filter(item => item !== itemName));
  };

  const resetInactiveItems = () => {
    setInactiveItems([]);
  };

  return (
    <div className='recommendation-table-container'>
      <Menu strengths={strengths} weaknesses={weaknesses} onItemDrag={handleItemDrag} inactiveItems={inactiveItems} />
      <DynamicTable
        strengths={strengths}
        weaknesses={weaknesses}
        onItemDrag={handleItemDrag}
        onItemRemove={handleItemRemove}
        onClearAllRows={resetInactiveItems}
        initialRowCount={10} // Pass the row count as a prop
      />
    </div>
  );
};

export default RecommendationTable;
