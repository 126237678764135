import React from 'react';
import './InfoButton.css'; // Importing the CSS file

const InfoButton = ({ onClick }) => {
    return (
        <button className="info-button" onClick={onClick}>
            i
        </button>
    );
};

export default InfoButton;
