import React, { createContext, useState } from 'react';

export const PolicyTypeContext = createContext();

export const PolicyTypeProvider = ({ children }) => {
    const [policyType, setPolicyType] = useState('home');
  
    return (
      <PolicyTypeContext.Provider value={{ policyType, setPolicyType }}>
        {children}
      </PolicyTypeContext.Provider>
    );
  };