import React, { useState, useEffect } from 'react';
import './AddressInformation.css';
import MyMapComponent from '../MyMapComponent'

const AddressInformation = ({ address }) => {


  function splitAtFirstComma(str) {
    if(str === undefined || str === null) {
        return null;
    }
    const commaIndex = str.indexOf(',');
    if (commaIndex === -1) {
        return { beforeComma: str, afterComma: '' };  // or handle as needed if no comma is found
    }

    const beforeComma = str.substring(0, commaIndex);
    const afterComma = str.substring(commaIndex + 1);

    return { beforeComma, afterComma };
}

const addressSplit = splitAtFirstComma(address);
const { beforeComma: streetAddress, afterComma: suburb } = addressSplit !== null ? addressSplit : { beforeComma: '', afterComma: '' };


  return (
    <div className="address-container">
      <div className="address-container-header">
        Address Information
      </div>
      <table className="address-container-items">
      <tbody>
        <tr>
          <td className="address-container-item-label">Street Address:</td>
          <td className="address-container-item-value">{streetAddress}</td>
        </tr>
        <tr>
          <td className="address-container-item-label">Suburb / Region:</td>
          <td className="address-container-item-value">{suburb}</td>
        </tr>
      </tbody>
    </table>
    
    <MyMapComponent address={address}/>
    </div>
  );
};

export default AddressInformation;
