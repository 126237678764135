import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  useReactFlow,
} from 'react-flow-renderer';
import CustomNode from './CustomNode';
import { getWorkflows, getSteps, createStep, updateStep, deleteStep } from '../../apis/Workflow';
import './FlowChart.css';
import WaitingDiv from '../WaitingDiv';
import ProcessFlow from './ProcessFlow'; // Import the ProcessFlow component

// Define nodeTypes outside of the component
const nodeTypes = {
  custom: CustomNode,
};

const getId = () => `dndnode_${+new Date()}`;

const FlowChart = () => {
  const reactFlowWrapper = useRef(null);
  const { fitView, project } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [selectedElements, setSelectedElements] = useState([]);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, elements: [] });
  const [showProcessFlow, setShowProcessFlow] = useState(false); // State to show/hide ProcessFlow

  const fetchWorkflows = async () => {
    try {
      const res = await getWorkflows();
      if (res.data.length > 0) {
        setWorkflows(res.data);
        setSelectedWorkflow(res.data[0]._id);
      }
    } catch (err) {
      console.error('Error fetching workflows:', err);
    }
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  useEffect(() => {
    const fetchSteps = async () => {
      if (!selectedWorkflow) {
        setNodes([]);
        setEdges([]);
        return;
      }
      try {
        const res = await getSteps(selectedWorkflow);
        if (!res.data || !res.data.steps || res.data.steps.length === 0) {
          setNodes([]);
          setEdges([]);
        } else {
          const validatedSteps = res.data.steps.map((step, index) => ({
            id: step.id,
            type: step.type === 'group' ? 'default' : step.type,
            data: step.data,
            position: step.position,
            className: step.className,
            style: step.style,
            parentId: step.parentId,
            draggable: step.parentId ? true : step.draggable !== undefined ? step.draggable : true,
            selectable: step.parentId ? true : step.selectable !== undefined ? step.selectable : true,
            number: step.number !== undefined ? step.number : index + 1, // Ensure step number is set
          }));

          const validatedEdges = res.data.edges.map((edge) => ({
            id: edge.id,
            source: edge.source,
            target: edge.target,
            animated: edge.animated,
            sourceHandle: edge.sourceHandle,
            targetHandle: edge.targetHandle,
          }));

          setNodes(validatedSteps);
          setEdges(validatedEdges);
        }
      } catch (err) {
        console.error('Error fetching steps:', err);
        setNodes([]);
        setEdges([]);
      }
    };
    fetchSteps();
  }, [selectedWorkflow, setNodes, setEdges]);

  useEffect(() => {
    if (nodes.length > 0) {
      fitView();
    }
  }, [nodes, fitView]);

  const onConnect = useCallback(
    (params) => {
      setEdges((els) => addEdge(params, els));
    },
    [setEdges]
  );

  const handleRemoveElements = useCallback(
    async (elementsToRemove) => {
      const nodeChanges = elementsToRemove
        .filter((el) => !el.id.startsWith('e'))
        .map((el) => ({ id: el.id, type: 'remove' }));
      const edgeChanges = elementsToRemove
        .filter((el) => el.id.startsWith('e'))
        .map((el) => ({ id: el.id, type: 'remove' }));

      setNodes((nds) => applyNodeChanges(nodeChanges, nds));
      setEdges((eds) => applyEdgeChanges(edgeChanges, eds));

      for (const el of elementsToRemove) {
        if (!el.id.startsWith('e')) {
          // Node removal
          try {
            await deleteStep(selectedWorkflow, el.id);
          } catch (err) {
            console.error(`Error deleting step with id ${el.id}:`, err);
          }
        }
      }
    },
    [setNodes, setEdges, selectedWorkflow]
  );

  const saveSteps = async () => {
    try {
      for (let node of nodes) {
        if (!node.id.startsWith('e')) {
          const existingElement = await getSteps(selectedWorkflow);
          const existingElementData = existingElement.data.find((el) => el.id === node.id);
          if (existingElementData) {
            await updateStep(selectedWorkflow, node.id, node);
          } else {
            await createStep(selectedWorkflow, node);
          }
        }
      }
      alert('Steps saved successfully');
    } catch (err) {
      console.error('Error saving steps:', err);
      alert('Error saving steps');
    }
  };

  const onDrop = (e) => {
    e.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = e.dataTransfer.getData('application/reactflow');

    if (!type) return;

    const position = project({
      x: e.clientX - reactFlowBounds.left,
      y: e.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: `${type} node` },
      draggable: true,
      selectable: true,
    };

    setNodes((nds) => nds.concat(newNode));
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Delete' && selectedElements.length > 0) {
        handleRemoveElements(selectedElements);
      }
    },
    [selectedElements, handleRemoveElements]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onContextMenu = (e) => {
    e.preventDefault();

    const targetNode = nodes.find(
      (node) =>
        e.clientX >= node.position.x &&
        e.clientX <= node.position.x + node.width &&
        e.clientY >= node.position.y &&
        e.clientY <= node.position.y + node.height
    );

    if (targetNode) {
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        elements: [targetNode],
      });
    } else {
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        elements: [...(selectedElements?.nodes || []), ...(selectedElements?.edges || [])],
      });
    }
  };

  const onSelectionChange = (elements) => {
    setSelectedElements({
      nodes: elements?.nodes || [],
      edges: elements?.edges || [],
    });
  };

  const handleContextMenuClick = (action) => {
    if (action === 'delete') {
      handleRemoveElements(contextMenu.elements);
    }
    setContextMenu({ visible: false, x: 0, y: 0, elements: [] });
  };

  return (
    <>
      {workflows.length > 0 ? (
        <div className="react-flow-wrapper" ref={reactFlowWrapper} style={{ height: '100vh' }} onContextMenu={onContextMenu}>
          <ReactFlowProvider>
            <div className="react-flow-header">
              <select value={selectedWorkflow} onChange={(e) => setSelectedWorkflow(e.target.value)}>
                <option value="" disabled>
                  Select Workflow
                </option>
                {workflows.map((workflow) => (
                  <option key={workflow._id} value={workflow._id}>
                    {workflow.name} (v{workflow.version})
                  </option>
                ))}
              </select>
              <button onClick={() => setShowProcessFlow(true)}>Show Process Flow</button>
              <button onClick={saveSteps}>Save Steps</button>
            </div>
            {nodes.length > 0 && (
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onSelectionChange={onSelectionChange}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            )}
          </ReactFlowProvider>
          {contextMenu.visible && (
            <div
              className="context-menu"
              style={{
                top: contextMenu.y,
                left: contextMenu.x,
                position: 'absolute',
                backgroundColor: 'white',
                border: '1px solid black',
                zIndex: 1000,
                padding: '5px',
                borderRadius: '3px',
              }}
            >
              <div onClick={() => handleContextMenuClick('delete')}>Delete</div>
            </div>
          )}
          {showProcessFlow && <ProcessFlow onClose={() => setShowProcessFlow(false)} workflow={selectedWorkflow} />} {/* Pass selectedWorkflow to ProcessFlow */}
        </div>
      ) : (
        <>
        <div style={{width:'100%', textAlign:'center'}}> Shouldn't be long. Please wait for templates to load </div>
        <WaitingDiv />
        </>
      )
      }
    </>
  );
};

export default FlowChart;
