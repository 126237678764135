import React, { useCallback, useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { CountryContext } from '../context/CountryContext';
import { useGlobalContext } from '../context/GlobalContext';
import { PolicyTypeContext } from '../context/PolicyTypeContext';
import { useCompareContext } from '../context/CompareContext';
import { fetchMyPolicies } from "../apis/PolicyService"; 
import { FiltersContext } from '../context/FiltersContext';
import FileUpload from "./FileUpload";
import './ComparePolicy.css';

function ComparePolicy() {
    const { country } = useContext(CountryContext);
    const { activeDocument, setActiveDocumentName } = useGlobalContext();
    const { compareDocument, setCompareDocument } = useCompareContext();
    const [policies, setPolicies] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [userContext] = useContext(UserContext);
    const { globalFilters, setGlobalFilters } = useContext(FiltersContext);
    const { policyType } = useContext(PolicyTypeContext);

    useEffect(() => {
        if (compareDocument) {
            const updatedFilters = globalFilters.map(filter =>
                filter.name === compareDocument?.policyProvider ? { ...filter, checked: true } : filter
            );
            setGlobalFilters(updatedFilters);
        }
    }, [compareDocument]);

    const toggleToggle = () => {
        setToggle(!toggle);
    };

    const handleSelectChange = (event) => {
        
        if (compareDocument.filename !== event.target.value) {
            
            setCompareDocument(policies.find(policy => {
                
                return policy.filename === event.target.value
            }));
        }
    };

    const fetchMyPolicyData =  async  () => {
        const data = await fetchMyPolicies(userContext.details.username,country);
        if (data.length > 0) {
            setPolicies(data);
        }
    };

    useEffect(() => {
        if (userContext.details) {
            const loadMyPolicyData = async () => {
                await fetchMyPolicyData();
            };
    
            loadMyPolicyData(); // Call the async function inside useEffect
        }
    }, [userContext.details]);

    const findSuitableDocument = () => {
        for (let policy of policies) {
            if (policy.filename !== activeDocument.filename && policy.policyType === policyType) {
                return policy;
            }
        }
        return null; // If no suitable document is found
    };

    useEffect(() => {
        
        if (policies[0] !== undefined && (compareDocument === undefined || compareDocument === "" || compareDocument === null)) {
            
            const suitableDocument = findSuitableDocument();
            if (suitableDocument) {
                setCompareDocument(suitableDocument);
            } else {
                // Optional: Handle the case where no suitable document is found
                
            }
        }
    }, [policies]);

    return (
        <div className="compare-policy-div">
            {policies.length < 2  || findSuitableDocument() === null ? (
                <div className="compare-policy-dropdown-none" onClick={() => toggleToggle()}>add second {policyType === 'HomeAndContents' ? 'home and contents' : policyType} policy</div>
            ) : (
                <select
                    className='compare-policy-dropdown'
                    onChange={handleSelectChange}
                    value={compareDocument?.filename || ''}>
                    {policies.filter(policy => policy.filename !== activeDocument?.filename)
                        .map((policy, index) => (
                            <option
                                key={policy._id || index}
                                value={policy.filename}
                                disabled={policy.policyType !== policyType} // This disables the option if the policy type is 'Home'
                            >
                                {policy.policyProvider}-{policy.policyType}-{policy.policyNumber.trim()}
                            </option>
                        ))
                    }
                </select>
            )}
            <FileUpload toggleWelcome={toggle} onToggle={toggleToggle} />
        </div>
    );

}

export default ComparePolicy;


