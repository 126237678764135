import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { marked } from 'marked';

// Helper function to format the date
const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};

// Function to render a table with a title
const renderTable = (title, rows) => (
    <View style={styles.tableContainer}>
        <Text style={styles.tableTitle}>{title}</Text>
        <View style={styles.table}>
            {rows.map(([label, value], index) => (
                <View style={styles.tableRow} key={index}>
                    <Text style={styles.tableCell}>{label}</Text>
                    <Text style={styles.tableCell}>{value}</Text>
                </View>
            ))}
        </View>
    </View>
);

const DocumentPreview = ({
    date, email, firmName, brokerName, brokerContact, brokerAddress, brokerLicence,
    introductionNote, thankYouNote, logo, splashBanner, disclaimers
}) => {
    const disclaimerText = Object.keys(disclaimers).map((key, index) => (
        `*${index + 1}. ${disclaimers[key]}\n`
    )).join('\n');

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.logo} />
                    <Text style={styles.headerText}>{firmName}</Text>
                </View>
                <View style={styles.body}>
                    <Text style={styles.date}>{formatDate(date)}</Text>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Summary</Text>
                        <View style={styles.introduction}>
                            <Text style={styles.introductionText} dangerouslySetInnerHTML={{ __html: marked(introductionNote) }} />
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.twoColumn}>
                            <View style={styles.column}>
                                {renderTable('Policy Summary', [
                                    ['Policyholders', ''],
                                    ['Date', ''],
                                    ['Start of Insurance', ''],
                                    ['Expiry Date', '']
                                ])}
                                {renderTable('Cover Summary', [
                                    ['Cover', ''],
                                    ['Optional Cover', 'Included/Not Included'],
                                    ['Legal Liability', '']
                                ])}
                            </View>
                            <View style={styles.column}>
                                {renderTable('Contact Details', [
                                    ['Contact Number(s)', brokerContact],
                                    ['Postal Address', brokerAddress],
                                    ['Email Address', email]
                                ])}
                                {renderTable('Excess(es) on Claims', [
                                    ['Basic Excess', ''],
                                    ['Standard Excess', ''],
                                    ['Premium Excess', '']
                                ])}
                                {renderTable('Optional Cover', [
                                    ['Cover Option 1', 'Included/Not Included'],
                                    ['Cover Option 2', 'Included/Not Included']
                                ])}
                            </View>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Your Premium</Text>
                        <Text style={styles.paragraph}>
                            *In accordance with the GST law relating to insurance premiums, the GST amount may be less than 10% of the total amount payable.
                            ^The ‘Total premium’ figure represents the annual premium for your home and contents based on the current details. If changes are made to the policy during the period of insurance the ‘Total premium’ shown here may differ from the total amount you pay. ‘Your pay plan’ details what you have already paid to us and any amount yet to be paid, but will not show any amounts that we have not charged or not refunded.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Your Declarations</Text>
                        {renderTable('Declarations', [
                            ['Declaration 1', 'Yes/No'],
                            ['Declaration 2', 'Yes/No'],
                            ['Declaration 3', 'Yes/No']
                        ])}
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Your Pay Plan</Text>
                        <Text style={styles.paragraph}>
                            Your payment schedule
                            This table shows the status of payments and the payment arrangements to the Direct Debit Request (DDR), credit card payment authority or PayPal agreement that you authorised to AGS (User ID: 142038) either over the telephone or the internet. If your payments are made by Direct Debit please also refer to the DDR Service Agreement.
                            For privacy and security reasons we encrypt part of your credit card or account number.
                            Date (on or after) Amount (inc. GST) GST Account Status
                            01/05/2024
                            Premium changes of $1 or less
                            $1,836.90 $153.20 VISA 4***********5569 Paid
                            When a change is made to your policy that results in an additional premium or refund of $1 or less: l If you pay annually, it will not be charged or refunded to your nominated account.
                            l If you pay by instalment, we will adjust any remaining instalment(s) to account for the amount owing or to be refunded. If the final instalment has already been paid, it will not be charged or refunded to your nominated account.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Optional Cover</Text>
                        {renderTable('Optional Cover', [
                            ['Cover Option 1', 'Included/Not Included'],
                            ['Cover Option 2', 'Included/Not Included']
                        ])}
                    </View>
                    <Text style={styles.thankYou}>{thankYouNote}</Text>
                </View>
                <View style={styles.footer}>
                    <Text style={styles.footerText}>
                        Product issuer: Auto & General Insurance Company Limited
                        ABN 42 111 586 353 AFS Licence No 285571 and Managing agent
                        Auto & General Services Pty Ltd AFS Licence No 241411 SMS 'CHAT' to +61 437 968 251 Customer Services Phone 1800 182 310 Claims Phone 1800 069 336
                    </Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        padding: 20,
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #000',
        marginBottom: 10,
    },
    logo: {
        height: 50,
        width: 'auto',
    },
    headerText: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    body: {
        flex: 1,
    },
    date: {
        textAlign: 'right',
        marginBottom: 10,
    },
    section: {
        marginBottom: 20,
    },
    sectionTitle: {
        fontSize: 16,
        marginBottom: 10,
    },
    introduction: {
        marginBottom: 20,
    },
    introductionText: {
        fontSize: 12,
    },
    twoColumn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column: {
        flex: 1,
    },
    tableContainer: {
        marginBottom: 20,
    },
    tableTitle: {
        fontSize: 14,
        marginBottom: 5,
    },
    table: {
        width: '100%',
        border: '1px solid #ddd',
        borderCollapse: 'collapse',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #ddd',
    },
    tableCell: {
        flex: 1,
        padding: 8,
        borderRight: '1px solid #ddd',
        textAlign: 'left',
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 10,
    },
    thankYou: {
        marginTop: 20,
        fontSize: 14,
    },
    footer: {
        borderTop: '1px solid #000',
        paddingTop: 10,
        textAlign: 'center',
        fontSize: 10,
    },
    footerText: {
        marginBottom: 5,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
});

export default DocumentPreview;
