import React, { useState, useRef, useEffect } from 'react';
import EmailPreview from './EmailPreview';
import { useClient } from '../../context/ClientContext';
import ProposalDisclaimers from './components/ProposalDisclaimers';
import './EmailTemplate.css';

// Import default images if they are part of your source files
import defaultLogo from '../../images/email/logo.avif';
import defaultBanner from '../../images/email/banner.png';

const EmailTemplate = () => {
    const { activeClient } = useClient();
    const [email, setEmail] = useState(activeClient?.contactInformation?.email);
    const [firmName, setFirmName] = useState('Archers Insurance');
    const [brokerName, setBrokerName] = useState('Simon Archer');
    const [brokerContact, setBrokerContact] = useState('+64 91 917 573');
    const [brokerAddress, setBrokerAddress] = useState('67 Browns Street, Auckland, 4000');
    const [brokerLicence, setBrokerLicence] = useState('557339 0101010');
    const [introductionNote, setIntroductionNote] = useState(`I am delighted to present you with a quote for your consideration. This document contains crucial policy details that should be reviewed meticulously and kept securely for future reference.

Your most recent Insurance Certificate, Product Disclosure Statement, and any Supplementary Product Disclosure Statement (if applicable) collectively form your Insurance Contract.

### What you need to do now

1. **Check the details**  
   Carefully examine all sections of your policy documents. Pay particular attention to the information in the Insurance Certificate and Your Declarations.

2. **Notify us of any changes, inaccuracies, or omissions**  
   It is vital, as part of your policy obligations (as outlined in the Product Disclosure Statement), to inform us as soon as possible if any information in these documents, including your previous responses to our questions, is incomplete or inaccurate.
    `);
    const [thankYouNote, setThankYouNote] = useState('');
    const [emailInputsWidth, setEmailInputsWidth] = useState(300);
    const [logo, setLogo] = useState(defaultLogo);
    const [splashBanner, setSplashBanner] = useState(defaultBanner);
    const [disclaimers, setDisclaimers] = useState({});

    const emailInputsRef = useRef(null);
    const isResizing = useRef(false);

    const handleMouseDown = (e) => {
        e.preventDefault();
        isResizing.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (isResizing.current) {
            const newWidth = e.clientX - emailInputsRef.current.getBoundingClientRect().left;
            if (newWidth > 300) {
                setEmailInputsWidth(newWidth);
            }
        }
    };

    const handleMouseUp = () => {
        if (isResizing.current) {
            isResizing.current = false;
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setLogo(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSplashBannerUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setSplashBanner(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="email-template-container">
            <div
                className="email-inputs"
                ref={emailInputsRef}
                style={{ width: emailInputsWidth }}
            >
                <div className="email-inputs-header">
                    <div>Template Editor</div>
                    <button className="save-button">Save</button>
                </div>
                <label>
                    Firm:
                    <input
                        className="email-template-input"
                        type="text"
                        value={firmName}
                        onChange={(e) => setFirmName(e.target.value)}
                        placeholder="e.g., Master Insurers"
                    />
                </label>
                <label>
                    Broker Name:
                    <input
                        className="email-template-input"
                        type="text"
                        value={brokerName}
                        onChange={(e) => setBrokerName(e.target.value)}
                        placeholder="e.g., Simon Bennett"
                    />
                </label>
                <label>
                    Broker Contact:
                    <input
                        className="email-template-input"
                        type="text"
                        value={brokerContact}
                        onChange={(e) => setBrokerContact(e.target.value)}
                        placeholder="Phone or email address etc."
                    />
                </label>
                <label>
                    Broker Address:
                    <input
                        className="email-template-input"
                        type="text"
                        value={brokerAddress}
                        onChange={(e) => setBrokerAddress(e.target.value)}
                        placeholder="Physical address"
                    />
                </label>
                <label>
                    Broker Licence:
                    <input
                        className="email-template-input"
                        type="text"
                        value={brokerLicence}
                        onChange={(e) => setBrokerLicence(e.target.value)}
                        placeholder="Regulated licence number..."
                    />
                </label>
                <label>
                    Upload Logo:
                    <input
                        className="email-template-input"
                        type="file" onChange={handleLogoUpload} />
                </label>
                <label>
                    Upload Splash Banner:
                    <input type="file"
                        className="email-template-input"
                        onChange={handleSplashBannerUpload} />
                </label>
                <label>
                    Introduction:
                    <textarea
                        className="email-template-textarea"
                        value={introductionNote}
                        onChange={(e) => setIntroductionNote(e.target.value)}
                        placeholder=""
                    />
                </label>
                <label>
                    Thank You:
                    <textarea
                        className="email-template-textarea"
                        value={thankYouNote}
                        onChange={(e) => setThankYouNote(e.target.value)}
                        placeholder="Thank you for your time. It has been a pleasure working with you..."
                    />
                </label>
                <ProposalDisclaimers setDisclaimerArray={setDisclaimers} />
                <div className="resizer" onMouseDown={handleMouseDown}></div>
            </div>
            <div style={{ minWidth: '600px', flex: '1' }}>
                <EmailPreview
                    date={new Date()}
                    email={email}
                    firmName={firmName}
                    brokerName={brokerName}
                    brokerContact={brokerContact}
                    brokerAddress={brokerAddress}
                    brokerLicence={brokerLicence}
                    introductionNote={introductionNote}
                    thankYouNote={thankYouNote}
                    logo={logo}
                    splashBanner={splashBanner}
                    disclaimers={disclaimers}
                />
            </div>
        </div >
    );
};

export default EmailTemplate;
