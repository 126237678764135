import React, { useState } from 'react';
import getStatusIcon from './components/Icons';
import './ClaimsList.css'; // Importing the CSS for styling

const ClaimsList = ({ claims }) => {
    const [activeIndex, setActiveIndex] = useState(null); // To track the expanded item

    const toggleDetails = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="claim-container">
            {claims.map((claim, index) => (
                <div key={index} className="claim-item">
                    <div className={`claim-summary ${activeIndex === index ? 'claim-summary-active ' : ''}`} onClick={() => toggleDetails(index)}>
                        <p style={{ flex: '0.2' }}>
                            <div className={`${activeIndex === index ? 'claim-arrow' : 'claim-arrow-green'}`}
                                style={{ transform: activeIndex === index ? 'rotate(-180deg)' : 'rotate(0deg)' }}></div>
                        </p>
                        <p style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '14pt', margin: '0px' }}>{claim.clientName}</span>
                            <span style={{ fontSize: '8pt', fontWeight: 'normal' }}>{claim.address}</span>
                        </p>
                        <p style={{ flex: '0.4' }}>{claim.claimAmount}</p>
                        <p style={{ flex: '0.4' }}>{claim.claimDate}</p>
                        <p style={{ flex: '0.4' }}>
                            {activeIndex !== index && (
                                getStatusIcon(claim.status)
                            )}
                        </p>
                        <p style={{ flex: '0.4', minWidth: '140px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <div className="details-button">
                                Claim Details
                            </div>
                        </p>
                    </div>
                    {activeIndex === index && (
                        <div className={`claim-details ${activeIndex === index ? 'claim-details-active' : ''}`}>
                            <p>Policy Number: {claim.policyNumber}</p>
                            <p>Type of Coverage: {claim.coverageType}</p>
                            <p>Incident Description: {claim.incidentDescription}</p>
                            <p>Cost Estimates: {claim.costEstimates}</p>
                            <p>Third-Party Details: {claim.thirdPartyDetails}</p>
                            <p>Additional Notes: {claim.additionalNotes}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ClaimsList;
