import React, { useEffect} from "react";
import './MyPrivacy.css'; // Custom CSS for privacy page

const MyPrivacy = () => {
    useEffect(() => {
        document.title = 'My Privacy';
      }, []);
    return (
        <div className="privacy-container">
            <h2>Privacy Settings</h2>
            <p>Manage your privacy preferences and account data.</p>

                <div className="privacy-section">
                    <h3>Data Sharing</h3>
                    <p className="privacy-info">Control how your data is shared with third-party providers and partners.</p>
                    <div className="privacy-options">
                        <label className="privacy-option">
                            <input type="checkbox" defaultChecked={true}/>
                            Allow data sharing with trusted partners
                        </label>
                        <label className="privacy-option">
                            <input type="checkbox" defaultChecked={true}/>
                            Allow personalized advertising
                        </label>
                        <label className="privacy-option">
                            <input type="checkbox" />
                            Share usage statistics for product improvement
                        </label>
                    </div>
                </div>

                <div className="privacy-section-50">
                    <h3>Account Data</h3>
                    <p className="privacy-info">Select which data you'd like to delete.</p>

                    <div className="privacy-options">
                        <div className="privacy-row">
                            <p>Delete All Data:</p>
                            <button className="privacy-button danger">Delete All</button>
                        </div>
                        <div className="privacy-row">
                            <p>Delete Customers & Customer Data Only:</p>
                            <button className="privacy-button danger">Delete Customers & Data</button>
                        </div>
                        <div className="privacy-row">
                            <p>Delete Policy Documents Only:</p>
                            <button className="privacy-button danger">Delete Policy Documents</button>
                        </div>
                        <div className="privacy-row">
                            <p>Delete User Activity:</p>
                            <button className="privacy-button danger">Delete User Activity</button>
                        </div>
                        <div className="privacy-row">
                            <p>Delete Audit Log:</p>
                            <button className="privacy-button danger">Delete Audit Log</button>
                        </div>
                        <div className="privacy-row">
                            <p>Delete Account:</p>
                            <button className="privacy-button danger">Delete Account</button>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default MyPrivacy;
