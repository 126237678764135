import React, { useEffect, useState } from "react";
import "./WaitingDiv.css"; // You can create a CSS file for styling

const WaitingDiv = () => {
  const [currentCircle, setCurrentCircle] = useState(1);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    if (waiting) {
      const interval = setInterval(() => {
        setCurrentCircle((prevCircle) => (prevCircle % 4) + 1);
      }, 400); // Adjust the interval duration as needed (e.g., 1000ms = 1 second)

      return () => {
        clearInterval(interval);
      };
    }
  }, [waiting]);

  const renderCircles = () => {
    const circles = [];
    for (let i = 1; i <= 4; i++) {
      circles.push(
        <div
          key={i}
          className={`waiting-circle ${currentCircle === i ? "filled" : ""}`}
        ></div>
      );
    }
    return circles;
  };

  return (
    <div className="waiting-div">
      {renderCircles()}
      {/* <button onClick={() => setWaiting(!waiting)}>
        {waiting ? "Stop" : "Start"} Waiting
      </button> */}
    </div>
  );
};

export default WaitingDiv;
