import React, { useEffect, useState, useRef, useContext } from 'react';
import TopActionsToday from "../../components/dashboard/TopActionsToday";
import RecentNews from "../../components/dashboard/RecentNews";
import ExpiringClients from '../../components/dashboard/ExpiringClients';
import PolicyStatus from '../../components/dashboard/PolicyStatus';
import RetentionRateChart from '../../components/dashboard/RetentionRateChart';
import RecentlyAcceptedClients from '../../components/dashboard/RecentlyAcceptedClients';
import { useCollapse } from '../../context/CollapseContext';
import PolicyStatusGraph from '../../components/dashboard/PolicyStatusGraph';
import './Home.css';

const statuses = ['Not Started', 'Needs Understanding', 'Research', 'Communication', 'Closed'];
const providers = ['AA', 'AMI', 'AIA', 'State', 'MyPolicy', 'Westpac', 'ASB', 'BNZ', 'CBA', 'Allianz', 'AIG', 'CGI', 'Initio'];
const policyTypes = ['home', 'contents', 'commercial', 'smb', 'liability', 'landlord', 'professional'];

const fakeNames = [
  "James Smith", "Michael Johnson", "Robert Brown", "Maria Garcia", "David Martinez",
  "Sarah Taylor", "Chris Anderson", "Patricia Moore", "Matthew Thomas", "Jennifer Jackson"
];

const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

const generatePolicies = (n) => {
  const policies = [];
  for (let i = 0; i < n; i++) {
    const name = getRandomElement(fakeNames);
    const provider = getRandomElement(providers);
    const type = getRandomElement(policyTypes);
    const status = getRandomElement(statuses);
    const addedDate = new Date(Date.now() - Math.floor(Math.random() * 10000000000));
    policies.push({ name, provider, type, addedDate, status });
  }
  return policies;
};

const Home = () => {
  useEffect(() => {
    document.title = 'Home';
  }, []);
  const { isCollapsed } = useCollapse();

  const newClients = [
    { name: 'Sam Bently', startDate: new Date(2024, 3, 10) },
    { name: 'James Kennedy', startDate: new Date(2024, 3, 15) },
    { name: 'Patrica Brown', startDate: new Date(2024, 3, 17) },
    { name: 'Michael Davis', startDate: new Date(2024, 3, 20) },
    { name: 'Richard Taylor', startDate: new Date(2024, 3, 26) },
  ];



  const newsItems = [
    {
      title: "Market Update",
      summary: "The latest trends in the market today...",
      link: "https://www.insurancebusinessmag.com/nz/news/breaking-news/two-companies-continue-to-dominate-general-insurance-market-503739.aspx",
      type: "link"
    },
    {
      title: "Policy Changes",
      summary: "New policies are being introduced...",
      link: "",
      type: "policy"
    },
    {
      title: "New Insurance Regulations",
      summary: "An in-depth look at the new regulations affecting the insurance sector...",
      link: "https://www.interest.co.nz/insurance/129470/there-enough-competition-new-zealand%E2%80%99s-general-insurance-market-country%E2%80%99s-two",
      type: "link"
    },
    {
      title: "Health Insurance Policies Update",
      summary: "Recent changes in health insurance policies due to legislative amendments...",
      link: "",
      type: "policy"
    },
    {
      title: "Climate Change and Insurance",
      summary: "Exploring the impact of climate change on insurance premiums and policies...",
      link: "https://www.rnz.co.nz/news/business/520522/how-to-get-a-better-deal-on-your-car-insurance",
      type: "link"
    }
  ];


  const today = new Date();
  const oneWeekFromToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
  const twoWeekFromToday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);

  const clientsExpiringSoon = [
    { name: 'Client A', expiryDate: oneWeekFromToday },
    { name: 'Client B', expiryDate: oneWeekFromToday },
    { name: 'Client C', expiryDate: twoWeekFromToday },
    { name: 'Client D', expiryDate: twoWeekFromToday },
    { name: 'Client E', expiryDate: twoWeekFromToday },
    { name: 'Client F', expiryDate: oneWeekFromToday },
    // More clients...
  ];

  const [policies, setPolicies] = useState(generatePolicies(10));

  const handleStatusChange = (index, newStatus) => {
    const updatedPolicies = [...policies];
    updatedPolicies[index].status = newStatus;
    setPolicies(updatedPolicies);
  };

  const policiesWithHandlers = policies.map((policy, index) => ({
    ...policy,
    onStatusChange: handleStatusChange
  }));

  const clientTrendData = {
    daily: [
      { label: 'Apr 1', value: 2 },
      { label: 'Apr 2', value: 3 },
      { label: 'Apr 3', value: 1 },
      { label: 'Apr 4', value: 4 },
      { label: 'Apr 5', value: 3 },
      { label: 'Apr 6', value: 2 },
      { label: 'Apr 7', value: 3 }
      // ... more days as needed ...
    ],
    weekly: [
      { label: 'Week 1', value: 5 },
      { label: 'Week 2', value: 4 },
      { label: 'Week 3', value: 6 },
      { label: 'Week 4', value: 7 },
      { label: 'Week 5', value: 3 }
      // ... more weeks as needed ...
    ],
    monthly: [
      { label: 'January', value: 20 },
      { label: 'February', value: 18 },
      { label: 'March', value: 22 },
      { label: 'April', value: 19 },
      { label: 'May', value: 24 }
      // ... more months as needed ...
    ]
  };

  const retentionData = [
    { period: 'January', rate: 75 },
    { period: 'February', rate: 78 },
    { period: 'March', rate: 75 },
    { period: 'April', rate: 80 },
    { period: 'June', rate: 50 },
    { period: 'July', rate: 65 },
  ];

  return (
    <div className="home-container">
      <h2>Dashboard</h2>
      <div className="generic-white-page-container">
        <div className="home-container-grid-container">
          <div className="home-container-grid-item">
            <RecentNews newsItems={newsItems} />
          </div>
          <div className="home-container-grid-item">
            <TopActionsToday />
          </div>
          <div className="home-container-grid-item">
            <RecentlyAcceptedClients clients={newClients} trendData={clientTrendData} />
          </div>
          <div className="home-container-grid-item">
            <ExpiringClients expiringClients={clientsExpiringSoon} />
          </div>
          <div className="home-container-grid-item">
            <RetentionRateChart retentionData={retentionData} />
          </div>
          <div className="home-container-grid-item">
            <PolicyStatus policies={policiesWithHandlers} />
          </div>
          <div className="home-container-grid-item">
            <PolicyStatusGraph policies={policies} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;
