import React, { useState } from 'react';
import { formatDate } from '../../../utils/utils';

const ProposalsTab = ({ clientData, handleChange, onAddProposal, onEditProposal, onDeleteProposal }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [proposalToDelete, setProposalToDelete] = useState(null);

  const promptDelete = (proposal) => {
    setProposalToDelete(proposal);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = (proposal) => {
    onDeleteProposal(proposal);
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="client-details-popup modal-body-container">
      <div className="client-details-popup modal-grid-item">
        <div className="client-details-popup modal-grid-item-label">
          Proposals
          <button onClick={onAddProposal} className="add-proposal-button">Add Proposal</button>
        </div>
        <table className="client-details-popup modal-grid-item-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Quotes (#)</th>
              <th>Created </th>
              <th>Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clientData?.proposals?.map((proposal, index) => (
              <tr key={index}>
                <td>{proposal?.proposalId}</td>
                <td>{proposal?.name}</td>
                <td>{proposal?.quotes?.length}</td>
                <td>{formatDate(proposal?.createdAt)}</td>
                <td>{formatDate(proposal?.updatedAt)}</td>
                <td>
                  <div className="proposal-action-button-container">
                    <button className="proposal-action-button">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </button>
                    <div className="pop-up-menu">
                      <div className="pop-up-menu-item" onClick={() => onEditProposal(proposal)}>Edit</div>
                      <div className="pop-up-menu-item" onClick={() => promptDelete(proposal)}>Delete</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDeleteModalOpen && (
        <div className="delete-modal">
          <div className="modal-content">
            <h2>Delete Proposal</h2>
            <p>Are you sure you want to delete this proposal?</p>
            <button onClick={() => handleDelete(proposalToDelete)}>Yes, Delete</button>
            <button onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposalsTab;
