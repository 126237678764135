import React, { useState, useEffect } from 'react';
import { getCoverages } from '../../apis/Coverage'; // Import the getCoverages API function
import { formatString } from '../../utils/utils';
import './ShowPolicy.css';

const ShowPolicy = ({ policy, closeOverlay }) => {
    const [expanded, setExpanded] = useState({});
    const [allExpanded, setAllExpanded] = useState(true); // To toggle between collapse and expand
    const [coveragesData, setCoveragesData] = useState([]); // State to store the coverages data from the API

    useEffect(() => {
        // Automatically expand all coverage sections
        const expandedKeys = {};
        const expandAll = (coverage, parentKey = '') => {
            if (coverage && typeof coverage === 'object') {  // Check if coverage exists
                Object.entries(coverage).forEach(([key, value]) => {
                    const currentKey = `${parentKey}.${key}`;
                    expandedKeys[currentKey] = true;  // Mark as expanded

                    // If the value is an object and does not have an 'included' flag, continue expanding
                    if (value && typeof value === 'object' && !value?.included) {
                        expandAll(value, currentKey);
                    }
                });
            }
        };

        // Loop through the coverages and expand each one
        if (policy && policy.coverages) {
            Object.keys(policy.coverages).forEach((coverageKey) => {
                expandAll(policy.coverages[coverageKey], coverageKey);
            });
        }

        setExpanded(expandedKeys);
    }, [policy]);

    // Fetch the coverages data from the API when the component mounts
    useEffect(() => {
        const fetchCoverages = async () => {
            try {
                const data = await getCoverages(); // Call the API to get coverages
                setCoveragesData(data); // Store the fetched coverages in state
            } catch (error) {
                console.error('Error fetching coverages:', error);
            }
        };

        fetchCoverages();
    }, []);

    // Helper function to find the matching coverage description from the API
    const findCoverageDescription = (coverageId) => {
        const matchingCoverage = coveragesData.find((coverage) => coverage.id === coverageId);
        return matchingCoverage ? matchingCoverage.description : '';
    };

    // Helper function to find the matching coverage description from the API
    const findCoverageConsiderations = (coverageId) => {
        const matchingCoverage = coveragesData.find((coverage) => coverage.id === coverageId);
        return matchingCoverage ? matchingCoverage.considerations : '';
    };

    // Toggle collapse/expand for a given key
    const toggleExpand = (key) => {
        setExpanded((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const toggleAll = () => {
        const newExpandedState = {};
        Object.keys(expanded).forEach((key) => {
            newExpandedState[key] = !allExpanded;
        });
        setExpanded(newExpandedState);
        setAllExpanded(!allExpanded);
    };

    if (!policy) return null;

    // Recursive function to render coverage details with indentation
    const renderCoverageDetails = (coverage, level = 0, parentKey = '') => {
        if (typeof coverage === 'object' && coverage !== null) { // Check that coverage is not null
            return Object.entries(coverage).map(([key, value]) => {
                const currentKey = `${parentKey}.${key}`;
                const indent = `${level * 10}px`; // Indent based on the level of depth
                const isExpanded = expanded[currentKey];
                const isExpandable = typeof value === 'object' && value !== null && !value?.included;

                // Find the matching description from the API
                const coverageDescription = findCoverageDescription(currentKey);
                const coverageConsiderations = findCoverageConsiderations(currentKey);

                return (
                    <React.Fragment key={currentKey}>
                        <tr>
                            <td style={{ paddingLeft: indent }} className="show-policy-comparison-table-heading">
                                {isExpandable && (
                                    <button
                                        className="show-policy-expand-collapse-button"
                                        onClick={() => toggleExpand(currentKey)}
                                    >
                                        {expanded[currentKey] ? '-' : '+'}
                                    </button>
                                )}
                                {formatString(key)}
                            </td>
                            <td className={value?.included ? 'show-policy-comparison-table-included-cell' : ''}>
                                {value?.included ? 'Yes' : ''}
                            </td>
                            {/* <td className={value?.optional ? 'show-policy-comparison-table-optional-cell' : ''}>
                                {value?.optional ? 'Yes' : ''}
                            </td>
                            <td className={value?.excess ? 'show-policy-comparison-table-excess-cell' : ''}>
                                {value?.excess || ''}
                            </td> */}
                            <td className={value?.limit ? 'show-policy-comparison-table-limit-cell' : ''}>
                                {value?.limit || ''}
                            </td>
                            <td className={value?.conditions ? 'show-policy-comparison-table-conditions-cell' : ''}>
                                {value?.conditions || ''}
                            </td>
                            <td className={value?.conditionsNumber ? 'show-policy-comparison-table-advanced' : ''}>
                                {value?.conditionsNumber === 'N/A' ? '0' : value?.conditionsNumber || ''}
                            </td>
                            <td className={value?.confidence ? 'show-policy-comparison-table-advanced' : ''}>
                                {value?.confidence === 'N/A' ? '0' : value?.confidence || ''}
                            </td>
                            <td className={value?.complexity ? 'show-policy-comparison-table-advanced' : ''}>
                                {value?.complexity === 'N/A' ? '0' : value?.complexity || ''}
                            </td>
                            {/* New column to display the matching coverage description */}
                            <td className="show-policy-comparison-table-description-cell">
                                {coverageDescription}
                            </td>
                            {/* New column to display the matching coverage description */}
                            <td className="show-policy-comparison-table-description-cell">
                                {coverageConsiderations}
                            </td>
                        </tr>
                        {isExpanded && isExpandable && renderCoverageDetails(value, level + 1, currentKey)}
                    </React.Fragment>
                );
            });
        }
        return null;
    };

    return (
        <div className="show-policy-overlay" onClick={closeOverlay}>
            <div className="show-policy-compare-popup" onClick={(e) => e.stopPropagation()}>
                <button className="show-policy-close-button" onClick={closeOverlay}>&times;</button>
                <div className='show-policy-comparison-table-header'>
                    <div className='show-policy-comparison-table-header-item'>
                        <strong>Policy:</strong> {policy?.name}
                    </div>
                    <div className='show-policy-comparison-table-header-item'>
                        <strong>Provider:</strong> {policy?.provider}
                    </div>
                </div>
                <div className='show-policy-comparison-table-scroll'>
                    <table className="show-policy-comparison-table">
                        <thead>
                            <tr>
                                <th>Coverage</th>
                                <th>Included</th>
                                {/* <th>Optional</th>
                                <th>Excess</th> */}
                                <th>Limit</th>
                                <th>Key Conditions</th>
                                <th className='show-policy-rotated-header'>Conditions (#)</th>
                                <th className='show-policy-rotated-header'>Confidence (%)</th>
                                <th className='show-policy-rotated-header'>Complexity (/100)</th>
                                <th>Description</th> {/* New header for description */}
                                <th>Considerations</th> {/* New header for description */}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(policy.coverages).map((coverageKey) => (
                                <React.Fragment key={coverageKey}>
                                    <tr>
                                        <td className="show-policy-comparison-table-heading" colSpan={10}>
                                            <strong>{formatString(coverageKey)}</strong>
                                        </td>
                                    </tr>
                                    {renderCoverageDetails(policy.coverages[coverageKey], 1, coverageKey)}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="compare-policy-footer">
                    <button className="toggle-expand-btn" onClick={toggleAll}>
                        {allExpanded ? 'Collapse all' : 'Expand all'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShowPolicy;
