import React, { useEffect} from "react";
import './Leads.css';
import LeadsOverview from '../../components/leads/LeadsOverview';
import LeadSourceAnalysis from '../../components/leads/LeadSourceAnalysis';
import ConversionRates from '../../components/leads/ConversionRates';
import LeadQualificationBreakdown from '../../components/leads/LeadQualificationBreakdown';
import RecentLeads from '../../components/leads/RecentLeads';
import TasksAndFollowUps from '../../components/leads/TasksAndFollowUps';
import ProfitabilityOverview from '../../components/leads/ProfitabilityOverview';
import ProfitabilityProgress from '../../components/leads/ProfitabilityProgress';

const Leads = () => {
  useEffect(() => {
      document.title = 'Leads';
    }, []);
  const totalLeads = 120;
  const newLeads = 30;
  const contactedLeads = 75;

  const leadData = {
    'Social Media': 120,
    'Email Campaigns': 75,
    'Website': 90,
    'Direct Contact': 45,
    'Other': 30
  };

  const conversionData = {
    'Social Media': 20,
    'Email Campaigns': 25,
    'Website': 15,
    'Direct Contact': 30,
    'Other': 10
  };

  const qualificationData = {
    'New': 120,
    'Qualified': 80,
    'In Negotiation': 60,
    'Converted': 40
  };

  const currentDate = new Date(); // Create a single date object

  const recentLeads = [
    { name: 'Alice Smith', dateCreated: currentDate, source: 'Email Campaign', contactInfo: 'alice@example.com', phone: '1234 6573 1112', status: 'New' },
    { name: 'Bob Johnson', dateCreated: currentDate, source: 'Social Media', contactInfo: 'bob@example.com', phone: '1234 6573 1112', status: 'Contacted' },
    { name: 'Carol White', dateCreated: currentDate, source: 'Direct Mail', contactInfo: 'carol@example.com', phone: '2345 7684 2213', status: 'New' },
    { name: 'David Brown', dateCreated: currentDate, source: 'Referral', contactInfo: 'david@example.com', phone: '3456 8795 3324', status: 'Qualified' },
    { name: 'Eva Green', dateCreated: currentDate, source: 'Event', contactInfo: 'eva@example.com', phone: '4567 9876 4435', status: 'Negotiation' },
    { name: 'Fiona Blue', dateCreated: currentDate, source: 'Online Ad', contactInfo: 'fiona@example.com', phone: '5678 1011 5546', status: 'Contacted' },
    { name: 'George King', dateCreated: currentDate, source: 'Email Campaign', contactInfo: 'george@example.com', phone: '6789 1122 6657', status: 'New' },
    { name: 'Hannah Stone', dateCreated: currentDate, source: 'Social Media', contactInfo: 'hannah@example.com', phone: '7890 2233 7768', status: 'Qualified' },
    { name: 'Ian Grey', dateCreated: currentDate, source: 'Referral', contactInfo: 'ian@example.com', phone: '8901 3344 8879', status: 'Negotiation' },
    { name: 'Julia Gold', dateCreated: currentDate, source: 'Event', contactInfo: 'julia@example.com', phone: '9012 4455 9980', status: 'Converted' },

  ];

  const tasks = [
    { name: 'Call with John Doe', dueDate: 'Apr 10, 2024', isCompleted: false },
    { name: 'Email proposal to Alice', dueDate: 'Apr 12, 2024', isCompleted: true },
    // ... more tasks
  ];

  const leadBatches = [
    { name: 'Batch 1', cost: 500, convertedCustomers: 5, profit: 2500,margin: 2000},
    { name: 'Batch 2', cost: 750, convertedCustomers: 7, profit: 3500, margin: 3000},
    { name: 'Batch 3', cost: 800, convertedCustomers: 10, profit: 4500, margin: 3500},
    { name: 'Batch 4', cost: 950, convertedCustomers: 15, profit: 6500, margin: 4000},

  ];

  return (
    <div className="lead-container">
      <h2>Lead Generation</h2>
      <div className="lead-dashboard">
        <div className="grid">
          <div className="grid-item">
            <div className="grid-item-title">Summary</div>
            <LeadsOverview
              totalLeads={totalLeads}
              newLeads={newLeads}
              contactedLeads={contactedLeads}
            />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Lead Source</div>
            <LeadSourceAnalysis leadData={leadData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Conversion Rates</div>
            <ConversionRates conversionData={conversionData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Qualification Breakdown</div>
            <LeadQualificationBreakdown qualificationData={qualificationData} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Profitability</div>
            <ProfitabilityOverview leadBatches={leadBatches} />
          </div>
          <div className="grid-item">
            <div className="grid-item-title">Profitability</div>
            <ProfitabilityProgress leadBatches={leadBatches} />
          </div>
        </div>
        <div className="lead-container-table">
          <div className="grid-item-title">Recent Leads</div>
          <RecentLeads leads={recentLeads} />
        </div>
      </div>
    </div>
  );
};

export default Leads;