import React, { createContext, useContext } from 'react';
import HomeIcon from "../icons/home.png";
import ContentsIcon from "../icons/contents.png";
import HomeAndContentsIcon from "../icons/homeandcontents.png";
import TravelIcon from "../icons/travel.png";
import LandLordIcon from "../icons/landlord.png";
import MotorIcon from "../icons/motor.png";

// Create a context
const PolicyTypesContext = createContext();

// Provider component
export const PolicyTypesProvider = ({ children }) => {
  const policyTypes = [

    // Residential Insurance
    { code: 'home', label: 'Home', enabled: true, category: 'General', icon: HomeIcon },
    { code: 'contents', label: 'Contents', enabled: true, category: 'General', icon: ContentsIcon },
    { code: 'home-contents', label: 'Home & Contents', enabled: true, category: 'General', icon: HomeAndContentsIcon },
    { code: 'landlord', label: 'Landlord', enabled: true, category: 'General', icon: LandLordIcon },
    { code: 'travel', label: 'Travel', enabled: true, category: 'General', icon: TravelIcon },
    { code: 'pet', label: 'Pet', enabled: true, category: 'General' },
    
    // General Business Insurance
    { code: 'isr', label: 'ISR', enabled: true, category: 'Business' },
    { code: 'abattoirs', label: 'Abattoirs', enabled: false, category: 'Business' },
    { code: 'accountants', label: 'Accountants', enabled: false, category: 'Business' },
    { code: 'aerospace', label: 'Aerospace', enabled: false, category: 'Business' },
    { code: 'aged-care', label: 'Aged Care', enabled: false, category: 'Business' },
    { code: 'business-owners-policy', label: 'Business Owners', enabled: false, category: 'Business' },
    { code: 'commercial-auto', label: 'Commercial Auto', enabled: false, category: 'Business' },
    { code: 'workers-compensation', label: 'Workers\' Compensation', enabled: false, category: 'Business' },
    { code: 'general-liability', label: 'General Liability', enabled: false, category: 'Business' },
    { code: 'umbrella-liability', label: 'Umbrella Liability', enabled: false, category: 'Business' },
    
    // Motor & Transport
    { code: 'auto-manufacture-liability', label: 'Auto Manufacture Liability', enabled: false, category: 'Motor' },
    { code: 'auto-mechanical-electrical', label: 'Auto Mechanical & Electrical', enabled: false, category: 'Motor' },
    { code: 'boats', label: 'Boats', enabled: false, category: 'Motor' },
    { code: 'bus-coach', label: 'Bus & Coach', enabled: false, category: 'Motor' },
    { code: 'commercial-motor-fleet', label: 'Commercial Motor Fleet', enabled: false, category: 'Motor' },
    { code: 'motorcycles', label: 'Motorcycles', enabled: false, category: 'Motor' },
    { code: 'motor', label: 'Motor Vehicle', enabled: true, category: 'Motor', icon: MotorIcon },
  
    // Personal Insurance
    { code: 'life-insurance', label: 'Life Insurance', enabled: false, category: 'Personal' },
    { code: 'income-protection', label: 'Income Protection', enabled: false, category: 'Personal' },
    { code: 'tppd', label: 'Total & Permanent Disability', enabled: false, category: 'Personal' },

    // Construction
    { code: 'construction-projects', label: 'Construction Projects', enabled: false, category: 'Construction' },
    { code: 'bridge-construction', label: 'Bridge Construction', enabled: false, category: 'Construction' },
    { code: 'builders', label: 'Builders', enabled: false, category: 'Construction' },
    { code: 'civil-construction', label: 'Civil Construction', enabled: false, category: 'Construction' },
    { code: 'contractor', label: 'Contractor', enabled: false, category: 'Construction' },
    { code: 'contractors-plant', label: 'Contractors Plant', enabled: false, category: 'Construction' },
    { code: 'cranes-earth-moving-heavy-machinery', label: 'Cranes / Earth Moving Heavy Machinery', enabled: false, category: 'Construction' },

    // Professional Services
    { code: 'architects', label: 'Architects', enabled: false, category: 'Professional' },
    { code: 'arborists-tree-contractors', label: 'Arborists / Tree Contractors', enabled: false, category: 'Professional' },
    { code: 'associations-professional-bodies', label: 'Associations / Professional Bodies', enabled: false, category: 'Professional' },
    { code: 'building-inspectors', label: 'Building Inspectors', enabled: false, category: 'Professional' },
    { code: 'dentists', label: 'Dentists', enabled: false, category: 'Professional' },
    { code: 'chiropractic', label: 'Chiropractic', enabled: false, category: 'Professional' },
    { code: 'electricians-liability', label: 'Electricians Liability', enabled: false, category: 'Professional' },
    { code: 'engineers', label: 'Engineers', enabled: false, category: 'Professional' },
    { code: 'financial-planning', label: 'Financial Planning', enabled: false, category: 'Professional' },
    { code: 'graphic-designers-professional-indemnity', label: 'Graphic Designers Professional Indemnity', enabled: false, category: 'Professional' },
    { code: 'physiotherapists', label: 'Physiotherapists', enabled: false, category: 'Professional' },

    // Property
    { code: 'art-antiques-collectables', label: 'Art & Antiques / Collectables', enabled: false, category: 'Property' },
    { code: 'art-galleries', label: 'Art Galleries', enabled: false, category: 'Property' },
    { code: 'assets-and-revenue', label: 'Assets & Revenue', enabled: false, category: 'Property' },
    { code: 'boarding-house-accommodation', label: 'Boarding House Accommodation', enabled: false, category: 'Property' },
    { code: 'body-corporate', label: 'Body Corporate', enabled: false, category: 'Property' },
    { code: 'builders-warranty', label: 'Builders Warranty', enabled: false, category: 'Property' },

    // Specialty
    { code: 'aviaries-birds', label: 'Aviaries / Birds', enabled: false, category: 'Specialty' },
    { code: 'aviation', label: 'Aviation', enabled: false, category: 'Specialty' },
    { code: 'aviation-hull-liability', label: 'Aviation Hull Liability', enabled: false, category: 'Specialty' },
    { code: 'ballooning', label: 'Ballooning', enabled: false, category: 'Specialty' },
    { code: 'cyber-liability', label: 'Cyber Liability', enabled: false, category: 'Specialty' },
    { code: 'event-insurance', label: 'Event Insurance', enabled: false, category: 'Specialty' },


  ];

  return (
    <PolicyTypesContext.Provider value={policyTypes}>
      {children}
    </PolicyTypesContext.Provider>
  );
};

// Custom hook to use the policy types
export const usePolicyTypes = () => {
  return useContext(PolicyTypesContext);
};
