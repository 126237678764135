import React, { useEffect } from 'react';
import BrokerDirectory from '../../components/directory/BrokerDirectory';
import './Directory.css';

const Directory = () => {
    useEffect(() => {
        document.title = 'Directory';
    }, []);

    return (
        <div className="directory-page">
            <BrokerDirectory />
        </div>
    );
};

export default Directory;