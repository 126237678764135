import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const TravelContext = createContext();

export const TravelProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial travel insurance state
  const initialTravelInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        tripProtection: false, // Objective for trip protection
        medicalCoverage: false, // Objective for medical coverage abroad
        cancellationCoverage: false, // Objective for trip cancellation coverage
      },
      goals: {
        coverageAmount: 0, // Goal for coverage amount in currency
        tripDuration: 0, // Goal for trip duration in days
      },
    },
    situation: {
      travelerDetails: {
        name: '',
        age: 0,
        nationality: '',
        destination: '',
      },
      travelPlans: {
        departureDate: '',
        returnDate: '',
        travelPurpose: '',
      },
      additionalCoverage: {
        rentalCarProtection: false, // Additional coverage option for rental cars
        adventureSportsCoverage: false, // Additional coverage option for adventure sports
        luggageProtection: false, // Additional coverage option for luggage protection
      },
    },
    modules: {
      options: {
        emergencyAssistance: false, // Option for emergency assistance coverage
        tripCancellation: false, // Option for trip cancellation insurance
        travelMedicalInsurance: false, // Option for travel medical insurance
        evacuationCoverage: false, // Option for emergency evacuation coverage
      },
    },
  };

  // Initialize filters state with the initialTravelInsuranceState
  const [filters, setFilters] = useState(initialTravelInsuranceState);

  return (
    <TravelContext.Provider value={{ filters, setFilters }}>
      {children}
    </TravelContext.Provider>
  );
};
