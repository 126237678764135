
import React, { useEffect } from 'react';
import CommissionsOverTime from './components/CommissionsOverTime';
import CircularProgressCard from './components/CircularProgressCard';
import StackedBarChart from './components/StackedBarChart';
import DonutChart from './components/DonutChart';
import TopClientsBarGraph from './components/TopClientsBarGraph';
import './Commissions.css';

const Commissions = () => {

  useEffect(() => {
    document.title = 'Commissions';
  }, []);

  const commissionGoal = 10000;

  const dummyData = {
    lastMonthCommissions: { upfront: 5000, trail: 2000, total: 7000 },
    thisMonthCommissions: { upfront: 3000, trail: 1500, total: 4500 },
    projectedCommissions: { upfront: 4000, trail: 1800, total: 5800 },
    monthlyCommissions: [500, 1500, 2500, 3000, 4000, 4500], // Add this array
    sales: [
      { date: '2024-08-01', client: 'John Doe', insurer: 'Tower', policyType: 'Home', upfront: 1000, trail: 200, total: 1200 },
      { date: '2024-08-10', client: 'Jane Smith', insurer: 'AA', policyType: 'Contents', upfront: 1200, trail: 300, total: 1500 },
      { date: '2024-08-20', client: 'Acme Corp', insurer: 'AMI', policyType: 'Business', upfront: 800, trail: 100, total: 900 },
      { date: '2024-08-25', client: 'Globex', insurer: 'State', policyType: 'Commercial', upfront: 1100, trail: 250, total: 1350 },
      { date: '2024-08-28', client: 'Wayne Enterprises', insurer: 'Tower', policyType: 'ISR', upfront: 1300, trail: 220, total: 1520 },
      { date: '2024-09-01', client: 'Stark Industries', insurer: 'AA', policyType: 'Business', upfront: 2000, trail: 300, total: 2300 },
      { date: '2024-09-05', client: 'Oscorp', insurer: 'AMI', policyType: 'Commercial', upfront: 900, trail: 150, total: 1050 },
      { date: '2024-09-06', client: 'Cyberdyne', insurer: 'State', policyType: 'ISR', upfront: 1250, trail: 180, total: 1430 },
      { date: '2024-09-07', client: 'Umbrella Corp', insurer: 'Tower', policyType: 'Home', upfront: 1100, trail: 170, total: 1270 },
      { date: '2024-09-08', client: 'InGen', insurer: 'AA', policyType: 'Business', upfront: 1600, trail: 400, total: 2000 },
      { date: '2024-09-10', client: 'Buy N Large', insurer: 'AMI', policyType: 'Contents', upfront: 1000, trail: 250, total: 1250 },
      { date: '2024-09-11', client: 'Sirius Cybernetics', insurer: 'State', policyType: 'ISR', upfront: 1400, trail: 270, total: 1670 },
      { date: '2024-09-12', client: 'Cybertronics', insurer: 'Tower', policyType: 'Commercial', upfront: 1200, trail: 300, total: 1500 },
      { date: '2024-09-13', client: 'Tyrell Corp', insurer: 'AA', policyType: 'Home', upfront: 1350, trail: 350, total: 1700 },
      { date: '2024-09-15', client: 'Weyland-Yutani', insurer: 'AMI', policyType: 'Business', upfront: 1550, trail: 200, total: 1750 },
      { date: '2024-09-17', client: 'Yoyodyne Propulsion', insurer: 'State', policyType: 'Contents', upfront: 900, trail: 180, total: 1080 },
      { date: '2024-09-19', client: 'Soylent Corp', insurer: 'Tower', policyType: 'ISR', upfront: 1400, trail: 220, total: 1620 },
      { date: '2024-09-20', client: 'Massive Dynamic', insurer: 'AA', policyType: 'Commercial', upfront: 1300, trail: 300, total: 1600 },
      { date: '2024-09-21', client: 'Octan Corp', insurer: 'AMI', policyType: 'Home', upfront: 1450, trail: 250, total: 1700 },
      { date: '2024-09-23', client: 'Wonka Industries', insurer: 'State', policyType: 'Business', upfront: 1200, trail: 230, total: 1430 },
      { date: '2024-09-24', client: 'Duff Brewery', insurer: 'Tower', policyType: 'Contents', upfront: 1150, trail: 180, total: 1330 },
      { date: '2024-09-25', client: 'OCP', insurer: 'AA', policyType: 'Commercial', upfront: 1100, trail: 210, total: 1310 },
      { date: '2024-09-26', client: 'Zorg Industries', insurer: 'AMI', policyType: 'Home', upfront: 1350, trail: 280, total: 1630 },
      { date: '2024-09-27', client: 'LexCorp', insurer: 'State', policyType: 'Business', upfront: 1250, trail: 300, total: 1550 },
      { date: '2024-09-28', client: 'Black Mesa', insurer: 'Tower', policyType: 'Contents', upfront: 1400, trail: 250, total: 1650 },
      { date: '2024-09-29', client: 'Aperture Science', insurer: 'AA', policyType: 'ISR', upfront: 1300, trail: 240, total: 1540 },
      { date: '2024-09-30', client: 'MomCorp', insurer: 'AMI', policyType: 'Commercial', upfront: 1450, trail: 260, total: 1710 },
      { date: '2024-10-01', client: 'Bluth Company', insurer: 'State', policyType: 'Business', upfront: 1000, trail: 200, total: 1200 },
      { date: '2024-10-02', client: 'SPECTRE', insurer: 'Tower', policyType: 'Home', upfront: 1100, trail: 250, total: 1350 },
      { date: '2024-10-03', client: 'Skynet', insurer: 'AA', policyType: 'ISR', upfront: 1200, trail: 300, total: 1500 },
      { date: '2024-10-04', client: 'Umbrella Academy', insurer: 'AMI', policyType: 'Business', upfront: 1050, trail: 220, total: 1270 },
      { date: '2024-10-05', client: 'Dunder Mifflin', insurer: 'State', policyType: 'Contents', upfront: 1150, trail: 230, total: 1380 },
      { date: '2024-10-06', client: 'Vought International', insurer: 'Tower', policyType: 'Commercial', upfront: 1300, trail: 300, total: 1600 },
      { date: '2024-10-07', client: 'Monsters Inc.', insurer: 'AA', policyType: 'Business', upfront: 1400, trail: 280, total: 1680 },
      { date: '2024-10-08', client: 'Pied Piper', insurer: 'AMI', policyType: 'Home', upfront: 1250, trail: 250, total: 1500 },
      { date: '2024-10-09', client: 'Hooli', insurer: 'State', policyType: 'Contents', upfront: 1500, trail: 350, total: 1850 },
      { date: '2024-10-10', client: 'Initech', insurer: 'Tower', policyType: 'ISR', upfront: 1400, trail: 330, total: 1730 },
      { date: '2024-10-11', client: 'Planet Express', insurer: 'AA', policyType: 'Business', upfront: 1600, trail: 400, total: 2000 },
      { date: '2024-10-12', client: 'Gringotts', insurer: 'AMI', policyType: 'Home', upfront: 1450, trail: 320, total: 1770 },
      { date: '2024-10-13', client: 'Cyberdyne Systems', insurer: 'State', policyType: 'ISR', upfront: 1350, trail: 300, total: 1650 },
      { date: '2024-10-14', client: 'Wolfram & Hart', insurer: 'Tower', policyType: 'Commercial', upfront: 1100, trail: 280, total: 1380 },
      { date: '2024-10-15', client: 'Nakatomi Trading', insurer: 'AA', policyType: 'Business', upfront: 1250, trail: 250, total: 1500 },
      { date: '2024-10-16', client: 'Goodman, Lieber, Kurtzberg & Holliway', insurer: 'AMI', policyType: 'Home', upfront: 1300, trail: 270, total: 1570 },
      { date: '2024-10-17', client: 'Hudsucker Industries', insurer: 'State', policyType: 'Commercial', upfront: 1450, trail: 290, total: 1740 },
      { date: '2024-10-18', client: 'Cyberdyne Research', insurer: 'Tower', policyType: 'ISR', upfront: 1200, trail: 200, total: 1400 },
      { date: '2024-10-19', client: 'Tyrell Biotech', insurer: 'AA', policyType: 'Business', upfront: 1100, trail: 230, total: 1330 },
      { date: '2024-10-20', client: 'Oscorp Genetics', insurer: 'AMI', policyType: 'Contents', upfront: 1400, trail: 280, total: 1680 },
      { date: '2024-10-21', client: 'Luthor Enterprises', insurer: 'State', policyType: 'Home', upfront: 1250, trail: 260, total: 1510 },
    ]
  };

  // Prepare the data for the chart, mapping it to 'upfront', 'trail', and 'total'
  const chartData = [
    {
      name: 'Last Month',
      upfront: dummyData.lastMonthCommissions.upfront,
      trail: dummyData.lastMonthCommissions.trail,
      total: dummyData.lastMonthCommissions.total,
    },
    {
      name: 'This Month',
      upfront: dummyData.thisMonthCommissions.upfront,
      trail: dummyData.thisMonthCommissions.trail,
      total: dummyData.thisMonthCommissions.total,
    },
    {
      name: 'Projected',
      upfront: dummyData.projectedCommissions.upfront,
      trail: dummyData.projectedCommissions.trail,
      total: dummyData.projectedCommissions.total,
    },
  ];

   // Function to add insurancePremium to each sale
   const addInsurancePremiums = (sales) => {
    return sales.map(sale => ({
      ...sale,
      insurancePremium: Math.floor(Math.random() * (50000 - 1000 + 1) + 1000)
    }));
  };

  const sales = addInsurancePremiums(dummyData.sales);

  const calculateTotalInsurancePremiums = (sales) => {
    return sales.reduce((total, sale) => total + sale.insurancePremium, 0);
  };

  return (
    <div className="commissions-container">
      <h2>Your Commission</h2>
      <div className="commissions-container-white">

        <h3>Summary</h3>
        <div className="commissions-metrics">
          <CircularProgressCard color={"rgb(79,166,88)"}
          totalCommission={calculateTotalInsurancePremiums(sales)} goal={calculateTotalInsurancePremiums(sales) * 1.5} 
          title={"Total Premiums "}/>
          <CircularProgressCard color={"rgb(79,166,88)"}
          totalCommission={dummyData.thisMonthCommissions.total} goal={commissionGoal} 
          title={"This Month's Commission"}/>
          <StackedBarChart data={chartData} />
          {dummyData.sales && dummyData.sales.length > 0 && <DonutChart sales={dummyData.sales} />}
          <TopClientsBarGraph data={sales}
          title={"Top 10 Clients by Premiums"}/>
        </div>

        <h3>Commissions Over Time</h3>
        <div className="commissions-graph">
          <CommissionsOverTime sales={dummyData.sales} />
        </div>
        <h3>Commissions Breakdown</h3>
        <div className="commissions-container-div">
          <div className="commissions-breakdown">
            <table className="commissions-table">
              <thead>
                <tr>
                  <th>Date of Sale</th>
                  <th>Client Name</th>
                  <th>Insurer</th>
                  <th>Policy Type</th>
                  <th>Upfront Commission</th>
                  <th>Trail Commission</th>
                  <th>Total Commission</th>
                </tr>
              </thead>
              <tbody>
                {dummyData.sales.map((sale, index) => (
                  <tr key={index}>
                    <td>{sale.date}</td>
                    <td>{sale.client}</td>
                    <td>{sale.insurer}</td>
                    <td>{sale.policyType}</td>
                    <td>${sale.upfront}</td>
                    <td>${sale.trail}</td>
                    <td>${sale.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commissions;
