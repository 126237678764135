import React, { useState, useEffect } from 'react';
import { formatString } from '../../utils/utils';
import './ComparePolicies.css';
import CompareStarRating from './components/CompareStarRating'; // Assuming you have the StarRating component
import StarRating from '../StarRating'; // Assuming you have the StarRating component

const ComparePolicies = ({ selectedPolicies, closeOverlay }) => {
  const [expanded, setExpanded] = useState({});
  const [allExpanded, setAllExpanded] = useState(true); // To toggle between collapse and expand

  useEffect(() => {
    // Automatically expand all coverage sections
    const expandedKeys = {};
    const expandAll = (coverage, parentKey = '') => {
      if (coverage && typeof coverage === 'object') {
        Object.entries(coverage).forEach(([key, value]) => {
          const currentKey = `${parentKey}.${key}`;
          expandedKeys[currentKey] = true;

          if (value && typeof value === 'object' && !value?.included) {
            expandAll(value, currentKey); // Recursively expand deeper levels
          }
        });
      }
    };

    if (selectedPolicies.first && selectedPolicies.first.coverages) {
      Object.keys(selectedPolicies.first.coverages).forEach((coverageKey) => {
        expandAll(selectedPolicies.first.coverages[coverageKey], coverageKey);
      });
    }

    setExpanded(expandedKeys);
  }, [selectedPolicies]);

  const toggleExpand = (key) => {
    setExpanded((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleAll = () => {
    const newExpandedState = {};
    Object.keys(expanded).forEach((key) => {
      newExpandedState[key] = !allExpanded;
    });
    setExpanded(newExpandedState);
    setAllExpanded(!allExpanded);
  };

  const calculateRating = (coverage) => {
    const confidence = Number(coverage?.confidence) === 0 ? 1 : (Number(coverage?.confidence) || 1);
    const complexity = Number(coverage?.complexity) === 0 ? 1 : (Number(coverage?.complexity) || 1);
    const conditions = Number(coverage?.conditionsNumber) === 0 ? 1 : (Number(coverage?.conditionsNumber) || 1);

    return confidence * complexity * conditions;
  };


  const calculateDepthRating = (coverage) => {
    let includedCount = 0;
    let totalCount = 0;

    const traverse = (obj) => {
      if (typeof obj === 'object' && obj !== null) {
        Object.values(obj).forEach((item) => {
          if (item && typeof item === 'object') {
            if ('included' in item) {
              totalCount++;
              if (item.included) {
                includedCount++;
              }
            } else {
              traverse(item); // Recursively count in deeper levels
            }
          }
        });
      }
    };

    traverse(coverage);

    if (totalCount === 0) return 0;
    {console.log("totalCount",includedCount / totalCount)}
    return (includedCount / totalCount) * 5; 
  };

  const renderCoverageDetails = (coverage, coverage2, level = 0, parentKey = '') => {
    if (coverage && typeof coverage === 'object') {
      return Object.entries(coverage).map(([key, value]) => {
        const currentKey = `${parentKey}.${key}`;
        const indent = `${level * 10}px`;
        const isExpanded = expanded[currentKey];
        const isExpandable = typeof value === 'object' && value !== null && !value?.included;
        const rating = calculateRating(value);
        const rating2 = coverage2 && coverage2[key] ? calculateRating(coverage2[key]) : 0;

        // Calculate star rating based on deeper items
        const depthRating = calculateDepthRating(value);
        const depthRating2 = calculateDepthRating(coverage2?.[key]);

        return (
          <React.Fragment key={currentKey}>
            <tr>
              <td style={{ paddingLeft: indent }} className="show-policy-comparison-table-heading">
                {isExpandable && (
                  <button className="show-policy-expand-collapse-button" onClick={() => toggleExpand(currentKey)}>
                    {expanded[currentKey] ? '-' : '+'}
                  </button>
                )}
                {formatString(key)}
              </td>

              {/* First Policy (coverage) */}
              <td className={value?.included ? 'show-policy-comparison-table-included-cell' : ''}>
                {value?.included ? 'Yes' : ''}
              </td>
              <td>
                {isExpandable ? (
                  <StarRating rating={Math.min(Math.ceil(depthRating), 5)} />
                ) : (
                  value?.included && (
                    <CompareStarRating
                      confidence={value.confidence}
                      complexity={value.complexity}
                      conditions={value.conditions}
                      rating={Math.min(Math.ceil(rating / 100), 5)} />
                ))}
              </td>

              {/* Second Policy (coverage2) */}
              <td className={coverage2?.[key]?.included ? 'show-policy-comparison-table-included-cell' : ''}>
                {coverage2?.[key]?.included ? 'Yes' : ''}
              </td>
              <td >
                {isExpandable ? (
                  <StarRating rating={Math.min(Math.ceil(depthRating2), 5)} />
                ) : (
                  coverage2?.[key]?.included && 
                  <CompareStarRating 
                  confidence={coverage2?.[key]?.confidence}
                  complexity={coverage2?.[key]?.complexity}
                  conditions={coverage2?.[key]?.conditions}
                  rating={Math.min(Math.ceil(rating2 / 100), 5)} />
                )}
              </td>
            </tr>
            {isExpanded && isExpandable && renderCoverageDetails(value, coverage2?.[key], level + 1, currentKey)}
          </React.Fragment>
        );
      });
    }
    return null;
  };



  if (!selectedPolicies.first || !selectedPolicies.second) return null;

  return (
    <div className="compare-policy-overlay" onClick={closeOverlay}>
      <div className="compare-policy-compare-popup" onClick={(e) => e.stopPropagation()}>
        <button className="compare-policy-close-button" onClick={closeOverlay}>&times;</button>
        <div className='compare-policy-comparison-table-header'>
          <div className='compare-policy-comparison-table-header-item'>
            <strong>Policy 1:</strong> {selectedPolicies.first?.provider} {selectedPolicies.first?.name}
            <p>{selectedPolicies.first?.fileName}</p>
          </div>
          <div className='compare-policy-comparison-table-header-item'>
            <strong>Policy 2:</strong> {selectedPolicies.second?.provider} {selectedPolicies.second?.name}
            <p>{selectedPolicies.second?.fileName}</p>
          </div>
        </div>
        <div className='compare-policy-comparison-table-scroll'>
          <table className="compare-policy-comparison-table">
            <thead>
              <tr>
                <th rowSpan="2">Policy Term</th> {/* Merged header */}
                <th colSpan="2">{selectedPolicies.first?.provider} {selectedPolicies.first?.name}</th>
                <th colSpan="2">{selectedPolicies.second?.provider} {selectedPolicies.second?.name}</th>
              </tr>
              <tr>
                <th>Coverage</th>
                <th>Rating</th>
                <th>Coverage</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(selectedPolicies.first.coverages).map((coverageKey) => (
                <React.Fragment key={coverageKey}>
                  <tr>
                    <td className="compare-policy-comparison-table-heading" colSpan={5}>
                      <strong>{formatString(coverageKey)}</strong>
                    </td>
                  </tr>
                  {/* Recursive rendering of deeper coverages */}
                  {renderCoverageDetails(selectedPolicies.first.coverages[coverageKey], selectedPolicies.second.coverages[coverageKey], 1, coverageKey)}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="compare-policy-footer">
          <button className="toggle-expand-btn" onClick={toggleAll}>
            {allExpanded ? 'Collapse all' : 'Expand all'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComparePolicies;
