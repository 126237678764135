import React, { createContext } from 'react';

export const RefContext = createContext();

export const RefProvider = ({ children }) => {
  const myTargetRef = React.useRef(null);

  return (
    <RefContext.Provider value={myTargetRef}>
      {children}
    </RefContext.Provider>
  );
};
