import React, { useState, useContext, useEffect, useRef } from 'react';
import EmailTemplate from '../../components/templates/EmailTemplate';
import DocumentTemplate from '../../components/templates/DocumentTemplate';
import { useCollapse } from '../../context/CollapseContext';
import Tabs from '../../components/Tabs';
import './Email.css';

const tabNames = ['Email Communication', 'Policy Schedule'];

const Email = () => {
  const { isCollapsed } = useCollapse();
  const [activeTab, setActiveTab] = useState(tabNames[0]);

  return (
    <div className="email-container">
      <h2>Your Templates</h2>
      <div style={{ width: '30%' }}>
        <Tabs tabNames={tabNames} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="email-tab-content">
        {activeTab === 'Email Communication' && (
          <EmailTemplate />
        )}
        {activeTab === 'Policy Schedule' && (
          <DocumentTemplate />
        )}
      </div>
    </div>
  );
};

export default Email;
