import React from 'react';
import PolicyChart from '../PolicyChart';
import PolicyElementsChart from '../PolicyElementsChart';

const Excess = ({ compare = true }) => {

    return (
        <>
            <PolicyElementsChart valueType="excess"  />
            {/* <PolicyChart type='excess' isVertical={false} maxSlide={5000} compare={compare} /> */}
        </>
    );
};

export default Excess;
