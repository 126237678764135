import React, { useContext, useState, useRef, useEffect } from 'react';
import { FiltersContext } from '../../context/FiltersContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';
import { FilterProvider, FilterContext } from '../../context/FilterContext';
import { useCollapse } from '../../context/CollapseContext';
import InsuranceFilters from './components/InsuranceFilters'; // Assuming the dynamic filter component is named InsuranceFilters
import SmallPopup from './components/components/SmallPopup';
import SearchIconURL from "../../icons/search.png";
import SearchButtonDropDown from '../../components/SearchButtonDropDown';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './NeedsFilter.css';

const NeedsFilterComponent = ({ insuranceType, setInsuranceType }) => {
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const { globalFilters } = useContext(FiltersContext);
  const { isCollapsed } = useCollapse();
  const [popupContent, setPopupContent] = useState(null);
  const [popupTitle, setPopupTitle] = useState('');
  const [activeItem, setActiveItem] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const { filters, setFilters } = useContext(FilterContext);
  const itemRefs = useRef([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    
    setPopupContent(false);
    setShowDropdown(!showDropdown);
    setShowFullScreenPopup(false);
  };
  const [coverageItems, setCoverageItems] = useState([]);
  const [showFullScreenPopup, setShowFullScreenPopup] = useState(false);
  const [newItemData, setNewItemData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);

  const formatText = (text) => {
    if (typeof text !== 'string') {
      return ''; // or handle this case as appropriate for your application
    }

    let formattedText = text;

    // Split by '_' and take the second part if it exists
    const parts = text.split('_');
    if (parts.length > 1) {
      formattedText = parts[1];
    }

    // Capitalize the first letter and add spaces before uppercase letters
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
    formattedText = formattedText.replace(/([A-Z])/g, ' $1').trim();

    return formattedText;
  };

  useEffect(() => {
    let coverageKeys = [];

    if (masterPolicies[1] && masterPolicies[1].coverages) {
      const coverages = masterPolicies[1].coverages;
      setWeaknesses(Object.keys(masterPolicies[1].weaknesses).map(key => {
        return key.split('_')[1];
      }));
      setStrengths(Object.keys(masterPolicies[1].strengths).map(key => {
        return key.split('_')[1];
      }));
      Object.keys(coverages).forEach(key => {
        if (typeof coverages[key] === 'object') {
          Object.keys(coverages[key]).forEach(subKey => {
            coverageKeys.push({ key, label: subKey, checked: false, excess: [0, 1000], limit: [0, 1000] });
          });
        }
      });

      setCoverageItems(coverageKeys);
    }
  }, [masterPolicies]);

  const handleInsuranceTypeChange = (e) => {
    setInsuranceType(e.target.value);
  };

  const openPopup = (sections, title, itemIndex) => {
    setShowFullScreenPopup(false);
    const itemRect = itemRefs.current[itemIndex].getBoundingClientRect();
    
    if (popupContent?.length > 0 && popupContent[0] === sections[0]) {
      setPopupContent(null);
    } else {
      setPopupContent(sections);
    }
    setShowDropdown(false);
    setPopupTitle(title);
    setActiveItem(itemIndex);
    setPopupPosition({
      top: itemRect.bottom + window.scrollY,
      left: itemRect.left - 150
    });
  };

  const closePopup = () => {
    setShowDropdown(false);
    setPopupContent(null);
    setPopupTitle('');
    setActiveItem(null);
  };

  const addNewItem = () => {
    setShowFullScreenPopup(true);
  };

  const saveNewItem = () => {
    setFilters({
      ...filters,
      newItems: [...(filters.newItems || []), newItemData]
    });
    setShowFullScreenPopup(false);
    setNewItemData({});
  };

  const handleNewItemChange = (index, checked) => {
    const originalIndex = coverageItems.findIndex(item => item.label === filteredCoverageItems[index].label);
    
    const newCoverageItems = [...coverageItems];
    newCoverageItems[originalIndex].checked = checked;
    setCoverageItems(newCoverageItems);
  };

  const handleSliderChange = (index, type, values) => {
    const originalIndex = coverageItems.findIndex(item => item.label === filteredCoverageItems[index].label);
    
    const newCoverageItems = [...coverageItems];
    newCoverageItems[originalIndex][type] = values;
    setCoverageItems(newCoverageItems);
  };

  const handleInputChange = (index, type, value, idx) => {
    const originalIndex = coverageItems.findIndex(item => item.label === filteredCoverageItems[index].label);
    
    const newCoverageItems = [...coverageItems];
    newCoverageItems[originalIndex][type][idx] = value;
    if (type === 'excess' || type === 'limit') {
      newCoverageItems[originalIndex][type] = [
        Math.min(newCoverageItems[originalIndex][type][0], value),
        Math.max(newCoverageItems[originalIndex][type][1], value)
      ];
    }
    setCoverageItems(newCoverageItems);
  };

  const handleSelectAll = () => {
    
    const newCoverageItems = coverageItems.map(item => ({ ...item, checked: true }));
    setCoverageItems(newCoverageItems);
  };

  const handleClearAll = () => {
    
    const newCoverageItems = coverageItems.map(item => ({ ...item, checked: false }));
    setCoverageItems(newCoverageItems);
  };

  const formatLabel = (label) => {
    return label
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
      .replace(/_/g, ' ');
  };

  const filteredCoverageItems = coverageItems.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {insuranceType && (
        <div style={{width:'100%'}}>
          <div className="needs-filter">
            <div
              className={`needs-filter-item ${activeItem === 0 ? 'active' : ''}`}
              onClick={() => openPopup(['existingCover'], 'Property Details', 0)}
              ref={(el) => (itemRefs.current[0] = el)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><strong>Current Situation</strong></div>
                <div className="needs-filter-item-small">Scope, existing policy... </div>
              </div>
            </div>
            <div
              className={`needs-filter-item ${activeItem === 1 ? 'active' : ''}`}
              onClick={() => openPopup(['objectivesAndGoals'], 'Objectives & Goals', 1)}
              ref={(el) => (itemRefs.current[1] = el)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><strong>Objectives</strong></div>
                <div className="needs-filter-item-small">Goals, Preferences...</div>
              </div>
            </div>
            <div
              className={`needs-filter-item ${activeItem === 2 ? 'active' : ''}`}
              onClick={() => openPopup(['situation'], 'Situation', 2)}
              ref={(el) => (itemRefs.current[2] = el)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><strong>Situation</strong></div>
                <div className="needs-filter-item-small">What are we insuring?</div>
              </div>
            </div>
            <div
              className={`needs-filter-item ${activeItem === 3 ? 'active' : ''}`}
              onClick={() => {
                setActiveItem(3);
                setShowFullScreenPopup(!showFullScreenPopup);
                if (popupContent) {
                  setPopupContent(false);
                }
                setShowDropdown(false);
              }}
              ref={(el) => (itemRefs.current[3] = el)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><strong>Needs</strong></div>
                <div className="needs-filter-item-small">Coverage items...</div>
              </div>
            </div>
            <div
              className={`needs-filter-item ${activeItem === 4 ? 'active' : ''}`}
              onClick={() => openPopup(['modules'], 'Extras', 4)}
              ref={(el) => (itemRefs.current[4] = el)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><strong>Extras</strong></div>
                <div className="needs-filter-item-small">Optional extras...</div>
              </div>
            </div>
            <div
              className={`needs-filter-item ${activeItem === 5 ? 'active' : ''}`}
              onClick={() => {
                setActiveItem(5);
                toggleDropdown();
              }}>
              <div style={{ display: 'flex', minWidth: '160px', flexDirection: 'column' }}>
                <div><strong>Insurers</strong></div>
                <div className="needs-filter-item-small">Have a preference?</div>
              </div>
              {showDropdown && (
                <div onClick={(e) => e.stopPropagation()}>
                  <SearchButtonDropDown
                    onClose={toggleDropdown}
                    right={'0px'}
                    width={'400px'}
                    top={'110%'}
                  />
                </div>
              )}
            </div>
          </div>

          {popupContent && (
            <SmallPopup
              title={popupTitle}
              content={<InsuranceFilters filterSections={popupContent} />}
              onClose={closePopup}
              position={popupPosition}
            />
          )}
          {showFullScreenPopup && (
            <div className="full-screen-popup-content">
              <div className="full-screen-popup-header">
                <button
                  className="full-screen-popup-close"
                  onClick={() => setShowFullScreenPopup(false)}>&times;</button>
                <div className="search-bar-container">
                  <input
                    className="full-screen-popup-body-search"
                    type="text"
                    placeholder="Search for coverage items..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="clear-search-button" onClick={() => setSearchTerm('')}>Clear</button>
                </div>
              </div>
              <div className="full-screen-popup-body">
                <table className="needs-filter-table">
                  <thead>
                    <tr>
                      <th>Cover</th>
                      <th>Mandatory</th>
                      <th>Excess</th>
                      <th>Limit</th>
                      <th>Insights</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCoverageItems.map((item, index) => (
                      <tr key={index}>
                        <td style={{ cursor: 'pointer' }} onClick={() => handleNewItemChange(index, !item.checked)}>
                          {formatLabel(item.label)}
                        </td>
                        <td style={{ cursor: 'pointer' }} onClick={() => handleNewItemChange(index, !item.checked)}>
                          <input
                            type="checkbox"
                            name={`coverageItems.${index}.checked`}
                            checked={item.checked}
                            onChange={(e) => handleNewItemChange(index, e.target.checked)}
                          />
                        </td>
                        <td>
                          {item.checked && (
                            <div className="needs-slider-container">
                              <div className="needs-filter-slider">
                                <Slider
                                  range
                                  min={0}
                                  max={10000}
                                  value={item.excess}
                                  trackStyle={[{ backgroundColor: 'green' }]}
                                  handleStyle={[{ borderColor: 'green' }, { borderColor: 'green' }]}
                                  railStyle={{ backgroundColor: 'grey' }}
                                  onChange={(values) => handleSliderChange(index, 'excess', values)}
                                />
                              </div>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <div className="needs-filter-label-input">
                                  <div style={{ minWidth: '50px', textAlign: 'left' }}>Min $</div>
                                  <input
                                    type="number"
                                    min="0"
                                    value={item.excess[0]}
                                    onChange={(e) => handleInputChange(index, 'excess', Number(e.target.value), 0)}
                                    style={{ border: 'none', width: '60px', fontSize: '12pt' }}
                                  />
                                </div>
                                <div className="needs-filter-label-input">
                                  <div style={{ minWidth: '50px', textAlign: 'left' }}>Max $</div>
                                  <input
                                    type="number"
                                    min="0"
                                    value={item.excess[1]}
                                    onChange={(e) => handleInputChange(index, 'excess', Number(e.target.value), 1)}
                                    style={{ border: 'none', width: '60px', fontSize: '12pt' }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {item.checked && (
                            <div className="needs-slider-container">
                              <div className="needs-filter-slider">
                                <Slider
                                  range
                                  min={0}
                                  max={10000}
                                  value={item.limit}
                                  trackStyle={[{ backgroundColor: 'green' }]}
                                  handleStyle={[{ borderColor: 'green' }, { borderColor: 'green' }]}
                                  railStyle={{ backgroundColor: 'grey' }}
                                  onChange={(values) => handleSliderChange(index, 'limit', values)}
                                />
                              </div>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <div className="needs-filter-label-input">
                                  <div style={{ minWidth: '50px', textAlign: 'left' }}>Min $</div>
                                  <input
                                    type="number"
                                    min="0"
                                    value={item.limit[0]}
                                    onChange={(e) => handleInputChange(index, 'limit', Number(e.target.value), 0)}
                                    style={{ border: 'none', width: '60px', fontSize: '12pt' }}
                                  />
                                </div>
                                <div className="needs-filter-label-input">
                                  <div style={{ minWidth: '50px', textAlign: 'left' }}>Max $</div>
                                  <input
                                    type="number"
                                    min="0"
                                    value={item.limit[1]}
                                    onChange={(e) => handleInputChange(index, 'limit', Number(e.target.value), 1)}
                                    style={{ border: 'none', width: '60px', fontSize: '12pt' }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          {(strengths.includes(item.label)) && (
                            <div>Included by 50% of providers.</div>
                          )}
                          {(weaknesses.includes(item.label)) && (
                            <div>Included by 50% of providers.</div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="full-screen-popup-footer">
                <div className="popup-footer-buttons">
                  <button className="popup-footer-button-grey" onClick={handleSelectAll}>Select All</button>
                  <button className="popup-footer-button-grey" onClick={handleClearAll}>Clear All</button>
                </div>
                <div className="popup-footer-buttons">
                  <button className="popup-footer-button-grey" onClick={() => setShowFullScreenPopup(false)}>Cancel</button>
                  <button className="popup-footer-button-green" onClick={saveNewItem}>Save</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

function NeedsFilter() {
  const { policyType, setPolicyType } = useContext(PolicyTypeContext);

  return (
    <FilterProvider type={policyType}>
      <NeedsFilterComponent insuranceType={policyType} setInsuranceType={setPolicyType} />
    </FilterProvider>
  );
}

export default NeedsFilter;
