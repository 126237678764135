import React, {useState} from 'react';
import sampleImage0 from '../../images/0_search.png';
import sampleImage1 from '../../images/1_providers.png';
import sampleImage2 from '../../images/2_policies.png';
import sampleImage3 from '../../images/3_review.png';
import './ProductShowcase.css';

const AsymmetricalLayout = ({ title, sections }) => {
    return (
        <div className="asymmetrical-layout">
            <h1 className="layout-title">{title}</h1>
            {sections.map((section, index) => (
                <Section
                    key={index}
                    reverse={index % 2 !== 0}
                    image={section.image}
                    header={section.header}
                    subHeader={section.subHeader}
                    sentence={section.sentence}
                    bulletPoints={section.bulletPoints}
                    additionalContent={section.additionalContent}
                />
            ))}
        </div>
    );
};

const Section = ({ reverse, image, header, subHeader, sentence, bulletPoints, additionalContent }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className={`section ${reverse ? 'reverse' : ''}`}>
            <div className={`section-image ${reverse ? 'reverse-image' : ''}`}>
                <img src={image} alt={header} />
            </div>
            <div className={`section-text ${reverse ? 'reverse-text' : ''}`}>
                <h2>{header}</h2>
                <h3>{subHeader}</h3>
                <p>{sentence}</p>
                <ul>
                    {bulletPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
                <p
                    className="learn-more"
                    onClick={() => setExpanded(!expanded)}
                >
                    Learn more...
                </p>
                {expanded && <p className="additional-content">{additionalContent}</p>}
            </div>
        </div>
    );
};


const ProductShowcase = () => {
    const sections = [
        {
          image: sampleImage0,
          header: "Digital Needs Capture",
          subHeader: "Streamline the Client Onboarding Process",
          sentence: "Easily capture and digitize client needs, ensuring a comprehensive understanding of their requirements right from the start.",
          bulletPoints: [
            "Automated data entry and client profiling",
            "Customized forms tailored to client needs",
            "Instant data validation and feedback",
            "Secure and compliant information handling"
          ],
          additionalContent: "With our Digital Needs Capture feature, brokers can efficiently gather detailed client information, reducing manual data entry errors and speeding up the onboarding process. This ensures a more personalized and accurate policy selection tailored to the specific needs of each client.",
        },
        {
          image: sampleImage1,
          header: "Find the Right Policy",
          subHeader: "AI-Driven Policy Matching",
          sentence: "Leverage the power of AI to match your clients with the best policies based on their unique needs.",
          bulletPoints: [
            "Automated policy matching using AI algorithms",
            "Tailor policy recommendations to client needs and preferences",
            "Continuously improve matching accuracy over time",
            "Quickly identify the best-fit policies for each client"
          ],
          additionalContent: "Our AI-powered Policy Matching tool allows brokers to efficiently align client needs with the most suitable policy options. By analyzing coverage, exclusions, and client priorities, it ensures brokers offer the most relevant and competitive policies, enhancing client satisfaction and reducing manual workload.",
        },
        {
          image: sampleImage2,
          header: "Policy Comparison",
          subHeader: "Effortless Side-by-Side Comparisons",
          sentence: "Compare policies with ease, highlighting key differences to help clients make informed decisions.",
          bulletPoints: [
            "Side-by-side comparison of multiple policies",
            "Highlight differences in coverage, premiums, and terms",
            "Visualize the best options for client needs",
            "Quickly filter and sort policies based on criteria"
          ],
          additionalContent: "Our Policy Comparison tool empowers brokers to present clients with clear, concise comparisons, ensuring that they understand the nuances of each policy. This feature aids in building trust and transparency, leading to more confident decision-making.",
        },
        {
          image: sampleImage3,
          header: "Client Communication",
          subHeader: "Seamless and Compliant Communication",
          sentence: "Enhance client interactions with streamlined communication tools that keep everything compliant and on record.",
          bulletPoints: [
            "Automated communication templates for proposals and updates",
            "Track all client interactions in one place",
            "Compliant messaging that meets industry regulations",
            "Personalize communication to strengthen client relationships"
          ],
          additionalContent: "With Client Communication, brokers can manage all client interactions through a single platform, ensuring that all communications are compliant and easily accessible. This feature helps in maintaining strong client relationships by providing timely and relevant updates, making clients feel valued and informed.",
        }
      ];
    
      return (
        <AsymmetricalLayout title="A fast, accurate and compliant process" sections={sections} />
      );
};

export default ProductShowcase;
