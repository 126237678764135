import React, { useState, useContext, useEffect } from 'react';
import './StrengthsAndWeaknesses.css';
import { useGlobalContext } from '../../context/GlobalContext';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { PolicyTypeContext } from '../../context/PolicyTypeContext';

const Conditions = () => {
  const { activeDocument } = useGlobalContext();
  const [attributes, setAttributes] = useState({});
  const [tableKey, setTableKey] = useState(0); // State to track table key
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const {policyType} = useContext(PolicyTypeContext);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => setShowMore(!showMore);

  const fetchPolicies = async () => {
    if (activeDocument !== null && activeDocument !== undefined && activeDocument.policyProvider) {
      const activeFileName = activeDocument.policyProvider.includes('AA') ? 'AA' : activeDocument.policyProvider;

      if (masterPolicies && masterPolicies.values) {
        for (let entry of masterPolicies.values()) {
          if (entry.provider === activeFileName && entry.type === policyType) {
            setAttributes(entry.conditions);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (activeDocument !== null && activeDocument !== undefined) {
      fetchPolicies();
      setTableKey(prevKey => prevKey + 1); // Update table key
    }
  }, [activeDocument]);


  const formatText = (text) => {
    // Step 1: Split the string at the underscore and take the second part
    let formattedText = text.split('_')[1];

    // Step 2: Capitalize the first letter
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);

    // Step 3: Add a space before each uppercase letter except the first one
    formattedText = formattedText.replace(/([A-Z])/g, ' $1').trim();

    return formattedText;
  }


  function formatCoverageName(coverageName) {
    // Add a space before any uppercase letters that follow a lowercase letter
    let withSpaces = coverageName.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
    withSpaces = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

    // Correct the capitalization for specific words
    return withSpaces.split(' ').map(word => {
      const lowerWord = word.toLowerCase();
      return (lowerWord === 'and' || lowerWord === 'or') ? lowerWord : word;
    }).join(' ');
  }

  return (
    <>
      {attributes && Object.keys(attributes).length > 0 && (
        <div className="strengths-container" >
          <div className='strengths-container-div'>
            <div className="strengths-container-header">
              {Object.keys(attributes).length}+ conditions to be aware of*
            </div>
            <table className="strengths-container-table" key={tableKey}>
              <tbody key={tableKey}>
                {attributes && Object.keys(attributes)
                  .slice(0, showMore ? undefined : 8)
                  .map((key, index) => (
                  <tr key={index}>
                    <td style={{fontWeight:'500',backgroundColor:'white',verticalAlign:'top'}} className="strengths-container-table-name">{formatCoverageName(key)}</td>
                    <td className="strengths-container-table-name">{attributes[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {Object.keys(attributes).length > 8 && (
              <div className="conditions-show-more " onClick={toggleShowMore}>
                {showMore ? 'Show less...' : 'Show more...'}
              </div>
            )}
            <div className="strengths-container-footer">
              * Consult your policy wording for all conditions and terms.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Conditions;
