import React, { useState,useEffect } from "react";
import './Feedback.css';

function Feedback() {
  useEffect(() => {
      document.title = 'Feedback';
    }, []);

  const [thankYou, setThankYou] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    product: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    
    setThankYou(true);
  };

  return (
    <div className="feedback-container">
      <h2>What do you think? </h2>
      <div className="feedback-description">We're building every day, making Coverage better than ever.
        But... we want to level up :) So share your ideas and be part of what makes Coverage powerful.
      </div>
      <form onSubmit={handleSubmit} className="feedback-form">
        <div className="feedback-form-group">
          {!thankYou && (<div>
            <label className="feedback-form-title">Which product?</label>
            
              <select  className="feedback-input-field" value={formData.product} style={{ 'font-size': '14pt' }}>
                <option value="General">General</option>
                <option value="Chat" >Chat</option>
                <option value="Compare" >Visualiser</option>
                <option value="Compare" >Compare</option>
                <option value="Insights" >Insights</option>
                <option value="Insights" >Score</option>
                <option value="Checklist" >Checklist</option>
                <option value="Insights" >Other</option>
              </select>
            <input
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="feedback-input-field"
              style={{ 'font-size': '14pt' }}
            />
            <input
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="feedback-input-field"
              style={{ 'font-size': '14pt' }}
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Feedback"
              className="feedback-input-field-textarea"
              rows="4"
            ></textarea>
            <div className="feedback-form-footer">
              <button type="submit" className="feedback-submit-button">
                Submit
              </button>
            </div>
          </div>)}
          {thankYou && (<div className="feedback-thankyou">Thank you!</div>)}
        </div>
      </form>
    </div >
  );
}

export default Feedback;

