import React, { useState, useContext, useEffect, useRef } from 'react';
import { MasterPoliciesContext } from '../../context/MasterPoliciesContext';
import { useLocation } from 'react-router-dom';
import fileIconURL from "../../icons/file.png";
import { formatString, formatPolicyType } from '../../utils/utils';
import './PolicyLibrary.css';

const PolicyLibrary = () => {
  const { masterPolicies } = useContext(MasterPoliciesContext);
  const location = useLocation();
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [excessFilter, setExcessFilter] = useState('');
  const [limitFilter, setLimitFilter] = useState('');
  const [includedFilter, setIncludedFilter] = useState(false);
  const [recentlyAddedFilter, setRecentlyAddedFilter] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [providerFilter, setProviderFilter] = useState([...new Set(masterPolicies.map((policy) => policy.provider))]);
  const [statusFilter, setStatusFilter] = useState(true);
  const [typeFilter, setTypeFilter] = useState([...new Set(masterPolicies.map((policy) => policy.type))]);
  const [underwriterFilter, setUnderwriterFilter] = useState([...new Set(masterPolicies.map((policy) => policy.underwriter))]);
  const [reviewDateFilter, setReviewDateFilter] = useState(['<1 month', '<6 months', '<12 months']);
  const [expandedRows, setExpandedRows] = useState([]);
  const [dropdownsVisible, setDropdownsVisible] = useState({
    provider: false,
    status: false,
    type: false,
    underwriter: false,
    reviewDate: false,
  });
  const [showCallout, setShowCallout] = useState(false);
  const toggleCallout = () => {
    setShowCallout(!showCallout);
  };
  const providerRef = useRef(null);
  const statusRef = useRef(null);
  const typeRef = useRef(null);
  const underwriterRef = useRef(null);
  const reviewDateRef = useRef(null);

  const toggleRowExpansion = (idx) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(idx)
        ? prevExpandedRows.filter((rowIndex) => rowIndex !== idx) // Collapse the row
        : [...prevExpandedRows, idx] // Expand the row
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('recentlyAdded')) {
      setRecentlyAddedFilter(true);
    }
  }, [location.search]);

  const handleRowClick = (policy) => {
    setSelectedPolicy(policy);
    document.body.classList.add('modal-open'); // Add class to body to prevent background scroll

  };

  const handleClosePopup = () => {
    setSelectedPolicy(null);
    document.body.classList.remove('modal-open'); // Remove class when popup is closed
  };

  const handleBackgroundClick = (e) => {
    if (e.target.className === 'policy-summary-list-popup-container') {
      handleClosePopup();
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleProviderChange = (provider) => {
    setProviderFilter((prev) =>
      prev.includes(provider)
        ? prev.filter((p) => p !== provider)
        : [...prev, provider]
    );
  };

  const handleTypeChange = (type) => {
    setTypeFilter((prev) =>
      prev.includes(type)
        ? prev.filter((t) => t !== type)
        : [...prev, type]
    );
  };

  const handleUnderwriterChange = (underwriter) => {
    setUnderwriterFilter((prev) =>
      prev.includes(underwriter)
        ? prev.filter((u) => u !== underwriter)
        : [...prev, underwriter]
    );
  };

  const handleReviewDateChange = (range) => {
    setReviewDateFilter((prev) =>
      prev.includes(range)
        ? prev.filter((r) => r !== range)
        : [...prev, range]
    );
  };

  const selectAllFilters = () => {
    setProviderFilter([...new Set(masterPolicies.map((policy) => policy.provider))]);
    setStatusFilter(true);
    setTypeFilter([...new Set(masterPolicies.map((policy) => policy.type))]);
    setUnderwriterFilter([...new Set(masterPolicies.map((policy) => policy.underwriter))]);
    setReviewDateFilter(['<1 month', '<6 months', '<12 months']);
  };

  const clearAllFilters = () => {
    setProviderFilter([]);
    setStatusFilter(false);
    setTypeFilter([]);
    setUnderwriterFilter([]);
    setReviewDateFilter([]);
  };

  const filteredPolicies = React.useMemo(() => {
    return masterPolicies.filter((policy) => {
      const matchesProvider = providerFilter.length === 0 || providerFilter.includes(policy.provider);
      const matchesStatus = !statusFilter || policy.status === 'active';
      const matchesType = typeFilter.length === 0 || typeFilter.includes(policy.type);
      const matchesUnderwriter = underwriterFilter.length === 0 || underwriterFilter.includes(policy.underwriter);
      const matchesReviewDate =
        reviewDateFilter.length === 0 ||
        reviewDateFilter.some((range) => {
          const now = new Date();
          const reviewDate = new Date(policy.lastReviewedDate);
          switch (range) {
            case '<1 month':
              return now - reviewDate < 30 * 24 * 60 * 60 * 1000;
            case '<6 months':
              return now - reviewDate < 6 * 30 * 24 * 60 * 60 * 1000;
            case '<12 months':
              return now - reviewDate < 12 * 30 * 24 * 60 * 60 * 1000;
            default:
              return true;
          }
        });
      const matchesRecentlyAdded = !recentlyAddedFilter || new Date(policy.effectiveDate) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      return matchesProvider && matchesStatus && matchesType && matchesUnderwriter && matchesReviewDate && matchesRecentlyAdded;
    });
  }, [masterPolicies, providerFilter, statusFilter, typeFilter, underwriterFilter, reviewDateFilter, recentlyAddedFilter]);

  const sortedPolicies = React.useMemo(() => {
    if (!sortConfig.key) return filteredPolicies;

    const sorted = [...filteredPolicies].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [filteredPolicies, sortConfig]);

  const filteredCoverages = () => {
    const coverages = selectedPolicy ? Object.entries(selectedPolicy.coverages) : [];
    return coverages.reduce((acc, [key, value]) => {
      const filteredItems = Object.entries(value).filter(([itemKey, itemValue]) => {
        const matchesSearch = itemKey.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesExcess = !excessFilter ||
          (excessFilter === '<$500' && itemValue.excess < 500) ||
          (excessFilter === '$500-1000' && itemValue.excess >= 500 && itemValue.excess <= 1000) ||
          (excessFilter === '$1000-5000' && itemValue.excess > 1000 && itemValue.excess <= 5000) ||
          (excessFilter === 'over $5000' && itemValue.excess > 5000);
        const matchesLimit = !limitFilter ||
          (limitFilter === '<$100k' && itemValue.limit < 100000) ||
          (limitFilter === '$100k-500k' && itemValue.limit >= 100000 && itemValue.limit <= 500000) ||
          (limitFilter === '$500k-1m' && itemValue.limit > 500000 && itemValue.limit <= 1000000) ||
          (limitFilter === 'over $1m' && itemValue.limit > 1000000);
        const matchesIncluded = !includedFilter || itemValue.included;

        return matchesSearch && matchesExcess && matchesLimit && matchesIncluded;
      });

      if (filteredItems.length > 0) {
        acc[key] = filteredItems;
      }

      return acc;
    }, {});
  };

  const uniqueProviders = [...new Set(masterPolicies.map((policy) => policy.provider))];
  const uniqueTypes = [...new Set(masterPolicies.map((policy) => policy.type))];
  const uniqueUnderwriters = [...new Set(masterPolicies.map((policy) => policy.underwriter))];

  const handleDropdownClick = (key, event) => {
    event.stopPropagation();
    setDropdownsVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleClickOutside = (event) => {
    if (
      providerRef.current && !providerRef.current.contains(event.target) &&
      statusRef.current && !statusRef.current.contains(event.target) &&
      typeRef.current && !typeRef.current.contains(event.target) &&
      underwriterRef.current && !underwriterRef.current.contains(event.target) &&
      reviewDateRef.current && !reviewDateRef.current.contains(event.target)
    ) {
      setDropdownsVisible({
        provider: false,
        status: false,
        type: false,
        underwriter: false,
        reviewDate: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDownload = (filename, type, provider) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}users/api/policy-download/${encodeURIComponent(filename)}?type=${encodeURIComponent(type)}&provider=${encodeURIComponent(provider)}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      })
      .catch(error => {
        console.error('Error during file download:', error);
      });
  };

  const renderPolicyType = (type) => {
    switch (type) {
      case 'home':
        return <><span className="icon home-icon">🏠</span></>;
      case 'landlord':
        return <><span className="icon home-icon">🏠</span></>;
      case 'contents':
        return <><span className="icon home-icon">🖼</span></>;
      case 'isr':
        return <><span className="icon home-icon">🏬</span></>;
      case 'home-contents':
        return <><span className="icon home-icon">🏠</span></>;
      case 'motor':
        return <><span className="icon life-icon">🚗</span></>;
      case 'health':
        return <><span className="icon life-icon">💊</span></>;
      case 'life':
        return <><span className="icon life-icon">💖</span></>;
      case 'travel':
        return <><span className="icon life-icon">🛫</span></>;
      case 'pet':
        return <><span className="icon life-icon">🐶</span></>;
      case 'business':
        return <><span className="icon life-icon">🏬</span></>;
      case 'professional':
        return <><span className="icon life-icon">👩‍💼</span></>;
      default:
        return '';
    }
  };

  return (
    <div className="policy-summary-list">
      <div className="policy-summary-list-header">
        <div className="policy-summary-list-header-title">Policy Library</div>
      </div>
      {selectedPolicy && (
        <div className="policy-summary-list-popup-container" onClick={handleBackgroundClick}>
          <div className="policy-summary-list-popup-content">
            <div className="policy-summary-list-close-button" onClick={handleClosePopup}>&times;</div>
            <div className="policy-summary-list-popup-header">
              {selectedPolicy.provider} {selectedPolicy.name}
            </div>
            <table className="policy-summary-table">
              <thead>
                <tr>
                  <th>Provider</th>
                  <th>Version</th>
                  <th >Type</th>
                  <th>Last Reviewed Date</th>
                  <th>Effective Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedPolicy.provider}</td>
                  <td>{selectedPolicy.version}</td>
                  <td>{selectedPolicy.type}</td>
                  <td>{new Date(selectedPolicy.lastReviewedDate).toLocaleDateString()}</td>
                  <td>{new Date(selectedPolicy.effectiveDate).toLocaleDateString()}</td>
                </tr>
              </tbody>
            </table>
            <div className="policy-summary-list-filter-bar">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select onChange={(e) => setExcessFilter(e.target.value)}>
                <option value="">Excess</option>
                <option value="<$500">Less than $500</option>
                <option value="$500-1000">$500-$1000</option>
                <option value="$1000-5000">$1000-$5000</option>
                <option value="over $5000">Over $5000</option>
              </select>
              <select onChange={(e) => setLimitFilter(e.target.value)}>
                <option value="">Limit</option>
                <option value="<$100k">Less than $100k</option>
                <option value="$100k-500k">$100k-$500k</option>
                <option value="$500k-1m">$500k-$1m</option>
                <option value="over $1m">Over $1m</option>
              </select>
              <label>
                Included
                <input
                  type="checkbox"
                  checked={includedFilter}
                  onChange={() => setIncludedFilter(!includedFilter)}
                />
              </label>
            </div>
            <div className="policy-summary-list-policy-details">
              <table className="policy-summary-table-details">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Item</th>
                    <th>Included</th>
                    <th>Optional</th>
                    <th>Excess</th>
                    <th>Limit</th>
                    <th>Conditions</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(filteredCoverages()).map(([category, items]) => (
                    items.map(([itemKey, itemValue], idx) => (
                      <tr key={idx}
                        className={expandedRows.includes(idx) ? 'expanded' : 'collapsed'}
                        onClick={() => toggleRowExpansion(idx)}>
                        <td className="align-left">{formatString(category)}</td>
                        <td className="align-left">{formatString(itemKey)}</td>
                        <td>{itemValue.included.toString() === 'true' ? '✔' : 'X'}</td>
                        <td>{itemValue.optional.toString() === 'true' ? '✔' : ''}</td>
                        <td>{itemValue.excess}</td>
                        <td>{itemValue.limit}</td>
                        <td className="align-left">{itemValue.conditions}</td>
                        <td className="align-left">{itemValue.reason}</td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="policy-summary-list-filters">
        <div className={`${recentlyAddedFilter ? 'recently-added' : 'filter-item'} `}>
          <div className="policy-summary-list-filter-item-label"
            onClick={() => setRecentlyAddedFilter(!recentlyAddedFilter)}>
            Recently Added
          </div>
        </div>
        <div className="filter-item" ref={providerRef} >
          <div className="policy-summary-list-filter-item-label" onClick={(e) => handleDropdownClick('provider', e)}>
            Providers ({providerFilter.length}/{uniqueProviders.length})
          </div>
          {dropdownsVisible.provider && (
            <div className="policy-summary-list-dropdown">
              {uniqueProviders.map((provider) => (
                <div className="policy-summary-list-dropdown-label" key={provider} onClick={() => handleProviderChange(provider)}>
                  <input type="checkbox" checked={providerFilter.includes(provider)} onChange={(e) => e.stopPropagation()} />
                  {provider}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="filter-item" style={{ flex: '0.2', minWidth: '80px' }} ref={statusRef}>
          <div className="policy-summary-list-filter-item-label" onClick={() => setStatusFilter(!statusFilter)}>
            <input type="checkbox" checked={statusFilter} />
            Active
          </div>
        </div>
        <div className="filter-item" ref={typeRef} >
          <div className="policy-summary-list-filter-item-label" onClick={(e) => handleDropdownClick('type', e)}>
            Types ({typeFilter.length}/{uniqueTypes.length})
          </div>
          {dropdownsVisible.type && (
            <div className="policy-summary-list-dropdown">
              {uniqueTypes.map((type) => (
                <div className="policy-summary-list-dropdown-label" key={type} onClick={() => handleTypeChange(type)}>
                  <input type="checkbox" checked={typeFilter.includes(type)} onChange={(e) => e.stopPropagation()} />
                  {formatPolicyType(type)}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="filter-item" ref={underwriterRef} >
          <div className="policy-summary-list-filter-item-label" onClick={(e) => handleDropdownClick('underwriter', e)}>
            Underwriters ({underwriterFilter.length}/{uniqueUnderwriters.length})
          </div>
          {dropdownsVisible.underwriter && (
            <div className="policy-summary-list-dropdown">
              {uniqueUnderwriters.map((underwriter) => (
                <div className="policy-summary-list-dropdown-label" key={underwriter} onClick={() => handleUnderwriterChange(underwriter)}>
                  <input type="checkbox" checked={underwriterFilter.includes(underwriter)} onChange={(e) => e.stopPropagation()} />
                  {underwriter}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="filter-item" ref={reviewDateRef} >
          <div className="policy-summary-list-filter-item-label" onClick={(e) => handleDropdownClick('reviewDate', e)}>
            Last Reviewed
          </div>
          {dropdownsVisible.reviewDate && (
            <div className="policy-summary-list-dropdown">
              {['<1 month', '<6 months', '<12 months'].map((range) => (
                <div className="policy-summary-list-dropdown-label" key={range} onClick={() => handleReviewDateChange(range)}>
                  <input type="checkbox" checked={reviewDateFilter.includes(range)} onChange={(e) => e.stopPropagation()} />
                  {range}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="filter-item" style={{ flex: '0.2', minWidth: '75px', backgroundColor: 'lightgray', color: 'grey' }}>
          <div className="policy-summary-list-filter-item-label" onClick={selectAllFilters}>
            Select all
          </div>
        </div>
        <div className="filter-item" style={{ flex: '0.2', minWidth: '75px', backgroundColor: 'lightgray', color: 'grey' }}>
          <div className="policy-summary-list-filter-item-label" onClick={clearAllFilters}>
            Clear all
          </div>
        </div>
        <div className="filter-item" style={{ flex: '0.3', minWidth: '100px', backgroundColor: 'rgb(240, 240, 240)', color: 'grey' }}>
          <div className="policy-summary-list-filter-item-label" onClick={toggleCallout} >
            Add Policy
          </div>
          {showCallout && (
            <div className="info-callout" style={{ width: '200px', top: '105%', left: '12px' }}
              onClick={toggleCallout}>
              <div className="callout-text">
                Feature coming soon
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="policy-summary-scroll">
        <table className="policy-summary-list-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('provider')}>Provider</th>
              <th onClick={() => handleSort('name')}>Name</th>
              <th onClick={() => handleSort('type')}>Type</th>
              <th onClick={() => handleSort('status')}>Status</th>
              <th onClick={() => handleSort('fileName')} className="wide">File</th>
              <th onClick={() => handleSort('underwriter')}>Underwriter</th>
              <th onClick={() => handleSort('version')}>Version</th>
              <th onClick={() => handleSort('lastReviewedDate')}>Last Reviewed</th>
              <th onClick={() => handleSort('effectiveDate')}>Effective Date</th>
            </tr>
          </thead>
          <tbody>
            {sortedPolicies.map((policy, index) => (
              <tr key={index} >
                <td className="align-left" onClick={() => handleRowClick(policy)}>{policy.provider}</td>
                <td className="align-left" onClick={() => handleRowClick(policy)}>{policy.name}</td>
                <td style={{ textAlign: 'left' }} onClick={() => handleRowClick(policy)} >{renderPolicyType(policy.type)}&nbsp;{formatPolicyType(policy.type)}</td>
                <td onClick={() => handleRowClick(policy)}>{policy.status === 'active' ? 'Active' : 'Inactive'}</td>
                <td className="wide" onClick={() => handleDownload(policy.fileName, policy.type, policy.provider)}>
                  <div className="policy-summary-list-file">
                    <img style={{ width: '20px' }} src={fileIconURL} alt="" />
                    {policy.fileName}
                  </div>
                </td>
                <td onClick={() => handleRowClick(policy)}>{policy.underwriter}</td>
                <td onClick={() => handleRowClick(policy)}>{policy.version}</td>
                <td>{new Date(policy.lastReviewedDate).toLocaleDateString()}</td>
                <td>{new Date(policy.effectiveDate).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PolicyLibrary;
