import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const ProfessionalContext = createContext();

export const ProfessionalProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial professional insurance state
  const initialProfessionalInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        protectAgainstClaims: false,
        mitigateRisk: false,
        ensureCoverage: false,
      },
      goals: {
        errorsOmissions: false,
        negligenceCoverage: false,
        professionalIndemnity: false,
      },
    },
    situation: {
      professionalDetails: {
        profession: '',
        yearsExperience: 0,
      },
      liabilityRisks: {
        clientDisputes: false,
        serviceErrors: false,
        professionalMisconduct: false,
      },
    },
    modules: {
      options: {
        retroactiveCoverage: false,
        lossMitigation: false,
        disciplinaryProceedings: false,
      },
    },
  };

  // Initialize filters state with the initialProfessionalInsuranceState
  const [filters, setFilters] = useState(initialProfessionalInsuranceState);

  return (
    <ProfessionalContext.Provider value={{ filters, setFilters }}>
      {children}
    </ProfessionalContext.Provider>
  );
};
