import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CountUp from 'react-countup';

const CircularProgressCard = ({ title, totalCommission, goal, color = '#4CAF50' }) => {
  const percentage = (totalCommission / goal) * 100;
  const formattedTotalCommission = `$${totalCommission.toLocaleString()}`;

  return (
    <div className="commissions-metric-card" style={{ maxWidth: '250px' }}>
      <h3 style={{ paddingBottom: "15px" }}>{title}</h3>
      <CircularProgressbar
        value={percentage}
        text={formattedTotalCommission}
        styles={{
          path: {
            // Path color
            stroke: color,
          },
          trail: {
            // Trail color (unused part of the progress bar)
            stroke: '#d6d6d6',
          },
          text: {
            // Text color
            fill: color,
            fontSize: '16px',
          }
        }}
      />
      <p style={{ fontSize: "16pt" }}>
        Goal:
        <CountUp start={0} end={goal} duration={2.5} separator="," prefix="$" />
      </p>
    </div>
  );
};

export default CircularProgressCard;