import React from 'react';
import { Pie } from 'react-chartjs-2';
import './ClientSegmentationChart.css';

const ClientSegmentationChart = ({ segmentationData }) => {
  const data = {
    labels: segmentationData.map(item => item.label),
    datasets: [{
      label: 'Client Segmentation',
      data: segmentationData.map(item => item.value),
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        // Add more colors for each segment
      ],
      hoverOffset: 4
    }]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom' // Setting the legend position to bottom
      }
    }
  };

  return (
    <div className="client-segmentation-chart">
      <Pie data={data} options={options} />
    </div>
  );
};

export default ClientSegmentationChart;
