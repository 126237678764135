import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import DocumentPreview from './DocumentPreview';

const DocumentPreviewWrapper = React.memo((props) => {
    const [data, setData] = useState(props);

    useEffect(() => {
        // Update the data state only if props have changed and are different from the current data
        if (JSON.stringify(props) !== JSON.stringify(data) && props && data) {
            setData(props);
        }
    }, [props, data]);

    return (
        <PDFViewer width="100%" height="600">
            <DocumentPreview {...data} />
        </PDFViewer>
    );
}, (prevProps, nextProps) => {
    // Custom comparison function to determine if re-render is necessary
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

export default DocumentPreviewWrapper;
