import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const PetContext = createContext();

export const PetProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial pet insurance state
  const initialPetInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        preventiveCare: false, // Objective for preventive care
        accidentCoverage: false, // Objective for accident coverage
        illnessCoverage: false, // Objective for illness coverage
      },
      goals: {
        coverageAmount: 0, // Goal for coverage amount in currency
        policyTerm: 0, // Goal for policy term in years
      },
    },
    situation: {
      petDetails: {
        name: '',
        age: 0,
        breed: '',
        gender: '',
      },
      healthHistory: {
        preExistingConditions: [], // Array of pre-existing conditions
        currentMedications: '', // Current medications
        allergies: [], // Array of allergies
      },
      lifestyle: {
        indoorPet: false, // Whether the pet is kept indoors
        outdoorPet: false, // Whether the pet is kept outdoors
        activePet: false, // Activity level of the pet
      },
    },
    modules: {
      options: {
        wellnessCoverage: false, // Option for wellness coverage
        dentalCoverage: false, // Option for dental coverage
        hereditaryConditionsCoverage: false, // Option for hereditary conditions coverage
        alternativeTherapiesCoverage: false, // Option for alternative therapies coverage
      },
    },
  };

  // Initialize filters state with the initialPetInsuranceState
  const [filters, setFilters] = useState(initialPetInsuranceState);

  return (
    <PetContext.Provider value={{ filters, setFilters }}>
      {children}
    </PetContext.Provider>
  );
};
