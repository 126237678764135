import React, { createContext, useState } from 'react';
import { useGeneralAndExistingCover } from './GeneralContext'; // Import the custom hook

export const HealthContext = createContext();

export const HealthProvider = ({ children }) => {
  // Call the custom hook inside the component where hooks are allowed
  const { general, existingCover } = useGeneralAndExistingCover();

  // Define the initial health insurance state
  const initialHealthInsuranceState = {
    general,         // Use imported `general`
    existingCover,   // Use imported `existingCover`
    objectivesAndGoals: {
      objectives: {
        preventiveCare: false, // Objective for preventive care coverage
        comprehensiveCoverage: false, // Objective for comprehensive health coverage
        costSavings: false, // Objective for cost savings
      },
      goals: {
        coverageAmount: 0, // Goal for coverage amount in currency
        policyTerm: 0, // Goal for policy term in years
      },
    },
    situation: {
      personalDetails: {
        name: '',
        gender: '',
        dateOfBirth: '',
        occupation: '',
      },
      medicalHistory: {
        preExistingConditions: [], // Array of pre-existing conditions
        currentMedications: '', // Current medications
        allergies: [], // Array of allergies
      },
      lifestyle: {
        smokingStatus: false, // Smoking status
        alcoholConsumption: false, // Alcohol consumption
        exerciseFrequency: '', // Exercise frequency
      },
    },
    modules: {
      options: {
        dentalCoverage: false, // Dental coverage option
        visionCoverage: false, // Vision coverage option
        mentalHealthCoverage: false, // Mental health coverage option
        maternityCoverage: false, // Maternity coverage option
        telehealthServices: false, // Telehealth services option
        wellnessPrograms: false, // Wellness programs option
      },
    },
  };

  // Initialize filters state with the initialHealthInsuranceState
  const [filters, setFilters] = useState(initialHealthInsuranceState);

  return (
    <HealthContext.Provider value={{ filters, setFilters }}>
      {children}
    </HealthContext.Provider>
  );
};
