import React from 'react';
import { Line } from 'react-chartjs-2';
import './ProfitabilityProgress.css';

const ProfitabilityProgress = ({ leadBatches }) => {
    const data = {
        labels: leadBatches.map(batch => batch.name),
        datasets: [
            {
                label: 'Profit',
                data: leadBatches.map(batch => batch.profit),
                fill: false,
                borderColor: 'rgb(83,166,88)',
                tension: 0.1
            },
            {
                label: 'Margin',
                data: leadBatches.map(batch => batch.margin),
                fill: false,
                borderColor: 'rgb(255, 99, 132)', // You can choose a different color
                tension: 0.1
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'  // Position the legend at the bottom
            }
        }
    };

    return (
        <div className="profitability-progress">
            <div className="profitability-chart-container">
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default ProfitabilityProgress;
