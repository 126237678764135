import React, { useState } from 'react';
import WaitList from "./../components/user_access/WaitList";
import ForgotPassword from "./../components/user_access/ForgotPassword";
import { usePopup } from './../context/PopupContext'; // Adjust the path as necessary
import './GetStartedBanner.css';

const GetStartedBanner = ({ banner = 'Get started today', height = 'calc(100vh / 1.8)' }) => {

  const {
    isLogInPopupOpen,
    setIsLogInPopupOpen,
    isRegisterPopupOpen,
    setIsRegisterPopupOpen,
    isForgotPasswordPopupOpen,
    setIsForgotPasswordPopupOpen,
    isWaitListPopupOpen,
    setIsWaitListPopupOpen
} = usePopup();

  const toggleWaitListPopup = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsWaitListPopupOpen(!isWaitListPopupOpen);
  };

  return (
    <div className="banner" style={{ height: height }} >
      {banner !== "" && (
        <h1>{banner}</h1>
      )}
      <div style={{ padding: '20px' }} >
        <button className="contact-us-button" onClick={toggleWaitListPopup}>Start Free Trial</button>

        <ForgotPassword isOpen={isForgotPasswordPopupOpen} onClose={() => setIsForgotPasswordPopupOpen(false)} resetStep={true} />
        <WaitList isOpen={isWaitListPopupOpen} onClose={() => setIsWaitListPopupOpen(false)} forgotPassword={() => setIsForgotPasswordPopupOpen(true)} />
      </div>
    </div>
  );
};

export default GetStartedBanner;
