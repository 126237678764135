import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useGlobalContext } from '../../context/GlobalContext';
import { formatString } from '../../utils/utils';
import './SpiderChart.css';
import './SpiderChartWithDropdown.css';

const SpiderChartWithDropdown = ({ data }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const tooltipRef = useRef(null);
    const { activeDocument } = useGlobalContext();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [showDatasetDropdown, setShowDatasetDropdown] = useState(false);
    const [chartDimensions, setChartDimensions] = useState({ width: 600, height: 600 });
    const colors = ['rgb(25, 107, 33)', 'rgb(107, 25, 33)', 'rgb(33, 25, 107)', 'rgb(107, 33, 25)', 'rgb(33, 107, 25)', 'rgb(25, 33, 107)', 'rgb(25, 107, 107)', 'rgb(107, 107, 25)'];
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const [datasetOrder, setDatasetOrder] = useState(0); // New state for ordering datasets

    // Ensure initial selection of two datasets and categories when data is available
    useEffect(() => {
        if (data.length > 0 && selectedDatasets.length === 0) {
            setSelectedDatasets(data.slice(0, 2).map((item, index) => ({
                provider: item.provider,
                color: colors[index % colors.length],
                order: index  // Initialize the order based on the index
            })));

            const coverages = Object.keys(data[0]?.coverages);
            setSelectedCategories(coverages?.slice(0, 5)); // Default to first 5 categories
        }
    }, [data]);

    // Initialize the tooltip
    useEffect(() => {
        if (!tooltipRef.current) {
            tooltipRef.current = d3.select('body').append('div')
                .attr('class', 'spider-tooltip')
                .style('opacity', 0)
        }
    }, []);

    const handleResize = () => {
        if (containerRef.current) {
            const { width } = containerRef.current.getBoundingClientRect();
            const newWidth = Math.max(600, Math.min(800, width));
            setChartDimensions({ width: newWidth, height: newWidth });
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const coverages = Object.keys(data[0]?.coverages);
            setSelectedCategories(coverages?.slice(0, 5));
        }
    }, [data]);

    useEffect(() => {
        if (selectedCategories?.length === 5) {
            const counts = selectedCategories.reduce((acc, category) => {
                acc[category] = data.reduce((sum, item) => {
                    if (item?.coverages && item?.coverages[category]) {
                        const subCategories = item?.coverages[category];
                        return sum + countIncludedTrue(subCategories);
                    }
                    return sum;
                }, 0);
                return acc;
            }, {});
            setCategoryCounts(counts);
        }
    }, [selectedCategories, data]);

    const countIncludedTrue = (obj) => {
        if (typeof obj !== 'object' || obj === null) return 0;
        return Object.entries(obj).reduce((count, [key, subItem]) => {
            if (subItem && typeof subItem === 'object' && 'included' in subItem && subItem.included === true) {
                count++;
            }
            return count + countIncludedTrue(subItem);
        }, 0);
    };

    const handleDataItemClick = (provider) => {
        setSelectedDatasets(currentData => {
            const updatedData = currentData.map(item => {
                if (item.provider === provider) {
                    return { ...item, color: colors[currentColorIndex] };
                }
                return item;
            });

            setCurrentColorIndex((currentColorIndex + 1) % colors.length);
            return updatedData;
        });
        drawSpiderChart();
    };

    const drawSpiderChart = () => {
        const svg = d3.select(chartRef.current);
        const viewBoxPadding = 60; // Increased padding for better view

        svg.selectAll("*").remove();

        // Adjust the chart dimensions to be 80% of the current width and height
        const adjustedWidth = chartDimensions.width * 0.8;
        const adjustedHeight = chartDimensions.height * 0.8;

        // Set the viewBox, with extra padding to allow for space for labels and circles
        svg.attr('viewBox', `0 0 ${(adjustedWidth + viewBoxPadding * 2)} ${(adjustedHeight + viewBoxPadding * 2)}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');

        const width = adjustedWidth;
        const height = adjustedHeight;
        const radius = Math.min(width, height) / 2;
        const innerRadius = 0.2 * radius;

        // Find the maximum value across all selected datasets and categories
        let maxValue = 0;
        selectedDatasets.forEach(({ provider }) => {
            const dataset = data.find(item => item?.provider === provider);
            selectedCategories.forEach(category => {
                const value = dataset?.coverages[category] ? countIncludedTrue(dataset?.coverages[category]) : 0;
                if (value > maxValue) maxValue = value;
            });
        });

        // Adjust the rScale to map the maximum value to the outer edge (radius) of the radar group
        const rScale = d3.scaleLinear()
            .domain([0, maxValue])
            .range([innerRadius, radius]);

        const angleSlice = Math.PI * 2 / selectedCategories.length;

        const radarLine = d3.lineRadial()
            .radius(d => rScale(d.value))
            .angle((d, i) => i * angleSlice);

        // Center the radar group by adjusting the translate based on the padding and dimensions
        const radarGroup = svg.append("g")
            .attr("transform", `translate(${(adjustedWidth / 2 + viewBoxPadding)}, ${(adjustedHeight / 2 + viewBoxPadding)})`);

        // Render datasets in current order
        const datasetsInOrder = [...selectedDatasets].sort((a, b) => a.order - b.order);
        datasetsInOrder.forEach(({ provider, color }) => {
            const dataset = data.find(item => item.provider === provider);

            // Ensure dataset and coverages are defined before accessing them
            if (dataset && dataset.coverages) {
                const chartData = selectedCategories.map((category) => ({
                    value: dataset.coverages[category] ? countIncludedTrue(dataset.coverages[category]) : 0
                }));

                radarGroup.append("path")
                    .datum(chartData)
                    .attr("d", radarLine)
                    .style("fill", color)
                    .style("stroke", 'white')
                    .style("fill-opacity", 0.6)
                    .style("stroke-width", 1);

                chartData.forEach((d, i) => {
                    const angle = i * angleSlice - Math.PI / 2;
                    const x = rScale(d.value) * Math.cos(angle);
                    const y = rScale(d.value) * Math.sin(angle);

                    const circle = radarGroup.append("circle")
                        .attr("r", 10)
                        .attr("cx", x)
                        .attr("cy", y)
                        .style("fill", color)
                        .on("click", () => handleDataItemClick(provider));

                    circle.on("mouseover", (event) => {
                        tooltipRef.current.transition().duration(200).style("opacity", 0.9);
                        tooltipRef.current.html(`Provider: <span style="color: ${color};"><span style="font-weight: normal;">${provider}</span></span><br>Value: <span style="font-weight: normal;">${d.value}</span>`)
                            .style("left", (event.pageX + 10) + "px")
                            .style("top", (event.pageY - 28) + "px");
                    }).on("mouseout", () => {
                        tooltipRef.current.transition().duration(500).style("opacity", 0);
                    });
                });
            }
        });

        const numAxes = selectedCategories.length;
        const axisRadius = radius;

        const axes = radarGroup.append("g").attr("class", "axes");

        axes.selectAll(".axis-label")
            .data(selectedCategories)
            .enter().append("text")
            .attr("class", "axis-label")
            .attr("x", (d, i) => (axisRadius + 40) * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("y", (d, i) => (axisRadius + 40) * Math.sin(angleSlice * i - Math.PI / 2))
            .style("font-size", "12pt")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .each(function (d) {
                const words = formatString(d).split(' ');
                const textElement = d3.select(this);
                words.forEach((word, index) => {
                    textElement.append('tspan')
                        .attr('x', textElement.attr('x'))
                        .attr('dy', index === 0 ? 0 : '1.2em')
                        .text(word);
                });
            });

        axes.selectAll(".axis")
            .data(d3.range(numAxes))
            .enter().append("line")
            .attr("class", "axis")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", (d, i) => axisRadius * Math.cos(angleSlice * i - Math.PI / 2))
            .attr("y2", (d, i) => axisRadius * Math.sin(angleSlice * i - Math.PI / 2))
            .style("stroke", "lightgrey")
            .style("stroke-width", "1px");



        // Add concentric circles (axis markers) with 5 circles
        const markerValues = d3.range(1, 6).map(d => (d / 5) * maxValue); // Generate 5 circles based on maxValue
        const markers = radarGroup.append("g").attr("class", "markers");

        markers.selectAll(".marker")
            .data(markerValues)
            .enter().append("circle")
            .attr("class", "marker")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", d => rScale(d))
            .style("fill", "none")
            .style("stroke", "lightgrey")
            .style("stroke-dasharray", "2");
    };



    useEffect(() => {
        if (selectedCategories.length === 5) {
            drawSpiderChart();
        }
    }, [categoryCounts, chartDimensions, selectedDatasets]);

    const handleCheckboxChange = (category) => {
        if (selectedCategories.includes(category)) {
            // If the clicked category is already selected, just uncheck it
            setSelectedCategories(selectedCategories.filter(c => c !== category));
        } else {
            if (selectedCategories.length < 5) {
                // If less than 5 categories are selected, simply add the new category
                setSelectedCategories([...selectedCategories, category]);
            } else {
                // If 5 categories are already selected, toggle off the first selected one and toggle on the new one
                setSelectedCategories([...selectedCategories.slice(1), category]);
            }
        }
    };
    
    const selectAllCategories = () => {
        setSelectedCategories(Object.keys(data[0]?.coverages)); 

    };

    const clearAllCategories = () => {
        setSelectedCategories([]); 
    };
    
    const selectAllDatasets = () => {
        const allProviders = data.map(dataset => dataset.provider);
        setSelectedDatasets(allProviders.map((provider, index) => ({ provider, color: colors[index % colors.length] })));
    };

    const clearAllDatasets = () => {
        setSelectedDatasets([]);
    };

    const handleDatasetChange = (provider) => {
        if (selectedDatasets.some(dataset => dataset.provider === provider)) {
            setSelectedDatasets(selectedDatasets.filter(item => item.provider !== provider));
        } else {
            const newDataset = { provider, color: colors[currentColorIndex] };
            setSelectedDatasets([...selectedDatasets, newDataset]);
            setCurrentColorIndex((currentColorIndex + 1) % colors.length);
        }
    };

    useEffect(() => {
        if (selectedCategories.length > 5) {
            setErrorMessage('You can only select 5 categories.');
        } else if (selectedCategories.length < 5 && selectedCategories.length > 0) {
            setErrorMessage('Please select at least 5 categories.');
        } else {
            setErrorMessage('');
        }
    }, [selectedCategories]);

    const toggleCategoryDropdown = () => {
        setShowDatasetDropdown(false);
        setShowCategoryDropdown(!showCategoryDropdown);
    };

    const toggleDatasetDropdown = () => {
        setShowCategoryDropdown(false);
        setShowDatasetDropdown(!showDatasetDropdown);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside both dropdowns
            if (
                headerRef.current &&
                !headerRef.current.contains(event.target)
            ) {
                setShowDatasetDropdown(false);
                setShowCategoryDropdown(false);
            }
        };

        // Add the event listener when dropdown is open
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatasetDropdown, showCategoryDropdown]); // Re-run if either dropdown state changes

    // New function to handle dataset re-ordering
    const handleReorder = () => {
        setDatasetOrder((prevOrder) => (prevOrder + 1) % selectedDatasets.length);

        const reorderedDatasets = selectedDatasets.map((dataset, index) => ({
            ...dataset,
            order: (dataset.order + 1) % selectedDatasets.length  // Increment the order and wrap using modulo
        }));

        setSelectedDatasets(reorderedDatasets);
    };

    useEffect(() => {
        drawSpiderChart();
    }, [selectedDatasets]);

    return (
        <div className="spider-container" ref={containerRef}>
            <div className='spider-container-title' ref={headerRef}>
                <div className="spider-container-header">
                    <span style={{ color: '#4fa658', fontWeight: 'bold' }}>{activeDocument !== undefined && activeDocument !== null ? `${activeDocument.policyProvider}` : ''}</span>&nbsp;coverage vs. other providers
                </div>
                <div className="spider-container-header">
                    <button className="dropdown-toggle-dataset" onClick={toggleCategoryDropdown}>
                        Select Categories
                    </button>
                    <button className="dropdown-toggle-dataset" onClick={toggleDatasetDropdown}>
                        Select Datasets
                    </button>
                    <button className="dropdown-toggle-dataset" onClick={handleReorder}>
                        Re-order
                    </button>
                </div>
                {showCategoryDropdown && (
                    <div className="spider-dropdown-menu">
                        <div className="spider-dropdown-menu-list">
                            {Object.keys(data[0]?.coverages).map((category, index) => (
                                <div className="spider-dropdown-menu-item" key={index}
                                    onClick={() => handleCheckboxChange(category)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCheckboxChange(category)}
                                        disabled={!selectedCategories.includes(category) && selectedCategories.length >= 5}
                                    />
                                    <label>{formatString(category)}</label>
                                </div>
                            ))}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                        <button onClick={selectAllCategories}>Select All</button>
                        <button onClick={clearAllCategories}>Clear All</button>
                    </div>
                )}
                {showDatasetDropdown && (
                    <div className="spider-dropdown-menu">
                        <div className="spider-dropdown-menu-list">
                            {data.map((dataset, index) => (
                                <div className="spider-dropdown-menu-item" key={index}
                                    onClick={() => handleDatasetChange(dataset.provider)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedDatasets.some(item => item.provider === dataset.provider)}
                                        onChange={() => handleDatasetChange(dataset.provider)}
                                    />
                                    <label>{dataset.provider}</label>
                                </div>
                            ))}
                        </div>
                        <button onClick={selectAllDatasets}>Select All</button>
                        <button onClick={clearAllDatasets}>Clear All</button>
                    </div>
                )}

            </div>

            <div className="spider-container-chart">
                <svg ref={chartRef} width={chartDimensions.width} height={chartDimensions.height}></svg>
            </div>
            <div className="spider-chart-legend">
                <div className="spider-chart-legend-item">
                    <span><strong>Score - </strong></span>The larger the score, the more coverage relative to other providers.
                </div>
            </div>
        </div>
    );
};

export default SpiderChartWithDropdown;
