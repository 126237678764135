import React, { useState } from 'react';
import './ExportModal.css'; // Reuse the same CSS as ExportModal
import './ImportModal.css'; // New CSS for ImportModal

const ImportModal = ({ isOpen, onClose }) => {
  const [fileName, setFileName] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null); // null, 'success', 'failure'

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validFormats = [
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'text/csv', // .csv
    ];

    setFileName(file.name);

    if (file && validFormats.includes(file.type)) {
      setFileName(file.name);
      // Simulate a file upload process
      uploadFile(file);
    } else {
      setUploadStatus('failure');
    }
  };

  const uploadFile = (file) => {
    // Simulate file upload success or failure
    setTimeout(() => {
      if (file.size > 0) { // Simulate successful upload based on file size
        setUploadStatus('success');
      } else {
        setUploadStatus('failure');
      }
    }, 1000); // Simulated delay for file upload
  };

  const handleDoneClick = () => {
    onClose();
  };

  const handleCancelClick = () => {
    setFileName('');
    setUploadStatus(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleCancelClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Import File</h3>
        <input
          type="file"
          className='modal-content-input'
          accept=".xls,.xlsx,.csv" // Accept .xls, .xlsx, and .csv files
          onChange={handleFileChange}
        />
        {fileName && (
          <p>
            {uploadStatus === 'success' ? (
              <span className="success-icon">✔</span>
            ) : uploadStatus === 'failure' ? (
              <span className="failure-icon">✘</span>
            ) : null}
            <span>{` Selected file: ${fileName}`}</span>
          </p>
        )}
        {/* TODO */}
        <span style={{color:'red'}}>Please note that this feautre is coming soon...</span>
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          {uploadStatus === 'success' ? (
            <button className="done-button" onClick={handleDoneClick}>Done</button>
          ) : (
            <button className="cancel-button" onClick={handleCancelClick}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
