import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './RecentNews.css';

const RecentNews = ({ newsItems }) => {
  const [filteredActions, setFilteredActions] = useState(newsItems || "");

  useEffect(() => {
    if (newsItems) {
      setFilteredActions(newsItems);
    }
  }, [newsItems]);

  const handleRemoveAction = (indexToRemove) => {
    setFilteredActions(filteredActions.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="recent-news">
      <div className="recent-news-title">Your Feed</div>
      <ul className="recent-news-list">
        {filteredActions.map((item, index) => (
          <li className="recent-news-item" key={item.title || index}>
            <div className='recent-news-item-header'>
              <h4>{item.title}</h4>
              <span className="remove-action" onClick={() => handleRemoveAction(index)}>&times;</span>
            </div>
            <p>{item.summary}</p>
            <div className="recent-news-links">
              {item.type == 'policy' ? (
                <Link className="recent-news-link"
                  to="/policy-library?recentlyAdded"
                  style={{ textDecoration: 'none' }}>View in Library</Link>
              ) : (
                <a className="recent-news-link" href={item.link} target="_blank" rel="noopener noreferrer">Read more</a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentNews;
